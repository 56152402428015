import React, { PureComponent } from 'react';

import CustomInput from './CustomInput';


export default class CurrencyInput extends PureComponent {
  render() {
    return (
      <CustomInput {...this.props} type="currency" factor={1 / 12} showSymbol />
    );
  }
}
