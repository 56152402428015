import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Analysis from 'vendor/Analysis';

import { saveAnalysis, selectAnalysisParams } from 'data/analyses';

import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';

import Layout from './Layout';


class ModalAnalysisSave extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      analysisName: props.name,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    this.props.closePopup();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { analysisName: name } = this.state;
    const { analysisParams, saveAnalysis, savedPropertyId } = this.props;

    if (!name || name.trim() === '') return;
    saveAnalysis({ ...new Analysis(analysisParams.toJS()), name, savedPropertyId });
    this.handleClose();
  }

  render() {
    return (
      <Layout
        {...this.props}
        analysisName={this.state.analysisName}
        onChange={this.handleChange}
        onClose={this.handleClose}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const formName = 'save-analysis-modal-form';

const mapStateToProps = state => ({
  form: formName,
  analysisParams: selectAnalysisParams(state),
});

const mapActionToProps = {
  saveAnalysis,
};

const Popup = connect(mapStateToProps, mapActionToProps)(ModalAnalysisSave);

Popup.registrationId = getPopupRegistration(Popup);
Popup.open = (obj = {}, ...rest) => openPopup(Popup.registrationId, { ...obj, priority: Priority.HIGHEST }, ...rest);

export default Popup;
