import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import Confirm from 'app/components/Confirm';
import Button from 'components/base/Button';
import Radio from 'components/base/Radio';
import Modal from 'components/base/Modal';
import Dropdown from 'components/base/Dropdown';

import css from './style.scss';


class AddToFolder extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    const { folders: allFolders, type, folderKey, editMode } = props;

    const folderMap = allFolders.reduce((m, f) => ({ ...m, [f.key]: f }), {});

    this.folders = allFolders.filter(f => f.type === type).map(f => ({ value: f.key, label: `${f.parentKey && folderMap[f.parentKey] ? `${folderMap[f.parentKey].name} / ` : ''}${f.name}`, name: f.name, parentKey: f.parentKey }));
    this.folder = this.folders.find(f => f.value === folderKey);

    // Sort by full name (parent folder + folder)
    this.folders.sort((a, b) => a.label.localeCompare(b.label));

    this.rootFolders = this.folders.filter(f => !f.parentKey && f.value !== folderKey);
    if (this.rootFolders.length) this.rootFolders.unshift({ value: '', label: '<No Parent>' });

    this.state = this.folder ? {
      selection: this.folder.value,
      parentSelection: this.folder.parentKey,
      name: this.folder.name,
      mode: editMode ? 'new' : 'add',
    } : {
      selection: (this.folders[0] || { value: '' }).value,
      parentSelection: '',
      name: '',
      mode: 'new',
    };
  }

  handleSubmit(ev) {
    ev.preventDefault();

    const { alert, closePopup, onSubmit, type, folderKey, editMode } = this.props;
    const { mode, selection, parentSelection } = this.state;
    const name = this.state.name.trim();

    if (mode === 'new' && name === '') alert('Please enter a name.');
    else {
      closePopup();
      onSubmit({
        type,
        key: editMode ? folderKey : selection,
        parentKey: parentSelection,
        name,
      });
    }
  }

  handleChange(ev) {
    const { name, value } = ev.target || ev;
    const state = { [name]: value };

    this.setState(state);
  }

  render() {
    const { loading, closePopup, newEnabled, editMode } = this.props;
    const { name, mode, selection, parentSelection } = this.state;

    const btn = { isLoading: loading, size: Button.size.large, kind: Button.kind.default };

    const modes = [{ value: 'add', label: 'Add to Folder' }];
    if (newEnabled) modes.push({ value: 'new', label: 'Create New' });

    return (
      <Modal
        isOpen
        uniqId="addToFolder"
        caption={editMode ? `${this.folder ? 'Edit' : 'Add New'} Folder` : `${this.folder ? 'Move' : 'Add'} to Folder`}
        width="420px"
      >
        <form className={css.folders} onSubmit={this.handleSubmit} key="bottom">
          {editMode ? null : (
            <div className={css.buttons} style={{ visibility: modes.length > 1 ? 'visible' : 'hidden' }}>
              {modes.map((m, i) => <Radio name="mode" {...m} key={m.value} checked={mode === m.value} disabled={!i && !this.folders.length} onChange={this.handleChange} />)}
            </div>
          )}
          <div className={css.groupType}>
            {mode === 'new'
              ? <input type="text" name="name" placeholder="New Folder" value={name} className={css.input} onChange={this.handleChange} />
              : <Dropdown name="selection" options={this.folders} value={selection} onChange={this.handleChange} />
            }
          </div>
          {mode !== 'new' || !this.rootFolders.length ? null : (
            <div className={css.groupType}>
              <div className={css.label}>Parent Folder:</div>
              <Dropdown name="parentSelection" options={this.rootFolders} value={parentSelection} onChange={this.handleChange} />
            </div>
          )}
          <div className={css.buttons}>
            <Button {...btn} onClick={closePopup}>Cancel</Button>
            <Button {...btn} kind={Button.kind.blue} type="submit">Save</Button>
          </div>
        </form>
      </Modal>
    );
  }
}

AddToFolder.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  folderKey: PropTypes.string,
  newEnabled: PropTypes.bool,
  editMode: PropTypes.bool,
};

AddToFolder.defaultProps = {
  newEnabled: true,
  editMode: false,
  folderKey: '',
};

const AddToFolderPopup = connect(() => ({
}), {
  confirm: Confirm.alert,
})(AddToFolder);

AddToFolderPopup.open = props => openPopup(getPopupRegistration(AddToFolderPopup), { ...props, priority: Priority.MEDIUM_HIGH });

export default AddToFolderPopup;
