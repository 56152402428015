import React, { PureComponent } from 'react';

import classNames from 'classnames';
import SVG from 'components/base/SVG';
import PropTypes from 'prop-types';

import css from './style.scss';


class Accordion extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    const { activeSection, isOpened } = props;

    this.auto = activeSection == null;

    this.state = { isOpened };
  }

  handleChange() {
    const { caption, onChange } = this.props;

    if (this.auto) this.setState({ isOpened: !this.state.isOpened });
    else onChange(caption);
  }

  render() {
    const { caption, captionClass, children, isLarge, isArrowRight, activeSection, className } = this.props;
    const { isOpened } = this.state;
    const opened = (this.auto && isOpened) || (!this.auto && caption === activeSection);
    const rootClass = classNames(css.accordion, { [css.opened]: opened, [css.large]: isLarge, [css.right]: isArrowRight }, className);
    return (
      <div className={rootClass}>
        {(caption || '') === '' && opened ? null : (
          <div className={css.header} onClick={this.handleChange}>
            <SVG icon="iconCaretLeft" className={css.arrow} />
            <span className={classNames(css.caption, captionClass)}>{caption}</span>
          </div>
        )}
        <div className={css.body}>
          {children}
        </div>
      </div>
    );
  }
}

Accordion.defaultProps = {
  isOpened: false,
  isLarge: false,
  isArrowRight: false,
  activeSection: null,
};

Accordion.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string,
  activeSection: PropTypes.string,
  isOpened: PropTypes.bool,
  children: PropTypes.node,
  isLarge: PropTypes.bool,
  isArrowRight: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Accordion;
