import React, { PureComponent } from 'react';
import Button, { ButtonLink } from 'components/base/Button';

import SVG from 'components/base/SVG';
import { campaignPath } from 'app/routes';

import css from './style.scss';


class NoCampaigns extends PureComponent {
  render() {
    return (
      <div className={css.noCampaigns}>
        <div>
          <div className={css.start}>
            <SVG icon="iconCampaigns" className={css.iconCampaigns} />
            <div className={css.title}>You have no ongoing campaigns</div>
            <div className={css.subtitle}>Click “New Campaign” or the button below to start one</div>
            <ButtonLink to={campaignPath('new')} kind={Button.kind.grayGhost} size={Button.size.middle} className={css.btn}>Start Your First Campaign</ButtonLink>
          </div>
          <div className={css.youCan}>
            <div className="youCanTitle">By creating a new campaign, you can:</div>
            <div className={css.listWrap}>
              <ul className={css.youCanList}>
                <li>Target to a specific group of recipients</li>
                <li>Keep track of your activities’ stats</li>
                <li>Have multiple campaigns at the same time</li>
                <li>Customize activities for different campaigns</li>
              </ul>
              <ul className={css.youCanList}>
                <li>Create a Website</li>
                <li>Create Postcard</li>
                <li>Send Emailer</li>
                <li>Create Online Advertisements</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NoCampaigns;
