import isArray from './isArray';

/** @flow */
/**
 * Checks if argument is an instance of FormData.
 */
export default function isFormData(arg) {
  return Object.prototype.toString.call(arg) === '[object FormData]';
}

export function getFormData(data) {
  const formData = new FormData();

  (isArray(data) ? data : [data]).forEach((item) => {
    if (item.file) formData.append(item.name, item.file, item.file.name);
    else formData.append(item.name, item.value);
  });

  return formData;
}
