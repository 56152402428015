import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import css from './style.scss';


class ContactNameCellRenderer extends Component {
  render() {
    const { value, data: { id } } = this.props;

    return <Link to={`/property/group/${id}`} className={css.link} target="detail">{value}</Link>;
  }
}

export default ContactNameCellRenderer;
