/** @flow */
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SVG from 'components/base/SVG';

import css from './style.scss';


/**
 * Button class
 *
 * You can use any button attributes like type, disabled, etc
 * @param isLoading     {Boolean} (optional) flag for loading state
 * @param spinnerColor  {String}  (optional) color of flag, can be white or blue (by default is blue)
 *
 * @returns {Component}
 *
 *
 * How you can use it
 *
 * <Button
 *   type="button"
 *   disabled={false}
 *   className={css.btn}
 *   kind={Button.kind.blue}
 *   size={Button.size.large}
 *   isLoading={isLoading}
 *   spinnerColor="white"
 * >
 *    My button
 * </Button>
 */
class Button extends PureComponent {
  /* :: static kind: Object */
  /* :: static size: Object */
  constructor(props) {
    super(props);
    const { width = null } = props;
    this.state = {
      style: { width },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { width = null } = nextProps;
    const { style } = this.state;
    if (style.width !== width) {
      this.setState({ style: { ...style, width } });
    }
  }

  renderSpinner() {
    const { isLoading, spinnerColor } = this.props;

    return isLoading ? (
      <SVG icon="spinner" className={classNames(css.spinner, css[spinnerColor])} />
    ) : null;
  }

  render() {
    const { isLoading, children, kind, size, className, onRef, spinnerColor, ...rest } = this.props;
    const { style } = this.state;
    const btnClass = classNames(css[kind], css[size], className, { [css.loading]: isLoading });
    
    return (
      <button ref={onRef} {...rest} style={style} className={this.props.type === 'submit' ? `${btnClass} btn btn-primary` : `${btnClass}`}>
        {this.renderSpinner()}
        <span className={css.text}>
          {children}
        </span>
      </button>
    );
  }
}

Button.kind = {
  link: 'link-default',
  blueLink: 'link-blue',
  redLink: 'link-red',
  default: 'border-default',
  ghost: 'border-blue',
  redGhost: 'border-red',
  blueGhost: 'border-blue',
  grayGhost: 'border-grey',
  blue: 'button-blue',
  transparent: 'button-transparent',
};

Button.size = {
  extraSmall: 'extra-small',
  small: 'small',
  middle: 'middle',
  large: 'large',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.oneOf(Object.values(Button.kind)).isRequired,
  size: PropTypes.oneOf(Object.values(Button.size)),
  name: PropTypes.string,
  spinnerColor: PropTypes.string,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  type: 'button',
  isLoading: false,
};


export default Button;
