import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import pluralize from 'pluralize';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { getUserLogoUrl } from 'utils/brand';
import { selectProfile, selectIsLoading } from 'data/user/selectors';
import { saveProfile, selectListManagementEnabled } from 'data/user';
import { deferExecutor } from 'app/DeferredOnLocation';

import UpgradeAccount from 'app/components/UpgradeAccount';
import numberFormat from 'utils/number/format';
import { formatDateShort, today } from 'utils/date/formatDate';
import { SolidButton } from 'components/Button';
import SVG from 'components/base/SVG';
import Link, { UploadLink } from 'components/Link';
import UserLogo from 'app/components/UserLogo';

import Activity from './Activity';
import Notifications from './Notifications';
import Profile from './Profile';
import Services from './Services';
import Team from './Team';
import css from './style.scss';
import { CANCELATION_BASE_URL } from '../../config/constants/API';


const AccountTabs = ['profile', 'team', 'activity', 'services', 'notifications'];

const writeLimit = (activeQuantity, remainingQuantity, onIncrease, upgradeButtonText, type) => (
  <div className={css.limit}>
    <div>
      <div>{numberFormat(remainingQuantity)} / {numberFormat(activeQuantity)} <span style={{ paddingLeft: '15px' }}>{type} Remaining</span></div>
      <div className={css.remainingBar}>
        <div className={css.remainingBarInner} style={{ width: `${Math.max(0, Math.min(100, Math.round((remainingQuantity * 100) / activeQuantity)))}%` }} />
      </div>
    </div>
    {!upgradeButtonText ? null : <SolidButton onClick={onIncrease}>{upgradeButtonText || 'Increase Limit'}</SolidButton>}
  </div>
);


class Account extends PureComponent {
  constructor(props) {
    super(props);

    this.handleLogoRemove = this.handleLogoRemove.bind(this);
    this.handleLogoChange = this.handleLogoChange.bind(this);
    this.handleUpgradePropertiesClick = this.handleUpgradePropertiesClick.bind(this);
    this.handleUpgradeExportsClick = this.handleUpgradeExportsClick.bind(this);
    this.loadTab = this.loadTab.bind(this);
    // this.getAccountPendingStatus = this.getAccountPendingStatus.bind(this);
    this.getAccountStatus = this.getAccountStatus.bind(this);

    const { profile: { logoUrl, firstName, lastName } } = props;

    this.state = {
      logoUrl,
      firstName,
      lastName,
      logo: null,
      account_id: null,
      trial_count: null,
      extended_count: null,
      cancelButtonShow: '1',
      next_cycle_date: '',
      apiError: false,
      remaining_days: '',
    };
  }

  componentWillReceiveProps() {
    if (this.getTabIndex() < 0) this.loadTab(0);
  }

  getTabIndex() {
    const { location: { pathname } } = this.props;
    const path = pathname.substr(Math.min(pathname.length, 9));

    return AccountTabs.indexOf(path);
  }

  loadTab(index) {
    this.props.history.push(`/account/${AccountTabs[index]}`);
  }

  handleLogoRemove() {
    this.setState({
      logo: null,
      logoUrl: getUserLogoUrl(),
    });
  }

  handleUpgradePropertiesClick() {
    this.props.openUpgradeAccount();
  }

  handleUpgradeExportsClick() {
    this.props.openUpgradeAccount({ bumpType: 'exportProperty' });
  }

  handleLogoChange(event) {
    if (!event.target.files || !event.target.files[0]) return;

    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      this.setState({
        logo: file,
        logoUrl: event.target.result,
      });
    };

    fileReader.readAsDataURL(file);
  }

  // getAccountPendingStatus() {
  //   const { profile } = this.props; 
  //   fetch(`${CANCELATION_BASE_URL}/users/user/pendingcancel/${profile.username}`, {
  //     method: 'GET', // or 'PUT'
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'x-auth-token': window.appAuthToken,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       let res = data.detail[0];
  //       this.setState({
  //         cancelButtonShow: res? res.pending_cancellation: '0',
  //       });
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //       this.setState({
  //         apiError: true,
  //       });
  //     });
  // }

  getAccountStatus() {
    const { profile } = this.props; 
    fetch(`${CANCELATION_BASE_URL}/get/user/info/${profile.username}`, {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': window.appAuthToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let res = data.detail[0];
        this.setState({
          trial_count: res ? res.trial_count : '0',
          extended_count: res ? res.extended_count : '0',
          account_id: res ? res.account_id : 0,
          cancelButtonShow: res? res.pending_cancellation: '0',
          remaining_days: res? res.remaining_days : '',
          next_cycle_date: res? res.next_cycle_date: '',
          apiError: false,
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        this.setState({
          apiError: true,
        });
      });
  }


  componentDidMount(){
    // this.getAccountPendingStatus();
    this.getAccountStatus();
  }


  render() {
    const { profile } = this.props;
    let nextBillingDate = '';
    if(this.state.next_cycle_date !== ''){
      const [year, month, date]= this.state.next_cycle_date.split('-');
      nextBillingDate = `${month}/${date}/${year.slice(2)}`;
    }
    const { paymentMethods, remainingSavedProperties, activeSavedPropertyLimit, remainingExportProperties, activeExportPropertyLimit, nextCycleDate, fullAccount, overdueAccount, assignedSavedPropertyLimit, catalogProducts, csPhone, subUser, planMrcs } = profile;
    const { firstName, lastName, logo, logoUrl } = this.state;
    const tabIndex = this.getTabIndex();
    const teamEnabled = !!planMrcs.filter(p => ['ACTIVATION_CODE', 'SUB_USER'].includes(p.type)).length;
    const serviceEnabled = !!planMrcs.filter(p => !['ACTIVATION_CODE', 'SUB_USER'].includes(p.type)).length;

    const periodEndDate = moment(nextCycleDate);
    const periodDaysRemaining = periodEndDate.diff(today(), 'day') + 1; // FIXME: Adding 1 since end date is GMT and today is local
    const properties = numberFormat(assignedSavedPropertyLimit);

    const updatedperiodEndDate = moment(this.state.next_cycle_date);
    const updatedperiodDaysRemaining = updatedperiodEndDate.diff(today(), 'day') + 1; // FIXME: Adding 1 since end date is GMT and today is local

    const paymentMethodOptions = (paymentMethods || []).map(({ id, description }) => ({ label: description, value: id }));
    paymentMethodOptions.unshift({ label: 'New Credit Card', value: '' });

    const logoSet = logoUrl && !logoUrl.endsWith('/defaultLogo');

    let upgradePropertiesButtonText = fullAccount && !overdueAccount ? null : `${fullAccount ? 'Renew' : 'Activate'} Subscription`;

    let alertMessage;
    if (upgradePropertiesButtonText) {
      alertMessage = subUser
        ? `Your account is currently ${fullAccount ? 'past due' : 'in trial mode'}. Please contact your team leader about gaining access to the full property limit.`
        : `Your account is currently ${fullAccount ? 'past due' : 'in trial mode'}. To ${fullAccount ? 'renew' : 'activate'} your subscription and gain access to the full ${properties} property limit, click the ${upgradePropertiesButtonText} button.`;
    } else if (!remainingSavedProperties) {
      alertMessage = subUser
        ? 'You have no remaining saved properties this billing period. Please contact your team leader about increasing your property limit.'
        : `You have no remaining saved properties this billing period. ${(catalogProducts || []).length ? 'To gain access to additional saved properties, click the Increase Limit button.' : `Please contact Customer Support at ${csPhone}.`}`;
    }

    let upgradeExportButtonText;
    if (subUser) upgradePropertiesButtonText = null;
    else if (!upgradePropertiesButtonText && (catalogProducts || []).length) {
      upgradePropertiesButtonText = 'Increase Save/Import Limit';
      upgradeExportButtonText = 'Increase Export Limit';
    }

    return (
      <div className={css.profile}>
        <Helmet title="My Account" />
        <div className={css.top}>
          <div className={css.left}>
            <div className={css.logo}>
              <UserLogo url={logoUrl} />
              <div className={css.logoActions} style={{ visibility: tabIndex || subUser ? 'hidden' : 'visible' }}>
                <UploadLink className={css.link} onChange={this.handleLogoChange}>{logoSet ? 'Edit' : 'Add Photo'}</UploadLink>
                {!logoSet ? null : <Link className={css.link} onClick={this.handleLogoRemove}>Remove</Link>}
              </div>
            </div>
            <div className={css.member}>
              <div className={css.name}>{firstName} {lastName}</div>
              <div className={css.location}>{firstName === 'Rich' && lastName === 'Test' && process.env.NODE_ENV !== 'production' ? 'Canada' : 'United States'}</div>
            </div>
          </div>
          <div className={css.right}>
            <div className={css.cycleLabel}>Current Billing Cycle</div>
            <div className={css.cycle}>
              <div className={css.remaining}>
                <div className={css.days}>{this.state.apiError? '--' : `${this.state.remaining_days? this.state.remaining_days: '--'}` }</div>
                <div>
                  <div>{pluralize('Day', this.state.apiError? '--' : `${this.state.remaining_days}`)} remaining.</div>
                  <div>Bills on {this.state.apiError? '--' : `${nextBillingDate && nextBillingDate !== '' ? nextBillingDate: '--' }`}</div>
                </div>
              </div>
              <div className={css.limits}>
                {writeLimit(activeSavedPropertyLimit, remainingSavedProperties, this.handleUpgradePropertiesClick, upgradePropertiesButtonText, 'Saves/Imports*')}
                {writeLimit(activeExportPropertyLimit, remainingExportProperties, this.handleUpgradeExportsClick, upgradeExportButtonText, 'Exports')}
                {periodDaysRemaining <= 40 ? null : <div className={css.monthlyProperties} style={{ marginBottom: '5px;' }}>You will receive access to download 10,000 additional properties/downloads each month.</div>}
                <div className={css.monthlyProperties}>*Imports require List Automator add-on service to import records.</div>
              </div>
            </div>
            {!alertMessage ? null : (
              <div className={css.alert}>
                <SVG icon="iconWarning" className={css.iconWarning} />
                {alertMessage}
              </div>
            )}
          </div>
        </div>
        {subUser ? null : (
          <Tabs selectedIndex={tabIndex} onSelect={this.loadTab} className={css.tabs}>
            <TabList>
              <Tab>Profile / Billing Settings</Tab>
              {!teamEnabled ? null : <Tab>Manage Team</Tab>}
              <Tab>Account Activity</Tab>
              {!serviceEnabled ? null : <Tab>Add-on Services</Tab>}
              {true ? null : [
                <Tab>Notifications</Tab>,
              ]}
            </TabList>
            <TabPanel>
              <Profile logo={logo} logoUrl={logoUrl} />
            </TabPanel>
            {!teamEnabled ? null : (
              <TabPanel>
                <Team />
              </TabPanel>
            )}
            <TabPanel>
              <Activity 
               trial_count= {this.state.trial_count}
               extended_count={this.state.extended_count}
               account_id= {this.state.account_id}
               cancelButtonShow={this.state.cancelButtonShow}
               getAccountStatus={this.getAccountStatus}
              //  getAccountPendingStatus={this.getAccountPendingStatus}
               />
            </TabPanel>
            {!serviceEnabled ? null : (
              <TabPanel>
                <Services />
              </TabPanel>
            )}
            {true ? null : [
              <TabPanel>
                <Notifications />
              </TabPanel>,
            ]}
          </Tabs>
        )}
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  profile: selectProfile(state).toJS(),
  isLoading: selectIsLoading(state),
  lmEnabled: selectListManagementEnabled(state),
}), {
  saveProfile,
  openUpgradeAccount: UpgradeAccount.open,
})(deferExecutor(Account)));
