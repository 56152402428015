import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { ListingTypes } from 'data/search';
import { getGoogleImageUrl } from 'data/property';
import numberToPrice from 'utils/currency/numberToPrice';

import CustomTabs from 'components/CustomTabs';
import AnalysisButton from 'app/components/AnalysisButton';
import Image from 'components/base/Image';
import Button, { ButtonLink } from 'components/base/Button';
import Label from 'components/Label';
import SVG from 'components/base/SVG';
import Table from 'components/base/Table';
import css from './style.scss';
import ColumnsRenderers from '../Columns';
import { getAerialImageUrl } from 'utils/brand';


const RightFields = [
  { field: 'estimatedValue', label: 'Estimated Value', formatter: numberToPrice, defaultValue: '-' },
  { field: 'onMarket', label: 'Status', formatter: v => (v ? 'On Market' : 'Off Market') },
  { field: 'distressed', label: 'Distressed', formatter: v => (v ? 'Yes' : 'No') },
  { field: 'involuntaryLienAmount', label: 'Liens', formatter: numberToPrice, defaultValue: 0 },
  { field: 'ownership', label: 'Owner', highlight: true },
  { field: 'occupancy', label: 'Owner', highlight: true },
];

const BottomFields = [
  { field: 'bedrooms', label: 'Beds' },
  { field: 'bathrooms', label: 'Baths' },
  { field: 'squareFeet', label: 'SqFt' },
  { field: 'yearBuilt', label: 'Yr. Built' },
];

const Tiles = [
  { field: 'estimatedEquity', label: 'Est. Equity' },
  { field: 'compSaleAmount', label: 'Avg. Comps' },
  { field: 'rentAmount', label: 'Est. Rent' },
];

const TabFields = [
  { field: 'neighbors', label: 'Neighbors', type: ListingTypes.Neighbor, fields: ['COUNTER', 'ADDRESS', 'BEDROOMS', 'BATHROOMS', 'ESTIMATE_VALUE', 'SALE_PRICE', 'SALE_DATE'] },
  { field: 'nearbyMlsListings', label: 'MLS', type: ListingTypes.M, fields: ['COUNTER', 'ADDRESS', 'BEDROOMS', 'BATHROOMS', 'LISTING_PRICE', 'LISTING_DATE', 'STATUS'] },
  { field: 'nearbyPreForeclosures', label: 'Pre-Foreclosures', type: ListingTypes.P, fields: ['COUNTER', 'DOCUMENT_TYPE', 'ADDRESS', 'BEDROOMS', 'BATHROOMS', 'LISTING_AMOUNT', 'UPDATE_DATE'] },
  { field: 'nearbyForeclosures', label: 'Foreclosures', type: ListingTypes.F, fields: ['COUNTER', 'DOCUMENT_TYPE', 'ADDRESS', 'BEDROOMS', 'BATHROOMS', 'LISTING_AMOUNT', 'UPDATE_DATE'] },
];


class PropertyView extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.state = { savedPropertyId: null };
  }

  handleSave() {
    this.props.onSaveProperty(savedPropertyId => this.setState({ savedPropertyId }));
  }

  handleSelect(index) {
    this.props.setActiveContext(TabFields[index].type);
  }

  render() {
    const { result, sort, onSort, onClick, activeContext, loading, activeMarker , selectProperty } = this.props;
    const subject = result.get('property');
    const id = subject.get('id');

    const selectPropertyId = selectProperty.get('id');
    const propertyDetailsSavedPropertyId = selectProperty.get('savedPropertyId');
    let savedPropertyId = subject.get('savedPropertyId') || this.state.savedPropertyId;

    const linkToDetails = `/search/${id}`;
    const results = activeContext.get('displayResults');
    const { streetAddress, cityName, stateCode, zip } = subject.get('address').toJS();

    if(selectPropertyId === id) {
      savedPropertyId = propertyDetailsSavedPropertyId || this.state.savedPropertyId;
   }

    const aerialUrl = getAerialImageUrl(id);
    let imageUrl = subject.get('imageUrl');
    // removed no_photo.with /house-img-not-available.
    if (!imageUrl || imageUrl.includes('/coming_soon.') || imageUrl.includes('/house-img-not-available.')) imageUrl = aerialUrl;

    return (
      <div className={css.property}>
        <div className={css.subject}>
          <h3 className={css.title}>
            <Link className={css.title} to={linkToDetails}>{`${streetAddress}, ${cityName} ${stateCode} ${zip}`}</Link>
          </h3>
          <div className={css.widgetInfo}>
            <div className={css.imagePreview}>
              <Link to={linkToDetails}>
                <Image src={imageUrl} alt={streetAddress} placeholder={aerialUrl} />
              </Link>
            </div>
            <div className={css.right}>
              {RightFields.map(f => ({ ...f, value: (f.formatter || (v => v))(subject.get(f.field), f.defaultValue) })).map(f => (
                <div className={css.rowInfo} key={f.field}>
                  <div className={css.label}>{f.label}:</div>
                  <div className={css.value}>{!f.highlight ? f.value : <Label background="blue" size="normal">{f.value}</Label>}</div>
                </div>
              ))}
              <div className={css.spacedValue}>{subject.get('landUse')}</div>
              <div className={css.bottom}>
                {BottomFields.map(f => ({ ...f, value: (f.formatter || (v => v))(subject.get(f.field), f.defaultValue) })).filter(f => !!f.value).map(f => (
                  <div className={css.item} key={f.field}>
                    <div className={css.value}>{f.value}</div>
                    <div className={css.label}>{f.label}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={css.tiles}>
            {Tiles.map(f => (
              <div className={css.tile} key={f.field}>
                <div className={css.value}>{numberToPrice(subject.get(f.field), '-')}</div>
                <div className={css.title}>{f.label}</div>
              </div>
            ))}
          </div>
          <div className={css.buttons}>
            <ButtonLink to={linkToDetails} className={`${css.wideButton} ${css.tealButton}`} isLoading={loading}>Details</ButtonLink>
            <AnalysisButton propertyId={id} width="115px" isLoading={loading} />
            <Button className={css.tealButton} kind={Button.kind.ghost} size={Button.size.middle} width="115px" disabled={!!savedPropertyId} onClick={this.handleSave} isLoading={loading}>{savedPropertyId ? 'Saved' : 'Save'}</Button>
          </div>
        </div>
        <hr className={css.divider} />
        <div className={css.wrapTitle}>
          <h3 className={css.title}>Nearby Listings</h3>
        </div>
        <div className={css.scrollTable}>
          <CustomTabs kind="line">
            <Tabs selectedIndex={TabFields.findIndex(t => t.type === result.get('activeContext'))} onSelect={this.handleSelect}>
              <TabList>
                {TabFields.map(t => <Tab>{t.label} ({result.getIn(['contexts', t.type, 'resultCount'])})</Tab>)}
              </TabList>
              {TabFields.map(({ fields }) => (
                <TabPanel>
                  <Table data={results} sortFields={sort} onColumnSort={onSort} onRowClick={onClick} keyField="id" className="type_11" isSortable isHoverable>
                    {fields.map(f => ColumnsRenderers[f]({ activeMarker }, <SVG icon={`iconCaret${activeContext.get('sortAscending') ? 'Up' : 'Down'}`} />))}
                  </Table>
                </TabPanel>
              ))}
            </Tabs>
          </CustomTabs>
        </div>
      </div>
    );
  }
}


PropertyView.propTypes = {};

export default PropertyView;
