import PreliminaryOverlay from 'app/components/PreliminaryOverlay';
import loadable from 'components/hoc/loadable';
import {
  authenticate,
  selectIsAuthed,
  selectIsLoading,
  selectProfile,
} from 'data/user';
import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBrandCode, getBrandLoginUrl } from 'utils/brand';

const AuthGuard = (props) => {
  useEffect(() => {
    const { authenticate, history, authed, location } = props;

    const { pathname } = location;
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('t');
    const activationCode = searchParams.get('c');
    if (!authed) {
      authenticate(
        token,
        activationCode,
        false,
        () => {
          history.replace(pathname);
        },
        () => {
          window.location.href = getBrandLoginUrl();
        }
      );
    }
  }, []);
  // }

  useEffect(() => {
    const { redirectUrl } = props;

    if (redirectUrl) top.location.href = redirectUrl;
  }, [props]);

  const {
    isLoading,
    authed,
    children,
    profile: { termsAgreementRequired, appPaymentMethodRequired },
  } = props;

  if (!authed || isLoading) return null;

  return termsAgreementRequired || appPaymentMethodRequired ? (
    <PreliminaryOverlay />
  ) : (
    children
  );
};

export default withRouter(
  connect(
    (state) => {
      const authed = selectIsAuthed(state);
      const redirectUrl =
        authed &&
        self !== top &&
        getBrandCode() === 'investorsedge' &&
        document.cookie.toLowerCase().indexOf('jsessionid=') < 0
          ? 'https://app.investorsedgesoftware.com/eqbackend/resource/cookie?b=investorsedge'
          : null;

      return {
        profile: selectProfile(state).toJS(),
        isLoading: selectIsLoading(state),
        authed: authed && !redirectUrl,
        redirectUrl,
      };
    },
    {
      authenticate,
    }
  )(loadable(AuthGuard))
);
