import React, { PureComponent } from 'react';

import { CheckboxDropdown } from 'components/base/Dropdown';
import FormControlWraper from 'components/base/FormControlWraper';
import PropTypes from 'prop-types';

import css from './style.scss';


class ColumnFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev) {
    const { field, onFilterChange, value, options } = this.props;
    const { value: newVal } = ev.target || ev;

    onFilterChange(field, newVal);
  }

  render() {
    const { handleChange, props: { options, modes, searchable, value, valueSep, disabled, readOnly } } = this;
    const props = {
      value: value || '',
      onClick: event => event?.stopPropagation(),
      onChange: handleChange,
      disabled,
      readOnly,
    };

    return (
      <div className={css.thRow}>
        <FormControlWraper>
          {options ? <CheckboxDropdown {...props} options={options} modes={modes} searchable={searchable} valueSep={valueSep} /> : <input type="text" {...props} />}
        </FormControlWraper>
      </div>
    );
  }
}

ColumnFilter.propTypes = {
  field: PropTypes.string.isRequired,
  searchString: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

ColumnFilter.defaultProps = {
  searchString: '',
  disabled: false,
  readOnly: false,
};


export default ColumnFilter;
