import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ColorDot from 'components/ColorDot';
import { SearchColors, MlsListingStatusNoneList, SearchDefaultColor } from 'data/search';
import { getOptionsNoDefault } from 'utils/normalizers/dropdown';
import { searchGroupContext, updateGroupContext, updateGroupContextSelection, selectOriginalFavorites, selectOriginalLists } from 'data/property';
import { toProperCase } from 'utils/string';
import { AnyNoneAllModes, AnyNoneModes } from 'components/base/Dropdown';

import SearchTable, { groupIdRenderer } from '.';
import css from './style.scss';


const indexRenderer = (val, coords, data) => <ColorDot className={css.colorDot} color={SearchColors[data.get('listingType') || SearchDefaultColor]} isBig={false}>{coords.row + 1}</ColorDot>;
const mlsListingStatusRenderer = val => (val === 'NONE' ? 'Off Market' : toProperCase(val));
const addressRenderer = url => (value, _, data) => <Link to={`${url}/${data.get('propertyId')}`} className={css.link}>{value}</Link>;

class PropertySearchTable extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSearch(context) {
    this.props.searchGroupContext(context);
  }

  handleUpdate(context) {
    this.props.updateGroupContext(context);
  }

  handleSelect(select, index) {
    const { context, updateGroupContextSelection } = this.props;
    updateGroupContextSelection(context, select, index);
  }

  render() {
    const { context, lists, loading, fullMode, onDragStart, onDragEnd } = this.props;

    // const favoriteMap = favorites.reduce((m, g) => ({ ...m, [g.get('id')]: g }), {});
    const listMap = lists.reduce((m, g) => ({ ...m, [g.get('id')]: g }), {});

    const fields = [
      { name: 'streetAddress', label: 'Address', renderer: fullMode ? addressRenderer(`/property/group/${context.get('id')}`) : undefined },
      { name: 'cityName', label: 'City' },
      { name: 'stateCode', label: 'State' },
      { name: 'zip', label: 'Zip' },
      { name: 'bedrooms', label: 'Beds' },
      { name: 'bathrooms', label: 'Baths' },
      { name: 'squareFeet', label: 'Square Feet' },
      { name: 'landUse', label: 'Property Type' },
      { name: 'mlsListingStatus', label: 'Status', renderer: mlsListingStatusRenderer, options: getOptionsNoDefault(MlsListingStatusNoneList, 'value', 'label'), modes: AnyNoneModes },
      // { name: 'groupIds', label: 'Groups', filterField: 'favoriteIds', renderer: groupIdRenderer(favoriteMap), options: getOptions(favorites.slice(1), false), sortable: false, display: fullMode },
      { name: 'groupIds', label: 'Marketing Lists', filterField: 'groupIds', renderer: groupIdRenderer(listMap), options: getOptionsNoDefault(lists), modes: AnyNoneAllModes, searchable: true, sortable: false, display: fullMode },
    ];

    const props = { context, loading, fields, onDragStart, onDragEnd, indexRenderer };

    return (
      <SearchTable
        {...props}
        className={css.propertySearchTable}
        onSearch={this.handleSearch}
        onUpdate={this.handleUpdate}
        onSelect={this.handleSelect}
      />
    );
  }
}

PropertySearchTable.propTypes = {
  fullMode: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
};

PropertySearchTable.defaultProps = {
  fullMode: false,
};

export default connect(state => ({
  favorites: selectOriginalFavorites(state),
  lists: selectOriginalLists(state),
}), {
  searchGroupContext,
  updateGroupContext,
  updateGroupContextSelection,
})(PropertySearchTable);
