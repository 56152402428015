const AnalysisClosingCosts = {
  POINTS_ID: 1,
  ADMINISTRATION_FEE_ID: 2,
  APPLICATION_FEE_ID: 3,
  COMMITMENT_FEE_ID: 4,
  DOCUMENT_PREPARATION_ID: 5,
  FUNDING_FEE_ID: 6,
  MORTGAGE_BROKER_FEE_ID: 7,
  PROCESSING_ID: 8,
  TAX_SERVICE_ID: 9,
  UNDERWRITING_ID: 10,
  APPRAISAL_ID: 11,
  ATTORNEY_SETTLEMENT_FEE_ID: 12,
  CREDIT_REPORT_ID: 13,
  FLOOD_CERTIFICATION_ID: 14,
  PEST_OTHER_INSPECTION_ID: 15,
  SURVEY_ID: 16,
  TITLE_INSURANCE_ID: 17,
  TITLE_SEARCH_ID: 18,
  RECORDING_FEE_ID: 19,
  TAXES_ID: 20,
  OTHER_FEES_COSTS_ID: 21,
};

export default AnalysisClosingCosts;
