/** @flow */
import { createSelector } from 'reselect';
import { List } from 'immutable';


const emptyList = List();

const checkState = state => state;

export const selectErrors = createSelector(
  checkState,
  state => state.getIn(['errors']),
);

export const selectErrorQueue = createSelector(
  selectErrors,
  errors => errors.get('queue', emptyList),
);

export const selectError = createSelector(
  selectErrorQueue,
  queue => queue.get(0, null),
);
