import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { selectProfile, selectUsers } from 'data/user';
import { UserTypeMap } from 'data/user/constants';
import { SolidButton } from 'components/Button';

import css from './style.scss';
import UserLogo from '../UserLogo';


const MiscUser = { id: 0, name: 'Miscellaneous User' };

const TeamMemberList = ({ editButtonText, onEditClick, onReplaceClick, onDeleteClick, users, loading, miscUserEnabled, primaryEnabled, profile, iconSize, includeIds, excludeIds, placeholder, selfEnabled }) => {
  let members = users.filter(m => (primaryEnabled || m.type !== 'FULL') && (selfEnabled || m.id !== profile.id));
  if (miscUserEnabled) members = members.concat([MiscUser]);
  if (includeIds) members = members.filter(m => includeIds.includes(m.id));
  if (excludeIds) members = members.filter(m => !excludeIds.includes(m.id));

  return (
    <div className={css.users}>
      {!members.length ? <div className={css.noUsers}>{loading ? '' : placeholder}</div> : members.map(u => (
        <div className={css.user} key={u.id}>
          <UserLogo size={iconSize} id={u.id} />
          <div className={css.name}>
            <div>{u.name}</div>
            <div>{u.username}</div>
            <div className={css.type}>{u.type && UserTypeMap[u.type].label}</div>
          </div>
          <div className={css.buttons}>
            {!onEditClick ? null : <SolidButton onClick={() => onEditClick(u)} loading={loading}>{editButtonText}</SolidButton>}
            {!onReplaceClick ? null : <SolidButton onClick={() => onReplaceClick(u)} loading={loading}>Replace</SolidButton>}
            {!onDeleteClick || u.expirationDate ? null : <SolidButton onClick={() => onDeleteClick(u)} loading={loading}>Delete</SolidButton>}
          </div>
          {!onDeleteClick || !u.expirationDate ? null : <div className={css.expiration}>Team Member slot will be available until {moment(u.expirationDate).add(1, 'd').format('MM/DD/yyyy')}.</div>}
        </div>
      ))}
    </div>
  );
};

TeamMemberList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  includeIds: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  excludeIds: PropTypes.array,
  editButtonText: PropTypes.string,
  miscUserEnabled: PropTypes.bool,
  iconSize: PropTypes.number,
  primaryEnabled: PropTypes.bool,
  selfEnabled: PropTypes.bool,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  onReplaceClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

TeamMemberList.defaultProps = {
  editButtonText: 'Edit',
  miscUserEnabled: false,
  primaryEnabled: true,
  selfEnabled: true,
  iconSize: 65,
  placeholder: 'No Team Members',
  loading: false,
};

export default connect(state => ({
  profile: selectProfile(state).toJS(),
  users: selectUsers(state).toJS(),
}))(TeamMemberList);
