/** @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  changeAnalysisRentalUnits,
  addAnalysisRentalUnit,
  removeAnalysisRentalUnit,
} from 'data/analyses/actions';

import Layout from './Layout';


class Income extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      activeRentalUnit: 0,
    };
    this.onRentalUnitsParamsChange = this.onRentalUnitsParamsChange.bind(this);
    this.handleAnalysisRentalUnitRemove = this.handleAnalysisRentalUnitRemove.bind(this);
    this.setActiveRentalUnit = this.setActiveRentalUnit.bind(this);
  }

  componentDidMount() {
    if (this.props.disabled) {
      this.props.openDefaultRoute();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.disabled) newProps.openDefaultRoute();

    const rentalUnitsLength = newProps.analysisParams.rentalUnits.length;
    const rentalUnitsLastIndex = rentalUnitsLength && rentalUnitsLength - 1;
    if (newProps.analysisParams.id !== this.props.analysisParams.id) {
      this.setState({ activeRentalUnit: 0 });
    } else if (this.state.activeRentalUnit > rentalUnitsLastIndex) {
      this.setState({ activeRentalUnit: rentalUnitsLastIndex });
    }
  }

    /* :: onRentalUnitsParamsChange: Function */
  onRentalUnitsParamsChange(event, id) {
    const { name, value } = event.target || event;
    this.props.changeAnalysisRentalUnits(id, { name, value });
  }

  /* :: setActiveRentalUnit: Function */
  setActiveRentalUnit(event, item, index) {
    this.setState({ activeRentalUnit: index });
  }

  /* :: handleAnalysisRentalUnitRemove: Function */
  handleAnalysisRentalUnitRemove() {
    const { activeRentalUnit } = this.state;
    const { removeAnalysisRentalUnit } = this.props;
    if (this.rentalRemoveIsEnabled(activeRentalUnit)) {
      removeAnalysisRentalUnit(activeRentalUnit);
    }
  }

  /* :: rentalRemoveIsEnabled: Function */
  rentalRemoveIsEnabled(activeRentalUnit) {
    const { analysisParams } = this.props;
    return activeRentalUnit >= 0 && activeRentalUnit < analysisParams.rentalUnits.length;
  }


  render() {
    const { activeRentalUnit } = this.state;
    return (
      <Layout
        {...this.props}
        onRentalUnitsChange={this.onRentalUnitsParamsChange}
        onAnalysisRentalUnitRemove={this.handleAnalysisRentalUnitRemove}
        onActiveRentalUnitChange={this.setActiveRentalUnit}
        rentalRemoveIsDisabled={!this.rentalRemoveIsEnabled(activeRentalUnit)}
        activeRentalUnit={activeRentalUnit}
      />
    );
  }
}

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  changeAnalysisRentalUnits,
  addAnalysisRentalUnit,
  removeAnalysisRentalUnit,
};

export default connect(mapStateToProps, mapDispatchToProps)(Income);
