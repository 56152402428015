import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

import Table, { Column } from '../../../components/base/Table';
import ImageComponent from '../../../components/base/Image';


class Image extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      src: 'https://facebook.github.io/react/img/logo',
      hideOnError: true,
    };
  }

  render() {
    const data = fromJS([
      { field1: 'src', field2: 'String', field3: 'false' },
      { field1: 'alt', field2: 'String', field3: 'false' },
      { field1: 'className', field2: 'String', field3: 'false' },
      { field1: 'hideOnEmpty', field2: 'Function', field3: 'false' },
      { field1: 'hideOnError', field2: 'Function', field3: 'false' },
    ]);

    setTimeout(() => {
      this.setState({
        hideOnError: false,
      });

      setTimeout(() => {
        this.setState({
          src: 'https://facebook.github.io/react/img/logo.svg',
        });
      }, 2000);
    }, 5000);

    return (
      <section style={{ paddingLeft: '100px' }}>
        <header>
          <h1>Image component</h1>
        </header>

        <article>
          <header>
            <h2>Props</h2>
          </header>
          <Table data={data}>
            <Column field="field1" defaultValue="default field1 value" isKey>Name</Column>
            <Column field="field2" defaultValue="default field2 value">Type</Column>
            <Column field="field3" defaultValue="default field3 value">Is required</Column>
          </Table>
        </article>

        <article>
          <header>
            <h2>Without src with hideOnEmpty flag.</h2>
            <p>After 5 seconds hideOnEmpty will be removed.</p>
            <p>After 2 more seconds src will be correct.</p>
          </header>
          <ImageComponent src={this.state.src} hideOnError={this.state.hideOnError} />
        </article>

        <article>
          <header>
            <h2>Without src</h2>
          </header>
          <pre>&lt;Image /&gt;</pre>
          <p>Placeholder image</p>
          <ImageComponent />
        </article>

        <article>
          <header>
            <h2>Without src with hideOnEmpty flag</h2>
          </header>
          <pre>&lt;Image hideOnEmpty /&gt;</pre>
          <p>No output</p>
          <ImageComponent hideOnEmpty />
        </article>

        <article>
          <header>
            <h2>With correct src</h2>
          </header>
          <pre>&lt;Image {'src="https://facebook.github.io/react/img/logo.svg"'} /&gt;</pre>
          <p>Specified image</p>
          <ImageComponent src="https://facebook.github.io/react/img/logo.svg" />
        </article>

        <article>
          <header>
            <h2>With correct src with custom styles</h2>
          </header>
          <pre>&lt;Image {'src="https://facebook.github.io/react/img/logo.svg" style={{ width: "100px", height: "100px" }}'} /&gt;</pre>
          <p>Specified image</p>
          <ImageComponent src="https://facebook.github.io/react/img/logo.svg" style={{ width: '100px', height: '100px' }} />
        </article>

        <article>
          <header>
            <h2>With broken src</h2>
          </header>
          <pre>&lt;Image {'src="https://facebook.github.io/react/img/logo"'} /&gt;</pre>
          <p>Placeholder image</p>
          <ImageComponent src="https://facebook.github.io/react/img/logo" />
        </article>

        <article>
          <header>
            <h2>With broken src and hideOnError flag</h2>
          </header>
          <pre>&lt;Image {'src="https://facebook.github.io/react/img/logo" hideOnError'} /&gt;</pre>
          <p>No output</p>
          <ImageComponent src="https://facebook.github.io/react/img/logo" hideOnError />
        </article>
      </section>
    );
  }
}

export default Image;
