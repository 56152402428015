import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectProperty, selectLoading, selectOriginalFavorites, getProperty, saveProperty } from 'data/property';
import Confirm from 'app/components/Confirm';
import AddToGroup from 'app/components/AddToGroup';


export default function (ComposedComponent, mapStatetoProps, actionCreators) {
  class Container extends React.PureComponent {
    constructor(props) {
      super(props);

      this.saveProperty = this.saveProperty.bind(this);
      this.loadProperty = this.loadProperty.bind(this);
      this.checkSavedProperty = this.checkSavedProperty.bind(this);
    }

    saveProperty(onComplete, propertyId = this.props.propertyId) {
      const { saveProperty, openAddToGroup, favorites } = this.props;
      openAddToGroup({
        selectLoading,
        groups: favorites,
        size: 1,
        addPropertyMode: true,
        onComplete,
        save: onComplete => saveProperty(propertyId, null, ({ response: { quantity } }) => onComplete(quantity)),
        add: (name, onComplete) => saveProperty(propertyId, { name }, ({ response: { quantity } }) => onComplete(quantity)),
      });
    }

    loadProperty(id) {
      const { getProperty, propertyId } = this.props;
      if (id !== propertyId) getProperty(id, 'F');
    }

    checkSavedProperty(callback) {
      const { savedPropertyId, confirm } = this.props;
      if (savedPropertyId) callback(savedPropertyId);
      else {
        confirm({
          question: 'This action can only be performed on Saved Properties. Would you like to save this property?',
          textAlign: Confirm.textAlign.center,
          onOk: () => this.saveProperty(callback),
        });
      }
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          {...this.boundActionCreators}
          saveProperty={this.saveProperty}
          loadProperty={this.loadProperty}
          checkSavedProperty={this.checkSavedProperty}
        />
      );
    }
  }

  Container.propTypes = { dispatch: PropTypes.func };
  Container.defaultProps = { dispatch: () => {} };

  return connect((state, props) => {
    const property = selectProperty(state);
    const savedPropertyId = property.get('savedPropertyId');

    return {
      ...mapStatetoProps(state, props),
      property,
      savedPropertyId,
      propertyId: property.get('id'),
      propertySaved: !!savedPropertyId,
      loading: selectLoading(state),
      favorites: selectOriginalFavorites(state),
    };
  }, {
    ...actionCreators,
    saveProperty,
    getProperty,
    confirm: Confirm.open,
    openAddToGroup: AddToGroup.open,
  })(Container);
}
