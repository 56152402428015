import React, { PureComponent } from 'react';
import { is } from 'immutable';
import { connect } from 'react-redux';
import SearchItem from 'components/SearchItem';
import RestrictedContent from 'app/components/RestrictedContent';

import { updateSurroundingPropertyType, selectSurroundingPropertyType, selectSurroundingPropertyTypes, SurroundingPropertyContexts } from 'data/property';

import Surrounding from '../../Surrounding';
import Panel from '../Panel';

import css from './style.scss';


class PropertyComparables extends PureComponent {
  constructor(props) {
    super(props);

    this.handleTypeChange = this.handleTypeChange.bind(this);

    // const { property, updateSurroundingPropertyType, types } = props;
    // if (property.getIn(['address', 'stateCode'])) updateSurroundingPropertyType(SurroundingPropertyContexts.COMPARABLES, types.find(t => t.get('type') === 'M'));
  }

  handleTypeChange(type) {
    const { updateSurroundingPropertyType, types } = this.props;

    updateSurroundingPropertyType(SurroundingPropertyContexts.COMPARABLES, types.find(t => t.get('type') === type));
  }

  render() {
    const { type, types, property } = this.props;

    return (
      <Panel className={css.panel}>
        <div className={css.searchItems}>
          {types.filter(t => !['LINKED'].includes(t.get('type'))).map(t => (
            <RestrictedContent permission={t.get('type').length > 1 || t.get('enabled')} key={t.get('type')}>
              <SearchItem
                label={t.get('label')}
                value={t.get('properties').size}
                tileName={t.get('type')}
                loading={t.get('loading')}
                isActive={is(t, type)}
                onClick={this.handleTypeChange}
                newFeature={t.get('label') === 'MLS'}
              />
            </RestrictedContent>
          ))}
        </div>
        <Surrounding context={SurroundingPropertyContexts.COMPARABLES} property={property} />
      </Panel>
    );
  }
}

export default connect(state => ({
  type: selectSurroundingPropertyType(state, SurroundingPropertyContexts.COMPARABLES),
  types: selectSurroundingPropertyTypes(state, SurroundingPropertyContexts.COMPARABLES),
}), {
  updateSurroundingPropertyType,
})(PropertyComparables);
