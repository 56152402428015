import React from 'react';

import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import Select from 'components/base/Select';
import Form from 'components/base/Form';
import FromControlWrapper from 'components/base/FormControlWraper';

import css from './style.scss';

const Layout = ({ form, analyses, onClose, onSubmit }) => {
  const header = <div className={css.header}>Select Analysis</div>;

  return (
    <Modal isOpen uniqId="modalCreateGroup" padding="25px 10px" caption={header} onClose={onClose}>
      <Form onSubmit={onSubmit} name={form} className={css.body}>
        <FromControlWrapper id="new-group-in-modal" label="Analysis" hasMarginBottom>
          <Select autoFocus required id="new-group-in-modal" name="id" className={css.dropdown} options={analyses.map(analysis => ({ label: analysis.name, value: analysis.id }))} />
        </FromControlWrapper>

        <div className={css.buttonContainer}>
          <Button
            kind={Button.kind.ghost}
            name="cancel"
            type="button"
            onClick={onClose}
          >Cancel</Button>
          <Button
            kind={Button.kind.ghost}
            name="create"
            type="submit"
          >Select</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Layout;
