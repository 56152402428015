import React, { PureComponent } from 'react';

import Modal from 'components/base/Modal';
import { getPopupRegistration, openPopup, closePopup } from 'app/PopupHolder';

import GraphicEditor from '.';
import css from './style.scss';


class GraphicEditorPopup extends PureComponent {
  render() {
    return (
      <Modal
        isOpen
        uniqId="GraphicEditor"
        width="1160px"
        padding="16px"
        className={css.popup}
      >
        <div>
          <GraphicEditor {...this.props} />
        </div>
      </Modal>
    );
  }
}

GraphicEditorPopup.registrationId = getPopupRegistration(GraphicEditorPopup);
GraphicEditorPopup.open = props => openPopup(GraphicEditorPopup.registrationId, props);
GraphicEditorPopup.close = () => closePopup({ popup: GraphicEditorPopup.registrationId });

export default GraphicEditorPopup;
