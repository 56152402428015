/** @flow */
const catchers = new Set();
let catchersArr = Array.from(catchers);

function isCatcher(element) {
  return catchers.has(element);
}

function isInCatcher(element) {
  return catchersArr.some(catcher => catcher.contains(element));
}

export function addListener(callback) {
  if (!document) throw new Error('addEventListener can be called only on client');
  if (!document.documentElement) throw new Error('addEventListener can be called only on client');
  document.documentElement.addEventListener('click', callback);
}

export function isOnElement(event, element, skipCatcherCheck) {
  if (!event || !event.target) return false;
  const target = event.target;
  if (!element) return false;
  if (!skipCatcherCheck) {
    if (isInCatcher(target)) return true;
    if (isCatcher(element)) return true;
  }
  return element.contains(target);
}

export function removeListener(callback) {
  if (!document) throw new Error('removeEventListener can be called only on client');
  if (!document.documentElement) throw new Error('removeEventListener can be called only on client');
  document.documentElement.removeEventListener('click', callback);
}

export function addCatcher(element) {
  catchers.add(element);
  catchersArr = Array.from(catchers);
}

export function removeCatcher(element) {
  catchers.delete(element);
  catchersArr = Array.from(catchers);
}
