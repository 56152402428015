/** @flow */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormControlWraper from 'components/base/FormControlWraper';
import css from './style.scss';


const TypeSettings = {
  digit: {
    isDigit: true,
    isCurrency: false,
    isPercent: false,
    maxWholeDigits: 10,
    minFractionDigits: 0,
    maxFractionDigits: 0,
    factor: 1,
  },
  currency: {
    isDigit: false,
    isCurrency: true,
    isPercent: false,
    maxWholeDigits: 10,
    minFractionDigits: 2,
    maxFractionDigits: 2,
    factor: 1,
  },
  percent: {
    isDigit: false,
    isCurrency: false,
    isPercent: true,
    maxWholeDigits: 3,
    minFractionDigits: 0,
    maxFractionDigits: 4,
    factor: 100,
  },
};

function getDecimalString(value, allowDecimal = true, forceValid = true) {
  // Strip any illegal characters.
  let val = String(value).replace(allowDecimal ? /[^.\d]/g : /\D/g, '');

  // Ensure there is one decimal max.
  const decimalIndex = val.indexOf('.');
  if (decimalIndex !== -1) val = `${val.substr(0, decimalIndex)}.${val.substr(decimalIndex + 1).replace(/\D/g, '')}`;

  if (forceValid) {
    // Ensure there is no leading/trailing decimal.
    val = `${val.startsWith('.') ? '0' : ''}${val}${val.endsWith('.') ? '0' : ''}`;

    // Ensure an empty string is not returned.
    if (val === '') val = '0';
  }

  return val;
}

class CustomInput extends PureComponent {
  /* :: state: Object */
  /* :: input: ?HTMLInputElement */
  /* :: isDigit: boolean */
  /* :: readOnly: boolean */
  /* :: isCurrency: boolean */
  /* :: isPercent: boolean */
  /* :: maxWholeDigits: number */
  /* :: minFractionDigits: number */
  /* :: maxFractionDigits: number */
  /* :: factor: number */

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setRef = this.setRef.bind(this);
    this.setValue = this.setValue.bind(this);

    this.input = null;
    this.state = { value: null, formattedValue: null };
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(props) {
    const { value, allowNull, name, type, factor, maxWholeDigits, maxFractionDigits, minFractionDigits } = props;

    Object.assign(this, TypeSettings[type || 'digit']);

    if (factor) this.factor = factor;
    if (maxWholeDigits >= 0) this.maxWholeDigits = maxWholeDigits;
    if (maxFractionDigits >= 0) this.maxFractionDigits = maxFractionDigits;
    if (minFractionDigits >= 0) this.minFractionDigits = minFractionDigits;

    let newValue = ((value != null && typeof value === 'object' ? value[name] : value) || (allowNull ? null : 0));
    if (newValue) newValue *= this.factor;

    this.setValue(newValue);
  }

  /* :: setRef: Function */
  setRef(ref) {
    this.input = ref;
  }

  /* :: setValue: Function */
  setValue(newValue, caretPosition = null) {
    const { input, minFractionDigits, maxFractionDigits, maxWholeDigits, isCurrency, props } = this;
    const { allowNull } = props;

    if (newValue == null || newValue === '' || isNaN(newValue)) {
      // Leave text box empty if user is editing (caretPosition != null), regardless of whether nulls are allowed.
      const value = allowNull ? null : 0;
      this.setState({ value, formattedValue: caretPosition == null && value != null ? String(value) : '' });
    } else {
      // Ensure max number of whole digits is not exceeded.
      let strValue = getDecimalString(newValue);
      let decimalIndex = strValue.indexOf('.');
      const exceededDigits = (decimalIndex === -1 ? strValue.length : decimalIndex) - maxWholeDigits;
      if (exceededDigits > 0) strValue = strValue.substr(exceededDigits);

      // Get numeric value, rounded to the max allowed fraction digits.
      const roundCoef = 10 ** maxFractionDigits;
      const value = Math.round(Number(strValue) * roundCoef) / roundCoef;

      // Get formatted number.
      let formattedValue = value.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: minFractionDigits || undefined,
        maximumFractionDigits: maxFractionDigits || undefined,
      });

      if (typeof newValue === 'string') {
        strValue = String(newValue);
        decimalIndex = formattedValue.indexOf('.');

        // Add trailing decimal (plus optional zeroes) if it was present it the value passed in; will be the case as the user is typing a fractional value.
        if (decimalIndex === -1) {
          const match = strValue.match(/.*(\.0*)$/);
          if (match) formattedValue = `${formattedValue}${match[1]}`;
        } else {
          // Add trailing zeroes following non-zero fractional digits
          const match = strValue.match(/.*\.\d*[1-9](0+)$/);
          if (match) {
            // Only add in zeroes that are not currently there. (They may already be there for currency values)
            const addZeroes = match[1].substr(0, Math.max(0, Math.min(match[1].length, (strValue.length - (strValue.indexOf('.') + 1)) - (formattedValue.length - (decimalIndex + 1)))));
            formattedValue = `${formattedValue}${addZeroes}`;
          }
        }
      }

      // Determine where caret should be placed; will be position of last character typed/deleted.
      let selectionStart = null;
      if (caretPosition != null) {
        selectionStart = 0;
        for (let digitsRemaining = caretPosition; selectionStart < formattedValue.length && digitsRemaining; selectionStart++) {
          if (/[.\d]/.test(formattedValue.charAt(selectionStart))) digitsRemaining--;
        }
      }

      // Remove ".00" from currency value, unless caret is in decimal position.
      if (isCurrency && formattedValue.endsWith('.00') && (selectionStart == null || selectionStart < formattedValue.length - 2)) formattedValue = formattedValue.substr(0, formattedValue.length - 3);

      this.setState({ value, formattedValue }, () => {
        // Set caret position.
        if (selectionStart != null && input && ('selectionStart' in input)) {
          input.selectionStart = selectionStart;
          input.selectionEnd = selectionStart;
        }
      });
    }
  }

  /* :: handleChange: Function */
  handleChange(event) {
    this.setValue(getDecimalString(event.target.value));
  }

  /* :: handleBlur: Function */
  handleBlur(event) {
    const { name } = event.target;
    const { value } = this.state;
    const newValue = value == null ? null : value / this.factor;

    // Set value again to clear any trailing decimal/zeroes
    this.setValue(value);

    // Trigger onChange if value has changed.
    if (this.props.value !== newValue) this.props.onChange({ name, value: newValue });
  }

  /* :: handleKeyDown: Function */
  handleKeyDown(event) {
    if (this.props.readOnly) return; // TODO: Allow for copy

    const { maxFractionDigits, maxWholeDigits, props } = this;
    const { name, onKeyDown } = props;
    const { target, keyCode, key, altKey, ctrlKey, metaKey, shiftKey } = event;
    const { value } = target;
    let { selectionStart, selectionEnd } = target;
    const digit = (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
    const decimal = keyCode === 190 || keyCode === 110;
    const backspace = keyCode === 8;
    const del = keyCode === 46;

    // Filter keys we might process
    if (backspace || del || ((digit || decimal) && !(altKey || ctrlKey || metaKey || shiftKey))) {
      // Assign one-character "selection" if backspace or delete is used, and there is no actual selection range.
      if (selectionStart === selectionEnd) {
        if (backspace && selectionStart) selectionStart--;
        else if (del && selectionEnd < value.length) selectionEnd++;
      }

      // Get the current input values outside the current selection. Determine what strings should end up on the left and right side of caret.
      let leftStr = getDecimalString(value.substring(0, selectionStart), true, false);
      let rightStr = getDecimalString(value.substring(selectionEnd), leftStr.indexOf('.') === -1, false);
      const deletedStr = value.substring(selectionStart, selectionEnd);
      // const origCaretPosition = leftStr.length;
      const origValue = `${leftStr}${rightStr}`;
      let decimalIndex = origValue.indexOf('.');

      let char = '';
      if (decimal && maxFractionDigits) {
        // Decimal typed while positioned on decimal. Move decimal to left of caret.
        if (rightStr.startsWith(key)) {
          char = key;
          rightStr = rightStr.substr(1);
        } else if (decimalIndex === -1) char = key;
      } else if (
        digit
        // Don't allow adding digits passed the max number of fractional digits.
        && (decimalIndex === -1 || leftStr.length - decimalIndex - 1 < maxFractionDigits)
        // Don't allow adding digits passed the max number of whole digits.
        && ((decimalIndex >= 0 && (decimalIndex < leftStr.length || decimalIndex < maxWholeDigits)) || (decimalIndex === -1 && origValue.length < maxWholeDigits))) char = key;

      // Truncate right string if the deleted section had a decimal and the user has not typed a decimal.
      if (deletedStr.indexOf('.') >= 0 && char !== '.') rightStr = '';

      // Remove leading zeroes, only allowing if the user just typed zero and that is the only character.
      leftStr = `${leftStr}${char}`.replace(/^0+((0\.)|[1-9]|$)/, '$1');
      if (leftStr === '') {
        rightStr = rightStr.replace(/^0+/, rightStr === '0' ? '' : '0');
        if (rightStr === '' && char === '0') leftStr = char;
      }

      // If the user typed a decimal and that's the only character on the left, then prefix with zero.
      if (leftStr === '.' && char === '.') leftStr = '0.';

      let newValue = `${leftStr}${rightStr}`;

      // Remove any decimal places that were pushed past the maximum.
      decimalIndex = newValue.indexOf('.');
      if (decimalIndex !== -1 && decimalIndex < newValue.length - maxFractionDigits - 1) newValue = newValue.substr(0, decimalIndex + maxFractionDigits + 1);

      // const caretPosition = leftStr.length;

      // Update state if value or caret position has changed. ** Always setting for now until change logic is right.
      // if (newValue !== origValue || caretPosition !== origCaretPosition)
      this.setValue(newValue, leftStr.length);

      if (onKeyDown) onKeyDown({ name, value: newValue });
    }

    // Prevent default, except for left/right arrows, copy, cut, paste, enter, & tab
    if (!(keyCode === 37 || keyCode === 39 || keyCode === 9 || ((ctrlKey || metaKey) && (keyCode === 67 || keyCode === 86 || keyCode === 88)))) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  render() {
    const { props, isCurrency, isPercent, setRef, handleChange, handleBlur, handleKeyDown, state: { formattedValue } } = this;
    const { id = String(Math.random()), label, placeholder, isInline, showSymbol, name, disabled, readOnly } = props;

    return (
      <FormControlWraper id={id} label={label} className={classNames({ [css.inlineControl]: isInline })}>
        {showSymbol && isCurrency ? <span className={css.symbol}>$</span> : null}
        <input
          id={id}
          type="text"
          placeholder={placeholder}
          ref={setRef}
          name={name}
          disabled={disabled}
          value={formattedValue == null ? '' : formattedValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={event => event.target.select()}
          onKeyDown={handleKeyDown}
          readOnly={readOnly}
        />
        {showSymbol && isPercent ? <span className={classNames(css.symbol, css.after)}>%</span> : null}
      </FormControlWraper>
    );
  }
}

CustomInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['digit', 'currency', 'percent']),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isInline: PropTypes.bool,
  allowNull: PropTypes.bool,
  showSymbol: PropTypes.bool,
  factor: PropTypes.number,
  maxWholeDigits: PropTypes.number,
  minFractionDigits: PropTypes.number,
  maxFractionDigits: PropTypes.number,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  readOnly: PropTypes.bool,
};

CustomInput.defaultProps = {
  isInline: false,
  allowNull: false,
  disabled: false,
  showSymbol: false,
  readOnly: false,
  type: 'digit',
};

export default CustomInput;
