import { CALL_API } from 'store/middleware/api';
import { getFormData } from 'utils/type/isFormData';


export const LOAD_GROUP_CONTEXT_CONTACT = ('LOAD_GROUP_CONTEXT_CONTACT');
export const SEARCH_GROUP_CONTEXT_CACHED_CONTACT = ('SEARCH_GROUP_CONTEXT_CACHED_CONTACT');
export const UPDATE_GROUP_CONTEXT_CONTACT = ('UPDATE_GROUP_CONTEXT_CONTACT');
export const SELECT_GROUP_CONTEXT_CONTACT = ('SELECT_GROUP_CONTEXT_CONTACT');
export const ASSIGN_CONTACTS = ('ASSIGN_CONTACTS');
export const UNASSIGN_CONTACTS = ('UNASSIGN_CONTACTS');

export const LOAD_CONTACT = ('LOAD_CONTACT');
export const LOAD_CONTACT_ERROR = ('LOAD_CONTACT_ERROR');
export const LOAD_CONTACT_SUCCESS = ('LOAD_CONTACT_SUCCESS');
export const SAVE_CONTACT = ('SAVE_CONTACT');
export const SAVE_CONTACT_SUCCESS = ('SAVE_CONTACT_SUCCESS');
export const SAVE_CONTACT_ERROR = ('SAVE_CONTACT_ERROR');
export const DELETE_CONTACT_GROUP = ('DELETE_CONTACT_GROUP');
export const DELETE_CONTACT_GROUP_SUCCESS = ('DELETE_CONTACT_GROUP_SUCCESS');
export const DELETE_CONTACT_GROUP_ERROR = ('DELETE_CONTACT_GROUP_ERROR');
export const IMPORT_CONTACTS = ('IMPORT_CONTACTS');
export const IMPORT_CONTACTS_SUCCESS = ('IMPORT_CONTACTS_SUCCESS');
export const IMPORT_CONTACTS_ERROR = ('IMPORT_CONTACTS_ERROR');
export const EXPORT_CONTACTS = ('EXPORT_CONTACTS');
export const EXPORT_CONTACTS_SUCCESS = ('EXPORT_CONTACTS_SUCCESS');
export const EXPORT_CONTACTS_ERROR = ('EXPORT_CONTACTS_ERROR');
export const APPEND_CONTACTS = ('APPEND_CONTACTS');
export const APPEND_CONTACTS_SUCCESS = ('APPEND_CONTACTS_SUCCESS');
export const APPEND_CONTACTS_ERROR = ('APPEND_CONTACTS_ERROR');
export const GET_APPEND_JOB = ('GET_APPEND_JOB');
export const GET_APPEND_JOB_SUCCESS = ('GET_APPEND_JOB_SUCCESS');
export const GET_APPEND_JOB_ERROR = ('GET_APPEND_JOB_ERROR');
export const UPDATE_APPEND_JOB = ('UPDATE_APPEND_JOB');
export const UPDATE_APPEND_JOB_SUCCESS = ('UPDATE_APPEND_JOB_SUCCESS');
export const UPDATE_APPEND_JOB_ERROR = ('UPDATE_APPEND_JOB_ERROR');
export const UPDATE_CONTACT_GROUP = ('UPDATE_CONTACT_GROUP');
export const UPDATE_CONTACT_GROUP_SUCCESS = ('UPDATE_CONTACT_GROUP_SUCCESS');
export const UPDATE_CONTACT_GROUP_ERROR = ('UPDATE_CONTACT_GROUP_ERROR');
export const SEARCH_GROUP_CONTEXT_CONTACT = ('SEARCH_GROUP_CONTEXT_CONTACT');
export const SEARCH_GROUP_CONTEXT_CONTACT_SUCCESS = ('SEARCH_GROUP_CONTEXT_CONTACT_SUCCESS');
export const SEARCH_GROUP_CONTEXT_CONTACT_ERROR = ('SEARCH_GROUP_CONTEXT_CONTACT_ERROR');
export const DELETE_GROUP_CONTEXT_CONTACT = ('DELETE_GROUP_CONTEXT_CONTACT');
export const DELETE_GROUP_CONTEXT_CONTACT_SUCCESS = ('DELETE_GROUP_CONTEXT_CONTACT_SUCCESS');
export const DELETE_GROUP_CONTEXT_CONTACT_ERROR = ('DELETE_GROUP_CONTEXT_CONTACT_ERROR');
export const SAVE_GROUP_CONTEXT_CONTACT = ('SAVE_GROUP_CONTEXT_CONTACT');
export const SAVE_GROUP_CONTEXT_CONTACT_SUCCESS = ('SAVE_GROUP_CONTEXT_CONTACT_SUCCESS');
export const SAVE_GROUP_CONTEXT_CONTACT_ERROR = ('SAVE_GROUP_CONTEXT_CONTACT_ERROR');
export const GET_CONTACT_IDS = ('GET_CONTACT_IDS');
export const GET_CONTACT_IDS_SUCCESS = ('GET_CONTACT_IDS_SUCCESS');
export const GET_CONTACT_IDS_ERROR = ('GET_CONTACT_IDS_ERROR');
export const GET_SAMPLE = ('GET_SAMPLE');
export const GET_SAMPLE_SUCCESS = ('GET_SAMPLE_SUCCESS');
export const GET_SAMPLE_ERROR = ('GET_SAMPLE_ERROR');

const request = data => ({ [CALL_API]: data });

function mapToObj(map){
  const obj = {}
  for (let [k,v] of map)
    obj[k] = v
  return obj
}

function contacts(method, path, startType, successType, errorType, body, { afterSuccess, afterError, successParams, query, download } = {}) {
  return request({
    method,
    startType,
    successType,
    errorType,
    body: body ? JSON.stringify(mapToObj(body)) : null,
    path: `/resource/auth/ps4/user/contacts/${path}`,
    afterSuccess,
    afterError,
    successParams,
    query,
    download,
    headers: { 'Content-Type': 'application/json' }
  });
}

function contactsImports(method, path, startType, successType, errorType, body, { afterSuccess, afterError, successParams, query, download } = {}) {
  return request({
    method,
    startType,
    successType,
    errorType,
    body,
    path: `/resource/auth/ps4/user/contacts/${path}`,
    afterSuccess,
    afterError,
    successParams,
    query,
    download
  });
}

function contactSave(method, path, startType, successType, errorType, body, { afterSuccess, afterError, successParams, query, download } = {} ) {
  return request({
    method,
    startType,
    successType,
    errorType,
    body: body,
    path: `/resource/auth/ps4/user/contacts/${path}`,
    afterSuccess,
    afterError,
    successParams,
    query,
    download,
    headers: { 'Content-Type': 'application/json' }
  });
}

function contactsSuccess(method, path, startType, successType, errorType, body, { afterSuccess, afterError, successParams, query, download } = {}) {
  return request({
    method,
    startType,
    successType,
    errorType,
    body: body ? JSON.stringify(mapToObj(body)) : null,
    path: `/resource/auth/ps4/user/contacts/${path}`,
    afterSuccess: (response) => {
      // Call the afterSuccess callback if provided
      if (afterSuccess) {
        afterSuccess({ response });
      }
      // Return the response
      return response;
    },
    afterError,
    successParams,
    query,
    download,
    headers: { 'Content-Type': 'application/json' }
  });
}

export const getSearch = context => context.merge({ data: null, info: null, fullSelection: null, name: context.getIn(['info', 'name']), type: context.getIn(['info', 'type']) });

export const loadContact = (id, afterSuccess) => contactsSuccess('get', `new/${id}`, LOAD_CONTACT, LOAD_CONTACT_SUCCESS, LOAD_CONTACT_ERROR, null, { afterSuccess });

export const saveContact = (contact, afterSuccess) => contactSave('put', 'new', SAVE_CONTACT, SAVE_CONTACT_SUCCESS, SAVE_CONTACT_ERROR, contact, { afterSuccess });

export const importContacts = (file, groupName, afterSuccess) => contactsImports('post', 'new', IMPORT_CONTACTS, IMPORT_CONTACTS_SUCCESS, IMPORT_CONTACTS_ERROR, getFormData([{ name: 'file', file }, { name: 'groupName', value: groupName || '' }]), { afterSuccess });

export const appendContacts = (data, paymentMethodId, cardCode, paymentAmount, creditAmount, afterSuccess) => contactSave('post', 'appendjobs', APPEND_CONTACTS, APPEND_CONTACTS_SUCCESS, APPEND_CONTACTS_ERROR, data, { afterSuccess, query: { paymentMethodId, cardCode, paymentAmount, creditAmount } });

export const getAppendJobEstimate = (data, afterSuccess) => contactSave('post', 'appendjobs/estimate', GET_APPEND_JOB, GET_APPEND_JOB_SUCCESS, GET_APPEND_JOB_ERROR, data, { afterSuccess });

export const getAppendJob = (id, afterSuccess) => contacts('get', `appendjobs/${id}`, GET_APPEND_JOB, GET_APPEND_JOB_SUCCESS, GET_APPEND_JOB_ERROR, null, { afterSuccess });

export const updateAppendJob = (job, afterSuccess) => contactSave('put', 'appendjobs', UPDATE_APPEND_JOB, UPDATE_APPEND_JOB_SUCCESS, UPDATE_APPEND_JOB_ERROR, job, { afterSuccess });

export const updateContactGroup = group => contactSave('put', 'new/groups/update', UPDATE_CONTACT_GROUP, UPDATE_CONTACT_GROUP_SUCCESS, UPDATE_CONTACT_GROUP_ERROR, group);

export const getSampleContact = ids => contactSave('post', 'sample', GET_SAMPLE, GET_SAMPLE_SUCCESS, GET_SAMPLE_ERROR, ids);

export const loadGroupContext = (name, groupId, defaults) => ({ type: LOAD_GROUP_CONTEXT_CONTACT, name, groupId, defaults });

export const searchGroupContext = (context) => {
  if (context.getIn(['info', 'data']) && context.getIn(['info', 'index'])) return { type: SEARCH_GROUP_CONTEXT_CACHED_CONTACT, context };
  return contacts('post', 'new/groups', SEARCH_GROUP_CONTEXT_CONTACT, SEARCH_GROUP_CONTEXT_CONTACT_SUCCESS, SEARCH_GROUP_CONTEXT_CONTACT_ERROR, getSearch(context).set('selection', null), { successParams: { context } });
};

export const loadContextIds = (context, selection) => contactSave('post', 'new/groups', SEARCH_GROUP_CONTEXT_CONTACT, SEARCH_GROUP_CONTEXT_CONTACT_SUCCESS, SEARCH_GROUP_CONTEXT_CONTACT_ERROR, { selection }, { successParams: { context } });

export const updateGroupContext = context => ({ type: UPDATE_GROUP_CONTEXT_CONTACT, context });

export const updateGroupContextSelection = (context, select, index) => ({ type: SELECT_GROUP_CONTEXT_CONTACT, context, select, index });

export const deleteGroupContext = (context,afterSuccess) => contacts('delete', 'new', DELETE_GROUP_CONTEXT_CONTACT, DELETE_GROUP_CONTEXT_CONTACT_SUCCESS, DELETE_GROUP_CONTEXT_CONTACT_ERROR, getSearch(context), {afterSuccess});

export const getContactIds = (id, afterSuccess) => contacts('get', `new/groups/${id}/ids`, GET_CONTACT_IDS, GET_CONTACT_IDS_SUCCESS, GET_CONTACT_IDS_ERROR, null, { afterSuccess });

export const searchContactIds = (context) => contacts('post', 'new/groups/ids', GET_CONTACT_IDS, GET_CONTACT_IDS_SUCCESS, GET_CONTACT_IDS_ERROR, getSearch(context), { afterSuccess });

export const saveGroupContext = (context, afterSuccess) => contacts('put', 'new/groups', SAVE_GROUP_CONTEXT_CONTACT, SAVE_GROUP_CONTEXT_CONTACT_SUCCESS, SAVE_GROUP_CONTEXT_CONTACT_ERROR, getSearch(context), { afterSuccess });

export const exportContacts = context => contacts('post', 'new/export', EXPORT_CONTACTS, EXPORT_CONTACTS_SUCCESS, EXPORT_CONTACTS_ERROR, getSearch(context), { download: true });

export const deleteContactGroup = (groupId,afterSuccess) => contacts('delete', `new/groups/${groupId?.substr(1)}`, DELETE_CONTACT_GROUP, DELETE_CONTACT_GROUP_SUCCESS, DELETE_CONTACT_GROUP_ERROR,null,{afterSuccess});

export const assignContext = () => ({ type: ASSIGN_CONTACTS });

export const unassignContext = () => ({ type: UNASSIGN_CONTACTS });
