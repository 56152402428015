/** @flow */
import React, { PureComponent } from 'react';
import { Form as ReduxForm, formValueSelector } from 'redux-form/immutable';
import bindToState from './bindToState';
import field from './field';
import PropTypes from 'prop-types';


class Form extends PureComponent {
  static bindToState(...rest) {
    return bindToState(...rest);
  }

  render() {
    const { children, name, onSubmit, ...rest } = this.props;
    return (
      <ReduxForm form={name} {...rest} name={name} onSubmit={onSubmit}>
        {children}
      </ReduxForm>
    );
  }
}

Form.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Form;

export {
  bindToState,
  formValueSelector,
  field,
};
