/** @flow */
import React, { PureComponent } from 'react';

import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';
import Confirm from 'app/components/Confirm';
import withProperty from 'app/components/withProperty';
import { selectProperty } from 'data/property';
import {
  selectAnalysesOptions,
  selectIsLoading,
  selectActiveAnalysisId,
  selectAnalysisParams,
  selectMortgageEditIndex,
} from 'data/analyses/selectors';
import {
  loadAnalyses,
  deleteAnalysis,
  newAnalysis,
  changeActiveAnalysis,
  changeAnalysisParams,
  changeAnalysisDetailsParams,
  changeAnalysisManualTotal,
  clearAnalysisChanges,
  saveAnalysis,
} from 'data/analyses/actions';
// TODO: Needed? Analysis should be able to run independently of a property.
import UrlStorage from 'app/Property/Detail/UrlStorage';
import Analysis from 'vendor/Analysis';
import { selectCurrentPathname } from './selectors';
import Layout from './Layout';
import ModalAnalysisSave from './ModalAnalysisSave/index';
import { withRouter } from 'react-router-dom';


const steps = [
  { name: 'Purchase', link: 'purchase' },
  { name: 'Mortgage', link: 'mortgage' },
  { name: 'Income', link: 'income', disabled: analysisParams => analysisParams.propertyType === 'PERSONAL' },
  { name: 'Expenses', link: 'expenses' },
  { name: 'Sale', link: 'sale' },
  { name: 'Finish', link: 'finish' },
];

function findStepIndexByPath(path) {
  return Math.max(0, steps.findIndex(link => link.link === path));
}

function getChangeData(event) {
  return event.target ? { name: event.target.name, value: event.target.value } : event;
}

class ModalAnalysis extends PureComponent {
  constructor(props) {
    super(props);

    this.handlePreviousClick = this.handlePreviousClick.bind(this);
    this.handleAnalysisSaveClick = this.handleAnalysisSaveClick.bind(this);
    this.handleActiveAnalysisChange = this.handleActiveAnalysisChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleAnalysisRenameClick = this.handleAnalysisRenameClick.bind(this);
    this.handleAnalysisDeleteClick = this.handleAnalysisDeleteClick.bind(this);
    this.openDefaultRoute = this.openDefaultRoute.bind(this);
    this.handleParamsChange = this.handleParamsChange.bind(this);
    this.handleNumberParamsChange = this.handleNumberParamsChange.bind(this);
    this.handleManualTotalChange = this.handleManualTotalChange.bind(this);
    this.handleBooleanFieldChange = this.handleBooleanFieldChange.bind(this);
    this.handleDetailsChange = this.handleDetailsChange.bind(this);
    this.handleNewAnalysisClick = this.handleNewAnalysisClick.bind(this);
    this.moveStep = this.moveStep.bind(this);

     // Extract the last segment of the URL path and the remaining path
  const pathSegments = props.location.pathname.split('/');
  const childPath = pathSegments.pop();
  // const parentPath = pathSegments.join('/');

    this.analysis = new Analysis();
    this.state = {
      activeStepIndex: findStepIndexByPath(childPath),
      savedPropertyAction: null,
      childPath : childPath,
      parentPath : pathSegments.join('/')
    };
  }

  /* :: analysis: Object */

  componentWillMount() {
    this.handleNewAnalysisClick();
  }

  componentDidMount() {
    const { savedPropertyId, loadAnalyses } = this.props;
    if (savedPropertyId) loadAnalyses(savedPropertyId);
  }

  componentWillReceiveProps(newProps) {
    const { savedPropertyAction } = this.state;
    const { currentPathname, analysisParams, savedPropertyId } = newProps;

      // Extract the last segment of the URL path and the remaining path
  const pathSegments = newProps.location.pathname.split('/');
  const childPath = pathSegments.pop();

    if (newProps.location.pathname !== this.props.location.pathname) {
      this.state = {
        activeStepIndex: findStepIndexByPath(childPath),
        childPath : childPath,
        parentPath : pathSegments.join('/')
      };
    }
    if (analysisParams !== this.props.analysisParams) this.analysis = new Analysis(analysisParams.toJS(), null);

    if (savedPropertyAction && savedPropertyId && savedPropertyId !== this.props.savedPropertyId) savedPropertyAction(savedPropertyId);
  }

  getFullTabLink(stepIndex) {
    const { match: {params}, url } = this.props;
    const {parentPath} = this.state;
    const tabLink = steps[stepIndex].link;
    return `${parentPath}/${tabLink}`;
  }

  /* :: openDefaultRoute: Function */
  openDefaultRoute() {
    const { match: {params}, url } = this.props;
    const {parentPath , childPath} = this.state;
    this.props.history.push(`${parentPath}/${childPath}`);
  }

  /* :: moveStep: Function */
  moveStep(forward) {
    const { activeStepIndex } = this.state;

    // If the user is editing a mortgage and hits Next we'll just clear the edit flag, causing the wizard to load the mortgage list.
    if (forward && steps[activeStepIndex].link === 'mortgage' && this.props.analysisParams.get('mortgageEditIndex') != null) {
      this.props.changeAnalysisParams({ name: 'mortgageEditIndex', value: null });
    } else {
      const offset = forward ? 1 : -1;
      for (let stepIndex = activeStepIndex + offset; stepIndex >= 0 && stepIndex < steps.length; stepIndex += offset) {
        const step = steps[stepIndex];
        if (!(step.disabled && step.disabled(this.analysis))) {
          this.props.history.push(this.getFullTabLink(stepIndex));
          return;
        }
      }
    }
  }

  /* :: handlePreviousClick: Function */
  handlePreviousClick() {
    this.moveStep(false);
  }

  /* :: handleNextClick: Function */
  handleNextClick() {
    this.moveStep(true);
  }

  /* :: handleActiveAnalysisChange: Function */
  handleActiveAnalysisChange(event) {
    this.props.changeActiveAnalysis(Number(event.target.value));
  }

  /* :: handleAnalysisRenameClick: Function */
  handleAnalysisRenameClick() {
    const { openSavePopup, analysisParams, savedPropertyId } = this.props;
    const id = analysisParams.get('id');
    if (id) openSavePopup({ rename: true, id, savedPropertyId, name: analysisParams.get('name') });
  }

  /* :: handleAnalysisDeleteClick: Function */
  handleAnalysisDeleteClick() {
    const { confirmAction, deleteAnalysis, analysisParams } = this.props;
    if (analysisParams.get('id')) {
      confirmAction({
        question: 'Do you want to delete analysis?',
        onOk: () => deleteAnalysis(analysisParams.get('id')),
      });
    }
  }

  /* :: handleAnalysisSaveClick: Function */
  handleAnalysisSaveClick() {
    const { analysisParams, saveAnalysis, openSavePopup, savedPropertyId, checkSavedProperty } = this.props;
    checkSavedProperty(() => {
      if (analysisParams.get('id')) saveAnalysis({ ...new Analysis(analysisParams.toJS()) });
      else openSavePopup({ savedPropertyId });
    });
  }

  /* :: handleNewAnalysisClick: Function */
  handleNewAnalysisClick() {
    const { newAnalysis, property } = this.props;
    newAnalysis(property);
    this.openDefaultRoute();
  }

  /* :: handleParamsChange: Function */
  handleParamsChange(event) {
    this.props.changeAnalysisParams(getChangeData(event));
  }

  /* :: handleBooleanFieldChange: Function */
  handleBooleanFieldChange(event) {
    const data = getChangeData(event);
    this.handleParamsChange(Object.assign(data, { value: data.value === 'true' }));
  }

  /* :: handleNumberParamsChange: Function */
  handleNumberParamsChange(event) {
    const data = getChangeData(event);
    const { changeAnalysisParams } = this.props;
    changeAnalysisParams(Object.assign({ ...data }, { value: Number(data.value) || 0, oneYearPeriod: this.analysis.oneYearPeriod }));
  }

  /* :: handleDetailsChange: Function */
  handleDetailsChange(event, prop, id) {
    const data = getChangeData(event);
    this.props.changeAnalysisDetailsParams(prop, id, Object.assign(data, { value: Number(data.value) || 0 }));
  }

  /* :: handleManualTotalChange: Function */
  handleManualTotalChange(event, prop) {
    const { analysisParams } = this.props;
    const { value } = event.target || event;

    if (!analysisParams.get('detailStatus').get(prop) || confirm('Changing this amount will delete any detailed amounts you have entered. Would you like to continue?')) {
      this.props.changeAnalysisManualTotal(prop, Number(value));
    } else this.props.clearAnalysisChanges();
  }
/* TODO: Get skipIfTrue to work and keep it from clearing the current manual value immediately.
  handleManualTotalChange(event: Object, prop: string) {
    const { value } = event.target || event;
    const { confirmAction, changeAnalysisManualTotal, clearAnalysisChanges, analysisParams } = this.props;

    confirmAction({
      question: 'Changing this amount will delete any detailed amounts you have entered. Would you like to continue?',
      onOk: () => changeAnalysisManualTotal(prop, Number(value)),
      onCancel: () => clearAnalysisChanges(),
      skipIfTrue: () => !analysisParams.get(prop).find(detail => detail.get('amount') && !detail.getIn(['field', 'manual'])),
    });
  }*/

  render() {
    const { activeStepIndex } = this.state;
    const fullTabLink = this.getFullTabLink(activeStepIndex);
    const breadcrumbs = steps.map((step, index) => ({
      ...step,
      link: this.getFullTabLink(index),
      isActive: index === activeStepIndex,
      isPast: index < activeStepIndex,
      disabled: step.disabled && step.disabled(this.analysis),
    }));

    return (
      <UrlStorage urls={this.props.urls}>
        <Layout
          {...this.props}
          breadcrumbs={breadcrumbs}
          onNewAnalysisClick={this.handleNewAnalysisClick}
          onNextClick={this.handleNextClick}
          onPreviousClick={this.handlePreviousClick}
          onAnalysisRenameClick={this.handleAnalysisRenameClick}
          onAnalysisDeleteClick={this.handleAnalysisDeleteClick}
          nextEnabled={activeStepIndex + 1 < steps.length}
          backEnabled={activeStepIndex > 0}
          activeStepIndex={activeStepIndex}
          tabLink={fullTabLink}
          handleAnalysisSaveClick={this.handleAnalysisSaveClick}
          analysisParams={this.analysis}
          onActiveAnalysisChange={this.handleActiveAnalysisChange}
          openDefaultRoute={this.openDefaultRoute}
          onParamsChange={this.handleParamsChange}
          onBooleanFieldChange={this.handleBooleanFieldChange}
          onDetailsChange={this.handleDetailsChange}
          onNumberParamsChange={this.handleNumberParamsChange}
          onManualTotalChange={this.handleManualTotalChange}
        />
      </UrlStorage>
    );
  }
}

function mapStateToProps(state) {
  // Hack until selectAnalysesOptions is fixed. Loads other props' analyses after a save.
  const property = selectProperty(state);

  return {
    analysesOptions: selectAnalysesOptions(state, property.get('savedPropertyId')),
    isAnalysesLoading: selectIsLoading(state),
    currentPathname: selectCurrentPathname(state),
    activeAnalysisId: selectActiveAnalysisId(state),
    mortgageEditIndex: selectMortgageEditIndex(state),
    analysisParams: selectAnalysisParams(state),
  };
}
const mapActionToProps = {
  loadAnalyses,
  deleteAnalysis,
  newAnalysis,
  changeActiveAnalysis,
  changeAnalysisParams,
  changeAnalysisManualTotal,
  changeAnalysisDetailsParams,
  clearAnalysisChanges,
  openSavePopup: ModalAnalysisSave.open,
  confirmAction: Confirm.open,
  saveAnalysis,
};

const ConnectedModal = withRouter(withProperty(ModalAnalysis, mapStateToProps, mapActionToProps));

const registrationId = getPopupRegistration(ConnectedModal);

(ConnectedModal).open = (props, ...rest) => openPopup(registrationId, { priority: Priority.HIGH, ...props }, ...rest);
(ConnectedModal).close = () => closePopup({ popup: registrationId });

export default ConnectedModal;
