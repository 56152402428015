import React, { PureComponent } from 'react';
import classNames from 'classnames';
import LoadablePanel from '../hoc/loadable/Panel';
import css from './style.scss';
import PropTypes from 'prop-types';

class SearchItem extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.tileName);
  }

  render() {
    const { label, value, isActive, onClick, className, disabled, loading } = this.props;
    const buttonProps = {
      onClick: this.onClick,
      className: classNames(className, { [css.active]: isActive }, css.searchItem),
      disabled: !onClick || disabled,
      title: label,
    };

    return (
      <button type="button" {...buttonProps}>
        <div className={classNames(css.searchInner, { [css.loading]: loading })}>
          <LoadablePanel loading={loading}>
            <div className={css.value}>{value}</div>
          </LoadablePanel>
          <div className={css.label}>{label}</div>
          {/* <NewFeature visible={!loading && newFeature} center />*/}
        </div>
      </button>
    );
  }
}

SearchItem.propTypes = {
  label: PropTypes.string.isRequired,
  tileName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isActive: PropTypes.bool,
  loading: PropTypes.bool,
  newFeature: PropTypes.bool,
};

SearchItem.defaultProps = {
  value: null,
  isActive: false,
  loading: false,
  newFeature: false,
};


export default SearchItem;
