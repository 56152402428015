const AdjustableRateMortgageHybridTypes = {
  ARM_10_1: 'ARM_10_1',
  ARM_7_1: 'ARM_7_1',
  ARM_5_1: 'ARM_5_1',
  ARM_3_1: 'ARM_3_1',
  ARM_1_YEAR: 'ARM_1_YEAR',
  CUSTOM: 'CUSTOM',
};

export default AdjustableRateMortgageHybridTypes;
