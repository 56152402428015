import AdjustableRateMortgageHybridTypes from './AdjustableRateMortgageHybridTypes';


export default function getArmHybridTerm(type) { // eslint-disable-line consistent-return
  switch (type) { // eslint-disable-line default-case
    case AdjustableRateMortgageHybridTypes.ARM_10_1:
      return 120;
    case AdjustableRateMortgageHybridTypes.ARM_7_1:
      return 84;
    case AdjustableRateMortgageHybridTypes.ARM_5_1:
      return 60;
    case AdjustableRateMortgageHybridTypes.ARM_3_1:
      return 36;
    case AdjustableRateMortgageHybridTypes.ARM_1_YEAR:
      return 12;
  }
}
