import { CALL_API } from 'store/middleware/api';
import isFormData from 'utils/type/isFormData';


export const LOAD_VOICEMAIL_CAMPAIGN = ('LOAD_VOICEMAIL_CAMPAIGN');
export const LOAD_CAMPAIGN = ('LOAD_CAMPAIGN');
export const LOAD_CAMPAIGN_SUCCESS = ('LOAD_CAMPAIGN_SUCCESS');
export const LOAD_CAMPAIGN_ERROR = ('LOAD_CAMPAIGN_ERROR');
export const SAVE_CAMPAIGN = ('SAVE_CAMPAIGN');
export const SAVE_CAMPAIGN_SUCCESS = ('SAVE_CAMPAIGN_SUCCESS');
export const SAVE_CAMPAIGN_ERROR = ('SAVE_CAMPAIGN_ERROR');
export const CHECK_WEBSITE_AVAILABILITY = ('CHECK_WEBSITE_AVAILABILITY');
export const CHECK_WEBSITE_AVAILABILITY_SUCCESS = ('CHECK_WEBSITE_AVAILABILITY_SUCCESS');
export const CHECK_WEBSITE_AVAILABILITY_ERROR = ('CHECK_WEBSITE_AVAILABILITY_ERROR');
export const LOAD_WEBSITE_TEMPLATES = ('LOAD_WEBSITE_TEMPLATES');
export const LOAD_WEBSITE_TEMPLATES_SUCCESS = ('LOAD_WEBSITE_TEMPLATES_SUCCESS');
export const LOAD_WEBSITE_TEMPLATES_ERROR = ('LOAD_WEBSITE_TEMPLATES_ERROR');
export const SAVE_WEBSITE = ('SAVE_WEBSITE');
export const SAVE_WEBSITE_SUCCESS = ('SAVE_WEBSITE_SUCCESS');
export const SAVE_WEBSITE_ERROR = ('SAVE_WEBSITE_ERROR');
export const GET_VOICEMAIL_AUDIO_PIN = ('GET_VOICEMAIL_AUDIO_PIN_CAMPAIGNS');
export const GET_VOICEMAIL_AUDIO_PIN_SUCCESS = ('GET_VOICEMAIL_AUDIO_PIN_SUCCESS');
export const GET_VOICEMAIL_AUDIO_PIN_ERROR = ('GET_VOICEMAIL_AUDIO_PIN_ERROR');
export const LOAD_VOICEMAIL_CAMPAIGNS = ('LOAD_VOICEMAIL_CAMPAIGNS');
export const LOAD_VOICEMAIL_CAMPAIGNS_SUCCESS = ('LOAD_VOICEMAIL_CAMPAIGNS_SUCCESS');
export const LOAD_VOICEMAIL_CAMPAIGNS_ERROR = ('LOAD_VOICEMAIL_CAMPAIGNS_ERROR');
export const SAVE_VOICEMAIL_CAMPAIGN = ('SAVE_VOICEMAIL_CAMPAIGN');
export const SAVE_VOICEMAIL_CAMPAIGN_SUCCESS = ('SAVE_VOICEMAIL_CAMPAIGN_SUCCESS');
export const SAVE_VOICEMAIL_CAMPAIGN_ERROR = ('SAVE_VOICEMAIL_CAMPAIGN_ERROR');
export const PREVIEW_VOICEMAIL_CAMPAIGN = ('PREVIEW_VOICEMAIL_CAMPAIGN');
export const PREVIEW_VOICEMAIL_CAMPAIGN_SUCCESS = ('PREVIEW_VOICEMAIL_CAMPAIGN_SUCCESS');
export const PREVIEW_VOICEMAIL_CAMPAIGN_ERROR = ('PREVIEW_VOICEMAIL_CAMPAIGN_ERROR');
export const DELETE_VOICEMAIL_CAMPAIGN = ('DELETE_VOICEMAIL_CAMPAIGN');
export const DELETE_VOICEMAIL_CAMPAIGN_SUCCESS = ('DELETE_VOICEMAIL_CAMPAIGN_SUCCESS');
export const DELETE_VOICEMAIL_CAMPAIGN_ERROR = ('DELETE_VOICEMAIL_CAMPAIGN_ERROR');
export const LOAD_VOICEMAIL_CALLER_IDS = ('LOAD_VOICEMAIL_CALLER_IDS');
export const LOAD_VOICEMAIL_CALLER_IDS_SUCCESS = ('LOAD_VOICEMAIL_CALLER_IDS_SUCCESS');
export const LOAD_VOICEMAIL_CALLER_IDS_ERROR = ('LOAD_VOICEMAIL_CALLER_IDS_ERROR');
export const SAVE_VOICEMAIL_CALLER_ID = ('SAVE_VOICEMAIL_CALLER_ID');
export const SAVE_VOICEMAIL_CALLER_ID_SUCCESS = ('SAVE_VOICEMAIL_CALLER_ID_SUCCESS');
export const SAVE_VOICEMAIL_CALLER_ID_ERROR = ('SAVE_VOICEMAIL_CALLER_ID_ERROR');
export const DELETE_VOICEMAIL_CALLER_ID = ('DELETE_VOICEMAIL_CALLER_ID');
export const DELETE_VOICEMAIL_CALLER_ID_SUCCESS = ('DELETE_VOICEMAIL_CALLER_ID_SUCCESS');
export const DELETE_VOICEMAIL_CALLER_ID_ERROR = ('DELETE_VOICEMAIL_CALLER_ID_ERROR');
export const LOAD_VOICEMAIL_RECORDINGS = ('LOAD_VOICEMAIL_RECORDINGS');
export const LOAD_VOICEMAIL_RECORDINGS_SUCCESS = ('LOAD_VOICEMAIL_RECORDINGS_SUCCESS');
export const LOAD_VOICEMAIL_RECORDINGS_ERROR = ('LOAD_VOICEMAIL_RECORDINGS_ERROR');
export const SAVE_VOICEMAIL_RECORDING = ('SAVE_VOICEMAIL_RECORDING');
export const SAVE_VOICEMAIL_RECORDING_SUCCESS = ('SAVE_VOICEMAIL_RECORDING_SUCCESS');
export const SAVE_VOICEMAIL_RECORDING_ERROR = ('SAVE_VOICEMAIL_RECORDING_ERROR');
export const DELETE_VOICEMAIL_RECORDING = ('DELETE_VOICEMAIL_RECORDING');
export const DELETE_VOICEMAIL_RECORDING_SUCCESS = ('DELETE_VOICEMAIL_RECORDING_SUCCESS');
export const DELETE_VOICEMAIL_RECORDING_ERROR = ('DELETE_VOICEMAIL_RECORDING_ERROR');
export const LOAD_VOICEMAIL_SCHEDULES = ('LOAD_VOICEMAIL_SCHEDULES');
export const LOAD_VOICEMAIL_SCHEDULES_SUCCESS = ('LOAD_VOICEMAIL_SCHEDULES_SUCCESS');
export const LOAD_VOICEMAIL_SCHEDULES_ERROR = ('LOAD_VOICEMAIL_SCHEDULES_ERROR');
export const SAVE_VOICEMAIL_SCHEDULE = ('SAVE_VOICEMAIL_SCHEDULE');
export const SAVE_VOICEMAIL_SCHEDULE_SUCCESS = ('SAVE_VOICEMAIL_SCHEDULE_SUCCESS');
export const SAVE_VOICEMAIL_SCHEDULE_ERROR = ('SAVE_VOICEMAIL_SCHEDULE_ERROR');
export const DELETE_VOICEMAIL_SCHEDULE = ('DELETE_VOICEMAIL_SCHEDULE');
export const DELETE_VOICEMAIL_SCHEDULE_SUCCESS = ('DELETE_VOICEMAIL_SCHEDULE_SUCCESS');
export const DELETE_VOICEMAIL_SCHEDULE_ERROR = ('DELETE_VOICEMAIL_SCHEDULE_ERROR');
export const DOWNLOAD_VOICEMAIL_DETAIL = ('DOWNLOAD_VOICEMAIL_DETAIL');
export const DOWNLOAD_VOICEMAIL_DETAIL_SUCCESS = ('DOWNLOAD_VOICEMAIL_DETAIL_SUCCESS');
export const DOWNLOAD_VOICEMAIL_DETAIL_ERROR = ('DOWNLOAD_VOICEMAIL_DETAIL_ERROR');
export const VOICEMAIL_CAMPAIGN_CHANGE = ('VOICEMAIL_CAMPAIGN_CHANGE');

export const LOAD_POSTCARD_CAMPAIGN = ('LOAD_POSTCARD_CAMPAIGN');
export const LOAD_POSTCARD_CAMPAIGN_SUCCESS = ('LOAD_POSTCARD_CAMPAIGN_SUCCESS');
export const LOAD_POSTCARD_CAMPAIGN_ERROR = ('LOAD_POSTCARD_CAMPAIGN_ERROR');
export const GET_POSTCARD_CAMPAIGN = ('GET_POSTCARD_CAMPAIGN');
export const GET_POSTCARD_CAMPAIGN_SUCCESS = ('GET_POSTCARD_CAMPAIGN_SUCCESS');
export const GET_POSTCARD_CAMPAIGN_ERROR = ('GET_POSTCARD_CAMPAIGN_ERROR');
export const LOAD_POSTCARD_TEMPLATES = ('LOAD_POSTCARD_TEMPLATES');
export const LOAD_POSTCARD_TEMPLATES_SUCCESS = ('LOAD_POSTCARD_TEMPLATES_SUCCESS');
export const LOAD_POSTCARD_TEMPLATES_ERROR = ('LOAD_POSTCARD_TEMPLATES_ERROR');
export const SAVE_POSTCARD_CAMPAIGN = ('SAVE_POSTCARD_CAMPAIGN');
export const SAVE_POSTCARD_CAMPAIGN_SUCCESS = ('SAVE_POSTCARD_CAMPAIGN_SUCCESS');
export const SAVE_POSTCARD_CAMPAIGN_ERROR = ('SAVE_POSTCARD_CAMPAIGN_ERROR');
export const PREVIEW_POSTCARD_CAMPAIGN = ('PREVIEW_POSTCARD_CAMPAIGN');
export const PREVIEW_POSTCARD_CAMPAIGN_SUCCESS = ('PREVIEW_POSTCARD_CAMPAIGN_SUCCESS');
export const PREVIEW_POSTCARD_CAMPAIGN_ERROR = ('PREVIEW_POSTCARD_CAMPAIGN_ERROR');
export const DELETE_POSTCARD_CAMPAIGN = ('DELETE_POSTCARD_CAMPAIGN');
export const DELETE_POSTCARD_CAMPAIGN_SUCCESS = ('DELETE_POSTCARD_CAMPAIGN_SUCCESS');
export const DELETE_POSTCARD_CAMPAIGN_ERROR = ('DELETE_POSTCARD_CAMPAIGN_ERROR');
export const DOWNLOAD_POSTCARD_CAMPAIGN_PDF = ('DOWNLOAD_POSTCARD_CAMPAIGN_PDF');
export const DOWNLOAD_POSTCARD_CAMPAIGN_PDF_SUCCESS = ('DOWNLOAD_POSTCARD_CAMPAIGN_PDF_SUCCESS');
export const DOWNLOAD_POSTCARD_CAMPAIGN_PDF_ERROR = ('DOWNLOAD_POSTCARD_CAMPAIGN_PDF_ERROR');
export const DOWNLOAD_POSTCARD_DETAIL = ('DOWNLOAD_POSTCARD_DETAIL');
export const DOWNLOAD_POSTCARD_DETAIL_SUCCESS = ('DOWNLOAD_POSTCARD_DETAIL_SUCCESS');
export const DOWNLOAD_POSTCARD_DETAIL_ERROR = ('DOWNLOAD_POSTCARD_DETAIL_ERROR');
export const CLEAR_POSTCARD_CAMPAIGN = ('CLEAR_POSTCARD_CAMPAIGN');
export const POSTCARD_CAMPAIGN_CHANGE = ('POSTCARD_CAMPAIGN_CHANGE');
export const UPDATE_WEBSITE = ('UPDATE_WEBSITE');
export const LOAD_WEBSITE = ('LOAD_WEBSITE');
export const GENERATE_MAILING_LABELS = ('GENERATE_MAILING_LABELS');
export const GENERATE_MAILING_LABELS_SUCCESS = ('GENERATE_MAILING_LABELS_SUCCESS');
export const GENERATE_MAILING_LABELS_ERROR = ('GENERATE_MAILING_LABELS_ERROR');

function mapToObj(map){
  const obj = {}
  for (let [k,v] of map)
    obj[k] = v
  return obj
}

function marketing(type, method, path, startType, successType, errorType, body, { afterSuccess, afterError, successParams, download = false, query } = {}) {
  return {
    [CALL_API]: {
      method,
      startType,
      successType,
      errorType,
      body: body ? (typeof body === 'object' && body.hasOwnProperty('__altered') ? JSON.stringify(mapToObj(body)) : body) : null,
      afterSuccess,
      afterError,
      successParams,
      query,
      download,
      path: `/resource/auth/ps4/user/marketing${type ? `/${type}` : ''}${path ? `/${path}` : ''}`,
      // ...(method === 'post' && !isFormData(body) ? { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } } : {}),
      headers: { 'Content-Type': 'application/json' }
    },
  };
}

function marketingSave(type, method, path, startType, successType, errorType, body, { afterSuccess, afterError, successParams, download = false, query } = {}) {
  return {
    [CALL_API]: {
      method,
      startType,
      successType,
      errorType,
      body,
      afterSuccess,
      afterError,
      successParams,
      query,
      download,
      path: `/resource/auth/ps4/user/marketing${type ? `/${type}` : ''}${path ? `/${path}` : ''}`,
    },
  };
}

export const loadCampaign = id => marketing(null, 'get', id, LOAD_CAMPAIGN, LOAD_CAMPAIGN_SUCCESS, LOAD_CAMPAIGN_ERROR);

export const saveCampaign = (campaign, afterSuccess) => marketing(null, 'put', null, SAVE_CAMPAIGN, SAVE_CAMPAIGN_SUCCESS, SAVE_CAMPAIGN_ERROR, campaign, { afterSuccess });

export const checkWebsiteAvailability = (id, path, afterSuccess) => marketing(null, 'get', `website/check${id ? `/${id}` : ''}/${path}`, CHECK_WEBSITE_AVAILABILITY, CHECK_WEBSITE_AVAILABILITY_SUCCESS, CHECK_WEBSITE_AVAILABILITY_ERROR, null, { afterSuccess });

export const saveWebsite = (id, website, afterSuccess) => marketing(null, 'put', `${id}/website`, SAVE_WEBSITE, SAVE_WEBSITE_SUCCESS, SAVE_WEBSITE_ERROR, website, { afterSuccess });

export const loadWebsiteTemplates = () => marketing(null, 'get', 'website/templates', LOAD_WEBSITE_TEMPLATES, LOAD_WEBSITE_TEMPLATES_SUCCESS, LOAD_WEBSITE_TEMPLATES_ERROR);

const voicemail = (method, path, startType, successType, errorType, body, params) => marketing('voicemail', method, path, startType, successType, errorType, body, params);

const postcard = (method, path, startType, successType, errorType, body, params) => marketingSave('postcard', method, path, startType, successType, errorType, body, params);

export const loadVoicemailCampaign = id => ({ type: LOAD_VOICEMAIL_CAMPAIGN, id });

export const getVoicemailAudioPin = (create, afterSuccess) => voicemail('get', 'audiopin', GET_VOICEMAIL_AUDIO_PIN, GET_VOICEMAIL_AUDIO_PIN_SUCCESS, GET_VOICEMAIL_AUDIO_PIN_ERROR, null, { afterSuccess, query: { create } });

export const loadVoicemailCampaigns = () => voicemail('get', 'campaigns', LOAD_VOICEMAIL_CAMPAIGNS, LOAD_VOICEMAIL_CAMPAIGNS_SUCCESS, LOAD_VOICEMAIL_CAMPAIGNS_ERROR);

export const saveVoicemailCampaign = (campaign, duplicate, paymentMethodId, cardCode, creditAmount, paymentAmount, afterSuccess) => voicemail('put', 'campaigns', SAVE_VOICEMAIL_CAMPAIGN, SAVE_VOICEMAIL_CAMPAIGN_SUCCESS, SAVE_VOICEMAIL_CAMPAIGN_ERROR, campaign, { afterSuccess, query: { duplicate, paymentMethodId, cardCode, creditAmount, paymentAmount } });

export const previewVoicemailCampaign = (campaign, estimate, afterSuccess) => voicemail('post', 'preview', PREVIEW_VOICEMAIL_CAMPAIGN, PREVIEW_VOICEMAIL_CAMPAIGN_SUCCESS, PREVIEW_VOICEMAIL_CAMPAIGN_ERROR, campaign, { afterSuccess, query: { estimate } });

export const deleteVoicemailCampaign = id => voicemail('delete', `campaigns/${id}`, DELETE_VOICEMAIL_CAMPAIGN, DELETE_VOICEMAIL_CAMPAIGN_SUCCESS, DELETE_VOICEMAIL_CAMPAIGN_ERROR);

export const loadVoicemailCallerIds = () => voicemail('get', 'callerids', LOAD_VOICEMAIL_CALLER_IDS, LOAD_VOICEMAIL_CALLER_IDS_SUCCESS, LOAD_VOICEMAIL_CALLER_IDS_ERROR);

export const saveVoicemailCallerId = phone => voicemail('post', 'callerids', SAVE_VOICEMAIL_CALLER_ID, SAVE_VOICEMAIL_CALLER_ID_SUCCESS, SAVE_VOICEMAIL_CALLER_ID_ERROR, { phone });

export const deleteVoicemailCallerId = id => voicemail('delete', `callerids/${id}`, DELETE_VOICEMAIL_CALLER_ID, DELETE_VOICEMAIL_CALLER_ID_SUCCESS, DELETE_VOICEMAIL_CALLER_ID_ERROR);

export const loadVoicemailRecordings = () => voicemail('get', 'recordings', LOAD_VOICEMAIL_RECORDINGS, LOAD_VOICEMAIL_RECORDINGS_SUCCESS, LOAD_VOICEMAIL_RECORDINGS_ERROR);

export const saveVoicemailRecording = recording => voicemail(isFormData(recording) ? 'post' : 'put', 'recordings', SAVE_VOICEMAIL_RECORDING, SAVE_VOICEMAIL_RECORDING_SUCCESS, SAVE_VOICEMAIL_RECORDING_ERROR, recording);

export const deleteVoicemailRecording = id => voicemail('delete', `recordings/${id}`, DELETE_VOICEMAIL_RECORDING, DELETE_VOICEMAIL_RECORDING_SUCCESS, DELETE_VOICEMAIL_RECORDING_ERROR);

export const loadVoicemailSchedules = () => voicemail('get', 'schedules', LOAD_VOICEMAIL_SCHEDULES, LOAD_VOICEMAIL_SCHEDULES_SUCCESS, LOAD_VOICEMAIL_SCHEDULES_ERROR);

export const saveVoicemailSchedule = schedule => voicemail('put', 'schedules', SAVE_VOICEMAIL_SCHEDULE, SAVE_VOICEMAIL_SCHEDULE_SUCCESS, SAVE_VOICEMAIL_SCHEDULE_ERROR, schedule);

export const deleteVoicemailSchedule = id => voicemail('delete', `schedules/${id}`, DELETE_VOICEMAIL_SCHEDULE, DELETE_VOICEMAIL_SCHEDULE_SUCCESS, DELETE_VOICEMAIL_SCHEDULE_ERROR);

export const downloadVoicemailDetail = id => voicemail('get', `${id}/detail`, DOWNLOAD_VOICEMAIL_DETAIL, DOWNLOAD_VOICEMAIL_DETAIL_SUCCESS, DOWNLOAD_VOICEMAIL_DETAIL_ERROR, null, { download: true });

export const voicemailCampaignChange = (name, value) => ({ type: VOICEMAIL_CAMPAIGN_CHANGE, name, value });

export const loadPostcardCampaign = id => (id > 0 ? postcard('get', `campaigns/${id}`, LOAD_POSTCARD_CAMPAIGN, LOAD_POSTCARD_CAMPAIGN_SUCCESS, LOAD_POSTCARD_CAMPAIGN_ERROR) : { type: CLEAR_POSTCARD_CAMPAIGN });

export const getPostcardCampaign = (id, afterSuccess) => postcard('get', `campaigns/${id}`, GET_POSTCARD_CAMPAIGN, GET_POSTCARD_CAMPAIGN_SUCCESS, GET_POSTCARD_CAMPAIGN_ERROR, null, { afterSuccess });

export const loadPostcardTemplates = () => postcard('get', 'templatesnew', LOAD_POSTCARD_TEMPLATES, LOAD_POSTCARD_TEMPLATES_SUCCESS, LOAD_POSTCARD_TEMPLATES_ERROR);

export const savePostcardCampaign = (campaign, duplicate, paymentMethodId, cardCode, paymentAmount, creditAmount, afterSuccess) => postcard('put', 'campaigns/new', SAVE_POSTCARD_CAMPAIGN, SAVE_POSTCARD_CAMPAIGN_SUCCESS, SAVE_POSTCARD_CAMPAIGN_ERROR, campaign, { afterSuccess, query: { duplicate, paymentMethodId, cardCode, paymentAmount, creditAmount } });

export const previewPostcardCampaign = (campaign, estimate, afterSuccess) => postcard('post', 'preview', PREVIEW_POSTCARD_CAMPAIGN, PREVIEW_POSTCARD_CAMPAIGN_SUCCESS, PREVIEW_POSTCARD_CAMPAIGN_ERROR, campaign, { afterSuccess, query: { estimate } });

export const downloadPostcardCampaignPdf = campaign => postcard('post', 'pdf', DOWNLOAD_POSTCARD_CAMPAIGN_PDF, DOWNLOAD_POSTCARD_CAMPAIGN_PDF_SUCCESS, DOWNLOAD_POSTCARD_CAMPAIGN_PDF_ERROR, campaign, { download: true });

export const downloadPostcardCampaignPdfById = id => postcard('get', `pdf/${id}`, DOWNLOAD_POSTCARD_CAMPAIGN_PDF, DOWNLOAD_POSTCARD_CAMPAIGN_PDF_SUCCESS, DOWNLOAD_POSTCARD_CAMPAIGN_PDF_ERROR, null, { download: true });

export const deletePostcardCampaign = id => postcard('delete', `campaigns/${id}`, DELETE_POSTCARD_CAMPAIGN, DELETE_POSTCARD_CAMPAIGN_SUCCESS, DELETE_POSTCARD_CAMPAIGN_ERROR);

export const downloadPostcardDetail = id => postcard('get', `${id}/detail`, DOWNLOAD_POSTCARD_DETAIL, DOWNLOAD_POSTCARD_DETAIL_SUCCESS, DOWNLOAD_POSTCARD_DETAIL_ERROR, null, { download: true });

export const postcardCampaignChange = (name, value) => ({ type: POSTCARD_CAMPAIGN_CHANGE, name, value });

export const generatePropertyMailingLabels = (layoutId, offset, format, propertySearch, afterSuccess) => marketing(null, 'post', 'mailinglabels/properties', GENERATE_MAILING_LABELS, GENERATE_MAILING_LABELS_SUCCESS, GENERATE_MAILING_LABELS_ERROR, propertySearch, { afterSuccess, download: true, query: { layoutId, offset, format } });
export const generateContactMailingLabels = (layoutId, offset, format, contactSearch, afterSuccess) => marketing(null, 'post', 'mailinglabels/contacts', GENERATE_MAILING_LABELS, GENERATE_MAILING_LABELS_SUCCESS, GENERATE_MAILING_LABELS_ERROR, contactSearch, { afterSuccess, download: true, query: { layoutId, offset, format } });


export const CLEAR_EMAIL_CAMPAIGN = ('CLEAR_EMAIL_CAMPAIGN');
export const SAVE_EMAIL_CAMPAIGN = ('SAVE_EMAIL_CAMPAIGN');
export const SAVE_EMAIL_CAMPAIGN_SUCCESS = ('SAVE_EMAIL_CAMPAIGN_SUCCESS');
export const SAVE_EMAIL_CAMPAIGN_ERROR = ('SAVE_EMAIL_CAMPAIGN_ERROR');
export const PREVIEW_EMAIL_CAMPAIGN = ('PREVIEW_EMAIL_CAMPAIGN');
export const PREVIEW_EMAIL_CAMPAIGN_SUCCESS = ('PREVIEW_EMAIL_CAMPAIGN_SUCCESS');
export const PREVIEW_EMAIL_CAMPAIGN_ERROR = ('PREVIEW_EMAIL_CAMPAIGN_ERROR');
export const DELETE_EMAIL_CAMPAIGN = ('DELETE_EMAIL_CAMPAIGN');
export const DELETE_EMAIL_CAMPAIGN_SUCCESS = ('DELETE_EMAIL_CAMPAIGN_SUCCESS');
export const DELETE_EMAIL_CAMPAIGN_ERROR = ('DELETE_EMAIL_CAMPAIGN_ERROR');
export const LOAD_EMAIL_CAMPAIGN = ('LOAD_EMAIL_CAMPAIGN');
export const LOAD_EMAIL_CAMPAIGN_ERROR = ('LOAD_EMAIL_CAMPAIGN_ERROR');
export const LOAD_EMAIL_CAMPAIGN_SUCCESS = ('LOAD_EMAIL_CAMPAIGN_SUCCESS');
export const LOAD_EMAIL_TEMPLATES = ('LOAD_EMAIL_TEMPLATES');
export const LOAD_EMAIL_TEMPLATES_ERROR = ('LOAD_EMAIL_TEMPLATES_ERROR');
export const LOAD_EMAIL_TEMPLATES_SUCCESS = ('LOAD_EMAIL_TEMPLATES_SUCCESS');
export const UPDATE_EMAIL_CAMPAIGN = ('UPDATE_EMAIL_CAMPAIGN');
export const CHECK_URL_VALIDITY = ('CHECK_URL_VALIDITY');
export const CHECK_URL_VALIDITY_SUCCESS = ('CHECK_URL_VALIDITY_SUCCESS');
export const CHECK_URL_VALIDITY_ERROR = ('CHECK_URL_VALIDITY_ERROR');
export const DOWNLOAD_EMAIL_DETAIL = ('DOWNLOAD_EMAIL_DETAIL');
export const DOWNLOAD_EMAIL_DETAIL_SUCCESS = ('DOWNLOAD_EMAIL_DETAIL_SUCCESS');
export const DOWNLOAD_EMAIL_DETAIL_ERROR = ('DOWNLOAD_EMAIL_DETAIL_ERROR');

const email = (method, path, startType, successType, errorType, body, params) => marketing('email', method, path, startType, successType, errorType, body, params);

export const loadEmailCampaign = id => (id > 0 ? email('get', id, LOAD_EMAIL_CAMPAIGN, LOAD_EMAIL_CAMPAIGN_SUCCESS, LOAD_EMAIL_CAMPAIGN_ERROR) : { type: CLEAR_EMAIL_CAMPAIGN });

export const loadEmailTemplates = () => email('get', 'templates', LOAD_EMAIL_TEMPLATES, LOAD_EMAIL_TEMPLATES_SUCCESS, LOAD_EMAIL_TEMPLATES_ERROR);

export const saveEmailCampaign = (campaign, duplicate, paymentMethodId, cardCode, paymentAmount, creditAmount, afterSuccess) => email('put', null, SAVE_EMAIL_CAMPAIGN, SAVE_EMAIL_CAMPAIGN_SUCCESS, SAVE_EMAIL_CAMPAIGN_ERROR, campaign, { afterSuccess, query: { duplicate, paymentMethodId, cardCode, paymentAmount, creditAmount } });

export const previewEmailCampaign = (campaign, previewEmail, estimate, afterSuccess) => email('post', 'preview', PREVIEW_EMAIL_CAMPAIGN, PREVIEW_EMAIL_CAMPAIGN_SUCCESS, PREVIEW_EMAIL_CAMPAIGN_ERROR, campaign.merge({ previewEmail }), { afterSuccess, query: { estimate } });

export const deleteEmailCampaign = id => email('delete', `campaigns/${id}`, DELETE_EMAIL_CAMPAIGN, DELETE_EMAIL_CAMPAIGN_SUCCESS, DELETE_EMAIL_CAMPAIGN_ERROR);

export const updateEmailCampaign = campaign => ({ type: UPDATE_EMAIL_CAMPAIGN, campaign });

export const updateWebsite = website => ({ type: UPDATE_WEBSITE, website });

export const loadWebsite = () => ({ type: LOAD_WEBSITE });

export const checkUrlValidity = (url, afterSuccess) => email('get', 'url', CHECK_URL_VALIDITY, CHECK_URL_VALIDITY_SUCCESS, CHECK_URL_VALIDITY_ERROR, null, { afterSuccess, query: { url } });

export const downloadEmailDetail = id => email('get', `${id}/detail`, DOWNLOAD_EMAIL_DETAIL, DOWNLOAD_EMAIL_DETAIL_SUCCESS, DOWNLOAD_EMAIL_DETAIL_ERROR, null, { download: true });


export const GET_SMS_STATUS = ('GET_SMS_STATUS');
export const GET_SMS_STATUS_SUCCESS = ('GET_SMS_STATUS_SUCCESS');
export const GET_SMS_STATUS_ERROR = ('GET_SMS_STATUS_ERROR');
export const GET_SMS_LOGIN = ('GET_SMS_LOGIN');
export const GET_SMS_LOGIN_SUCCESS = ('GET_SMS_LOGIN_SUCCESS');
export const GET_SMS_LOGIN_ERROR = ('GET_SMS_LOGIN_ERROR');
export const CREATE_SMS_CAMPAIGN = ('CREATE_SMS_CAMPAIGN');
export const CREATE_SMS_CAMPAIGN_SUCCESS = ('CREATE_SMS_CAMPAIGN_SUCCESS');
export const CREATE_SMS_CAMPAIGN_ERROR = ('CREATE_SMS_CAMPAIGN_ERROR');

const sms = (method, path, startType, successType, errorType, body, params) => marketing('sms', method, path, startType, successType, errorType, body, params);

export const getSmsStatus = () => sms('get', 'status', GET_SMS_STATUS, GET_SMS_STATUS_SUCCESS, GET_SMS_STATUS_ERROR);

export const getSmsLogin = afterSuccess => sms('get', 'login', GET_SMS_LOGIN, GET_SMS_LOGIN_SUCCESS, GET_SMS_LOGIN_ERROR, null, { afterSuccess });

export const createSmsCampaign = (search, afterSuccess) => sms('post', null, CREATE_SMS_CAMPAIGN, CREATE_SMS_CAMPAIGN_SUCCESS, CREATE_SMS_CAMPAIGN_ERROR, search, { afterSuccess });
