import React, { Component } from 'react';

import { joinWith } from '../string';


class AddressCellRenderer extends Component {
  render() {
    const { data: { streetAddress, cityName, zip } = {} } = this.props;

    return (
      <div style={{ whiteSpace: 'pre', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {joinWith('\n', streetAddress, joinWith(' ', cityName, zip))}
      </div>
    );
  }
}

export default AddressCellRenderer;
