import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { colorHexNames, rgb2hsv, hex2rgb } from 'utils/style';
import ColorPicker from 'components/ColorPicker';

import css from './style.scss';


class Color extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { color, name, openColorPicker, transparencyEnabled, element, onChange, enabled } = this.props;
    if (enabled) openColorPicker({ color, transparencyEnabled, onChange: ({ value: color }) => onChange({ [name]: color }, element) });
  }

  render() {
    const { title, color, enabled } = this.props;

    let colorName = 'Transparent';
    let foregroundColor = '#aaa';
    if (color) {
      const { r, g, b } = hex2rgb(color);
      colorName = colorHexNames[color.toUpperCase()];
      foregroundColor = rgb2hsv(r, g, b).v < 40 ? '#fff' : '#000';
    }

    return (
      <div className={classNames(css.backgroundColor, { [css.enabled]: enabled })}>
        <div className={css.title}>{title}</div>
        <div className={classNames(css.color)} style={{ backgroundColor: color || '#fff', color: foregroundColor }} onClick={this.handleClick}>
          <div>{colorName || color}</div>
        </div>
      </div>
    );
  }
}

Color.defaultProps = {
  transparencyEnabled: false,
  enabled: true,
  name: 'color',
};

export default connect(null, { openColorPicker: ColorPicker.open })(Color);
