import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory,  } from 'react-router-dom';
import classNames from 'classnames';

import Button, { ButtonLink } from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';
import Dropdown from 'components/base/Dropdown';
import FormControlWraper from 'components/base/FormControlWraper';
import {
  loadContact,
  saveContact,
  selectContact,
  selectContactStatuses,
  selectContactTypes,
  selectLoading
} from 'data/contacts';
import { selectPabEnabled } from 'data/user';
import { UsStateShortOptions } from 'data/user/constants';
// import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import dropdownNormalize from 'utils/normalizers/dropdown';
import { validate, verifyRequired } from 'utils/validation';
import ContactItems from './Items';

import css from './style.scss';

const Wrapper = ({ name, label, children }) => (
  <FormControlWraper id={name} label={label} className={css.formControlWraper} hasMarginBottom large>
    {children}
  </FormControlWraper>
);

const LabeledTextInput = ({ name, label, contact = {}, onChange, disabled = false, maxLength }) => (
  <Wrapper name={name} label={label}>
    <input
      name={name}
      type="text"
      value={contact[name] || ''}
      onChange={onChange}
      disabled={disabled}
      maxLength={maxLength}
    />
  </Wrapper>
);

const LabeledDropdown = ({ name, label, options, onChange, contact = {} }) => (
  <Wrapper name={name} label={label}>
    <Dropdown name={name} options={options} value={contact[name]} onChange={onChange} />
  </Wrapper>
);

const blankState = () => ({
  companyName: null,
  contact: {
    phones: [],
    emails: [],
  },
});

const phoneTypes = [
  { label: 'Mobile', value: 'MOBILE' },
  { label: 'Landline', value: 'LANDLINE' },
  { label: 'Other', value: 'OTHER' },
];

const emailTypes = [
  { label: 'Home', value: 'HOME' },
  { label: 'Work', value: 'WORK' },
  { label: 'Other', value: 'OTHER' },
];

const ContactEditor = ({ location, history }) => {
  const [state, setState] = useState(blankState());
  const [localLoading, setlocalLoading] = useState(false);

  const [isNewContact, setisNewContact] = useState('');
  const [pristine, setPristine] = useState();



  // const regex = /contact\/(\d+)\/(\d+)/;
  // const matches = location.pathname.match(regex);
  // const groupId = matches && matches.length > 1 ? matches[1] : null;
  // const contactId = matches && matches.length > 2 ? matches[2] : null;

  // const regex = /contact\/(\d+)\/(\d+)/;
  const matches = location.pathname.split("/");
  const groupId = matches && matches.length > 1 ? matches[2] : null;
  const contactId = matches && matches.length > 2 ? matches[3] : null;



  const parentPath = `/contact/${groupId}`;

  const dispatch = useDispatch();

  const types = useSelector(selectContactTypes);
  const statuses = useSelector(selectContactStatuses);
  const loading = useSelector(selectLoading);
  const pabEnabled = useSelector(selectPabEnabled);
  const selectContactload = useSelector(selectContact)


  const handleLoadContact = async () => {
    try {
      await dispatch(loadContact(Number(contactId), ({ response }) => {
        setState({
          companyName: response?.response?.companyName,
          contact: {
            ...response?.response,
            phones: response?.response?.phones?.map((p, seq) => ({ ...p, seq })),
            emails: response?.response?.emails?.map((e, seq) => ({ ...e, seq })),
            type: undefined,
            status: undefined,
          },
        });
      }));
    } catch (error) {
      console.error('Failed to load contact:', error);
    }
  };

  useEffect(() => {
    if (contactId && contactId !== 'new') {
      handleLoadContact();
    } else {
      clearContact()
      setlocalLoading(false)
    }
  }, [contactId]);

  const clearContact = () => {
    setState(blankState());
  }

  const handleChange = (ev) => {
    const { name, value, checked } = ev.target || ev;
    setState((prevState) => ({
      contact: { ...prevState.contact, [name]: name === 'mailAddressSame' ? !checked : value },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { contact } = state;

    validate(() => {
      verifyRequired(null, `${contact.firstName}${contact.lastName}${contact.companyName}`, 'First Name, Last Name, or Company Name is required.');
      verifyRequired(null, contact.typeId, 'Please select a contact type.');
      verifyRequired(null, contact.statusId, 'Please select a contact status.');


      dispatch(saveContact(contact, () => history.push(parentPath)));
    });
  };


  useEffect(() => {
    if (selectContactload && selectContactload?.get('id') && contactId !== 'new') {
      const mapToObj = (map) => Object.fromEntries(map.entries());
      let selectedContact = mapToObj(selectContactload);

    let  phones = selectedContact?.phones?.toJS()
    let  emails = selectedContact?.emails?.toJS()

      setState({
        companyName: selectedContact?.companyName,
        contact: {
          ...selectedContact,
          phones: phones?.map((p, seq) => ({ ...p, seq })),
          emails: emails?.map((e, seq) => ({ ...e, seq })),
          type: undefined,
          status: undefined,
        },
      });
    }
  }, [selectContactload])


  // 
  // const { isNewContact, pristine } = state;
  const { companyName, contact } = state;
  const saveButtonText = (isNewContact || !pristine) ? 'Save' : 'Saved';
  const fieldProps = { contact, onChange: handleChange };
  const { emails, phones } = contact;
  const btn = { isLoading: loading, size: Button.size.large, className: css.btn };

  return (
    <div className={css.newContact}>
      <div className={css.header}>
        <div className={css.left}>
          <div>
            <div className={css.title}>{contact.id ? contact.name || companyName : 'New Contact'}</div>
            {!contact.name || !companyName ? null : <div className={css.companyTitle}>{companyName}</div>}
          </div>
        </div>
        <div className={css.legend}>
          <div>(DNC) - Do Not Call</div>
          {!pabEnabled ? null : <div>(PAB) - Possible Agent or Broker</div>}
        </div>
        <div className={css.right}>
          <ButtonLink {...btn} to={parentPath} kind={ButtonLink.kind.grayGhost} disabled={loading}>
            Cancel
          </ButtonLink>
          {/* pristine || */}
          <Button {...btn} onClick={handleSubmit} kind={Button.kind.blue} disabled={loading}>
            {saveButtonText}
          </Button>
        </div>
      </div>
      <div className={css.body}>
        <div className={css.container}>
          <div className={css.fields}>
            <div className={css.row}>
              <div className={css.md}>
                <LabeledTextInput name="firstName" label="First Name" maxLength={100} {...fieldProps} />
              </div>
              <div className={css.sm}>
                <LabeledTextInput name="middleName" label="Middle Name" maxLength={100} {...fieldProps} />
              </div>
              <div className={css.md}>
                <LabeledTextInput name="lastName" label="Last Name" maxLength={100} {...fieldProps} />
              </div>
            </div>
            <div className={css.row}>
              <div className={css.lg}>
                <LabeledTextInput name="companyName" label="Company Name" maxLength={200} {...fieldProps} />
              </div>
              <div className={css.md}>
                <LabeledTextInput name="suffix" label="Suffix" maxLength={50} {...fieldProps} />
              </div>
            </div>
            <div className={css.row}>
              <div className={css.lg}>
                <LabeledTextInput name="streetAddress" label="Property Address" maxLength={200} {...fieldProps} />
              </div>
              <div className={css.md}>
                <LabeledTextInput name="city" label="City" maxLength={100} {...fieldProps} />
              </div>
            </div>
            <div className={css.row}>
              <div className={css.lg}>
                <LabeledDropdown name="state" label="State" options={UsStateShortOptions} {...fieldProps} />
              </div>
              <div className={css.md}>
                <LabeledTextInput name="zip" label="Zip" {...fieldProps} />
              </div>
            </div>
            <div className={classNames(css.row, css.checkRow)}>
              <Checkbox label="Mailing address is different from property address" checked={!contact.mailAddressSame} onClick={handleChange} name="mailAddressSame" />
            </div>
            {contact.mailAddressSame ? null : (
              <div>
                <div className={css.row}>
                  <div className={css.lg}>
                    <LabeledTextInput name="mailStreetAddress" label="Mailing Address" maxLength={200} {...fieldProps} />
                  </div>
                  <div className={css.md}>
                    <LabeledTextInput name="mailCity" label="City" maxLength={100} {...fieldProps} />
                  </div>
                </div>
                <div className={css.row}>
                  <div className={css.lg}>
                    <LabeledDropdown name="mailState" label="State" options={UsStateShortOptions} {...fieldProps} />
                  </div>
                  <div className={css.md}>
                    <LabeledTextInput name="mailZip" label="Zip" {...fieldProps} />
                  </div>
                </div>
              </div>
            )}
            <div className={css.row}>
              <div className={css.sm2}>
                <LabeledDropdown name="typeId" label="Contact Type*" options={dropdownNormalize(types)} {...fieldProps} />
              </div>
              <div className={css.sm2}>
                <LabeledDropdown name="statusId" label="Status*" options={dropdownNormalize(statuses)} {...fieldProps} />
              </div>
            </div>
          </div>
          <ContactItems items={phones} field="phone" title="Phone Numbers" types={phoneTypes} onChange={handleChange} />
          <ContactItems items={emails} field="email" title="Email Addresses" types={emailTypes} onChange={handleChange} />
        </div>
      </div>
    </div>
  );
};

ContactEditor.defaultProps = {
  title: 'New Contact',
};

export default withRouter(ContactEditor);

