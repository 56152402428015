import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import { Portal } from 'react-portal';

import uniqueId from 'utils/uniqueId';

import Button from 'components/base/Button';
import SVG from 'components/base/SVG';

import css from './styles.scss';


const noSuggestions = List();
const listProps = { role: 'listbox', className: 'react-autosuggest__suggestions-list' };
const itemProps = { role: 'option', 'aria-selected': false, className: 'react-autosuggest__suggestion react-autosuggest__suggestion--accent' };

const renderSuggestion = suggestion => <span className="react-autosuggest__suggestion-wrapper">{suggestion.label}</span>;

export default class SuggestedInput extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSuggestionsClearRequested = this.handleSuggestionsClearRequested.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.renderSuggestionsContainer = this.renderSuggestionsContainer.bind(this);
    this.shouldSuggest = this.shouldSuggest.bind(this);
    this.handleSuggestionsSelected = this.handleSuggestionsSelected.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleClear = this.handleClear.bind(this);

    this.id = uniqueId();
    this.container = null;
    this.canDisplay = false;

    this.state = { value: '', loading: false, focused: false };
  }

  componentWillReceiveProps(props) {
    const { suggestions } = this.props;

    let { loading } = this.props;

    if (loading !== props.loading) loading = props.loading;
    else if (!('loading' in props) && suggestions !== props.suggestions) loading = false;

    this.setState({ loading });
  }

  setFocus(ev, focus) {
    const { inputProps } = this.props;
    this.canDisplay = focus;
    this.setState({ focused: focus });
    const method = focus ? 'onFocus' : 'onBlur';
    if (inputProps && inputProps[method]) inputProps[method](ev);
  }

  handleRef(ref) {
    const { inputProps } = this.props;
    this.container = ref;
    if (inputProps && inputProps.ref) inputProps.ref(ref);
  }

  handleSuggestionsSelected(ev, data) {
    this.canDisplay = false;
    this.props.onChange(data.suggestion);
  }

  handleFocus(ev) {
    this.setFocus(ev, true);
  }

  handleBlur(ev) {
    this.setFocus(ev, false);
  }

  handleInputChange(ev) {
    ev.preventDefault();
    this.setState({ value: ev.target.value || '' });
    this.canDisplay = true;
    if (typeof ev.target.value === 'string') this.updateSuggestions({ text: ev.target.value, selected: null });
  }

  handleClear() {
    const { clearSearch } = this.props;
    if (clearSearch) clearSearch();

    this.setState({ value: '' });
    this.container.input.focus();
  }

  // FIXME: Is this needed?
  handleSuggestionsClearRequested() {
    const { suggestions } = this.props;
    if (suggestions && suggestions.length) {
      this.updateSuggestions({ suggestions: noSuggestions });
      this.setState({ value: '' });
    }
  }

  updateSuggestions(suggestion) {
    const { value, onFetch, showRecent } = this.props;
    const { text } = suggestion;
    if ((text || text === '') && text !== value) {
      onFetch(suggestion.text);

      // if (suggestion.text === '') showRecent();
    }
  }

  shouldSuggest(value) {
    const { suggestIfEmpty = false, minSuggestLength = 0 } = this.props;
    if (suggestIfEmpty) return true;
    if (!this.canDisplay) return false;
    return (value || '').trim().length > minSuggestLength;
  }

  renderSuggestionsContainer({ children, containerProps }) {
    if (!this.container || !this.canDisplay) return null;

    const targetElement = this.container.input;
    if (!targetElement) return null;

    const { suggestions, value, isRecent } = this.props;
    const { loading } = this.state;

    const bodyRect = document.documentElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();
    const noData = !(loading || !value || !value.length || !suggestions || suggestions.length);

    return (
      <Portal isOpened>
        <div
          {...containerProps}
          style={{
            top: (targetRect.bottom - bodyRect.top) + 5,
            left: (targetRect.left - bodyRect.left) - 1,
            width: Math.max((targetRect.width + 2), 150),
            zIndex: 10,
          }}
          className={classNames('autocomplete-div-hide-on-empty', { hidden: !(children || loading || noData) })}
        >
          {!loading ? null : (
            <ul {...listProps}>
              <li {...itemProps}>
                <i className="fa fa-spinner fa-spin" /> Loading...
              </li>
            </ul>
          )}
          {!isRecent || this.props.loading ? null : <div className={css.caption}><SVG icon="iconClock" className={css.iconCaption} />Recent Searches</div>}
          <div className="react-autosuggest__suggestions-wrapper">
            {loading ? null : children}
          </div>
          {!noData ? null : (
            <ul {...listProps}>
              <li {...itemProps}>No data</li>
            </ul>
          )}
        </div>
      </Portal>
    );
  }

  render() {
    const { label, className, suggestions, placeholder, getSuggestion, getSuggestionValue, canEverBeLoading, extraProps, value, changed = false } = this.props;

    return (
      <div className={css.autosuggestWrapper}>
        <Button kind="link-default" name="clear" className={css.btnClear} onClick={this.handleClear}>
          <SVG icon="iconClose" className={css.iconClear} />
        </Button>
        <label htmlFor={this.id} className={classNames({ hidden: !label })}>{label}</label>
        <Autosuggest
          {...extraProps}
          highlightFirstSuggestion
          suggestions={suggestions || noSuggestions}
          ref={this.handleRef}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          shouldRenderSuggestions={this.shouldSuggest}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          onSuggestionSelected={this.handleSuggestionsSelected}
          inputProps={{
            id: this.id,
            onChange: this.handleInputChange,
            onBlur: this.handleBlur,
            onFocus: this.handleFocus,
            className: classNames(className, { changed }),
            value: this.state.value || value || '',
            placeholder,
          }}
          onSuggestionsFetchRequested={(data) => {
            this.setState({ loading: canEverBeLoading });
            getSuggestion(data);
          }}
        />
        {this.props.children}
      </div>
    );
  }
}

SuggestedInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  suggestions: PropTypes.array.isRequired,
  onFetch: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func,
  getSuggestion: PropTypes.func,
  clearSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  suggestIfEmpty: PropTypes.bool,
  isRecent: PropTypes.bool,
  minSuggestLength: PropTypes.number,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  canEverBeLoading: PropTypes.bool,
};

SuggestedInput.defaultProps = {
  placeholder: '',
  getSuggestionValue: () => {},
  getSuggestion: () => {},
  suggestIfEmpty: true,
  isRecent: false,
  canEverBeLoading: false,
};

