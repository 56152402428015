/** @flow */
import RealColumn from './RealColumn';
import PropTypes from 'prop-types';


const Column = () => null;

Column.propTypes = {
  ...RealColumn.propTypes,
  field: PropTypes.string.isRequired,
  cellClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  row: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  renderTooltip: PropTypes.func,
};

Column.defaultProps = {
  row: 0,
};

export default Column;
