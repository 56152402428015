export function formatYearMonth(number) {
  const num = Number(number);
  if (isNaN(num)) return '';

  const years = Math.floor(num / 12);
  const months = num % 12;
  let str = '';

  if (years) str = `${years} Year${years === 1 ? '' : 's'}`;
  if (!years || months) str = `${str} ${months} Month${months === 1 ? '' : 's'}`.trim();

  return str;
}

export default function format(number, invalidValue = null, options = {}) {
  if (isNaN(String(number))) return invalidValue;
  return Number(number).toLocaleString('en-US', options);
}
