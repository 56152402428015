import { fromJS, List, Map } from 'immutable';

import { AUTHENTICATION_SUCCESS } from 'data/user';
import { getSortComparator } from 'reducers';

import ListingTypes, { RESULTS_PER_PAGE, SearchModes, SearchLayouts, StatisticPeriods, StatisticUnitFormats, MAP_NO_METRIC, fipsMap, countyMap } from './constants';
import * as ActionType from './actions';


const FilterFields = [
  // Date
  'mortgageRecDateMin,mortgageRecDateMax,auctionDateMin,auctionDateMax,saleDateMin,saleDateMax,lienRecordingDateMin,lienRecordingDateMax,bankruptcyRecordingDateMin,bankruptcyRecordingDateMax,divorceRecordingDateMin,divorceRecordingDateMax,mlsListingDateMin,mlsListingDateMax,foreclosureRecordingDateMin,foreclosureRecordingDateMax,foreclosureReleaseDateMin,foreclosureReleaseDateMax,mortgageMaturityDateMin,mortgageMaturityDateMax,mortgageRefinanceDateMin,mortgageRefinanceDateMax,mortgage1RecordingDateMin,mortgage1RecordingDateMax,mortgage2RecordingDateMin,mortgage2RecordingDateMax,mortgage3RecordingDateMin,mortgage3RecordingDateMax,mortgage4RecordingDateMin,mortgage4RecordingDateMax,mortgage1RefinanceDateMin,mortgage1RefinanceDateMax,mortgage2RefinanceDateMin,mortgage2RefinanceDateMax,mortgage3RefinanceDateMin,mortgage3RefinanceDateMax,mortgage4RefinanceDateMin,mortgage4RefinanceDateMax,mortgage1DueDateMin,mortgage1DueDateMax,mortgage2DueDateMin,mortgage2DueDateMax,mortgage3DueDateMin,mortgage3DueDateMax,mortgage4DueDateMin,mortgage4DueDateMax',
  // String
  'ownerLocation,propertyClassCode,residentialPropCode,multiFamilyPropCode,commercialPropCode,vacantPropCode,officePropCode,industrialPropCode,agriculturalPropCode,recreationalPropCode,transportationPropCode,exemptPropCode,residentialPropCode,multiFamilyPropCode,commercialPropCode,otherPropCode,bankruptcyChapter,lienTypeCode,foreclosureStatus2Class,preforeclosureIncluded,poolType,garageType,basementType,lienStatus,lienStatusExclude,mlsListingStatus,mlsListingKeyword,foreclosureStatus2,openMortgageQuantity,propertyFeatures,landUseCode,ownerType,schoolDistrict,exemptions,mortgageLoanCode,mortgageFinancingCode,mortgageLenderName,mortgage1LenderName,mortgage2LenderName,mortgage3LenderName,mortgage4LenderName,mortgage1FinancingCode,mortgage2FinancingCode,mortgage3FinancingCode,mortgage4FinancingCode,mortgage1LoanCode,mortgage2LoanCode,mortgage3LoanCode,mortgage4LoanCode',
  // Decimal
  'bathroomsMin,bathroomsMax,loanToValueRatioMin,loanToValueRatioMax,mortgage1LtvRatioMin,mortgage1LtvRatioMax,estimatedValueGrowthMin,estimatedValueGrowthMax,estimatedEquityPercentMin,estimatedEquityPercentMax,storiesMin,storiesMax,grossYieldMin,grossYieldMax,itvRatioMin,itvRatioMax,mortgageInterestRateMin,mortgageInterestRateMax,mortgageTotalPaymentMin,mortgageTotalPaymentMax,mortgageCountMin,mortgageCountMax,openMortgageBalanceMin,openMortgageBalanceMax,mortgagePaymentMin,mortgagePaymentMax,mortgage1BalanceMin,mortgage1BalanceMax,mortgage2BalanceMin,mortgage2BalanceMax,mortgage3BalanceMin,mortgage3BalanceMax,mortgage4BalanceMin,mortgage4BalanceMax,mortgage1PaymentMin,mortgage1PaymentMax,mortgage2PaymentMin,mortgage2PaymentMax,mortgage3PaymentMin,mortgage3PaymentMax,mortgage4PaymentMin,mortgage4PaymentMax,mortgage1InterestRateMin,mortgage1InterestRateMax,mortgage2InterestRateMin,mortgage2InterestRateMax,mortgage3InterestRateMin,mortgage3InterestRateMax,mortgage4InterestRateMin,mortgage4InterestRateMax',
  // Long
  'estimatedEquityMin,estimatedEquityMax,wholesaleValueMin,wholesaleValueMax,saleAmountMin,saleAmountMax,estimatedValueMin,estimatedValueMax,estimatedRentalIncomeMin,estimatedRentalIncomeMax,mortgageAmountMin,mortgageAmountMax,openingBidAmountMin,openingBidAmountMax,lienAmountMin,lienAmountMax,mlsListingAmountMin,mlsListingAmountMax,mortgage1EstimatedEquityMin,mortgage1EstimatedEquityMax,defaultAmountMin,defaultAmountMax,assessedValueMin,assessedValueMax,assessedLandValueMin,assessedLandValueMax,assessedImprovementValueMin,assessedImprovementValueMax',
  // Integer
  'yearBuiltMin,yearBuiltMax,squareFeetMin,squareFeetMax,lotSquareFeetMin,lotSquareFeetMax,bedroomsMin,bedroomsMax,propertiesOwnedMin,propertiesOwnedMax,unitsMin,unitsMax,daysOnMarketMin,daysOnMarketMax,ownershipLengthMin,ownershipLengthMax,delinquentTaxYearMin,delinquentTaxYearMax,propConditionMin,propConditionMax,bathConditionMin,bathConditionMax,kitchenConditionMin,kitchenConditionMax,intConditionMin,intConditionMax,extConditionMin,extConditionMax,foreclosureFactorMin,foreclosureFactorMax', 'delinquentTaxYearMin', 'delinquentTaxYearMax',
  // Boolean
  'belowMarketPrice,residentialPropClass,commercialPropClass,vaccantPropClass,otherPropClass,forSale,vacant,rental,ownerOccupied,cashBuyer,freeClear,missingSaleIncluded,interFamilyTransfer,mortgageSellerCarried,lienActive,bankruptcyActive,divorceActive,hoaPresent,poolAvailable,garageAvailable,basementAvailable,atticAvailable,unknownEquityIncluded,unknownLtvIncluded,ownerDeceased,mortgageRefinance,mortgageEquity,mortgage1Refinance,mortgage2Refinance,mortgage3Refinance,mortgage4Refinance,mortgage1Equity,mortgage2Equity,mortgage3Equity,mortgage4Equity',
  // Enum
  'listingType,estimatedValueGrowthPeriod',
].join(',').split(',').reduce((m, f) => ({ ...m, [f]: true }), {});

const LocationFields = 'id,type,fips,apn,countyId,cityId,zip,shapeDefinition,propertyId,streetAddress,label'.split(',').reduce((m, f) => ({ ...m, [f]: true }), {});

/*
// Mobile: latitude,longitude,radius,

    name, description, notificationInterval, selectionInversed, resultOffset, resultLimit, selection, sortField, filter, totalCount,oneWeekCount,oneMonthCount,threeMonthCount,slot,ownerDeceasedCode,

    stateId, cityName, countyName, stateCode, fips
    mortgageTermMin,mortgageTermMax,mortgageCountMin,mortgageCountMax,amountOfDefaultMin,amountOfDefaultMax,foreclosureStatus,zoning,documentTypeCode,propertyTypeCode, listingDateMin, listingDateMax, sortAscending, allSelected, enableAlerts, ownerFirstName, ownerLastName, ownerCompanyName,
    inDefault, lienStatus, divorceStatus,bankruptcyStatus,rental,ownerCorporate,ownerOutOfState,distressed,poolAvailable,fireplaceAvailable,garageAvailable,airConditioningAvailable,basementAvailable,atticAvailable,
 */

const emptyMapMetric = {
  name: MAP_NO_METRIC,
  displayName: 'No analytics',
  max: 0,
  maxString: '',
  midString: '',
  min: 0,
  minString: '',
};

const defaultSuggestion = {
  loading: false,
  label: '',
  value: null,
  items: [],
  recentItems: [],
  recentLoaded: false,
  countySearchEnabled: false,
};

const defaultFilter = {
  estimatedValueGrowthPeriod: 'ONE_MONTH',
};

const defaultSelection = {
  selection: [],
  selectionCount: 0,
  allSelected: false,
  selectionInversed: false, // If user hits select all, manually checked IDs in "selection" count as not selected.
};

const defaultContext = fromJS({
  results: [],
  resultCount: 0,
  pageIndex: 1,
  pageCount: 1,
  rangeEnabled: false,
  rangeFrom: null,
  rangeTo: null,
  effectiveRangeFrom: 1,
  effectiveRangeTo: 0,
  rangeCount: 0,
  sortField: null,
  sortAscending: true,
  ...defaultSelection,

  // Result values constrained by result total count & user-selected page / range. Passed to server to get effective result range. (Server doesn't process page / range values)
  resultOffset: 1,
  resultLimit: RESULTS_PER_PAGE,
});

const defaultResult = fromJS({
  query: null,
  mode: null,
  overlay: null,
  layout: SearchLayouts.Picture,
  property: null,
  activeContext: null,
  contexts: {},
});

const defaultMap = fromJS({
  metrics: [],
});

const defaultLocalFilter = fromJS({
  metrics: [],
});

const defaultStatistic = fromJS({
  loading: false,
  stale: false,
  data: { title: 'Statistics' },
  filter: {
    listingTrendGrowthPeriod: StatisticPeriods[0].value,
    marketTrendGrowthPeriod: StatisticPeriods[0].value,
    salesTrendGrowthPeriod: StatisticPeriods[0].value,
  },
});

const defaultState = fromJS({
  loading: false,
  availableListingTypes: [],
  stale: false,
  suggestion: defaultSuggestion,
  location: null,
  filter: defaultFilter,
  result: defaultResult,
  map: defaultMap,
  statistic: defaultStatistic,
  savedSearchLoading: false,
  savedSearches: [],
  favoriteSearchLoading: false,
  favoriteSearchStale: false,
  favoriteSearches: {},
  favoriteSearchCounts: {},
  localFilter: [],
  showAllEditOption: {},
  leadeList: [],
  selectedsavesearch: null,
  favoriteSelectedName: null
});


const constrainObject = (obj, fields, allowEmptyField = false, allowEmptyObject = false) => {
  const o = Object.keys(obj || {}).filter(k => !!fields[k] && (allowEmptyField || (obj[k] != null && obj[k] !== undefined))).reduce((o, k) => ({ ...o, [k]: obj[k] }), {});

  return allowEmptyObject || Object.keys(o).length ? o : null;
};

const setSuggestionValue = (state, value) => state.mergeIn(['suggestion'], fromJS({ value, label: ((value || {}).label || '') }));

const setSuggestions = (state, items, recent = false) => state.mergeIn(['suggestion'], fromJS({ loading: false, recentLoaded: recent, items }));

const setStale = (state, stale = true) => state.set('stale', stale);

const setFilter = (state, filter = null) => setStale(state.set('filter', fromJS(constrainObject({ ...defaultFilter, ...(filter || {}) }, FilterFields))));
const clearSuggestion = state => setSuggestionValue(setSuggestions(state, state.getIn(['suggestion', 'recentItems']), true), null);

const clearResult = state => state.set('result', defaultResult);


const clearFilter = state => setStale(clearResult(state.set('filter', fromJS(defaultFilter))));

const clearStatistic = state => state.set('statistic', defaultStatistic);

const getActiveContext = (state, init = false) => state.getIn(['result', 'contexts', state.getIn(['result', 'activeContext'])], init ? defaultContext : null);

const setActiveContext = (state, context) => state.setIn(['result', 'contexts', state.getIn(['result', 'activeContext'])], context);

const mergeActiveContext = (state, data) => setActiveContext(state, getActiveContext(state).merge(fromJS(data)));

const setLocation = (state, location = null) => {
  let newState = state;
  let loc = constrainObject(location, LocationFields);

  if (loc) {
    const { id, type, streetAddress, cityId, countyId, zip, shapeDefinition } = loc;
    let { fips, apn } = loc;
    let query;
    let geo;

    if (apn) apn = apn.replace(/-+/g, '-'); // Strip consecutive dashes or else request is 403'd by AWS' firewall.
    if (fips && fips.length !== 5) fips = undefined;

    if (cityId) geo = { cityId };
    else if (countyId) geo = { countyId };
    else if (fips) geo = { fips };
    else if (zip) geo = { zip };
    else if (shapeDefinition) geo = { shapeDefinition };

    // if (propertyId) query = { propertyId };  // Doesn't handle multi-unit addresses currently (e.g. 3250 NE 1st Ave Miami, FL 33137)
    // else
    if (geo) query = { id, addressType: type, streetAddress, fips, apn, ...geo };

    loc = { ...loc, geo, query };

    const geoChanged = geo && JSON.stringify(geo) !== JSON.stringify(state.getIn(['location', 'geo'], Map()).toJS());

    newState = setStale(newState.set('stale', true))
      .update('statistic', s => s.set('stale', geoChanged || s.get('stale')))
      .set('favoriteSearchStale', geoChanged || state.get('favoriteSearchStale'));
  } else newState = newState.set('favoriteSearchCounts', Map());

  return clearResult(newState.set('location', fromJS(loc)));
};

const applySelection = (context) => {
  const allSelected = context.get('allSelected');
  const selectionInversed = context.get('selectionInversed');
  const selection = context.get('selection');
  return context.update('displayResults', r => r.map(r => r.set('selected', allSelected || !(Math.min(selection.indexOf(r.get('id')), 0) + (selectionInversed ? 1 : 0)))));
};

const constrainContextRange = (state, context, page, rangeFrom, rangeTo) => {
  const mode = state.getIn(['result', 'mode']);
  const serverPagingEnabled = mode === SearchModes.Listing;
  const resultsPerPage = mode === SearchModes.Property ? 100000 : RESULTS_PER_PAGE; // Don't page surrounding property results.

  const newContext = context || getActiveContext(state);
  const rangeEnabled = newContext.get('rangeEnabled');
  const resultCount = newContext.get('resultCount');

  const from = (rangeEnabled && (rangeFrom === undefined ? newContext.get('rangeFrom') : rangeFrom)) || null;
  const to = (rangeEnabled && (rangeTo === undefined ? newContext.get('rangeTo') : rangeTo)) || null;

  const effectiveRangeFrom = Math.max(1, Math.min(resultCount, from || 0));
  const effectiveRangeTo = Math.max(effectiveRangeFrom, Math.min(resultCount, to || resultCount));

  const pageCount = Math.ceil(((effectiveRangeTo - effectiveRangeFrom) + 1) / resultsPerPage);
  const pageIndex = Math.max(1, Math.min(pageCount, (page === undefined ? newContext.get('pageIndex') : page) || 0));

  const resultOffset = effectiveRangeFrom + ((pageIndex - 1) * resultsPerPage);
  const resultLimit = Math.min(resultsPerPage, Math.min(resultOffset + resultsPerPage, effectiveRangeTo + 1) - resultOffset); // Should never exceed RESULTS_PER_PAGE anyway.

  let rangeResults = newContext.get('results');
  let displayResults = rangeResults;

  // Perform client-side sorting and range constraint.
  if (!serverPagingEnabled) {
    const field = newContext.get('sortField');
    rangeResults = displayResults.sort(getSortComparator(`${!field || newContext.get('sortAscending') ? '' : '!'}${field || 'index'}`)).map((r, i) => r.set('resultIndex', i + 1)).filter(r => r.get('resultIndex') >= effectiveRangeFrom && r.get('resultIndex') <= effectiveRangeTo);
    displayResults = rangeResults.filter(r => r.get('resultIndex') >= resultOffset && r.get('resultIndex') < resultOffset + resultLimit);
  }

  return applySelection(newContext.merge({
    pageCount,
    pageIndex,
    resultOffset,
    resultLimit,
    effectiveRangeFrom,
    effectiveRangeTo,
    displayResults,
    rangeResults,
    stale: serverPagingEnabled && (resultOffset !== newContext.get('resultOffset') || resultLimit !== newContext.get('resultLimit')),
    // Only store range from/to if set by user.
    rangeFrom: from ? effectiveRangeFrom : null,
    rangeTo: to ? effectiveRangeTo : null,
    rangeCount: (effectiveRangeTo - effectiveRangeFrom) + 1,
  }));
};

const constrainRange = (state, context, page, rangeFrom, rangeTo) => {
  const newContext = constrainContextRange(state, context, page, rangeFrom, rangeTo);
  const newState = setActiveContext(state, newContext);

  // Ignore derived stale value if context was passed in, which indicates this is being called right after a server request. Stale value should always be false in this case but ignore just in case to prevent an infinite loop.
  return !newContext.get('stale') || context ? newState : setStale(newState);
};

const initContext = (state, results) => constrainContextRange(state, defaultContext.merge(fromJS({ results: results.map((i, x) => ({ ...i, index: x, resultIndex: x + 1 })), resultCount: results.length })));

const loadSavedSearches = (state, savedSearches = []) => state.merge(fromJS({
  savedSearchLoading: false,
  savedSearches,
  favoriteSearches: savedSearches.filter(s => typeof s.slot === 'number').reduce((m, s) => ({ ...m, [s.slot]: s }), {}),
}));

const updateLocalFilterAction = (state, localFilter) => {
  const existingLocalFilter = state.get('localFilter') || [];

  const filteredLocalFilter = existingLocalFilter?.filter(existingRecord =>
    localFilter.every(newRecord =>  newRecord?.apiKey !== existingRecord?.apiKey)
  );

  // Filter out records with name ' or more' from localFilter
  const filteredLocalFilterWithoutOrMore = localFilter.filter(record => {
    const trimmedName = record.name.trim();
    return trimmedName !== 'or more' && trimmedName !== ''
  }
  );

  // Concatenate filtered array with new records
  const updatedLocalFilter = [...filteredLocalFilter, ...filteredLocalFilterWithoutOrMore];

  // Set the updated localFilter array in the state
  return state.set('localFilter', updatedLocalFilter);
};

const removeLocalFilterItemById = (state, id) => {
  const existingLocalFilter = state.get('localFilter') || [];

  // Filter out the item with the specified id
  const updatedLocalFilter = existingLocalFilter?.filter(item => item?.id !== id);

  // Set the updated localFilter array in the state
  return state.set('localFilter', updatedLocalFilter);
};


const removeFilterById = (state, id) => {
  // Get the existing filter object from the state
  let existingFilter = state.get('filter');

  // Check if the existing filter is defined and not null
  if (existingFilter) {
    // Check if the ID exists exactly in the filter
    if (existingFilter.has(id)) {
      // Remove the item with the specified ID
      existingFilter = existingFilter.delete(id);
    } else {
      // Remove all items related to the given ID
      existingFilter = existingFilter.filter((value, key) => !key.startsWith(id));
    }
  }

  // Update the 'filter' property in the state with the filtered items
  return clearResult(setFilter(state, existingFilter.toJS()));
};

const clearLocalFilter = (state) => {
  // Set the updated localFilter array in the state
  return state.set('localFilter', []);
};

const setShowAllbuttonOption = (state, data) => {

  // Set the updated localFilter array in the state
  return state.set('showAllEditOption', data);
};

const clearShowAllbuttonOption = (state) => {

  // Set the updated localFilter array in the state
  return state.set('showAllEditOption', {});
};

const setLeadList = (state, response) => {
  return state.set('leadeList', (response || []));
};

export default function reducer(state = defaultState, action) {
  const { response } = action;

  switch (action.type) {
    case AUTHENTICATION_SUCCESS: {
      const { availableListingTypes = [], searches = [], profile: { countySearchEnabled } } = response;

      return loadSavedSearches(state.merge(fromJS({ availableListingTypes })).setIn(['suggestion', 'countySearchEnabled'], countySearchEnabled), searches);
    }

    case ActionType.CLEAR_ALL:
      return setLocation(clearStatistic(clearFilter(clearSuggestion(state))));

    case ActionType.SET_LOCATION:
      return setLocation(state, action.location);

    case ActionType.CLEAR_SUGGESTION:
      return clearSuggestion(state);

    case ActionType.SET_SUGGESTION_LABEL:
      return state.setIn(['suggestion', 'label'], action.label);

    case ActionType.SET_SUGGESTION_VALUE:
      return setLocation(setSuggestionValue(state, action.value), action.value);

    case ActionType.SET_RECENT_SUGGESTIONS: {
      const newState = state.setIn(['suggestion', 'recentItems'], fromJS(action.recentItems));
      return !action.clear ? newState : clearSuggestion(newState);
    }

    case ActionType.SET_SUGGESTIONS:
      return setSuggestions(state, action.items);

    case ActionType.CLEAR_FILTER:
      return clearFilter(state);

    case ActionType.UPDATE_FILTER:
      return setFilter(state, { ...state.get('filter').toJS(), ...action.data });

    case ActionType.LOAD_SEARCH: {
      const { search } = action;
      const { filter, cityId, countyId, zip, shapeDefinition, formattedAddress, ...rest } = search;
      const loc = state.get('location') || Map();

      return setLocation(setFilter(state, rest), filter ? { ...loc.get('geo', Map()).toJS(), label: loc.get('label') } : { cityId, countyId, zip, shapeDefinition, label: formattedAddress });
    }

    case ActionType.SET_PAGE:
      return constrainRange(state, undefined, action.page);

    case ActionType.SET_RANGE:
      return constrainRange(mergeActiveContext(state, defaultSelection), undefined, undefined, action.rangeFrom, action.rangeTo);

    case ActionType.SET_SORT: {
      const { field, ascending } = action;
      const context = getActiveContext(state).merge({ sortField: field, sortAscending: ascending });

      if (state.getIn(['result', 'mode']) === SearchModes.Listing) return setActiveContext(state.set('stale', true), context);

      return constrainRange(state, context);
    }

    case ActionType.TOGGLE_RANGE:
      return setActiveContext(state, getActiveContext(state).set('rangeEnabled', action.enabled));

    case ActionType.TOGGLE_SELECTION: {
      const { id } = action;
      const context = getActiveContext(state);
      const pagingEnabled = state.getIn(['result', 'mode']) === SearchModes.Listing;
      const rangeCount = context.get('rangeCount');

      let selectionInversed = pagingEnabled && context.get('selectionInversed');
      let selection = context.get('selection');

      if (id) {
        const idx = selection.indexOf(id);
        selection = idx < 0 ? selection.push(id) : selection.delete(idx);
      } else if (pagingEnabled) {
        selectionInversed = !context.get('allSelected');
        selection = List();
      } else selection = selection.size === rangeCount ? List() : context.get('rangeResults').map(r => r.get('id'));

      const selectionCount = selectionInversed ? rangeCount - selection.size : selection.size;

      return setActiveContext(state, applySelection(context.merge(fromJS({ selection, selectionInversed, selectionCount, allSelected: selectionCount === rangeCount }))));
    }

    case ActionType.SET_LAYOUT:
      return state.setIn(['result', 'layout'], action.layout);

    case ActionType.SET_OVERLAY:
      return state.setIn(['result', 'overlay'], state.getIn(['result', 'overlay']) === action.overlay ? null : action.overlay);

    case ActionType.SET_ACTIVE_CONTEXT:
      return state.setIn(['result', 'activeContext'], action.context);

    case ActionType.REFRESH_SEARCH:
      return setStale(constrainRange(mergeActiveContext(state, { pageIndex: 1, rangeFrom: null, rangeTo: null, rangeEnabled: false })));

    case ActionType.SEARCH_LISTING:
      return setStale(state.set('loading', true), false).mergeIn(['result'], {
        query: action.query,
        mode: SearchModes.Listing,
        activeContext: ListingTypes.Default,
      });

    case ActionType.SEARCH_LISTING_SUCCESS: {
      const results = (response || []).map(i => ({ ...i, index: i.resultIndex }));

      const listhubIds = results.filter(l => l.listhubId).map(l => ({ lkey: l.listhubId }));
      if (listhubIds.length && window.listHub) window.listHub('submit', 'SEARCH_DISPLAY', listhubIds);

      const context = getActiveContext(state, true).merge(fromJS({
        results,
        resultCount: (results.length && results[0].resultCount) || 0,
      }));

      return constrainRange(state.set('loading', false), context);
    }

    case ActionType.SAVE_LISTINGS:
      return state.set('loading', true);

    case ActionType.SAVE_LISTINGS_SUCCESS:
    case ActionType.SAVE_LISTINGS_ERROR:
    case ActionType.SEARCH_LISTING_ERROR:
      return state.set('loading', false);

    case ActionType.SEARCH_PROPERTY:
      return setStale(state.set('loading', true), false).set('result', defaultResult.merge({ query: action.query, mode: SearchModes.Property }));

    case ActionType.SEARCH_PROPERTY_SUCCESS: {
      const { specificProperty, properties = [], neighbors = [], nearbyMlsListings = [], nearbyPreForeclosures = [], nearbyForeclosures = [] } = response;
      let result;

      if (specificProperty) {
        const ns = state.setIn(['result', 'mode'], SearchModes.Property);
        result = {
          mode: SearchModes.Property,
          activeContext: ListingTypes.Neighbor,
          property: properties[0],
          contexts: {
            [ListingTypes.Neighbor]: initContext(ns, neighbors),
            [ListingTypes.M]: initContext(ns, nearbyMlsListings),
            [ListingTypes.P]: initContext(ns, nearbyPreForeclosures),
            [ListingTypes.F]: initContext(ns, nearbyForeclosures),
          },
        };
      } else {
        const ns = state.setIn(['result', 'mode'], SearchModes.Multiple);
        result = {
          mode: SearchModes.Multiple,
          activeContext: ListingTypes.Default,
          contexts: { [ListingTypes.Default]: initContext(ns, properties.map(p => ({ ...(p.address || {}), ...p }))) },
        };
      }

      return state.set('loading', false).mergeIn(['result'], fromJS(result));
    }

    case ActionType.SEARCH_PROPERTY_ERROR:
      return state.set('loading', false);

    case ActionType.SEARCH_SUGGESTIONS:
      return state.setIn(['suggestion', 'loading'], true);

    case ActionType.SEARCH_SUGGESTIONS_SUCCESS:
      return setSuggestions(state, response || []);

    case ActionType.SEARCH_SUGGESTIONS_ERROR:
      return state.setIn(['suggestion', 'loading'], false);

    case ActionType.GET_MAP_METRICS_SUCCESS:
      return state.setIn(['map', 'metrics'], fromJS([emptyMapMetric, ...action.response]));

    case ActionType.CLEAR_STATISTICS:
      return clearStatistic(state);

    case ActionType.UPDATE_STATISTICS_FILTER:
      return state.mergeIn(['statistic', 'filter'], fromJS({ ...action.data })).setIn(['statistic', 'stale'], true);

    case ActionType.SEARCH_STATISTICS: {
      const { zip, cityId, countyId } = action.query;

      let location;
      if (cityId) location = { cityId };
      else if (countyId) location = { countyId };
      else if (zip) location = { zip };

      return state.mergeIn(['statistic'], fromJS({ location, loading: true }));
    }

    case ActionType.EXPORT_STATISTICS:
      return state.setIn(['statistic', 'loading'], true);

    case ActionType.SEARCH_STATISTICS_SUCCESS: {
      const statisticUnit = (percents = 0, name, value = 0, format) => ({ name, value, format, percents: percents * 100, direction: percents < 0 ? 'down' : 'up' });

      const statisticGraph = ({ type, series = [] } = {}) => (series.filter(s => !!s.category && !!s.points).reduce((m, { points, category }) => ({
        ...m,
        categories: [...m.categories, category],
        points: points.filter(p => !!p.value).reduce((ar, p) => {
          const pt = ar.find(pt => pt.label === p.label) || p;
          pt[category] = p.value;

          return p !== pt ? ar : ar.concat([pt]);
        }, m.points),
      }), { type, points: [], categories: [] }));

      const r = response;

      const data = {
        title: r.title,
        pricePerSquareFootGraph: statisticGraph(r.pricePerSquareFootGraph),
        averageDaysOnMarketGraph: statisticGraph(r.averageDaysOnMarketGraph),
        averageMonthlyRentGraph: statisticGraph(r.averageMonthlyRentGraph),
        newPreForeclosuresGraph: statisticGraph(r.newPreForeclosuresGraph),
        daysOnMarketVsInventoryGraph: statisticGraph(r.daysOnMarketVsInventoryGraph),
        listPriceVsSalePriceGraph: statisticGraph(r.listPriceVsSalePriceGraph),
        oneMonthPriceChange: statisticUnit(r.oneMonthPriceChange),
        oneMonthRentChange: statisticUnit(r.oneMonthRentChange),
        marketTrends: [
          statisticUnit(r.newListingsChange, 'New Listings', r.newListings, StatisticUnitFormats.number),
          statisticUnit(r.closedSalesChange, 'Closed Sales', r.closedSales, StatisticUnitFormats.number),
        ],
        listingTrends: [
          statisticUnit(r.homesForSaleChange, 'Homes for Sale', r.homesForSale, StatisticUnitFormats.number),
          statisticUnit(r.averageListingPriceChange, 'Avg. List $', r.averageListingPrice, StatisticUnitFormats.currency),
          statisticUnit(r.averageListingPricePerSquareFootChange, 'Avg. List $/Sq. Ft.', r.averageListingPricePerSquareFoot, StatisticUnitFormats.currency),
          statisticUnit(r.averageDaysOnMarketChange, 'Avg. DOM', r.averageDaysOnMarket, StatisticUnitFormats.number),
        ],
        salesTrends: [
          statisticUnit(r.homesSoldChange, 'Homes Sold', r.homesSold, StatisticUnitFormats.number),
          statisticUnit(r.averageSalePriceChange, 'Avg. Sold $', r.averageSalePrice, StatisticUnitFormats.currency),
          statisticUnit(r.averageSalePricePerSquareFootChange, 'Avg. Sold $/Sq. Ft.', r.averageSalePricePerSquareFoot, StatisticUnitFormats.currency,),
          statisticUnit(r.averageSaleDaysOnMarketChange, 'Avg. DOM', r.averageSaleDaysOnMarket, StatisticUnitFormats.number),
        ],
      };

      return state.mergeIn(['statistic'], fromJS({ loading: false, stale: false, data }));
    }

    case ActionType.EXPORT_STATISTICS_SUCCESS:
    case ActionType.EXPORT_STATISTICS_ERROR:
    case ActionType.SEARCH_STATISTICS_ERROR:
      return state.setIn(['statistic', 'loading'], false);

    case ActionType.SEARCH_FAVORITES:
      return state.set('favoriteSearchLoading', true);

    case ActionType.SEARCH_FAVORITES_SUCCESS:
      return state.merge({ favoriteSearchLoading: false, favoriteSearchStale: false, favoriteSearchCounts: fromJS((response || []).reduce((m, s) => ({ ...m, [s.id]: s.totalCount }), {})) });

    case ActionType.SEARCH_FAVORITES_ERROR:
      return state.set('favoriteSearchLoading', false);

    case ActionType.SAVE_SEARCH:
    case ActionType.DELETE_SEARCH:
    case ActionType.ASSIGN_SEARCH_SLOT:
      return state.set('favoriteSearchLoading', false);

    case ActionType.GET_SAVED_SEARCHES:
      // Only happens in the background and don't want the app to reflect as "loading" searches.
      return state;

    case ActionType.SAVE_SEARCH_SUCCESS:
    case ActionType.DELETE_SEARCH_SUCCESS:
    case ActionType.ASSIGN_SEARCH_SLOT_SUCCESS:
      return loadSavedSearches(state, response);

    case ActionType.GET_SAVED_SEARCHES_SUCCESS:
      // Array with null first item is an indicator that searches are refreshing, so retain existing ones in the meantime.
      return action.response && !action.response[0] ? state : loadSavedSearches(state, response);

    case ActionType.SAVE_SEARCH_ERROR:
    case ActionType.DELETE_SEARCH_ERROR:
    case ActionType.ASSIGN_SEARCH_SLOT_ERROR:
    case ActionType.GET_SAVED_SEARCHES_ERROR:
      return state.set('savedSearchLoading', false);

    // new desing filter
    case ActionType.LOCAL_UPDATE_FILTER:
      return updateLocalFilterAction(state, action.localFilter);

    case ActionType.LOCAL_REMOVE_FILTER:
      return removeLocalFilterItemById(state, action.id);

    case ActionType.REMOVE_FILTER_BY_KEY:
      return removeFilterById(state, action.id);

    case ActionType.LOCAL_CLEAR_FILTER:
      return clearLocalFilter(state)

    case ActionType.FILTER_SHOW_ALL_EDIT:
      return setShowAllbuttonOption(state, action.data)

    case ActionType.FILTER_SHOW_ALL_CLEAR:
      return clearShowAllbuttonOption(state)

    case ActionType.LEAD_LIST_SUCCESS:
      return setLeadList(state, response)

      case ActionType.FILTER_SAVE_SEARCH:
        return state.set('selectedsavesearch', action.name);
      
        case ActionType.LEAD_LIST_CLEAR:
          return state.set('leadeList', []);
        
          case ActionType.SET_FAVORITE_ITEM:
            return state.set('favoriteSelectedName', action.name);
          

            
    default:
      return state;
  }
}
