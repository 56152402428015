import React, { PureComponent } from 'react';
import { Field } from 'redux-form/immutable';

import Checkbox from './Checkbox';


export default class CheckboxFormed extends PureComponent {
  render() {
    return (
      <Field {...this.props} component={Checkbox} />
    );
  }
}
