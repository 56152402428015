import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFilterById,
  removeLocalFilter,
  selectLocalFilter,
  selectNewFilter,
  selectShowAllEditOption,
  updateFilter,
  updateLocalFilter
} from "../../../../../../data/search";
import newsearchfilter from "../../SearchFilterNew.scss"; // Import the SCSS file
import { useFilter } from "../../TagFilterContext";
import FilterComboBox from "../../shared/Autosearch/FilterComboBox";
import {
  BankruptcyChapterOptions,
  BankruptcyOptions,
  DivorceOptions,
  LBDRecordDateOptions,
  LienAmountOptions,
  LienOptions,
  LienTypeCodeList,
  fieldTagsMap,
  formatDateShortWithUTC,
  TaxDelinquentYearOptions
} from "../../shared/ConstantsNew";
import SelectAnyoneByMinMax from "../../shared/SelectAnyoneDate/SelectAnyoneByMinMax";
import ToggleSection from "../../shared/ToggleSection";
import ToggleList from "../../shared/ToggleSection/ToggleList";
import moment from "moment";
import { generateMinMaxDateValue, generateMinMaxValue, getMinMaxDateToUpdate, getMinMaxValuesToUpdate } from "../../../../../../utils/filter_menu/minandmax";
const LiensBankruptcy = () => {
  const getLocalFilter = useSelector(selectLocalFilter);
  const { selectedTags, filteredFields, navIndex } = useFilter();
  const [isFilterApplied, setFilterApplied] = useState(false);

  const refs = useRef({});
  // console.log(getLocalFilter)
  const dispatch = useDispatch();
  const filter = useSelector(selectNewFilter);
  const [lbd, setlbValues] = useState({
    lienActive: null,
    bankruptcyActive: null,
    divorceActive: null,
    lienTypeCode: null,
    lienAmountMin: null,
    lienAmountMax: null,
    divorceRecordingDateMin: null,
    divorceRecordingDateMax: null,
    bankruptcyRecordingDateMin: null,
    bankruptcyRecordingDateMax: null,
    lienRecordingDateMin: null,
    lienRecordingDateMax: null,
    delinquentTaxYearMin: null,
    delinquentTaxYearMax: null
  });

  useEffect(() => {
    if (filter) {
      setlbValues(filter);
      setFilterApplied(true);
    }
  }, [filter]);

  useEffect(() => {
    if (isFilterApplied) {
      applyFiltersBasedOnLiensBankruptcy(lbd);
    }
  }, [isFilterApplied]);

  const applyFiltersBasedOnLiensBankruptcy = (l) => {
    const fieldsToCheck = [
      // Range fields
      { label: "Number of Liens", min: l.lienCountMin, max: l.lienCountMax, key: "lienCount" },
      { label: "Lien Amount", min: l.lienAmountMin, max: l.lienAmountMax, key: "lienAmount" },
      { label: "Tax Delinquent Year", min: l.delinquentTaxYearMin, max: l.delinquentTaxYearMax, key: "delinquentTaxYear" },
      
      // Date fields
      { label: "Lien Date", min: l.lienDateMin, max: l.lienDateMax, key: "lienDate", isDate: true },
      
      // Select fields
      { label: "Lien Types", value: l.lienTypeCode, key: "lienTypeCode", options: LienTypeCodeList },
      { label: "Bankruptcy Type", value: l.bankruptcyChapter, key: "bankruptcyChapter", options: BankruptcyChapterOptions },
      
      // ComboBox fields
      { label: "Total Liens Amount", min: l.totalLiensAmountMin, max: l.totalLiensAmountMax, key: "totalLiensAmount" },
      { label: "Lien to Value Ratio %", min: l.lienToValueRatioMin, max: l.lienToValueRatioMax, key: "lienToValueRatio" },
      
      // Boolean fields
      { label: "Lien", value: l.lienActive, key: "lienActive", options: LienOptions, isBool: true },
      { label: "Bankruptcy", value: l.bankruptcyActive, key: "bankruptcyActive", options: BankruptcyOptions, isBool: true }
    ];
  
    fieldsToCheck.forEach(({ label, min, max, key, isBool, isDate, value, options }) => {
      if (isBool) {
        const option = options.find(option => option.value === value);
        if (option) {
          handleBooleanSelectPCNew(option, key, label);
        } else {
          handleClearFilter(label);
        }
      } 
      else if (isDate) {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboDateValueChange(min, max, label, key);
        }
      } 
      else if (Array.isArray(options) && options.length) {
        const selectedValues = value ? value.split(',') : [];
        const selectedOptions = options.filter(option => selectedValues.includes(option.id));
        if (selectedOptions.length) {
          handleSelectAllClick(selectedOptions, label, key);
        } else {
          handleClearFilter(label);
        }
      } 
      else {
        if ((min !== null && min !== '' && min !== undefined) || (max !== null && max !== '' && max !== undefined)) {
          comboBoxValueChange(min, max, label, key);
        } else if (value !== null && value !== '' && value !== undefined) {
          handleOptionSelectPCNew({ label: value, value }, key, label);
        } else {
          handleClearFilter(label);
        }
      }
    });
  };

  const getEditOptions = useSelector(selectShowAllEditOption)


  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key === 'lbd') {
        let key = getEditOptions?.apiKey.replace(/Max|Min/g, "")
        editSectionClick(key ? key : '')
      }
    }
  }, [getEditOptions]);


  const editSectionClick = (id) => {
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200);
  };


  const handleOptionSelectPCNew = (option, key, leftLabel) => {
    // add again
    updateFilterOldStore(key, option.value);
    updateFilterStore(option, key, leftLabel);
  };

  const stringToBoolean = str => {
    // Convert "true" to true (case insensitive)
    if (str === "true") {
      return true;
    }
    // Convert "false" to false (case insensitive)
    else if (str === "false") {
      return false;
    }
    // For any other string, return undefined or handle accordingly
    else {
      return str;
    }
  };

  // combox
  const comboBoxValueChange = (minValue, maxValue, leftLabel, key) => {
    const name = generateMinMaxValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxValuesToUpdate(minValue, maxValue, key);

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };

  const handleSelectAllClick = (selectedOptions, leftValue, key) => {
    let name = "";
    if (selectedOptions?.length) {
      if (selectedOptions?.length > 1) {
        name = `${selectedOptions[0]?.name}  ( ${selectedOptions.length} )`;
      } else {
        name = `${selectedOptions[0]?.name}`;
      }

      const selectedIds = selectedOptions.map(element => element.id).join(",");
      const fullSeletedNamesList = selectedOptions
        .map(element => element.name)
        .join(",");
      updateFilterOldStore(key, selectedIds);
      // meed to work morning
      updateFilterStore(
        { value: key, label: name },
        key,
        leftValue,
        fullSeletedNamesList
      );
    } else {
      handleOldRemoveFilter(key);
      handleClearFilter(leftValue);
    }
  };

  // combo date
  const comboDateValueChange = (minValue, maxValue, leftLabel, key) => {
   
    const name = generateMinMaxDateValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxDateToUpdate(minValue, maxValue, key);

    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };

  const handleBooleanSelectPCNew = (option, key, leftLabel) => {
    // add again
    if (option.value === true || option.value === false || option.value !== '') {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel)
    }

  };

  // store
  const updateFilterOldStore = (key, id) => {
    // if (id) {
    dispatch(updateFilter({ [key]: id }));
    // }
  };
  const updateFilterStoreCombox = (name, key, leftLabel) => {
    // if (option.value) {
    dispatch(
      updateLocalFilter([
        {
          key: "lbd", id: leftLabel, name: name, apiKey: key,
          removeID: key,
          showALLDisplayValue: name
        }
      ])
    );
    // }
  };

  const updateFilterStore = (option, key, leftLabel, fullSeletedNamesList) => {
    // if (option.value) {
    // option.value = stringToBoolean(option.value);
    // name;
    // if (option.value) {
    dispatch(
      updateLocalFilter([
        {
          key: "lbd",
          id: leftLabel,
          name: option.label,
          apiKey: key,
          [key]: option.value,
          removeID: key,
          showALLDisplayValue: fullSeletedNamesList
        }
      ])
    );
    // } else {
    //   updateFilterOldStore(key);
    // }

    // }
  };

  const handleClearFilter = id => {
    dispatch(removeLocalFilter(id));
  };

  const handleOldRemoveFilter = id => {
    dispatch(removeFilterById(id));
  };

  const getClassNames = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    const lbdFilters = getLocalFilter.filter(filter => filter.key === 'lbd');
    return `${newsearchfilter.clsShowOnTag}`;
  };

  const getTagsForField = (fieldLabel) => {
    return fieldTagsMap[fieldLabel] || [];
  };

  const isFilteredSection = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    return selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag));
  };

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField === 'Liens Type' || highlightedField === 'Lien Recording Date' || highlightedField === 'Lien Amount'){
      setlbValues(prevState => ({ ...prevState, lienActive: true }));

      setTimeout(() => {

      if (highlightedField && refs.current[highlightedField]) {   
        refs.current[highlightedField].focus({ preventScroll: true });
        refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      }, 200)   
    }
    else if (highlightedField === 'Bankruptcy Type' || highlightedField === 'Bankruptcy Recording Date'){
      setlbValues(prevState => ({ ...prevState, bankruptcyActive: true }));

      setTimeout(() => {

      if (highlightedField && refs.current[highlightedField]) {   
        refs.current[highlightedField].focus({ preventScroll: true });
        refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      }, 200)   
    }
    else if (highlightedField === 'Divorce Filing Date'){
      setlbValues(prevState => ({ ...prevState, divorceActive: true }));

      setTimeout(() => {

      if (highlightedField && refs.current[highlightedField]) {   
        refs.current[highlightedField].focus({ preventScroll: true });
        refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      }, 200)   
    }
    else if (highlightedField === 'Tax Delinquent Year'){
      if (highlightedField && refs.current[highlightedField]) {   
        refs.current[highlightedField].focus({ preventScroll: true });
        refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
      } 
    }
    
  }, [filteredFields, navIndex]);

  return (
    <div className={classNames(getClassNames('Liens Type'), getClassNames('Lien Recording Date'), getClassNames('Lien Amount'), getClassNames('Bankruptcy Type'), getClassNames('Bankruptcy Recording Date'), getClassNames('Divorce Filing Date'))}>
      {/* <div className={newsearchfilter.clsRightBodyScroll}> */}
      <div className={newsearchfilter.clsContentBody}>
        <div className={newsearchfilter.headerBg}>
          <h2 className={newsearchfilter.clsHeading}>
            Lien, Bankruptcy, & Divorce
          </h2>
        </div>
        <h4 className={classNames(newsearchfilter.clsSubHeading, getClassNames('Liens Type'), getClassNames('Lien Recording Date'), getClassNames('Lien Amount'))}>Liens</h4>
        <div className={classNames(newsearchfilter.pb_28, getClassNames('Liens Type'), getClassNames('Lien Recording Date'), getClassNames('Lien Amount'))}>
          <div id="lienActive" className={newsearchfilter.clsBgGray}>
            <div className={classNames(getClassNames("Liens Type"), getClassNames('Lien Recording Date'), getClassNames('Lien Amount'))}>
            <div className={lbd.lienActive === true || lbd.lienActive === false ? newsearchfilter.pb_28 : ''}>
              <ToggleList
                headerTitle={"Liens"}
                headerTitleShow={false}
                backGroudGray={false}
                selected={lbd.lienActive}
                options={LienOptions}
                isHighlightIndex={filteredFields['Liens Type'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Liens Type") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={selectedOption => {
                  // reset sub types on tab update
                  handleOldRemoveFilter("lienTypeCode");
                  handleClearFilter("Liens Type");
                  handleOldRemoveFilter("lienRecordingDate");
                  handleClearFilter("Lien Recording Date");
                  handleOldRemoveFilter("lienAmount");
                  handleClearFilter("Lien Amount");
                  
                  handleBooleanSelectPCNew(selectedOption, "lienActive", "Lien");
                }}
              />
              </div>
             
               {(lbd.lienActive === true || lbd.lienActive === false) && (
                <>
                
              <div id="lienTypeCode" ref={el => refs.current['Liens Type'] = el}></div>
              <div className={newsearchfilter.pb_28}>
              <ToggleSection
                headerTitle={"Liens Type"}
                options={LienTypeCodeList}
                selectedIds={lbd.lienTypeCode}
                isHighlightIndex={filteredFields['Liens Type'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Liens Type") ? newsearchfilter.filteredSubTitle : ""}
                onSelectClickAction={selectedOptions => {
                  handleSelectAllClick(
                    selectedOptions,
                    "Liens Type",
                    "lienTypeCode"
                  );
                }}
              />
              </div>
              </>)}
            </div>

            {(lbd.lienActive === true || lbd.lienActive === false) && (
                <>  

            <div
              className={classNames(
                newsearchfilter.pb_28,
                newsearchfilter.clsBorderTop,
                getClassNames('Lien Recording Date')
              )}
            ></div>

            {/* <div className={newsearchfilter.pb_28}> */}
            <div id="lienRecordingDate" className={classNames(newsearchfilter.pb_28, getClassNames('Lien Recording Date'))} ref={el => refs.current['Lien Recording Date'] = el}>
              <div className={newsearchfilter.px_16}>
              <SelectAnyoneByMinMax
                headerTitle={"Lien Recording Date"}
                box2DefaultValue={"Any"}
                defaultValues={{
                  min: lbd.lienRecordingDateMin,
                  max: lbd.lienRecordingDateMax
                }}
                isHighlightIndex={filteredFields['Lien Recording Date'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Lien Recording Date") ? newsearchfilter.filteredSubTitle : ""}
                onValueChange={(fromDate, toDate) => {
                  if (fromDate || toDate) {
                    comboDateValueChange(
                      fromDate,
                      toDate,
                      "Lien Recording Date",
                      "lienRecordingDate"
                    );
                  } else {
                    handleOldRemoveFilter("lienRecordingDate");
                    handleClearFilter("Lien Recording Date");
                  }
                }}
                orText={"or"}
                options={LBDRecordDateOptions}
              />
              </div>
            </div>
            {/* </div> */}

            <div id="lienAmount" className={classNames(newsearchfilter.pb_28, getClassNames('Lien Amount'))} ref={el => refs.current['Lien Amount'] = el}>
            <div className={newsearchfilter.px_16}>
              <Row>
                <Col md={6} xs={12}>
                  <FilterComboBox
                    boxTittle={"Lien Amount"}
                    minOptions={LienAmountOptions}
                    maxOptions={LienAmountOptions}
                    minLabel={"Min"}
                    maxLabel={"Max"}
                    defaultValues={{
                      min: lbd.lienAmountMin,
                      max: lbd.lienAmountMax
                    }}
                    isHighlightIndex={filteredFields['Lien Amount'] === navIndex ? true : false}
                    headerClassName={isFilteredSection("Lien Amount") ? newsearchfilter.filteredSubTitle : ""}
                    onSelectValue={(minValue, maxValue) =>
                      comboBoxValueChange(
                        minValue,
                        maxValue,
                        "Lien Amount",
                        "lienAmount"
                      )
                    }
                  />
                </Col>{" "}
              </Row>
              </div>
            </div>

            </> 
              )}    

          </div>
        </div>

        {/*  */}
        <h4 className={classNames(newsearchfilter.clsSubHeading, getClassNames('Bankruptcy Type'), getClassNames('Bankruptcy Recording Date'))}>Bankruptcy</h4>
        <div className={classNames(newsearchfilter.pb_28, getClassNames('Bankruptcy Type'), getClassNames('Bankruptcy Recording Date'))}>
          <div id="bankruptcyActive" className={newsearchfilter.clsBgGray}>
            <div className={classNames(getClassNames('Bankruptcy Type'), getClassNames('Bankruptcy Recording Date'))}>
            <div className={lbd.bankruptcyActive === true || lbd.bankruptcyActive === false ? newsearchfilter.pb_28 : ''}>
              <ToggleList
                headerTitle={"Bankruptcy"}
                headerTitleShow={false}
                backGroudGray={false}
                selected={lbd.bankruptcyActive}
                options={BankruptcyOptions}
                onSelectOption={selectedOption => {
                  // reset sub types on tab update
                  handleOldRemoveFilter("bankruptcyChapter");
                  handleClearFilter("Bankruptcy Type");
                  handleOldRemoveFilter("bankruptcyRecordingDate");
                  handleClearFilter("Bankruptcy Recording Date");
                  
                  handleBooleanSelectPCNew(
                    selectedOption,
                    "bankruptcyActive",
                    "Bankruptcy"
                  );
                }
                }
              />
              </div>
              
                 {(lbd.bankruptcyActive === true || lbd.bankruptcyActive === false) && (
                <>  
              <div id="bankruptcyChapter" ref={el => refs.current['Bankruptcy Type'] = el}></div>
              <div className={newsearchfilter.pb_28}>
              <ToggleSection
                headerTitle={"Bankruptcy Type"}
                options={BankruptcyChapterOptions}
                selectedIds={lbd.bankruptcyChapter}
                isHighlightIndex={filteredFields['Bankruptcy Type'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Bankruptcy Type") ? newsearchfilter.filteredSubTitle : ""}
                onSelectClickAction={selectedOptions => {
                  handleSelectAllClick(
                    selectedOptions,
                    "Bankruptcy Type",
                    "bankruptcyChapter"
                  );
                }}
              />
              </div>
               </> 
              )}
            </div>

            {(lbd.bankruptcyActive === true || lbd.bankruptcyActive === false) && (
                <>  
            <div
              className={classNames(
                newsearchfilter.pb_28,
                newsearchfilter.clsBorderTop,
                getClassNames('Bankruptcy Recording Date')
              )}
            ></div>

            <div id="bankruptcyRecordingDate" ref={el => refs.current['Bankruptcy Recording Date'] = el} className={classNames(newsearchfilter.pb_28, getClassNames('Bankruptcy Recording Date'))}>
            <div className={newsearchfilter.px_16}>
              <SelectAnyoneByMinMax
                headerTitle={"Bankruptcy Recording Date"}
                box2DefaultValue={"Any"}
                isHighlightIndex={filteredFields['Bankruptcy Recording Date'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Bankruptcy Recording Date") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{
                  min: lbd.bankruptcyRecordingDateMin,
                  max: lbd.bankruptcyRecordingDateMax
                }}
                onValueChange={(fromDate, toDate) => {
                  if (fromDate || toDate) {
                    comboDateValueChange(
                      fromDate,
                      toDate,
                      "Bankruptcy Recording Date",
                      "bankruptcyRecordingDate"
                    );
                  } else {
                    handleOldRemoveFilter("bankruptcyRecordingDate");
                    handleClearFilter("Bankruptcy Recording Date");
                  }
                }}
                orText={"or"}
                options={LBDRecordDateOptions}
              />
              </div>
            </div>
            </>)}

          </div>
        </div>

        <h4 className={classNames(newsearchfilter.clsSubHeading, getClassNames('Divorce Filing Date'))}>Divorce</h4>
        <div className={classNames(newsearchfilter.clsBgGray, getClassNames('Divorce Filing Date'))}>
          <div id="divorceStatus"> 
          <div className={lbd.divorceActive === true || lbd.divorceActive === false ? newsearchfilter.pb_28 : ''}>
            <ToggleList
              headerTitle={"Divorce"}
              headerTitleShow={false}
              backGroudGray={false}
              selected={lbd.divorceActive}
              options={DivorceOptions}
              isHighlightIndex={filteredFields['Divorce Filing Date'] === navIndex ? true : false}
              headerClassName={isFilteredSection("Divorce Filing Date") ? newsearchfilter.filteredSubTitle : ""}
              onSelectOption={selectedOption => {
                // reset sub types on tab update
                handleOldRemoveFilter("divorceRecordingDate");
                handleClearFilter("Divorce Filing Date");

                handleBooleanSelectPCNew(selectedOption, "divorceActive", "Divorce");
              }
              }
            />
            </div>
          </div>
          {(lbd.divorceActive === true || lbd.divorceActive === false) && (
                <>  
          <div id="divorceRecordingDate" ref={el => refs.current['Divorce Filing Date'] = el} className={newsearchfilter.pb_28}>
          <div className={newsearchfilter.px_16}>
            <SelectAnyoneByMinMax
              headerTitle={"Divorce Recording Date"}
              box2DefaultValue={"Any"}
              defaultValues={{
                min: lbd.divorceRecordingDateMin,
                max: lbd.divorceRecordingDateMax
              }}
              isHighlightIndex={filteredFields['Divorce Filing Date'] === navIndex ? true : false}
              headerClassName={isFilteredSection("Divorce Filing Date") ? newsearchfilter.filteredSubTitle : ""}
              onValueChange={(fromDate, toDate) => {
                if (fromDate || toDate) {
                  comboDateValueChange(
                    fromDate,
                    toDate,
                    "Divorce Filing Date",
                    "divorceRecordingDate"
                  );
                } else {
                  handleOldRemoveFilter("divorceRecordingDate");
                  handleClearFilter("Divorce Filing Date");
                }

              }}
              orText={"or"}
              options={LBDRecordDateOptions}
            />
            </div>
          </div>
          </>)}
        </div>

        <div className={classNames(newsearchfilter.pb_28, newsearchfilter.pt_28, getClassNames('Tax Delinquent Year'))}>
          <div id='delinquentTaxYear' />
          <Row>
            <div ref={el => refs.current['Tax Delinquent Year'] = el}></div>
            <Col md={6} xs={12} className={getClassNames('Tax Delinquent Year')}>
              <FilterComboBox
                boxTittle={"Tax Delinquent Year"}
                minOptions={TaxDelinquentYearOptions}
                minLabel={"Min"}
                maxLabel={"Max"}
                maxOptions={TaxDelinquentYearOptions}
                isHighlightIndex={filteredFields['Tax Delinquent Year'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Tax Delinquent Year") ? newsearchfilter.filteredSubTitle : ""}
                defaultValues={{ min: lbd.delinquentTaxYearMin, max: lbd.delinquentTaxYearMax }}
                onSelectValue={(minValue, maxValue) =>
                  comboBoxValueChange(minValue, maxValue, "Tax Delinquent Year", "delinquentTaxYear")
                }
              />
            </Col>
          </Row>
        </div>

      </div>
    </div>
    // </div>
  );
};

export default LiensBankruptcy;
