/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *  Don't update files without changing mortgage.source
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
import AdjustableRateMortgageAdjustmentTypes from './AdjustableRateMortgageAdjustmentTypes';
import AdjustableRateMortgageHybridTypes from './AdjustableRateMortgageHybridTypes';
import AdjustableRateMortgageRateCapTypes from './AdjustableRateMortgageRateCapTypes';
import AdjustableRateMortgageTypes from './AdjustableRateMortgageTypes';
import ArmRate from './ArmRate';
import MortgageFrequencies from './MortgageFrequencies';
import MortgagePeriod from './MortgagePeriod';
import MortgageTypes from './MortgageTypes';
import getArmHybridTerm from './getArmHybridTerm';
import getMortgageFrequencyTerm from './getMortgageFrequencyTerm';


/* eslint-disable eqeqeq */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
class Mortgage {
  static round(d) {
    return Math.round(d * 100) / 100;
  }

  static today() {
    const d = new Date();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate());
  }

  static monthDiff(d1, d2) {
    return Math.max(
      0,
      (((d2.getFullYear() - d1.getFullYear()) * 12) - (d1.getMonth() + 1)) + d2.getMonth(),
    );
  }

  constructor(mortgage = {}, id = 0) {
    this.id = id;

    this.extraPayments = !!(mortgage.extraPaymentAmount || mortgage.extraPayments);
    Object.assign(this, mortgage);
    this.setNonNullDefaults();
  }

  setNonNullDefaults() {
    this.firstPaymentDate = this.firstPaymentDate || Mortgage.today();
    this.type = this.type || MortgageTypes.FIXED_INTEREST;
    this.compoundFrequency = this.compoundFrequency || MortgageFrequencies.MONTHLY;
    this.armType = this.armType || AdjustableRateMortgageTypes.STANDARD;
    this.armHybridType = this.armHybridType || AdjustableRateMortgageHybridTypes.ARM_10_1;
    this.armRateCapType = this.armRateCapType || AdjustableRateMortgageRateCapTypes.RATE;


    let props = ['amount', 'downPaymentAmount', 'armIncreaseRate', 'armIncreaseRateMax', 'armRateMax',
      'extraPaymentAmount', 'fixedInterestRate', 'interestOnlyRate', 'seq', 'fixedInterestTerm',
      'interestOnlyTerm', 'armFixedInterestTerm', 'armRateIncreaseTerm', 'balloonPaymentNumber'];
    for (let i = 0; i < props.length; i++) {
      if (typeof this[props[i]] !== 'number') {
        this[props[i]] = 0;
      }
    }

    props = ['adjustableRate', 'balloon', 'biWeekly', 'doublePrincipal'];
    for (let i = 0; i < props.length; i++) {
      if (typeof this[props[i]] !== 'boolean') {
        this[props[i]] = false;
      }
    }

    this.useStructsWithValidProtos();
  }

  useStructsWithValidProtos() {
    if (!(this.firstPaymentDate instanceof Date)) this.firstPaymentDate = new Date(this.firstPaymentDate || Date.now());
  }

  copy(m) {
    this.type = m.type;
    this.compoundFrequency = m.compoundFrequency;
    this.extraPaymentFrequency = m.extraPaymentFrequency;
    this.armType = m.armType;
    this.armHybridType = m.armHybridType;
    this.armRateCapType = m.armRateCapType;
    this.firstPaymentDate = m.firstPaymentDate;
    this.amount = m.amount;
    this.downPaymentAmount = m.downPaymentAmount;
    this.armIncreaseRate = m.armIncreaseRate;
    this.armIncreaseRateMax = m.armIncreaseRateMax;
    this.armRateMax = m.armRateMax;
    this.extraPaymentAmount = m.extraPaymentAmount;
    this.fixedInterestRate = m.fixedInterestRate;
    this.interestOnlyRate = m.interestOnlyRate;
    this.fixedInterestTerm = m.fixedInterestTerm;
    this.interestOnlyTerm = m.interestOnlyTerm;
    this.armFixedInterestTerm = m.armFixedInterestTerm;
    this.armRateIncreaseTerm = m.armRateIncreaseTerm;
    this.balloonPaymentNumber = m.balloonPaymentNumber;
    this.adjustableRate = m.adjustableRate;
    this.balloon = m.balloon;
    this.biWeekly = m.biWeekly;
    this.doublePrincipal = m.doublePrincipal;
  }

  getPrincipal() {
    return this.amount/* - this.downPaymentAmount*/;
  }

  getEffectiveFixedInterestTerm() {
    return this.type != MortgageTypes.INTEREST_ONLY ? this.fixedInterestTerm : 0;
  }

  getEffectiveInterestOnlyTerm() {
    return this.type == MortgageTypes.INTEREST_ONLY || this.type == MortgageTypes.COMBINATION ?
      this.interestOnlyTerm : 0;
  }

  getEffectiveTotalTerm() {
    return this.getEffectiveFixedInterestTerm() + this.getEffectiveInterestOnlyTerm();
  }

  getEffectiveArmFixedInterestTerm() {
    if (this.armHybridType == AdjustableRateMortgageHybridTypes.CUSTOM) {
      return this.armFixedInterestTerm;
    }

    if (this.armHybridType != null) {
      return getArmHybridTerm(this.armHybridType);
    }

    return 0;
  }

  getEffectiveArmRateIncreaseTerm() {
    if (this.armType != null) {
      if (
        this.armType == AdjustableRateMortgageTypes.STANDARD ||
        this.armHybridType == AdjustableRateMortgageHybridTypes.CUSTOM
      ) {
        return this.armRateIncreaseTerm;
      }

      if (this.armHybridType != null) {
        return 12;
      }
    }

    return 0;
  }

  getEffectiveBalloonPaymentIndex() {
    return this.balloon ? this.balloonPaymentNumber : 0;
  }

  getEffectiveBiWeekly() {
    return this.type == MortgageTypes.ACCELERATED && this.biWeekly;
  }

  getEffectiveDoublePrincipal() {
    return this.type == MortgageTypes.ACCELERATED && this.doublePrincipal;
  }

  getEffectiveExtraPaymentAmount() {
    return this.type == MortgageTypes.ACCELERATED ? this.extraPaymentAmount : 0;
  }

  getEffectiveExtraPaymentFrequency() {
    return this.type == MortgageTypes.ACCELERATED ? this.extraPaymentFrequency : null;
  }

  getEffectiveFixedInterestRate() {
    return this.type != MortgageTypes.INTEREST_ONLY ? this.fixedInterestRate : 0;
  }

  getEffectiveFixedInterestPayment() {
    return this.type != MortgageTypes.INTEREST_ONLY ? this.fixedInterestPaymentAmount : 0;
  }

  getEffectiveInterestOnlyRate() {
    return this.type == MortgageTypes.INTEREST_ONLY || this.type == MortgageTypes.COMBINATION ?
      this.interestOnlyRate : 0;
  }

  getEffectiveInterestOnlyPayment() {
    return this.type == MortgageTypes.INTEREST_ONLY || this.type == MortgageTypes.COMBINATION ?
      this.interestOnlyPaymentAmount : 0;
  }

  getMortgageRate() {
    return this.type == MortgageTypes.INTEREST_ONLY ? this.interestOnlyRate : this.fixedInterestRate;
  }

  getMortgageTerm() {
    return this.type == MortgageTypes.INTEREST_ONLY ? this.interestOnlyTerm : this.fixedInterestTerm;
  }

  getMortgageMonthlyPayment() {
    return this.type == MortgageTypes.INTEREST_ONLY ? this.interestOnlyPaymentAmount : this.fixedInterestPaymentAmount;
  }

  isEffectiveAdjustableRate() {
    return this.adjustableRate && this.type != MortgageTypes.COMBINATION;
  }

  calculateInterestPerPayment(rate, compoundPeriod) {
    const timesCompounded = 12 / getMortgageFrequencyTerm(compoundPeriod);

    return ((1 + (rate / timesCompounded)) ** (timesCompounded / 12)) - 1;
  }

  calculateMonthlyPayment(principal, durationMonths, rate, compoundPeriod) {
    let payment = 0;

    if (typeof compoundPeriod !== 'undefined') {
      rate = this.calculateInterestPerPayment(rate, compoundPeriod);
    }

    if (durationMonths != 0 && principal != 0) {
      if (rate == 0) {
        payment = principal / durationMonths;
      } else {
        payment = (principal * rate) / (1 - (1 / ((1 + rate) ** durationMonths)));
      }
    }

    return Mortgage.round(payment);
  }

  calculateNumberOfPayments(payment, futureValue, presentValue, rate, paymentsPerYear) {
    let n = 0;

    if (paymentsPerYear > 0) {
      rate /= paymentsPerYear;
      payment = -payment;

      if (rate != 0) {
        if ((payment + (presentValue * rate)) != 0 && Math.log(1 + rate) != 0) {
          n = Math.log((payment - (futureValue * rate)) / (payment + (presentValue * rate))) / Math.log(1 + rate);
        }
      } else if (payment > 0) { n = -(presentValue + futureValue) / payment; }
    }
    return Math.round(n);
  }

  calculateRoiPv(fv, roi) {
    return roi == -1 ? 0 : fv / (1 + roi);
  }

  calculateTotalInterest(principal, durationMonths, rate, compoundPeriod) {
    if (principal == 0 || durationMonths == 0 || rate == 0) { return 0; }

    const payment = this.calculateMonthlyPayment(principal, durationMonths, rate, compoundPeriod);
    const periodInterest = rate / 1200;
    let totalInterest = 0;

    for (let i = 1; principal > 0; i++) {
      // Add interest
      const interest = principal * periodInterest;

      totalInterest += interest;

      // Add payment
      principal += interest;
      principal -= i == durationMonths ? principal : payment;
    }

    return Mortgage.round(totalInterest);
  }

  interestHelper1(futureValue, monthlyPayment, presentValue, annualInterest, numberOfPayments) {
    return presentValue + (
      (
        monthlyPayment * (
          1 - (
            (1 + annualInterest) ** -numberOfPayments)
        )
      ) / annualInterest
    ) + (
      futureValue * ((1 + annualInterest) ** -numberOfPayments)
    );
  }

  interestHelper2(futureValue, monthlyPayment, annualInterest, numberOfPayments) {
    return (
      (
        -futureValue * ((annualInterest + 1) ** (-1 - numberOfPayments)) * numberOfPayments
      ) - (
        ((1 / annualInterest) ** 2) * monthlyPayment
      )) + (
      (
        (
          1 / (annualInterest ** 2)
        ) * (
          (1 + annualInterest) ** -numberOfPayments
        )
      ) * monthlyPayment
    ) + (
      (
        (
          (
            1 / annualInterest
          ) * (
            (1 + annualInterest) ** (-1 - numberOfPayments)
          )
        ) * numberOfPayments
      ) * monthlyPayment
    );
  }

  calculateRate(futureValue, presentValue, payment, payments) {
    if (presentValue == futureValue || presentValue == 0 || payment <= 0 || payments <= 0) { return 0; }

    let v;
    let rate = 0.0001;
    let i = 0;

    payment = -payment;
    while ( // eslint-disable-line no-cond-assign
      i++ <= 1000 &&
      Math.abs(v = (this.interestHelper1(futureValue, payment, presentValue, rate, payments))) > 0.001
      ) {
      rate -= v / this.interestHelper2(futureValue, payment, rate, payments);
    }

    return rate * 12;
  }

  calculatePresentValue(principal, durationMonths, rate, paymentIndex, compoundPeriod) {
    if (principal == 0 || durationMonths == 0 || rate == 0) { return 0; }

    const payment = this.calculateMonthlyPayment(principal, durationMonths, rate, compoundPeriod);
    const periodInterest = rate / 1200;

    for (let i = 1; i <= paymentIndex; i++) {
      // Add interest then subtract payment
      principal += principal * periodInterest;
      principal -= i == durationMonths ? principal : payment;
    }

    return Mortgage.round(principal);
  }

  // * Future Value = 0 for Full Amortization, = -[BalloonAmount] if there is a balloon payment
  getPresentValue(futureValue, payment, ror, payments) {
    const i = ror / 1200;
    const p = -payment;

    if (payments == 0 || i == 0) { return 0; }

    return (((p / i) - futureValue) / ((1.0 + i) ** payments)) - (p / i);
  }

  getLastPeriod() {
    return this.periods == null || !this.periods.length ? new MortgagePeriod() : this.periods[this.periods.length - 1];
  }

  getLastPaymentDate() {
    return this.getLastPeriod().date;
  }

  setLastPaymentDate(d) {
    d.setMonth(d.getMonth() - Math.max(0, this.getEffectiveTerm() - 1));

    this.firstPaymentDate = d;
  }

  getEffectiveTerm() {
    const d = this.getLastPaymentDate();

    return d == null ? this.getEffectiveFixedInterestTerm() : Mortgage.monthDiff(this.firstPaymentDate, d) + 1;
  }

  getInterestTotal() {
    return this.getLastPeriod().cumulativeInterest;
  }

  getPaymentTotal() {
    return this.downPaymentAmount + (this.getPrincipal() - this.getLastPeriod().balance) + this.getInterestTotal();
  }

  getBalloonPayment() {
    return this.balloon ? this.getLastPeriod().payment : 0;
  }

  generateArmRates() {
    // Create an empty schedule unless a custom one was provided
    if (!this.isEffectiveAdjustableRate() || this.armRates == null) { this.armRates = []; }

    // Generate the schedule for a standard or hybrid ARM
    if (this.isEffectiveAdjustableRate()) {
      const armStartingRate = this.type == MortgageTypes.INTEREST_ONLY ? this.interestOnlyRate : this.fixedInterestRate;
      const armMinRate = 0;
      const armMaxRate = this.armRateCapType == AdjustableRateMortgageRateCapTypes.RATE ?
        this.armRateMax : armStartingRate + this.armIncreaseRateMax;

      if (this.armIncreaseRate != 0 && this.getEffectiveArmRateIncreaseTerm() > 0) {
        // Get start date
        let adjust = false;
        const date = new Date(this.firstPaymentDate);
        if (this.armType == AdjustableRateMortgageTypes.HYBRID) {
          date.setMonth(date.getMonth() + this.getEffectiveArmFixedInterestTerm());
          adjust = true; // Ensure that adjustment is made immediately after
          // fixed term (instead of waiting for an _armInterestAdjustmentFrequency cycle initially)
        }

        // Create schedule rows until 0% or max APR is reached
        let rate = armStartingRate;
        for (let elapsedMonths = 0; rate > armMinRate && rate < armMaxRate;) {
          if (adjust) {
            rate = Math.min(armMaxRate, Math.max(armMinRate, rate + this.armIncreaseRate));
            this.armRates.push(new ArmRate(new Date(date), rate, AdjustableRateMortgageAdjustmentTypes.ABSOLUTE));
            adjust = false;
          }

          date.setMonth(date.getMonth() + 1);
          if (++elapsedMonths >= this.getEffectiveArmRateIncreaseTerm()) {
            elapsedMonths = 0;
            adjust = true;
          }
        }
      }
    }
  }

  process() {
    this.setNonNullDefaults();

    // Calculate payments
    this.interestOnlyPaymentAmount = Mortgage.round(
      this.getPrincipal() * this.calculateInterestPerPayment(this.interestOnlyRate, this.compoundFrequency),
    );
    this.fixedInterestPaymentAmount = this.calculateMonthlyPayment(
      this.getPrincipal(),
      this.fixedInterestTerm,
      this.calculateInterestPerPayment(this.fixedInterestRate, this.compoundFrequency),
    );

    // Create ARM Schedule
    this.generateArmRates();

    const month = 30.31;

    this.periods = [];

    // Check if needed values are present and amortize mortgage.
    if (this.getPrincipal() > 0 && this.getEffectiveTotalTerm() > 0) {
      let armRateIndex = 0;
      let extraPaymentPeriod = 0;
      let elapsedMonths = 0;
      let monthAdvance = 0;
      let basePayment = 0;
      let periodRate = 0;
      const biWeekly = this.getEffectiveBiWeekly();
      const doublePrincipal = this.getEffectiveDoublePrincipal();
      const adjustableRate = this.isEffectiveAdjustableRate();
      const fixedInterestTerm = this.getEffectiveFixedInterestTerm();
      const interestOnlyTerm = this.getEffectiveInterestOnlyTerm();
      const totalTerm = interestOnlyTerm + fixedInterestTerm;
      let termPosition = -interestOnlyTerm;
      let prevMonth = this.firstPaymentDate.getDate() < 15 ? 0 : this.firstPaymentDate.getMonth() + 1;
      const extraPaymentAmount = this.getEffectiveExtraPaymentAmount();
      let nextDate = new Date(this.firstPaymentDate);
      const extraPaymentFrequency = this.getEffectiveExtraPaymentFrequency();
      const extraPaymentTerm = getMortgageFrequencyTerm(extraPaymentFrequency);

      // Get the starting interest rate - FIXME: doen't seem to get used
      // double rate;
      // if (isEffectiveAdjustableRate())
      // rate = armStartingRate;
      // else if (interestOnlyTerm > 0)
      // rate = interestOnlyRate;
      // else
      // rate = fixedInterestRate;

      // Determine the maximum balloon payment number (not implemented for accelerated mortgages)
      if (this.balloon && this.type != MortgageTypes.ACCELERATED) {
        this.balloonPaymentNumber = Math.min(
          this.balloonPaymentNumber,
          this.type == MortgageTypes.INTEREST_ONLY ? totalTerm : totalTerm - 2,
        );
      }

      let lp;
      let cp = new MortgagePeriod();
      cp.balance = this.getPrincipal();

      // Keep going while there is a principal balance and
      // we have not surpassed the total number of months (which is possible for IO loans)
      for (let i = 1; cp.balance > 0 && Math.floor(elapsedMonths + monthAdvance) < totalTerm; i++) {
        // Add period. (Original is just a place holder with zeroed values.)
        lp = cp;
        cp = new MortgagePeriod();
        cp.paymentNumber = i;
        cp.date = new Date(nextDate);
        this.periods.push(cp);

        // Advance current date. Do it here rather than the end of the loop
        // so that the final values reflect the actual final payment date
        elapsedMonths += monthAdvance;
        termPosition += monthAdvance;

        // If there is a balloon and we have reached the balloon payment,
        // make this payment the remaining principal balance
        if (this.balloon && i > this.balloonPaymentNumber) {
          cp.principal = lp.balance;
          cp.payment = cp.principal;
        } else {
          // Determine if there should be an APR change
          if (adjustableRate) {
            // Adjustable rate loan. Determine if we're at a rate change
            const armRate = armRateIndex < this.armRates.length ? this.armRates[armRateIndex] : null;
            if (armRate != null && armRate.effectiveDate.getTime() < cp.date.getTime()) {
              // Set based on the ARM schedule
              // rate = armRate.getType() == AdjustableRateMortgageAdjustmentTypes.ABSOLUTE ?
              // armRate.getRate() : rate + armRate.getRate();
              basePayment = 0;
              armRateIndex++;
            }
          } else if (this.type == MortgageTypes.COMBINATION && termPosition == 0) {
            // IO/Fixed Combination Loan. IO term is up, switch apr to fixed.
            // Note - ioMonthsRemaining is an int and not a decimal because
            // there are no bi-weekly IO loans, so periods will always advance by one month
            // rate = fixedInterestRate;
            basePayment = 0;
          }

          // Calculate payment amount if we need to
          if (basePayment == 0) {
            periodRate = this.calculateInterestPerPayment(
              termPosition < 0 ? this.interestOnlyRate : this.fixedInterestRate,
              this.compoundFrequency,
            );
            basePayment = termPosition < 0 ?
              Mortgage.round(this.getPrincipal() * periodRate)
              :
              this.calculateMonthlyPayment(lp.balance, fixedInterestTerm - Math.floor(termPosition), periodRate);

            if (biWeekly) {
              basePayment /= 2;
              periodRate = (6 * periodRate) / 13;
            }
          }

          // Determine extra payment amount and amount of time that has
          // elapsed
          let periodExtraPayment = 0;
          if (biWeekly) {
            // Add 2 weeks to the number of months that have passed
            monthAdvance = 14 / (365 / 12);

            // Determine extra payment amount
            if (extraPaymentAmount > 0 && extraPaymentFrequency != null) {
              if (extraPaymentFrequency == MortgageFrequencies.MONTHLY) {
                // If the extra payments are monthly, add the extra
                // payment each time we reach a new month
                if (prevMonth != cp.date.getMonth() + 1) {
                  prevMonth = cp.date.getMonth() + 1;
                  periodExtraPayment = extraPaymentAmount;
                }
              } else {
                // Add 2 weeks to the number of days that have
                // passed
                extraPaymentPeriod += 14;

                if (extraPaymentPeriod >= extraPaymentTerm * month) {
                  // If any other interval than a month, make the extra payment
                  // after the accumulated days meet or exceed the month interval multiplied by the
                  // "month" contstant (which is a magical real estate figure for number of days in a month).
                  // Then subtract the product from the accumulated days.
                  extraPaymentPeriod -= extraPaymentTerm * month;
                  periodExtraPayment = extraPaymentAmount;
                }
              }
            }

            // Advance the current date by 2 weeks
            nextDate = new Date(cp.date);
            nextDate.setDate(nextDate.getDate() + 14);
          } else {
            // Add one month to the number of months that have passed
            monthAdvance = 1;

            // Determine extra payment amount
            if (extraPaymentAmount > 0 && extraPaymentFrequency != null) {
              extraPaymentPeriod += 1;
              if (extraPaymentPeriod >= extraPaymentTerm) {
                extraPaymentPeriod -= extraPaymentTerm;
                periodExtraPayment = extraPaymentAmount;
              }
            }

            // Advance the current date by one month
            nextDate = new Date(this.firstPaymentDate);
            nextDate.setMonth(nextDate.getMonth() + cp.paymentNumber);
          }

          // Determine the period interest and payment amount
          if (termPosition < 0) {
            // This is an interest only payment, do not alter the remaining principal.
            // Note: Do not apply extra payment to IO loan. Extra payment loan processing should not reach this code anyway.
            cp.payment = this.interestOnlyPaymentAmount;
            cp.interest = this.interestOnlyPaymentAmount;
          } else {
            // Calculate period interest and apply it to remaining
            // principal
            cp.interest = Mortgage.round(lp.balance * periodRate);

            // Calculate period payment amount.
            cp.payment = basePayment + periodExtraPayment;
            if (doublePrincipal) {
              cp.payment = (cp.payment * 2) - cp.interest;
            }

            // If it's the final payment or the payment would exceed the balance, make it the full remaining balance
            cp.payment = Math.floor((elapsedMonths + monthAdvance)) == totalTerm ?
              (lp.balance + cp.interest) : Math.min(lp.balance + cp.interest, cp.payment);

            // Calculate amount applied to principal
            cp.principal = cp.payment - cp.interest;
          }
        }

        // Update cumulative values
        cp.balance = lp.balance - cp.principal;
        cp.cumulativeInterest = lp.cumulativeInterest + cp.interest;
        cp.cumulativePrincipal = lp.cumulativePrincipal + cp.principal;
      }
    }

    return this.periods;
  }
}
/* eslint-enable eqeqeq class-methods-use-this no-param-reassign */

export default Mortgage;
/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *  Don't update files without changing mortgage.source
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
