import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';

import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import Confirm, { type } from 'app/components/Confirm';
import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import SVG from 'components/base/SVG';
import { selectLoading } from 'data/property';
import { clearAll, loadSearch } from 'data/search';
import { withRouter } from 'react-router-dom';

import getFields, { writeFields, getDisplayFields } from './Constants';
import SearchFilterEditor from './Editor';
import css from './style.scss';


const warningMessage = 'Please note that changing your list filters will alter your alerts, settings and additional criteria set throughout this list.\nThe lists are set up for complete automation so changing and adjusting this list could result in loss of property listings.';

class SearchFilterViewer extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSave = this.handleSave.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    const { editMode, summaryMode, availableListingTypes } = this.props;
    const editSearch = this.props.search || {};

    const { formattedAddress, estimatedValueGrowthPeriod = 'ONE_MONTH' } = editSearch;
    const search = fromJS(editSearch).set('estimatedValueGrowthPeriod', estimatedValueGrowthPeriod);
    const fields = getFields(search, availableListingTypes).fields;

    if (formattedAddress) fields.unshift({ name: 'location', label: 'Location', value: formattedAddress });

    this.state = {
      fields,
      search,
      originalSearch: search,
      editMode: !!editMode,
      disclaimerVisible: !summaryMode,
      searchEnabled: !!getDisplayFields(fields).length,
    };
  }

  handleSave() {
    const { onSave, closePopup } = this.props;

    onSave(this.state.search, closePopup);
  }

  handleDelete() {
    const { onDelete, closePopup, confirm } = this.props;

    confirm({
      caption: 'Confirm Delete',
      onOk: () => onDelete(closePopup),
      okLabel: 'Delete',
      type: type.danger,
      question: 'Delete your custom filter and restore the original?',
    });
  }

  handleEdit() {
    this.setState({ editMode: true, disclaimerVisible: !this.state.editMode });
  }

  handleChange(changes) {
    this.setState({ search: this.state.search.merge(changes) });
  }

  handleReset() {
    this.setState({ search: this.state.originalSearch });
  }

  handleSearch() {
    const { closePopup, clearAll, loadSearch, history } = this.props;

    closePopup();
    history.push('/search');
    clearAll();
    loadSearch(this.state.search.toJS());
  }

  writeButtons() {
    const { loading, closePopup, editEnabled } = this.props;
    const { editMode, searchEnabled } = this.state;
    const btnProps = { loading };

    return (
      <div className={css.buttons}>
        <Button {...btnProps} onClick={closePopup}>{this.state.editMode ? 'Cancel' : 'Close'}</Button>
        {!editEnabled ? null : <SolidButton {...btnProps} onClick={this.handleEdit}>Edit List</SolidButton>}
        {editMode || !searchEnabled ? null : <SolidButton {...btnProps} onClick={this.handleSearch}>Rerun Search</SolidButton>}
      </div>
    );
  }

  render() {
    const { closePopup, summaryMode, onDelete } = this.props;
    const { editMode, search, originalSearch, disclaimerVisible } = this.state;

    return (
      <Modal isOpen uniqId="SearchFilterViewerPopup" padding="0" width="auto" onClose={closePopup}>
        <div className={classNames(css.viewer, { [css.editMode]: editMode })}>
          <div className={css.header}>{summaryMode ? `Current Summary Filters: ${originalSearch.get('name') || '<New>'}` : 'Current List Search Filters'}</div>
          {editMode ? (
            <SearchFilterEditor
              onClose={closePopup}
              onReset={this.handleReset}
              onChange={this.handleChange}
              onSave={this.handleSave}
              onDelete={onDelete ? this.handleDelete : null}
              search={search}
              summaryMode={summaryMode}
            />
          ) : (
            <div>
              {writeFields(this.state.fields)}
              {/* select list code */}
              {this.writeButtons()}
            </div>
          )}
          {summaryMode ? null : (
            <div className={classNames(css.footer, css.warning)}>
              <span>Caution:</span>
              {warningMessage}
            </div>
          )}
          {!editMode || !disclaimerVisible ? null : (
            <div className={css.disclaimer}>
              <div>
                <SVG icon="iconWarning" className={css.iconWarning} />
                <div className={css.warning}>
                  <span>Caution:</span>
                  {warningMessage}
                </div>
                {this.writeButtons()}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

SearchFilterViewer.propTypes = {
  editMode: PropTypes.bool,
  editEnabled: PropTypes.bool,
  summaryMode: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

SearchFilterViewer.defaultProps = {
  editMode: false,
  editEnabled: false,
  summaryMode: false,
};

const SearchFilterViewerPopup = withRouter(connect(state => ({
  loading: selectLoading(state),
}), {
  clearAll,
  loadSearch,
  confirm: Confirm.open,
})(SearchFilterViewer));

const registrationId = getPopupRegistration(SearchFilterViewerPopup);
SearchFilterViewerPopup.open = props => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });

export default SearchFilterViewerPopup;
