import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import YouTube from 'react-youtube';

import { selectProfile, selectLoading } from 'data/user/selectors';
import { upgradeAccount } from 'data/user';
import { deferExecutor } from 'app/DeferredOnLocation';
import { getBrandName, getBrandLogoUrl, isPropStream, isReiAutomator } from 'utils/brand';
import { SolidButton } from 'components/Button';
import Dropdown from 'components/base/Dropdown';
import currencyFormat from 'utils/currency/numberToPrice';
import numberFormat from 'utils/number/format';
import AddServiceConfirmation from 'app/components/AddServiceConfirmation';

import css from './style.scss';
import cssAccount from '../style.scss';


const NameTypes = {
  subscription: 'SUBSCRIPTION',
  listManagement: 'LIST_AUTOMATOR',
  offerAutomator: 'OFFER_AUTOMATOR',
};

class AccountServices extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.upgradeClick = this.upgradeClick.bind(this);

    const { planMrcs, profile: { subscriptionPlanMrcId, listManagementPlanMrcId } } = this.props;

    this.state = {
      subscriptionPlan: planMrcs.find(p => p.id === subscriptionPlanMrcId),
      listManagementPlan: planMrcs.find(p => p.id === listManagementPlanMrcId) || null,
    };

    this.state = {
      ...this.getLists(),
    };
  }

  getPlan(name) {
    return (this.state || {})[`${name}Plan`];
  }

  getPlans(name) {
    return this.props.planMrcs.filter(p => p.type === NameTypes[name]);
  }

  getList(name, newPlan) {
    const { profile: { paymentMethods, paymentMethodId } } = this.props;
    const { [`${name}PaymentMethodId`]: currentPaymentMethodId } = this.state;
    const plans = this.getPlans(name);
    const plan = newPlan || this.getPlan(name) || plans[0];
    const quantityOptions = plans.filter((p, i, ar) => p.quantity && i === ar.findIndex(l => l.quantity === p.quantity)).map(p => ({ value: p.quantity, label: numberFormat(p.quantity) }));
    const termOptions = plans.filter(p => !p.quantity || p.quantity === plan.quantity).map(p => ({ value: p.id, label: p.annual ? 'Annually' : 'Monthly' }));

    return {
      [`${name}QuantityOptions`]: quantityOptions,
      [`${name}TermOptions`]: termOptions,
      [`${name}Plan`]: plan,
      [`${name}PaymentMethodId`]: currentPaymentMethodId || paymentMethodId || (paymentMethods[0] || {}).id || null,
    };
  }

  getLists() {
    return {
      ...this.getList('subscription'),
      ...this.getList('listManagement'),
      ...this.getList('offerAutomator'),
    };
  }

  handleChange(ev) {
    const { name: fullName, value } = ev.target || ev;
    const name = fullName.replace('Quantity', '').replace('Term', '');
    const field = fullName.replace(name, '');
    let plan = this.getPlan(name);
    let plans = this.getPlans(name);

    if (field === 'Term') plan = plans.find(p => p.id === Number(value));
    else {
      plans = plans.filter(p => !plan.quantity || p.quantity === Number(value));
      plan = plans.find(p => p.annual === plan.annual) || plans[0];
    }

    this.setState({
      ...this.getList(name, plan),
    });
  }

  handleChange2(ev) {
    const { name, value } = ev.target || ev;
    this.setState({ [name]: Number(value) });
  }

  upgradeClick(name) {
    const { profile: { [`${name}PlanMrcId`]: planMrcId }, openAddServiceConfirmation, upgradeAccount, planMrcs } = this.props;

    const {
      [`${name}Plan`]: plan,
      [`${name}PaymentMethodId`]: paymentMethodId,
    } = this.state;
    const { id, amount } = plan;

    openAddServiceConfirmation({
      planMrcId: plan.id,
      paymentMethodId,
      amount,
      onPurchase: (amount, cardCode, password, onSuccess) => {
        upgradeAccount({ cardCode, paymentMethodId }, null, id, null, amount, password, ({ response: { success, message } }) => {
          this.setState({
            [`${name}Success`]: success,
            [`${name}Message`]: message,
          });

          onSuccess();
        });
      },
    });
  }

  writeLists(name) {
    const {
      [`${name}QuantityOptions`]: quantityOptions,
      [`${name}TermOptions`]: termOptions,
      [`${name}Plan`]: plan,
      [`${name}PaymentMethodId`]: paymentMethodId,
      [`${name}Success`]: success = null,
      [`${name}Message`]: message,
    } = this.state;

    const { paymentMethods } = this.props.profile;
    const dropdownProps = { onChange: this.handleChange };
    const { amount, annual } = plan;
    const fmt = currencyFormat(amount, null, { forceDecimal: true });
    const dec = fmt.indexOf('.');

    const paymentMethodOptions = (paymentMethods || []).map(({ id, description }) => ({ label: description, value: id }));

    return (
      <div className={css.lists}>
        {!quantityOptions.length ? null : (
          <div className={cssAccount.field}>
            <label htmlFor={`${name}Quantity`}>{name === 'subscription' ? 'Saves/Imports* & Exports' : 'Monitored Properties'}</label>
            <Dropdown {...dropdownProps} name={`${name}Quantity`} options={quantityOptions} value={String(plan.quantity)} />
          </div>
        )}
        <div className={cssAccount.field}>
          <label htmlFor={`${name}Term`}>Billed</label>
          <Dropdown {...dropdownProps} name={`${name}Term`} options={termOptions} value={plan.id} />
        </div>
        <div className={cssAccount.field}>
          <label htmlFor={`${name}PaymentMethodId`}>Payment Method</label>
          <Dropdown {...dropdownProps} name={`${name}PaymentMethodId`} options={paymentMethodOptions} value={paymentMethodId} onChange={this.handleChange2} />
        </div>
        <div className={css.price}>
          <div>
            <div>
              <div>{fmt.substr(0, dec)}</div>
              <div>{fmt.substr(dec)}</div>
            </div>
            <div>per {annual ? 'year' : 'month'}</div>
          </div>
        </div>
        {success == null ? null : <div className={classNames(css.message, { [css.success]: success })}>{success ? 'Service upgraded successfully!' : message}</div>}
      </div>
    );
  }

  render() {
    const { subscriptionPlanMrcId, listManagementPlanMrcId, listManagementEnabled } = this.props.profile;
    const { subscriptionPlan, listManagementPlan } = this.state;
    const brand = getBrandName();
    const propstream = isPropStream();
    const reiAutomator = isReiAutomator();

    let laImage;
    if (reiAutomator) laImage = '/assets/images/reiautomator-listAutomator-banner-trial.png';
    else laImage = `/assets/images/listAutomator-banner${listManagementPlan && listManagementPlan.trialAvailable ? '-trial' : '2'}.png`;

    return (
      <div className={css.services}>
        <div className={classNames(css.item, css.narrow)}>
          <div className={cssAccount.header}>Current {brand} Saves/Imports* &amp; Exports</div>
          <div className={css.content}>
            <div className={classNames(css.image, css.logo)}>
              <img src={getBrandLogoUrl()} alt="logo" />
            </div>
            <div className={css.inner}>
              <div className={css.description}>
                <p>
                  Looking to increase your monthly available Saves/Imports* &amp; Exports? Upgrade your subscription instantly below. By upgrading your subscription you are agreeing to the increased monthly fee in order to get more monthly Saves/Imports* &amp; Exports each month. To cancel please contact Customer Support.<br />
                  <br />
                  *Imports require List Automator add-on service to import records.
                </p>
              </div>
              {!subscriptionPlan ? null : (
                <div className={css.center}>
                  {this.writeLists('subscription')}
                  <SolidButton disabled={subscriptionPlanMrcId === subscriptionPlan.id} onClick={() => this.upgradeClick('subscription')}>Upgrade</SolidButton>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={classNames(css.item, css.listAutomator)}>
          <div className={cssAccount.header}>Add-Ons</div>
          <div className={css.content}>
            <div>
              <img className={css.image} src={laImage} alt="List Automator Banner" />
              <div className={css.left}>
                <div className={css.inner}>
                  <div className={css.description}>
                    <p className={css.title}>Introducing List Automator. The First-ever List Monitoring, Automation and Marketing Solution for your real estate business.</p>
                    <p>A property owner&apos;s situation can change quickly, altering their motivation to sell. Inevitably, properties in your lists are sold, get listed on the MLS, resolve their foreclosure issues, pay off their liens, etc., which significantly reduces your chances of acquisition.</p>
                    <p>List Automator, {brand}&apos;s new Data-Driven Automated List Management feature is a first of its kind tool. It utilizes all of {brand}&apos;s Big Data and automation to increase your odds of acquiring a property.</p>
                    <p>Increase list accuracy, save time, money, and remove the headaches associated with managing your real estate marketing lists manually with List Automator.</p>
                    <p>Using {brand}&apos;s current national databases, List Automator helps you identify the most motivated sellers in all of your lists to Stack and combine into a one. Doing so will reduce your marketing expenses and help you achieve higher conversions!</p>
                    <p>
                      <b>Are you having trouble identifying the most motivated sellers in all of your lists and combining them into one?</b>
                      <br />
                      List Automator will identify the most motivated leads and allow you to Stack and apply additional filters.
                    </p>
                  </div>
                </div>
                <div className={css.inner}>
                  <div className={css.description}>
                    <p>
                      <b>Have you ever bought a list and let it sit for too long, not knowing if it&apos;s still feasible to keep marketing?</b>
                      <br />
                      Now you can upload your list from any source and get the data instantly updated to ensure your list still meets your desired criteria (identify the leads you still want to go after)
                    </p>
                    <p>
                      <b>Have you ever wondered how many times you have targeted a specific lead?</b>
                      <br />
                      Now you can see how many times a property has been targeted via {brand}&apos;s marketing services and even identify if a property is duplicated across multiple lists and ensure you&apos;re not over marketing to the same property.
                      <br />
                      And, much more!
                    </p>
                  </div>
                  {!listManagementPlan ? null : (
                    <div className={css.redBox}>
                      {this.writeLists('listManagement')}
                      <SolidButton disabled={listManagementPlanMrcId === listManagementPlan.id} onClick={() => this.upgradeClick('listManagement')}>{(listManagementPlan.trialAvailable && 'Activate Trial') || (listManagementEnabled ? 'Upgrade' : 'Activate')}</SolidButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              {propstream ? <YouTube opts={{ width: '390', height: '220' }} videoId="a5-h2TDzFVI" /> : <img className={css.image} src="/assets/images/listAutomator-laptop.png" alt="YouTube" />}
              <div className={css.right}>
                <div className={css.inner}>
                  <div className={css.description}>
                    <p>
                      <b>Monitoring and Automation</b>
                      <ul>
                        <li>Automatically add new records that meet your criteria.</li>
                        <li>Automatically remove records that no longer meet your criteria.</li>
                        <li>Identify and isolate the newest records added and removed for review.</li>
                      </ul>
                    </p>
                    <p>
                      <b>Organize, Sort, and Stack lists</b>
                      <ul>
                        <li>Apply additional filters within your list to isolate various key situations or find those diamonds in the rough.</li>
                        <li>Advanced list sorting.</li>
                        <li>Identify properties that are in multiple lists and remove the redundancy.</li>
                        <li>Identify how many times a property has been marketed to.</li>
                      </ul>
                    </p>
                    <p>
                      <b>Import &amp; Export</b>
                      <ul>
                        <li>Easily import lists from another service to refresh, enhance, monitor, and to use {brand}&apos;s Skip Tracing &amp; Marketing Services.</li>
                        <li>Customize your layout/export to include over 110 additional fields.</li>
                      </ul>
                    </p>
                    <p>
                      <b>Marketing</b>
                      <ul>
                        <li>Send postcards and voicemails to an entire list or to select records.</li>
                      </ul>
                    </p>
                    <p>
                      <b>Save Time &amp; Money</b>
                      <ul>
                        <li>Send Postcards and skip trace directly from List Automator.</li>
                        <li>Prevent marketing and skip tracing to duplicate records.</li>
                        {reiAutomator ? null : <li>Discounted pricing on skiptracing and postcard services.</li>}
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*
          <div className={cssAccount.header}>Data-Driven Automated List Management</div>
          <div className={css.image} style={{ backgroundImage: 'url(/assets/images/alerts/49/list-management.png)' }} />
          <div className={css.content}>
            <div className={css.description}>
              You may have heard of List Stacking, also known as List Management. But have you heard about Data-Driven Automated List Management?<br />
              Odds are you haven&apos;t, primarily because it doesn&apos;t exist, until now.<br />
              <br />
              Existing list management tools let you move records around within a list, or from one list to another, and some will even flag vacant and non-owner-occupied properties, which is helpful, no doubt. Kudos to the developers! But that only solves a tiny portion of the problem! Here&apos;s where they missed the mark. They are not dynamic, data-driven, or automated.
            </div>
            {this.writeLists('listManagement')}
            <SolidButton disabled={listManagementPlanMrcId === listManagementPlan.id} onClick={() => this.upgradeClick('listManagement')}>{listManagementEnabled ? 'Upgrade' : 'Activate'}</SolidButton>
          </div>

        <div className={classNames(css.item, css.disabled)} style={{ display: 'none' }}>
          <div className={cssAccount.header}>Offer Automator</div>
          <div className={css.image} style={{ backgroundImage: 'url(/assets/images/alerts/49/offer-automator.png)' }} />
          <div className={css.content}>
            <div className={css.description}>
              If you are a wholesaler or flipper, having trouble or spending too much time researching and calculating exactly what to offer for a potential acquisition, this may be the solution you are looking for.
            </div>
            {this.writeLists('offerAutomator')}
            <SolidButton>Activate</SolidButton>
          </div>
        </div>
 */

export default connect((state) => {
  const profile = selectProfile(state).toJS();

  return {
    profile,
    loading: selectLoading(state),
    planMrcs: profile.planMrcs.filter(p => !p.annual),
  };
}, {
  upgradeAccount,
  openAddServiceConfirmation: AddServiceConfirmation.open,
})(deferExecutor(AccountServices));
