import React from 'react';
import { pure } from 'recompose';

import classNames from 'classnames';
import Accordion from 'components/base/Accordion';
import Radio from 'components/base/Radio';
import AdditionalField from 'components/base/AdditionalField';
import InputDate from 'components/base/InputDate';
import FormControlWraper from 'components/base/FormControlWraper';
import {
  DepreciationMethodValues,
  RateAmountTypeValues,
  RateAmountNoneTypeValues,
  PropertyValueBasisValues,
  PropertyValueIncreaseTypeValues,
} from 'data/analyses/constants';

import CustomInput from '../CustomInput';
import CurrencyInput from '../CurrencyInput';
import PercentInput from '../PercentInput';
import YearMonthInput from '../YearMonthInput';
import css from '../style.scss';


const Sale = (props) => {
  const {
    analysisParams: a,
    onParamsChange,
    onNumberParamsChange,
  } = props;

  const rateTypes = RateAmountTypeValues;

  return (
    <div className={css.root}>
      <Accordion caption="Property Value Basis" isOpened isLarge>
        <div className={css.label}>Choose the field which will serve as the property&#39;s starting value.</div>
        <div className={css.row}>
          {PropertyValueBasisValues.map(option => (
            <div className={css.col_3} key={option.label}>
              <Radio {...option} name="propertyValueBasis" onChange={onParamsChange} checked={a.propertyValueBasis === option.value} hasArrow />
            </div>
          ))}
        </div>
        <AdditionalField isActive={a.propertyValueBasis === PropertyValueBasisValues[0].value}>
          <CurrencyInput label={PropertyValueBasisValues[0].label} name="purchaseAmount" value={a} disabled />
        </AdditionalField>
        <AdditionalField isActive={a.propertyValueBasis === PropertyValueBasisValues[1].value}>
          <div className={css.row}>
            <div className={css.col_offset_3}>
              <CurrencyInput label={PropertyValueBasisValues[1].label} name="marketValue" value={a} disabled />
            </div>
          </div>
        </AdditionalField>
        <AdditionalField isActive={a.propertyValueBasis === PropertyValueBasisValues[2].value}>
          <div className={css.row}>
            <div className={css.col_offset_6}>
              <CurrencyInput label={PropertyValueBasisValues[2].label} name="propertyValue" value={a} onChange={onNumberParamsChange} />
            </div>
          </div>
        </AdditionalField>
      </Accordion>
      <Accordion caption="Annual Property Value Increase" isOpened isLarge>
        <div className={css.label}>Choose the field which determines the property&#39;s annual value increase.</div>
        <div className={css.row}>
          {PropertyValueIncreaseTypeValues.map(option => (
            <div className={css.col_3} key={option.label}>
              <Radio {...option} name="propertyValueIncreaseType" onChange={onParamsChange} checked={a.propertyValueIncreaseType === option.value} hasArrow />
            </div>
          ))}
        </div>
        <AdditionalField isActive={a.propertyValueIncreaseType === PropertyValueIncreaseTypeValues[0].value}>
          <PercentInput label={PropertyValueIncreaseTypeValues[0].label} name="marketValueIncreaseRate" value={a} onChange={onNumberParamsChange} />
        </AdditionalField>
        <AdditionalField isActive={a.propertyValueIncreaseType === PropertyValueIncreaseTypeValues[1].value}>
          <div className={css.row}>
            <div className={css.col_offset_3}>
              <PercentInput label={PropertyValueIncreaseTypeValues[1].label} name="capRate" value={a} onChange={onNumberParamsChange} />
            </div>
          </div>
        </AdditionalField>
        <AdditionalField isActive={a.propertyValueIncreaseType === PropertyValueIncreaseTypeValues[2].value}>
          <div className={css.row}>
            <div className={css.col_offset_6}>
              <PercentInput label={PropertyValueIncreaseTypeValues[2].label} name="inflationRate" value={a} onChange={onNumberParamsChange} />
            </div>
          </div>
        </AdditionalField>
        <AdditionalField isActive={a.propertyValueIncreaseType === PropertyValueIncreaseTypeValues[3].value}>
          <div className={css.row}>
            <div className={css.col_offset_9}>
              <CurrencyInput label={PropertyValueIncreaseTypeValues[3].label} name="saleAmount" value={a} onChange={onNumberParamsChange} />
            </div>
          </div>
        </AdditionalField>
      </Accordion>
      <Accordion caption="Sales Cost" isOpened isLarge>
        <div className={css.label}>Type</div>
        <div className={css.row}>
          {RateAmountNoneTypeValues.map(option => (
            <div className={css.col_3} key={option.label}>
              <Radio {...option} name="saleCostIncreaseType" onChange={onParamsChange} checked={a.saleCostIncreaseType === option.realValue} hasArrow />
            </div>
          ))}
        </div>
        <AdditionalField isActive={a.saleCostIncreaseType === RateAmountNoneTypeValues[1].value}>
          <div className={css.row}>
            <div className={css.col_offset_3}>
              <PercentInput label="Percentage of Sale Price" name="saleCostRate" value={a} onChange={onNumberParamsChange} />
            </div>
          </div>
        </AdditionalField>
        <AdditionalField isActive={a.saleCostIncreaseType === RateAmountNoneTypeValues[2].value}>
          <div className={css.row}>
            <div className={css.col_offset_6}>
              <CurrencyInput label="Fixed Dollar Amount" name="saleCostAmount" value={a} onChange={onNumberParamsChange} />
            </div>
          </div>
        </AdditionalField>
      </Accordion>
      <Accordion caption="Tax" isOpened isLarge>
        <div className={classNames(css.row, css.between)}>
          <div className={css.col_3}>
            <PercentInput label="Capital Gains Tax Rate" name="capitalGainTaxRate" value={a} onChange={onNumberParamsChange} />
          </div>
          <div className={css.col_3}>
            <PercentInput label="State Income Tax Rate" name="stateTaxRate" value={a} onChange={onNumberParamsChange} />
          </div>
          <div className={css.col_3}>
            <PercentInput label="Federal Income Tax Rate" name="federalTaxRate" value={a} onChange={onNumberParamsChange} />
          </div>
        </div>
      </Accordion>
      <Accordion caption="Depreciation" isOpened isLarge>
        <div className={classNames(css.row, css.between)}>
          <div className={css.col_6}>
            <div className={css.label}>Depreciation Method</div>
            <div className={classNames(css.row, css.depreciationMethodRow)}>
              {DepreciationMethodValues.map((option, index) => (
                <div key={option.label} className={classNames({ [css.col_6]: index === 2 }, { [css.col_3]: index !== 2 })}>
                  <Radio {...option} name="depreciationMethod" onChange={onParamsChange} checked={a.depreciationMethod === option.realValue} />
                </div>
              ))}
            </div>
          </div>
          <div className={css.col_6}>
            {!a.depreciationMethod ? null : (
              <div className={classNames(css.row, css.depreciationTypeRow)}>
                <div className={css.col_8}>
                  <div className={css.label}>Depreciation Type</div>
                  <div className={css.row}>
                    {rateTypes.map((option, index) => (
                      <div className={classNames({ [css.col_7]: index }, { [css.col_5]: !index })} key={option.label}>
                        <Radio {...option} onChange={onParamsChange} name="depreciationType" checked={a.depreciationType === option.value} hasArrow />
                      </div>
                    ))}
                  </div>
                </div>
                <div className={css.col_4}>
                  <CustomInput label="Depreciable Years" name="depreciableYears" value={a} onChange={onNumberParamsChange} maxWholeDigits={2} maxFractionDigits={2} />
                </div>
              </div>
            )}
          </div>
        </div>
        <AdditionalField isActive={a.depreciationType === rateTypes[0].value && a.depreciationMethod}>
          <div className={css.row}>
            <div className={classNames(css.col_4, css.col_offset_6)}>
              <div className={css.row}>
                <div className={css.col_7}>
                  <PercentInput label="% of Purchase Price" name="depreciableAmount" value={a} onChange={onNumberParamsChange} />
                </div>
              </div>
            </div>
          </div>
        </AdditionalField>
        <AdditionalField isActive={a.depreciationType === rateTypes[1].value && a.depreciationMethod}>
          <div className={css.row}>
            <div className={classNames(css.col_4, css.col_offset_6)}>
              <div className={css.row}>
                <div className={classNames(css.col_7, css.col_offset_5)}>
                  <CurrencyInput label="Depreciable Amount" name="depreciableAmount" value={a} onChange={onNumberParamsChange} />
                </div>
              </div>
            </div>
          </div>
        </AdditionalField>
      </Accordion>
      <Accordion caption="Analysis Period" isOpened isLarge>
        <div className={css.row}>
          <div className={css.col_5}>
            <YearMonthInput label="Term of analysis" name="term" value={a} onChange={onNumberParamsChange} />
          </div>
          <div className={css.col_3}>
            <FormControlWraper id="firstPaymentDate" className={css.date} label="First Payment Date">
              <InputDate
                name="firstPaymentDate"
                onChange={onParamsChange}
                value={a.firstPaymentDate}
                selectsStart
                isClearable
                showYearDropdown
              />
            </FormControlWraper>
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default pure(Sale);
