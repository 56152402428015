import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pluralize from 'pluralize';
import { equalsIgnoreCase, capitalize } from 'utils/string';
import {
  getVoicemailAudioPin,
  voicemailCampaignChange,
  loadVoicemailCampaign,
  saveVoicemailCampaign,
  previewVoicemailCampaign,
  deleteVoicemailCampaign,
  loadVoicemailCallerIds,
  saveVoicemailCallerId,
  deleteVoicemailCallerId,
  loadVoicemailRecordings,
  saveVoicemailRecording,
  deleteVoicemailRecording,
  loadVoicemailSchedules,
  saveVoicemailSchedule,
  deleteVoicemailSchedule,
  downloadVoicemailDetail,
} from 'data/campaigns/actions';
import {
  selectCampaign,
  selectLoading,
  selectVoicemailAudioPin,
  selectVoicemailCallerId,
  selectVoicemailRecording,
  selectVoicemailSchedule,
  selectVoicemailCampaign,
  selectVoicemailCallerIds,
  selectVoicemailRecordings,
  selectVoicemailSchedules,
} from 'data/campaigns/selectors';
import { ContactAssignmentPopup } from 'app/components/GroupAssignment';
import Confirm, { type } from 'app/components/Confirm';
import { MarketingCampaignStatuses, VoicemailRecordingTypes } from 'data/campaigns/constants';
import { campaignPath } from 'app/routes';
import { selectProfile } from 'data/user';
import WarningMessage from 'components/WarningMessage';
import Prompt from 'app/components/Prompt';

import classNames from 'classnames';

import Button, { ButtonLink, ButtonImport } from 'components/base/Button';
import Radio from 'components/base/Radio';
import Dropdown from 'components/base/Dropdown';
import FormControlWraper from 'components/base/FormControlWraper';
import { getDropdownOptions } from 'utils/normalizers/dropdown';
import { timeFormatter, phoneFormatter } from 'utils/formatter';
import formatServerUrl from 'utils/URL';

import css from './style.scss';

import Payment from '../Payment';


const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(day => ({ name: day, prefix: day.toLowerCase().substr(0, 3) }));

const time = (t) => {
  const parts = t.split(':');
  return Number(parts[0]) + ((Number(parts[1]) / 15) * 0.25);
};

const hourDiff = (t1, t2) => time(t2) - time(t1);

class Voicemail extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSaveCallerId = this.handleSaveCallerId.bind(this);
    this.handleDeleteCallerId = this.handleDeleteCallerId.bind(this);
    this.handleSaveRecording = this.handleSaveRecording.bind(this);
    this.handleDeleteRecording = this.handleDeleteRecording.bind(this);
    this.handleSaveSchedule = this.handleSaveSchedule.bind(this);
    this.handleDeleteSchedule = this.handleDeleteSchedule.bind(this);
    this.handleEditRecipientsClick = this.handleEditRecipientsClick.bind(this);
    this.handleSaveRecipients = this.handleSaveRecipients.bind(this);
    this.handleGetAudioPinClick = this.handleGetAudioPinClick.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handlePlayClick = this.handlePlayClick.bind(this);
    this.handleDownloadDetailClick = this.handleDownloadDetailClick.bind(this);
    this.handleRef = this.handleRef.bind(this);

    this.state = {
      origCampaign: null,
      recordingType: VoicemailRecordingTypes.FILE.code,
      audioFile: null,
      contactIds: [],
      audio: null,
      playing: false,
    };
  }

  componentWillMount() {
    const { alert, match: {params: { campaignId }}, history } = this.props;

    history.push(campaignPath(campaignId));
    alert('Currently RVM services are paused due to changes in FTC regulations. We are currently researching additional strategies to help you market your services to motivated buyers. If you have any questions please feel free to contact our expert product team.');

    // const { params: { campaignId, voicemailCampaignId: loadId = null }, loadVoicemailCampaign, getVoicemailAudioPin, router, profile } = this.props;
    // if (profile.get('voicemailUrl')) router.push(campaignPath(campaignId));
    // else {
    //   getVoicemailAudioPin(false, () => {
    //     const voicemailCampaignId = loadId ? Number(loadId) : null;
    //     loadVoicemailCampaign(voicemailCampaignId);
    //     this.setState({ voicemailCampaignId });
    //   });
    // }
  }

  componentWillReceiveProps(nextProps) {
    const { campaign, marketingCampaign: { contactIds }, history, match: {params: { campaignId }} } = nextProps;
    const { state } = this;
    const { voicemailCampaignId } = state;
    let { origCampaign } = state;

    // Treat first campaign object we receive after loading screen as the "original" object, which we'll do change comparisons on.
    if (!origCampaign && typeof voicemailCampaignId !== 'undefined' && campaign.id === voicemailCampaignId) {
      origCampaign = Object.assign({}, campaign);
      this.setState({ origCampaign });
    }

    if (origCampaign) {
      // Update path if new campaign was just saved.
      if ((voicemailCampaignId || 0) !== (campaign.id || 0)) this.setState({ voicemailCampaignId: campaign.id }, () => history.push(campaignPath(`${campaignId}/voicemail/${campaign.id || ''}`)));

      // Determine the effective contacts for this campaign and whether any changes have been made since loading.
      this.setState({
        contactIds: campaign.contactIds || contactIds || [],
        changed: true,
/* !campaign.id ||
          campaign.callerIdId !== origCampaign.callerIdId ||
          campaign.recordingId !== origCampaign.recordingId ||
          campaign.scheduleId !== origCampaign.scheduleId ||
          campaign.ports !== origCampaign.ports ||
          ((origCampaign.contactGroup && origCampaign.contactGroup.contactIds) || []).join(',') !== contactIds.join(','),*/
      });
    }
  }

  handleRef(ref) {
    this.setState({ audio: ref, playing: false });
  }

  checkName(type) {
    const item = this.props[type];
    const { name } = item;
    if (!name || name.trim() === '') return `Please enter a name for the ${type}.`;
    if (this.props[`${type}s`].find(i => equalsIgnoreCase(name, i.name) && i.id !== item.id)) return `A ${type} named ${name} already exists.`;

    return null;
  }

  handleStateChange(event) {
    const target = event.target || event;
    const { name, value } = target;

    this.setState({ [name]: value });
  }

  handleChange(event, number) {
    const { name, value } = event.target || event;
    if (name === 'editMode') this.setState({ audioFile: null });
    else if (name === 'recordingId' && this.state.playing) this.handlePlayClick();
    this.props.voicemailCampaignChange(name, number && value ? Number(value) : value);
  }

  handleGetAudioPinClick() {
    const { confirm, getVoicemailAudioPin } = this.props;
    confirm({ question: 'Generate new audio PIN?', onOk: () => getVoicemailAudioPin(true) });
  }

  handleSaveClick(publish = false, duplicate = false) {
    const { campaign, saveVoicemailCampaign, previewVoicemailCampaign, openPayment, closePayment, confirm, match: {params: { campaignId }} } = this.props;
    const { contactIds } = this.state;
    const settings = { marketingCampaignId: campaignId, contactIds };
    let { status } = campaign;

    const doSave = () => {
      let message = null;
      ['callerId', 'recording', 'schedule'].find(field => !!(message = campaign[`${field}Id`] ? null : `Please select a ${field}.`));

      if (message) confirm({ cancelLabel: null, question: message });
      else {
        const fullCampaign = { ...campaign, ...settings };
        const save = (paymentMethodId, cardCode, creditAmount, paymentAmount) => saveVoicemailCampaign({ ...fullCampaign, status }, duplicate, paymentMethodId, cardCode, creditAmount, paymentAmount, () => { if (this.props.campaign.success) closePayment(); });

        if (publish) previewVoicemailCampaign(fullCampaign, publish, () => openPayment({ onConfirm: save, selectLoading, selectCampaign: selectVoicemailCampaign }));
        else save();
      }
    };

    if (duplicate) {
      // prompt({
      //   title: 'Voicemail Settings',
      //   label: 'Voicemail Campaign Name (Optional)',
      //   value: `${name} - Duplicate`,
      //   onSubmit: (newName) => {
      //     name = newName;
      //     doSave();
      //   },
      // });

      confirm({
        caption: 'Duplicate voicemail campaign?',
        okLabel: 'Yes',
        cancelLabel: 'No',
        question: '',
        onOk: doSave,
      });
    } else if (!publish) doSave();
    else {
      status = MarketingCampaignStatuses.PROCESSING;
      confirm({
        caption: 'Confirm Activation',
        onOk: doSave,
        okLabel: 'Activate',
        type: type.danger,
        question: 'Activate campaign? Once activated the voicemail campaign will begin and its settings & recipients cannot be changed.',
      });
    }
  }

  handleDownloadDetailClick() {
    const { downloadVoicemailDetail, campaign: { id } } = this.props;
    downloadVoicemailDetail(id);
  }

  handleSaveCallerId() {
    const { callerId: { phone }, saveVoicemailCallerId } = this.props;
    this.doSave(saveVoicemailCallerId, phone, phone.replace(/\D/g, '').match(/^\d{10}$/) ? null : 'Please enter a valid phone number.');
  }

  handleSaveRecording() {
    const { recording, saveVoicemailRecording, audioPin, confirm } = this.props;
    const { recordingType, audioFile } = this.state;
    let data = null;
    let message = this.checkName('recording');

    if (!message) {
      if (recording.id) data = recording;
      else {
        data = new FormData();
        data.append('name', recording.name);

        const doSave = () => this.doSave(saveVoicemailRecording, data, message);

        if (recordingType === VoicemailRecordingTypes.PIN.code) {
          confirm({
            question: 'Have you finished recording your message?',
            onOk: () => {
              if (audioPin) data.append('pin', audioPin);
              else message = 'Invalid Audio PIN.';

              doSave();
            },
          });
        } else {
          if (recordingType === VoicemailRecordingTypes.FILE.code) {
            if (audioFile) data.append('file', audioFile);
            else message = 'No file selected.';
          } else message = 'No recording type selected.';

          doSave();
        }
      }
    }
  }

  handlePlayClick() {
    const { audio } = this.state;
    const playing = !this.state.playing;
    if (playing) {
      audio.load();
      audio.play();
    } else audio.pause();

    this.setState({ playing });
  }

  handleSaveSchedule() {
    const { schedule, saveVoicemailSchedule } = this.props;

    let message = this.checkName('schedule');
    if (!message) {
      const day = days.find(day => schedule[`${day.prefix}Start`].localeCompare(schedule[`${day.prefix}End`]) > 0);
      if (day) message = `Start Time for ${day.name} must be less that or equal to End Time.`;
      else {
        const hours = days.reduce((hours, day) => hours + Math.max(0, hourDiff(schedule[`${day.prefix}Start`], schedule[`${day.prefix}End`])), 0);
        if (hours < 8) message = `${!hours ? 'You have not allotted any delivery time for you voicemails' : `There ${hours === 1 ? 'is' : 'are'} only ${pluralize('hour', hours, true)} of time allotted for your voicemail delivery`}. Please adjust your schedule to have a total of at least 8 hours allotted for voicemail delivery.`;
      }
    }
    this.doSave(saveVoicemailSchedule, schedule, message);
  }

  handleFileChange(event) {
    const { files } = event.target;
    let audioFile = (files && files[0]) || null;
    if (audioFile && audioFile.type !== 'audio/wav') {
      this.props.confirm({ cancelLabel: null, question: 'Audio file must be WAV format.' });
      audioFile = null;
    }

    this.setState({ audioFile });
  }

  doSave(func, item, message) {
    if (message) this.props.confirm({ cancelLabel: null, question: message });
    else func(item);
  }

  doDelete(type, name = type) {
    this.props.confirm({ question: `Are you sure you want to delete this ${name}?`, onOk: () => this.props[`deleteVoicemail${capitalize(type)}`](this.props[type].id) });
  }

  handleDeleteClick() {
    this.doDelete('campaign');
  }

  handleDeleteCallerId() {
    this.doDelete('callerId', 'caller ID');
  }

  handleDeleteRecording() {
    this.doDelete('recording');
  }

  handleDeleteSchedule() {
    this.doDelete('schedule');
  }

  handleSaveRecipients(contactIds) {
    this.setState({ contactIds });
    this.props.voicemailCampaignChange('contactIds', contactIds);
  }

  handleEditRecipientsClick() {
    const { openContactAssignmentPopup, campaign: { status } } = this.props;

    openContactAssignmentPopup({ ids: this.state.contactIds, onSave: this.handleSaveRecipients, viewMode: status !== MarketingCampaignStatuses.NEW });
  }

  render() {
    const { params, campaign, callerId, recording, schedule, callerIds, recordings, schedules, audioPin, isLoading, recipientIsLoading } = this.props;
    const { recordingType, audioFile, contactIds, playing } = this.state;

    const { callerIdId, recordingId, scheduleId, editMode, status } = campaign;
    const editingCallerId = editMode === 'callerId';
    const editingRecording = editMode === 'recording';
    const editingSchedule = editMode === 'schedule';
    const loading = isLoading || recipientIsLoading;
    const blueButtonProps = { kind: Button.kind.blue, size: Button.size.large, className: css.btn, isLoading: loading, disabled: loading };
    const blueLinkButtonProps = { kind: Button.kind.blueLink, className: css.blueLink };
    const grayButtonProps = Object.assign({}, blueButtonProps, { kind: Button.kind.grayGhost });
    const redButtonProps = Object.assign({}, blueButtonProps, { kind: Button.kind.redGhost });
    const edit = editMode => this.handleChange({ name: 'editMode', value: editMode || null });
    const cancelButtonProps = Object.assign({}, grayButtonProps, { onClick: edit });
    const times = [];
    const canSave = status === MarketingCampaignStatuses.NEW;
    const pinMode = !recordingId && recordingType === VoicemailRecordingTypes.PIN.code && !audioPin;
    const fileMode = !recordingId && recordingType === VoicemailRecordingTypes.FILE.code && !audioFile;

    for (let i = 32; i <= 84; i++) {
      const hour = Math.floor(i / 4);
      const minute = (i % 4) * 15;
      times.push(`${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}:00`);
    }

    const ports = [];
    for (let i = 1; i <= 30; i++) ports.push(i);
    return (
      <div className={css.voicemail}>
        <div className={css.header}>
          <div className={css.left}>
            <div className={css.title}>{campaign.id ? 'Edit' : 'New'} Voicemail Campaign</div>
          </div>
          <div className={css.right}>
            <ButtonLink {...grayButtonProps} to={campaignPath(params.campaignId)}>Back</ButtonLink>
            {!campaign.id || status !== MarketingCampaignStatuses.COMPLETE ? null : <Button {...grayButtonProps} onClick={this.handleDownloadDetailClick}>Download Detail</Button>}
            {!campaign.id || status !== MarketingCampaignStatuses.NEW ? null : <Button {...redButtonProps} onClick={this.handleDeleteClick}>Delete</Button>}
            <Button {...blueButtonProps} onClick={this.handleEditRecipientsClick}>{canSave ? 'Edit' : 'View'} Recipients ({contactIds.length})</Button>
            {!campaign.id ? null : <Button {...blueButtonProps} onClick={() => this.handleSaveClick(false, true)}>Duplicate</Button>}
            {!canSave ? null : <Button {...blueButtonProps} onClick={() => this.handleSaveClick(false, false)}>{canSave ? 'Save' : 'Saved'}</Button>}
          </div>
        </div>
        <div className={css.body}>
          <div className={css.instructions}>Follow the four steps below to drop voice messages directly into mobile telephone<br />and digital landline voicemails.</div>
          <div className={css.panel}>
            <div className={css.section}>
              <FormControlWraper label="Step 1: Select a Caller ID" large>
                <Dropdown
                  name="callerIdId"
                  options={getDropdownOptions(callerIds, 'phone', 'id', `--${editingCallerId ? 'New' : 'Select'} Caller ID--`, phoneFormatter)}
                  onChange={event => this.handleChange(event, true)}
                  value={callerIdId}
                  disabled={!canSave}
                />
              </FormControlWraper>
              {!canSave ? null : (
                <div className={css.editContainer}>
                  <div className={classNames(css.edit, { [css.active]: editingCallerId })}>
                    <div>
                      <FormControlWraper label="New Caller ID" hasMarginBottom>
                        <input type="text" name="voicemailCallerId.phone" value={callerId.phone} onChange={this.handleChange} />
                      </FormControlWraper>
                      <div className={css.buttons}>
                        <Button {...cancelButtonProps}>Cancel</Button>
                        <Button {...grayButtonProps} onClick={this.handleDeleteCallerId} disabled={!callerIdId}>Delete</Button>
                        <Button {...blueButtonProps} onClick={this.handleSaveCallerId}>Save</Button>
                      </div>
                    </div>
                  </div>
                  {editingCallerId ? null : <Button {...blueLinkButtonProps} onClick={() => edit('callerId')}>Add/Edit Caller ID</Button>}
                </div>
              )}
            </div>
            <div className={css.section}>
              <FormControlWraper
                large
                label={
                  <div>
                    <div>Step 2: Select a Recording</div>
                    {!recordingId ? null : (
                      <div>
                        <audio onEnded={this.handlePlayClick} ref={this.handleRef}>
                          <source src={formatServerUrl(recording.path)} />
                          <track kind="captions" src="" srcLang="en" />
                        </audio>
                        <Button {...blueLinkButtonProps} onClick={this.handlePlayClick}>{playing ? 'Stop' : 'Play'}</Button>
                      </div>
                    )}
                  </div>
                }
              >
                <Dropdown
                  name="recordingId"
                  options={getDropdownOptions(recordings, 'name', 'id', `--${editingRecording ? 'New' : 'Select'} Recording--`)}
                  onChange={event => this.handleChange(event, true)}
                  value={recordingId}
                  disabled={!canSave}
                />
              </FormControlWraper>
              {!canSave ? null : (
                <div className={css.editContainer}>
                  <div className={classNames(css.edit, { [css.active]: editingRecording })}>
                    <div>
                      <FormControlWraper label="Recording Name" hasMarginBottom>
                        <input type="text" name="voicemailRecording.name" value={recording.name} onChange={this.handleChange} />
                      </FormControlWraper>
                      {recordingId ? null : (
                        <div style={{ marginBottom: '10px' }}>
                          <div className={css.radioButtons}>
                            {Object.keys(VoicemailRecordingTypes).map(type => (
                              <Radio name="recordingType" onChange={this.handleStateChange} checked={recordingType === type} label={VoicemailRecordingTypes[type].label} value={type} key={type} />
                            ))}
                          </div>
                          {recordingType !== VoicemailRecordingTypes.FILE.code ? null : (
                            <div>
                              {audioFile ? (
                                <div>
                                  <div className={css.recordingLine}>Selected File: {audioFile.name}</div>
                                  <div className={css.recordingLine}>Complete the audio file upload by clicking the &quot;Save New Recording&quot; button below.</div>
                                </div>
                              ) : (
                                <div className={css.recordingLine}>Please select an audio file by clicking the &quot;Choose Audio File&quot; button below. The file selected must be WAV format.</div>
                              )}
                            </div>
                          )}
                          {recordingType !== VoicemailRecordingTypes.PIN.code ? null : (
                            <div>
                              {audioPin ? (
                                <div className={css.recordingLine}>Please call (866) 672-8438 and use PIN# <span>{audioPin}</span> to record your message. Once you have recorded your message, click &quot;Save New Recording&quot; button below.</div>
                              ) : (
                                <div className={css.recordingLine}>To create a new audio recording, click &quot;Obtain Audio PIN&quot; below. You will be able to use the generated PIN to call in and record a message.</div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      <div className={css.buttons}>
                        <Button {...cancelButtonProps}>Cancel</Button>
                        <Button {...grayButtonProps} onClick={this.handleDeleteRecording} disabled={!recordingId}>Delete</Button>
                        {!pinMode ? null : <Button {...blueButtonProps} onClick={this.handleGetAudioPinClick}>Obtain Audio PIN</Button>}
                        {!fileMode ? null : <ButtonImport {...blueButtonProps} name="audioFile" onChange={this.handleFileChange} className={css.autoBtn}>Choose Audio File</ButtonImport>}
                        {pinMode || fileMode ? null : <Button {...blueButtonProps} onClick={this.handleSaveRecording}>{`${recordingId ? 'Update' : 'Save New'} Recording`}</Button>}
                      </div>
                    </div>
                  </div>
                  {editingRecording ? null : <Button {...blueLinkButtonProps} onClick={() => edit('recording')}>Add/Edit Recording</Button>}
                </div>
              )}
            </div>
            <div className={css.section}>
              <FormControlWraper label="Step 3: Select a Schedule" large>
                <Dropdown
                  name="scheduleId"
                  options={getDropdownOptions(schedules, 'name', 'id', `--${editingSchedule ? 'New' : 'Select'} Schedule--`)}
                  onChange={event => this.handleChange(event, true)}
                  value={scheduleId}
                  disabled={!canSave}
                />
              </FormControlWraper>
              {!canSave ? null : (
                <div className={css.editContainer}>
                  <div className={classNames(css.edit, { [css.active]: editingSchedule })}>
                    <div>
                      <FormControlWraper label="Schedule Name" hasMarginBottom>
                        <input type="text" name="voicemailSchedule.name" value={schedule.name} onChange={this.handleChange} />
                      </FormControlWraper>
                      <FormControlWraper label="Call Times" hasMarginBottom>
                        {days.map((day) => {
                          const t1 = schedule[`${day.prefix}Start`];
                          const t2 = schedule[`${day.prefix}End`];
                          const hours = Math.max(0, hourDiff(t1, t2));

                          return (
                            <div className={classNames(css.callTime, { [css.noDelivery]: !hours })} key={day.prefix}>
                              <label>{day.name}:</label>
                              <Dropdown
                                name={`voicemailSchedule.${day.prefix}Start`}
                                options={getDropdownOptions(times, null, null, null, timeFormatter)}
                                onChange={this.handleChange}
                                value={t1}
                                className={css.time}
                              />
                              -
                              <Dropdown
                                name={`voicemailSchedule.${day.prefix}End`}
                                options={getDropdownOptions(times, null, null, null, timeFormatter)}
                                onChange={this.handleChange}
                                value={t2}
                                className={css.time}
                              />
                              <div className={css.status}>{hours ? `${hours < 1 ? '' : Math.floor(hours)}${!(hours % 1) ? '' : String.fromCharCode(187 + ((hours % 1) / 0.25))}-Hour Delivery Window` : 'No Deliveries'}</div>
                            </div>
                          );
                        })}
                      </FormControlWraper>
                      <WarningMessage className={css.warning}>
                        <div>Voicemails will be delivered only during the times you specify in the delivery windows for each day of the week.</div>
                        <div>Incomplete deliveries during your specified delivery time will start again the next day you&apos;ve scheduled a delivery time.</div>
                        <div>Delivery times are local to your recipient&apos;s time zone. Please take that into consideration while setting your voicemail delivery schedule.</div>
                      </WarningMessage>
                      <div className={css.buttons}>
                        <Button {...cancelButtonProps}>Cancel</Button>
                        <Button {...grayButtonProps} onClick={this.handleDeleteSchedule} disabled={!scheduleId}>Delete</Button>
                        <Button {...blueButtonProps} onClick={this.handleSaveSchedule}>{scheduleId ? 'Update' : 'Save'}</Button>
                      </div>
                    </div>
                  </div>
                  {editingSchedule ? null : <Button {...blueLinkButtonProps} onClick={() => edit('schedule')}>Add/Edit Schedule</Button>}
                </div>
              )}
            </div>
            <div className={css.section}>
              <FormControlWraper label="Step 4: Campaign Settings" large>
                <div className={css.callTime}>
                  <label>Calls Per Minute:</label>
                  <Dropdown name="ports" options={getDropdownOptions(ports)} onChange={this.handleChange} value={campaign.ports} className={css.time} disabled={!canSave} />
                </div>
              </FormControlWraper>
            </div>
            <div className={classNames(css.section, css.bottomButtons)}>
              {/* {!campaign.id || status === MarketingCampaignStatuses.COMPLETE ? null : <Button {...blueButtonProps} onClick={() => onSaveClick(!active)}>{`${canChange ? 'Save and ' : ''}${active ? 'Pause' : 'Activate'}`}</Button>} */}
              {!canSave ? null : <Button {...blueButtonProps} onClick={() => this.handleSaveClick(true)}>{`${canSave ? 'Save and ' : ''}${false ? 'Pause' : 'Activate'}`}</Button>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  marketingCampaign: selectCampaign(state).toJS(),
  audioPin: selectVoicemailAudioPin(state),
  callerId: selectVoicemailCallerId(state).toJS(),
  recording: selectVoicemailRecording(state).toJS(),
  schedule: selectVoicemailSchedule(state).toJS(),
  campaign: selectVoicemailCampaign(state).toJS(),
  callerIds: selectVoicemailCallerIds(state).toJS(),
  recordings: selectVoicemailRecordings(state).toJS(),
  schedules: selectVoicemailSchedules(state).toJS(),
  isLoading: selectLoading(state),
  profile: selectProfile(state),
}), {
  getVoicemailAudioPin,
  voicemailCampaignChange,
  loadVoicemailCampaign,
  saveVoicemailCampaign,
  previewVoicemailCampaign,
  deleteVoicemailCampaign,
  loadVoicemailCallerIds,
  saveVoicemailCallerId,
  deleteVoicemailCallerId,
  loadVoicemailRecordings,
  saveVoicemailRecording,
  deleteVoicemailRecording,
  loadVoicemailSchedules,
  saveVoicemailSchedule,
  deleteVoicemailSchedule,
  downloadVoicemailDetail,
  openContactAssignmentPopup: ContactAssignmentPopup.open,
  openPayment: Payment.open,
  closePayment: Payment.close,
  alert: Confirm.alert,
  prompt: Prompt.open,
  confirm: Confirm.open,
})(Voicemail));

