/** @flow */
import React from 'react';

import { SearchFields as Fields } from 'data/search';

import { Column } from 'components/base/Table';
import Checkbox from 'components/base/Checkbox';


import renderAddressCell from './renderAddressCell';
import renderUnitCell from './renderUnitCell';
import renderCheckboxCell from './renderCheckboxCell';
import renderTypeCell from './renderTypeCell';
import renderCurrencyCell from './renderCurrencyCell';
import renderDateCell from './renderDateCell';
import renderColorDot from './renderColorDot';
import renderDefault from './renderDefault';


export default {
  ADDRESS: (extraProps, sortIcon) => (
    <Column
      key="ADDRESS"
      field={Fields.ADDRESS}
      fieldName={Fields.ADDRESS_FIELD_NAME}
      renderCell={renderAddressCell}
      extraProps={extraProps}
      sortIcon={sortIcon}
    >
      Address
    </Column>
  ),
  UNIT: (extraProps, sortIcon) => (
    <Column
      key="UNIT"
      field={Fields.ADDRESS}
      fieldName={Fields.UNIT}
      renderCell={renderUnitCell}
      extraProps={extraProps}
      sortIcon={sortIcon}
    >
      Unit #
    </Column>
  ),
  TYPE: (extraProps, sortIcon) => (
    <Column
      key="TYPE"
      field={Fields.TYPE}
      fieldName={Fields.TYPE}
      renderCell={renderTypeCell}
      sortIcon={sortIcon}
    >
      Listing Type
    </Column>
  ),
  BEDROOMS: (extraProps, sortIcon) => (
    <Column
      key="BEDROOMS"
      field={Fields.BEDROOMS}
      fieldName={Fields.BEDROOMS}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      Beds
    </Column>
  ),
  BATHROOMS: (extraProps, sortIcon) => (
    <Column
      key="BATHROOMS"
      field={Fields.BATHROOMS}
      fieldName={Fields.BATHROOMS}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      Baths
    </Column>
  ),
  LISTING_PRICE: (extraProps, sortIcon) => (
    <Column
      key="LISTING_PRICE"
      field={Fields.LISTING_PRICE}
      fieldName={Fields.LISTING_PRICE}
      renderCell={renderCurrencyCell}
      sortIcon={sortIcon}
    >
      List Price
    </Column>
  ),
  LISTING_DATE: (extraProps, sortIcon) => (
    <Column
      key="LISTING_DATE"
      field={Fields.LISTING_DATE}
      fieldName={Fields.LISTING_DATE}
      renderCell={renderDateCell}
      sortIcon={sortIcon}
    >
      List Date
    </Column>
  ),
  MLS_LISTING_AMOUNT: (extraProps, sortIcon) => (
    <Column
      key="MLS_LISTING_AMOUNT"
      field={Fields.MLS_LISTING_AMOUNT}
      fieldName={Fields.MLS_LISTING_AMOUNT}
      renderCell={renderCurrencyCell}
      sortIcon={sortIcon}
    >
      List Price
    </Column>
  ),
  MLS_LISTING_DATE: (extraProps, sortIcon) => (
    <Column
      key="MLS_LISTING_DATE"
      field={Fields.MLS_LISTING_DATE}
      fieldName={Fields.MLS_LISTING_DATE}
      renderCell={renderDateCell}
      sortIcon={sortIcon}
    >
      List Date
    </Column>
  ),
  STATUS: (extraProps, sortIcon) => (
    <Column
      key="STATUS"
      field={Fields.STATUS}
      fieldName={Fields.STATUS}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      Status
    </Column>
  ),
  DOCUMENT_TYPE: (extraProps, sortIcon) => (
    <Column
      key="DOCUMENT_TYPE"
      field={Fields.DOCUMENT_TYPE}
      fieldName={Fields.DOCUMENT_TYPE}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      Document Type
    </Column>
  ),
  DEFAULT_AMOUNT: (extraProps, sortIcon) => (
    <Column
      key="DEFAULT_AMOUNT"
      addIndex
      field={Fields.DEFAULT_AMOUNT}
      fieldName={Fields.DEFAULT_AMOUNT}
      renderCell={renderCurrencyCell}
      sortIcon={sortIcon}
    >
      Default Amount
    </Column>
  ),
  LISTING_AMOUNT: (extraProps, sortIcon) => (
    <Column
      key="LISTING_AMOUNT"
      addIndex
      field={Fields.LISTING_AMOUNT}
      fieldName={Fields.LISTING_AMOUNT}
      renderCell={renderCurrencyCell}
      sortIcon={sortIcon}
    >
      Default Amount
    </Column>
  ),
  SALE_PRICE: (extraProps, sortIcon) => (
    <Column
      key="SALE_PRICE"
      field={Fields.SALE_PRICE}
      fieldName={Fields.SALE_PRICE}
      renderCell={renderCurrencyCell}
      sortIcon={sortIcon}
    >
      Sale Amount
    </Column>
  ),
  SALE_DATE: (extraProps, sortIcon) => (
    <Column
      key="SALE_DATE"
      field={Fields.SALE_DATE}
      fieldName={Fields.SALE_DATE}
      renderCell={renderDateCell}
      sortIcon={sortIcon}
    >
      Sale Date
    </Column>
  ),
  ESTIMATE_VALUE: (extraProps, sortIcon) => (
    <Column
      key="ESTIMATE_VALUE"
      field={Fields.ESTIMATE_VALUE}
      fieldName={Fields.ESTIMATE_VALUE}
      renderCell={renderCurrencyCell}
      sortIcon={sortIcon}
    >
      Estimated Value
    </Column>
  ),
  ESTIMATE_EQUITY: (extraProps, sortIcon) => (
    <Column
      key="ESTIMATE_EQUITY"
      field={Fields.ESTIMATE_EQUITY}
      fieldName={Fields.ESTIMATE_EQUITY}
      renderCell={renderCurrencyCell}
      sortIcon={sortIcon}
    >
      Estimated Equity
    </Column>
  ),
  UPDATE_DATE: (extraProps, sortIcon) => (
    <Column
      key="UPDATE_DATE"
      addIndex
      field={Fields.UPDATE_DATE}
      fieldName={Fields.UPDATE_DATE}
      renderCell={renderDateCell}
      sortIcon={sortIcon}
    >
      Last Updated
    </Column>
  ),
  CITY: (extraProps, sortIcon) => (
    <Column
      key="CITY"
      field={Fields.CITY}
      fieldName={Fields.CITY_FIELD_NAME}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      City
    </Column>
  ),
  STATE: (extraProps, sortIcon) => (
    <Column
      key="STATE"
      field={Fields.STATE}
      fieldName={Fields.STATE_FIELD_NAME}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      State
    </Column>
  ),
  ZIP: (extraProps, sortIcon) => (
    <Column
      key="ZIP"
      field={Fields.ZIP}
      fieldName={Fields.ZIP_FIELD_NAME}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      Zip
    </Column>
  ),
  APN: (extraProps, sortIcon) => (
    <Column
      key="APN"
      field={Fields.APN}
      fieldName={Fields.APN_FIELD_NAME}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      APN
    </Column>
  ),
  LAND_USE: (extraProps, sortIcon) => (
    <Column
      key="LAND_USE"
      field={Fields.LAND_USE}
      fieldName={Fields.LAND_USE_FIELD_NAME}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      Property Type
    </Column>
  ),
  LIEN_TYPE: (extraProps, sortIcon) => (
    <Column
      key="LIEN_TYPE"
      field={Fields.LIEN_TYPE}
      fieldName={Fields.LIEN_TYPE}
      renderCell={renderDefault}
      sortIcon={sortIcon}
    >
      Lien Type
    </Column>
  ),
  LIEN_AMOUNT: (extraProps, sortIcon) => (
    <Column
      key="LIEN_AMOUNT"
      field={Fields.LIEN_AMOUNT}
      fieldName={Fields.LIEN_AMOUNT}
      renderCell={renderCurrencyCell}
      sortIcon={sortIcon}
    >
      Lien Amount
    </Column>
  ),
  LIEN_DATE: (extraProps, sortIcon) => (
    <Column
      key="LIEN_DATE"
      field={Fields.LIEN_DATE}
      fieldName={Fields.LIEN_DATE}
      renderCell={renderDateCell}
      sortIcon={sortIcon}
    >
      Lien Date
    </Column>
  ),
  CHECKBOX: (extraProps) => (
    <Column
      key="CHECKBOX"
      field={Fields.COUNTER}
      renderCell={renderCheckboxCell}
      extraProps={extraProps}
    />
  ),
  COUNTER: (extraProps) => (
    <Column
      key="RESULT_INDEX"
      field={Fields.COUNTER}
      fieldName={Fields.COUNTER}
      renderCell={renderColorDot}
      extraProps={extraProps}
    />
  ),
};

