import React, { PureComponent } from 'react';
import classNames from 'classnames';
import LoadablePanel from 'components/hoc/loadable/Panel';

import css from './style.scss';


class Panel extends PureComponent {
  render() {
    const { children, width, loading, className } = this.props;

    return (
      <div className={classNames(css.panel, className)} style={{ width }}>
        <LoadablePanel loading={loading}>
          {children}
        </LoadablePanel>
      </div>
    );
  }
}


export default Panel;
