import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { createConfirmable } from 'app/components/Confirm';

import css from './style.scss';


class TextContent extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { element, onSelectElement, onChange } = this.props;
    if (element.key) onSelectElement(element);
    else onChange({ text: '' }, element);
  }

  render() {
    const { title, element, selectedElement } = this.props;
    const empty = !element.key;

    return (
      <div className={css.textElement} onClick={this.handleClick}>
        <div className={classNames(css.textContent, { [css.selected]: selectedElement === element })}>
          <div className={css.textTitleContainer}>
            <div className={css.textTitle}>{title}</div>
            {!empty ? null : <div className={css.clickToAdd}>Click to Add</div>}
          </div>
          {empty ? null : <div className={css.text} dangerouslySetInnerHTML={{ __html: element.text }} />}
        </div>
      </div>
    );
  }
}

TextContent.defaultProps = { colorMode: false };

export default createConfirmable(connect()(TextContent));
