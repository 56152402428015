import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from 'react-redux';
import { selectNewFilter, selectShowAllEditOption, updateFilter, updateLocalFilter, selectLocalFilter, removeFilterById, removeLocalFilter } from '../../../../../../data/search';
import newsearchfilter from "../../SearchFilterNew.scss";

import { AssessedLandOptions, AssessedValueOptions, EquityCurrencyOptions, EquityPercentOptions, EstimatedValueOptions, WholesaleValueOptions, GrossYieldOptions, GrowthValueListOptions, GrowthValueMinMaxOptions, ImprovementTaxOptions, LoanValueOptions, RentalIncomeOptions, fieldTagsMap, IncludeExcludeOptions } from '../../shared/ConstantsNew';
// import FilterDateRangePicker from "../../shared/DateRanger";
import SelectAnyoneMinMax from "../../shared/SelectAnyoneMinMax";
import FilterComboBox from "../../shared/Autosearch/FilterComboBox";
import { useFilter } from "../../TagFilterContext";
import classNames from "classnames";
import ToggleList from "../../shared/ToggleSection/ToggleList";
import { generateMinMaxValue, getMinMaxValuesToUpdate } from "../../../../../../utils/filter_menu/minandmax";

const ValueEquityScreen = () => {

  const dispatch = useDispatch();
  const filter = useSelector(selectNewFilter);

  const getLocalFilter = useSelector(selectLocalFilter);
  const { selectedTags, filteredFields, navIndex } = useFilter();
  const [isFilterApplied, setFilterApplied] = useState(false);
  const refs = useRef({});


  const [ve, setValueEquity] = useState({
    estimatedValueMin: null,
    estimatedValueMax: null,

    assessedLandValueMin: null,
    assessedLandValueMax: null,

    estimatedValueGrowthMin: null,
    estimatedValueGrowthMax: null,
    // 1 pening

    wholesaleValueMin: null,
    wholesaleValueMax: null,

    estimatedRentalIncomeMin: null,
    estimatedRentalIncomeMax: null,

    grossYieldMin: null,
    grossYieldMax: null,

    estimatedEquityMin: null,
    estimatedEquityMax: null,

    estimatedEquityPercentMin: null,
    estimatedEquityPercentMax: null,

    loanToValueRatioMin: null,
    loanToValueRatioMax: null,

    assessedValueMin: null,
    assessedValueMax: null,

    unknownEquityIncluded: null

  })

  useEffect(() => {
    if (filter) {
      setValueEquity(filter);
      setFilterApplied(true);
    }
  }, [filter]);

  useEffect(() => {
    if (isFilterApplied) {
      applyFiltersBasedOnValueEquity();
    }
  }, [isFilterApplied]);

  const getEditOptions = useSelector(selectShowAllEditOption)

  useEffect(() => {
    if (getEditOptions) {
      if (getEditOptions?.key === 've') {
        let key = getEditOptions?.apiKey.replace(/Max|Min/g, "")
        editSectionClick(key ? key : '')
      }
    }
  }, [getEditOptions]);


  const editSectionClick = (id) => {
    setTimeout(() => {
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 200); // 5000 milliseconds = 5 seconds
  };
  // combox
  const comboBoxValueChange = (minValue, maxValue, leftLabel, key) => {
  
    const name = generateMinMaxValue(minValue, maxValue);
    const valuesToUpdate = getMinMaxValuesToUpdate(minValue, maxValue, key);
  
    valuesToUpdate.forEach(({ key, value }) => {
      updateFilterOldStore(key, value);
    });

    updateFilterStoreCombox(name, key, leftLabel);
  };


  const handleBooleanSelectPCNew = (option, key, leftLabel) => {
    // add again
    if (option.value === true || option.value === false || option.value !== '') {
      updateFilterOldStore(key, option.value)
      updateFilterStore(option, key, leftLabel)
    } else {
      handleOldRemoveFilter(key)
      handleClearFilter(leftLabel)
    }

  };

  const handleOldRemoveFilter = id => {
    dispatch(removeFilterById(id));
  };

  const handleClearFilter = id => {
    dispatch(removeLocalFilter(id));
  };


  
  // store 
  const updateFilterOldStore = (key, id) => {
    dispatch(updateFilter({ [key]: id }));
  };

  const applyFiltersBasedOnValueEquity = () => {
    const fields = [
      { min: ve.estimatedValueMin, max: ve.estimatedValueMax, label: 'Estimated Value', key: "estimatedValue" },
      { min: ve.assessedLandValueMin, max: ve.assessedLandValueMax, label: 'Assessed Land Value', key: "assessedLandValue" },
      { min: ve.estimatedValueGrowthMin, max: ve.estimatedValueGrowthMax, label: 'Growth %', key: "estimatedValueGrowth", isPercent: true },
      { min: ve.wholesaleValueMin, max: ve.wholesaleValueMax, label: 'Estimated Wholesale Price', key: "wholesaleValue" },
      { min: ve.estimatedRentalIncomeMin, max: ve.estimatedRentalIncomeMax, label: 'Estimated Rental Income', key: "estimatedRentalIncome" },
      { min: ve.grossYieldMin, max: ve.grossYieldMax, label: 'Gross Yield %', key: "grossYield", isPercent: true },
      { min: ve.estimatedEquityMin, max: ve.estimatedEquityMax, label: 'Estimated Equity $ Amount', key: "estimatedEquity" },
      { min: ve.estimatedEquityPercentMin, max: ve.estimatedEquityPercentMax, label: 'Estimated Equity % Amount', key: "estimatedEquityPercent", isPercent: true },
      { min: ve.loanToValueRatioMin, max: ve.loanToValueRatioMax, label: 'Loan to Value (LTV) %', key: "loanToValueRatio", isPercent: true },
      { min: ve.assessedValueMin, max: ve.assessedValueMax, label: 'Assessed Total Value', key: "assessedValue" },
      { min: ve.assessedImprovementValueMin, max: ve.assessedImprovementValueMax, label: 'Assessed Improvement Value', key: "assessedImprovementValue" },
      { min: ve.itvRatioMin, max: ve.itvRatioMax, label: 'Improvement to Tax Value %', key: "itvRatio", isPercent: true },
      { label: "Include Unknown Equity", value: ve.unknownEquityIncluded, key: "unknownEquityIncluded", options: IncludeExcludeOptions, isBool: true },
    ];
  
    fields.forEach(field => {
      if (
        (field.min !== null && field.min !== '' && field.min !== undefined) || 
        (field.max !== null && field.max !== '' && field.max !== undefined) && !field?.isBool
      ) {
        comboBoxValueChange(field.min, field.max, field.label, field.key);
      } else {
        if (field?.isBool) {
          const option = field?.options.find(option => option.value === field?.value);
          if (option) {
            handleBooleanSelectPCNew(option, field?.key, field?.label);
          } else {
            handleOldRemoveFilter(field?.key);
            handleClearFilter(field?.label);
          }
        } 
      }
    });
  };



  const updateFilterStoreCombox = (name, key, leftLabel) => {
    // if (option.value) {
    dispatch(updateLocalFilter([{
      key: 've', id: leftLabel, name: name, apiKey: key,
      removeID: key,
      showALLDisplayValue: name
    }]));
    // }
  };

  const updateFilterStore = (option, key, leftLabel) => {
    dispatch(
      updateLocalFilter([
        {
          key: "ve",
          id: leftLabel,
          name: option.label,
          apiKey: key,
          [key]: option.value,
          removeID: key,
        }
      ])
    );
  };

  const getClassNames = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    const valueEqFilters = getLocalFilter.filter(filter => filter.key === 've');
    return `${newsearchfilter.clsShowOnTag}`;
  };
  
  const getTagsForField = (fieldLabel) => {
    return fieldTagsMap[fieldLabel] || [];
  };

  const isFilteredSection = (fieldLabel) => {
    const tagsForField = getTagsForField(fieldLabel);
    return selectedTags.length > 0 && tagsForField.some(tag => selectedTags.includes(tag));
  };

  useEffect(() => {
    const highlightedField = Object.keys(filteredFields).find(field => filteredFields[field] === navIndex);
    if (highlightedField && refs.current[highlightedField]) {
      refs.current[highlightedField].focus({ preventScroll: true });
      refs.current[highlightedField].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [filteredFields, navIndex]);


  return (
    <div className={classNames(
      getClassNames('Estimated Value'),
      getClassNames('Assessed Land Value'),
      getClassNames('Growth %'),
      getClassNames("Wholesale Value"),
      getClassNames('Estimated Rental Income'),
      getClassNames('Gross Yield %'),
      getClassNames('Estimated Equity $ Amount'),
      getClassNames('Estimated Equity % Amount'),
      getClassNames('Loan to Value (LTV) %'),
      getClassNames('Assessed Total Value'),
      getClassNames('Assessed Improvement Value'),
      getClassNames('Improvement to Tax Value %')
    )}>
      {/* <div className={newsearchfilter.clsRightBodyScroll}> */}
        <div className={newsearchfilter.clsContentBody}>

          <div className={newsearchfilter.headerBg}>
            <h2 className={newsearchfilter.clsHeading}>Value & Equity</h2>
          </div>


          <div className={classNames(newsearchfilter.pb_28, getClassNames("Estimated Value"), getClassNames("Assessed Land Value"))}>
            <Row>
              <div ref={el => refs.current['Estimated Value'] = el}></div>
              <Col id="estimatedValue" md={6} xs={12} className={getClassNames("Estimated Value")}>
                <FilterComboBox
                  boxTittle={"Estimated Value"}
                  minOptions={EstimatedValueOptions}
                  maxOptions={EstimatedValueOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Estimated Value'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Estimated Value") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.estimatedValueMin, max: ve.estimatedValueMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, 'Estimated Value', "estimatedValue")
                  }
                />
              </Col>
              <div ref={el => refs.current['Assessed Land Value'] = el}></div>
              <Col id="assessedLandValue" md={6} xs={12} className={getClassNames("Assessed Land Value")}>
                <FilterComboBox
                  boxTittle={"Assessed Land Value"}
                  minOptions={AssessedLandOptions}
                  maxOptions={AssessedLandOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Assessed Land Value'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Assessed Land Value") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.assessedLandValueMin, max: ve.assessedLandValueMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, "Assessed Land Value", "assessedLandValue")
                  }
                />
              </Col>
            </Row>
          </div>

             <Row>
              <div ref={el => refs.current['Growth %'] = el}></div>
              <Col id="estimatedValueGrowth" md={6} xs={12} className={classNames(newsearchfilter.pb_28, getClassNames('Growth %'))}>
              <FilterComboBox
                  boxTittle={"Growth %"}
                  minOptions={GrowthValueMinMaxOptions}
                  maxOptions={GrowthValueMinMaxOptions}
                  defaultValues={{ min: ve.estimatedValueGrowthMin, max: ve.estimatedValueGrowthMax }}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Growth %'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Growth %") ? newsearchfilter.filteredSubTitle : ""}
                  isPercent={true}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, 'Growth %', "estimatedValueGrowth")
                  }
                />
              </Col>
              <div ref={el => refs.current['Estimated Wholesale Price'] = el}></div>
              <Col id="wholesaleValue" md={6} xs={12} className={getClassNames('Estimated Wholesale Price')}>
                <FilterComboBox
                  boxTittle={"Estimated Wholesale Price"}
                  minOptions={WholesaleValueOptions}
                  maxOptions={WholesaleValueOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Estimated Wholesale Price'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Estimated Wholesale Price") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.wholesaleValueMin, max: ve.wholesaleValueMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, 'Estimated Wholesale Price', "wholesaleValue")
                  }
                />
              </Col>
           </Row>
          {/* </div> */}



          <div className={classNames(newsearchfilter.pb_28, getClassNames('Estimated Rental Income'), getClassNames('Gross Yield %'))}>
            <Row>
              <div ref={el => refs.current['Estimated Rental Income'] = el}></div>
              <Col id="estimatedRentalIncome" md={6} xs={12} className={getClassNames('Estimated Rental Income')}>
                <FilterComboBox
                  boxTittle={"Estimated Rental Income"}
                  minOptions={RentalIncomeOptions}
                  maxOptions={RentalIncomeOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Estimated Rental Income'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Estimated Rental Income") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.estimatedRentalIncomeMin, max: ve.estimatedRentalIncomeMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, 'Estimated Rental Income', "estimatedRentalIncome")
                  }
                />
              </Col>
              <div ref={el => refs.current['Gross Yield %'] = el}></div>
              <Col id="grossYield" md={6} xs={12} className={getClassNames('Gross Yield %')}>
                <FilterComboBox
                  boxTittle={"Gross Yield %"}
                  minOptions={GrossYieldOptions}
                  maxOptions={GrossYieldOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Gross Yield %'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Gross Yield %") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.grossYieldMin, max: ve.grossYieldMax }}
                  isPercent={true}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, "Gross Yield %", "grossYield")
                  }
                />
              </Col>
            </Row>
          </div>

          <div className={classNames(newsearchfilter.pb_28, getClassNames('Estimated Equity $ Amount'), getClassNames('Estimated Equity % Amount'))}>
            <Row>
              <div ref={el => refs.current['Estimated Equity $ Amount'] = el}></div>
              <Col id="estimatedEquity" md={6} xs={12} className={getClassNames('Estimated Equity $ Amount')}>
                <FilterComboBox
                  boxTittle={"Estimated Equity $ Amount"}
                  minOptions={EquityCurrencyOptions}
                  maxOptions={EquityCurrencyOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  allowNegativeValue={true}
                  isHighlightIndex={filteredFields['Estimated Equity $ Amount'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Estimated Equity $ Amount") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.estimatedEquityMin, max: ve.estimatedEquityMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, "Estimated Equity $ Amount", "estimatedEquity")
                  }
                />
              </Col>
              <div ref={el => refs.current['Estimated Equity % Amount'] = el}></div>
              <Col id="estimatedEquityPercent" md={6} xs={12} className={getClassNames('Estimated Equity % Amount')}>
                <FilterComboBox
                  boxTittle={"Estimated Equity %"}
                  minOptions={EquityPercentOptions}
                  maxOptions={EquityPercentOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  allowNegativeValue={true}
                  isHighlightIndex={filteredFields['Estimated Equity % Amount'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Estimated Equity % Amount") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.estimatedEquityPercentMin, max: ve.estimatedEquityPercentMax }}
                  isPercent={true}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, "Estimated Equity % Amount", "estimatedEquityPercent")
                    //Please confirm if it needs to be Estimated Equity $ Amount or Estimated Equity % Amount
                  }
                />
              </Col>
            </Row>
          </div>

          <div className={classNames(newsearchfilter.pb_28, getClassNames('Loan to Value (LTV) %'), getClassNames('Assessed Total Value'))}>
            <Row>
              <div ref={el => refs.current['Loan to Value (LTV) %'] = el}></div>
              <Col id="loanToValueRatio" md={6} xs={12} className={getClassNames('Loan to Value (LTV) %')}>
                <FilterComboBox
                  boxTittle={"Loan to Value (LTV) %"}
                  minOptions={LoanValueOptions}
                  maxOptions={LoanValueOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Loan to Value (LTV) %'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Loan to Value (LTV) %") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.loanToValueRatioMin, max: ve.loanToValueRatioMax }}
                  isPercent={true}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, "Loan to Value (LTV) %", "loanToValueRatio")
                  }
                />
              </Col>
              <div ref={el => refs.current['Assessed Total Value'] = el}></div>
              <Col id="assessedValue" md={6} xs={12} className={getClassNames('Assessed Total Value')}>
                <FilterComboBox
                  boxTittle={"Assessed Total Value"}
                  minOptions={EquityPercentOptions}
                  maxOptions={EquityPercentOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Assessed Total Value'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Assessed Total Value") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.assessedValueMin, max: ve.assessedValueMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, "Assessed Total Value", "assessedValue")
                  }
                />
              </Col>
            </Row>
          </div>

          <div className={classNames(newsearchfilter.pb_28, getClassNames('Assessed Improvement Value'), getClassNames('Improvement to Tax Value %'))}>
            <Row>
              <div ref={el => refs.current['Assessed Improvement Value'] = el}></div>
              <Col id="assessedImprovementValue" md={6} xs={12} className={getClassNames('Assessed Improvement Value')}>
                <FilterComboBox
                  boxTittle={"Assessed Improvement Value"}
                  minOptions={AssessedValueOptions}
                  maxOptions={AssessedValueOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Assessed Improvement Value'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Assessed Improvement Value") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.assessedImprovementValueMin, max: ve.assessedImprovementValueMax }}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, "Assessed Improvement Value", "assessedImprovementValue")
                    //Please confirm if it needs to be Loan to Value (LTV) % or Assessed Improvement Value
                  }
                />
              </Col>
              <div ref={el => refs.current['Improvement to Tax Value %'] = el}></div>
              <Col id="itvRatio" md={6} xs={12} className={getClassNames('Improvement to Tax Value %')}>
                <FilterComboBox
                  boxTittle={"Improvement to Tax Value %"}
                  minOptions={ImprovementTaxOptions}
                  maxOptions={ImprovementTaxOptions}
                  minLabel={"Min"}
                  maxLabel={"Max"}
                  isHighlightIndex={filteredFields['Improvement to Tax Value %'] === navIndex ? true : false}
                  headerClassName={isFilteredSection("Improvement to Tax Value %") ? newsearchfilter.filteredSubTitle : ""}
                  defaultValues={{ min: ve.itvRatioMin, max: ve.itvRatioMax }}
                  isPercent={true}
                  onSelectValue={(minValue, maxValue) =>
                    comboBoxValueChange(minValue, maxValue, "Improvement to Tax Value %", "itvRatio")
                  }
                />
              </Col>
            </Row>
          </div>


{/*  */}

<div className={classNames(newsearchfilter.pb_28, getClassNames("Include Unknown Equity"))}>
          <div id="unknownEquityIncluded" ref={el => refs.current['Include Unknown Equity'] = el}></div>
          <Row>
            <Col md={6} xs={12} className={getClassNames("Include Unknown Equity")}>
              <ToggleList
                headerTitle={"Include Unknown Equity"}
                backGroudGray={false}
                selected={ve.unknownEquityIncluded}
                options={IncludeExcludeOptions}
                isHighlightIndex={filteredFields['Include Unknown Equity'] === navIndex ? true : false}
                headerClassName={isFilteredSection("Include Unknown Equity") ? newsearchfilter.filteredSubTitle : ""}
                onSelectOption={selectedOption =>
                  handleBooleanSelectPCNew(
                    selectedOption,
                    "unknownEquityIncluded",
                    "Include Unknown Equity"
                  )
                }
              />
            </Col>
           
          </Row>
        </div>


        </div>
      </div>
    // </div>
  )
}

export default ValueEquityScreen
