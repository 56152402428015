import MortgageFrequencies from './MortgageFrequencies';

// eslint-disable-next-line consistent-return
export default function getMortgageFrequencyTerm(frequency) {
  switch (frequency) { // eslint-disable-line default-case
    case MortgageFrequencies.MONTHLY:
      return 1;
    case MortgageFrequencies.QUARTERLY:
      return 3;
    case MortgageFrequencies.SEMI_ANNUALLY:
      return 6;
    case MortgageFrequencies.ANNUALLY:
      return 12;
  }
}
