import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './style.scss';


class ButtonRoundSquare extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.onClick) {
      this.props.onClick({
        name: this.props.name,
        value: this.props.value,
      });
    }
  }

  render() {
    const { label, value, formattedValue = value, active, className, small, ...rest } = this.props;
    const buttonProps = {
      className: classNames(className, { [css.active]: active }, { [css.small]: small }, css.root),
      title: label,
    };

    return (
      <button type="button" {...rest} {...buttonProps} onClick={this.handleClick}>
        <div>
          <div className={css.value}>{formattedValue}</div>
          <div className={css.label}>{label}</div>
        </div>
      </button>
    );
  }
}

ButtonRoundSquare.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  active: PropTypes.bool,
};

ButtonRoundSquare.defaultProps = {
  active: false,
};


export default ButtonRoundSquare;
