/** @flow */
import React from 'react';
import { pure } from 'recompose';
import { Form as ReduxForm } from 'redux-form/immutable';


export const Form = ({ children, name, onSubmit, handleSubmit, ...rest }) => (
  <ReduxForm form={name} {...rest} name={name} onSubmit={handleSubmit(onSubmit)}>
    {children}
  </ReduxForm>
);

export default pure(Form);
