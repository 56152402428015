import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/base/Modal';
import numberToPrice from 'utils/currency/numberToPrice';
import classNames from 'classnames';

import { getAppendJob, selectAppendJob } from 'data/contacts';
import { getPopupRegistration, openPopup, closePopup } from 'app/PopupHolder';

import css from './style.scss';


class AppendJobSummary extends PureComponent {
  componentWillMount() {
    this.props.getAppendJob(this.props.appendJobId);
  }

  render() {
    const { appendJobId, appendJob: { id, lines } } = this.props;

    return (
      <Modal
        isOpen={id !== appendJobId}
        isCloseButton
        uniqId="modalImportContacts"
        caption="Contact Append Summary"
        width="400px"
        border="0"
        padding="20px 35px 35px"
        className={css.root}
      >
        <div className={css.upload}>
          {lines.map((line, i) => (
            <div key={line.description} className={classNames(css.lineContainer, { [css.total]: i === lines.length - 1 })}>
              <div className={css.line}>
                <span className={css.key}>{line.description}</span>
                <span className={css.value}>{numberToPrice(line.total)}</span>
              </div>
              <div className={css.amount}>quantity: {line.quantity}</div>
            </div>
          ))}

        </div>
      </Modal>
    );
  }
}

const AppendJobSummaryPopup = connect(state => ({
  appendJob: selectAppendJob(state).toJS(),
}), {
  getAppendJob,
})(AppendJobSummary);

const registrationId = getPopupRegistration(AppendJobSummaryPopup);
AppendJobSummaryPopup.open = (...rest) => openPopup(registrationId, ...rest);
AppendJobSummaryPopup.close = () => closePopup({ popup: registrationId });

export default AppendJobSummaryPopup;
