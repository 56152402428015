import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { selectLoading, selectMailingLabelLayouts, generateContactMailingLabels, generatePropertyMailingLabels } from 'data/campaigns';
import Modal from 'components/base/Modal';
import Radio from 'components/base/Radio';
import Button, { SolidButton } from 'components/Button';
import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';

import css from './style.scss';

const MailingLabels = ({ loading, layouts, closePopup, propertySearch, contactSearch, generateContactMailingLabels, generatePropertyMailingLabels }) => {
  const [state, setState] = useState({
    layoutId: layouts.getIn([0, 'id']),
    offset: 0,
    format: 'docx',
  });

  const generateLabelsClick = () => {
    const { format, offset, layoutId } = state;

    if (propertySearch) generatePropertyMailingLabels(layoutId, offset, format, propertySearch, closePopup);
    else generateContactMailingLabels(layoutId, offset, format, contactSearch, closePopup);
  };

  const handleChange = (ev) => {
    const { name, value } = ev.target || ev;

    let val = value;

    if (name === 'offset') {
      val = Number(val);
      if (isNaN(val) || val < 0 || val > 29) val = 0;
    }

    setState({ ...state, [name]: val });
  };

  return (
    <Modal isOpen uniqId="mailingLabels" width="1000px" padding="30px" isCloseButton caption={`Mailing Labels - ${(propertySearch || contactSearch).selected} Contacts Selected`}>
      <div className={css.mailingLabels}>
        <div className={css.layouts}>
          {layouts.map(l => (
            <div key={l.get('id')} className={classNames(css.layout, { [css.selected]: l.get('id') === state.layoutId })} onClick={() => handleChange({ name: 'layoutId', value: l.get('id') })}>
              <div className={css.imageContainer}>
                <img className={css.image} src={l.get('imageUrl')} alt={l.get('name')} />
              </div>
              <div className={css.name}>{l.get('name')}</div>
            </div>
          ))}
        </div>
        <div className={css.option}>
          <label>Offset: </label>
          <input onChange={handleChange} name="offset" type="text" value={state.offset} maxLength={2} />
          <div className={css.info}>(This number of label positions will be skipped on the first sheet.)</div>
        </div>
        <div className={css.option}>
          <label>Format: </label>
          <Radio onChange={handleChange} name="format" label="Word" checked={state.format === 'docx'} value="docx" />
          <Radio onChange={handleChange} name="format" label="PDF" checked={state.format === 'pdf'} value="pdf" />
        </div>
        <div className={css.footer}>
          <Button onClick={closePopup} loading={loading}>Close</Button>
          <SolidButton onClick={generateLabelsClick} loading={loading}>Generate Labels</SolidButton>
        </div>
      </div>
    </Modal>
  );
};

const MailingLabelsPopup = connect(state => ({
  loading: selectLoading(state),
  layouts: selectMailingLabelLayouts(state),
}), {
  generateContactMailingLabels,
  generatePropertyMailingLabels,
})(MailingLabels);

const registrationId = getPopupRegistration(MailingLabelsPopup);
MailingLabelsPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });

export default MailingLabelsPopup;
