import getFieldAmount from './getFieldAmount';


export default class AnalysisPeriod {
  constructor() {
    // double
    this.expenseTotal = 0;
    this.expenseUnitTotal = 0;
    this.grossRentAmount = 0;
    this.rentAmount = 0;
    this.additionalIncomeTotal = 0;
    this.propertyManagementAmount = 0;
    this.leasingCommissionAmount = 0;
    this.saleCostAmount = 0;
    this.saleAmount = 0;
    this.propertyDepreciationAmount = 0;
    this.improvementDepreciationAmount = 0;
    this.taxableIncomeAmount = 0;
    this.adjustedTaxBasisAmount = 0;
    this.exchangeExpenseAmount = 0;
    this.saleExpenseAmount = 0;
    this.depreciationRecoveryTaxAmount = 0;
    this.capitalGainTaxAmount = 0;
    this.saleNetIncomeAmount = 0;
    this.vacancyLossAmount = 0;
    this.grossIncomeAmount = 0;
    this.incomeTotal = 0;
    this.grossScheduledIncomeAmount = 0;
    this.grossOperatingIncomeAmount = 0;
    this.operatingExpenseAmount = 0;
    this.netOperatingIncomeAmount = 0;
    this.interestAmount = 0;
    this.principalAmount = 0;
    this.mortgageBalance = 0;
    this.taxDeductionTotal = 0;
    this.cashBeforeTaxAmount = 0;
    this.depreciationTotal = 0;
    this.stateTaxAmount = 0;
    this.federalTaxAmount = 0;
    this.taxTotal = 0;
    this.netIncomeTotal = 0;
    this.taxableGainAmount = 0;
    this.saleTaxTotal = 0;
    this.saleNetGainAmount = 0;
    this.saleNetProfitAmount = 0;
    this.cashOnCashReturnRate = 0;
    this.capRate = 0;
    this.cashBeforeTaxCumulativeAmount = 0;
    this.netIncomeCumulativeTotal = 0;
    this.depreciationCumulativeTotal = 0;
    this.interestCumulativeAmount = 0;
    this.principalCumulativeAmount = 0;
    this.operatingExpenseCumulativeAmount = 0;
    this.grossScheduledIncomeCumulativeAmount = 0;
    this.taxCumulativeTotal = 0;
    this.netOperatingIncomeCumulativeAmount = 0;
    this.expenseTotal = 0;
    this.monthlyExpenseTotal = 0;
    this.monthlyExpenseCumulativeTotal = 0;
    this.grossOperatingIncomeCumulativeAmount = 0;

    // int
    this.term = 0;
    this.seq = 0;

    // Date
    this.date = null;

    // AnalysisExpense[]
    this.expenses = [];

    // AnalysisIncome[]
    this.incomes = [];

    // AnalysisMortgagePeriod[]
    this.mortgages = [];
  }


  getIncomeAmount(...incomesIds) {
    return getFieldAmount(this.incomes, ...incomesIds);
  }

  getExpenseAmount(...expenseIds) {
    return getFieldAmount(this.expenses, ...expenseIds);
  }

  getMortgageBalance(i) {
    return this.mortgages.length > i ? this.mortgages[i].balance : 0;
  }

  getMortgageInterestAmount(i) {
    return this.mortgages.length > i ? this.mortgages[i].interestAmount : 0;
  }
}
