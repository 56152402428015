import classNames from "classnames";
import Button from "components/base/Button";
import React, { useState } from "react";
import css from "../style.scss";
import { ReactSVG } from "react-svg";
import SelectAllCheck from "../../../../../../assets/images/check-green.svg"
import { useEffect } from "react";

// Child ToggleButton component
const ToggleButton = ({ option, selected, onClick }) => {
  const buttonClasses = classNames(css.clsBtnList, {
    [css.clsActive]: selected
  });
  return (
    <Button
      kind="link-default"
      size="large"
      className={buttonClasses}
      onClick={() => onClick(option)}
    >
      {option?.name}
    </Button>
  );
};

// Parent ToggleSection component 
const ToggleSection = ({ options, headerTitle, onSelectClickAction , selectedIds , splitByColon = false, headerClassName, isHighlightIndex }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAll, setShowAll] = useState(false);

  // const [displayedOptions, setDisplayedOptions] = useState(showAll ? options : options?.slice(0, 3));

  const [displayedOptions, setDisplayedOptions] = useState([]);

  const allOptionsSelected = selectedOptions.length === options.length;


  useEffect(() => {
    if (selectedIds) {
      getOptionsById(selectedIds)
    } else {
      setSelectedOptions([]);
    }
    if (options) {
      setDisplayedOptions(options?.slice(0, 3))
      setShowAll(false)
    } else {
      setSelectedOptions([]);
    }
  }, [selectedIds, options]);

  const getOptionsById  = (selectedIds) => {
    let selectedIdsArray = []
    if(splitByColon) {
      selectedIdsArray =  selectedIds.includes(':') ? selectedIds.split(':') : [selectedIds]
    }else {
      selectedIdsArray =  selectedIds.includes(',') ? selectedIds.split(',') : [selectedIds]
    }
    // const selectedIdsArray =  selectedIds.includes(',') ? selectedIds.split(',') : [selectedIds]
     const filteredOptions = options.filter(option => selectedIdsArray.includes(option.id));
     setSelectedOptions(filteredOptions);
  }

  const handleOptionClick = option => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
      onSelectClickAction(selectedOptions.filter(item => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
      onSelectClickAction([...selectedOptions, option]);
    }
    
  };

  const handleSelectAllClick = () => {
    if (allOptionsSelected) {
      setSelectedOptions([]);
      onSelectClickAction([]);
    } else {
      setSelectedOptions(options);
      onSelectClickAction(options);
    }
  };

  const handleShowAllClick = () => {
    handleShowAllClickOptions(!showAll)
    setShowAll(!showAll);
  };

  const handleShowAllClickOptions = (showAll) => {
    let displayedOptions;
    if (showAll) {
      displayedOptions = options
    } else {
      // const selectedCount = selectedOptions.length;
      // if (selectedCount >= 3) {
      //   displayedOptions = selectedOptions.slice(0, 3);
      // } 
      // else {

            // Get IDs of unselected options
      // const unselectedIds = options
      // .map(option => option.id)
      // .filter(id => !selectedOptions.some(selected => selected.id === id));

      // Combine selected and unselected IDs, ensuring a total of three
      // const selectedAndUnselectedIds = selectedOptions
      // .map(option => option.id)
      // .concat(unselectedIds)
      // .slice(0, 3);

      // Filter options based on combined IDs
      displayedOptions = options?.slice(0, 3)
      // options.filter(option => selectedAndUnselectedIds.includes(option.id));

      // }
    }
    setDisplayedOptions(displayedOptions) 
  };

 

  return (
    <>
    {options && options.length !== 0 && (
       <div className={css.px_16}>
       <div className={classNames(css.flexBoxWrap, css.align_items_center)}>
         <h4 className={classNames(( headerClassName === "" ? css.clsSubHeading: headerClassName), css.mb_0, css.mt_0, (isHighlightIndex ? css.highlightOrange : ""))}>
           {headerTitle ? headerTitle : ''}
         </h4>
 
         {allOptionsSelected ? (
           <Button
             kind="link-default"
             className={classNames(css.selctAllBtn, css.checkSelctedBtn)}
             size="large"
             onClick={handleSelectAllClick}
           >
             <span className={classNames(css.selctAllCrle, css.checkSelctCrle)}>
               <ReactSVG src={SelectAllCheck} className={css.svgCheckIcon} />
             </span>
             Select All
           </Button>
         ) : (
           <Button
             kind="link-default"
             className={css.selctAllBtn}
             size="large"
             onClick={handleSelectAllClick}
           >
             <span
               className={classNames(css.selctAllCrle, {
                 [css.minSelctCrle]: !allOptionsSelected || selectedOptions.length > 0,
                 [css.unSelctCrle]: selectedOptions && selectedOptions.length === 0
               })}
             ></span>
             Select All
           </Button>
         )}
       </div>
       <div className={css.clsGroupBtn}>
         {displayedOptions &&
           displayedOptions.map((option, index) => (
             <ToggleButton
               key={index}
               option={option}
               selected={selectedOptions.includes(option)}
               onClick={handleOptionClick}
             />
           ))}
         <Button
           kind="link-default"
           className={css.clsShowAll}
           size="large"
           onClick={handleShowAllClick}
         >
           {!showAll ? "Show All" : "Show Less"}
         </Button>
       </div>
     </div>
    )}
    </>
  );
};

export default ToggleSection;