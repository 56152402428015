import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';

import { selectProfile, selectLoading, upgradeAccount, saveUser, deleteUser } from 'data/user';

import Button, { SolidButton } from 'components/Button';
import { BlueLink } from 'components/Link';
import Checkbox from 'components/base/Checkbox';
import Text, { Password } from 'components/Text';
import Dropdown from 'components/base/Dropdown';
import Radio from 'components/base/Radio';
import Confirm from 'app/components/Confirm';
import AddServiceConfirmation from 'app/components/AddServiceConfirmation';
import TeamMemberList from 'app/components/TeamMemberList';
import currencyFormat from 'utils/currency/numberToPrice';
import { validate, verify, verifyRequired, isValidEmail, isValidPhone } from 'utils/validation';
import { formatDateShort, today } from 'utils/date/formatDate';
import UserLogo from 'app/components/UserLogo';
import { selectSmsEnabled } from 'data/campaigns';

import css from './style.scss';
import cssAccount from '../style.scss';
// get value for password from Bitwarden = Propstream local devuser
const defaultUserDev = {
  firstName: 'Dev',
  lastName: 'User',
  username: 'devuser_20200711_@test.com',
  password: '',
  phone: '4443335555',
  propertyLimit: 999,
  spendingLimit: 524,
  purchaseNotificationEnabled: true,
  spendingReportEnabled: true,
  agreeToTerms: true,
};

const defaultUser = process.env.NODE_ENV === 'dev-server' ? defaultUserDev : {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  phone: '',
  propertyLimit: '',
  spendingLimit: '',
  purchaseNotificationEnabled: false,
  spendingReportEnabled: false,
  agreeToTerms: false,
};

const typeLabels = {
  TEAM_FULL: 'Web + Mobile',
  TEAM: 'Web Only',
  SCOUT: 'Scout - (Limited Mobile Only - Free)',
};

class AccountTeam extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClear = this.handleClear.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleLogoRemove = this.handleLogoRemove.bind(this);
    this.handleLogoChange = this.handleLogoChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleReplace = this.handleReplace.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleCancelDowngradeClick = this.handleCancelDowngradeClick.bind(this);

    this.ref = {};

    const { planMrcs, profile: { paymentMethods } } = props;
    const findPm = type => (paymentMethods.find(p => p[type]) || paymentMethods[0] || {}).id || null;

    this.userDefaults = {
      paymentMethodId: findPm('billingPaymentMethod'),
      purchasePaymentMethodId: findPm('purchasePaymentMethod'),
    };

    const type = planMrcs.find(p => p.type === 'TEAM_FULL') ? 'TEAM_FULL' : 'TEAM';

    this.state = {
      replace: false,
      user: {
        ...(process.env.NODE_ENV !== 'dev-server' ? defaultUser : defaultUserDev),
        ...this.userDefaults,
        type,
      },
    };
  }

  getPlanMrc() {
    const { planMrcs } = this.props;
    const { type, accountPlanMrc = {} } = this.state.user;

    // Get currently selected mrc. If the currect selection is the active one on the account, return the mrc in the user object. This will have the exact one on their account, which may have special pricing.
    return type === accountPlanMrc.userType ? accountPlanMrc : planMrcs.find(p => p.value === type);
  }

  getPlanMrcs() {
    const { planMrcs } = this.props;
    const { accountPlanMrc = {} } = this.state.user;

    // Get plan MRCs, replacing the active one with the one in the user option, which will always reflect the current pricing on their account. (They may have special pricing not available for new Team Members.)
    return planMrcs.map(m => (m.type === accountPlanMrc.userType ? { ...m, ...accountPlanMrc } : m));
  }

  getFormData(orig = false) {
    const { user, origUser, replace } = this.state;
    const { id: planMrcId } = this.getPlanMrc();

    const formData = new FormData();

    const u = orig ? origUser : user;

    Object.keys(u).forEach((property) => {
      formData.append(property, u[property]);
    });

    formData.append('replace', replace);
    formData.append('planMrcId', orig || !u.pendingAccountPlanMrc ? planMrcId : u.pendingAccountPlanMrc.id);

    return formData;
  }

  handleEdit(u) {
    const user = { ...defaultUser, ...u };
    this.setState({ replace: false, user, origUser: user });
  }

  handleReplace(u) {
    const { id, fullName, type, accountPlanMrc } = u;
    const user = { ...this.userDefaults, ...defaultUser, id, fullName, type, accountPlanMrc };

    this.setState({ replace: true, user, origUser: user });
  }

  handleRemove(user) {
    const { confirmRemoval, deleteUser, profile: { nextCycleDate } } = this.props;

    confirmRemoval({
      onOk: () => deleteUser(user.id, this.handleClear),
      caption: `Delete Team Member "${user.fullName}"?`,
      question: `You are about to Delete a Team Member, this Team Member slot will still be accessible until your next billing cycle (${formatDateShort(nextCycleDate)}).`,
    });
  }

  handleClear() {
    this.setState({ replace: false, user: { ...this.userDefaults, ...defaultUser } });
  }

  handleLogoRemove() {
    this.setState({ user: { ...this.state.user, logo: null, logoUrl: null } });
  }

  handleLogoChange(ev) {
    const file = (ev.target.files || [])[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = ev => this.setState({ user: { ...this.state.user, logo: file, logoUrl: ev.target.result } });
      fileReader.readAsDataURL(file);
    }
  }

  handleCancelDowngradeClick() {
    const { confirm, saveUser } = this.props;

    confirm({
      question: 'Cancel member access downgrade?',
      onOk: () => saveUser(this.getFormData(true), this.handleClear),
    });
  }

  handleSave() {
    const r = this.ref;
    const { replace, user } = this.state;
    const { id, firstName, lastName, username, password, phone, propertyLimit, spendingLimit, agreeToTerms, paymentMethodId, purchasePaymentMethodId, type, accountPlanMrc: { prorateAmount: currentAmount = 0 } = {} } = user;
    const { confirm, saveUser, openAddServiceConfirmation } = this.props;
    const { id: planMrcId, prorateAmount } = this.getPlanMrc();
    const scout = type === 'SCOUT';

    validate(() => {
      verifyRequired(r.firstName, firstName, 'First Name is required.');
      verifyRequired(r.lastName, lastName, 'Last Name is required.');

      // SSO accounts will not have a login set, so only require it for people who have a login currently set. Require password if new login is being set.
      verifyRequired(r.username, username, 'Email is required.');
      verify(r.username, isValidEmail(username), 'Email is invalid.');

      // Ignore password if blank or all asterisks; existing password will be preserved.
      if (!id || (password !== '' && !/^\*+$/.test(password))) {
        verifyRequired(r.password, password, 'Password is required.');
        verify(r.password, password.length >= 8, 'Password must be at least 8 characters.');
        verify(r.password, password.length <= 20, 'Password cannot exceed 20 characters.');
        verify(r.password, !/\s/.test(password), 'Password cannot contain any white space.');
        verify(r.password, /[A-Z]/.test(password), 'Password must contain at least one capital letter.');
        verify(r.password, /[^a-zA-Z0-9]/.test(password), 'Password must contain at least one symbol.');
      }

      verifyRequired(r.phone, phone, 'Phone Number is required.');
      verify(r.phone, phone.trim() === '' || isValidPhone(phone), 'Phone Number is invalid.');

      if (!scout) {
        verify(r.propertyLimit, String(propertyLimit).trim() === '' || Number(propertyLimit) >= 0, 'Download Limit is invalid.');
        verify(r.spendingLimit, String(spendingLimit).trim() === '' || Number(spendingLimit) >= 0, 'Spending Limit is invalid.');

        verify(r.paymentMethodId, paymentMethodId > 0, 'Monthly Subscription Credit Card is required.');
        verify(r.purchasePaymentMethodId, purchasePaymentMethodId > 0, 'In-App Purchase Credit Card is required.');
      }

      verify(r.agreeToTerms, id || agreeToTerms, 'You must agree to the Billing Terms.');

      const name = `${firstName} ${lastName}`;
      const formData = this.getFormData();

      const save = () => saveUser(formData, this.handleClear);

      const run = () => {
        const chargeAmount = prorateAmount - currentAmount;

        if (!chargeAmount) save();
        else if (chargeAmount < 0) {
          confirm({
            question: 'Change member access level? This change will take effect in your next billing cycle.',
            onOk: save,
          });
        } else {
          openAddServiceConfirmation({
            planMrcId,
            paymentMethodId,
            chargeAmount,
            onPurchase: (amount, cardCode, password, onSuccess) => {
              formData.append('approvedAmount', amount);
              formData.append('cardCode', cardCode);
              formData.append('accountPassword', password);
              saveUser(formData, () => {
                this.handleClear();
                onSuccess();
              });
            },
          });
        }
      };

      if (id) {
        confirm({
          question: replace ? 'You are about to Replace the Team Member with all new information, this should be used when a Team Member is no longer part of your Team.' : `Apply changes to team member ${name}?`,
          onOk: run,
        });
      } else if (!scout) run();
      else {
        confirm({
          question: 'Add new Scout user?',
          onOk: save,
        });
      }
    });
  }

  handleChange(ev) {
    const { name, value, checked } = ev.target;
    const { user } = this.state;
    const stateChange = {};

    stateChange[name] = ['purchaseNotificationEnabled', 'spendingReportEnabled', 'agreeToTerms', 'smsEnabled'].includes(name) ? checked : value;

    this.setState({ user: { ...user, ...stateChange } });
  }

  handleRef(element) {
    if (element) {
      const el = element.tagName ? element : findDOMNode(element);
      this.ref[el.name] = el;
    }
  }

  render() {
    const { loading, accountSmsEnabled, profile: { paymentMethods, fullAccount } } = this.props;
    const { replace, user } = this.state;
    const { id, fullName, purchaseNotificationEnabled, spendingReportEnabled, agreeToTerms, logoUrl, paymentMethodId, purchasePaymentMethodId, type, pendingAccountPlanMrc, smsEnabled } = user;
    const { amount, prorated, prorateAmount } = this.getPlanMrc();
    const planMrcs = this.getPlanMrcs();

    const inputProps = { onInputRef: this.handleRef, onChange: this.handleChange, value: user };
    const dropdownProps = { onInputRef: this.handleRef, onChange: this.handleChange };

    const paymentMethodOptions = (paymentMethods || []).map(({ id, description }) => ({ label: description, value: id }));

    return (
      <div className={css.team}>
        <div className={cssAccount.left}>
          <div className={cssAccount.header}>Build your Team.</div>
          <div className={cssAccount.information}>
            Team Member accounts can Research Properties, Run Comps, Create Lists &amp; utilize Marketing Services like Skip Tracing, Postcards, Ringless Voicemails &amp; E-Mails.
            <br /><br />
            These accounts share the current subscription and are limited to that subscription tier of access.
            <br /><br />
            As the Primary Account Holder you can set monthly limits for each Team Member. Set a limit to the number of properties they can save/download each month and also set a limit to the amount of money they can spend each month on Marketing Services.
            <br /><br />
            You are also the only one that has access to the account information &amp; an ability to update credit cards &amp; turn on/off Team Member access.
          </div>
          <TeamMemberList onEditClick={this.handleEdit} onReplaceClick={this.handleReplace} onDeleteClick={this.handleRemove} loading={loading} primaryEnabled={false} />
        </div>
        <div className={cssAccount.right}>
          <div className={cssAccount.header}>
            {id ? `${replace ? 'Replace' : 'Edit'} User - ${fullName}` : (
              <div>
                Add New Member -&nbsp;
                <span className={cssAccount.norm}>
                  <span className={cssAccount.highlight}>{currencyFormat(amount)} per month, per user.</span>
                </span>
              </div>
            )}
          </div>
          <div className={cssAccount.fields}>
            <Text {...inputProps} name="firstName" label="First Name" />
            <Text {...inputProps} name="lastName" label="Last Name" />
            <Text {...inputProps} name="username" label="Email / Username" readOnly={!!id && !replace} />
            <Password {...inputProps} name="password" label="Password" />
            <Text {...inputProps} name="phone" label="Phone" />
            <div className={classNames(cssAccount.field, css.logoField)}>
              <label htmlFor="paymentMethod">Upload Photo</label>
              <div>
                <Button onChange={this.handleLogoChange} upload loading={loading}>Choose Photo</Button>
                {!logoUrl ? null : <Button onClick={this.handleLogoRemove} loading={loading}>Remove Photo</Button>}
              </div>
              <UserLogo url={logoUrl} small className={css.logo} />
            </div>
            {type === 'SCOUT' ? null : (
              <div className={cssAccount.fields}>
                <div className={cssAccount.sep} />
                <Text {...inputProps} name="propertyLimit" label="Download Limit (Monthly)" placeholder="Unlimited" />
                <Text {...inputProps} name="spendingLimit" label="Spending Limit (Monthly)" placeholder="Unlimited" />
                <div className={cssAccount.field}>
                  <label htmlFor="paymentMethodId">Monthly Subscription Credit Card</label>
                  <Dropdown {...dropdownProps} name="paymentMethodId" options={paymentMethodOptions} value={paymentMethodId} />
                </div>
                <div className={cssAccount.field}>
                  <label htmlFor="purchasePaymentMethodId">In-App Purchase Credit Card</label>
                  <Dropdown {...dropdownProps} name="purchasePaymentMethodId" options={paymentMethodOptions} value={purchasePaymentMethodId} />
                </div>
              </div>
            )}
            <div className={cssAccount.sep} />
            <div className={cssAccount.field} style={{ width: '100%' }}>
              <label>Member Access</label>
              <div className={cssAccount.radioRow}>
                {planMrcs.map(option => (
                  <Radio {...option} key={option.value} name="type" onChange={this.handleChange} checked={type === option.value} className={cssAccount.radio} disabled={!!pendingAccountPlanMrc || replace} />
                ))}
              </div>
              {!pendingAccountPlanMrc ? null : (
                <div className={css.downgrade}>
                  Access will be downgraded to {typeLabels[pendingAccountPlanMrc.userType]} on your next billing cycle. <BlueLink onClick={this.handleCancelDowngradeClick}>Cancel Downgrade</BlueLink>
                </div>
              )}
              {!accountSmsEnabled ? null : (
                <div style={{ marginTop: '10px' }}>
                  <label>SMS Texting Permission</label>
                  <div className={cssAccount.radioRow} style={{ paddingTop: '6px' }}>
                    <Checkbox
                      label="I, grant my Team Member access to my Launch Control Texting Account, I understand it is a shared account and I accept & authorize all charges incurred with their access to my account."
                      name="smsEnabled"
                      style={{ marginTop: '8px' }}
                      checked={smsEnabled}
                      onChange={this.handleChange}
                      onInputRef={this.handleRef}
                      labelClassName={cssAccount.wrapLabel}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={cssAccount.sep} />
          </div>
          <div>
            <div className={cssAccount.preferences} style={{ display: 'none' }}>
              <Checkbox label="Notify for every transaction" name="purchaseNotificationEnabled" checked={purchaseNotificationEnabled} onChange={this.handleChange} onInputRef={this.handleRef} />
              <Checkbox label="Receive monthly spending report" name="spendingReportEnabled" checked={spendingReportEnabled} onChange={this.handleChange} />
            </div>
            {fullAccount ? (
              <div>
                {id ? null : (
                  <div className={cssAccount.preferences}>
                    <Checkbox
                      label={`I understand that by adding a Team Member I will be billed ${prorated ? `a pro-rated amount of ${currencyFormat(prorateAmount)} now and an additional ` : ''}${currencyFormat(amount)} per month on a month-to-month basis until I cancel my subscription or delete the Team Member.`}
                      name="agreeToTerms"
                      checked={agreeToTerms}
                      onChange={this.handleChange}
                      onInputRef={this.handleRef}
                    />
                  </div>
                )}
                <div className={classNames(cssAccount.buttons, css.buttons)}>
                  <Button onClick={this.handleClear} loading={loading}>Clear Changes</Button>
                  <SolidButton onClick={this.handleSave} loading={loading}>{id ? ((replace && 'Replace') || 'Update') : 'Add New'} Team Member</SolidButton>
                </div>
              </div>
            ) : (
              <div className={cssAccount.alert}>
                Team Members can only be added to active subscriptions. Please click the &quot;Activate Subscription&quot; above to activate.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// , I accept &amp; authorize all charges incurred with their access to my account

export default connect((state) => {
  const profile = selectProfile(state).toJS();

  // Get sub-user types (ACTIVATION_CODE will become SUB_USER once other sub-user types are live). Reverse array since default is least to greatest amount.
  const planMrcs = profile.planMrcs.filter(p => ['ACTIVATION_CODE', 'SUB_USER'].includes(p.type)).map(p => ({ ...p, value: p.userType, label: `${typeLabels[p.userType]}${!p.amount ? '' : ` (${currencyFormat(p.amount)})`}` }));
  planMrcs.sort((a, b) => b.amount - a.amount);

  return {
    profile,
    loading: selectLoading(state),
    accountSmsEnabled: selectSmsEnabled(state),
    planMrcs,
  };
}, {
  upgradeAccount,
  saveUser,
  deleteUser,
  confirm: Confirm.open,
  confirmRemoval: Confirm.confirmRemoval,
  openAddServiceConfirmation: AddServiceConfirmation.open,
})(AccountTeam);
