import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';
import Map from 'app/components/Map';

import Modal from 'components/base/Modal';

import css from './style.scss';


class PropertyGroupMap extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { activePin: null };
  }

  render() {
    const { properties, groupName } = this.props;

    const pins = fromJS(properties.filter(p => p.latitude).map(({ propertyId, latitude, longitude, listingType, resultIndex }) => ({ id: propertyId, latitude, longitude, listingType, resultIndex: String(resultIndex) })));

    return (
      <Modal
        isOpen
        isCloseButton
        uniqId="modal-properties-map"
        caption={`${groupName} Map`}
        width="1300px"
      >
        <div className={css.modalBody}>
          <Map properties={pins} infoEnabled />
        </div>
      </Modal>
    );
  }
}

const PropertyGroupMapPopup = connect()(PropertyGroupMap);

const registrationId = getPopupRegistration(PropertyGroupMapPopup);

PropertyGroupMapPopup.open = props => openPopup(registrationId, { priority: Priority.LOW, ...props });
PropertyGroupMapPopup.close = () => closePopup({ popup: registrationId });

export default PropertyGroupMapPopup;
