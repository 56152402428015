/** @flow */
import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import Cell from './Cell';


class Row extends PureComponent {
  constructor(props) {
    super(props);
    (this).onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (this.props.onRowClick) {
      this.props.onRowClick(event, this.props.data, this.props.index);
    }
  }

  getCells() {
    const { data, index } = this.props;

    return this.props.columnsMeta.map((meta, columnIndex) => {
      const { renderCell, renderTooltip, cellClassName, field, addIndex = false } = meta;
      const fieldPath = String(field).split('.');
      const cellData = data.getIn(fieldPath, meta.defaultValue);
      const content = renderCell(
        cellData,
        { row: index, column: columnIndex, fieldPath },
        data,
        renderTooltip,
        meta.extraProps,
      );
      const key = addIndex ? `${field} ${columnIndex}` : `${field}${columnIndex}`;

      return (
        <Cell key={key} className={cellClassName} data-row-index={index} data-index={columnIndex} data-value={cellData}>
          {content}
        </Cell>
      );
    });
  }

  render() {
    const { columnsMeta, index, onRowClick, data, ...rest } = this.props;

    return (
      <tr {...rest} data-index={index} onClick={this.onClick}>
        {this.getCells()}
      </tr>
    );
  }
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  columnsMeta: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    renderCell: PropTypes.func.isRequired,
    renderTooltip: PropTypes.func.isRequired,
  })).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
};

Row.defaultProps = ({
  columnsMeta: [],
});


export default Row;
