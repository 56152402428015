import React, { PureComponent } from 'react';

import css from './style.scss';
import PropTypes from 'prop-types';


class AdditionalField extends PureComponent {
  render() {
    const { id, isActive, children } = this.props;
    if (!isActive) return null;
    return (
      <div className={css.root} id={id}>
        {children}
      </div>
    );
  }
}

AdditionalField.defaultProps = {
  isActive: false,
};

AdditionalField.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.node,
};

export default AdditionalField;
