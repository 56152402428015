import percentFormat from '../percent/formatPercent';
import currencyFormat from '../currency/numberToPrice';
import dateFormat from '../date/formatDate';
import numberFormat from '../number/format';
import { toProperCase } from '../string';
import AddressCellRenderer from './addressCellRenderer';
import PropertyAddressCellRenderer from './propertyAddressCellRenderer';
import { getCategoryFromPCScore, getRangeFromFFScoreCategory } from '../../app/Search/Header/searchFilterNew/shared/ConstantsNew';
// Convenience function for using grid formatters with arbitrary object
export const gridValue = (data, field) => (data ? { data, value: data[field], colDef: { field } } : null);

export const percentFormatter = d => percentFormat(d.value * 100);
export const currencyFormatter = ({ value, data, colDef: { field } }) => (isNaN(Number(value)) ? null : `${currencyFormat(value)}${data[`${field}Estimated`] ? '' : ''}`);
export const dateFormatter = ({ value, data, colDef: { field } }) => data && dateFormat(value, null, null, data[`${field}Format`]);
export const numberFormatter = d => numberFormat(d.value);
export const properCaseFormatter = d => toProperCase(d.value);
export const naFormatter = d => (d.value == null || d.value === undefined ? 'n/a' : d.value);
export const vacantFormatter = d => (d.value ? 'Vacant' : 'Occupied');
export const ownerOccupiedFormatter = d => (d.value ? 'Owner Occupied' : 'Non-Owner Occupied');
export const ownerCorporateFormatter = d => (d.value ? 'Corporate' : 'Individual');
export const purchaseMethodFormatter = d => (d.value ? 'Cash' : 'Financed');
export const yesNoFormatter = ({ value: val }) => (val === null || val === undefined ? '' : ((val && 'Yes') || 'No'));
export const rangeToScoreFormatter = ({ value: val }) => getCategoryFromPCScore(val);
export const rangeToFFScoreFormatter = ({ value: val }) => getRangeFromFFScoreCategory(val);


const ColumnTypes = {
  STRING: {
    filter: 'agTextColumnFilter',
    width: 100,
  },
  INT: {
    filter: 'agNumberColumnFilter',
    formatter: numberFormatter,
    width: 60,
  },
  DATE: {
    filter: 'agDateColumnFilter',
    formatter: dateFormatter,
  },
  DECIMAL: {
    filter: 'agNumberColumnFilter',
    formatter: numberFormatter,
  },
  PERCENT: {
    filter: 'agNumberColumnFilter',
    formatter: percentFormatter,
  },
  CHAR: {
    filter: 'agTextColumnFilter',
    formatter: rangeToFFScoreFormatter,
  },
  CURRENCY: {
    filter: 'agNumberColumnFilter',
    formatter: currencyFormatter,
  },
  YESNO: {
    formatter: yesNoFormatter,
  },
  DOUBLE: {
    filter: 'agTextColumnFilter',
    formatter: rangeToScoreFormatter,
  },
  PROPERTY_ADDRESS: {
    filter: 'agTextColumnFilter',
    renderer: 'propertyAddressCellRenderer',
  },
};
var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
  maxValidYear: 9999,
  inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};


const mapFieldColumns = (fields, presets = {}) => (
  fields.map(({ name, path, pin, type, width, hidden }) => {
    const { filter, width: defWidth, renderer, formatter, cellClass, onCellClicked, hidden: forceHidden = false } = { ...ColumnTypes[type], ...(presets[path] || {}) };

    const cols = {
      headerName: name,
      headerTooltip: name,
      field: path,
      // filter,
      // filterParams,
      // colId: path,  // colId defaults to the "field" value but still must be set. Otherwise a bug causes "_1" to be appended to the colId after dynamic column changes. https://github.com/ag-grid/ag-grid/issues/2889
      pinned: (pin && pin.toLowerCase()) || null,
      width: width || defWidth || 80,
      filter: filter || null,
      cellRenderer: renderer || null,
      valueFormatter: formatter || null,
      hide: hidden || forceHidden,
      fieldType: type,
      cellClass,
      onCellClicked,
    };
    if (name && name.toLowerCase().includes('date')) {
      cols.filter = 'agDateColumnFilter'; 
      cols.filterParams = filterParams
    }
    return cols;
  })
);

/**
 * Utility function used to either update a grid's row dataset when new data is being loaded, or to simply update the selection
 * if only the selection has changed. Main issue this addresses is preventing the grid from scrolling back to the top whenever
 * a record is selected. (i.e. as a result of replacing the dataset entirely.)
 */
export const handleSurroundingTypeChange = (context, type) => {
  if (context.gridApi && !type.get('loading')) {
    const dataVersion = type.get('dataVersion');
    const selectionVersion = type.get('selectionVersion');
    let dataChanged = context.dataVersion !== dataVersion;

    // Update rows' selection status if selection has changed
    if (!dataChanged && context.selectionVersion !== selectionVersion) {
      const selMap = type.get('selectionMap');
      const updateNodes = [];
      context.gridApi.forEachNode((node) => {
        const { id, selected } = node.data;
        const sel = selMap.get(id, false);
        if (selected !== sel) updateNodes.push({ node, sel });
      });

      // Updating individual node values is very expensive (triggers a render for each) so if a ton are being changed we'll just replace the datasource instead
      if (updateNodes.length > 100) dataChanged = true;
      else {
        updateNodes.forEach(({ node, sel }) => node.setDataValue('selected', sel));

        // Header won't automatically pick up on state change, so manual refresh is needed to have the "select all" state update.
        context.gridApi.refreshHeader();
      }
    }

    // All row data will be replaced if 1) the underlying data has changed or 2) enough records are having their selection status change at once.
    if (dataChanged) context.gridApi.setRowData(type.get('properties').toJS());

    Object.assign(context, { dataVersion, selectionVersion });
  }
};

export default mapFieldColumns;

export { AddressCellRenderer, PropertyAddressCellRenderer };

// { headerName: 'Address', field: 'streetAddress', width: 165, filter: 'agTextColumnFilter', cellRenderer: 'streetAddressCellRenderer', pinned: 'left' },
// { headerName: 'City', field: 'cityName', width: 130, filter: 'agTextColumnFilter', pinned: 'left' },
// { headerName: 'State', field: 'stateCode', width: 55, filter: 'agTextColumnFilter', pinned: 'left' },
// { headerName: 'Zip', field: 'zip', width: 55, filter: 'agTextColumnFilter', pinned: 'left' },
// { headerName: 'County', field: 'countyName', width: 100, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'APN', field: 'apn', width: 100, filter: 'agTextColumnFilter' },
// { headerName: 'Type', field: 'landUse', width: 80, filter: 'agTextColumnFilter' },
// { headerName: 'Beds', field: 'bedrooms', width: 50, filter: 'agNumberColumnFilter' },
// { headerName: 'Baths', field: 'bathrooms', width: 55, filter: 'agNumberColumnFilter' },
// { headerName: 'SqFt.', field: 'squareFeet', width: 60, filter: 'agNumberColumnFilter', valueFormatter: numberFormatter },
// { headerName: 'Lot SqFt.', field: 'lotSquareFeet', width: 63, filter: 'agNumberColumnFilter', valueFormatter: numberFormatter },
// { headerName: 'Year Built', field: 'yearBuilt', width: 50, filter: 'agNumberColumnFilter' },
// { headerName: 'Assessed Value', field: 'assessedValue', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter },
// { headerName: 'Property Tax', field: 'taxAmount', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter, hide: true },
// { headerName: 'Tax Year', field: 'taxYear', width: 50, filter: 'agNumberColumnFilter', hide: true },
// { headerName: 'Estimated Value', field: 'estimatedValue', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter },
// { headerName: 'Monthly Rent', field: 'rentAmount', width: 70, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter, hide: true },
// // { headerName: 'Sale Date', field: 'lastSaleDate', width: 60, filter: 'agDateColumnFilter', valueFormatter: dateFormatter },
// { headerName: 'Sale Amount', field: 'lastSaleAmount', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter },
// // { headerName: 'Sale Recording Date', field: 'lastRecordingDate', width: 60, filter: 'agDateColumnFilter', valueFormatter: dateFormatter },
// { headerName: 'Open Loans', field: 'lienCount', width: 60, filter: 'agNumberColumnFilter' },
// { headerName: 'Total Mortgage Debt', field: 'lienAmount', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter },
// { headerName: 'LTV %', field: 'ltvRatio', width: 60, filter: 'agNumberColumnFilter', valueFormatter: percentFormatter },
// { headerName: 'Mortgage Amount', field: 'lastMortgage1Amount', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter, hide: true },
// { headerName: 'Mortgage Rate', field: 'lastMortgage1InterestRate', width: 80, filter: 'agNumberColumnFilter', valueFormatter: percentFormatter, hide: true },
// { headerName: 'Equity $', field: 'estimatedEquity', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter },
// { headerName: 'Equity %', field: 'estimatedEquityPercent', width: 70, filter: 'agNumberColumnFilter', valueFormatter: percentFormatter },
// { headerName: 'MLS Status', field: 'mlsListingStatus', width: 80, filter: 'agTextColumnFilter', valueFormatter: properCaseFormatter },
// { headerName: 'Marketing Lists', field: 'listQuantity', width: 80, filter: 'agNumberColumnFilter', cellClass: marketingCellClass, onCellClicked: this.handleMarketingCellClicked },
// { headerName: 'Marketing Campaigns', field: 'campaignQuantity', width: 85, filter: 'agNumberColumnFilter', cellClass: marketingCellClass, onCellClicked: this.handleMarketingCellClicked },
// { headerName: 'Skip Traces', field: 'contactAppendQuantity', width: 60, filter: 'agNumberColumnFilter', cellClass: marketingCellClass, onCellClicked: this.handleMarketingCellClicked, hide: !this.lmEnabled },
// { headerName: 'Voicemail Drops', field: 'voicemailCampaignQuantity', width: 75, filter: 'agNumberColumnFilter', cellClass: marketingCellClass, onCellClicked: this.handleMarketingCellClicked, hide: !this.lmEnabled },
// { headerName: 'Postcards', field: 'postcardCampaignQuantity', width: 80, filter: 'agNumberColumnFilter', cellClass: marketingCellClass, onCellClicked: this.handleMarketingCellClicked, hide: !this.lmEnabled },
// { headerName: 'E-Mails', field: 'emailCampaignQuantity', width: 70, filter: 'agNumberColumnFilter', cellClass: marketingCellClass, onCellClicked: this.handleMarketingCellClicked, hide: !this.lmEnabled },
// { headerName: 'Owner Name', field: 'owner1FullName', width: 100, filter: 'agTextColumnFilter' },
// { headerName: 'Owner 2 Name', field: 'owner2FullName', width: 100, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Occupancy', field: 'vacant', width: 80, filter: null, valueFormatter: vacantFormatter, hide: true },
// { headerName: 'Owner Status', field: 'ownerOccupied', width: 80, filter: null, valueFormatter: ownerOccupiedFormatter, hide: true },
// { headerName: 'Owner Type', field: 'owner1Corporate', width: 80, filter: null, valueFormatter: ownerCorporateFormatter, hide: true },
// { headerName: 'Mailing Address', field: 'mailStreetAddress', width: 165, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Mailing City', field: 'mailCityName', width: 130, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Mailing State', field: 'mailStateCode', width: 55, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Mailing Zip', field: 'mailZip', width: 55, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Mailing County', field: 'mailCountyName', width: 100, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Do Not Mail', field: 'vacant', width: 80, filter: null, valueFormatter: yesNoFormatter, hide: true },
// { headerName: 'Property Class', field: 'propertyClass', width: 100, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Zoning', field: 'zoning', width: 100, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Lot Acres', field: 'lotAcres', width: 80, filter: 'agNumberColumnFilter', hide: true },
// { headerName: 'Lot SqFt', field: 'lotSquareFeet', width: 80, filter: 'agNumberColumnFilter', hide: true },
// { headerName: 'School District', field: 'schoolDistrict', width: 100, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Subdivision', field: 'subdivision', width: 100, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'Assessed Land Value', field: 'assessedLandValue', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter, hide: true },
// { headerName: 'Assessed Improvement Value', field: 'assessedImprovementValue', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter, hide: true },
// { headerName: 'Assessment Year', field: 'assessmentYear', width: 80, filter: 'agNumberColumnFilter', hide: true },
// { headerName: 'Delinquent Tax Year', field: 'delinquentTaxYear', width: 80, filter: 'agNumberColumnFilter', hide: true },
// { headerName: 'HOA 1 Name', field: 'hoa1Name', width: 100, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'HOA 1 Type', field: 'hoa1Type', width: 100, filter: 'agTextColumnFilter', hide: true },
// { headerName: 'HOA 1 Est Dues', field: 'hoa1Fee', width: 80, filter: 'agNumberColumnFilter', valueFormatter: currencyFormatter, hide: true },
// { headerName: 'Purchase Method', field: 'purchaseMethod', width: 100, filter: 'agTextColumnFilter', hide: true },
