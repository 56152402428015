import React, { createContext, useContext, useState, useEffect } from "react";
import { fieldTagsMap, ListingTypeOptions } from "./shared/ConstantsNew";

const FilterContext = createContext();

export const TagFilterProvider = ({ children }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredFields, setFilteredFields] = useState([]);
  const [navIndex, setNavIndex] = useState(1);

  const addTag = (tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags((prevTags) => [...prevTags, tag]);
    }
  };

  const countTagOccurrences = (tag) => {
    const fieldTagCount = Object.values(fieldTagsMap).flat().filter(t => t === tag).length;
    const listingTypeTagCount = ListingTypeOptions.flatMap(option => option.tag).filter(t => t === tag).length;
    return fieldTagCount + listingTypeTagCount;
  };

  const removeTag = (tag) => {
    setSelectedTags((prevTags) => prevTags.filter((t) => t !== tag));
  };

  const clearTags = () => {
    setSelectedTags([]);
  };

  const updateFilteredFields = () => {
    const fieldMatches = {};
    let index = 1;

    // Add ListingTypeOptions first
    ListingTypeOptions.forEach(option => {
      option.tag.forEach(tag => {
        if (selectedTags.includes(tag)) {
          fieldMatches[`${option.label}_lead`] = index++;
        }
      });
    });

    // Then add fieldTagsMap
    Object.keys(fieldTagsMap).forEach(field => {
      fieldTagsMap[field].forEach(tag => {
        if (selectedTags.includes(tag)) {
          fieldMatches[`${field}`] = index++;
        }
      });
    });

    setFilteredFields(fieldMatches);
  };

  useEffect(() => {
    updateFilteredFields();
  }, [selectedTags]);

  const navigateUp = () => {
    setNavIndex((prevIndex) => (prevIndex > 1 ? prevIndex - 1 : Object.keys(filteredFields).length));
  };

  const navigationReset = () => {
    setNavIndex(1);
  };

  const navigateDown = () => {
    setNavIndex((prevIndex) => (prevIndex < Object.keys(filteredFields).length ? prevIndex + 1 : 1));
  };

  return (
    <FilterContext.Provider value={{ selectedTags, addTag, removeTag, clearTags, countTagOccurrences, filteredFields, navIndex, setNavIndex, navigateUp, navigateDown, navigationReset }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
