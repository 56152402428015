import React from "react";
import PropTypes from 'prop-types';
import css from "./style.scss";

const ListKeyValue = (props) => {
  const { children } = props;
  return <div className={css.listKeyValue}>{children}</div>;
};

ListKeyValue.propTypes = {
  children: PropTypes.node,
};

export default ListKeyValue;
