/** @flow */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './styles.scss';


// type TCellProps = {|
//   children: *,
//   'data-value': *,
//   'data-row-index': number,
//   'data-index': number,
//   'className': string,
// |};

export const Cell = ({ children, className, ...rest }) => (
  <td {...rest} className={classNames(css.td, className)}>
    {children}
  </td>
);

Cell.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
};


export default Cell;
