import React from 'react';

import { Permissions } from 'data/user/constants';
import RestrictedContent from 'app/components/RestrictedContent';
import Button from 'components/Button';
import { withRouter, useLocation } from 'react-router-dom';

const AnalysisButton = ({ onRef, isLoading, location = { pathname: '/search' }, propertyId, match: {params: { id }} = {} }) => {

  const current_location = location.pathname
  const url = current_location === '/search'
    ? `${current_location}/${propertyId}/analysis/purchase`
    : `${propertyId}/analysis/purchase`;  

  return (
    <RestrictedContent permission={Permissions.analysisView}>
      <Button
        onRef={onRef}
        loading={isLoading}
        // url={`${propertyId}/analysis/purchase`}
        url={url}
      >Analysis</Button>
    </RestrictedContent>
  );
};

export default withRouter(AnalysisButton);
