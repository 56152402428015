/** @flow */

import { createSelector } from 'reselect';
import { Map, List } from 'immutable';
import { AnalysisFieldsTypes } from './constants';


const emptyList = List();
const emptyMap = Map({});

const checkState = state => state;

export const selectAnalyses = createSelector(
  checkState,
  state => state.getIn(['analyses']),
);

export const selectSavedAnalyses = createSelector(
  [selectAnalyses, (state, savedPropertyId) => savedPropertyId],
  (analyses, savedPropertyId) => analyses.get('analyses', emptyList)
    .filter(analysis => !savedPropertyId || analysis.get('savedPropertyId') === savedPropertyId)
    .sort((a, b) => a.get('name').localeCompare(b.get('name'))),
);

export const selectAnalysesOptions = createSelector(
  selectSavedAnalyses,
    analyses => analyses
    .map(analysis => Map({ value: analysis.get('id'), label: analysis.get('name') }))
    .unshift(Map({ value: '--New Analysis--', id: '' })),
);

export const selectIsLoading = createSelector(
  selectAnalyses,
  analyses => analyses.get('isLoading') || analyses.get('isSaving') || analyses.get('isDeleting')
    || analyses.get('isReportLoading'),
);

export const selectActiveAnalysisId = createSelector(
  selectAnalyses,
  analyses => analyses.getIn(['analysisParams', 'id']),
);

export const selectMortgageEditIndex = createSelector(
  selectAnalyses,
  analyses => analyses.getIn(['analysisParams', 'mortgageEditIndex'], 0),
);

export const selectAnalysisMortgages = createSelector(
  selectAnalyses,
  analyses => analyses.getIn(['analysisParams', 'mortgages'], emptyList).map(
    (mortgage, index) => mortgage.set('id', index),
  ),
);

export const selectAnalysisMortgageParams = createSelector(
  [selectAnalysisMortgages, selectMortgageEditIndex],
  (mortgages, mortgageEditIndex) => mortgages.get(mortgageEditIndex) || emptyMap,
);

export const selectDetailsValuesById = createSelector(
  [details => details, (details, id) => id],
  (details = emptyList, id) => details.find(item => item.getIn(['field', 'id']) === id, null, emptyMap),
);

export const selectAnalysisFields = createSelector(
  [selectAnalyses, (state, prop) => prop],
  (analyses, prop) => {
    const type = AnalysisFieldsTypes[prop];
    return analyses.get('analysisFields', emptyList).filter(field => field.get('type') === type)
      .sort((a, b) => {
        if (a.get('manual') !== b.get('manual')) return a.get('manual') ? 1 : -1;
        return a.get('name').localeCompare(b.get('name'));
      });
  },
);

function selectAnalysisFieldsDetails(state, prop, details = emptyList) {
  const analysisFields = selectAnalysisFields(state, prop);
  return analysisFields.map((field) => {
    const actualValues = selectDetailsValuesById(details, field.get('id'));
    const detail = Map({
      amount: actualValues.get('amount', 0),
      field,
    });

    return (prop === 'closingCosts') ? detail.set('paidBySeller', actualValues.get('paidBySeller', false)) : detail;
  });
}

export const selectRealAnalysisParams = createSelector(
  selectAnalyses,
  analyses => analyses.get('analysisParams', emptyMap),
);

export const selectAnalysisParams = createSelector(
  [checkState, selectRealAnalysisParams],
  (state, analysisParams) => {
    let params = analysisParams;
    const status = {};

    Object.keys(AnalysisFieldsTypes).forEach((type) => {
      // Populate all detail items for each detail type, and assign current amount
      params = params.update(type, items => selectAnalysisFieldsDetails(state, type, items));
      // Determine "detail status": If any details have an amount set other than the default/manual detail item.
      status[type] = params.get(type).find(detail => detail.get('amount') && !detail.getIn(['field', 'manual'])) != null;
    });

    // Determine rental unit detail status: If there is more than one room, or at least one room with custom info.
    const units = params.get('rentalUnits');
    status.rentalUnits = !!units && (units.size > 1 || units.find(
      unit => unit.get('name') !== '' || unit.get('quantity') !== 1 || unit.get('squareFeet') || unit.get('occupancyRate') !== 1 || unit.get('rentIncreaseRate') != null) != null);

    return params.set('detailStatus', Map(status));
  },
);

export const selectActiveProperty = createSelector(
  selectAnalyses,
  analyses => analyses.get('activeProperty', emptyMap),
);

export const selectActivePropertyId = createSelector(
  selectAnalyses,
  analyses => analyses.getIn(['activeProperty', 'id']),
);

export const selectIsActivePropertyLoading = createSelector(
  selectAnalyses,
  analyses => analyses.get('activePropertyLoading'),
);
