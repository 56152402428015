import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';

import { selectProfile, selectIsLoading, selectLoading } from 'data/user/selectors';
import { saveProfile, savePrepaidCredit, removeError, getPrepaidCredit } from 'data/user';

import { CcMonthOptions, CountryOptions, CountryMap, UsStateOptions, getCcYearOptions } from 'data/user/constants';

import Dropdown from 'components/base/Dropdown';
import Button, { SolidButton } from 'components/Button';
import Checkbox from 'components/base/Checkbox';
import Text, { Password } from 'components/Text';
import { inferCardType } from 'utils/payment';
import formatCurrency from 'utils/currency/numberToPrice';
import Confirm from 'app/components/Confirm';
import { CVV_PAYMENT_THRESHOLD } from 'data/user/constants';
import { validate, verify, verifyRequired, isValidEmail, isValidPhone, isValidCardNumber, isValidCardCode, isValidCardExpiration } from 'utils/validation';

import css from './style.scss';
import cssAccount from '../style.scss';


class AccountProfile extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handlePurchase = this.handlePurchase.bind(this);

    this.ref = {};
    this.loginSet = (props.profile.username || '').trim() !== '';

    this.state = { ...props.profile };
  }

  componentDidMount() {
    this.props.getPrepaidCredit();
  }

  handleChange(event) {
    const { name, value, checked } = event.target;
    const stateChange = {};

    stateChange[name] = ['newFeatureLabelEnabled', 'billingPaymentMethod', 'purchasePaymentMethod'].includes(name) ? checked : value;
    if (name === 'cardNumber') stateChange[name] = value.replace(/\D/g, '');
    else if (name === 'billingCountry') stateChange.billingState = '';
    else if (name === 'paymentMethodId') {
      const pm = this.props.profile.paymentMethods.find(pm => pm.id === Number(value)) || { billingCountry: 'US', cardExpMonth: 1, cardExpYear: new Date().getFullYear() };
      'cardNumber,cardCode,cardName,cardExpMonth,cardExpYear,billingStreetAddress,billingStreetAddress2,billingCity,billingState,billingZip,billingCountry,billingPaymentMethod,purchasePaymentMethod'.split(',').forEach((field) => {
        stateChange[field] = pm[field] || '';
      });
      stateChange.cardNumber = '';
      stateChange.cardNumberMasked = pm.cardNumber || '';
    }

    this.setState(stateChange);
  }

  handleRef(element) {
    if (element) {
      const el = element.tagName ? element : findDOMNode(element);
      this.ref[el.name] = el;
    }
  }

  getPrepayCardInfo() {
    const { cardNumber } = this.props.profile.paymentMethods.find(pm => pm.id === Number(this.state.prepayPaymentMethodId)) || {};

    return { ...inferCardType(cardNumber), cardNumber };
  }

  handlePurchase() {
    const r = this.ref;
    const { confirm, savePrepaidCredit } = this.props;
    const { prepayPaymentMethodId, prepayAgreed, prepayAmount, prepayCardCode } = this.state;
    const amount = Number(prepayAmount);
    const cardCodeRequired = amount >= CVV_PAYMENT_THRESHOLD;
    const { codePosition, codeLength, cardNumber } = this.getPrepayCardInfo();

    validate(() => {
      verifyRequired(r.firstName, prepayPaymentMethodId, 'Credit card is required.');
      verify(r.prepayAmount, !isNaN(amount), 'Please enter a valid amount.');
      verify(r.prepayAmount, amount >= 5, 'Amount must be at least $5.');
      verify(r.prepayAmount, amount < 10000, 'Amount must be less than $10,000.');
      verifyRequired(r.prepayCardCode, prepayCardCode, `Please enter the ${codeLength}-Digit Credit Card CVV number on the ${codePosition} of your card.`, cardCodeRequired);
      verify(r.prepayCardCode, !cardCodeRequired || isValidCardCode(prepayCardCode, cardNumber), 'Credit Card CVV is invalid.');
      verify(r.prepayAgreed, prepayAgreed, 'You must agree to the terms.');

      confirm({
        question: `Click OK to proceed with your purchase a ${formatCurrency(prepayAmount)} pre-paid credit.`,
        onOk: () => {
          savePrepaidCredit(prepayPaymentMethodId, prepayAmount, cardCodeRequired ? prepayCardCode : null, ({ response: { message } }) => {
            this.setState({ prepayMessage: message || 'Your pre-paid credit has been added successfully.', prepaySuccess: !message });
          });
        },
      });
    });
  }

  handleSave(section) {
    const r = this.ref;
    const {
      firstName, lastName, phone, phone2, emailFriendlyFrom, replyToEmail, username, password, cardNumber, cardCode, cardName, cardExpMonth, cardExpYear,
      billingStreetAddress, billingStreetAddress2, billingCity, billingState, billingZip, billingCountry, paymentMethodId,
      businessFirstName, businessLastName, businessEmail, businessPhone, businessZip,
    } = this.state;
    const { logo, logoUrl, saveProfile } = this.props;
    const pm = (this.props.profile.paymentMethods || []).find(pm => pm.id === Number(paymentMethodId)) || {};

    validate(() => {
      if (section === 'profile') {
        verifyRequired(r.firstName, firstName, 'First Name is required.');
        verifyRequired(r.lastName, lastName, 'Last Name is required.');

        // SSO accounts will not have a login set, so only require it for people who have a login currently set. Require password if new login is being set.
        if (this.loginSet) {
          verifyRequired(r.username, username, 'Email is required.');
          verify(r.username, isValidEmail(username), 'Email is invalid.');
        } else if ((username || '').trim() !== '') verifyRequired(r.password, password, 'Password is required.');

        // Ignore password if blank or all asterisks; existing password will be preserved.
        if (password !== '' && !/^\*+$/.test(password)) {
          verify(r.password, password.length >= 8, 'Password must be at least 8 characters.');
          verify(r.password, password.length <= 20, 'Password cannot exceed 20 characters.');
          verify(r.password, !/\s/.test(password), 'Password cannot contain any white space.');
          verify(r.password, /[A-Z]/.test(password), 'Password must contain at least one capital letter.');
          verify(r.password, /[^a-zA-Z0-9]/.test(password), 'Password must contain at least one symbol.');
        }

        verify(r.phone, phone.trim() === '' || isValidPhone(phone), 'Phone 1 is invalid.');
        verify(r.phone2, phone2.trim() === '' || isValidPhone(phone2), 'Phone 2 is invalid.');

        verifyRequired(r.businessFirstName, businessFirstName, 'Business First Name is required.');
        verifyRequired(r.businessLastName, businessLastName, 'Business Last Name is required.');
        verify(r.businessEmail, businessEmail.trim() === '' || isValidEmail(businessEmail), 'Business Email is invalid.');
        verify(r.businessPhone, businessPhone.trim() === '' || isValidPhone(businessPhone), 'Business Phone is invalid.');
        verify(r.businessZip, businessZip.trim() === '' || /^\d{5}(-?\d{4})?$/.test(businessZip), 'Business Zip is invalid.');

        // verify(r.emailFriendlyFrom, emailFriendlyFrom.trim() !== '', 'Email Friendly From is required. This name will appear as the sender name in your email campaigns.');
        verify(r.emailFriendlyFrom, !isValidEmail(emailFriendlyFrom), 'Email Friendly From must be an individual or business name, and not an email address.');
        // verify(r.replyToEmail, replyToEmail.trim() !== '', 'Reply To Email is required. This will be the address replied to in your email campaigns.');
        verify(r.replyToEmail, isValidEmail(replyToEmail), 'Reply To Email must be a valid email address.');
      } else if (section === 'billing') {
        const billingChanged = cardNumber !== pm.cardNumber || cardName !== pm.cardName || cardExpMonth !== pm.cardExpMonth
          || cardExpYear !== pm.cardExpYear || billingStreetAddress !== pm.billingStreetAddress || billingStreetAddress2 !== pm.billingStreetAddress2
          || billingCity !== pm.billingCity || billingState !== pm.billingState || billingZip !== pm.billingZip
          || billingCountry !== pm.billingCountry;
        const hasBillingInfo = `${cardNumber}${cardCode}${cardName}${billingStreetAddress}${billingStreetAddress2}${billingCity}${billingState}${billingZip}`.trim() !== '';

        // Only need to validate if billing info has changed. Do not allow and existing payment method to be removed.
        // Not checking if card code changed since that's only needed when a new CC number is entered, in which case
        // that will have been changed, triggering the checks. This will prevent checks when only code is changed.
        if (billingChanged && (hasBillingInfo || pm.id)) {
          if (!pm.id) {
            verifyRequired(r.cardNumber, cardNumber, 'Credit Card Number is required.');
            verify(r.cardNumber, isValidCardNumber(cardNumber), 'Credit Card Number is invalid.');
            verifyRequired(r.cardCode, cardCode, 'Credit Card CVV is required.');
            verify(r.cardCode, isValidCardCode(cardCode, cardNumber), 'Credit Card CVV is invalid.');
          }

          verifyRequired(r.cardName, cardName, 'Name on Card is required.');
          verifyRequired(r.cardExpMonth, cardExpMonth, 'Expiration Month is required.');
          verifyRequired(r.cardExpYear, cardExpYear, 'Expiration Year is required.');

          const country = CountryMap[billingCountry];
          verify(r.cardExpMonth, isValidCardExpiration(cardExpYear, cardExpMonth), 'Credit card is expired.');
          verifyRequired(r.billingStreetAddress, billingStreetAddress, 'Billing Street Address is required.');
          verifyRequired(r.billingCity, billingCity, 'Billing City is required.');
          verifyRequired(r.billingState, billingState, `Billing ${country.stateLabel} is required.`);
          verifyRequired(r.billingZip, billingZip, `Billing ${country.postalCodeLabel} is required.`);
          verify(r.billingZip, !country.postalCodePattern || new RegExp(country.postalCodePattern, 'i').test(billingZip), `${country.postalCodeLabel} is invalid.`);
        } else return;
      }

      const formData = new FormData();
      const data = { ...this.state, logo, logoUrl };

      Object.keys(data).forEach((property) => {
        formData.append(property, data[property]);
      });

      saveProfile(formData);
    });
  }

  render() {
    // ID23-133 we had to get remainPrepaidCredits from props instad of profile
    // so that the reducer can update the value.
    const { profile, history, loading, remainingPrepaidCredit } = this.props;
    const { paymentMethods } = profile;
    const {
      cardExpMonth,
      cardExpYear,
      cardNumber,
      cardNumberMasked,
      paymentMethodId,
      billingState,
      billingCountry,
      newFeatureLabelEnabled,
      businessState,
      billingPaymentMethod,
      purchasePaymentMethod,
      prepayPaymentMethodId,
      prepayAgreed,
      prepayAmount,
      prepayMessage,
      prepaySuccess,
    } = this.state;

    // Get country state list. If no country is selected, default to US.
    const country = CountryMap[billingCountry] || CountryMap.US;
    const { states, stateLabel, postalCodeLabel } = country;
    const stateOptions = (states || []).map(state => ({ label: state.name, value: state.code }));
    if (stateOptions.length) stateOptions.unshift({ value: '' });

    const paymentMethod = (paymentMethods || []).find(pm => pm.id === Number(paymentMethodId));
    const newPaymentMethod = !paymentMethod;

    const pmOptions = (paymentMethods || []).map(({ id, description }) => ({ label: description, value: id }));
    const paymentMethodOptions = [{ label: 'New Credit Card', value: '' }].concat(pmOptions);
    const paymentMethodOptions2 = [{ label: 'Select Credit Card', value: '' }].concat(pmOptions);

    const { numberMask, codeMask } = inferCardType(cardNumber || cardNumberMasked);
    const { codeMask: prepayCodeMask } = this.getPrepayCardInfo();

    const inputProps = { onInputRef: this.handleRef, onChange: this.handleChange, value: this.state };
    const dropdownProps = { onInputRef: this.handleRef, onChange: this.handleChange };
    const amt = Number(prepayAmount);

    return (
      <div className={css.profile}>
        <div className={cssAccount.left}>
          <div className={cssAccount.header}>Member Profile</div>
          <div className={cssAccount.fields}>
            <Text {...inputProps} name="firstName" label="First Name" readOnly />
            <Text {...inputProps} name="lastName" label="Last Name" readOnly />
            <Text {...inputProps} name="username" label="Email / Username" />
            <Password {...inputProps} name="password" label="Password" />
            <Text {...inputProps} name="phone" label="Phone 1" />
            <Text {...inputProps} name="phone2" label="Phone 2" />
          </div>
          <div className={cssAccount.header}>Business Details (Used For Marketing)</div>
          <div className={cssAccount.fields}>
            <Text {...inputProps} name="businessFirstName" label="First Name" />
            <Text {...inputProps} name="businessLastName" label="Last Name" />
            <Text {...inputProps} name="businessName" label="Business Name" />
            <Text {...inputProps} name="businessPhone" label="Business Phone" />
            <Text {...inputProps} name="businessUrl" label="Business Website / Landing Page" />
            <Text {...inputProps} name="businessEmail" label="Business Email" />
            <Text {...inputProps} name="businessStreetAddress" label="Business Address" />
            <Text {...inputProps} name="businessStreetAddress2" label="Business Address 2" />
            <Text {...inputProps} name="businessCity" label="Business City" />
            <div className={cssAccount.stateZip}>
              <div className={cssAccount.field}>
                <label htmlFor="businessState">Business State</label>
                <Dropdown {...dropdownProps} name="businessState" options={UsStateOptions} value={businessState} />
              </div>
              <Text {...inputProps} name="businessZip" label="Business Zip" />
            </div>
            <Text {...inputProps} name="emailFriendlyFrom" label="Email Friendly From" />
            <Text {...inputProps} name="replyToEmail" label="Reply To Email" />
          </div>
          <div className={css.buttons}>
            <Checkbox label="Show 'New' label next to new features." name="newFeatureLabelEnabled" checked={newFeatureLabelEnabled} onChange={this.handleChange} />
            <div>
              <Button onClick={() => history.goBack()}>Close</Button>
              <SolidButton onClick={() => this.handleSave('profile')}>Update Profile</SolidButton>
            </div>
          </div>
        </div>
        <div className={cssAccount.right}>
          <div className={cssAccount.header}>Billing Information</div>
          <div className={cssAccount.fields}>
            <div className={cssAccount.field}>
              <label htmlFor="paymentMethod">Select Credit Card</label>
              <Dropdown {...dropdownProps} name="paymentMethodId" options={paymentMethodOptions} value={paymentMethodId} />
            </div>
            <Text {...inputProps} name="cardName" label="Name on Card" />
            <Text {...inputProps} name="cardNumber" label="Card Number" mask={numberMask} placeholder={cardNumberMasked} value={newPaymentMethod ? cardNumber : ''} readOnly={!newPaymentMethod} />
            <div className={cssAccount.cardExp}>
              <div className={cssAccount.field}>
                <label htmlFor="cardExpMonth">Expiration Date</label>
                <Dropdown {...dropdownProps} name="cardExpMonth" options={CcMonthOptions} value={cardExpMonth} />
              </div>
              <div className={cssAccount.field}>
                <label htmlFor="cardExpYear">&nbsp;</label>
                <Dropdown {...dropdownProps} name="cardExpYear" options={getCcYearOptions(cardExpYear)} value={cardExpYear} />
              </div>
              <Text {...inputProps} name="cardCode" label="CVV" mask={codeMask} />
            </div>
            <Text {...inputProps} name="billingStreetAddress" label="Address" />
            <Text {...inputProps} name="billingStreetAddress2" label="Address 2" />
            <Text {...inputProps} name="billingCity" label="City" />
            <div className={cssAccount.stateZip}>
              {!stateOptions.length ? <Text {...inputProps} name="billingState" label="State" /> : (
                <div className={cssAccount.field}>
                  <label htmlFor="billingState">{stateLabel}</label>
                  <Dropdown {...dropdownProps} name="billingState" options={stateOptions} value={billingState} />
                </div>
              )}
              <Text {...inputProps} name="billingZip" label={postalCodeLabel} />
            </div>
            <div className={cssAccount.field}>
              <label htmlFor="billingState">Country</label>
              <Dropdown {...dropdownProps} name="billingCountry" options={CountryOptions} value={country.code} />
            </div>
          </div>
          <div className={cssAccount.bottom}>
            <div className={cssAccount.preferences}>
              <Checkbox label="Use this card for my subscription billing." name="billingPaymentMethod" checked={billingPaymentMethod} onChange={this.handleChange} disabled={paymentMethod && paymentMethod.billingPaymentMethod} />
              <Checkbox label="Use this card for my in-app purchases." name="purchasePaymentMethod" checked={purchasePaymentMethod} onChange={this.handleChange} disabled={paymentMethod && paymentMethod.purchasePaymentMethod} />
            </div>
            <SolidButton onClick={() => this.handleSave('billing')}>Update Billing</SolidButton>
          </div>
          <div className={cssAccount.sep} />
          <div className={cssAccount.between}>
            <div className={cssAccount.header}>Pre-Paid Credits (Web &amp; Mobile)</div>
            <div className={cssAccount.remainingCredits}>Remaining Credits: {formatCurrency(remainingPrepaidCredit)}</div>
          </div>
          {!prepayMessage ? null : <div className={prepaySuccess ? cssAccount.success : cssAccount.error}>{prepayMessage}</div>}
          <div className={cssAccount.fields}>
            <div className={cssAccount.field}>
              <label htmlFor="paymentMethod">Select Credit Card</label>
              <Dropdown {...dropdownProps} name="prepayPaymentMethodId" options={paymentMethodOptions2} value={prepayPaymentMethodId} />
            </div>
            <div className={cssAccount.stateZip}>
              <Text {...inputProps} name="prepayAmount" label="Amount" />
              {amt >= CVV_PAYMENT_THRESHOLD ? <Text {...inputProps} name="prepayCardCode" label="CVV" mask={prepayCodeMask} /> : <div />}
            </div>
          </div>
          <Checkbox label={`I acknowledge that by clicking Purchase and adding Pre-Paid Credits, I authorize the non-refundable amount${isNaN(amt) || amt < 5 || amt >= 10000 ? '' : ` of ${formatCurrency(amt)}`} to be charged to the credit or debit card specified above. Pre-Paid Credits may only be used towards in-app purchases, and may not be used towards any monthly subscription dues or past due balances. Pre-Paid Credits are non-refundable and non-transferable.`} name="prepayAgreed" checked={prepayAgreed} onChange={this.handleChange} style={{ marginTop: '20px' }} />
          <div className={classNames(css.buttons, css.right)}>
            <SolidButton onClick={this.handlePurchase} loading={loading}>Purchase</SolidButton>
          </div>
        </div>
      </div>
    );
  }
}
// first parameter is function erpresenting mapStateToProps,
// second parameter is object representing mapDispatchToProps
// ID23-133 we had to add remainPrepaidCredits instead of using the value from profile
// so that we can update it in the reducer function
export default connect(state => ({
  profile: selectProfile(state).toJS(),
  isLoading: selectIsLoading(state),
  loading: selectLoading(state),
  remainingPrepaidCredit: state.getIn(['user','profile', 'remainingPrepaidCredit'])
}), {
  saveProfile,
  savePrepaidCredit,
  getPrepaidCredit,
  removeError,
  confirm: Confirm.open,
})(AccountProfile);
