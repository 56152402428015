import React, { PureComponent } from 'react';
import classNames from 'classnames';
import Dropdown from 'components/base/Dropdown';
import SVG from 'components/base/SVG';

import css from './style.scss';
import PropTypes from 'prop-types';


const DncOptions = [
  { label: '', value: null },
  { label: 'DNC', value: 'D' },
  { label: 'PAB', value: 'A' },
  { label: 'DNC / PAB', value: 'DA' },
];

class ContactItems extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
  }

  setItems(newItems) {
    const { field, onChange } = this.props;
    onChange({ name: `${field}s`, value: newItems });
  }

  handleAdd() {
    const { items, types, field } = this.props;
    const newItems = items && items?.length ? items?.slice() : [];
    const seq = items?.reduce((seq, i) => Math.max(seq, i.seq), -1) + 1;

    newItems.unshift({ type: types[0].value, [field]: '', seq });

    this.setItems(newItems);
  }

  handleRemove(seq) {
    const items = this.props.items?.length ? this.props.items?.slice() : [];
    items.splice(seq, 1);

    this.setItems(items);
  }

  handleChange(item, seq, ev) {
    const { name, value } = ev.target || ev;
    const items = this.props.items?.length ? this.props.items?.slice() : [];
    items[seq] = { ...items[seq], [name]: value };

    if (ev.stopPropagation) ev.stopPropagation();

    this.setItems(items);
  }

  render() {
    const { items, types, field, title, pabEnabled } = this.props;

    return (
      <div className={css.items}>
        <div className={css.itemsHeader}>
          <div className={css.itemsTitle}>{title}{items?.length ? ` (${items?.length})` : ''}</div>
          <SVG icon="iconRoundAdd" onClick={this.handleAdd} className={css.icon} />
        </div>
        {items?.map((i, seq) => (
          <div className={classNames(css.item, css[field])} key={i.seq}>
            <Dropdown
              name="type"
              value={i.type}
              onChange={ev => this.handleChange(i, seq, ev)}
              options={types}
              className={css.type}
            />
            <div className={css.inputContainer}>
              <input type="text" name={field} value={i[field]} onChange={ev => this.handleChange(i, seq, ev)} className={css.input} />
            </div>
            {field !== 'phone' ? null : (
              <Dropdown
                name="dncPab"
                value={i.dncPab}
                onChange={ev => this.handleChange(i, seq, ev)}
                options={DncOptions.filter(o => pabEnabled || !o.value || !o.value.includes('A'))}
                className={css.dnc}
              />
            )}
            <SVG icon="iconRoundRemove" onClick={() => this.handleRemove(seq)} className={css.icon} />
          </div>
        ))}
      </div>
    );
  }
}


ContactItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  pabEnabled: PropTypes.bool,
};

export default ContactItems;
