import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isValidEmail } from 'utils/validation';
import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';
import Confirm from 'app/components/Confirm';
import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';
import FormControlWrapper from 'components/base/FormControlWraper';

import css from './style.scss';


class Prompt extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    this.ref = null;

    this.state = {
      checkOptions: (props.checkOptions || []).map(({ id, label, checked }) => ({ id, label, checked: !!checked })),
    };
  }

  componentDidMount() {
    this.ref.focus();
    this.ref.select();
  }

  handleCheck(ev) {
    const { name, checked } = ev.target || ev;

    this.setState({ checkOptions: this.state.checkOptions.map(o => ({ ...o, checked: (String(o.id) === String(name) && checked) || o.checked })) });
  }

  handleSubmit(ev) {
    const { closePopup, alert, email, onSubmit } = this.props;
    const { checkOptions } = this.state;
    const value = ev.target.value.value.trim();

    ev.preventDefault();

    let message;
    if (value === '') message = `Please enter ${email ? 'an email address' : 'a value'}.`;
    else if (email && !isValidEmail(value)) message = 'Email address is invalid.';

    if (message) alert(message);
    else {
      onSubmit(value, { checkOptions });
      closePopup();
    }
  }

  handleRef(ref) {
    this.ref = ref;
  }

  render() {
    const { closePopup, label, email, title, text, value } = this.props;
    const { checkOptions } = this.state;

    return (
      <Modal isOpen uniqId="prompt" padding="25px 10px" caption={title} width="350px" onClose={closePopup}>
        <form onSubmit={this.handleSubmit} className={css.body}>
          {!text ? null : <div className={css.text}>{text}</div>}
          <FormControlWrapper label={`${label || (email && 'Email') || 'Value'}:`} hasMarginBottom>
            <input name="value" type="text" defaultValue={value} ref={this.handleRef} />
          </FormControlWrapper>
          {!checkOptions.length ? null : (
            <div className={css.checks}>
              <div>
                {checkOptions.map(o => <Checkbox label={o.label} name={o.id} key={o.id} checked={o.checked} defaultChecked={false} onClick={this.handleCheck} />)}
              </div>
            </div>
          )}
          <div className={css.buttonContainer}>
            <Button kind={Button.kind.ghost} onClick={closePopup}>Cancel</Button>
            <Button kind={Button.kind.blue} type="submit">Submit</Button>
          </div>
        </form>
      </Modal>
    );
  }
}


Prompt.propType = {
  text: PropTypes.string,
  label: PropTypes.string,
  email: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  onSubmit: PropTypes.func,
  checkOptions: PropTypes.array,
};

Prompt.defaultProps = {
  email: false,
  title: 'Input Required',
  value: '',
  checkOptions: [],
};

const PromptPopup = connect(null, { alert: Confirm.alert })(Prompt);

const registrationId = getPopupRegistration(PromptPopup);
PromptPopup.open = props => openPopup(registrationId, { priority: Priority.HIGH, ...props });
PromptPopup.close = props => closePopup(registrationId, props);

export default PromptPopup;
