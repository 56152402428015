/*
 * If has not some API's you need, please check react-bootstrap-StickyTable package.
 * If there is API you need, please ask Konstantin Petryaev about update this component.
 */
import React, { PureComponent, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { debounce } from 'underscore';
import * as Table from 'reactabular-table';
import * as Sticky from 'reactabular-sticky';
import classNames from 'classnames';

import Column from './Column';


import css from './styles.scss';


const renderSimple = val => val;

const renderNothing = () => null;

const noExtraProps = {};
Object.freeze(noExtraProps);

export {
  Column,
};

export default class StickyTable extends PureComponent {
  static getHeaderRowProps() {
    return {
      className: css.headTr,
    };
  }

  /* :: state: { expandedRow: ?number } */
  /* :: thead: ?HTMLElement */
  /* :: tbody: ?HTMLElement */
  constructor(props) {
    super(props);
    this.state = {
      expandedRow: null,
    };
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.isExpandedRow = this.isExpandedRow.bind(this);
    this.isActiveRow = this.isActiveRow.bind(this);
    this.handleHeaderRef = this.handleHeaderRef.bind(this);
    this.handleBodyRef = this.handleBodyRef.bind(this);
    this.isActiveRow = this.isActiveRow.bind(this);
    this.redraw = debounce(this.redraw.bind(this), 26, true);
  }

  componentDidMount() {
    // We have refs now. Force update to get those to Header/Body.
    window.addEventListener('resize', this.redraw);
    this.interval = setInterval(() => {
      this.redraw();
    }, 16);
    this.redraw();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearTimeout(this.timeout);
    window.removeEventListener('resize', this.redraw);
  }

  getSizes() {
    const max = this.tbody.parentNode.getBoundingClientRect().width;
    if (this.max !== max) {
      const multiplier = [];
      const bodyRow = this.tbody.children[0].children;
      Array.from(this.thead.children[0].children).forEach((child, i) => {
        multiplier[i] = ((bodyRow[i].getBoundingClientRect().width * 3) + child.getBoundingClientRect().width) / 4;
      });
      const summ = multiplier.reduce((a, b) => (a + b), 0);
      const percent = max / summ;
      this.sizes = multiplier.map(v => percent * v);
      this.max = max;
    }
  }

  getColumnWidth(index) {
    if (!this.sizes) {
      return 'auto';
    }
    if (!this.sizes[index]) return 'auto';
    return this.sizes[index];
  }

  getColumns() {
    const meta = [];
    const columns = [];
    let keyField = null;

    const allKeys = new Set();
    const { data, isSortable, sortFields } = this.props;

    Children.toArray(this.props.children).forEach((child, index, full) => {
      const isLast = index + 1 === full;
      if (child) {
        switch (child.type) {
          case Column: {
            const {
              props: {
                field,
                fieldName,
                renderCell = renderSimple,
                renderTooltip = renderNothing,
                defaultValue = null,
                isKey = false,
                row,
                extraProps = noExtraProps,
                addIndex = false,
                style,
                sortIcon,
                ...rest
              },
            } = child;

            const columnMeta = {
              field,
              ...rest,
            };

            if (row === 0) {
              meta.push({
                field,
                renderCell,
                renderTooltip,
                defaultValue,
                extraProps,
                addIndex,
              });

              if (isKey) keyField = field;
            }

            let key = field;
            while (allKeys.has(key)) key += '%';
            allKeys.add(key);

            const width = this.getColumnWidth(index);
            const styleIn = { ...style, maxWidth: width, minWidth: width, width, overflow: 'hidden' };
            const sortIconContainer = (fieldName && isSortable && sortFields.indexOf(fieldName) !== -1)
              ? <div className={css.caretIcon}>{sortIcon}</div> : null;

            return columns.push({
              property: field,
              header: {
                label: field,
                props: {
                  className: css.th,
                  style: styleIn,
                  onClick: this.handleColumnSort(isSortable, fieldName),
                },
                formatters: [() => {
                  const { children } = rest;
                  if (!children) {
                    return null;
                  } else if (typeof children === 'string') {
                    return (<div className={css.headerContent}>
                      <div>{children}</div>
                      {sortIconContainer}
                    </div>);
                  }
                  return cloneElement(children, { meta: columnMeta });
                }],
              },
              cell: {
                props: {
                  className: css.td,
                  style: isLast ? {} : styleIn,
                },
                formatters: [
                  (_, { rowIndex, columnIndex, property }) => {
                    const row = data.get(rowIndex);

                    return renderCell(
                      row.get(property, defaultValue),
                      {
                        row: rowIndex,
                        column: columnIndex,
                      },
                      row,
                      renderTooltip,
                      extraProps);
                  },
                ],
              },
            });
          }
          default:
        }
      }
      return null;
    });

    return { columns, meta, keyField };
  }

  getMaxWidth() {
    if (!this.sizes) return '100%';
    return this.sizes.reduce((a, b) => a + b, 0);
  }

  /* :: handleRowClick: Function */
  handleRowClick(event, data, rowIndex) {
    if (typeof this.props.onRowClick === 'function') {
      this.props.onRowClick(event, data, rowIndex);
    }

    if (!this.props.expandableRow(rowIndex) || this.isExpandedRow(rowIndex)) {
      this.setState({ expandedRow: null });
    } else {
      this.setState({ expandedRow: rowIndex });
    }
  }


  /* :: handleColumnSort: Function */
  handleColumnSort(isSortable, columnName) {
    if (!isSortable || !columnName) {
      return () => {};
    }
    return () => {
      if (typeof this.props.onColumnSort === 'function') {
        this.props.onColumnSort(columnName);
      }
    };
  }

  /* :: isExpandedRow: Function */
  isExpandedRow(rowIndex) {
    return (this.state.expandedRow) === rowIndex;
  }

  /* :: isActiveRow: Function */
  isActiveRow(rowIndex) {
    return (this.props.activeRow) === rowIndex;
  }

  handleHeaderRef(ref) {
    this.thead = ref && ref.getRef();
  }

  handleBodyRef(ref) {
    this.body = ref;
    this.tbody = ref && ref.getRef();
  }

  redraw() {
    if (this.thead) {
      const max = this.tbody.parentNode.getBoundingClientRect().width;
      if (this.max !== max) {
        this.getSizes();
        this.forceUpdate();
        this.timeout = setTimeout(() => {
          this.max = null;
          this.getSizes();
          this.forceUpdate();
        }, 16);
      }
    }
  }

  render() {
    // TODO: replace className property into type property with moving classNames into some clearly values
    const {
      data,
      className,
      isHoverable,
      maxHeight,
      maxHeightOffset,
      keyField: keyFromProps,
      activeRowClassName,
    } = this.props;
    const { columns, keyField } = this.getColumns();

    const rootClasses = classNames(
      css.sticky,
      css.table,
      css[className],
      {
        [css.isHoverable]: isHoverable,
        [css.flexed]: this.thead,
      });


    return (
      <Table.Provider
        className={rootClasses}
        columns={columns}
      >
        <Sticky.Header
          ref={this.handleHeaderRef}
          tableBody={this.tbody}
          style={{ maxWidth: this.getMaxWidth() }}
          onRow={StickyTable.getHeaderRowProps}
        />

        <Sticky.Body
          rows={data.toJS()}
          rowKey={keyFromProps || keyField}
          style={{
            maxWidth: this.getMaxWidth(),
            maxHeight: maxHeightOffset ? window.innerHeight - maxHeightOffset : maxHeight,
          }}
          ref={this.handleBodyRef}
          tableHeader={this.thead}
          onRow={(row, { rowIndex }) => ({
            onClick: event => this.handleRowClick(event, data.get(rowIndex), rowIndex),
            className: classNames(
              css.tr,
              {
                [css.expander]: this.isExpandedRow(rowIndex),
                [activeRowClassName]: this.isActiveRow(rowIndex),
              },
            ),
          })}
        />
      </Table.Provider>
    );
  }
}

StickyTable.defaultProps = {
  data: List(),
  className: 'type_1',
  isHoverable: false,
  expandableRow: () => false,
  sortFields: List(),
};

StickyTable.propTypes = {
  data: PropTypes.instanceOf(List).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOf(['type_1', 'type_2', 'type_3', 'type_4', 'type_5', 'type_6', 'type_7', 'type_8', 'type_9', 'type_10', 'type_11']),
  maxHeight: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  maxHeightOffset: PropTypes.number,
  isHoverable: PropTypes.bool,
  expandableRow: PropTypes.func,
  isSortable: PropTypes.bool,
  sortFields: PropTypes.instanceOf(List),
  // expandComponent: PropTypes.oneOfType([
  //   PropTypes.func,
  //   PropTypes.instanceOf(Component),
  // ]),
};

