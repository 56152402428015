import React from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';
import numberToPrice from 'utils/currency/numberToPrice';
import formatPercent from 'utils/percent/formatPercent';
import { formatYearMonth } from 'utils/number/format';

import Button from 'components/base/Button';
import Accordion from 'components/base/Accordion';
import CurrencyInput from '../CurrencyInput';
import DetailsInput from '../DetailsInput';
import css from '../style.scss';


const fieldSets = [{
  seq: 0,
  fields: [
    { label: 'Amount', field: 'amount', format: value => numberToPrice(value) },
    { label: 'Down Payment', field: 'downPaymentAmount', format: value => numberToPrice(value) },
    { label: 'Rate', field: '', format: value => formatPercent(100 * value.getMortgageRate()) },
    { label: 'Monthly Payment', field: '', format: value => numberToPrice(value.getMortgageMonthlyPayment()) },
  ],
},
{
  seq: 1,
  fields: [
  { label: 'Term', field: '', format: value => formatYearMonth(value.getMortgageTerm()) },
  { label: 'Effective Term', field: '', format: value => formatYearMonth(value.getEffectiveTerm()) },
  { label: 'Total Interest', field: '', format: value => numberToPrice(value.getInterestTotal()) },
  { label: 'Total Paid', field: '', format: value => numberToPrice(value.getPaymentTotal()) },
  ],
}];


const Mortgage = (props) => {
  const {
    analysisParams: a,
    analysisMortgages,
    onManualTotalChange,
    onDetailsChange,
    onRemoveMortgageClick,
    onAmortizeClick,
    onEditMortgageClick,
    addAnalysisMortgage,
    showAddMortgageButton,
  } = props;

  return (
    <div className={classNames(css.results, css.root)}>
      {
        analysisMortgages.map(mortgage => (
          <div className={css.resultsContainer} key={mortgage.seq}>
            <div className={css.resultsHeader}>
              <div className={css.resultsCaption}>{mortgage.ordinal} Mortgage</div>
              <div className={css.resultsControls}>
                <div className={css.resultsControl} onClick={() => onAmortizeClick(mortgage)}>Amortize</div>
                <div className={css.resultsControl} onClick={() => onEditMortgageClick(mortgage.seq)}>Edit</div>
                <div className={css.resultsControl} onClick={() => onRemoveMortgageClick(mortgage.seq)}>Remove</div>
              </div>
            </div>
            <div className={css.resultsBody}>
              {
                fieldSets.map(set => (
                  <div className={css.row} key={set.seq}>
                    {
                      set.fields.map(field => (
                        <div className={css.col_3} key={field.label}>
                          <div className={css.resultsItem}>
                            <span className={css.resultsColor}>{field.label}: </span>
                            {field.field ? field.format(mortgage[field.field]) : field.format(mortgage)}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
      <div className={css.resultsButtons}>
        {!showAddMortgageButton ? null : (
          <Button kind="border-grey" size="large" className={css.resultsButton} onClick={addAnalysisMortgage}>
            Add {analysisMortgages.length ? 'Second' : 'First'} Mortgage
          </Button>)}
      </div>
      <Accordion caption="Closing Costs" isOpened isLarge>
        <CurrencyInput label="Closing Costs" name="closingCostTotal" value={a} onChange={event => onManualTotalChange(event, 'closingCosts')} />
        <Accordion caption="Detail Breakdown" isOpened={a.detailStatus && a.detailStatus.closingCosts} isArrowRight>
          <div className={css.inlinesContainer}>
            {a.closingCosts.map(closingCost => (
              <DetailsInput className={css.inline} prop="closingCosts" detail={closingCost} key={closingCost.field.id} onDetailsChange={onDetailsChange} />
            ))}
          </div>
        </Accordion>
      </Accordion>
    </div>
  );
};

export default pure(Mortgage);
