import React, { useRef, useEffect } from 'react';
import { findDOMNode } from 'react-dom';
import DatePicker from 'react-datepicker';
import formatDate from 'utils/date/formatDate';
import * as onOuterClick from 'utils/DOM/onOuterClick';

import SVG from 'components/base/SVG';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import css from './style.scss';
import moment from 'moment';

const DEFAULT_FORMAT = 'MMM dd, yyyy';

const InputDate = ({ name, value, placeholder, dateFormat = DEFAULT_FORMAT, onChange, noIcon }) => {
  const pickerRef = useRef(null);
  const domRef = useRef(null);

  useEffect(() => {
    const handleClick = () => {
      if (!pickerRef.current) return;
      if (!pickerRef.current.refs) return;
      if (!pickerRef.current.refs.calendar) return;
      domRef.current = findDOMNode(pickerRef.current.refs.calendar);
      onOuterClick.addCatcher(domRef.current);
    };

    const cleanup = () => {
      if (domRef.current) {
        onOuterClick.removeCatcher(domRef.current);
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
      cleanup();
    };
  }, []);

  const handleChange = (date, event) => {
    if (onChange) {
      onChange({ id: name, name, value: date }, event);
    }
  };

  const renderIcon = () => {
    if (noIcon) return null;
    return <SVG icon="iconCalendar" className={css.icon} />;
  };

  const formattedValue = value ? (moment.isMoment(value) ? value?.toDate() : value) : null;

  return (
    <div className={css.root}>
      <DatePicker
        ref={pickerRef}
        onChange={handleChange}
        name={name}
        placeholder={placeholder}
        selected={formattedValue}
        dateFormat={dateFormat}
        autoComplete="off"
        utcOffset={0}
      />
      {renderIcon()}
    </div>
  );
};

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  noIcon: PropTypes.bool,
  dateFormat: PropTypes.string,
};

InputDate.defaultProps = {
  dateFormat: DEFAULT_FORMAT,
};

export default InputDate;
