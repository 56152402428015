import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './style.scss';


export const handleFileChange = (file, name, onChange, document) => {
  if (file && onChange) {
    if (!document) onChange({ name, value: { file } });
    else {
      const fileReader = new FileReader();
      const { name: fileName, type: mimeType } = file;

      fileReader.onload = (ev) => {
        const props = {};
        const dataUrl = ev.target.result;
        const change = () => onChange({ name, value: { file, document: { dataUrl, fileName, mimeType, ...props } } });

        if (!mimeType.toLowerCase().startsWith('image/')) change();
        else {
          const img = window.document.createElement('img');
          img.onload = () => {
            const { width, height } = img;
            Object.assign(props, { width, height });
            change();
          };
          img.src = dataUrl;
        }
      };

      fileReader.readAsDataURL(file);
    }
  }
};

function preventDefault(ev) {
  ev.preventDefault();
  ev.stopPropagation();
}

class FileReceiver extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { dragging: false };
  }

  handleDrop(ev) {
    preventDefault(ev);

    const { name, document, onChange } = this.props;
    handleFileChange(ev.dataTransfer.files && ev.dataTransfer.files[0], name, onChange, document);
  }

  handleDragEnter(ev, enter) {
    preventDefault(ev);

    this.setState({ dragging: enter });
  }

  render() {
    const { onChange, children } = this.props;

    return (
      <div
        className={classNames({ [css.noDrop]: !onChange && this.state.dragging })}
        onDrop={ev => this.handleDrop(ev)}
        onDrag={preventDefault}
        onDragStart={preventDefault}
        onDragOver={preventDefault}
        onDragEnter={ev => this.handleDragEnter(ev, true)}
        onDragLeave={ev => this.handleDragEnter(ev, false)}
      >
        {children}
      </div>
    );
  }
}

FileReceiver.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  document: PropTypes.bool,
  onChange: PropTypes.func,
};

FileReceiver.defaultProps = {
  document: true,
};

export default FileReceiver;
