import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { imageFormatList } from 'config/constants/fileFormats';
import { Button, ButtonUpload } from 'components/base/Button';
import FileReceiver from 'components/base/FileReceiver';
import { createConfirmable } from 'app/components/Confirm';
import { formatDocumentUrl } from 'utils/URL';

import css from './style.scss';


class ContentTile extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleChangeImage({ value: { document: image } }) {
    const { onChange, element } = this.props;
    onChange({ image }, element);
  }

  handleClick() {
    const { element, onSelectElement } = this.props;
    if (element && onSelectElement) onSelectElement(element);
  }

  render() {
    const { title, element, selectedElement, className } = this.props;
    const { image } = element || {};
    const btn = { kind: Button.kind.grayGhost, size: Button.size.small, className: css.btn };
    const empty = !image;

    const style = {};

    return (
      <div className={classNames(css.contentTile, className, { [css.selected]: selectedElement === element })} onClick={this.handleClick}>
        <div className={css.title}>{title}</div>
        <FileReceiver onChange={this.handleChangeImage}>
          <div className={classNames(css.tile, { [css.empty]: empty })} style={style}>
            {image ? <img src={formatDocumentUrl(image)} alt="" /> : (
              <div className={css.buttons}>
                <div>Drag File Here</div>
                <ButtonUpload {...btn} accept={imageFormatList} onChange={this.handleChangeImage}>Browse</ButtonUpload>
              </div>
            )}
          </div>
        </FileReceiver>
      </div>
    );
  }
}

export default createConfirmable(connect()(ContentTile));
