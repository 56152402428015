
export const dragStartWrapper = handler => (ev) => {
  // Required on FireFox to initiate dragging.
  ev.dataTransfer.setData('text', 'anything');

  handler(ev);
};

export const dropWrapper = handler => (ev) => {
  // Required on FireFox prevent redirection on drop.
  ev.preventDefault();

  handler(ev);
};
