/** @flow */
import Chart from 'components/Chart';
import { SearchNames } from 'data/search';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Button from 'components/base/Button';
import Image from 'components/base/Image';
import { preventPropagationStop } from 'components/hoc/propagation';
import { getPropertyImageUrl } from 'data/property';
import { getAerialImageUrl } from 'utils/brand';
import numberToPrice from 'utils/currency/numberToPrice';
import formatDate from 'utils/date/formatDate';
import numberFormat from 'utils/number/format';

import css from './style.scss';

const fields = {
  listPrice: { name: 'listingAmount', label: 'List Price', format: value => numberToPrice(value, 'N/A') },
  listingDate: { name: 'listingDate', label: 'Listing Date', format: value => formatDate(value, 'N/A') },
  status: { name: 'status', label: 'Status', format: value => value },
  documentType: { name: 'documentType', label: 'Document Type', format: value => value },
  defaultAmount: { name: 'defaultAmount', label: 'Default Amount', format: value => numberToPrice(value, 'N/A') },
  lastUpdated: { name: 'listingDate', label: 'Last Updated', format: value => formatDate(value, 'N/A') },
  estimatedValue: { name: 'estimatedValue', label: 'Estimated Value', format: value => numberToPrice(value, 'N/A') },
  salePrice: { name: 'lastSaleAmount', label: 'Sale Price', format: value => numberToPrice(value, 'N/A') },
  saleDate: { name: 'lastSaleDate', label: 'Sale Date', format: value => formatDate(value, 'N/A') },
  estimatedEquity: { name: 'estimatedEquity', label: 'Estimated Equity', format: value => numberToPrice(value, 'N/A') },
  lienDocumentType: { name: 'lienDocumentType', label: 'Lien Type', format: value => value },
  lienType: { name: 'documentType', label: 'Lien Type', format: value => value },
  lienDate: { name: 'listingDate', label: 'Lien Date', format: value => formatDate(value, 'N/A') },
  lienAmount: { name: 'listingAmount', label: 'Lien Amount', format: value => numberToPrice(value, 'N/A') },
};

const config = {
  M: [
    fields.listPrice,
    fields.listingDate,
    fields.status,
  ],
  F: [
    fields.documentType,
    fields.defaultAmount,
    fields.lastUpdated,
  ],
  A: [
    fields.estimatedValue,
    fields.defaultAmount,
    fields.lastUpdated,
  ],
  P: [
    fields.documentType,
    fields.defaultAmount,
    fields.lastUpdated,
  ],
  C: [
    fields.estimatedValue,
    fields.salePrice,
    fields.saleDate,
  ],
  R: [
    fields.estimatedValue,
    fields.salePrice,
    fields.saleDate,
  ],
  L: [
    fields.lienDocumentType,
    fields.lienDate,
    fields.lienAmount,
  ],
  LIEN: [
    fields.lienType,
    fields.lienDate,
    fields.lienAmount,
  ],
  default: [
    fields.estimatedValue,
    fields.estimatedEquity,
    fields.saleDate,
  ],
};

const CHART_WIDTH = 245;

const UiButton = preventPropagationStop(Button);

class PropertyInfo extends PureComponent {
  componentDidMount() {
    const { info, loading, property } = this.props;
    const id = info.get('propertyId') || info.get('id');

    if (!loading && id !== property.get('id')) this.props.getProperty(id, 'M');
  }

  handleSave = () => {
    const { handleSaveProperty, info } = this.props;
    const id = info.get('propertyId') || info.get('id');
    handleSaveProperty(id);
  };

  render() {
    const { info: data, saveProperty, loading, history, property } = this.props;
    // const info = property.get('id') ? property.set('lastSaleDate', property.getIn(['lastSale', 'effectiveSaleDate'])) : data;

    const info = property || data;

    const propertyId = info.get('propertyId') || info.get('id');
    const address = info.get('address', Map());
    const cityName = address.get('cityName', '');
    const streetAddress = address.get('streetAddress', '');
    const propertyType = info.get('propertyType');
    const yearBuilt = info.get('yearBuilt');
    const bedrooms = info.get('bedrooms');
    const bathrooms = info.get('bathrooms');
    const squareFeet = info.get('squareFeet');
    const lotSquareFeet = info.get('lotSquareFeet');
    const addressString = `${streetAddress} ${cityName}`;
    const result = {};
    const btn = { kind: Button.kind.ghost, size: Button.size.middle, isLoading: loading };
    const saved = !!property.get('savedPropertyId');

    const lien = info.get('lien');
    const apartmentInfo = lien ? lien.set('type', 'LIEN') : info;
    const apartmentFields = config[apartmentInfo.get('type')] || config.default;
    const estimatedValueGraph = info.get('estimatedValueGraph');
    const estimatedEquity = info.get('estimatedEquity');
    const compSaleAmount = info.get('compSaleAmount');
    let imageUrl = info.get('imageUrl', '');
    // replace /no_photo. with /house-img-not-available.
    if (!loading && (!imageUrl || imageUrl.includes('/coming_soon.') || imageUrl.includes('/house-img-not-available.'))) imageUrl = getAerialImageUrl(propertyId);

    return (
      <div className={css.info}>
        <div className={css.details}>
          <div className={css.image}>
            {imageUrl === '' ? null : <Image src={imageUrl} alt={addressString} placeholder={getPropertyImageUrl(propertyId)} />}
          </div>
          <div className={css.left}>
            <div>{SearchNames[info.get('type')] || null}</div>
            <div className={css.address}>
              <div>
                {streetAddress}
                <br />
                {cityName}, {address.get('stateCode', '')} {address.get('zip', '')}
              </div>
            </div>
            <div>Est. Value: <b className={css.price}>{numberToPrice(info.get('estimatedValue', 0))}</b></div>
          </div>
          <div className={css.right}>
            <div>{result.district}</div>
            {!propertyType ? null : <div>{propertyType}</div>}
            {!yearBuilt ? null : <div>Built in {yearBuilt}</div>}
            {!bedrooms ? null : <div>Beds: {bedrooms}</div>}
            {!bathrooms ? null : <div>Baths: {bathrooms}</div>}
            {!squareFeet ? null : <div>SqFt: {numberFormat(squareFeet)}</div>}
            {!lotSquareFeet ? null : <div>Lot SqFt: {numberFormat(lotSquareFeet)}</div>}
          </div>
        </div>
        <div className={css.apartment}>
          {!apartmentFields ? null : apartmentFields.map((field) => {
            const value = apartmentInfo.get(field.name);
            return (<div className={css.apartmentItem} key={field.name}>
              <div className={css.centered}>
                <div className={css.value}>{value ? field.format(value) : 'N/A'}</div>
                <div className={css.caption}>{field.label}</div>
              </div>
            </div>);
          })}
        </div>
        {!estimatedValueGraph && !estimatedEquity && !compSaleAmount ? null : (
          <div className={css.chartInfo}>
            {!estimatedValueGraph ? null : (
              <Chart
                data={estimatedValueGraph.get('points')}
                type={estimatedValueGraph.get('type')}
                categories={estimatedValueGraph.get('categories')}
                colors={estimatedValueGraph.get('colors')}
                yTickFormat={Chart.yTickFormat.currency}
                caption="Estimated Value"
                className={css.chart}
                chartWidth={CHART_WIDTH}
              />
            )}
            <div className={css.tiles}>
              <div className={css.tile}>
                <div className={css.wrapper}>
                  <span className={css.value}>{numberToPrice(info.get('estimatedEquity'), '-')}</span>
                  <span className={css.title}>Est. Equity</span>
                </div>
              </div>
              <div className={css.tile}>
                <div className={css.wrapper}>
                  <span className={css.value}>{numberToPrice(info.get('compSaleAmount'), '-')}</span>
                  <span className={css.title}>Avg. Comps</span>
                </div>
              </div>
              <div className={css.tile}>
                <div className={css.wrapper}>
                  <span className={css.value}>{numberToPrice(info.get('rentAmount'), '-')}</span>
                  <span className={css.title}>Monthly Rent</span>
                </div>
              </div>
              <div className={css.tile}>
                <div className={css.wrapper}>
                  <span className={css.value}>{numberToPrice(info.get('estimatedValue'), '-')}</span>
                  <span className={css.title}>Est. Value</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={css.buttons}>
          <UiButton {...btn} onClick={() => history.push(`/search/${info.get('id')}`)}>Details</UiButton>
          <UiButton {...btn} onClick={() => history.push(`/search/${info.get('id')}/analysis/purchase/`)}>Analysis</UiButton>
          {/* <UiButton {...btn} onClick={() => console.log('save')} disabled={saved}>{saved ? 'Saved' : 'Save'}</UiButton> */}
          <UiButton {...btn} onClick={() => this.handleSave()} disabled={saved}>{saved ? 'Saved' : 'Save'}</UiButton>

        </div>
      </div>
    );
  }
}


PropertyInfo.propTypes = {
  info: PropTypes.instanceOf(Map).isRequired,
  saveProperty: PropTypes.func.isRequired,
};


export default PropertyInfo
