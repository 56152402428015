import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'components/base/SVG';
import Dropdown, { dropdownable } from 'components/base/Dropdown';
import FormControlWraper from 'components/base/FormControlWraper';
import Button, { SolidButton } from 'components/Button';
import Checkbox from 'components/base/Checkbox';
import withProperty from 'app/components/withProperty';
import Accordion from 'components/base/Accordion';
import { saveLayout, deleteLayout, selectLayouts, selectLoading } from 'data/user';

import css from './style.scss';


const getFieldName = (colId) => {
  // Get around grid bug where it adds "_1" to the end of column names.
  const idx = colId.indexOf('_');
  return colId.substr(0, idx > 0 ? idx : colId.length);
};

class GridLayoutEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.handleApply = this.handleApply.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleColumnChange = this.handleColumnChange.bind(this);
    this.handleSectionChange = this.handleSectionChange.bind(this);

    this.state = this.getState(props);
  }

  componentWillReceiveProps(props) {
    const { listingType = '', isOpen, columnApi } = props;

    if (columnApi && (!this.state || listingType !== this.state.listingType || (isOpen && !this.props.isOpen))) this.setState(this.getState(props));
  }

  getLayoutState(props, id, gridVisibility = false) {
    const { layouts, columns, defaultName } = props;
    const layout = layouts.find(l => l.id === Number(id)) || { id: '' };
    let { columnInfo } = this.state || {};

    const { name = defaultName, defaultLayout = false } = layout;

    if (!columnInfo || gridVisibility || layout.id > 0) {
      let fields;
      if (gridVisibility) fields = props.columnApi.getAllGridColumns().reduce((m, { colId, visible }) => ({ ...m, [getFieldName(colId)]: { visible } }), {});
      else fields = (layout.fields || []).reduce((m, f) => ({ ...m, [f.name]: { visible: !f.hidden } }), {});

      columnInfo = columns.reduce((m, c) => ({ ...m, [c.field]: c.field in fields ? fields[c.field].visible : true }), {});
    }

    return {
      layout,
      name,
      defaultLayout,
      columnInfo,
    };
  }

  getState(props) {
    const { layouts, columnApi, listingType = '', layoutId } = props;
    if (!columnApi) return null;

    const columns = props.columns.slice();
    // columns.sort((a, b) => a.name.localeCompare(b.name));

    const groupMap = {};
    const groups = columns.reduce((groups, c) => {
      const name = c.group || '';
      const group = groupMap[name] || { name, fields: [] };
      if (!group.fields.length) {
        groupMap[name] = group;
        groups.push(group);
      }

    // Check if the field is already in the group's fields array and if res.name starts with "Purch" hide
    if (!group.fields.some(res => res?.field === c?.field) &&  !c?.name?.startsWith('Purch')) {
      group.fields.push(c);
    }

      return groups;
    }, []);

    return {
      defaultAvailable: !!layouts.find(l => l.defaultLayout),
      listingType,
      columns,
      groups,
      layoutId,
      activeSection: groups[0].name,
      ...this.getLayoutState(props, layoutId, true),
    };
  }

  handleClick(i) {
    const { closeDropdown, onSelect } = this.props;
    closeDropdown();
    (i.onSelect || onSelect)(i);
  }

  handleSectionChange(name) {
    this.setState({ activeSection: name === this.state.activeSection ? '' : name });
  }

  handleApply(save) {
    // const { closeDropdown, onSave } = this.props;
    // onSave(this.state.columnInfo);
    // closeDropdown();
    const { name, layout: { id = null, fields }, defaultLayout, columnInfo, layoutId } = this.state;
    const { type, listingType, saveLayout, propertyId, savedPropertyId, onApply, closeDropdown, savedPropertyGroupId } = this.props;

    const layout = {
      id,
      name,
      type,
      defaultLayout,
      ...(type === 'SURROUNDING_GRID' ? { listingType, propertyId, savedPropertyId } : { savedPropertyGroupId }),
      fields: id && layoutId !== id ? fields.map(f => ({ ...f, hidden: !columnInfo[f.name] })) : this.props.columnApi.getAllGridColumns().map(({ colId, actualWidth, pinned }, seq) => {
        const name = getFieldName(colId);

        return {
          name,
          width: actualWidth,
          pin: !pinned ? '' : pinned.toUpperCase(),
          hidden: !columnInfo[name],
          seq,
        };
      }),
    };

    const apply = (layout) => {
      onApply(layout);
      closeDropdown();
    };

    if (!save) apply(layout);
    else {
      saveLayout(layout, ({ response: { layouts = [] } }) => {
        this.setState(this.getLayoutState(this.props, layout.id), () => apply(layout));
      });
    }
  }

  handleColumnChange(ev) {
    const { name, checked } = ev.target || ev;
    this.setState({ columnInfo: { ...this.state.columnInfo, [name]: checked } });
  }

  handleChange(ev) {
    const { name, checked, value } = ev.target || ev;

    let state;
    if (name === 'layout') state = this.getLayoutState(this.props, value);
    else state = { [name]: ['defaultLayout'].includes(name) ? checked : value };

    this.setState(state);
  }

  render() {
    if (!this.state) return null;

    const { isOpen, onClick, closeDropdown, loading: propLoading, onRef, layouts, userLoading } = this.props;
    const loading = propLoading || userLoading;
    const { groups, columnInfo, name, defaultLayout, layout, activeSection } = this.state;
    const btnProps = { loading };

    return (
      <div className={classNames(css.gridOptions, { [css.open]: isOpen })} onClick={onClick}>
        <SVG title="Edit Columns" icon="iconGear" />
        <div className={css.dropdown} ref={onRef}>
          <div className={css.sections}>
            <div className={css.title}>Customize Your Layout</div>
            {groups.map(g => (
              <Accordion key={g.name} caption={g.name} onChange={this.handleSectionChange} activeSection={activeSection} className={css.accordion}>
                <div className={css.items} style={{ height: `${(Math.floor(g.fields.length / 4) + (g.fields.length % 4 ? 1 : 0)) * 21}px` }}>
                  {g.fields.map(({ field, name, unavailable = false }) => (
                    <Checkbox
                      key={field}
                      label={name}
                      name={field}
                      defaultChecked={false}
                      checked={columnInfo[field] && !unavailable}
                      onClick={this.handleColumnChange}
                      containerClassName={css.item}
                      disabled={name === 'Address' || unavailable}
                    />
                  ))}
                </div>
              </Accordion>
            ))}
          </div>
          <div className={css.layouts}>
            <div className={css.fields}>
              <FormControlWraper label="Saved Layout">
                <Dropdown
                  name="layout"
                  value={String(layout.id)}
                  onChange={this.handleChange}
                  options={[{ value: '', label: '--New Layout--' }].concat(layouts.map(l => ({ value: String(l.id), label: `${l.name}${l.defaultLayout ? ' (Default)' : ''}` })))}
                />
              </FormControlWraper>
              <FormControlWraper label="Name">
                <input name="name" value={name} onChange={this.handleChange} />
              </FormControlWraper>
            </div>
            <Checkbox label="Use as Default Layout" name="defaultLayout" checked={defaultLayout} onClick={this.handleChange} />
          </div>
          <div className={css.buttons}>
            <div />
            <div>
              <Button {...btnProps} onClick={closeDropdown}>Close</Button>
              <SolidButton {...btnProps} onClick={() => this.handleApply(false)}>Apply</SolidButton>
              <SolidButton {...btnProps} onClick={() => this.handleApply(true)}>Apply &amp; Save</SolidButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*
              <div key={g.name}>
                <div className={css.title}>{g.name}</div>
                <div className={css.items} style={{ height: `${height}px` }}>
                  {g.fields.map(({ field, name }) => (
                    <Checkbox key={field} label={name} name={field} defaultChecked={false} checked={columnInfo[field]} onClick={this.handleColumnChange} containerClassName={css.item} disabled={name === 'Address'} />
                  ))}
                </div>
              </div>

          <div className={css.sections}>
            <div className={css.title}>Customize Your Layout</div>
            {sections.map(s => (
              <Accordion key={s.code} caption={s.name} onChange={this.handleSectionChange} activeSection={activeSection} className={css.accordion}>
                <div className={css.items} style={{ height: `${s.height}px` }}>
                  {s.fields.map(({ id, name }) => (
                    <Checkbox
                      key={name}
                      label={name}
                      name={name}
                      checked={!this.state.columnInfo[name] && (lmEnabled || s.code !== 'MARKETING' || id === 584)}
                      onClick={this.handleChange}
                      containerClassName={css.item}
                      disabled={name === 'streetAddress' || (!lmEnabled && s.code === 'MARKETING' && id !== 584)}
                    />
                  ))}
                </div>
              </Accordion>
            ))}
          </div>
 */
GridLayoutEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  gridApi: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnApi: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fixedColumns: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  listingType: PropTypes.string,
  defaultName: PropTypes.string,
  layoutId: PropTypes.number,
  savedPropertyGroupId: PropTypes.number,
};

GridLayoutEditor.defaultProps = {
  listingType: '',
};

export default dropdownable(withProperty(GridLayoutEditor, (state, props) => ({
  layouts: selectLayouts(state).filter(l => l.get('type') === props.type && l.get('listingType', '') === (props.listingType || '')).toJS(),
  userLoading: selectLoading(state),
}), {
  saveLayout,
  deleteLayout,
}));
