import React, { PureComponent } from 'react';
import * as outerClick from 'utils/DOM/onOuterClick';
import isFullyVisible from 'utils/DOM/isFullyVisible';

// Global variable to keep track of the currently open dropdown
let openDropdownInstance = null;

export default function decorate(Component) {
  const DropDown = class DropDown extends PureComponent {
    constructor(props) {
      super(props);
      this.onRef = this.onRef.bind(this);
      this.onClick = this.onClick.bind(this);
      this.open = this.open.bind(this);
      this.close = this.close.bind(this);
      this.tryClose = this.tryClose.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);

      this.state = {
        isOpen: false,
        direction: this.props.direction || 'bottom',
      };

      this.canOpen = true;
    }

    componentDidMount() {
      document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClickOutside);

      // Reset the openDropdownInstance if this instance is the currently open dropdown
      if (openDropdownInstance === this) {
        openDropdownInstance = null;
      }
    }

    handleClickOutside(event) {
      const { target } = event;
      const dropdownElement = this.element;

      // Check if the click happened inside the dropdown
      if (dropdownElement && dropdownElement.contains(target)) {
        return;
      }

      if (this.tryClose(event)) {
        event.stopPropagation();
      }
    }

    componentDidUpdate() {
      if (!this.props.direction) {
        if (this.state.isOpen) {
          if (this.element instanceof HTMLElement && this.state.direction === 'bottom') {
            requestAnimationFrame(() => {
              if (!isFullyVisible(this.element)) {
                this.setState({ direction: 'top' });
              }
            });
          }
        }
      }
    }

    onRef(element) {
      this.element = element;

      if (this.props.onRef) {
        this.props.onRef(element);
      }
    }

    onClick(event, ...rest) {
      event.stopPropagation(); // Prevent the event from bubbling up

      // Check if the click happened inside the dropdown
      if (this.element && this.element.contains(event.target)) {
        return;
      }

      if (this.state.isOpen) {
        this.close();
      } else {
        // Close any currently open dropdown, if it's not a parent of this one
        if (openDropdownInstance && openDropdownInstance !== this) {
          if (!this.isChildOf(openDropdownInstance)) {
            openDropdownInstance.close();
          }
        }
        this.open();
      }

      if (this.props.onClick) {
        return this.props.onClick(event, ...rest);
      }
      return undefined;
    }

    isChildOf(parentInstance) {
      let parentElement = parentInstance.element;
      let currentElement = this.element;

      while (currentElement) {
        if (currentElement === parentElement) {
          return true;
        }
        currentElement = currentElement.parentElement;
      }

      return false;
    }

    tryClose(event) {
      if (this.state.isOpen === false) return false;
      if (outerClick.isOnElement(event, this.element, this.props.skipCatcherCheck)) return false;
      this.close();
      return true;
    }

    open() {
      if (!this.state.isOpen) {
        if (this.canOpen) {
          // Set this instance as the currently open dropdown
          openDropdownInstance = this;
          this.setState({ isOpen: true, direction: this.props.direction || 'bottom' });
        }
      }
    }

    close() {
      if (this.state.isOpen) {
        this.canOpen = false;
        this.setState({ isOpen: false }, () => {
          setTimeout(() => {
            this.canOpen = true;
          }, 0);
        });
      }
    }

    render() {
      const { isOpen, direction } = this.state;

      return (
        <Component
          {...this.props}
          openDropdown={this.open}
          closeDropdown={this.close}
          onRef={this.onRef}
          onClick={this.onClick}
          isOpen={isOpen}
          direction={direction}
        />
      );
    }
  };

  DropDown.defaultProps = {
    skipCatcherCheck: false,
  };

  return DropDown;
}
