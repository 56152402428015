/** @flow */
import { CALL_API } from 'store/middleware/api';


export const LOAD_ANALYSES = ('LOAD_ANALYSES');
export const LOAD_ANALYSES_SUCCESS = ('LOAD_ANALYSES_SUCCESS');
export const LOAD_ANALYSES_ERROR = ('LOAD_ANALYSES_ERROR');

export function loadAnalyses(savedPropertyId) {
  const request = {};
  request[CALL_API] = {
    method: 'get',
    path: `/resource/auth/ps4/user/${savedPropertyId ? `properties/${savedPropertyId}/` : ''}analyses`,
    startType: LOAD_ANALYSES,
    successType: LOAD_ANALYSES_SUCCESS,
    errorType: LOAD_ANALYSES_ERROR,
  };
  return request;
}

export const SAVE_ANALYSIS = ('SAVE_ANALYSIS');
export const SAVE_ANALYSIS_SUCCESS = ('SAVE_ANALYSIS_SUCCESS');
export const SAVE_ANALYSIS_ERROR = ('SAVE_ANALYSIS_ERROR');

export function saveAnalysis(body = {}) {
  const request = {};
  request[CALL_API] = {
    method: 'put',
    path: '/resource/auth/ps4/user/analyses',
    body,
    successParams: { id: body.id },
    startType: SAVE_ANALYSIS,
    successType: SAVE_ANALYSIS_SUCCESS,
    errorType: SAVE_ANALYSIS_ERROR,
  };
  return request;
}

export const DELETE_ANALYSIS = ('DELETE_ANALYSIS');
export const DELETE_ANALYSIS_SUCCESS = ('DELETE_ANALYSIS_SUCCESS');
export const DELETE_ANALYSIS_ERROR = ('DELETE_ANALYSIS_ERROR');

export function deleteAnalysis(id) {
  const request = {};
  request[CALL_API] = {
    method: 'delete',
    path: `/resource/auth/ps4/user/analyses/${id}`,
    startType: DELETE_ANALYSIS,
    successType: DELETE_ANALYSIS_SUCCESS,
    errorType: DELETE_ANALYSIS_ERROR,
  };
  return request;
}

export const CHANGE_ANALYSIS_PARAMS = ('CHANGE_ANALYSIS_PARAMS');

export function changeAnalysisParams(data) {
  return {
    type: CHANGE_ANALYSIS_PARAMS,
    data,
  };
}

export const CHANGE_ANALYSIS_MORTGAGE_PARAMS = ('CHANGE_ANALYSIS_MORTGAGE_PARAMS');

export function changeAnalysisMortgageParams(data) {
  return {
    type: CHANGE_ANALYSIS_MORTGAGE_PARAMS,
    data,
  };
}

export const CHANGE_ANALYSIS_RENTAL_UNITS = ('CHANGE_ANALYSIS_RENTAL_UNITS');

export function changeAnalysisRentalUnits(id, data) {
  return {
    type: CHANGE_ANALYSIS_RENTAL_UNITS,
    data,
    id,
  };
}

export const ADD_ANALYSIS_RENTAL_UNIT = ('ADD_ANALYSIS_RENTAL_UNIT');

export function addAnalysisRentalUnit() {
  return {
    type: ADD_ANALYSIS_RENTAL_UNIT,
  };
}

export const REMOVE_ANALYSIS_RENTAL_UNIT = ('REMOVE_ANALYSIS_RENTAL_UNIT');

export function removeAnalysisRentalUnit(id) {
  return {
    type: REMOVE_ANALYSIS_RENTAL_UNIT,
    id,
  };
}

export const CHANGE_ANALYSIS_DETAILS_PARAMS = ('CHANGE_ANALYSIS_DETAILS_PARAMS');

export function changeAnalysisDetailsParams(prop, id, data) {
  return {
    type: CHANGE_ANALYSIS_DETAILS_PARAMS,
    prop,
    id,
    data,
  };
}

export const CHANGE_ANALYSIS_MANUAL_TOTAL = ('CHANGE_ANALYSIS_MANUAL_TOTAL');

export function changeAnalysisManualTotal(prop, amount) {
  return {
    type: CHANGE_ANALYSIS_MANUAL_TOTAL,
    prop,
    amount,
  };
}

export const CHANGE_ACTIVE_ANALYSIS = ('CHANGE_ACTIVE_ANALYSIS');

export function changeActiveAnalysis(id) {
  return {
    type: CHANGE_ACTIVE_ANALYSIS,
    id,
  };
}

export const LOAD_ACTIVE_PROPERTY = ('LOAD_ACTIVE_PROPERTY');
export const LOAD_ACTIVE_PROPERTY_SUCCESS = ('LOAD_ACTIVE_PROPERTY_SUCCESS');
export const LOAD_ACTIVE_PROPERTY_ERROR = ('LOAD_ACTIVE_PROPERTY_ERROR');

export function changeActiveProperty(id) {
  const result = {};
  result[CALL_API] = {
    method: 'get',
    path: `/resource/auth/ps4/property/report/subject/${id}`,
    startType: LOAD_ACTIVE_PROPERTY,
    successType: LOAD_ACTIVE_PROPERTY_SUCCESS,
    errorType: LOAD_ACTIVE_PROPERTY_ERROR,
  };

  return result;
}

export const LOAD_ANALYSIS_REPORT = ('LOAD_ANALYSIS_REPORT');
export const LOAD_ANALYSIS_REPORT_SUCCESS = ('LOAD_ANALYSIS_REPORT_SUCCESS');
export const LOAD_ANALYSIS_REPORT_ERROR = ('LOAD_ANALYSIS_REPORT_ERROR');

export function loadAnalysisReport(analysis, reportCode) {
  const result = {};
  result[CALL_API] = {
    method: 'post',
    download: true,
    path: `/resource/auth/ps4/user/analyses/reports/${reportCode}`,
    body: analysis,
    startType: LOAD_ANALYSIS_REPORT,
    successType: LOAD_ANALYSIS_REPORT_SUCCESS,
    errorType: LOAD_ANALYSIS_REPORT_ERROR,
  };

  return result;
}

export function loadMortgageAmortizationReport(mortgage) {
  const result = {};
  result[CALL_API] = {
    method: 'post',
    download: true,
    path: '/resource/auth/ps4/user/reports/mortgage/amortization',
    body: mortgage,
    startType: LOAD_ANALYSIS_REPORT,
    successType: LOAD_ANALYSIS_REPORT_SUCCESS,
    errorType: LOAD_ANALYSIS_REPORT_ERROR,
  };

  return result;
}

export function loadSavedAnalysisReport(analysisId, reportCode) {
  const result = {};
  result[CALL_API] = {
    method: 'get',
    download: true,
    path: `/resource/auth/ps4/user/analyses/${analysisId}/reports/${reportCode}`,
    startType: LOAD_ANALYSIS_REPORT,
    successType: LOAD_ANALYSIS_REPORT_SUCCESS,
    errorType: LOAD_ANALYSIS_REPORT_ERROR,
  };

  return result;
}

export const REMOVE_ANALYSIS_MORTGAGE = ('REMOVE_ANALYSIS_MORTGAGE');

export function removeAnalysisMortgage(index) {
  return {
    type: REMOVE_ANALYSIS_MORTGAGE,
    index,
  };
}

export const ADD_ANALYSIS_MORTGAGE = ('ADD_ANALYSIS_MORTGAGE');

export function addAnalysisMortgage() {
  return {
    type: ADD_ANALYSIS_MORTGAGE,
  };
}

export const CLEAR_ANALYSIS_CHANGES = ('CLEAR_ANALYSIS_CHANGES');

export function clearAnalysisChanges() {
  return {
    type: CLEAR_ANALYSIS_CHANGES,
  };
}

export const NEW_ANALYSIS = ('NEW_ANALYSIS');

export function newAnalysis(property) {
  return {
    type: NEW_ANALYSIS,
    data: { property },
  };
}
