import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SVG from 'components/base/SVG';
import numberToPrice from 'utils/currency/numberToPrice';
import formatNumber from 'utils/number/format';

import css from './style.scss';

const PriceList = ({ rates, options, title, icon }) => {
  return (
    <div className={css.priceList}>
      <div className={classNames(css.gradient, { [css.short]: !icon })} />
      <div className={css.title}>{title}</div>
      {!icon ? null : <SVG icon="iconIdCard" className={css.icon} />}
      <div className={css.pricing}>
        <div className={classNames(css.rate, css.caption)}>
          <div>Contacts</div>
          {options.map(o => (
            <div key={o.field} className={o.headerClassName}>
              {!o.icon ? null : <SVG icon={o.icon} />}
              <div>{o.name}</div>
            </div>
          ))}
        </div>
        <div className={css.rates}>
          {rates.map(r => (
            <div className={css.rate} key={r.quantityMin}>
              <div>{`${formatNumber(r.quantityMin)}${!r.quantityMax ? '+' : ' - '}${formatNumber(r.quantityMax) || ''}`}</div>
              {options.map(o => <div key={o.field} className={classNames({ [css.bold]: !!o.bold })}>{numberToPrice(r[o.field])} each</div>)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

PriceList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  rates: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

export default PriceList;
