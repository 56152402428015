import React, { Component } from 'react';
import Checkbox from 'components/base/Checkbox';


class IndexHeaderRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect() {
    const { context, typeCode, type, updateSurroundingPropertySelection } = this.props.context.getSurroundingProps();

    updateSurroundingPropertySelection(context, null, !type.get('allSelected'), typeCode);
  }

  render() {
    return (
      <div>
        <Checkbox checked={this.props.context.getSurroundingProps().type.get('allSelected')} onClick={this.handleSelect} />
      </div>
    );
  }
}

export default IndexHeaderRenderer;
