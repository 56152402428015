import React, { PureComponent } from 'react';

import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import FromControlWrapper from 'components/base/FormControlWraper';

import css from './style.scss';


export default class Layout extends PureComponent {
  render() {
    const { onClose, onSubmit, analysisName, onChange, rename } = this.props;
    const header = (<div className={css.header}>{rename ? 'Rename Analysis' : 'Save Analysis'}</div>);

    /* eslint-disable jsx-a11y/no-autofocus */
    return (
      <Modal isOpen uniqId="modalSaveAnalysis" padding="25px 10px" caption={header} width="350px" onClose={onClose}>
        <form className={css.body} onSubmit={onSubmit}>
          <FromControlWrapper id="analysis-name" label="Analysis Name" hasMarginBottom>
            <input autoFocus id="analysis-name" name="analysisName" type="text" value={analysisName} onChange={onChange} />
          </FromControlWrapper>

          <div className={css.buttonContainer}>
            <Button
              kind={Button.kind.ghost}
              name="cancel"
              type="button"
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              kind={Button.kind.ghost}
              name="create"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </Modal>
    );
    /* eslint-enable jsx-a11y/no-autofocus */
  }
}
