import React from "react";
import PropTypes from "prop-types";
import css from "./style.scss";

const CircularProgressBar = (props) => {
  const { percent, radius, strokeWidth, strokeColor, bgColor } = props;

  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percent / 100) * circumference;

  return (
    <div className={css.container}>
      <svg height={radius * 1.8} width={radius * 1.8} className={css.circularProgressBar}>
        <circle
          stroke={bgColor} // Background color
          fill="transparent"
          strokeWidth={strokeWidth}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={strokeColor} // Progress bar color
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

// Define the prop types
CircularProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  radius: PropTypes.number,        // Radius of the circle
  strokeWidth: PropTypes.number,   // Thickness of the stroke
  strokeColor: PropTypes.string,   // Color of the progress bar
  bgColor: PropTypes.string,       // Background color for the circle
};

// Define the default props
CircularProgressBar.defaultProps = {
  percent: 0,
  radius: 50,           // Default radius
  strokeWidth: 8,       // Default stroke width
  strokeColor: "#128292",  // Default progress bar color
  bgColor: "#CED1D6",   // Default background color
};

export default CircularProgressBar;
