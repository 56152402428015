/** @flow */
import { fromJS, Map } from 'immutable';

import * as ActionType from '../actions';


const defaultState = fromJS({
  contractsLoading: false,
  contractsError: null,
  contractUploading: false,
  contractUploadError: null,
  data: [],
  contractDeleting: false,
  contractDeleteError: null,
  contractEditing: false,
  contractEditError: null,
  contractsSaving: false,
  contractsSavingError: null,
  contractLoading: false,
  contractLoadingError: null,
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOAD_CONTRACTS:
      return state.set('contractsLoading', true)
        .set('data', defaultState.get('data'));

    case ActionType.LOAD_CONTRACTS_SUCCESS:
      return state.set('contractsLoading', false)
        .set('data', fromJS(action.response))
        .set('contractsError', null);

    case ActionType.LOAD_CONTRACTS_ERROR:
      return state.set('contractsLoading', false)
        .set('contractsError', action.error);

    case ActionType.LOAD_CONTRACT_PROPERTIES:
    case ActionType.SAVE_CONTRACT_PROPERTIES:
      return state.set('contractsLoading', true)
        .set('data', defaultState.get('data'));

    case ActionType.LOAD_CONTRACT_PROPERTIES_SUCCESS:
    case ActionType.SAVE_CONTRACT_PROPERTIES_SUCCESS:
      return state.set('contractsLoading', false)
        .set('contractsError', null);

    case ActionType.LOAD_CONTRACT_PROPERTIES_ERROR:
    case ActionType.SAVE_CONTRACT_PROPERTIES_ERROR:
      return state.set('contractsLoading', false)
        .set('contractsError', action.error);

    case ActionType.DELETE_CONTRACT:
      return state.set('contractDeleting', true);

    case ActionType.DELETE_CONTRACT_SUCCESS:
      return state.set('contractDeleting', false)
        .set('data', fromJS(action.response))
        .set('contractDeleteError', null);

    case ActionType.DELETE_CONTRACT_ERROR:
      return state.set('contractDeleting', false)
        .set('contractDeleteError', action.error);

    case ActionType.EDIT_CONTRACT:
      return state.set('contractEditing', true);

    case ActionType.EDIT_CONTRACT_SUCCESS:
      return state.set('contractEditing', false)
        .set('data', fromJS(action.response))
        .set('contractEditError', null);

    case ActionType.EDIT_CONTRACT_ERROR:
      return state.set('contractEditing', false)
        .set('contractEditError', action.error);

    case ActionType.UPLOAD_CONTRACT:
      return state.set('contractUploading', true);

    case ActionType.UPLOAD_CONTRACT_SUCCESS:
      return state.set('contractUploading', false)
        .set('data', fromJS(action.response))
        .set('contractUploadError', null);

    case ActionType.UPLOAD_CONTRACT_ERROR:
      return state.set('contractUploading', false)
        .set('contractUploadError', action.error);
    case ActionType.VIEW_CONTRACT:
      return state.set('contractLoading', true);

    case ActionType.VIEW_CONTRACT_SUCCESS:
      return state.set('contractLoading', false);

    case ActionType.VIEW_CONTRACT_ERROR:
      return state.set('contractLoading', false)
        .set('contractLoadingError', action.error);

    default:
      return state;
  }
}
