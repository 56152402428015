import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


export default function urlSubscribe(Comp) {
  class UrlSubscriber extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        detailsRoot: '',
        root: '/search',
      };
      this.handleChanges = () => {
        this.setState(this.getEmitter().getState());
      };
    }

    componentDidMount() {
      requestAnimationFrame(() => this.setState(this.getEmitter()?.getState()));
      this.getEmitter()?.on('changes', this.handleChanges);
    }

    componentWillUnmount() {
      this.getEmitter()?.removeListener('changes', this.handleChanges);
    }

    getEmitter() {
      return this.context.urls || this.props.urls;
    }

    render() {
      return <Comp {...this.props} {...this.state} urls={this.getEmitter()} />;
    }
  }

  UrlSubscriber.contextTypes = {
    urls: PropTypes.object.isRequired,
  };

  return UrlSubscriber;
}
