import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import pluralize from 'pluralize';
import classNames from 'classnames';

import Button, { SolidButton } from 'components/Button';
import SVG from 'components/base/SVG';
import { logout, extendSession, selectLogoutMessage, selectLoggedOut, authenticate, selectDuplicateSession } from 'data/user';
import { getSessionRemainingSeconds } from 'store/middleware/api';
import { getBrandCode, getBrandLoginUrl, getBrandName } from 'utils/brand';

import css from './style.scss';


const TIMEOUT_THRESHOLD_SECONDS = 30 * 60;

const exit = () => {
// // if (getBrandCode() === 'investorsedge' && self !== top) {
// //   // Only redirect if we we're actually logged out, and not a result of Safari issue https://gist.github.com/iansltx/18caf551baaa60b79206, in which case we'll already be redirecting.
// //   if (document.cookie.toLowerCase().indexOf('jsessionid=') >= 0) top.location.href = 'https://www.dohardmoney.com/module/feature/investors-edge';
// // } else window.location.href = getBrandLoginUrl();
  if (getBrandCode() === 'investorsedge' && self !== top) top.location.reload();
  else window.location.href = getBrandLoginUrl();
}


class SessionOverlay extends PureComponent {
  constructor(props) {
    super(props);

    this.handleExtendClick = this.handleExtendClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleOvertakeClick = this.handleOvertakeClick.bind(this);
    this.checkTimeout = this.checkTimeout.bind(this);

    this.logoutTimer = process.env.NODE_ENV === 'dev-server' ? null : setInterval(this.checkTimeout, 1000);

    this.state = { remainingSeconds: getSessionRemainingSeconds() };
  }

  componentWillReceiveProps(props) {
    const { loggedOut, logoutMessage } = props;

    if (loggedOut && !logoutMessage) exit();
  }


  handleExtendClick() {
    this.props.extendSession();
  }

  handleLogoutClick() {
    const { loggedOut, logout } = this.props;

    if (loggedOut) exit();
    else {
      this.killTimer();
      logout('manual');
    }
  }

  handleOvertakeClick() {
    this.props.authenticate(null, null, true);
  }

  killTimer() {
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
      this.logoutTimer = null;
    }
  }

  checkTimeout() {
    this.setState({ remainingSeconds: getSessionRemainingSeconds() }, () => {
      if (this.state.remainingSeconds <= 0) {  // (89 * 60) + 40
        this.killTimer();
        this.props.logout('auto');
      }
    });
  }

  render() {
    const { loggedOut, logoutMessage, duplicateSession } = this.props;
    const { remainingSeconds } = this.state;
    const minutes = Math.floor(remainingSeconds / 60);

    let message;

    if (loggedOut) message = logoutMessage;
    else if (duplicateSession) message = `The username you are using is currently still logged in. This is due to a prior session not being properly logged out or another user is using the same account credentials. It is required that every user have their own unique username.\n\nAdditional Team Member accounts can be added by the primary account holder in ${getBrandName()}'s Account Settings.\n\nBy clicking "PROCEED" you will continue with your login but will end the other open session.`;
    else if (remainingSeconds < TIMEOUT_THRESHOLD_SECONDS) message = `Are you still there? Click Extend Session if you would like to continue using the software, otherwise you will be automatically logged out in ${minutes ? `${pluralize('minute', minutes, true)} ` : ''}${pluralize('second', remainingSeconds % 60, true)}.`;

    return !message ? null : (
      <div className={classNames(css.session, { [css.loggedOut]: loggedOut })}>
        <div className={css.popup}>
          <div className={css.warning}>
            <SVG icon="iconWarning" />
          </div>
          <div className={css.message}>{message}</div>
          <div className={css.buttons}>
            <Button onClick={this.handleLogoutClick} solid={loggedOut}>{loggedOut ? 'Close' : 'Log Out'}</Button>
            {loggedOut || !duplicateSession ? null : <SolidButton onClick={this.handleOvertakeClick}>Proceed</SolidButton>}
            {loggedOut || duplicateSession ? null : <SolidButton onClick={this.handleExtendClick}>Extend Session</SolidButton>}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  loggedOut: selectLoggedOut(state),
  logoutMessage: selectLogoutMessage(state),
  duplicateSession: selectDuplicateSession(state),
}), {
  logout,
  extendSession,
  authenticate,
})(SessionOverlay);
