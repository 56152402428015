import React, { Component } from 'react';
import formatServerUrl from 'utils/URL';
import Image from 'components/base/Image';
import Button from 'components/base/Button';

import css from './style.scss';


class CampaignContentCellRenderer extends Component {
  constructor(props) {
    super(props);

    this.openPostcardPreview = this.openPostcardPreview.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handlePlayClick = this.handlePlayClick.bind(this);

    this.state = { audio: null, playing: false };
  }

  openPostcardPreview() {
    const { context: { openPostcardPreview }, data: { id, contentUrl, contentUrl2 } = {} } = this.props;

    openPostcardPreview({ campaign: { id, frontGraphicUrl: formatServerUrl(contentUrl), backGraphicUrl: formatServerUrl(contentUrl2) } });
  }

  handleRef(ref) {
    this.setState({ audio: ref, playing: false });
  }

  handlePlayClick() {
    const { audio, playing } = this.state;

    if (!playing) {
      audio.load();
      audio.play();
    } else audio.pause();

    this.setState({ playing: !playing });
  }


  render() {
    const { value, data: { type, previewUrl2, contentUrl, contentUrl2 } = {} } = this.props;

    if (type === 'POSTCARD') {
      return (
        <div className={css.postcardContent} onClick={this.openPostcardPreview}>
          {!value ? null : <Image src={formatServerUrl(value)} alt="preview" />}
          {!previewUrl2 ? null : <Image src={formatServerUrl(previewUrl2)} alt="preview2" />}
        </div>
      );
    }

    if (type === 'EMAIL') {
      return (
        <div className={css.emailContent}>
          {!value ? null : <Image src={formatServerUrl(value)} alt="preview" />}
        </div>
      );
    }

    if (type === 'VOICEMAIL') {
      return (
        <div>
          <audio onEnded={this.handlePlayClick} ref={this.handleRef}>
            <source src={formatServerUrl(contentUrl)} />
            <track kind="captions" src="" srcLang="en" />
          </audio>
          <span>{contentUrl2}</span>
          <Button kind={Button.kind.blueLink} className={css.blueLink} onClick={this.handlePlayClick}>{this.state.playing ? 'Stop' : 'Play'}</Button>
        </div>
      );
    }

    return null;
  }
}

export default CampaignContentCellRenderer;
