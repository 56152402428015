/** @flow */
import React from 'react';
import { pure } from 'recompose';

import SVGsprite from 'components/SVGsprite';

import css from './style.scss';


export default pure(props => (
  <div className={css.fullHeight}>
    <SVGsprite />
    {props.children}
  </div>
));

