import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { selectAnalysisReports } from 'data/user/selectors';
import { selectIsLoading } from 'data/analyses/selectors';
import { loadAnalysisReport } from 'data/analyses/actions';
import ListKeyValue from 'components/ListKeyValue';
import SVG from 'components/base/SVG';
import Button from 'components/base/Button';
import css from './style.scss';


class TabReports extends PureComponent {
  constructor(props) {
    super(props);
    this.handleReportDownload = this.handleReportDownload.bind(this);
  }

  handleReportDownload(reportCode) {
    const { analysisParams, loadAnalysisReport } = this.props;
    loadAnalysisReport(analysisParams, reportCode);
  }

  render() {
    const { analysisReports, isLoading } = this.props;

    return (
      <div>
        <div className={css.tabWrapper}>
          <div className="listKeyValue">
            <ListKeyValue>{analysisReports.map(report => (
              <div className="row" key={report.get('code')}>
                <div className={classNames('key', css.key)}>
                  <SVG icon="iconFolder" className={css.iconFolder} />
                  <span>{report.get('name')}</span>
                </div>
                <div className={classNames('value', css.value)}>
                  <Button kind="link-blue" name="download" size="small" onClick={() => this.handleReportDownload(report.get('code'))} isLoading={isLoading}>
                    Download
                  </Button>
                </div>
              </div>
            ))}</ListKeyValue>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    analysisReports: selectAnalysisReports(state),
    isLoading: selectIsLoading(state),
  };
}

const mapActionToProps = {
  loadAnalysisReport,
};


export default connect(mapStateToProps, mapActionToProps)(TabReports);
