import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { MinMaxInput } from 'components/base/Input';
import PageButton from './PageButton';

import css from './style.scss';


class Paginator extends PureComponent {
  render() {
    const { current, total, onChangePage } = this.props;

    if (!total || total === 1) return null;

    const disabledLeft = current <= 1;
    const disabledRight = current >= total;

    return (
      <div className={css.pagination}>
        <PageButton first disabled={disabledLeft} page={1} onClick={onChangePage} />
        <PageButton previous disabled={disabledLeft} page={current - 1} onClick={onChangePage} />
        <div className={css.currentPage}>
          <span className={css.separator} />
          <span>Page </span>
          <MinMaxInput className={css.input} value={current} min={1} max={total} onChange={event => onChangePage(event.value)} />
          <span> of {total}</span>
          <span className={css.separator} />
        </div>
        <PageButton next disabled={disabledRight} page={current + 1} onClick={onChangePage} />
        <PageButton last disabled={disabledRight} page={total} onClick={onChangePage} />
      </div>
    );
  }
}

Paginator.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChangePage: PropTypes.func,
};

export default Paginator;
