export default function getParentRoute(props) {
  const { routes, route: currentRoute, params } = props;
  const paths = [];

  routes?.some((route) => {
    if (route === currentRoute) return true;
    if (route.path) {
      paths.push(route.path);
    }
    return false;
  });

  const route = paths.filter(Boolean).map((path) => {
    if (path.startsWith(':')) return params[path.slice(1)];
    return path;
  }).join('/');

  return `/${route}`.replace(/\/{2,}/g, '/').replace(/\/$/g, '') || '';
}
