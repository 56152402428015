/** @flow */
import { createSelector } from 'reselect';
import { List } from 'immutable';


const checkState = state => state;

export const selectCurrentPathname = createSelector(
  checkState,
  state => List(
    state => List(state.getIn(['routing', 'locationBeforeTransitions', 'pathname']).split('/')),
  )
);

export default selectCurrentPathname;
