/** @flow */
import React from 'react';
import { Text } from 'recharts';
import css from './style.scss';


const CustomTick = (props) => {
  const { payload, tickFormatter, ...rest } = props;
  const { value } = payload;

  const formattedValue = typeof tickFormatter === 'function' ? tickFormatter(value) : value;

  return (
    <Text {...rest} textAnchor="start" className={css.label} x={0}>
      {formattedValue}
    </Text>
  );
};

export default CustomTick;
