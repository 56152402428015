import { fromJS, Map, List } from 'immutable';
import { GroupTypeHeaders } from 'data/property';

import { getSortComparator } from '.';

/**
 Dynamic group loading functionality... due for some cleanup.
 **/

export const defaultGroupId = '0';
export const assignedGroupId = 'assigned';
export const defaultLimit = 500;

export const MAIN_CONTEXT = 'main';
export const AVAILABLE_CONTEXT = 'available';
export const ASSIGNED_CONTEXT = assignedGroupId;

export const defaultGroup = {
  id: null,                 // Group ID.
  name: '',                 // Group Name.
  type: null,               // Group Type.
  data: null,               // Cached, full data set for this group. Will be null if contexts are only paging through subset, and will be set once all data has been retrieved.
  size: 0,                  // Total number of records in this group.
  modified: false,          // Whether this was the target group in last server operation, and should be loaded in interface.
  refresh: false,           // Whether the data for this group should be refreshed.
};

export const defaultAssignedGroup = {
  ...defaultGroup,
  id: assignedGroupId,
  data: [],
};

export const defaultGroupContext = {
  info: defaultGroup,       // Full group info.
  filterEnabled: false,     // Whether to apply data filters.
  filters: {                // Data filters.
    groupIds: 'ANY|',
    marketingFlags: 'ANY|',
    mlsListingStatus: 'ANY|',
    typeId: 'ANY|',
    statusId: 'ANY|',
    listingTypes: 'ANY|',
  },
  sortFields: [],           // Fields & Directions to sort by. Only one field support at a time.
  index: 0,                 // Index from which last server result set started at.
  limit: defaultLimit,      // Number of records to pull from server at a time.
  data: [],                 // All data being displayed, and in proper order. Will be a subset of group's full data if there are filters or if all data has not been retrieved.
  size: null,               // Number of records in "data". This will be null if data has not been pulled yet.
  selected: 0,              // Number of records in selection, including "remaining" that have not been pulled into "data".
  selection: [],            // Array of IDs of current selection, all of which appear in "data".
  fullSelection: {},        // Map of all IDs that the user has selected. May not all be in "data" currently, thus not part of current selection, but will be added back to selection if data is pulled back in due to modified criteria.
  remaining: 0,             // Number of records that have not been retrieved from server.
  remainingSelected: false, // Whether all records not pulled into "data" are considered selected; i.e. they will be included in any server side operation.
  allSelected: false,       // Whether all records in "data" as well as remaining have been selected.
};

export const defaultContext = {
  name: null,
  group: defaultGroupContext,
  groups: {},
};

export const defaultGroupMap = fromJS(defaultGroup);
export const defaultAssignedGroupMap = fromJS(defaultAssignedGroup);
export const defaultContextMap = fromJS(defaultContext);
export const defaultGroupContextMap = fromJS(defaultGroupContext);

const getContext = (state, id) => state.getIn(['contexts', id, 'group']);

export const getGroupOptions = groups => (
  groups.reduce((ar, group, index) => {
    if (!index || group.get('type') !== groups.getIn([index - 1, 'type'])) ar.push({ label: GroupTypeHeaders[group.get('type')], options: [] });
    ar[ar.length - 1].options.push({ value: index, label: `${group.get('name')} (${group.get('size')})` });

    return ar;
  }, [{ options: [{ label: 'Select Group' }] }])
);

export const refreshGroups = (state, refreshGeneric = false) => {
  // See if there is a modified group, for which we'll toss any cached data and reset the modified flag
  const refreshIds = [];

  const groups = state.get('groups').map((g) => {
    const id = g.get('id');
    if (g.get('refresh') || (id < 100 && refreshGeneric)) {
      refreshIds.push(id);
      return g.merge({ data: null, refresh: false });
    }
    return g.merge({ refresh: false });
  }).concat(List(state.getIn(['groupInfo', assignedGroupId]) ? [] : [defaultAssignedGroupMap]));

  // Create group.id -> group map
  const groupInfo = groups.reduce((m, g, i) => m.set(g.get('id'), g.set('index', i)), Map());

  // Update all group references to the master objects in the "groups" list.
  return state.merge({ groupInfo, groups }).update('contexts', contexts => contexts.map((context) => {
    const newGroups = groups.filter(g => context.get('groups').has(g.get('id'))).reduce((groups, group) => {
      const id = group.get('id');
      let newGroup = context.getIn(['groups', id]).merge({ info: group, name: context.get('name') });

      if (refreshIds.includes(id)) newGroup = newGroup.set('size', null);
      return groups.set(id, newGroup);
    }, Map());

    return context.set('groups', newGroups).update('group', group => newGroups.get(group.get('id'), defaultGroupContextMap));
  }));
};

export const mergeGroups = (state, data, refreshAll = false) => {
  if (!data) return state;

  // Determine if any groups' property counts have changed, indicating that those groups should be refreshed, as well as all generic ones.
  const groups = state.get('groups');
  const newGroups = data.map(g => ({ ...defaultGroup, ...g, refresh: refreshAll || g.size !== state.getIn(['groupInfo', g.id], defaultGroupMap).get('size') }));
  const refreshGeneric = !!(newGroups.find(g => g.refresh) || groups.find(g => g.get('size') && !newGroups.find(n => n.id === g.get('id'))));

  return refreshGroups(state.merge({ groups: newGroups }), refreshGeneric);
};

export const updateGroupContext = (state, groupContext) => (
  refreshGroups(state.updateIn(['contexts', groupContext.get('name')], (c) => {
    const id = groupContext.get('id');
    const group = c.getIn(['groups', id]);
    if (!group) return c;

    return c.setIn(['groups', id], groupContext);
  }))
);

export const updateGroupContextSelection = (context) => {
  const fullSelection = context.get('fullSelection');
  const data = context.get('data').map(d => d.set('selected', fullSelection.get(d.get('id'), false)));

  const selection = data.filter(d => d.get('selected')).map(d => d.get('id'));
  const selected = selection.size + (context.get('remainingSelected') ? context.get('remaining') : 0);

  return context.merge({ data, selected, selection, allSelected: selected === context.get('size') });
};

export const setGroupContextSelection = (state, { context, select, index }) => {
  const sel = !!select;
  let fullSelection = context.get('fullSelection');
  let remainingSelected = context.get('remainingSelected');
  if (index === -1) remainingSelected = sel;
  else if (typeof index === 'number') fullSelection = fullSelection.set(context.getIn(['data', index, 'id']), sel);
  else {
    remainingSelected = sel;
    fullSelection = sel ? context.get('data').reduce((m, d) => m.set(d.get('id'), true), Map()) : Map();
  }

  return updateGroupContext(state, updateGroupContextSelection(context.merge({ fullSelection, remainingSelected })));
};

export const mergeGroupResults = (state, origContext, { data: newData = [], size = newData.length || newData.size || 0, index = 0 } = {}) => {
  if (!origContext) return state;

  const id = origContext.get('id');
  const info = state.getIn(['groupInfo', id]);

  if (!info) return state;

  const origData = origContext.get('data');
  const preSelected = origContext.get('remainingSelected') || origContext.get('name') === AVAILABLE_CONTEXT;
  const data = origData.slice(0, Math.min(index, origData.size)).concat(newData.size ? newData : fromJS(newData.map(d => ({ ...d, selected: preSelected }))));
  const fullSelection = origContext.get('fullSelection').merge(newData.reduce((m, d) => m.set(d.id, preSelected), Map()));
  const context = updateGroupContextSelection(origContext.merge({ size, data, remaining: Math.max(0, size - data.size), limit: defaultLimit, fullSelection }));

  return refreshGroups(state
    .updateIn(['contexts', context.get('name')], c => c.set('group', context).setIn(['groups', id], context))
    .mergeIn(['groups', info.get('index')], data.size < info.get('size') ? { data: null, ids: null } : { data, ids: data.map(d => d.get('id')) }));
};

const matchFilter = (value, filter) => value.toLowerCase().includes(filter.trim().toLowerCase());
const multiFilter = (value, filter, sep = ',') => {
  const parts = filter.split('|');
  if (parts.length === 2 && parts[1] !== '') {
    const mode = parts[0];
    const filterVals = parts[1].split(sep);
    const vals = value.split(sep);

    const matchCount = vals.filter(v => filterVals.includes(v)).length;

    if (mode === 'ANY') return matchCount > 0;
    if (mode === 'NONE') return !matchCount;

    return matchCount === filterVals.length; // ALL
  }

  return true;
};

const sanitize = str =>
  // Remove special characters and spaces from the string
   str.replace(/[^\w\s]/gi, '').replace(/\s+/g, '').toLowerCase();

const multiCodeFilter = (value, filter) => multiFilter(value, filter, '');

const fieldFilterMap = {
  typeId: multiFilter,
  statusId: multiFilter,
  groupIds: multiFilter,
  mlsListingStatus: multiFilter,
  marketingFlags: multiCodeFilter,
  listingTypes: multiCodeFilter,
};

export const searchGroup = (state, context) => {
  let data = context.getIn(['info', 'data']);
  if (!data) return state;

  const filters = context.get('filterEnabled') ? context.get('filters').entrySeq().filter(e => e[1] && e[1].trim().length).map(e => ({ name: e[0], value: e[1] })) : [];
  const id = context.get('id');
  const name = context.get('name');
  // const aliases = {
  //   favoriteIds: 'groupIds',
  //   listIds: 'groupIds',
  // };

  data = data.filter(d => !filters.find(f => !(fieldFilterMap[f.name] || matchFilter)(sanitize(String(d.get(f.name) || '')), sanitize(f.value))));

  // If this is the "available" context, filter out any assigned contacts.
  if (name === AVAILABLE_CONTEXT) {
    const ids = getContext(state, ASSIGNED_CONTEXT).getIn(['info', 'ids']) || List();
    data = data.filter(d => !ids.includes(d.get('id')));
  } else if (name === ASSIGNED_CONTEXT) {
    const ids = getContext(state, AVAILABLE_CONTEXT).getIn(['info', 'ids']) || List();
    data = data.map(d => d.set('assigned', ids.includes(d.get('id'))));
  }

  const sort = context.getIn(['sortFields', 0], null);
  if (sort) data = data.sort(getSortComparator(sort));

  return refreshGroups(state.setIn(['contexts', context.get('name'), 'groups', id], updateGroupContextSelection(context.merge({ data, size: data.size }))));
};

export const refreshAssigned = state => searchGroup(searchGroup(state, getContext(state, AVAILABLE_CONTEXT)), getContext(state, ASSIGNED_CONTEXT));

export const loadGroupContext = (state, { name, groupId: id, defaults = {} }) => {
  let newState = state;

  // If this is the assigned group, then clear out and existing data first since this is called on the first load.
  if (name === ASSIGNED_CONTEXT) newState = mergeGroupResults(newState, getContext(newState, ASSIGNED_CONTEXT));

  newState =
    newState.update('contexts', s => s.set(name, s.get(name, defaultContextMap.merge({ name }))
      .update((c) => {
        const info = newState.getIn(['groupInfo', id]);

        if (!info) return c.set('group', defaultGroupContextMap.merge({ name }));

        let g = c.getIn(['groups', id]);
        if (!g) {
          const data = info.get('data');
          g = defaultGroupContextMap.merge({ ...defaults, name, id, data: data || [], size: data ? data.size : null });
        }

        return c.setIn(['groups', id], g).set('group', g);
      })));
  return name === AVAILABLE_CONTEXT ? refreshAssigned(newState) : refreshGroups(newState);
};

/**
 * Move contacts selected in "available" context to "assigned".
 */
export const assignContext = (state) => {
  const context = getContext(state, ASSIGNED_CONTEXT);
  const available = getContext(state, AVAILABLE_CONTEXT);
  const size = context.get('size');
  const data = available.get('data').filter(d => d.get('selected')).map(d => d.set('selected', false));
  return refreshAssigned(mergeGroupResults(state, context, { data, size: size + data.size, index: size }));
};

export const unassignContext = (state) => {
  const context = getContext(state, ASSIGNED_CONTEXT);
  const data = context.get('data').filter(d => !d.get('selected'));
  return refreshAssigned(mergeGroupResults(state, context, { data, size: data.size, index: 0 }));
};
