import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Link as ReactLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import css from './style.scss';


class Link extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(ev) {
    const { onClick, stopPropagation } = this.props;

    if (stopPropagation) ev.stopPropagation();

    if (onClick) onClick(ev);
  }

  render() {
    const { loading, disabled, text, className, onRef, onClick, onChange, url, target, red, blue, theme, upload, name, accept, multiple, children } = this.props;
    const props = {};

    let Tag = 'div';
    if (url) {
      const external = url && url.startsWith('http');
      Tag = external ? 'a' : ReactLink;

      props[external ? 'href' : 'to'] = url;
      props.target = target;
    }

    return (
      <Tag
        ref={onRef}
        onClick={onClick}
        className={classNames(css.link, { [css.disabled]: disabled, [css.loading]: loading, [css.red]: red, [css.blue]: blue, [css.theme]: theme }, className)}
        {...props}
      >
        {text}
        {children}
        {!upload ? null : <input type="file" name={name} accept={accept} onChange={onChange} multiple={multiple} />}
      </Tag>
    );
  }
}

Link.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  accept: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  red: PropTypes.bool,
  blue: PropTypes.bool,
  theme: PropTypes.bool,
  upload: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  stopPropagation: PropTypes.bool,
};

Link.defaultProps = {
  disabled: false,
  loading: false,
  red: false,
  blue: false,
  theme: false,
  upload: false,
  multiple: false,
  stopPropagation: true,
};


export default Link;
