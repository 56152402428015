import React, { Component } from 'react';

export default class AddonSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isOpen } = this.props;

    let colorCode = `${this.props.brandCode.includes('propstream')? '#F16722': '#3D3D3D'}`
    return (
      <div style={{ display: 'flex', marginTop: '15px', alignItems: 'center' }}>
        <div
          style={{
            display: 'inline-block',
            textAlign: 'center',
            width: '114px',
          }}
        >
          <div style={{ width: '100%', height: '60px' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              id='Postcard-icon'
              width='49.27'
              height='33.593'
              viewBox='0 0 49.27 33.593'
            >
              <path
                id='Path_5'
                data-name='Path 5'
                d='M193.41,196H150.859a3.363,3.363,0,0,0-3.359,3.359v26.874a3.363,3.363,0,0,0,3.359,3.359H193.41a3.363,3.363,0,0,0,3.359-3.359V199.359A3.363,3.363,0,0,0,193.41,196Zm1.12,30.234a1.121,1.121,0,0,1-1.12,1.12H150.859a1.121,1.121,0,0,1-1.12-1.12V199.359a1.121,1.121,0,0,1,1.12-1.12H193.41a1.121,1.121,0,0,1,1.12,1.12Z'
                transform='translate(-147.5 -196)'
                fill={colorCode}
              />
              <path
                id='Path_6'
                data-name='Path 6'
                d='M169.62,200a1.119,1.119,0,0,0-1.12,1.12v22.4a1.12,1.12,0,0,0,2.24,0v-22.4A1.119,1.119,0,0,0,169.62,200Z'
                transform='translate(-144.985 -195.521)'
                fill={colorCode}
              />
              <path
                id='Path_7'
                data-name='Path 7'
                d='M187.817,200H181.1a1.119,1.119,0,0,0-1.12,1.12v4.174a4.457,4.457,0,0,1-1.417-.23l-1.589-.529a1.12,1.12,0,1,0-.708,2.125l1.588.529a6.749,6.749,0,0,0,4.25,0l1.589-.53a1.12,1.12,0,1,0-.708-2.125l-.766.255V202.24H186.7v6.719a1.12,1.12,0,1,0,0,2.24h1.12a1.119,1.119,0,0,0,1.12-1.12V201.12A1.119,1.119,0,0,0,187.817,200Z'
                transform='translate(-144.146 -195.521)'
                fill={colorCode}
              />
              <path
                id='Path_8'
                data-name='Path 8'
                d='M166.057,210H152.62a1.12,1.12,0,1,0,0,2.24h13.437a1.12,1.12,0,0,0,0-2.24Z'
                transform='translate(-147.021 -194.323)'
                fill={colorCode}
              />
              <path
                id='Path_9'
                data-name='Path 9'
                d='M163.817,218h-11.2a1.12,1.12,0,1,0,0,2.24h11.2a1.12,1.12,0,1,0,0-2.24Z'
                transform='translate(-147.021 -193.365)'
                fill={colorCode}
              />
              <path
                id='Path_10'
                data-name='Path 10'
                d='M152.62,216.24h8.958a1.12,1.12,0,0,0,0-2.24H152.62a1.12,1.12,0,1,0,0,2.24Z'
                transform='translate(-147.021 -193.844)'
                fill={colorCode}
              />
              <path
                id='Path_11'
                data-name='Path 11'
                d='M182.985,207.057l-1.59.53a4.487,4.487,0,0,1-2.832,0l-1.589-.529a1.12,1.12,0,1,0-.708,2.125l1.588.529a6.749,6.749,0,0,0,4.25,0l1.589-.53a1.12,1.12,0,1,0-.708-2.125Z'
                transform='translate(-144.146 -194.683)'
                fill={colorCode}
              />
            </svg>
          </div>
          <div
            style={{ color: '#000000', fontSize: '13px', fontWeight: 'bold' }}
          >
            Send a Postcard
          </div>
          <div style={{ color: '#6C6C6C', fontSize: '12px', padding: '5px' }}>
            Fully customizable and as low as <b>40¢</b> per postcard.
          </div>
        </div>
        <div
          style={{
            width: '2px',
            margin: '0 10px',
            height: '70px',
            background: '#A5A5A5',
          }}
        ></div>
        <div
          style={{
            display: 'inline-block',
            textAlign: 'center',
            width: '130px',
          }}
        >
          <div style={{ width: '100%', height: '74px' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='51.946'
              height='36.962'
              viewBox='0 0 51.946 36.962'
              style={{ strokeWidth: '2', marginTop: '11px' }}
            >
              <g id='SkipTrace-icon' transform='translate(1 1)'>
                <path
                  id='v-card'
                  d='M47.449,4.8H2.5A2.5,2.5,0,0,0,0,7.3V37.265a2.5,2.5,0,0,0,2.5,2.5H47.449a2.5,2.5,0,0,0,2.5-2.5V7.3a2.5,2.5,0,0,0-2.5-2.5ZM32.465,14.789h9.989v2.5H32.465ZM27.47,34.276a5.767,5.767,0,0,0-1.32-.757c-2.947-1.269-7.4-3.147-7.4-5.435,0-1.377.9-.927,1.294-3.443.165-1.044.961-.017,1.111-2.4,0-.949-.434-1.186-.434-1.186s.22-1.4.307-2.488c.091-1.132-.553-4.5-3.189-5.237-.465-.47-.777-.278.645-1.029-3.106-.147-3.83,1.478-5.485,2.675a4.347,4.347,0,0,0-1.72,3.593c.092,1.082.312,2.488.312,2.488s-.437.234-.437,1.183c.153,2.382.949,1.356,1.111,2.4.395,2.518,1.3,2.068,1.3,3.443,0,2.288-4.448,4.166-7.4,5.435a6.326,6.326,0,0,0-1.177.649V9.795H27.47V34.276Zm17.481-9.5H32.465v-2.5H44.951Z'
                  transform='translate(0 -4.8)'
                  fill='none'
                  stroke={colorCode}
                  stroke-width='2'
                />
              </g>
            </svg>
          </div>
          <div
            style={{ color: '#000000', fontSize: '13px', fontWeight: 'bold' }}
          >
            Skip Trace Contacts
          </div>
          <div style={{ color: '#6C6C6C', fontSize: '12px', padding: '5px' }}>
            Access property owners’ telephone numbers and emails instantly.
          </div>
        </div>
        <div
          style={{
            width: '2px',
            margin: '0 10px',
            height: '70px',
            background: '#A5A5A5',
          }}
        ></div>
        {this.props.brandCode.includes('propstream') &&
        <div
          style={{
            display: 'inline-block',
            textAlign: 'center',
            width: '139px',
          }}
        >
          <div style={{ width: '100%', height: '60px' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='51.27'
              height='46.779'
              viewBox='0 0 51.27 46.779'
              style={{ strokeWidth: '2', marginTop: '-10px' }}
            >
              <g id='ListAutomator-icon' transform='translate(1 1)'>
                <path
                  id='Path_19'
                  data-name='Path 19'
                  d='M305.941,222.472l2.065-4.986,3.035,1.009a12.85,12.85,0,0,1,3.006-3l-1.012-3.038,4.986-2.065,1.434,2.863a12.9,12.9,0,0,1,4.247,0l1.431-2.859,4.987,2.065-1.011,3.035a12.854,12.854,0,0,1,3,3.006l3.038-1.012,2.065,4.986-2.863,1.433a12.9,12.9,0,0,1,0,4.247l2.859,1.431-2.065,4.987-3.035-1.011a12.855,12.855,0,0,1-3.006,3l1.012,3.038-4.987,2.065L323.7,238.8a12.876,12.876,0,0,1-4.248,0l-1.431,2.861-4.986-2.065,1.009-3.035a12.872,12.872,0,0,1-3-3.006l-3.038,1.012-2.065-4.987,2.863-1.432a12.9,12.9,0,0,1,0-4.248Z'
                  transform='translate(-287.945 -210.392)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linejoin='round'
                  stroke-width='2'
                />
                <path
                  id='Path_20'
                  data-name='Path 20'
                  d='M316.053,227.271a5.182,5.182,0,1,0,2.8-6.768A5.181,5.181,0,0,0,316.053,227.271Z'
                  transform='translate(-287.204 -209.652)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_21'
                  data-name='Path 21'
                  d='M302.658,223.942a3.571,3.571,0,1,0-5.085,4.458'
                  transform='translate(-288.718 -209.527)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_22'
                  data-name='Path 22'
                  d='M289.787,240.384l1.413-3.412,2.638,1.092a8.1,8.1,0,0,1,1.9-1.894l-1.093-2.639,3.412-1.414,1.093,2.64a8.112,8.112,0,0,1,2.68,0l1.092-2.638,3.412,1.413-1.092,2.638a8.1,8.1,0,0,1,1.893,1.9l2.64-1.093,1.413,3.412-2.639,1.093a8.114,8.114,0,0,1,0,2.68l2.638,1.092-1.413,3.412-2.638-1.092a8.122,8.122,0,0,1-1.9,1.894l1.093,2.639-3.412,1.414-1.093-2.64a8.113,8.113,0,0,1-2.68,0l-1.092,2.638-3.412-1.413,1.092-2.638a8.122,8.122,0,0,1-1.894-1.9l-2.639,1.093-1.413-3.412,2.639-1.093a8.142,8.142,0,0,1,0-2.68Z'
                  transform='translate(-289.176 -208.736)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linejoin='round'
                  stroke-width='2'
                />
                <path
                  id='Path_23'
                  data-name='Path 23'
                  d='M293.62,230.788l-2.526,1.229-1.588-3.266,2.527-1.228a7.963,7.963,0,0,1-.16-2.63l-2.653-.917,1.186-3.432,2.653.917a8.025,8.025,0,0,1,1.75-1.971l-1.228-2.526,3.265-1.588,1.228,2.527a7.965,7.965,0,0,1,2.63-.16l.917-2.653,3.432,1.186-.917,2.653'
                  transform='translate(-289.219 -210.034)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linejoin='round'
                  stroke-width='2'
                />
                <path
                  id='Path_24'
                  data-name='Path 24'
                  d='M296.667,240.94A3.587,3.587,0,1,0,301.354,239,3.587,3.587,0,0,0,296.667,240.94Z'
                  transform='translate(-288.672 -208.233)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linejoin='round'
                  stroke-width='3'
                />
              </g>
            </svg>
          </div>
          <div
            style={{ color: '#000000', fontSize: '13px', fontWeight: 'bold' }}
          >
            Automate Your Leads
          </div>
          <div style={{ color: '#6C6C6C', fontSize: '12px', padding: '5px' }}>
            With List Automator save 2¢ on each skip trace record returned.
          </div>
        </div>
        }
        {this.props.brandCode.includes('propstream') &&
        <div
          style={{
            width: '2px',
            margin: '0 10px',
            height: '70px',
            background: '#A5A5A5',
          }}
        ></div>
        }
        <div
          style={{
            display: 'inline-block',
            textAlign: 'center',
            width: '125px',
          }}
        >
          <div style={{ width: '100%', height: '73px' }}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30.608'
              height='51.27'
              viewBox='0 0 30.608 51.27'
              style={{ strokeWidth: '2' }}
            >
              <g id='DriveForDollars-icon' transform='translate(1 1)'>
                <path
                  id='rect1259'
                  d='M49.973,33H70.635a3.965,3.965,0,0,1,3.973,3.973V78.3a3.965,3.965,0,0,1-3.973,3.973H49.973A3.965,3.965,0,0,1,46,78.3V36.973A3.965,3.965,0,0,1,49.973,33Z'
                  transform='translate(-46 -33)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                />
                <path
                  id='path1262'
                  d='M46,41H74.608'
                  transform='translate(-46 -34.643)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                />
                <path
                  id='path1264'
                  d='M46,85H74.608'
                  transform='translate(-46 -43.677)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                />
                <path
                  id='path1266'
                  d='M63,90h1.589'
                  transform='translate(-49.49 -44.704)'
                  fill='none'
                  stroke={colorCode}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                />
              </g>
            </svg>
          </div>
          <div
            style={{ color: '#000000', fontSize: '13px', fontWeight: 'bold' }}
          >
            Drive for Dollars
          </div>
          <div style={{ color: '#6C6C6C', fontSize: '12px', padding: '5px' }}>
            Download our app and reach out to homeowners/leads on the go.
          </div>
        </div>
      </div>
    );
  }
}
