import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, fromJS } from 'immutable';
import { withRouter } from 'react-router-dom';
import Slider from 'rc-slider';
import classNames from 'classnames';
import { ContactAssignmentPopup } from 'app/components/GroupAssignment';
import Button, { ButtonLink } from 'components/base/Button';
import Confirm from 'app/components/Confirm';
import FroalaEditor from 'components/FroalaEditor';
import { froalaMinimalConfig } from 'config/constants/wysiwyg';
import { campaignPath, campaignEmailPath } from 'app/routes';
import ContentTile from 'app/components/GraphicEditor/ContentTile';
import { selectProfile } from 'data/user';
import Dropdown from 'components/base/Dropdown';
import Prompt from 'app/components/Prompt';

import {
  selectCampaign,
  selectLoading,
  selectEmailCampaign,
  selectEmailLayouts,
  loadEmailCampaign,
  saveEmailCampaign,
  previewEmailCampaign,
  updateEmailCampaign,
  ElementTypes,
  MarketingCampaignStatuses,
  placeholderOptions,
  downloadEmailDetail,
} from 'data/campaigns';

import Checkout from './Checkout';
import Preview from './Preview';
import { layouts } from './Preview';
import css from './style.scss';


class EmailCampaign extends PureComponent {

  constructor(props) {
    super(props);

    this.handleSaveClick = this.handleSaveClick.bind(this);
//    this.handleToggleStatusClick = this.handleToggleStatusClick.bind(this);
    this.handleEditorInitialized = this.handleEditorInitialized.bind(this);
    this.handleHeaderChange = this.handleHeaderChange.bind(this);
    this.handleBodyChange = this.handleBodyChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.handlePublishClick = this.handlePublishClick.bind(this);
    this.handleSelectElement = this.handleSelectElement.bind(this);
    this.handleLogoWidthChange = this.handleLogoWidthChange.bind(this);
    this.handleMoveBackClick = this.handleMoveBackClick.bind(this);
    this.handleMoveForwardClick = this.handleMoveForwardClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleEditRecipientsClick = this.handleEditRecipientsClick.bind(this);
    this.handleDownloadDetailClick = this.handleDownloadDetailClick.bind(this);

    this.editors = [];
    this.headerConfig = { ...froalaMinimalConfig, fontSizeDefaultSelection: '44', events: { 'froalaEditor.initialized': this.handleEditorInitialized }, placeholderText: 'Header Text' };
    this.bodyConfig = { ...this.headerConfig, fontSizeDefaultSelection: '18', placeholderText: 'Body Text' };
    this.initElements(props);

    this.state = { loaded: false, selectedIndex: null, path: null, emailCampaignId: null };
  }

  componentWillMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(props) {
    const readOnly = this.isReadOnly(props);
    this.editors.forEach(editor => editor.edit[readOnly ? 'off' : 'on']());
    this.handleProps(props);
    this.initElements(props);
  }

  initElements(props) {
    const elements = (props.campaign.get('elements') || List());

    this.elements = fromJS([
      (elements.find(e => e.get('type') === ElementTypes.BANNER) || fromJS({ type: ElementTypes.BANNER })).set('title', 'Banner Image'),
      (elements.find(e => e.get('type') === ElementTypes.LOGO) || fromJS({ type: ElementTypes.LOGO })).set('title', 'Top Logo'),
      (elements.find(e => e.get('type') === ElementTypes.LOGO2) || fromJS({ type: ElementTypes.LOGO2 })).set('title', 'Bottom Logo'),
    ]).map((el, i) => el.merge({ i }));
  }

  handleEditorInitialized(e, editor) {
    const readOnly = this.isReadOnly();
    this.editors.push(editor);
    editor.edit[readOnly ? 'off' : 'on']();
  }

  handleEditRecipientsClick() {
    const { campaign, updateEmailCampaign, openContactAssignmentPopup } = this.props;
    openContactAssignmentPopup({ ids: (campaign.get('contactIds') || List()).toJS(), onSave: contactIds => updateEmailCampaign(campaign.merge({ contactIds }), { viewMode: campaign.get('status') !== MarketingCampaignStatuses.NEW }) });
  }

  handleProps(props) {
    const { marketingCampaign, campaign, loading, loadEmailCampaign, updateEmailCampaign, location: { pathname }, history } = props;

    const regex = /campaign\/(\d+)(?:\/email\/(\d+))?/;
    const matches = location.pathname.match(regex);
    let campaignId = matches && matches.length > 0 ? matches[1] : 0
    let emailId = matches && matches.length > 1 ? matches[2] : null

    if (!loading && !this.state.loaded && marketingCampaign.get('id')) {
      const id = emailId ? Number(emailId) : null;
      if (id !== campaign.get('id')) loadEmailCampaign(id);
      else {
        this.setState({ loaded: true }, () => {
          const campaignProps = {
            marketingCampaignId: marketingCampaign.get('id'),
            contactIds: campaign.get('contactIds') || marketingCampaign.get('contactIds'),
          };

          updateEmailCampaign(campaign.merge(campaignProps));

          if (!campaign.get('id') && (!pathname.includes('settings'))) history.push(campaignEmailPath(marketingCampaign, 'settings'));
        });
      }
    }
  }

  save(campaign, duplicate = false) {
    const { history, marketingCampaign, saveEmailCampaign, prompt, match: {params: { emailId }} } = this.props;

    let newCampaign = campaign.set('marketingCampaignId', marketingCampaign.get('id'));

    const save = () => {
      saveEmailCampaign(newCampaign, duplicate, null, null, null, null, ({ response: { campaign } }) => {
        if ((emailId || 0) !== (campaign.id || 0)) history.push(campaignEmailPath(marketingCampaign, campaign.id));
      });
    };

    if (!duplicate) save();
    else {
      prompt({
        title: 'Email Settings',
        label: 'Email Campaign Name (Optional)',
        value: `${campaign.get('name')} - Duplicate`,
        onSubmit: (name) => {
          newCampaign = newCampaign.set('name', name);
          save();
        },
      });
    }
  }

  handleSaveClick(duplicate) {
    this.save(this.props.campaign, duplicate);
  }

  handlePublishClick() {
    const { confirm, campaign, previewEmailCampaign, openCheckout } = this.props;

    let message = null;
    if (campaign.get('contactIds') && !campaign.get('contactIds').size) message = 'You must have at least one recipient selected.';
    else if ((campaign.get('subject') || '').trim() === '') message = 'You must have a subject line entered. Click the "settings" link above to set a subject line.';
    else if ((campaign.get('friendlyFrom') || '').trim() === '') message = 'You must have a Friendly From entered. Click the "settings" link above to set a Friendly From.';
    else if ((campaign.get('replyToEmail') || '').trim() === '') message = 'You must have a Reply To Email entered. Click the "settings" link above to set a Reply To Email.';
    else if ((campaign.get('url') || '').trim() === '') message = 'You must have a click-through URL entered. Click the "settings" link above to set a click-through URL.';

    if (message) confirm({ cancelLabel: null, question: message })();
    else {
      previewEmailCampaign(campaign, null, true, () => {
        openCheckout();
      });
    }
  }

  handleMoveBackClick() {
    this.moveElement(-1);
  }

  handleMoveForwardClick() {
    this.moveElement(1);
  }

  handlePlaceholderChange(index, value) {
    this.editors[index].html.insert(value, false);
    this.handleChange({ [index ? 'bodyText' : 'headerText']: this.editors[index].html.get() });
  }

  moveElement(delta) {
    const { selectedIndex } = this.state;
    this.updateElements(this.elements.delete(selectedIndex).insert(selectedIndex + delta, this.elements.get(selectedIndex)).setIn([1, 'type'], ElementTypes.LOGO).setIn([2, 'type'], ElementTypes.LOGO2));
    this.setState({ selectedIndex: selectedIndex + delta });
  }

  handleRemoveClick() {
    const { selectedIndex } = this.state;
    this.setState({ selectedIndex: null }, () => this.updateElements(this.elements.delete(selectedIndex)));
  }

  handlePreviewClick() {
    const { confirm, alert, campaign, prompt, profile, previewEmailCampaign } = this.props;

    let message = null;
    if ((campaign.get('subject') || '').trim() === '') message = 'You must have a subject line entered. Click the "settings" link above to set a subject line.';
    else if ((campaign.get('url') || '').trim() === '') message = 'You must have a click-through URL entered. Click the "settings" link above to set a click-through URL.';

    if (message) confirm({ cancelLabel: null, question: message })();
    else {
      prompt({
        text: 'Please enter the email address to send the preview to.',
        title: 'Email Campaign Preview',
        value: profile.get('username'),
        email: true,
        onSubmit: email => previewEmailCampaign(campaign, email, false, ({ response: { errors } }) => {
          if (errors) alert(`The following errors must be corrected before a preview email can be sent:\n\n${errors.join('\n\n')}`);
        }),
      });
    }
  }

  handleHeaderChange(text) {
    if (text === '') this.editors[0].fontSize.apply('32px');
    this.handleChange({ headerText: text || '' });
  }

  handleBodyChange(text) {
    if (text === '') this.editors[1].fontSize.apply('32px');
    this.handleChange({ bodyText: text || '' });
  }

  handleImageChange(image, element) {
    let elements = this.elements;
    for (let i = 0; i <= 2; i++) {
      const el = elements.get(i);
      // Have logo image drop populate any unset logos as well.
      if (i === element.i || (!el.get('image') && i > 0 && element.i > 0)) elements = elements.set(i, el.merge({ ...image, id: null }));
    }

    this.updateElements(elements);
  }

  updateElements(els) {
    const elements = els.filter(el => !!el.get('image'));
    this.handleChange({ elements });
  }

  handleSelectElement(selectedElement) {
    this.setState({ selectedIndex: selectedElement.i });
  }

  handleChange(change) {
    const { campaign, updateEmailCampaign } = this.props;
    updateEmailCampaign(campaign.merge(change));
  }

  handleLogoWidthChange(width) {
    this.updateElements(this.elements.setIn([this.state.selectedIndex === 2 ? 2 : 1, 'width'], width));
  }

  handleDownloadDetailClick() {
    const { downloadEmailDetail, campaign } = this.props;
    downloadEmailDetail(campaign.get('id'));
  }

  isReadOnly(props = this.props) {
    return props.campaign.get('status') !== MarketingCampaignStatuses.NEW;
  }

  render() {
    const { marketingCampaign, campaign, children, loading, profile } = this.props;
    const { selectedIndex } = this.state;

    const id = marketingCampaign.get('id');
    const campaignId = campaign.get('id');
    const layoutId = campaign.get('layoutId');

    const readOnly = this.isReadOnly();
    const btn = { kind: Button.kind.grayGhost, size: Button.size.large, className: css.btn, isLoading: loading };
    const smallBtn = { ...btn, size: Button.size.small };
    const blueBtn = { ...btn, kind: Button.kind.blue };

    const elements = this.elements.toJS();
    const logo = elements[1];
    const logo2 = elements[2];
    const selectedElement = selectedIndex == null ? null : elements[selectedIndex];
    const tileProps = { onChange: this.handleImageChange, onSelectElement: this.handleSelectElement, className: css.tile, selectedElement };
    const placeholderProps = { className: css.placeholderDropdown, disabled: readOnly, options: placeholderOptions, value: '' };

    if (!logo.width) logo.width = 175;
    if (!logo2.width) logo2.width = 125;

    return (
      <div className={classNames(css.campaign, css.email)}>
        <div className={css.header}>
          <div className={css.left}>
            <div>
              <div className={css.title}>
                Email
                {loading ? null : <ButtonLink kind={Button.kind.blueLink} className={css.blueLink} to={campaignEmailPath(marketingCampaign, `${campaignId ? `${campaignId}/` : ''}settings`)}>SETTINGS</ButtonLink>}
              </div>
              <div className={css.name}>{campaign.get('name')}</div>
            </div>
          </div>
          <div className={css.right}>
            <ButtonLink {...btn} to={campaignPath(id)}>Back</ButtonLink>
            {!campaign.get('detailAvailable') ? null : <Button {...blueBtn} onClick={this.handleDownloadDetailClick}>Download Detail</Button>}
            <Button {...btn} onClick={this.handlePreviewClick}>Preview</Button>
            <Button {...blueBtn} onClick={this.handleEditRecipientsClick} isLoading={loading}>{readOnly ? 'View' : 'Edit'} Recipients ({(campaign.get('contactIds') || List()).size})</Button>
            {!id ? null : <Button {...blueBtn} onClick={() => this.handleSaveClick(true)}>Duplicate</Button>}
            {readOnly ? null : <Button {...blueBtn} onClick={() => this.handleSaveClick(false)}>Save</Button>}
            {readOnly ? null : <Button {...blueBtn} onClick={this.handlePublishClick}>Publish</Button>}
          </div>
        </div>
        <div className={css.body}>
          <div className={css.entry}>
            <div className={css.raisedPanel}>
              <div className={css.label}>Email Images</div>
              <div className={css.images}>
                {elements.map(el => <ContentTile key={el.title} title={el.title} element={el} {...tileProps} />)}
              </div>
              {!logo.image && !logo2.image ? null : (
                <div className={css.slider}>
                  <div className={css.fieldLabel}>{selectedIndex === 2 ? 'Bottom' : 'Top'} Logo Size</div>
                  <Slider min={25} max={650} value={selectedIndex === 2 ? logo2.width : logo.width} onChange={this.handleLogoWidthChange} disabled={readOnly} />
                </div>
              )}
              <div className={css.buttons}>
                <Button {...smallBtn} onClick={this.handleMoveBackClick} disabled={readOnly || selectedIndex !== 2}>Move Back</Button>
                <Button {...smallBtn} onClick={this.handleMoveForwardClick} disabled={readOnly || selectedIndex !== 1}>Move Forward</Button>
                <Button {...smallBtn} kind={Button.kind.redGhost} onClick={this.handleRemoveClick} disabled={readOnly || selectedIndex == null}>Remove</Button>
              </div>
            </div>
            <div className={css.sectionTitle}>
              <div className={css.label}>Header Text</div>
              <Dropdown {...placeholderProps} onChange={({ target: { value } }) => this.handlePlaceholderChange(0, value)} />
            </div>
            <div className={css.headerText}>
              <FroalaEditor config={this.headerConfig} model={campaign.get('headerText')} onModelChange={this.handleHeaderChange} />
            </div>
            <div className={css.sectionTitle}>
              <div className={css.label}>Body Text</div>
              <Dropdown {...placeholderProps} onChange={({ target: { value } }) => this.handlePlaceholderChange(1, value)} />
            </div>
            <div className={css.bodyText}>
              <FroalaEditor config={this.bodyConfig} model={campaign.get('bodyText')} onModelChange={this.handleBodyChange} />
            </div>
          </div>
          <div className={css.previewOuter}>
            <div className={css.layouts}>
              {layouts.map(layout => (
                <div key={layout.id} className={classNames(css.layoutTile, { [css.selected]: layoutId === layout.id })} onClick={readOnly ? () => null : () => this.handleChange({ layoutId: layout.id })}>
                  <img src={layout.icon} alt="" />
                  <div>Layout {layout.id}</div>
                </div>
              ))}
            </div>
            <Preview />
          </div>
        </div>
        {children}
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  marketingCampaign: selectCampaign(state),
  loading: selectLoading(state),
  campaign: selectEmailCampaign(state),
  emailLayouts: selectEmailLayouts(state),
  profile: selectProfile(state),
}), {
  openContactAssignmentPopup: ContactAssignmentPopup.open,
  openCheckout: Checkout.open,
  downloadEmailDetail,
  loadEmailCampaign,
  saveEmailCampaign,
  updateEmailCampaign,
  previewEmailCampaign,
  confirm: Confirm.open,
  prompt: Prompt.open,
  alert: Confirm.alert,
})(EmailCampaign));
