const AnalysisTermTypes = {
  ONE_YEAR: 'ONE_YEAR',
  THREE_YEAR: 'THREE_YEAR',
  FIVE_YEAR: 'FIVE_YEAR',
  TEN_YEAR: 'TEN_YEAR',
  MORTGAGE: 'MORTGAGE',
  MANUAL: 'MANUAL',
};

export default AnalysisTermTypes;
