import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Field from './Field';
import css from './style.scss';


class FieldSet extends PureComponent {
  render() {
    const { name, fieldMap, label, placeholder, containerClass, maxLength, fullRow } = this.props;
    const field = fieldMap[name];
    const { range, tooltip, description } = field;
    const effectiveLabel = `${label || field.label}${maxLength ? ` (Limit: ${maxLength})` : ''}`;

    return (
      <div className={classNames(containerClass, { [css.partDouble]: fullRow })} title={tooltip}>
        {!effectiveLabel ? null : <div className={containerClass === css.section ? css.sectionCaption : css.partCaption}>{effectiveLabel}</div>}
        <Field {...this.props} placeholder={range ? 'Min' : placeholder} name={`${name}${range ? 'Min' : ''}`} field={field} />
        {!range ? null : <span className={css.divider}>to</span>}
        {!range ? null : <Field {...this.props} placeholder={range ? 'Max' : placeholder} name={`${name}${range ? 'Max' : ''}`} field={field} />}
        {!description ? null : <div className={css.subText}>{description}</div>}
      </div>
    );
  }
}

FieldSet.propType = {
  range: PropTypes.bool,
  fullRow: PropTypes.bool,
  containerClass: PropTypes.string,
};

FieldSet.defaultProps = {
  range: false,
  fullRow: false,
  containerClass: css.part,
};

export default FieldSet;
