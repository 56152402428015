import { Map, fromJS } from 'immutable';

import { LOCATION_CHANGE } from 'react-router-redux';


const defaultState = Map({
  locationBeforeTransitions: null,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.set('locationBeforeTransitions', fromJS(action.payload));

    default:
      return state;
  }
};
