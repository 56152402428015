import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import Button from 'components/Button';
import Modal from 'components/base/Modal';
import Checkbox from 'components/base/Checkbox';

import confirmType from './type';
import css from './style.scss';


class Confirm extends PureComponent {
  static registrationId;
  static open;

  constructor(props) {
    super(props);

    this.handleOk = this.handleOk.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    this.ref = null;

    this.state = {
      checkOptions: (props.checkOptions || []).map(({ id, label, checked }) => ({ id, label, checked: !!checked })),
    };
  }

  componentDidMount() {
    this.ref.focus();
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  handleRef(ref) {
    this.ref = ref;
  }

  handleClose() {
    this.props.closePopup();
    this.props.onCancel();
  }

  handleOk() {
    const { checkOptions } = this.state;

    this.props.closePopup();
    this.props.onOk({ checkOptions });
  }

  handleCheck(ev) {
    const { name, checked } = ev.target || ev;

    this.setState({ checkOptions: this.state.checkOptions.map(o => ({ ...o, checked: (String(o.id) === String(name) && checked) || o.checked })) });
  }

  render() {
    const { question, type, caption, okLabel, cancelLabel, textAlign, warning } = this.props;
    const { checkOptions } = this.state;
    const isDanger = type === confirmType.danger;

    return (
      <Modal isOpen uniqId="confirmation-modal" caption={caption} width="400px">
        <form onSubmit={(ev) => { ev.preventDefault(); this.handleOk(); }}>
          <div className={classNames(css.question, { [css[textAlign]]: textAlign, [css.left]: !textAlign && question.includes('\n') })}>
            {question}
          </div>
          {!warning ? null : <div className={css.warning}>{warning}</div>}
          {!checkOptions.length ? null : (
            <div className={css.checks}>
              <div>
                {checkOptions.map(o => <Checkbox label={o.label} name={o.id} key={o.id} checked={o.checked} defaultChecked={false} onClick={this.handleCheck} />)}
              </div>
            </div>
          )}
          <div className={css.buttons}>
            {!cancelLabel ? null : <Button onClick={this.handleClose}>{cancelLabel}</Button>}
            <Button solid={!isDanger} red={isDanger} onClick={this.handleOk} type="submit" onRef={this.handleRef}>{okLabel}</Button>
          </div>
        </form>
      </Modal>
    );
  }
}

Confirm.textAlign = {
  center: 'center',
  right: 'right',
};

Confirm.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  question: PropTypes.node.isRequired,
  caption: PropTypes.node,
  warning: PropTypes.string,
  type: PropTypes.oneOf(Object.values(confirmType)),
  textAlign: PropTypes.oneOf(Object.values(Confirm.textAlign)),
  // eslint-disable-next-line react/forbid-prop-types
  checkOptions: PropTypes.array,
};

Confirm.defaultProps = {
  onOk: () => {},
  onCancel: () => {},
  onClose: () => {},
  question: 'Are you sure?',
  caption: '',
  okLabel: 'Ok',
  cancelLabel: 'Cancel',
  type: confirmType.default,
  checkOptions: [],
};

Confirm.registrationId = getPopupRegistration(Confirm);
Confirm.open = (obj = {}, ...rest) => openPopup(
  Confirm.registrationId,
  { ...obj, priority: Priority.HIGHEST },
  ...rest,
);
Confirm.ask = Confirm.open;

Confirm.alert = message => Confirm.open({ cancelLabel: null, question: message });

Confirm.confirmRemoval = props => Confirm.open({
  caption: `Confirm ${props.itemType ? `${props.itemType} ` : ''}Removal`,
  okLabel: 'Remove',
  type: confirmType.danger,
  question: 'Are you sure you want to remove the selected item(s)?',
  ...props,
});

export default Confirm;
