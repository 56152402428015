import React, { PureComponent } from 'react';

import { withoutPrefix } from 'utils/currency/numberToPrice';
import stringToNumber from 'utils/currency/stringToNumber';


export default function wrap(Component) {
  return class CurrencyField extends PureComponent {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, ...rest) {
      const { value: val, name } = event.target;
      const value = stringToNumber(val);
      this.props.onChange({ value, name }, ...rest);
    }

    render() {
      const { value, onInputRef, ...rest } = this.props;
      return <Component {...rest} ref={onInputRef} value={withoutPrefix(value, '')} onChange={this.handleChange} />;
    }
  };
}
