import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';
import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import Dropdown from 'components/base/Dropdown';
import FormControlWraper from 'components/base/FormControlWraper';
import { formatGraphicUrl } from 'utils/URL';
import { selectLoading } from 'data/campaigns';

import css from './style.scss';


const categoryOptions = [
  { label: 'All Templates', value: 0 },
  { label: 'Investor Only Templates', value: 2 },
  { label: 'Realtor Only Templates', value: 3 },
  { label: 'My Custom Templates', value: -1 },
  { label: 'Past Orders', value: -2 },
];

class PostcardTemplates extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);

    const { template, templates } = this.props;

    this.state = {
      categoryId: 0,
      template: templates[!template ? 0 : Math.max(0, templates.findIndex(t => t.id === template.id))],
    };
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    if (onUnmount) onUnmount();
  }

  handleSelect() {
    this.props.onSelect(this.state.template);
  }

  handleChange(template) {
    this.setState({ template });
  }

  handleCategoryChange(ev) {
    const { value } = ev.target || ev;
    this.setState({ categoryId: Number(value) });
  }

  render() {
    const { closePopup, templates, loading } = this.props;
    const { template: selectedTemplate, categoryId } = this.state;

    // Get distinct categories applicable to available templates.
    // const categoryOptions = categories
    //   .filter(c => !!templates.find(t => t.category.id === c.get('id')))
    //   .map(c => ({ label: c.get('name'), value: c.get('id') })).toJS();

    return (
      <Modal
        isOpen
        uniqId="postcardTemplateModal"
        padding="15px"
        caption={<div className={css.previewHeader}>Select Template</div>}
        width="930px"
      >
        <div className={css.templates}>
          <div className={css.options}>
            <div className={css.option}>
              <FormControlWraper label="Category" large>
                <Dropdown
                  options={categoryOptions}
                  onChange={this.handleCategoryChange}
                  value={categoryId}
                />
              </FormControlWraper>
            </div>
            <div className={css.option}>
              <div className={css.items}>
                {templates.filter(t => (t.category.id === categoryId || (!categoryId && t.templateEnabled) || (categoryId === -1 && !t.standard && t.templateEnabled) || (categoryId === -2 && !t.templateEnabled))).map((template, i) => (
                  <div
                    key={template.id}
                    className={classNames(css.item, { [css.selected]: template.id === selectedTemplate.id })}
                    onClick={() => { this.handleChange(template); }}
                  >
                    <div className={css.template}>
                      <img src={formatGraphicUrl(template.frontGraphic, false, true)} alt={`Template Front ${i + 1}`} />
                      <img src={formatGraphicUrl(template.backGraphic, false, true)} alt={`Template Back ${i + 1}`} />
                      <div className={css.itemCaption}>{template.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={css.buttons}>
            <Button onClick={closePopup} loading={loading}>Cancel</Button>
            <SolidButton onClick={this.handleSelect} loading={loading}>Select</SolidButton>
          </div>
        </div>
      </Modal>
    );
  }
}

const PostcardTemplatesPopup = connect(state => ({
  // categories: selectPostcardCategories(state),
  loading: selectLoading(state),
}))(PostcardTemplates);

const registrationId = getPopupRegistration(PostcardTemplatesPopup);
PostcardTemplatesPopup.open = (props = {}) => openPopup(registrationId, { priority: Priority.HIGHEST, ...props });
PostcardTemplatesPopup.close = () => closePopup({ popup: registrationId });

export default PostcardTemplatesPopup;

