/** @flow */

import isArray from '../type/isArray';

/**
 * Concatenate string representation of arguments, using designated separator value between them.
 * Null/undefined values and empty strings (after trim) will be ignored.
 *
 * @param separator
 * @param args
 * @returns {string}
 */
export function joinWith(separator, ...args) {
  let str = '';
  args.forEach((arg) => {
    const val = String(typeof arg === 'undefined' || arg == null ? '' : arg).trim();

    str += (str !== '' && val !== '' ? separator : '') + val;
  });

  return str;
}

/**
 * Join values with ", " separator.
 *
 * @param args
 * @returns {string}
 */
export function join(...args) {
  return joinWith(', ', ...args);
}

export function joinPath(...args) {
  return joinWith('/', ...args);
}

export function capitalize(str) {
  return str.length ? `${str.substr(0, 1).toUpperCase()}${str.substr(1)}` : '';
}

export function uncapitalize(str) {
  return str.length ? `${str.substr(0, 1).toLowerCase()}${str.substr(1)}` : '';
}

export function toProperCase(str) {
  if (!str || typeof str !== 'string' || !str.trim().length) return '';

  return str.trim().toLowerCase().split(/\s+/).reduce((s, p) => `${s.length ? `${s} ` : ''}${capitalize(p)}`, '');
}

export function equalsIgnoreCase(str1, str2, forceEmpty = true, trim = true) {
  let s1 = str1 == null || typeof str1 === 'undefined' ? null : String(str1);
  let s2 = str2 == null || typeof str2 === 'undefined' ? null : String(str2);

  if (forceEmpty) {
    s1 = s1 || '';
    s2 = s2 || '';
  } else if (s1 == null || s2 == null) return s1 === s2; // Equal if both null.

  if (trim) {
    s1 = s1.trim();
    s2 = s2.trim();
  }

  return s1.toUpperCase() === s2.toUpperCase();
}

export function pluralize(str, qty = 0, includeNumber = false) {
  const len = isArray(qty) ? qty.length : qty;
  let val = str;
  if (len !== 1) val = str.endsWith('y') ? `${str.substr(0, str.length - 1)}ies` : `${str}s`;
  if (includeNumber) val = `${len} ${val}`;

  return val;
}

export function trimAndAddEllipsis(str = '', maxLength = 0) {
  if (str.length <= maxLength) {
    return str;
  }
  return `${str.slice(0, maxLength - 3)}...`;
}

