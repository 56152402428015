import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Checkbox from 'components/base/Checkbox';
import { selectSelection } from 'data/property';

import css from './style.scss';


class IndexHeaderRenderer extends Component {
  constructor(props) {
    super(props);

    this.menuButton = null;

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }

  componentDidMount() {
    this.props.api.addEventListener('columnResized', () => this.setState({ dummyRefresh: Math.random }));
  }

  handleMenuClick() {
    this.props.showColumnMenu(this.menuButton);
  }

  handleRef(ref) {
    this.menuButton = ref;
  }

  render() {
    const { selection, context: { onSelect }, column } = this.props;
    const selected = selection.get('allSelected');

    return (
      <div className={classNames(css.selectCell, css.selectHeader)} style={{ width: `${column.getActualWidth() - 6}px` }}>
        <Checkbox checked={selected} onClick={() => onSelect(0, !selected)} />
        <span className="ag-header-icon ag-header-cell-menu-button" onClick={this.handleMenuClick} ref={this.handleRef}>
          <span className="ag-icon ag-icon-menu" />
        </span>
      </div>
    );
  }
}

export default connect(state => ({ selection: selectSelection(state) }))(IndexHeaderRenderer);
