import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getPopupRegistration, openPopup, closePopup } from 'app/PopupHolder';
import Modal from 'components/base/Modal';
import Image from 'components/base/Image';
import Button from 'components/base/Button';
import { selectLoading, downloadPostcardCampaignPdf, downloadPostcardCampaignPdfById ,selectPostcardCampaign } from 'data/campaigns';

import css from './style.scss';


class PostcardPreview extends PureComponent {
  constructor(props) {
    super(props);

    this.downloadPdf = this.downloadPdf.bind(this);
    this.closePreviewPopup = this.closePreviewPopup.bind(this);

    // Full object (which may not be persisted yet) will be passed in preview mode. Otherwise we're just getting the campaign id and image URLs passed in.
    this.previewMode = props.campaign.frontGraphic;
  }

  downloadPdf() {
    const { downloadPostcardCampaignPdf, downloadPostcardCampaignPdfById, campaign } = this.props;

    if (this.previewMode) downloadPostcardCampaignPdf(campaign);
    else downloadPostcardCampaignPdfById(campaign.id);
  }

  closePreviewPopup() {
    const previewModal = document.getElementById('PostcardPreview')
    if(previewModal && previewModal?.style) {
      previewModal.style.display = "none";
    }
    this.props.closePopup();
  }
  render() {
    const { onConfirm, loading, campaign } = this.props;
    const { frontGraphic, backGraphic, frontGraphicUrl, backGraphicUrl } = campaign;
    const btnProps = { size: Button.size.large, isLoading: loading };

    return (
      <Modal
        isOpen
        isCloseButton={!onConfirm}
        uniqId="PreviewPostcard"
        padding="15px"
        caption={<div className={css.previewHeader}>{onConfirm ? 'Review Postcard' : `Postcard ${this.previewMode ? 'Preview' : 'Content'}`}</div>}
        width="1250px"
      >
        <div className={classNames(css.preview, { [css.confirm]: !!onConfirm })}>
          {!onConfirm ? null : <div className={css.instructions}>Please review the postcard renderings and click Next if you wish to proceed with the order.</div>}
          <div className={css.imageContainer}>
            <div className={`${css.side} col-md-6 col-sm-6 col-lg-6`}>
              <div className={css.sideHeader}>Print (Front)</div>
              <Image src={frontGraphicUrl || this.props.campaign.frontGraphic.rendering?.dataUrl} alt="front side" />
            </div>
            <div className={`${css.side} col-md-6 col-sm-6 col-lg-6`}>
              <div className={css.sideHeader}>Print (Back)</div>
              <Image src={backGraphicUrl || this.props.campaign.backGraphic.rendering?.dataUrl} alt="back side" />
            </div>
          </div>
          {!onConfirm ? null : (
            <div className={css.disclaimer}>
              <div className={css.important}>Important:</div>
              <div className={css.message}>
                <div className={css.bold}>Are your text, font and images within the specified margins?</div>
                <div className={css.bold}>Did you provide your contact information?</div>
                <div className={css.bold}>Are your images clear and high resolution enough for printing?</div>
              </div>
              <div className={css.info}>If you are satisfied with your design please press continue. To make changes, click cancel to return to the editor. By clicking continue, you understand no changes can be made after your order is placed.</div>
              <div className={css.info}>*Note: Orders are typically processed within 3-5 business days and sent to USPS for distribution.</div>
            </div>
          )}
          <div className={css.buttons}>
            <div>
              <Button kind={Button.kind.grayGhost} {...btnProps} onClick={this.closePreviewPopup} style={{ marginRight: '10px' }}>{onConfirm ? 'Cancel' : 'Close'}</Button>
              {!onConfirm ? null : <Button kind={Button.kind.blue} {...btnProps} onClick={onConfirm}>Continue, I have reviewed and approve my proof</Button>}
            </div>
            <Button kind={onConfirm ? Button.kind.grayGhost : Button.kind.blue} {...btnProps} onClick={this.downloadPdf}>Download PDF</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const PostcardPreviewPopup = connect(state => ({ loading: selectLoading(state) , campaign: selectPostcardCampaign(state).toJS(), }), { downloadPostcardCampaignPdf, downloadPostcardCampaignPdfById })(PostcardPreview);

const registrationId = getPopupRegistration(PostcardPreviewPopup);
PostcardPreviewPopup.open = (props = {}) => openPopup(registrationId, props);
PostcardPreviewPopup.close = () => closePopup({ popup: registrationId });

export default PostcardPreviewPopup;
