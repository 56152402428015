import { PureComponent } from 'react';
import { connect } from 'react-redux';

import urlSubscribe from './urlSubscribe';

import Modal from './Detail';
import { withRouter } from 'react-router-dom';


export class PropertyDetailLoader extends PureComponent {
  componentDidMount() {
    const { openPopup, history, match: { params: {id}, url } } = this.props;
    const parentRoute = url;
    const currentRoute = id;
    if (currentRoute) {
      openPopup({
        ...this.props,
        parentRoute,
        currentRoute,
      });
    }
  }

  componentWillUnmount() {
    this.props.closePopup();
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(connect(null, { openPopup: Modal.open, closePopup: Modal.close })(urlSubscribe(PropertyDetailLoader)));
