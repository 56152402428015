import React, { Component } from 'react';
import Button, { SolidButton } from 'components/Button';
import AddonSection from './AddonSection';
export default class CancelModalOffer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span>
        {/* <Activities onClick={this.handleClose} /> */}
        <div
          style={{ width: '1160px', padding: '15px 25px 0', display: 'flex' }}
        >
          <div style={{ width: '50%', paddingBottom: '25px' }}>
          <img
              src='https://signup.propstream.com/resource/logo?c=CA02937056'
              placeholder='https://signup.propstream.com/resource/logo?c=CA02937056'
              class='_159lC__logoSmall'
              style={{ width: '138px', height: '76px', visibility: `${this.props.brandCode.includes('propstream')? 'visible': 'hidden'}` }}
            />
            <div
              style={{
                marginTop: `${
                  this.props.accountType ? '80px' : '30px'
                }`,
                color: '#3D3D3D',
                font: 'normal normal bold 24px/24px Helvetica Neue',
                lineHeight: 1.2,
              }}
            >
              Do you need more time
              {!this.props.accountType && this.props.brandCode.includes('propstream') &&
                ' to explore all of the features and benefits of PropStream'}
                {!this.props.accountType && !this.props.brandCode.includes('propstream') && ` to explore all of the features and benefits of ${this.props.brandName}`}
              ?
            </div>
            <div
              style={{
                color: `${this.props.brandCode.includes('propstream')? '#F16722': '#3D3D3D'}`,
                font: 'normal normal bold 24px/24px Helvetica Neue',
                lineHeight: 1.2,
              }}
            >{this.props.brandCode.includes('propstream') ?
              <span>
              How about 
              {this.props.accountType
                ? ' a whole month'
                : ' another week'}
              , on us?</span>: 
              <span>Enjoy another {this.props.accountType ? ' month' : 'week'} on us!</span>}

            </div>
            <div
              style={{
                marginTop: '10px',
                font: 'normal normal bold 16px/20px Helvetica Neue',
                color: '#3D3D3D',
              }}
            >
              {this.props.brandCode.includes('propstream') ? 
              <span>Do you want an additional free {this.props.accountType ? ' month' : ' 7 days'} to explore PropStream and use the best real estate software to get your next
              deal?</span>: <span style={{fontWeight: 400,color: '#707070'}}>{this.props.accountType ? <span>If you’d like to continue exploring and using the best real estate software to find new deals, we’d be happy to offer a one month trial to help you understand {this.props.brandName}’s various features and benefits a little better.</span> : <span>If you choose to stay, we’re pleased to offer an additional free 7 days to help you explore {this.props.brandName} and use the best real estate software to get your next deal.</span>}</span>}
            </div>
            <div
              style={{
                marginTop: '32px',
                font: 'normal normal bold 16px/20px Helvetica Neue',
                color: '#3D3D3D',
              }}
            >
              More Great {this.props.brandCode.includes('propstream') && 'PropStream'} Features {this.props.brandCode.includes('propstream')? 'T' : 't'}o Explore:
            </div>
            <AddonSection brandCode={this.props.brandCode}/>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: `${this.props.brandCode.includes('propstream')?'flex-end': 'flex-start'}`,
                paddingRight: '5px',
                marginTop: '60px',
              }}
            >
              <SolidButton onClick={() => this.props.handleCancelOfferAccept()}>
                {this.props.accountType
                  ? 'I Want Another Month'
                  : 'I Want 7 More Days'}
              </SolidButton>
              <Button onClick={(e) => this.props.handleCancelContinue(e)}>
                Continue to Cancelation
              </Button>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <img src={'/assets/images/cancelOffer.jpg'} alt='' height='640px' />
          </div>
        </div>
      </span>
    );
  }
}
