import { Map, List, fromJS } from 'immutable';
import { AUTHENTICATION_SUCCESS, SAVE_LAYOUT_SUCCESS, SAVE_TAG_SUCCESS, DELETE_TAG_SUCCESS } from 'data/user';
import moment from 'moment';
import numberToPrice from 'utils/currency/numberToPrice';
import {
  loading,
  success,
  error,
  getSortComparator,
  mergeGroups,
  updateGroupContext,
  setGroupContextSelection,
  mergeGroupResults,
  searchGroup,
  loadGroupContext,
  assignContext,
  unassignContext,
} from 'reducers';

import { SAVE_LISTINGS, SAVE_LISTINGS_SUCCESS, SAVE_LISTINGS_ERROR } from '../search';
import { Colors, getPropertyImageUrl, SurroundingPropertyContexts, defaultSurroundingPropertySearch, defaultSelection, SaleSituationMap, SurroundingTypeIndexes } from './constants';
import * as ActionType from './actions';


const now = Date.now();
const date = new Date(now);

let typeDataVersionCounter = 1; // Counter used for setting defaultSurroundingPropertyType.dataVersion
let typeSelectionVersionCounter = 1; // Counter used for setting defaultSurroundingPropertyType.selectionVersion

const defaultSurroundingPropertyType = {
  type: null,
  label: null,
  title: null,
  stats: '',
  sort: 'distanceFromSubject',
  properties: [],
  selection: [],
  selectionMap: {},
  allSelected: false,
  loading: true,
  enabled: false,
  index: null,
  pricePerSquareFoot: null,
  dataVersion: 0, // Version number used to track changes in property results and whether data grid should be refreshed.
  selectionVersion: 0, // Version number used to track changes in selection status and whether data grid's selection should be updated.
  search: { ...defaultSurroundingPropertySearch },
};

const SurroundingTypes = [
  { name: 'Flip Comps', type: 'FLIP' },
  { name: 'Neighbors', type: 'NEIGHBOR' },
  { name: 'Linked', type: 'LINKED' },
  { name: 'Cash Buyers', type: 'C' },
  { name: 'Pre-Foreclosures', type: 'P' },
  // { name: 'Auctions', type: 'A' },
  { name: 'Bank Owned', type: 'F' },
  { name: 'Liens', type: 'L' },
  { name: 'High Equity', type: 'E' },
  { name: 'Vacant', type: 'V' },
  { name: 'Free & Clear', type: 'R' },
  { name: 'Bankruptcy', type: 'B' },
  { name: 'Divorce', type: 'D' },
];


const defaultCompDates = {
  saleDateMin: moment(date.setFullYear(date.getFullYear() - 1)),
  saleDateMax: moment(now),
};

const defaultSurroundingPropertyTypes = [
  {
    ...defaultSurroundingPropertyType,
    type: 'COMP',
    name: 'Comparables',
    label: 'Comparables',
    title: 'Comparable Properties',
  },
].concat(SurroundingTypes).map((t, i) => ({
  ...defaultSurroundingPropertyType,
  label: t.name,
  ...t,
  index: i,
  search: {
    ...(t.search || defaultSurroundingPropertySearch),
    ...(t.type === 'COMP' ? { mlsListingStatus: 'SOLD' } : {}),
    ...(['COMP', 'FLIP'].includes(t.type) ? defaultCompDates : {}),
  },
}));

const defaultSurroundingPropertyContext = {
  stale: true,
  types: defaultSurroundingPropertyTypes,
  type: defaultSurroundingPropertyTypes[0],
};

const defaultProperty = {
  address: {},
  estimatedValueGraph: {},
  compSaleAmountGraph: {},
  status: {},
};

const defaultState = fromJS({
  groups: [],
  groupInfo: {},
  groupCounts: {},
  folders: [],
  property: defaultProperty,
  contexts: {},
  pushpins: [],
  documents: [],
  importJobs: [],
  filters: [],
  searches: [],
  tags: [],
  statuses: [],
  streetView: null,
  loading: false,
  error: null,
  pushpinLoading: false,
  pushpinError: null,
  comparableLoading: false,
  comparableError: null,
  documentLoading: false,
  documentError: null,
  streetViewLoading: false,
  streetViewError: null,
  importLoading: false,
  importError: null,
  pendingPropertyExportId: null,
  propertyExport: null,
  propertyExportLoading: false,
  propertyExportError: null,
  surroundingPropertyContexts: {},
  selection: defaultSelection,
});

const getSurroundingPropertyContexts = () => Object.keys(SurroundingPropertyContexts).reduce((m, k) => ({ ...m, [k]: { name: k, ...defaultSurroundingPropertyContext } }), {});

const getGraph = (data) => {
  if (!data) return Map();

  const emptyList = List();
  const graph = data.get('series', emptyList).reduce((info, s) => {
    const category = s.get('category', '');
    const points = info.get('points', emptyList);

    return info.merge({
      points: s.get('points', List()).filter(p => !!p.get('value')).map(p => (points.find(p2 => p2.get('label') === p.get('label')) || p).set(category, p.get('value'))),
      categories: info.get('categories', List()).push(category),
    });
  }, Map({ type: data.getIn(['type']), points: emptyList, categories: emptyList }));


  return graph.set('colors', Map(graph.get('categories').map((c, i) => [c, Colors[i % 10]])));
};

export default function reducer(state = defaultState, action) {
  const { response, sort, index, selected, typeCode, context = SurroundingPropertyContexts.COMPARABLES, search, selection } = action;

  /**
   * Applies calculations to surrounding property type based on results & current selection.
   */
  const processType = (type) => {
    const code = type.get('type');
    let properties = type.get('properties');

    // Constrain selection so that a distinct set of properties are selected. If the same property is selected more than once,
    // give preference to the record based on the non-disclosure status of the area. This is currently only needed for comp
    // results when running in blended mode, for which there are frequently both public record and MLS results for a given property.
    if (code === 'COMP' && !type.getIn(['search', 'listingType'])) {
      const preferredType = state.getIn(['property', 'nonDisclosure']) ? 'M' : 'U';

      properties = properties.map((p) => {
        const propertyId = p.get('propertyId');
        const selected =
          // Must be currently selected.
          p.get('selected')
          // Must be 1) the record just clicked on 2) a record of the preferred type or 3) a non-preferred type when no preferred type selection exists for the property.
          && (p.get('type') === preferredType || !properties.find(p => p.get('propertyId') === propertyId && p.get('type') === preferredType && p.get('selected')));

        return p.set('selected', selected);
      });
    }

    const saleField = code === 'M' ? 'listingAmount' : 'saleAmount';
    const selection = properties.filter(c => c.get('selected'));

    const selectionMap = selection.reduce((m, p) => Object.assign(m, { [p.get('id')]: true }), {});
    let sales = selection.filter(p => !!p.get(saleField));
    const sqFt = selection.filter(p => !!p.get('pricePerSquareFoot'));
    const days = selection.filter(p => p.get('type') === 'M' && !!p.get('daysOnMarket'));
    const saleAmount = sales.size ? Math.round(sales.reduce((amount, s) => amount + s.get(saleField), 0) / sales.size) : null;
    const daysOnMarket = days.size ? Math.floor(days.reduce((days, p) => days + p.get('daysOnMarket'), 0) / days.size) : null;
    const pricePerSquareFoot = sqFt.size ? Math.round((sqFt.reduce((amount, s) => amount + s.get('pricePerSquareFoot'), 0) / sqFt.size) * 100) / 100 : null;
    let stats = `Avg. Sale Price: ${saleAmount ? numberToPrice(saleAmount) : '--'}, Avg. PPSF: ${pricePerSquareFoot ? numberToPrice(pricePerSquareFoot) : '--'}`;
    const daysOnMarketStats = `, Avg. Days On Market: ${daysOnMarket || '--'}`;

    // Compare selection size against number of distinct property IDs as opposed to records. Blended comp results will have
    // duplicate properties in most cases and only one instance can be selected at a time. If all distinct properties are
    // selected then the "all selected" status should be positive.
    const allSelected = selection.size >= properties.map(p => p.get('propertyId')).toSet().size;

    // Apply special Flip Comp logic
    let flipFields = {};
    if (code === 'FLIP') {
      sales = selection.filter(p => !!p.get('acqSaleAmount'));
      const purchaseAmount = sales.size ? Math.round(sales.reduce((amount, s) => amount + s.get('acqSaleAmount'), 0) / sales.size) : null;

      sales = selection.filter(p => !!p.get('disSaleAmount'));
      const resaleAmount = sales.size ? Math.round(sales.reduce((amount, s) => amount + s.get('disSaleAmount'), 0) / sales.size) : null;

      sales = selection.filter(p => !!p.get('acqSalePpsf'));
      const purchasePpsf = sales.size ? Math.round(sales.reduce((amount, s) => amount + s.get('acqSalePpsf'), 0) / sales.size) : null;

      sales = selection.filter(p => !!p.get('disSalePpsf'));
      const resalePpsf = sales.size ? Math.round(sales.reduce((amount, s) => amount + s.get('disSalePpsf'), 0) / sales.size) : null;

      const flipAmount = (resaleAmount - purchaseAmount) || null;
      const flipPpsf = (resalePpsf - purchasePpsf) || null;

      stats = `Avg Purchase Amt: ${purchaseAmount ? numberToPrice(purchaseAmount) : '--'}, Avg Flip Amt: ${resaleAmount ? numberToPrice(resaleAmount) : '--'}, Avg Gross Profit: ${flipAmount ? numberToPrice(flipAmount) : '--'}\nAvg Purchase PPSF: ${purchasePpsf ? numberToPrice(purchasePpsf) : '--'}, Avg Flip PPSF: ${resalePpsf ? numberToPrice(resalePpsf) : '--'}, Avg Gross Profit PPSF: ${flipPpsf ? numberToPrice(flipPpsf) : '--'}`;

      flipFields = { purchaseAmount, resaleAmount, flipAmount, purchasePpsf, resalePpsf, flipPpsf };
    }

    return type.merge({ stats, daysOnMarketStats, selection, selectionMap, allSelected, saleAmount, pricePerSquareFoot, properties, ...flipFields });
  };

  /**
   * Sets the results for a surrounding property type (surrounding comps, cash buyers, pfcs etc) & initializes selection & calculations.
   * Called when surrounding results are returned; i.e. after Comp tab's initial load and after any search criteria changes.
   */
  const setTypeProperties = (state, index, properties = []) => (
    state.updateIn(['surroundingPropertyContexts', context, 'types', index], (type) => {
      // Initial selection will be set here when saved comps or flip comps are first loaded.
      const initialSelection = type.get('initialSelection');
      const propertyIndexes = {};
      let propertyIndex = 1;

      const unprocessedType = type.merge({
        dataVersion: typeDataVersionCounter++,
        loading: false,
        initialSelection: null,
        properties: fromJS(properties.map((p, recordSeq) => {
          const { address: { streetAddress, cityName, zip } = {}, lastSale: { saleDate, saleAmount: lastSaleAmount2, multiParcel: lastMultiParcel } = {}, lastSaleDate, lastSaleAmount, multiParcel, saleSituationCode, pricePerSquareFoot, type: recordType, id: propertyId } = p;
          const saleAmount = lastSaleAmount2 || lastSaleAmount;

          // ID will be prefixed with M (Mls) or U (pUblic sale) for surrounding comps, allowing for fully
          // unique IDs among comp results, which typically have the same property record as both types.
          const id = `${['U', 'M'].includes(recordType) ? recordType : ''}${propertyId}`;

          // Add property index to map. If there are duplicate properties in the result set (as is common with blended comps), they should share the same index.
          if (!propertyIndexes[propertyId]) propertyIndexes[propertyId] = propertyIndex++;

          return {
            ...p,
            id,
            seq: propertyIndexes[propertyId],
            recordSeq,
            selected: !initialSelection || initialSelection.contains(id),
            streetAddress,
            cityName,
            zip,
            saleDate: saleDate || lastSaleDate,
            saleAmount,
            pricePerSquareFoot: pricePerSquareFoot || (saleAmount && p.squareFeet ? saleAmount / p.squareFeet : null),
            multiParcel: lastMultiParcel || multiParcel || false,
            saleSituation: SaleSituationMap[((type.get('type') !== 'M' && saleSituationCode) || '').substr(0, 1)] || null,
          };
        })).sort(getSortComparator(state.getIn(['surroundingPropertyContexts', context, 'types', index, 'sort']))),
      });

      const processedType = processType(unprocessedType);

      return processedType;
    })
  );

  const updateType = (newType, runSearch, targetContext = context, newState = state) => {
    const type = processType(newType);

    return newState.updateIn(['surroundingPropertyContexts', targetContext], (context) => {
      let ctx = context.setIn(['types', type.get('index')], type);

      // Only perform the following if typeCode is not specified, which indicates we're not changing things on the Comps tab; i.e. this is a different tab like Linked Properties, which doesn't have an active type or run async searches.
      if (!typeCode) ctx = ctx.set('type', type).set('stale', typeof runSearch === 'undefined' ? context.get('stale') : runSearch);

      return ctx;
    });
  };

  const mergePropertyCounts = () => {
    const { savedPropertyGroupId = 0, automationStatus = '', filterCounts = {} } = response;
    const set = automationStatus === '' ? '' : ((automationStatus && '1') || '0');

    return !filterCounts ? state : state.mergeDeep({ groupCounts: { [savedPropertyGroupId]: { [set]: { ...filterCounts, dirty: false } } } });
  };

  const mergePropertyExport = (prompt = false, isXML = false) => {
    const { base64Uri, fileName, fileId, pendingPropertyExportId = null } = response;
    if (isXML) fileName.replace('.csv', 'xml');
    return state.merge({ propertyExport: fileId ? { base64Uri, name: fileName, id: fileId, prompt } : null, pendingPropertyExportId });
  };

  const getType = (targetContext = context, newState = state) => newState.getIn(['surroundingPropertyContexts', targetContext, ...(typeCode ? ['types', SurroundingTypeIndexes[typeCode]] : ['type'])]);
  const setType = state => state.setIn(['surroundingPropertyContexts', context, 'type'], state.getIn(['surroundingPropertyContexts', context, 'types', state.getIn(['surroundingPropertyContexts', context, 'type', 'index'])]));
  const getCompSearchDefaults = (property) => {
    const squareFeet = property.get('squareFeet');

    return {
      squareFeetMin: !squareFeet ? null : Math.round(squareFeet - (squareFeet * 0.2)),
      squareFeetMax: !squareFeet ? null : Math.round(squareFeet + (squareFeet * 0.2)),
    };
  };

  const setProperty = (state, property = defaultProperty) => {
    const { surroundingSearches = {}, linkedProperties = [] } = property || {};
    let newState = state;

    if (property) {
      newState = state.merge({
        property: fromJS(property)
          .update('estimatedValueGraph', g => getGraph(g))
          .update('compSaleAmountGraph', g => getGraph(g))
          .set('mapUrl', getPropertyImageUrl(property.id)),
      });

      if (!property.id || property.id !== state.getIn(['property', 'id'])) {
        newState = newState.merge({
          surroundingPropertyContexts: getSurroundingPropertyContexts(),
          documents: [],
          streetView: null,
        });

        // Reset Comp searches
        ['FLIP', 'COMP'].forEach((type) => {
          const savedCompSearch = surroundingSearches[type] || {};
          const { saleDateMin, saleDateMax, id, listingType, selectedIds = '', shapeDefinition } = savedCompSearch;
          let initialSelection = null;

          if (id) {
            if (saleDateMin) savedCompSearch.saleDateMin = moment(saleDateMin);
            if (saleDateMax) savedCompSearch.saleDateMax = moment(saleDateMax);
            if (!listingType) savedCompSearch.listingType = null;
            if (shapeDefinition) savedCompSearch.shapeDefinition = shapeDefinition.split('/');
            if (selectedIds) initialSelection = selectedIds.split(','); // .reduce((ids, id) => (ids.includes(id) ? ids : ids.concat([id])), []);
          }
          // .reduce((ids, id) => (ids.includes(id) ? ids : ids.concat([id])), []);

          // Set square feet defaults for the property and saved comp search values if set.
          const compDefaults = getCompSearchDefaults(newState.get('property'));

          newState = updateType(newState.getIn(['surroundingPropertyContexts', SurroundingPropertyContexts.COMPARABLES, 'types', SurroundingTypeIndexes[type]]).mergeDeep({
            initialSelection,
            search: {
              ...compDefaults,
              ...savedCompSearch,
              selectedIds: null,
            },
          }), true, SurroundingPropertyContexts.COMPARABLES, newState);
        });

        // Linked properties will be returned with the detail, as it's on its own tab, so load into surrounding context here. (Other
        // types are loaded async when comps tab is opened.) Add in a "propertyId" field since that's needed for grid operations.
        newState = setTypeProperties(newState, SurroundingTypeIndexes.LINKED, linkedProperties.map(p => Object.assign(p, { propertyId: p.id })));
      }
    }

    return newState;
  };

  switch (action.type) {
    case AUTHENTICATION_SUCCESS: {
      const {
        availableListingTypes: types = [],
        propertySearches: searches = [],
        filters = [],
        tags = [],
        propertyGroups,
        propertyFolders = [],
        propertyStatuses,
        pendingPropertyExportId = null,
      } = response;

      defaultSurroundingPropertyContext.types = defaultSurroundingPropertyTypes.map(t => ({ ...t, enabled: t.type.length > 1 || types.includes(t.type) }));

      return mergeGroups(state, propertyGroups).merge({
        surroundingPropertyContexts: getSurroundingPropertyContexts(),
        filters: filters.filter(f => ['PROPERTY_GRID', 'PROPERTY_EXPORT'].includes(f.type)),
        searches,
        pendingPropertyExportId,
        folders: propertyFolders,
        tags: tags.filter(t => t.type === 'P'),
        statuses: propertyStatuses,
      });
    }

    case ActionType.SET_PROPERTY_SELECTION:
      return state.merge({ selection });

    case ActionType.CLEAR_PROPERTY_SELECTION:
      return state.merge({ selection: defaultSelection });

    case ActionType.GET_PROPERTY_EXPORT:
    case ActionType.CONFIRM_PROPERTY_EXPORT:
      return loading(state.merge({ propertyExport: null, pendingPropertyExportId: null }), 'propertyExport');

    case ActionType.GET_PROPERTY_EXPORT_ERROR:
    case ActionType.CONFIRM_PROPERTY_EXPORT_ERROR:
      return error(state, action, 'propertyExport');

    case SAVE_LISTINGS:
    case ActionType.GET_GROUP_SEARCH:
    case ActionType.RETRIEVE_PROPERTY:
    case ActionType.SAVE_GROUP_SEARCH:
    case ActionType.SEARCH_GROUP_CONTEXT:
    case ActionType.DELETE_PROPERTY_GROUP:
    case ActionType.SAVE_PROPERTY:
    case ActionType.SAVE_PROPERTIES:
    case ActionType.SAVE_PROPERTY_GROUP_OPTIONS:
    case ActionType.SEARCH_GROUP_PROPERTIES:
    case ActionType.DELETE_GROUP_PROPERTIES:
    case ActionType.SAVE_GROUP_PROPERTIES:
    case ActionType.GET_SAVED_PROPERTY_DETAIL:
    case ActionType.EXPORT_GROUP_PROPERTIES:
    case ActionType.DOWNLOAD_PROPERTY_DATA:
    case ActionType.SEARCH_PROPERTY_CONTACTS:
    case ActionType.SAVE_PROPERTY_FILTER:
    case ActionType.DELETE_PROPERTY_FILTER:
    case ActionType.SAVE_PROPERTY_GROUP_FILTER:
    case ActionType.DELETE_PROPERTY_GROUP_FILTER:
    case ActionType.DELETE_SAVED_PROPERTY:
    case ActionType.SAVE_PROPERTY_FOLDER:
    case ActionType.DELETE_PROPERTY_FOLDER:
    case ActionType.SAVE_PROPERTY_STATUS:
      return loading(state);

    case ActionType.GET_PROPERTY_EXPORT_SUCCESS:
      return success(mergePropertyExport(true), 'propertyExport');

    case ActionType.EXPORT_GROUP_PROPERTIES_SUCCESS:
      return success(mergePropertyExport());

    case ActionType.CONFIRM_PROPERTY_EXPORT_SUCCESS:
      return success(state, 'propertyExport');

    case ActionType.GET_PROPERTY:
      return loading(setProperty(state));

    case ActionType.LOAD_GROUP_PUSHPINS:
      return loading(state, 'pushpin');

    case ActionType.DOWNLOAD_COMPARABLES:
    case ActionType.EXPORT_COMPARABLES:
    case ActionType.EXPORT_FLIP_COMPARABLES:
    case ActionType.SAVE_SURROUNDING_SEARCH:
      return loading(state, 'comparable');

    case ActionType.SEARCH_COMPARABLES:
      return loading(updateType(state.getIn(['surroundingPropertyContexts', context, 'types', 0]).set('loading', true), false), 'comparable');

    case ActionType.SEARCH_FLIP_COMPARABLES:
      return loading(updateType(state.getIn(['surroundingPropertyContexts', context, 'types', SurroundingTypeIndexes.FLIP]).set('loading', true), false), 'comparable');

    case ActionType.SEARCH_NEIGHBORS:
      return loading(updateType(state.getIn(['surroundingPropertyContexts', context, 'types', SurroundingTypeIndexes.NEIGHBOR]).set('loading', true), false), 'comparable');

    case ActionType.SEARCH_SURROUNDING_PROPERTIES: {
      const { listingType } = action;
      return !listingType ? state : updateType(state.getIn(['surroundingPropertyContexts', context, 'types', SurroundingTypes.findIndex(t => t.type === listingType) + 1]).set('loading', true), false);
    }

    case SAVE_LISTINGS_ERROR:
    case ActionType.GET_GROUP_SEARCH_ERROR:
    case ActionType.SAVE_GROUP_SEARCH_ERROR:
    case ActionType.SEARCH_GROUP_CONTEXT_ERROR:
    case ActionType.DELETE_PROPERTY_GROUP_ERROR:
    case ActionType.SAVE_PROPERTY_ERROR:
    case ActionType.SAVE_PROPERTIES_ERROR:
    case ActionType.GET_PROPERTY_ERROR:
    case ActionType.RETRIEVE_PROPERTY_ERROR:
    case ActionType.SAVE_PROPERTY_GROUP_OPTIONS_ERROR:
    case ActionType.SEARCH_GROUP_PROPERTIES_ERROR:
    case ActionType.SAVE_GROUP_PROPERTIES_ERROR:
    case ActionType.DELETE_GROUP_PROPERTIES_ERROR:
    case ActionType.GET_SAVED_PROPERTY_DETAIL_ERROR:
    case ActionType.EXPORT_GROUP_PROPERTIES_ERROR:
    case ActionType.DOWNLOAD_PROPERTY_DATA_ERROR:
    case ActionType.SEARCH_PROPERTY_CONTACTS_ERROR:
    case ActionType.SAVE_PROPERTY_FILTER_ERROR:
    case ActionType.DELETE_PROPERTY_FILTER_ERROR:
    case ActionType.SAVE_PROPERTY_GROUP_FILTER_ERROR:
    case ActionType.DELETE_PROPERTY_GROUP_FILTER_ERROR:
    case ActionType.DELETE_SAVED_PROPERTY_ERROR:
    case ActionType.SAVE_PROPERTY_FOLDER_ERROR:
    case ActionType.DELETE_PROPERTY_FOLDER_ERROR:
    case ActionType.SAVE_PROPERTY_STATUS_ERROR:
      return error(state, action);

    case ActionType.LOAD_GROUP_PUSHPINS_ERROR:
      return error(state, action, 'pushpin');

    case ActionType.SEARCH_COMPARABLES_ERROR:
    case ActionType.SEARCH_NEIGHBORS_ERROR:
    case ActionType.DOWNLOAD_COMPARABLES_ERROR:
    case ActionType.EXPORT_COMPARABLES_ERROR:
    case ActionType.EXPORT_FLIP_COMPARABLES_ERROR:
    case ActionType.SAVE_SURROUNDING_SEARCH_ERROR:
    // case ActionType.SEARCH_SURROUNDING_PROPERTIES_ERROR:
      return error(state, action, 'comparable');

    case ActionType.SEARCH_GROUP_CONTEXT_SUCCESS:
      return success(mergeGroupResults(state, action.context, response));

    case ActionType.GET_GROUP_SEARCH_SUCCESS:
    case ActionType.RETRIEVE_PROPERTY_SUCCESS:
    case ActionType.DOWNLOAD_PROPERTY_DATA_SUCCESS:
    case ActionType.GET_SAVED_PROPERTY_DETAIL_SUCCESS:
    case ActionType.SEARCH_PROPERTY_CONTACTS_SUCCESS:
      return success(state);

    case ActionType.GET_GROUP_PROPERTY_COUNTS_SUCCESS:
      return mergePropertyCounts();

    case ActionType.SEARCH_GROUP_PROPERTIES_SUCCESS:
      return success(mergePropertyCounts());

    case ActionType.SAVE_GROUP_SEARCH_SUCCESS:
      return success(mergeGroups(state, response));

    case ActionType.DELETE_PROPERTY_GROUP_SUCCESS:
    case ActionType.SAVE_PROPERTIES_SUCCESS:
    case ActionType.SAVE_PROPERTY_GROUP_OPTIONS_SUCCESS:
    case ActionType.DELETE_GROUP_PROPERTIES_SUCCESS:
    case ActionType.SAVE_GROUP_PROPERTIES_SUCCESS:
    case ActionType.DELETE_SAVED_PROPERTY_SUCCESS:
    case SAVE_LISTINGS_SUCCESS:
      return success(mergeGroups(state, response.groups).merge({ groupCounts: {}, selection: defaultSelection }));

    case ActionType.SAVE_PROPERTY_FOLDER_SUCCESS:
    case ActionType.DELETE_PROPERTY_FOLDER_SUCCESS:
      return success(mergeGroups(state, response.groups).merge({ folders: response.folders || [] }));

    case ActionType.SAVE_PROPERTY_SUCCESS:
      return success(setProperty(mergeGroups(state, response.groups), response.property || null));

    case ActionType.SAVE_PROPERTY_FILTER_SUCCESS:
    case ActionType.DELETE_PROPERTY_FILTER_SUCCESS:
      return success(mergeGroups(state, response.groups)).merge({ filters: (response || {}).filters || [] });

    case ActionType.SAVE_PROPERTY_GROUP_FILTER_SUCCESS:
    case ActionType.DELETE_PROPERTY_GROUP_FILTER_SUCCESS:
      return success(state).merge({ searches: response || [], groupCounts: {} });

    case ActionType.SEARCH_GROUP_CONTEXT_CACHED:
      return searchGroup(state, action.context);

    case ActionType.LOAD_GROUP_CONTEXT:
      return loadGroupContext(state, action);

    case ActionType.UPDATE_GROUP_CONTEXT:
      return updateGroupContext(state, action.context);

    case ActionType.SELECT_GROUP_CONTEXT:
      return setGroupContextSelection(state, action);

    case ActionType.LOAD_GROUP_PUSHPINS_SUCCESS:
      return success(state.set('pushpins', fromJS(response.data)), 'pushpin');

    case ActionType.GET_PROPERTY_SUCCESS:
      return success(setProperty(state, response));

    case ActionType.SAVE_PROPERTY_STATUS_SUCCESS:
      return success(state.setIn(['property', 'status'], fromJS(response)));

    case ActionType.UPDATE_SURROUNDING_PROPERTY_SEARCH:
      return updateType(getType().merge({ search }), action.runSearch);

    case ActionType.RESET_SURROUNDING_PROPERTY_SEARCH: {
      const type = getType();

      return updateType(type.merge({
        search: {
          ...defaultSurroundingPropertyTypes[type.get('index')].search,
          ...(['COMP', 'FLIP'].includes(type.get('type')) ? getCompSearchDefaults(state.get('property')) : {}),
        },
      }), true);
    }

    case ActionType.SORT_SURROUNDING_PROPERTIES:
      return updateType(getType().set('sort', sort).update('properties', p => p.sort(getSortComparator(sort))));

    case ActionType.UPDATE_SURROUNDING_PROPERTY_SELECTION: {
      const type = getType();
      const selection = type.get('selection');

      // Toggle record selection for this type and update state.
      return updateType(type.update('properties', (props) => {
        // If an individual record was selected then grab its property ID. Any other records with the same property ID will be deselected.
        const propertyId = selected && index != null && props.getIn([index, 'propertyId']);

        return props.map((p, i) => {
          let sel = p.get('selected');
          const pid = p.get('propertyId');

          // If this is the record just clicked then simply update its selection status to the status passed in.
          if (i === index) sel = selected;

          // Deselect if this is a duplicate property of one just selected. (Blended comps can have 2 of the same property: PR & MLS)
          else if (propertyId === pid) sel = false;

          // If toggling all properties' selection status:
          // 1. Deselect if deselecting all records.
          // 2. If selecting all, do not select this record if there is already another record of the same property selected. This is to
          //   prevent overriding the user's current selection in cases where they have a non-preferred record currently selected. E.g.,
          //   in non-disclosure markets where the user currently has a PR record selected, that selection should be preserved instead of
          //   deselecting it and selecting the respective MLS record.
          else if (index == null) sel = selected && (sel || !selection.find(p => p.get('propertyId') === pid));

          return p.set('selected', sel);
        });
      }).set('selectionVersion', typeSelectionVersionCounter++));
    }

    case ActionType.UPDATE_SURROUNDING_PROPERTY_TYPE:
      return updateType(action.newType);

    case ActionType.SEARCH_COMPARABLES_SUCCESS:
      return success(setType(setTypeProperties(state, 0, response)), 'comparable');

    case ActionType.SEARCH_FLIP_COMPARABLES_SUCCESS:
      return success(setType(setTypeProperties(state, 1, response)), 'comparable');

    case ActionType.SEARCH_NEIGHBORS_SUCCESS:
      return success(setType(setTypeProperties(state, 2, response)), 'comparable');

    case ActionType.SEARCH_SURROUNDING_PROPERTIES_SUCCESS: {
      const types = (response.nearbyListings || []).reduce((m, l) => ({ ...m, [l.type]: (m[l.type] || []).concat([l]) }), {});

      let newState;
      if (action.listingType) newState = setTypeProperties(state, SurroundingTypes.findIndex(t => t.type === action.listingType) + 1, types[action.listingType]);
      else {
        newState = setTypeProperties(state, SurroundingTypeIndexes.FLIP, response.flipComparables);
        newState = setTypeProperties(newState, SurroundingTypeIndexes.NEIGHBOR, response.neighbors);
        SurroundingTypes.filter(f => !['FLIP', 'NEIGHBOR', 'LINKED'].includes(f.type)).forEach((f, i) => { newState = setTypeProperties(newState, SurroundingTypeIndexes.REMAINING + i, types[f.type]); });
      }

      return setType(newState);
    }

    case ActionType.DOWNLOAD_COMPARABLES_SUCCESS:
    case ActionType.EXPORT_COMPARABLES_SUCCESS:
    case ActionType.EXPORT_FLIP_COMPARABLES_SUCCESS:
    case ActionType.SAVE_SURROUNDING_SEARCH_SUCCESS:
      return success(state, 'comparable');

    case ActionType.LOAD_PROPERTY_DOCUMENTS:
    case ActionType.UPLOAD_PROPERTY_DOCUMENT:
    case ActionType.EDIT_PROPERTY_DOCUMENT:
    case ActionType.PURCHASE_REPORT:
    case ActionType.DOWNLOAD_PROPERTY_DOCUMENT:
    case ActionType.DOWNLOAD_PROPERTY_REPORT:
    case ActionType.DELETE_PROPERTY_DOCUMENT:
      return loading(state, 'document');

    case ActionType.LOAD_PROPERTY_DOCUMENTS_ERROR:
    case ActionType.UPLOAD_PROPERTY_DOCUMENT_ERROR:
    case ActionType.EDIT_PROPERTY_DOCUMENT_ERROR:
    case ActionType.PURCHASE_REPORT_ERROR:
    case ActionType.DOWNLOAD_PROPERTY_DOCUMENT_ERROR:
    case ActionType.DOWNLOAD_PROPERTY_REPORT_ERROR:
    case ActionType.DELETE_PROPERTY_DOCUMENT_ERROR:
      return error(state, action, 'document');

    case ActionType.LOAD_PROPERTY_DOCUMENTS_SUCCESS:
    case ActionType.UPLOAD_PROPERTY_DOCUMENT_SUCCESS:
    case ActionType.EDIT_PROPERTY_DOCUMENT_SUCCESS:
    case ActionType.PURCHASE_REPORT_SUCCESS:
    case ActionType.DELETE_PROPERTY_DOCUMENT_SUCCESS:
      return success(state.merge({ documents: response.map(d => ({ ...d, editable: d.typeDescription === 'Document' })) }), 'document');

    case ActionType.DOWNLOAD_PROPERTY_DOCUMENT_SUCCESS:
    case ActionType.DOWNLOAD_PROPERTY_REPORT_SUCCESS:
      return success(state, 'document');

    case ActionType.GET_STREET_VIEW:
      return loading(state, 'streetView');

    case ActionType.GET_STREET_VIEW_ERROR:
      return error(state, action, 'streetView');

    case ActionType.GET_STREET_VIEW_SUCCESS:
      return success(state.merge({ streetView: response || {} }), 'streetView');

    case ActionType.IMPORT_PROPERTIES:
    case ActionType.GET_IMPORT_JOBS:
    case ActionType.DELETE_IMPORT_JOB:
    case ActionType.DOWNLOAD_IMPORT_JOB_FILE:
    case ActionType.DOWNLOAD_IMPORT_JOB_REPORT:
      return loading(state, 'import');

    case ActionType.IMPORT_PROPERTIES_ERROR:
    case ActionType.GET_IMPORT_JOBS_ERROR:
    case ActionType.DELETE_IMPORT_JOB_ERROR:
    case ActionType.DOWNLOAD_IMPORT_JOB_FILE_ERROR:
    case ActionType.DOWNLOAD_IMPORT_JOB_REPORT_ERROR:
      return error(state, action, 'import');

    case ActionType.IMPORT_PROPERTIES_SUCCESS:
    case ActionType.GET_IMPORT_JOBS_SUCCESS:
    case ActionType.DELETE_IMPORT_JOB_SUCCESS: {
      const { importJobs = [], savePropertyResponse: { groups } = {} } = response;
      return success(mergeGroups(state.merge({ importJobs }), groups), 'import');
    }

    case SAVE_LAYOUT_SUCCESS: {
      const { propertyId, savedPropertyGroupId, propertyLayouts = [] } = response;

      const layout = (response.layouts || []).find(l => l.modified);
      const layoutId = (layout && layout.id) || null;

      let newState = state;
      if (propertyId && propertyId === state.getIn(['property', 'id'])) newState = state.setIn(['property', 'layouts'], fromJS(propertyLayouts));
      else if (savedPropertyGroupId) {
        // TODO: Make a function for updating group fields.
        const index = state.getIn(['groupInfo', savedPropertyGroupId, 'index']);
        newState = state.setIn(['groupInfo', savedPropertyGroupId, 'gridLayoutId'], layoutId).setIn(['groups', index, 'gridLayoutId'], layoutId);
      }

      return newState;
    }

    case ActionType.DOWNLOAD_IMPORT_JOB_FILE_SUCCESS:
    case ActionType.DOWNLOAD_IMPORT_JOB_REPORT_SUCCESS:
      return success(state, 'import');

    case ActionType.ASSIGN_PROPERTIES:
      return assignContext(state);

    case ActionType.UNASSIGN_PROPERTIES:
      return unassignContext(state);

    case SAVE_TAG_SUCCESS:
    case DELETE_TAG_SUCCESS:
      return state.set('tags', fromJS((response || []).filter(t => t.type === 'P')));

    default:
      return state;
  }
}