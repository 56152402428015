import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { selectPermissions } from 'data/user/selectors';

import css from './style.scss';

const RestrictedContent = ({ permission, permissions, children }) => {
  const hasPermission = () => {
    if (typeof permission === 'boolean') return permission;
    return permissions && permissions.contains(permission);
  };

  const renderChildren = () => {
    return React.Children.map(children, child => (
      React.cloneElement(child, {
        disabled: !hasPermission(),
      })
    ));
  };

  const handleClick = () => {
    if (!hasPermission()) alert('To use this feature you must upgrade to the full version.');
  };

  return (
    <div onClick={handleClick} className={css.restrictedContent}>
      {renderChildren()}
    </div>
  );
};

RestrictedContent.propTypes = {
  permission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
};

const mapStateToProps = state => ({
  permissions: selectPermissions(state),
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(RestrictedContent);
