/** @flow */
export default function isFullyVisible(element) {
  if (!element) return true;

  const rect = element.getBoundingClientRect();

  // Check if element is within the viewport
  const isWithinViewport = (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );

  // Check if element is within any scrollable container
  let parent = element.parentElement;
  while (parent) {
    const parentRect = parent.getBoundingClientRect();
    const overflowY = getComputedStyle(parent).overflowY;
    const overflowX = getComputedStyle(parent).overflowX;

    if ((overflowY === 'auto' || overflowY === 'scroll') && (rect.bottom > parentRect.bottom || rect.top < parentRect.top)) {
      return false;
    }

    if ((overflowX === 'auto' || overflowX === 'scroll') && (rect.right > parentRect.right || rect.left < parentRect.left)) {
      return false;
    }

    parent = parent.parentElement;
  }

  return isWithinViewport;
}
