import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Link from 'components/Link';
import config from 'config';
import ListManagementInfo from 'app/components/ListManagementInfo';
import { isLaAvailable } from 'utils/brand';

import css from './style.scss';


class ListAutomatorPopover extends PureComponent {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(ev) {
    const { checkSavedProperty } = this.props;
    const { target: form } = ev;
    const { constants: { API: { BASE_URL } } } = config;

    ev.preventDefault();
    ev.stopPropagation();

    checkSavedProperty((id) => {
      form.action = `${BASE_URL}/resource/auth/ps4/user/properties/${id}/bluebook/directaccess/url?redirect=true`;
      form.submit();
    });
  }

  render() {
    const { className, openListManagementInfo } = this.props;

    return !isLaAvailable() ? null : (
      <div className={classNames(css.lmOverlay, className)}>
        <div className={css.lmUpgrade}>
          Requires List Automator Upgrade - <Link onClick={openListManagementInfo} className={css.link}>Learn More</Link>
        </div>
      </div>
    );
  }
}

ListAutomatorPopover.propTypes = {
  className: PropTypes.string,
};

export default connect(null, { openListManagementInfo: ListManagementInfo.open })(ListAutomatorPopover);
