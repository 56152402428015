import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import SearchArrow from '../../../../../../assets/images/select-down-arrow.svg';
import SearchUpArrow from '../../../../../../assets/images/select-up-arrow-black.svg';
import css from "../style.scss";
import { v4 as uuidv4 } from 'uuid';
const AutoSearchDropdown = ({ items, placeHolder, onChangeValue , defaultValue , searchDisabled = false, allowNegativeValue = false , isPercent = false }) => {

  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState(items ? items : []);
  const InputContainerRef = useRef(null);
  const inputRef = useRef(null); // Create a ref for the input element

  useEffect(() => {
    const formatDefaultValue = () => {
      if (defaultValue === undefined || defaultValue === null || defaultValue === '') {
        return '';
      }
      return isPercent && defaultValue != '0' ?  String(defaultValue * 100) : String(defaultValue);
    };
  
    setSearchTerm(formatDefaultValue());
  }, [defaultValue, isPercent]);


  const handleInputChange = e => {
    if (!searchDisabled) { // Check if input is not disabled
      let value = allowNegativeValue ? e.target.value.replace(/(?!^-)-|[^\d-]/g, '') : e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      if (value === '-0') value = '0'; // Convert '-0' to '0'
      setSearchTerm(value);

      const numericValue = isPercent && value ? Number(value) / 100 : value;
      
      const searchValue = String(numericValue).toLowerCase();
      const filteredSuggestions = items?.filter(item =>
        String(item?.name)?.toLowerCase()?.includes(searchValue)
      );

      if (filteredSuggestions?.length) {
        setOptions(filteredSuggestions);
      } else {
        setIsFocused(false);
      }
      if (value !== '-') onChangeValue(numericValue); // check for valid characters
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputTabPress = (e) => {
    if (e.key === "Tab") {
      setIsFocused(false);
    }
  };

  const handleOutsideClick = e => {
    if (InputContainerRef.current && !InputContainerRef.current.contains(e.target)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const optionSelected = (option) => {
    const displayValue = isPercent ? String(option.value) : option.value;
    setSearchTerm(displayValue);
    setIsFocused(false);
    // Pass normalized value (e.g., 50% as 0.5)
    let normalizedValue = isPercent ? Number(option.value) / 100 : option.value;
    normalizedValue = String(normalizedValue)
    onChangeValue(normalizedValue);
  };

  const dropdownToggle = (ev) => {
    ev.stopPropagation();
    setIsFocused(!isFocused);
  
    if (!isFocused) {
      if (inputRef?.current) {
        inputRef?.current.focus();
      }
    }
  };

  return (
    <div className={classNames(css.clsSearchBox)} ref={InputContainerRef}>
      <input
        type="text"
        placeholder={placeHolder ? placeHolder  : 'select'}
        className={isFocused ? classNames(css.clsSearchInput, css.clsSearchInputActive) : css.clsSearchInput}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onKeyDown={handleInputTabPress}
        ref={inputRef}
        readOnly={searchDisabled}
        id={'newuifilterRef'+ uuidv4()}
      />
      <span className={classNames(css.clsAutoSearchArrow, css.clsAutoSearchRotate)}>
        <ReactSVG
          src={isFocused ? SearchUpArrow : SearchArrow}
          className={css.svgArrowIcon}
          onClick={dropdownToggle}
        />
      </span>

      {isFocused && (
        <div className={css.clsAutoSearchDropdown}>
          <ul className={css.clsAutoSearchList}>
            {options &&
              options.map((option, index) => (
                <li
                id={'newuifilterRef'+ uuidv4()}
                  key={index}
                  className={css.clsAutoSearchOption}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    optionSelected(option)
                  }}
                >
                  {option.label}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AutoSearchDropdown;