import { fromJS } from 'immutable';


export const MAP_NO_METRIC = 'none';

export const FAVORITE_SEARCH_SLOTS = 8;

export const RESULTS_PER_PAGE = 50;

export const ListingTypes = {
  Default: '',
  Neighbor: 'Neighbor',
  M: 'M',
  P: 'P',
  F: 'F',
};

export const SearchModes = {
  Listing: 'Listing',
  Property: 'Property',
  Multiple: 'Multiple',
};

export const SearchLayouts = {
  Picture: 'Picture',
  Grid: 'Grid',
};

export const SearchOverlays = {
  Property: 'Property',
  Graph: 'Graph',
};

export const StatisticPeriods = [
  { label: 'Last 30 Days', value: 'ONE_MONTH' },
  { label: 'Last 90 Days', value: 'THREE_MONTH' },
  { label: 'Last 6 Months', value: 'SIX_MONTH' },
  { label: 'Last Year', value: 'ONE_YEAR' },
];

export const StatisticColors = {
  'List Price': '#109e2f',
  'Sale Price': '#9b9b9b',
  'Days on Market': '#109e2f',
  Inventory: '#9b9b9b',
};

export const StatisticUnitFormats = {
  number: 'number',
  percent: 'percent',
  currency: 'currency',
};

export const SearchNotificationIntervals = {
  NEVER: { apiValue: null, value: 'NEVER', label: 'Never' },
  IMMEDIATE: { apiValue: 'IMMEDIATE', value: 'IMMEDIATE', label: 'Immediately' },
  DAILY: { apiValue: 'DAILY', value: 'DAILY', label: 'Daily' },
  WEEKLY: { apiValue: 'WEEKLY', value: 'WEEKLY', label: 'Weekly' },
};


const Neighbors = 'NEIGHBORS';

export const SearchTypeCustom = 'CUSTOM';
export const SearchFullTable = 'FULL';
export const SearchLiensTable = 'LIENS';
export const SearchDefaultColor = 'DEFAULT_COLOR';
export const SearchTypeDefault = 'DEFAULT';

export const SearchColors = {
  M: '#FFA715',
  P: '#FFDC55',
  A: '#7CB247',
  F: '#39D4A9',
  C: '#0FB474',
  V: '#AF66BD',
  E: '#8360EF',
  R: '#666666',
  L: '#FF6060',
  D: '#B01024',
  B: '#DD33FF',
  [Neighbors]: '#7FEAFF',
  [SearchDefaultColor]: '#666666',
};

export const SearchDataType = {
  PRICE: 'PRICE',
  DATE: 'DATE',
  NONE: null,
};

export const SearchFields = {
  BLANK: '_blank',
  BLANK2: '_blank2',
  TYPE: 'type',
  ADDRESS: 'address',
  UNIT: 'unitNumber',
  ADDRESS_FIELD_NAME: 'streetAddress',
  BEDROOMS: 'bedrooms',
  BATHROOMS: 'bathrooms',
  LISTING_TYPE: 'listingType',
  LISTING_PRICE: 'listingAmount',
  LISTING_DATE: 'listingDate',
  AUCTION_DATE: 'auctionDate',
  STATUS: 'status',
  DOCUMENT_TYPE: 'documentType',
  DEFAULT_AMOUNT: 'defaultAmount',
  OPENING_BID_AMOUNT: 'openingBidAmount',
  OPEN_MORTGAGE_BALANCE: 'openMortgageBalance',
  BANKRUPTCY_CHAPTER: 'bankruptcyChapter',
  LISTING_AMOUNT: 'listingAmount',
  UNPAID_BALANCE: 'unpaidBalance',
  UPDATE_DATE: 'listingDate',
  ESTIMATE_VALUE: 'estimatedValue',
  ESTIMATE_EQUITY: 'estimatedEquity',
  SALE_PRICE: 'lastSaleAmount',
  SALE_DATE: 'lastSaleDate',
  CITY: 'address.cityName',
  CITY_FIELD_NAME: 'cityName',
  STATE: 'address.stateCode',
  STATE_FIELD_NAME: 'stateCode',
  ZIP: 'address.zip',
  ZIP_FIELD_NAME: 'zip',
  APN: 'apn',
  APN_FIELD_NAME: 'apn',
  SQUARE_FEET: 'squareFeet',
  SQUARE_FEET_FIELD_NAME: 'squareFeet',
  PROPERTY_TYPE: 'propertyType',
  PROPERTY_TYPE_FIELD_NAME: 'propertyTypeCode',
  LAND_USE: 'landUse',
  LAND_USE_FIELD_NAME: 'landUse',
  COUNTER: 'resultIndex',
  LIEN_TYPE: 'lienDocumentType',
  LIEN_DATE: 'listingDate',
  LIEN_AMOUNT: 'listingAmount',
  MLS_LISTING_DATE: 'mlsListingDate',
  MLS_LISTING_DATE_FORMATTED: 'mlsListingDateFormatted',
  MLS_LISTING_AMOUNT: 'mlsListingAmount',
};

export const SearchNames = {
  M: 'MLS',
  P: 'Pre-Foreclosure',
  F: 'Foreclosure',
  L: 'Lien',
  C: 'Cash Buyer',
  E: 'High Equity',
  V: 'Vacant',
  R: 'Free & Clear',
  B: 'Bankruptcy',
  D: 'Divorce',
  A: 'Auction',
  FAIL: 'Failed',
  SOLD: 'Recently Sold',
  [SearchTypeCustom]: 'Custom',
};

export const SearchTypes = {
  M: 'M',
  P: 'P',
  F: 'F',
  L: 'L',
  C: 'C',
  E: 'E',
  I: 'I',
  V: 'V',
  R: 'R',
  B: 'B',
  D: 'D',
  A: 'A',
  X: 'X',
  Z: 'Z',
//  W: 'W',
};

export const MlsListingStatus = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Contingent', value: 'CONTINGENT' },
  { label: 'Failed', value: 'FAIL' },
  { label: 'Sold', value: 'SOLD' },
  { label: 'Never Listed', value: 'NONE' },
];

export const LienTypeCode = [
  { label: 'Tax Lien', value: 'TAX' },
  { label: 'HOA Lien', value: 'HOA' },
  { label: 'Mechanics Lien', value: 'MECHANIC' },
  { label: 'Utility Lien', value: 'UTILITY' },
  { label: 'Solar Lien', value: 'SOLAR' },
  { label: 'Child Support Lien', value: 'CHILD' },
  { label: 'Other', value: 'OTHER' },
];

export const EstimatedValueGrowthPeriod = [
  { label: 'One Month', value: 'ONE_MONTH' },
  { label: '3 Months', value: 'THREE_MONTH' },
  { label: '6 Months', value: 'SIX_MONTH' },
  { label: '12 Months', value: 'ONE_YEAR' },
  { label: '18 Months', value: 'EIGHTEEN_MONTH' },
  { label: '24 Months', value: 'TWO_YEAR' },
];

export const ForeclosureStatusOptions = [
  { label: 'Notice of Default / Lis Pendens', value: 'PRE_FORECLOSURE' },
  { label: 'Auctions', value: 'AUCTION' },
  { label: 'Default Releases', value: 'RECISSION' },
  { label: 'Bank Owned', value: 'FORECLOSURE' },
  { label: 'Exclude Foreclosures', value: 'NONE' },
];

export const LoanTypes = [
  { label: 'Conventional', value: 'CONVENTIONAL' },
  { label: 'FHA', value: 'FHA' },
  { label: 'VA', value: 'VA' },
  { label: 'Credit Line', value: 'CREDIT' },
  { label: 'Reverse Mortgage', value: 'REVERSE' },
  { label: 'Commercial', value: 'COMMERCIAL' },
  { label: 'Construction', value: 'CONSTRUCTION' },
  { label: 'Other', value: 'OTHER' },
  { label: 'Unknown', value: 'UNKNOWN' },
];

export const FinancingTypes = [
  { label: 'Fixed Rate', value: 'FIXED' },
  { label: 'Adjustable Rate', value: 'ADJUSTABLE' },
  { label: 'Variable Rate', value: 'VARIABLE' },
  { label: 'Step Interest Rate', value: 'STEP' },
  { label: 'Other', value: 'OTHER' },
  { label: 'Unknown', value: 'UNKNOWN' },
];

export const BankruptcyChapterOptions = [
  { value: '7', label: '7' },
  { value: '9', label: '9' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '15', label: '15' },
];

export const PreForeclosureStatusOptions = [
  { label: 'Notice of Default / Lis Pendens', value: 'PRE_FORECLOSURE' },
  { label: 'Auctions', value: 'AUCTION' },
];

export const MlsListingStatusNoneList = fromJS([{ label: 'Off Market', value: 'NONE' }].concat(MlsListingStatus));


export const SearchTables = {
  M: [
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'LISTING_PRICE',
    'LISTING_DATE',
    'STATUS',
  ],
  P: [
    'DOCUMENT_TYPE',
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'DEFAULT_AMOUNT',
    'UPDATE_DATE',
  ],
  F: [
    'DOCUMENT_TYPE',
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'DEFAULT_AMOUNT',
    'UPDATE_DATE',
  ],
  C: [
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'ESTIMATE_VALUE',
    'SALE_PRICE',
    'SALE_DATE',
  ],
  V: [
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'ESTIMATE_VALUE',
    'ESTIMATE_EQUITY',
    'SALE_DATE',
  ],
  E: [
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'ESTIMATE_VALUE',
    'ESTIMATE_EQUITY',
    'SALE_DATE',
  ],
  R: [
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'ESTIMATE_VALUE',
    'SALE_PRICE',
    'SALE_DATE',
  ],
  A: [
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'ESTIMATE_VALUE',
    'DEFAULT_AMOUNT',
    'UPDATE_DATE',
  ],
  L: [
    'LIEN_TYPE',
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'LIEN_AMOUNT',
    'LIEN_DATE',
  ],
  [SearchTypeDefault]: [
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'ESTIMATE_VALUE',
    'ESTIMATE_EQUITY',
    'SALE_DATE',
  ],
  [SearchTypeCustom]: [
    'ADDRESS',
    'BEDROOMS',
    'BATHROOMS',
    'ESTIMATE_VALUE',
    'SALE_PRICE',
    'SALE_DATE',
  ],
  [SearchFullTable]: [
    'TYPE',
    'ADDRESS',
    'CITY',
    'STATE',
    'ZIP',
    'APN',
    'BEDROOMS',
    'BATHROOMS',
    'LAND_USE',
    'ESTIMATE_VALUE',
    'MLS_LISTING_DATE',
    'MLS_LISTING_AMOUNT',
    'STATUS',
    'DOCUMENT_TYPE',
    'DEFAULT_AMOUNT',
    'UPDATE_DATE',
    'SALE_PRICE',
    'SALE_DATE',
    'ESTIMATE_EQUITY',
  ],
  [SearchLiensTable]: [
    'LIEN_TYPE',
    'ADDRESS',
    'CITY',
    'STATE',
    'ZIP',
    'BEDROOMS',
    'BATHROOMS',
    'LAND_USE',
    'ESTIMATE_VALUE',
    'LIEN_AMOUNT',
    'LIEN_DATE',
    'STATUS',
    'DOCUMENT_TYPE',
    'DEFAULT_AMOUNT',
    'UPDATE_DATE',
    'SALE_PRICE',
    'SALE_DATE',
    'ESTIMATE_EQUITY',
  ],
};

export const PicViewFields = {
  M: [
    {
      label: 'Price',
      value: 'LISTING_PRICE',
      type: 'PRICE',
    },
    {
      label: 'Status Date',
      value: 'LISTING_DATE',
      type: 'DATE',
    },
    {
      label: 'Status',
      value: 'STATUS',
      type: 'NONE',
    },
  ],
  F: [
    {
      label: '',
      value: 'BLANK',
      type: 'NONE',
    },
    {
      label: 'Sale Price',
      value: 'SALE_PRICE',
      type: 'PRICE',
    },
    {
      label: 'Recording Date',
      value: 'SALE_DATE',
      type: 'DATE',
    },
  ],
  A: [
    {
      label: 'Doc. Type',
      value: 'DOCUMENT_TYPE',
      type: 'NONE',
    },
    {
      label: 'Opening Bid',
      value: 'OPENING_BID_AMOUNT',
      type: 'PRICE',
    },
    {
      label: 'Auction Date',
      value: 'AUCTION_DATE',
      type: 'DATE',
    },
  ],
  P: [
    {
      label: 'Doc. Type',
      value: 'DOCUMENT_TYPE',
      type: 'NONE',
    },
    {
      label: 'Unpaid Balance',
      value: 'UNPAID_BALANCE',
      type: 'PRICE',
    },
    {
      label: 'Recording Date',
      value: 'UPDATE_DATE',
      type: 'DATE',
    },
  ],
  C: [
    {
      label: '',
      value: 'BLANK',
      type: 'PRICE',
    },
    {
      label: 'Sale Price',
      value: 'SALE_PRICE',
      type: 'PRICE',
    },
    {
      label: 'Recording Date',
      value: 'SALE_DATE',
      type: 'DATE',
    },
  ],
  R: [
    {
      label: '',
      value: 'BLANK',
      type: 'NONE',
    },
    {
      label: '',
      value: 'BLANK2',
      type: 'NONE',
    },
    {
      label: 'Last Sale Date',
      value: 'SALE_DATE',
      type: 'DATE',
    },
  ],
  E: [
    {
      label: 'Loan Balance',
      value: 'OPEN_MORTGAGE_BALANCE',
      type: 'PRICE',
    },
    {
      label: 'Est. Equity',
      value: 'ESTIMATE_EQUITY',
      type: 'PRICE',
    },
    {
      label: 'Last Sale Date',
      value: 'SALE_DATE',
      type: 'DATE',
    },
  ],
  V: [
    {
      label: 'Est. Loan Balance',
      value: 'OPEN_MORTGAGE_BALANCE',
      type: 'PRICE',
    },
    {
      label: 'Est. Equity',
      value: 'ESTIMATE_EQUITY',
      type: 'PRICE',
    },
    {
      label: 'Last Sale Date',
      value: 'SALE_DATE',
      type: 'DATE',
    },
  ],
  L: [
    {
      label: 'Lien Type',
      value: 'LIEN_TYPE',
      type: 'NONE',
    },
    {
      label: 'Lien Amount',
      value: 'LISTING_PRICE',
      type: 'PRICE',
    },
    {
      label: 'Lien Date',
      value: 'LISTING_DATE',
      type: 'DATE',
    },
  ],
  B: [
    {
      label: 'BK Recording Date',
      value: 'LISTING_DATE',
      type: 'DATE',
    },
    {
      label: 'Bankruptcy Chapter',
      value: 'BANKRUPTCY_CHAPTER',
      type: 'NONE',
    },
    {
      label: 'Last Sale Date',
      value: 'SALE_DATE',
      type: 'DATE',
    },
  ],
  D: [
    {
      label: 'Recording Date',
      value: 'LISTING_DATE',
      type: 'DATE',
    },
    {
      label: 'Est. Equity',
      value: 'ESTIMATE_EQUITY',
      type: 'PRICE',
    },
    {
      label: 'Last Sale Date',
      value: 'SALE_DATE',
      type: 'DATE',
    },
  ],
  [SearchTypeCustom]: [
    {
      label: 'Est. Loan Balance',
      value: 'OPEN_MORTGAGE_BALANCE',
      type: 'PRICE',
    },
    {
      label: 'Est. Equity',
      value: 'ESTIMATE_EQUITY',
      type: 'PRICE',
    },
    {
      label: 'Last Sale Date',
      value: 'SALE_DATE',
      type: 'DATE',
    },
  ],
};

// Failed listings result fields are the same as MLS
PicViewFields.Z = PicViewFields.M;


const countyData = [
  ['01001', 'AL', 'Autauga'],
  ['01003', 'AL', 'Baldwin'],
  ['01005', 'AL', 'Barbour'],
  ['01007', 'AL', 'Bibb'],
  ['01009', 'AL', 'Blount'],
  ['01011', 'AL', 'Bullock'],
  ['01013', 'AL', 'Butler'],
  ['01015', 'AL', 'Calhoun'],
  ['01017', 'AL', 'Chambers'],
  ['01019', 'AL', 'Cherokee'],
  ['01021', 'AL', 'Chilton'],
  ['01023', 'AL', 'Choctaw'],
  ['01025', 'AL', 'Clarke'],
  ['01027', 'AL', 'Clay'],
  ['01029', 'AL', 'Cleburne'],
  ['01031', 'AL', 'Coffee'],
  ['01033', 'AL', 'Colbert'],
  ['01035', 'AL', 'Conecuh'],
  ['01037', 'AL', 'Coosa'],
  ['01039', 'AL', 'Covington'],
  ['01041', 'AL', 'Crenshaw'],
  ['01043', 'AL', 'Cullman'],
  ['01045', 'AL', 'Dale'],
  ['01047', 'AL', 'Dallas'],
  ['01049', 'AL', 'DeKalb'],
  ['01051', 'AL', 'Elmore'],
  ['01053', 'AL', 'Escambia'],
  ['01055', 'AL', 'Etowah'],
  ['01057', 'AL', 'Fayette'],
  ['01059', 'AL', 'Franklin'],
  ['01061', 'AL', 'Geneva'],
  ['01063', 'AL', 'Greene'],
  ['01065', 'AL', 'Hale'],
  ['01067', 'AL', 'Henry'],
  ['01069', 'AL', 'Houston'],
  ['01071', 'AL', 'Jackson'],
  ['01073', 'AL', 'Jefferson'],
  ['01075', 'AL', 'Lamar'],
  ['01077', 'AL', 'Lauderdale'],
  ['01079', 'AL', 'Lawrence'],
  ['01081', 'AL', 'Lee'],
  ['01083', 'AL', 'Limestone'],
  ['01085', 'AL', 'Lowndes'],
  ['01087', 'AL', 'Macon'],
  ['01089', 'AL', 'Madison'],
  ['01091', 'AL', 'Marengo'],
  ['01093', 'AL', 'Marion'],
  ['01095', 'AL', 'Marshall'],
  ['01097', 'AL', 'Mobile'],
  ['01099', 'AL', 'Monroe'],
  ['01101', 'AL', 'Montgomery'],
  ['01103', 'AL', 'Morgan'],
  ['01105', 'AL', 'Perry'],
  ['01107', 'AL', 'Pickens'],
  ['01109', 'AL', 'Pike'],
  ['01111', 'AL', 'Randolph'],
  ['01113', 'AL', 'Russell'],
  ['01115', 'AL', 'St. Clair'],
  ['01117', 'AL', 'Shelby'],
  ['01119', 'AL', 'Sumter'],
  ['01121', 'AL', 'Talladega'],
  ['01123', 'AL', 'Tallapoosa'],
  ['01125', 'AL', 'Tuscaloosa'],
  ['01127', 'AL', 'Walker'],
  ['01129', 'AL', 'Washington'],
  ['01131', 'AL', 'Wilcox'],
  ['01133', 'AL', 'Winston'],
  ['02013', 'AK', 'Aleutians East Borough', 0],
  ['02016', 'AK', 'Aleutians West Census Area', 0],
  ['02020', 'AK', 'Anchorage Municipality', 0],
  ['02050', 'AK', 'Bethel Census Area', 0],
  ['02060', 'AK', 'Bristol Bay Borough', 0],
  ['02068', 'AK', 'Denali Borough', 0],
  ['02070', 'AK', 'Dillingham Census Area', 0],
  ['02090', 'AK', 'Fairbanks North Star Borough', 0],
  ['02100', 'AK', 'Haines Borough', 0],
  ['02105', 'AK', 'Hoonah-Angoon Census Area', 0],
  ['02110', 'AK', 'Juneau and Borough', 0],
  ['02122', 'AK', 'Kenai Peninsula Borough', 0],
  ['02130', 'AK', 'Ketchikan Gateway Borough', 0],
  ['02150', 'AK', 'Kodiak Island Borough', 0],
  ['02158', 'AK', 'Kusilvak Census Area', 0],
  ['02164', 'AK', 'Lake and Peninsula Borough', 0],
  ['02170', 'AK', 'Matanuska-Susitna Borough', 0],
  ['02180', 'AK', 'Nome Census Area', 0],
  ['02185', 'AK', 'North Slope Borough', 0],
  ['02188', 'AK', 'Northwest Arctic Borough', 0],
  ['02195', 'AK', 'Petersburg Census Area', 0],
  ['02198', 'AK', 'Prince of Wales-Hyder Census Area', 0],
  ['02220', 'AK', 'Sitka and Borough', 0],
  ['02230', 'AK', 'Skagway Municipality', 0],
  ['02240', 'AK', 'Southeast Fairbanks Census Area', 0],
  ['02261', 'AK', 'Valdez-Cordova Census Area', 0],
  ['02270', 'AK', 'Wade Hampton Census Area', 0],
  ['02275', 'AK', 'Wrangell and Borough', 0],
  ['02282', 'AK', 'Yakutat and Borough', 0],
  ['02290', 'AK', 'Yukon-Koyukuk Census Area', 0],
  ['04001', 'AZ', 'Apache'],
  ['04003', 'AZ', 'Cochise'],
  ['04005', 'AZ', 'Coconino'],
  ['04007', 'AZ', 'Gila'],
  ['04009', 'AZ', 'Graham'],
  ['04011', 'AZ', 'Greenlee'],
  ['04012', 'AZ', 'La Paz'],
  ['04013', 'AZ', 'Maricopa'],
  ['04015', 'AZ', 'Mohave'],
  ['04017', 'AZ', 'Navajo'],
  ['04019', 'AZ', 'Pima'],
  ['04021', 'AZ', 'Pinal'],
  ['04023', 'AZ', 'Santa Cruz'],
  ['04025', 'AZ', 'Yavapai'],
  ['04027', 'AZ', 'Yuma'],
  ['05001', 'AR', 'Arkansas'],
  ['05003', 'AR', 'Ashley'],
  ['05005', 'AR', 'Baxter'],
  ['05007', 'AR', 'Benton'],
  ['05009', 'AR', 'Boone'],
  ['05011', 'AR', 'Bradley'],
  ['05013', 'AR', 'Calhoun'],
  ['05015', 'AR', 'Carroll'],
  ['05017', 'AR', 'Chicot'],
  ['05019', 'AR', 'Clark'],
  ['05021', 'AR', 'Clay'],
  ['05023', 'AR', 'Cleburne'],
  ['05025', 'AR', 'Cleveland'],
  ['05027', 'AR', 'Columbia'],
  ['05029', 'AR', 'Conway'],
  ['05031', 'AR', 'Craighead'],
  ['05033', 'AR', 'Crawford'],
  ['05035', 'AR', 'Crittenden'],
  ['05037', 'AR', 'Cross'],
  ['05039', 'AR', 'Dallas'],
  ['05041', 'AR', 'Desha'],
  ['05043', 'AR', 'Drew'],
  ['05045', 'AR', 'Faulkner'],
  ['05047', 'AR', 'Franklin'],
  ['05049', 'AR', 'Fulton'],
  ['05051', 'AR', 'Garland'],
  ['05053', 'AR', 'Grant'],
  ['05055', 'AR', 'Greene'],
  ['05057', 'AR', 'Hempstead'],
  ['05059', 'AR', 'Hot Spring'],
  ['05061', 'AR', 'Howard'],
  ['05063', 'AR', 'Independence'],
  ['05065', 'AR', 'Izard'],
  ['05067', 'AR', 'Jackson'],
  ['05069', 'AR', 'Jefferson'],
  ['05071', 'AR', 'Johnson'],
  ['05073', 'AR', 'Lafayette'],
  ['05075', 'AR', 'Lawrence'],
  ['05077', 'AR', 'Lee'],
  ['05079', 'AR', 'Lincoln'],
  ['05081', 'AR', 'Little River'],
  ['05083', 'AR', 'Logan'],
  ['05085', 'AR', 'Lonoke'],
  ['05087', 'AR', 'Madison'],
  ['05089', 'AR', 'Marion'],
  ['05091', 'AR', 'Miller'],
  ['05093', 'AR', 'Mississippi'],
  ['05095', 'AR', 'Monroe'],
  ['05097', 'AR', 'Montgomery'],
  ['05099', 'AR', 'Nevada'],
  ['05101', 'AR', 'Newton'],
  ['05103', 'AR', 'Ouachita'],
  ['05105', 'AR', 'Perry'],
  ['05107', 'AR', 'Phillips'],
  ['05109', 'AR', 'Pike'],
  ['05111', 'AR', 'Poinsett'],
  ['05113', 'AR', 'Polk'],
  ['05115', 'AR', 'Pope'],
  ['05117', 'AR', 'Prairie'],
  ['05119', 'AR', 'Pulaski'],
  ['05121', 'AR', 'Randolph'],
  ['05123', 'AR', 'St. Francis'],
  ['05125', 'AR', 'Saline'],
  ['05127', 'AR', 'Scott'],
  ['05129', 'AR', 'Searcy'],
  ['05131', 'AR', 'Sebastian'],
  ['05133', 'AR', 'Sevier'],
  ['05135', 'AR', 'Sharp'],
  ['05137', 'AR', 'Stone'],
  ['05139', 'AR', 'Union'],
  ['05141', 'AR', 'Van Buren'],
  ['05143', 'AR', 'Washington'],
  ['05145', 'AR', 'White'],
  ['05147', 'AR', 'Woodruff'],
  ['05149', 'AR', 'Yell'],
  ['06001', 'CA', 'Alameda'],
  ['06003', 'CA', 'Alpine'],
  ['06005', 'CA', 'Amador'],
  ['06007', 'CA', 'Butte'],
  ['06009', 'CA', 'Calaveras'],
  ['06011', 'CA', 'Colusa'],
  ['06013', 'CA', 'Contra Costa'],
  ['06015', 'CA', 'Del Norte'],
  ['06017', 'CA', 'El Dorado'],
  ['06019', 'CA', 'Fresno'],
  ['06021', 'CA', 'Glenn'],
  ['06023', 'CA', 'Humboldt'],
  ['06025', 'CA', 'Imperial'],
  ['06027', 'CA', 'Inyo'],
  ['06029', 'CA', 'Kern'],
  ['06031', 'CA', 'Kings'],
  ['06033', 'CA', 'Lake'],
  ['06035', 'CA', 'Lassen'],
  ['06037', 'CA', 'Los Angeles'],
  ['06039', 'CA', 'Madera'],
  ['06041', 'CA', 'Marin'],
  ['06043', 'CA', 'Mariposa'],
  ['06045', 'CA', 'Mendocino'],
  ['06047', 'CA', 'Merced'],
  ['06049', 'CA', 'Modoc'],
  ['06051', 'CA', 'Mono'],
  ['06053', 'CA', 'Monterey'],
  ['06055', 'CA', 'Napa'],
  ['06057', 'CA', 'Nevada'],
  ['06059', 'CA', 'Orange'],
  ['06061', 'CA', 'Placer'],
  ['06063', 'CA', 'Plumas'],
  ['06065', 'CA', 'Riverside'],
  ['06067', 'CA', 'Sacramento'],
  ['06069', 'CA', 'San Benito'],
  ['06071', 'CA', 'San Bernardino'],
  ['06073', 'CA', 'San Diego'],
  ['06075', 'CA', 'San Francisco'],
  ['06077', 'CA', 'San Joaquin'],
  ['06079', 'CA', 'San Luis Obispo'],
  ['06081', 'CA', 'San Mateo'],
  ['06083', 'CA', 'Santa Barbara'],
  ['06085', 'CA', 'Santa Clara'],
  ['06087', 'CA', 'Santa Cruz'],
  ['06089', 'CA', 'Shasta'],
  ['06091', 'CA', 'Sierra'],
  ['06093', 'CA', 'Siskiyou'],
  ['06095', 'CA', 'Solano'],
  ['06097', 'CA', 'Sonoma'],
  ['06099', 'CA', 'Stanislaus'],
  ['06101', 'CA', 'Sutter'],
  ['06103', 'CA', 'Tehama'],
  ['06105', 'CA', 'Trinity'],
  ['06107', 'CA', 'Tulare'],
  ['06109', 'CA', 'Tuolumne'],
  ['06111', 'CA', 'Ventura'],
  ['06113', 'CA', 'Yolo'],
  ['06115', 'CA', 'Yuba'],
  ['08001', 'CO', 'Adams'],
  ['08003', 'CO', 'Alamosa'],
  ['08005', 'CO', 'Arapahoe'],
  ['08007', 'CO', 'Archuleta'],
  ['08009', 'CO', 'Baca'],
  ['08011', 'CO', 'Bent'],
  ['08013', 'CO', 'Boulder'],
  ['08014', 'CO', 'Broomfield'],
  ['08015', 'CO', 'Chaffee'],
  ['08017', 'CO', 'Cheyenne'],
  ['08019', 'CO', 'Clear Creek'],
  ['08021', 'CO', 'Conejos'],
  ['08023', 'CO', 'Costilla'],
  ['08025', 'CO', 'Crowley'],
  ['08027', 'CO', 'Custer'],
  ['08029', 'CO', 'Delta'],
  ['08031', 'CO', 'Denver'],
  ['08033', 'CO', 'Dolores'],
  ['08035', 'CO', 'Douglas'],
  ['08037', 'CO', 'Eagle'],
  ['08039', 'CO', 'Elbert'],
  ['08041', 'CO', 'El Paso'],
  ['08043', 'CO', 'Fremont'],
  ['08045', 'CO', 'Garfield'],
  ['08047', 'CO', 'Gilpin'],
  ['08049', 'CO', 'Grand'],
  ['08051', 'CO', 'Gunnison'],
  ['08053', 'CO', 'Hinsdale'],
  ['08055', 'CO', 'Huerfano'],
  ['08057', 'CO', 'Jackson'],
  ['08059', 'CO', 'Jefferson'],
  ['08061', 'CO', 'Kiowa'],
  ['08063', 'CO', 'Kit Carson'],
  ['08065', 'CO', 'Lake'],
  ['08067', 'CO', 'La Plata'],
  ['08069', 'CO', 'Larimer'],
  ['08071', 'CO', 'Las Animas'],
  ['08073', 'CO', 'Lincoln'],
  ['08075', 'CO', 'Logan'],
  ['08077', 'CO', 'Mesa'],
  ['08079', 'CO', 'Mineral'],
  ['08081', 'CO', 'Moffat'],
  ['08083', 'CO', 'Montezuma'],
  ['08085', 'CO', 'Montrose'],
  ['08087', 'CO', 'Morgan'],
  ['08089', 'CO', 'Otero'],
  ['08091', 'CO', 'Ouray'],
  ['08093', 'CO', 'Park'],
  ['08095', 'CO', 'Phillips'],
  ['08097', 'CO', 'Pitkin'],
  ['08099', 'CO', 'Prowers'],
  ['08101', 'CO', 'Pueblo'],
  ['08103', 'CO', 'Rio Blanco'],
  ['08105', 'CO', 'Rio Grande'],
  ['08107', 'CO', 'Routt'],
  ['08109', 'CO', 'Saguache'],
  ['08111', 'CO', 'San Juan'],
  ['08113', 'CO', 'San Miguel'],
  ['08115', 'CO', 'Sedgwick'],
  ['08117', 'CO', 'Summit'],
  ['08119', 'CO', 'Teller'],
  ['08121', 'CO', 'Washington'],
  ['08123', 'CO', 'Weld'],
  ['08125', 'CO', 'Yuma'],
  ['09001', 'CT', 'Fairfield'],
  ['09003', 'CT', 'Hartford'],
  ['09005', 'CT', 'Litchfield'],
  ['09007', 'CT', 'Middlesex'],
  ['09009', 'CT', 'New Haven'],
  ['09011', 'CT', 'New London'],
  ['09013', 'CT', 'Tolland'],
  ['09015', 'CT', 'Windham'],
  ['10001', 'DE', 'Kent'],
  ['10003', 'DE', 'New Castle'],
  ['10005', 'DE', 'Sussex'],
  ['11001', 'DC', 'District of Columbia', 0],
  ['12001', 'FL', 'Alachua'],
  ['12003', 'FL', 'Baker'],
  ['12005', 'FL', 'Bay'],
  ['12007', 'FL', 'Bradford'],
  ['12009', 'FL', 'Brevard'],
  ['12011', 'FL', 'Broward'],
  ['12013', 'FL', 'Calhoun'],
  ['12015', 'FL', 'Charlotte'],
  ['12017', 'FL', 'Citrus'],
  ['12019', 'FL', 'Clay'],
  ['12021', 'FL', 'Collier'],
  ['12023', 'FL', 'Columbia'],
  ['12027', 'FL', 'DeSoto'],
  ['12029', 'FL', 'Dixie'],
  ['12031', 'FL', 'Duval'],
  ['12033', 'FL', 'Escambia'],
  ['12035', 'FL', 'Flagler'],
  ['12037', 'FL', 'Franklin'],
  ['12039', 'FL', 'Gadsden'],
  ['12041', 'FL', 'Gilchrist'],
  ['12043', 'FL', 'Glades'],
  ['12045', 'FL', 'Gulf'],
  ['12047', 'FL', 'Hamilton'],
  ['12049', 'FL', 'Hardee'],
  ['12051', 'FL', 'Hendry'],
  ['12053', 'FL', 'Hernando'],
  ['12055', 'FL', 'Highlands'],
  ['12057', 'FL', 'Hillsborough'],
  ['12059', 'FL', 'Holmes'],
  ['12061', 'FL', 'Indian River'],
  ['12063', 'FL', 'Jackson'],
  ['12065', 'FL', 'Jefferson'],
  ['12067', 'FL', 'Lafayette'],
  ['12069', 'FL', 'Lake'],
  ['12071', 'FL', 'Lee'],
  ['12073', 'FL', 'Leon'],
  ['12075', 'FL', 'Levy'],
  ['12077', 'FL', 'Liberty'],
  ['12079', 'FL', 'Madison'],
  ['12081', 'FL', 'Manatee'],
  ['12083', 'FL', 'Marion'],
  ['12085', 'FL', 'Martin'],
  ['12086', 'FL', 'Miami-Dade'],
  ['12087', 'FL', 'Monroe'],
  ['12089', 'FL', 'Nassau'],
  ['12091', 'FL', 'Okaloosa'],
  ['12093', 'FL', 'Okeechobee'],
  ['12095', 'FL', 'Orange'],
  ['12097', 'FL', 'Osceola'],
  ['12099', 'FL', 'Palm Beach'],
  ['12101', 'FL', 'Pasco'],
  ['12103', 'FL', 'Pinellas'],
  ['12105', 'FL', 'Polk'],
  ['12107', 'FL', 'Putnam'],
  ['12109', 'FL', 'St. Johns'],
  ['12111', 'FL', 'St. Lucie'],
  ['12113', 'FL', 'Santa Rosa'],
  ['12115', 'FL', 'Sarasota'],
  ['12117', 'FL', 'Seminole'],
  ['12119', 'FL', 'Sumter'],
  ['12121', 'FL', 'Suwannee'],
  ['12123', 'FL', 'Taylor'],
  ['12125', 'FL', 'Union'],
  ['12127', 'FL', 'Volusia'],
  ['12129', 'FL', 'Wakulla'],
  ['12131', 'FL', 'Walton'],
  ['12133', 'FL', 'Washington'],
  ['13001', 'GA', 'Appling'],
  ['13003', 'GA', 'Atkinson'],
  ['13005', 'GA', 'Bacon'],
  ['13007', 'GA', 'Baker'],
  ['13009', 'GA', 'Baldwin'],
  ['13011', 'GA', 'Banks'],
  ['13013', 'GA', 'Barrow'],
  ['13015', 'GA', 'Bartow'],
  ['13017', 'GA', 'Ben Hill'],
  ['13019', 'GA', 'Berrien'],
  ['13021', 'GA', 'Bibb'],
  ['13023', 'GA', 'Bleckley'],
  ['13025', 'GA', 'Brantley'],
  ['13027', 'GA', 'Brooks'],
  ['13029', 'GA', 'Bryan'],
  ['13031', 'GA', 'Bulloch'],
  ['13033', 'GA', 'Burke'],
  ['13035', 'GA', 'Butts'],
  ['13037', 'GA', 'Calhoun'],
  ['13039', 'GA', 'Camden'],
  ['13043', 'GA', 'Candler'],
  ['13045', 'GA', 'Carroll'],
  ['13047', 'GA', 'Catoosa'],
  ['13049', 'GA', 'Charlton'],
  ['13051', 'GA', 'Chatham'],
  ['13053', 'GA', 'Chattahoochee'],
  ['13055', 'GA', 'Chattooga'],
  ['13057', 'GA', 'Cherokee'],
  ['13059', 'GA', 'Clarke'],
  ['13061', 'GA', 'Clay'],
  ['13063', 'GA', 'Clayton'],
  ['13065', 'GA', 'Clinch'],
  ['13067', 'GA', 'Cobb'],
  ['13069', 'GA', 'Coffee'],
  ['13071', 'GA', 'Colquitt'],
  ['13073', 'GA', 'Columbia'],
  ['13075', 'GA', 'Cook'],
  ['13077', 'GA', 'Coweta'],
  ['13079', 'GA', 'Crawford'],
  ['13081', 'GA', 'Crisp'],
  ['13083', 'GA', 'Dade'],
  ['13085', 'GA', 'Dawson'],
  ['13087', 'GA', 'Decatur'],
  ['13089', 'GA', 'DeKalb'],
  ['13091', 'GA', 'Dodge'],
  ['13093', 'GA', 'Dooly'],
  ['13095', 'GA', 'Dougherty'],
  ['13097', 'GA', 'Douglas'],
  ['13099', 'GA', 'Early'],
  ['13101', 'GA', 'Echols'],
  ['13103', 'GA', 'Effingham'],
  ['13105', 'GA', 'Elbert'],
  ['13107', 'GA', 'Emanuel'],
  ['13109', 'GA', 'Evans'],
  ['13111', 'GA', 'Fannin'],
  ['13113', 'GA', 'Fayette'],
  ['13115', 'GA', 'Floyd'],
  ['13117', 'GA', 'Forsyth'],
  ['13119', 'GA', 'Franklin'],
  ['13121', 'GA', 'Fulton'],
  ['13123', 'GA', 'Gilmer'],
  ['13125', 'GA', 'Glascock'],
  ['13127', 'GA', 'Glynn'],
  ['13129', 'GA', 'Gordon'],
  ['13131', 'GA', 'Grady'],
  ['13133', 'GA', 'Greene'],
  ['13135', 'GA', 'Gwinnett'],
  ['13137', 'GA', 'Habersham'],
  ['13139', 'GA', 'Hall'],
  ['13141', 'GA', 'Hancock'],
  ['13143', 'GA', 'Haralson'],
  ['13145', 'GA', 'Harris'],
  ['13147', 'GA', 'Hart'],
  ['13149', 'GA', 'Heard'],
  ['13151', 'GA', 'Henry'],
  ['13153', 'GA', 'Houston'],
  ['13155', 'GA', 'Irwin'],
  ['13157', 'GA', 'Jackson'],
  ['13159', 'GA', 'Jasper'],
  ['13161', 'GA', 'Jeff Davis'],
  ['13163', 'GA', 'Jefferson'],
  ['13165', 'GA', 'Jenkins'],
  ['13167', 'GA', 'Johnson'],
  ['13169', 'GA', 'Jones'],
  ['13171', 'GA', 'Lamar'],
  ['13173', 'GA', 'Lanier'],
  ['13175', 'GA', 'Laurens'],
  ['13177', 'GA', 'Lee'],
  ['13179', 'GA', 'Liberty'],
  ['13181', 'GA', 'Lincoln'],
  ['13183', 'GA', 'Long'],
  ['13185', 'GA', 'Lowndes'],
  ['13187', 'GA', 'Lumpkin'],
  ['13189', 'GA', 'McDuffie'],
  ['13191', 'GA', 'McIntosh'],
  ['13193', 'GA', 'Macon'],
  ['13195', 'GA', 'Madison'],
  ['13197', 'GA', 'Marion'],
  ['13199', 'GA', 'Meriwether'],
  ['13201', 'GA', 'Miller'],
  ['13205', 'GA', 'Mitchell'],
  ['13207', 'GA', 'Monroe'],
  ['13209', 'GA', 'Montgomery'],
  ['13211', 'GA', 'Morgan'],
  ['13213', 'GA', 'Murray'],
  ['13215', 'GA', 'Muscogee'],
  ['13217', 'GA', 'Newton'],
  ['13219', 'GA', 'Oconee'],
  ['13221', 'GA', 'Oglethorpe'],
  ['13223', 'GA', 'Paulding'],
  ['13225', 'GA', 'Peach'],
  ['13227', 'GA', 'Pickens'],
  ['13229', 'GA', 'Pierce'],
  ['13231', 'GA', 'Pike'],
  ['13233', 'GA', 'Polk'],
  ['13235', 'GA', 'Pulaski'],
  ['13237', 'GA', 'Putnam'],
  ['13239', 'GA', 'Quitman'],
  ['13241', 'GA', 'Rabun'],
  ['13243', 'GA', 'Randolph'],
  ['13245', 'GA', 'Richmond'],
  ['13247', 'GA', 'Rockdale'],
  ['13249', 'GA', 'Schley'],
  ['13251', 'GA', 'Screven'],
  ['13253', 'GA', 'Seminole'],
  ['13255', 'GA', 'Spalding'],
  ['13257', 'GA', 'Stephens'],
  ['13259', 'GA', 'Stewart'],
  ['13261', 'GA', 'Sumter'],
  ['13263', 'GA', 'Talbot'],
  ['13265', 'GA', 'Taliaferro'],
  ['13267', 'GA', 'Tattnall'],
  ['13269', 'GA', 'Taylor'],
  ['13271', 'GA', 'Telfair'],
  ['13273', 'GA', 'Terrell'],
  ['13275', 'GA', 'Thomas'],
  ['13277', 'GA', 'Tift'],
  ['13279', 'GA', 'Toombs'],
  ['13281', 'GA', 'Towns'],
  ['13283', 'GA', 'Treutlen'],
  ['13285', 'GA', 'Troup'],
  ['13287', 'GA', 'Turner'],
  ['13289', 'GA', 'Twiggs'],
  ['13291', 'GA', 'Union'],
  ['13293', 'GA', 'Upson'],
  ['13295', 'GA', 'Walker'],
  ['13297', 'GA', 'Walton'],
  ['13299', 'GA', 'Ware'],
  ['13301', 'GA', 'Warren'],
  ['13303', 'GA', 'Washington'],
  ['13305', 'GA', 'Wayne'],
  ['13307', 'GA', 'Webster'],
  ['13309', 'GA', 'Wheeler'],
  ['13311', 'GA', 'White'],
  ['13313', 'GA', 'Whitfield'],
  ['13315', 'GA', 'Wilcox'],
  ['13317', 'GA', 'Wilkes'],
  ['13319', 'GA', 'Wilkinson'],
  ['13321', 'GA', 'Worth'],
  ['15001', 'HI', 'Hawaii'],
  ['15003', 'HI', 'Honolulu'],
  ['15007', 'HI', 'Kauai'],
  ['15009', 'HI', 'Maui'],
  ['16001', 'ID', 'Ada'],
  ['16003', 'ID', 'Adams'],
  ['16005', 'ID', 'Bannock'],
  ['16007', 'ID', 'Bear Lake'],
  ['16009', 'ID', 'Benewah'],
  ['16011', 'ID', 'Bingham'],
  ['16013', 'ID', 'Blaine'],
  ['16015', 'ID', 'Boise'],
  ['16017', 'ID', 'Bonner'],
  ['16019', 'ID', 'Bonneville'],
  ['16021', 'ID', 'Boundary'],
  ['16023', 'ID', 'Butte'],
  ['16025', 'ID', 'Camas'],
  ['16027', 'ID', 'Canyon'],
  ['16029', 'ID', 'Caribou'],
  ['16031', 'ID', 'Cassia'],
  ['16033', 'ID', 'Clark'],
  ['16035', 'ID', 'Clearwater'],
  ['16037', 'ID', 'Custer'],
  ['16039', 'ID', 'Elmore'],
  ['16041', 'ID', 'Franklin'],
  ['16043', 'ID', 'Fremont'],
  ['16045', 'ID', 'Gem'],
  ['16047', 'ID', 'Gooding'],
  ['16049', 'ID', 'Idaho'],
  ['16051', 'ID', 'Jefferson'],
  ['16053', 'ID', 'Jerome'],
  ['16055', 'ID', 'Kootenai'],
  ['16057', 'ID', 'Latah'],
  ['16059', 'ID', 'Lemhi'],
  ['16061', 'ID', 'Lewis'],
  ['16063', 'ID', 'Lincoln'],
  ['16065', 'ID', 'Madison'],
  ['16067', 'ID', 'Minidoka'],
  ['16069', 'ID', 'Nez Perce'],
  ['16071', 'ID', 'Oneida'],
  ['16073', 'ID', 'Owyhee'],
  ['16075', 'ID', 'Payette'],
  ['16077', 'ID', 'Power'],
  ['16079', 'ID', 'Shoshone'],
  ['16081', 'ID', 'Teton'],
  ['16083', 'ID', 'Twin Falls'],
  ['16085', 'ID', 'Valley'],
  ['16087', 'ID', 'Washington'],
  ['17001', 'IL', 'Adams'],
  ['17003', 'IL', 'Alexander'],
  ['17005', 'IL', 'Bond'],
  ['17007', 'IL', 'Boone'],
  ['17009', 'IL', 'Brown'],
  ['17011', 'IL', 'Bureau'],
  ['17013', 'IL', 'Calhoun'],
  ['17015', 'IL', 'Carroll'],
  ['17017', 'IL', 'Cass'],
  ['17019', 'IL', 'Champaign'],
  ['17021', 'IL', 'Christian'],
  ['17023', 'IL', 'Clark'],
  ['17025', 'IL', 'Clay'],
  ['17027', 'IL', 'Clinton'],
  ['17029', 'IL', 'Coles'],
  ['17031', 'IL', 'Cook'],
  ['17033', 'IL', 'Crawford'],
  ['17035', 'IL', 'Cumberland'],
  ['17037', 'IL', 'DeKalb'],
  ['17039', 'IL', 'De Witt'],
  ['17041', 'IL', 'Douglas'],
  ['17043', 'IL', 'DuPage'],
  ['17045', 'IL', 'Edgar'],
  ['17047', 'IL', 'Edwards'],
  ['17049', 'IL', 'Effingham'],
  ['17051', 'IL', 'Fayette'],
  ['17053', 'IL', 'Ford'],
  ['17055', 'IL', 'Franklin'],
  ['17057', 'IL', 'Fulton'],
  ['17059', 'IL', 'Gallatin'],
  ['17061', 'IL', 'Greene'],
  ['17063', 'IL', 'Grundy'],
  ['17065', 'IL', 'Hamilton'],
  ['17067', 'IL', 'Hancock'],
  ['17069', 'IL', 'Hardin'],
  ['17071', 'IL', 'Henderson'],
  ['17073', 'IL', 'Henry'],
  ['17075', 'IL', 'Iroquois'],
  ['17077', 'IL', 'Jackson'],
  ['17079', 'IL', 'Jasper'],
  ['17081', 'IL', 'Jefferson'],
  ['17083', 'IL', 'Jersey'],
  ['17085', 'IL', 'Jo Daviess'],
  ['17087', 'IL', 'Johnson'],
  ['17089', 'IL', 'Kane'],
  ['17091', 'IL', 'Kankakee'],
  ['17093', 'IL', 'Kendall'],
  ['17095', 'IL', 'Knox'],
  ['17097', 'IL', 'Lake'],
  ['17099', 'IL', 'LaSalle'],
  ['17101', 'IL', 'Lawrence'],
  ['17103', 'IL', 'Lee'],
  ['17105', 'IL', 'Livingston'],
  ['17107', 'IL', 'Logan'],
  ['17109', 'IL', 'McDonough'],
  ['17111', 'IL', 'McHenry'],
  ['17113', 'IL', 'McLean'],
  ['17115', 'IL', 'Macon'],
  ['17117', 'IL', 'Macoupin'],
  ['17119', 'IL', 'Madison'],
  ['17121', 'IL', 'Marion'],
  ['17123', 'IL', 'Marshall'],
  ['17125', 'IL', 'Mason'],
  ['17127', 'IL', 'Massac'],
  ['17129', 'IL', 'Menard'],
  ['17131', 'IL', 'Mercer'],
  ['17133', 'IL', 'Monroe'],
  ['17135', 'IL', 'Montgomery'],
  ['17137', 'IL', 'Morgan'],
  ['17139', 'IL', 'Moultrie'],
  ['17141', 'IL', 'Ogle'],
  ['17143', 'IL', 'Peoria'],
  ['17145', 'IL', 'Perry'],
  ['17147', 'IL', 'Piatt'],
  ['17149', 'IL', 'Pike'],
  ['17151', 'IL', 'Pope'],
  ['17153', 'IL', 'Pulaski'],
  ['17155', 'IL', 'Putnam'],
  ['17157', 'IL', 'Randolph'],
  ['17159', 'IL', 'Richland'],
  ['17161', 'IL', 'Rock Island'],
  ['17163', 'IL', 'St. Clair'],
  ['17165', 'IL', 'Saline'],
  ['17167', 'IL', 'Sangamon'],
  ['17169', 'IL', 'Schuyler'],
  ['17171', 'IL', 'Scott'],
  ['17173', 'IL', 'Shelby'],
  ['17175', 'IL', 'Stark'],
  ['17177', 'IL', 'Stephenson'],
  ['17179', 'IL', 'Tazewell'],
  ['17181', 'IL', 'Union'],
  ['17183', 'IL', 'Vermilion'],
  ['17185', 'IL', 'Wabash'],
  ['17187', 'IL', 'Warren'],
  ['17189', 'IL', 'Washington'],
  ['17191', 'IL', 'Wayne'],
  ['17193', 'IL', 'White'],
  ['17195', 'IL', 'Whiteside'],
  ['17197', 'IL', 'Will'],
  ['17199', 'IL', 'Williamson'],
  ['17201', 'IL', 'Winnebago'],
  ['17203', 'IL', 'Woodford'],
  ['18001', 'IN', 'Adams'],
  ['18003', 'IN', 'Allen'],
  ['18005', 'IN', 'Bartholomew'],
  ['18007', 'IN', 'Benton'],
  ['18009', 'IN', 'Blackford'],
  ['18011', 'IN', 'Boone'],
  ['18013', 'IN', 'Brown'],
  ['18015', 'IN', 'Carroll'],
  ['18017', 'IN', 'Cass'],
  ['18019', 'IN', 'Clark'],
  ['18021', 'IN', 'Clay'],
  ['18023', 'IN', 'Clinton'],
  ['18025', 'IN', 'Crawford'],
  ['18027', 'IN', 'Daviess'],
  ['18029', 'IN', 'Dearborn'],
  ['18031', 'IN', 'Decatur'],
  ['18033', 'IN', 'DeKalb'],
  ['18035', 'IN', 'Delaware'],
  ['18037', 'IN', 'Dubois'],
  ['18039', 'IN', 'Elkhart'],
  ['18041', 'IN', 'Fayette'],
  ['18043', 'IN', 'Floyd'],
  ['18045', 'IN', 'Fountain'],
  ['18047', 'IN', 'Franklin'],
  ['18049', 'IN', 'Fulton'],
  ['18051', 'IN', 'Gibson'],
  ['18053', 'IN', 'Grant'],
  ['18055', 'IN', 'Greene'],
  ['18057', 'IN', 'Hamilton'],
  ['18059', 'IN', 'Hancock'],
  ['18061', 'IN', 'Harrison'],
  ['18063', 'IN', 'Hendricks'],
  ['18065', 'IN', 'Henry'],
  ['18067', 'IN', 'Howard'],
  ['18069', 'IN', 'Huntington'],
  ['18071', 'IN', 'Jackson'],
  ['18073', 'IN', 'Jasper'],
  ['18075', 'IN', 'Jay'],
  ['18077', 'IN', 'Jefferson'],
  ['18079', 'IN', 'Jennings'],
  ['18081', 'IN', 'Johnson'],
  ['18083', 'IN', 'Knox'],
  ['18085', 'IN', 'Kosciusko'],
  ['18087', 'IN', 'LaGrange'],
  ['18089', 'IN', 'Lake'],
  ['18091', 'IN', 'LaPorte'],
  ['18093', 'IN', 'Lawrence'],
  ['18095', 'IN', 'Madison'],
  ['18097', 'IN', 'Marion'],
  ['18099', 'IN', 'Marshall'],
  ['18101', 'IN', 'Martin'],
  ['18103', 'IN', 'Miami'],
  ['18105', 'IN', 'Monroe'],
  ['18107', 'IN', 'Montgomery'],
  ['18109', 'IN', 'Morgan'],
  ['18111', 'IN', 'Newton'],
  ['18113', 'IN', 'Noble'],
  ['18115', 'IN', 'Ohio'],
  ['18117', 'IN', 'Orange'],
  ['18119', 'IN', 'Owen'],
  ['18121', 'IN', 'Parke'],
  ['18123', 'IN', 'Perry'],
  ['18125', 'IN', 'Pike'],
  ['18127', 'IN', 'Porter'],
  ['18129', 'IN', 'Posey'],
  ['18131', 'IN', 'Pulaski'],
  ['18133', 'IN', 'Putnam'],
  ['18135', 'IN', 'Randolph'],
  ['18137', 'IN', 'Ripley'],
  ['18139', 'IN', 'Rush'],
  ['18141', 'IN', 'St. Joseph'],
  ['18143', 'IN', 'Scott'],
  ['18145', 'IN', 'Shelby'],
  ['18147', 'IN', 'Spencer'],
  ['18149', 'IN', 'Starke'],
  ['18151', 'IN', 'Steuben'],
  ['18153', 'IN', 'Sullivan'],
  ['18155', 'IN', 'Switzerland'],
  ['18157', 'IN', 'Tippecanoe'],
  ['18159', 'IN', 'Tipton'],
  ['18161', 'IN', 'Union'],
  ['18163', 'IN', 'Vanderburgh'],
  ['18165', 'IN', 'Vermillion'],
  ['18167', 'IN', 'Vigo'],
  ['18169', 'IN', 'Wabash'],
  ['18171', 'IN', 'Warren'],
  ['18173', 'IN', 'Warrick'],
  ['18175', 'IN', 'Washington'],
  ['18177', 'IN', 'Wayne'],
  ['18179', 'IN', 'Wells'],
  ['18181', 'IN', 'White'],
  ['18183', 'IN', 'Whitley'],
  ['19001', 'IA', 'Adair'],
  ['19003', 'IA', 'Adams'],
  ['19005', 'IA', 'Allamakee'],
  ['19007', 'IA', 'Appanoose'],
  ['19009', 'IA', 'Audubon'],
  ['19011', 'IA', 'Benton'],
  ['19013', 'IA', 'Black Hawk'],
  ['19015', 'IA', 'Boone'],
  ['19017', 'IA', 'Bremer'],
  ['19019', 'IA', 'Buchanan'],
  ['19021', 'IA', 'Buena Vista'],
  ['19023', 'IA', 'Butler'],
  ['19025', 'IA', 'Calhoun'],
  ['19027', 'IA', 'Carroll'],
  ['19029', 'IA', 'Cass'],
  ['19031', 'IA', 'Cedar'],
  ['19033', 'IA', 'Cerro Gordo'],
  ['19035', 'IA', 'Cherokee'],
  ['19037', 'IA', 'Chickasaw'],
  ['19039', 'IA', 'Clarke'],
  ['19041', 'IA', 'Clay'],
  ['19043', 'IA', 'Clayton'],
  ['19045', 'IA', 'Clinton'],
  ['19047', 'IA', 'Crawford'],
  ['19049', 'IA', 'Dallas'],
  ['19051', 'IA', 'Davis'],
  ['19053', 'IA', 'Decatur'],
  ['19055', 'IA', 'Delaware'],
  ['19057', 'IA', 'Des Moines'],
  ['19059', 'IA', 'Dickinson'],
  ['19061', 'IA', 'Dubuque'],
  ['19063', 'IA', 'Emmet'],
  ['19065', 'IA', 'Fayette'],
  ['19067', 'IA', 'Floyd'],
  ['19069', 'IA', 'Franklin'],
  ['19071', 'IA', 'Fremont'],
  ['19073', 'IA', 'Greene'],
  ['19075', 'IA', 'Grundy'],
  ['19077', 'IA', 'Guthrie'],
  ['19079', 'IA', 'Hamilton'],
  ['19081', 'IA', 'Hancock'],
  ['19083', 'IA', 'Hardin'],
  ['19085', 'IA', 'Harrison'],
  ['19087', 'IA', 'Henry'],
  ['19089', 'IA', 'Howard'],
  ['19091', 'IA', 'Humboldt'],
  ['19093', 'IA', 'Ida'],
  ['19095', 'IA', 'Iowa'],
  ['19097', 'IA', 'Jackson'],
  ['19099', 'IA', 'Jasper'],
  ['19101', 'IA', 'Jefferson'],
  ['19103', 'IA', 'Johnson'],
  ['19105', 'IA', 'Jones'],
  ['19107', 'IA', 'Keokuk'],
  ['19109', 'IA', 'Kossuth'],
  ['19111', 'IA', 'Lee'],
  ['19113', 'IA', 'Linn'],
  ['19115', 'IA', 'Louisa'],
  ['19117', 'IA', 'Lucas'],
  ['19119', 'IA', 'Lyon'],
  ['19121', 'IA', 'Madison'],
  ['19123', 'IA', 'Mahaska'],
  ['19125', 'IA', 'Marion'],
  ['19127', 'IA', 'Marshall'],
  ['19129', 'IA', 'Mills'],
  ['19131', 'IA', 'Mitchell'],
  ['19133', 'IA', 'Monona'],
  ['19135', 'IA', 'Monroe'],
  ['19137', 'IA', 'Montgomery'],
  ['19139', 'IA', 'Muscatine'],
  ['19141', 'IA', 'O\'Brien'],
  ['19143', 'IA', 'Osceola'],
  ['19145', 'IA', 'Page'],
  ['19147', 'IA', 'Palo Alto'],
  ['19149', 'IA', 'Plymouth'],
  ['19151', 'IA', 'Pocahontas'],
  ['19153', 'IA', 'Polk'],
  ['19155', 'IA', 'Pottawattamie'],
  ['19157', 'IA', 'Poweshiek'],
  ['19159', 'IA', 'Ringgold'],
  ['19161', 'IA', 'Sac'],
  ['19163', 'IA', 'Scott'],
  ['19165', 'IA', 'Shelby'],
  ['19167', 'IA', 'Sioux'],
  ['19169', 'IA', 'Story'],
  ['19171', 'IA', 'Tama'],
  ['19173', 'IA', 'Taylor'],
  ['19175', 'IA', 'Union'],
  ['19177', 'IA', 'Van Buren'],
  ['19179', 'IA', 'Wapello'],
  ['19181', 'IA', 'Warren'],
  ['19183', 'IA', 'Washington'],
  ['19185', 'IA', 'Wayne'],
  ['19187', 'IA', 'Webster'],
  ['19189', 'IA', 'Winnebago'],
  ['19191', 'IA', 'Winneshiek'],
  ['19193', 'IA', 'Woodbury'],
  ['19195', 'IA', 'Worth'],
  ['19197', 'IA', 'Wright'],
  ['20001', 'KS', 'Allen'],
  ['20003', 'KS', 'Anderson'],
  ['20005', 'KS', 'Atchison'],
  ['20007', 'KS', 'Barber'],
  ['20009', 'KS', 'Barton'],
  ['20011', 'KS', 'Bourbon'],
  ['20013', 'KS', 'Brown'],
  ['20015', 'KS', 'Butler'],
  ['20017', 'KS', 'Chase'],
  ['20019', 'KS', 'Chautauqua'],
  ['20021', 'KS', 'Cherokee'],
  ['20023', 'KS', 'Cheyenne'],
  ['20025', 'KS', 'Clark'],
  ['20027', 'KS', 'Clay'],
  ['20029', 'KS', 'Cloud'],
  ['20031', 'KS', 'Coffey'],
  ['20033', 'KS', 'Comanche'],
  ['20035', 'KS', 'Cowley'],
  ['20037', 'KS', 'Crawford'],
  ['20039', 'KS', 'Decatur'],
  ['20041', 'KS', 'Dickinson'],
  ['20043', 'KS', 'Doniphan'],
  ['20045', 'KS', 'Douglas'],
  ['20047', 'KS', 'Edwards'],
  ['20049', 'KS', 'Elk'],
  ['20051', 'KS', 'Ellis'],
  ['20053', 'KS', 'Ellsworth'],
  ['20055', 'KS', 'Finney'],
  ['20057', 'KS', 'Ford'],
  ['20059', 'KS', 'Franklin'],
  ['20061', 'KS', 'Geary'],
  ['20063', 'KS', 'Gove'],
  ['20065', 'KS', 'Graham'],
  ['20067', 'KS', 'Grant'],
  ['20069', 'KS', 'Gray'],
  ['20071', 'KS', 'Greeley'],
  ['20073', 'KS', 'Greenwood'],
  ['20075', 'KS', 'Hamilton'],
  ['20077', 'KS', 'Harper'],
  ['20079', 'KS', 'Harvey'],
  ['20081', 'KS', 'Haskell'],
  ['20083', 'KS', 'Hodgeman'],
  ['20085', 'KS', 'Jackson'],
  ['20087', 'KS', 'Jefferson'],
  ['20089', 'KS', 'Jewell'],
  ['20091', 'KS', 'Johnson'],
  ['20093', 'KS', 'Kearny'],
  ['20095', 'KS', 'Kingman'],
  ['20097', 'KS', 'Kiowa'],
  ['20099', 'KS', 'Labette'],
  ['20101', 'KS', 'Lane'],
  ['20103', 'KS', 'Leavenworth'],
  ['20105', 'KS', 'Lincoln'],
  ['20107', 'KS', 'Linn'],
  ['20109', 'KS', 'Logan'],
  ['20111', 'KS', 'Lyon'],
  ['20113', 'KS', 'McPherson'],
  ['20115', 'KS', 'Marion'],
  ['20117', 'KS', 'Marshall'],
  ['20119', 'KS', 'Meade'],
  ['20121', 'KS', 'Miami'],
  ['20123', 'KS', 'Mitchell'],
  ['20125', 'KS', 'Montgomery'],
  ['20127', 'KS', 'Morris'],
  ['20129', 'KS', 'Morton'],
  ['20131', 'KS', 'Nemaha'],
  ['20133', 'KS', 'Neosho'],
  ['20135', 'KS', 'Ness'],
  ['20137', 'KS', 'Norton'],
  ['20139', 'KS', 'Osage'],
  ['20141', 'KS', 'Osborne'],
  ['20143', 'KS', 'Ottawa'],
  ['20145', 'KS', 'Pawnee'],
  ['20147', 'KS', 'Phillips'],
  ['20149', 'KS', 'Pottawatomie'],
  ['20151', 'KS', 'Pratt'],
  ['20153', 'KS', 'Rawlins'],
  ['20155', 'KS', 'Reno'],
  ['20157', 'KS', 'Republic'],
  ['20159', 'KS', 'Rice'],
  ['20161', 'KS', 'Riley'],
  ['20163', 'KS', 'Rooks'],
  ['20165', 'KS', 'Rush'],
  ['20167', 'KS', 'Russell'],
  ['20169', 'KS', 'Saline'],
  ['20171', 'KS', 'Scott'],
  ['20173', 'KS', 'Sedgwick'],
  ['20175', 'KS', 'Seward'],
  ['20177', 'KS', 'Shawnee'],
  ['20179', 'KS', 'Sheridan'],
  ['20181', 'KS', 'Sherman'],
  ['20183', 'KS', 'Smith'],
  ['20185', 'KS', 'Stafford'],
  ['20187', 'KS', 'Stanton'],
  ['20189', 'KS', 'Stevens'],
  ['20191', 'KS', 'Sumner'],
  ['20193', 'KS', 'Thomas'],
  ['20195', 'KS', 'Trego'],
  ['20197', 'KS', 'Wabaunsee'],
  ['20199', 'KS', 'Wallace'],
  ['20201', 'KS', 'Washington'],
  ['20203', 'KS', 'Wichita'],
  ['20205', 'KS', 'Wilson'],
  ['20207', 'KS', 'Woodson'],
  ['20209', 'KS', 'Wyandotte'],
  ['21001', 'KY', 'Adair'],
  ['21003', 'KY', 'Allen'],
  ['21005', 'KY', 'Anderson'],
  ['21007', 'KY', 'Ballard'],
  ['21009', 'KY', 'Barren'],
  ['21011', 'KY', 'Bath'],
  ['21013', 'KY', 'Bell'],
  ['21015', 'KY', 'Boone'],
  ['21017', 'KY', 'Bourbon'],
  ['21019', 'KY', 'Boyd'],
  ['21021', 'KY', 'Boyle'],
  ['21023', 'KY', 'Bracken'],
  ['21025', 'KY', 'Breathitt'],
  ['21027', 'KY', 'Breckinridge'],
  ['21029', 'KY', 'Bullitt'],
  ['21031', 'KY', 'Butler'],
  ['21033', 'KY', 'Caldwell'],
  ['21035', 'KY', 'Calloway'],
  ['21037', 'KY', 'Campbell'],
  ['21039', 'KY', 'Carlisle'],
  ['21041', 'KY', 'Carroll'],
  ['21043', 'KY', 'Carter'],
  ['21045', 'KY', 'Casey'],
  ['21047', 'KY', 'Christian'],
  ['21049', 'KY', 'Clark'],
  ['21051', 'KY', 'Clay'],
  ['21053', 'KY', 'Clinton'],
  ['21055', 'KY', 'Crittenden'],
  ['21057', 'KY', 'Cumberland'],
  ['21059', 'KY', 'Daviess'],
  ['21061', 'KY', 'Edmonson'],
  ['21063', 'KY', 'Elliott'],
  ['21065', 'KY', 'Estill'],
  ['21067', 'KY', 'Fayette'],
  ['21069', 'KY', 'Fleming'],
  ['21071', 'KY', 'Floyd'],
  ['21073', 'KY', 'Franklin'],
  ['21075', 'KY', 'Fulton'],
  ['21077', 'KY', 'Gallatin'],
  ['21079', 'KY', 'Garrard'],
  ['21081', 'KY', 'Grant'],
  ['21083', 'KY', 'Graves'],
  ['21085', 'KY', 'Grayson'],
  ['21087', 'KY', 'Green'],
  ['21089', 'KY', 'Greenup'],
  ['21091', 'KY', 'Hancock'],
  ['21093', 'KY', 'Hardin'],
  ['21095', 'KY', 'Harlan'],
  ['21097', 'KY', 'Harrison'],
  ['21099', 'KY', 'Hart'],
  ['21101', 'KY', 'Henderson'],
  ['21103', 'KY', 'Henry'],
  ['21105', 'KY', 'Hickman'],
  ['21107', 'KY', 'Hopkins'],
  ['21109', 'KY', 'Jackson'],
  ['21111', 'KY', 'Jefferson'],
  ['21113', 'KY', 'Jessamine'],
  ['21115', 'KY', 'Johnson'],
  ['21117', 'KY', 'Kenton'],
  ['21119', 'KY', 'Knott'],
  ['21121', 'KY', 'Knox'],
  ['21123', 'KY', 'Larue'],
  ['21125', 'KY', 'Laurel'],
  ['21127', 'KY', 'Lawrence'],
  ['21129', 'KY', 'Lee'],
  ['21131', 'KY', 'Leslie'],
  ['21133', 'KY', 'Letcher'],
  ['21135', 'KY', 'Lewis'],
  ['21137', 'KY', 'Lincoln'],
  ['21139', 'KY', 'Livingston'],
  ['21141', 'KY', 'Logan'],
  ['21143', 'KY', 'Lyon'],
  ['21145', 'KY', 'McCracken'],
  ['21147', 'KY', 'McCreary'],
  ['21149', 'KY', 'McLean'],
  ['21151', 'KY', 'Madison'],
  ['21153', 'KY', 'Magoffin'],
  ['21155', 'KY', 'Marion'],
  ['21157', 'KY', 'Marshall'],
  ['21159', 'KY', 'Martin'],
  ['21161', 'KY', 'Mason'],
  ['21163', 'KY', 'Meade'],
  ['21165', 'KY', 'Menifee'],
  ['21167', 'KY', 'Mercer'],
  ['21169', 'KY', 'Metcalfe'],
  ['21171', 'KY', 'Monroe'],
  ['21173', 'KY', 'Montgomery'],
  ['21175', 'KY', 'Morgan'],
  ['21177', 'KY', 'Muhlenberg'],
  ['21179', 'KY', 'Nelson'],
  ['21181', 'KY', 'Nicholas'],
  ['21183', 'KY', 'Ohio'],
  ['21185', 'KY', 'Oldham'],
  ['21187', 'KY', 'Owen'],
  ['21189', 'KY', 'Owsley'],
  ['21191', 'KY', 'Pendleton'],
  ['21193', 'KY', 'Perry'],
  ['21195', 'KY', 'Pike'],
  ['21197', 'KY', 'Powell'],
  ['21199', 'KY', 'Pulaski'],
  ['21201', 'KY', 'Robertson'],
  ['21203', 'KY', 'Rockcastle'],
  ['21205', 'KY', 'Rowan'],
  ['21207', 'KY', 'Russell'],
  ['21209', 'KY', 'Scott'],
  ['21211', 'KY', 'Shelby'],
  ['21213', 'KY', 'Simpson'],
  ['21215', 'KY', 'Spencer'],
  ['21217', 'KY', 'Taylor'],
  ['21219', 'KY', 'Todd'],
  ['21221', 'KY', 'Trigg'],
  ['21223', 'KY', 'Trimble'],
  ['21225', 'KY', 'Union'],
  ['21227', 'KY', 'Warren'],
  ['21229', 'KY', 'Washington'],
  ['21231', 'KY', 'Wayne'],
  ['21233', 'KY', 'Webster'],
  ['21235', 'KY', 'Whitley'],
  ['21237', 'KY', 'Wolfe'],
  ['21239', 'KY', 'Woodford'],
  ['22001', 'LA', 'Acadia Parish', 0],
  ['22003', 'LA', 'Allen Parish', 0],
  ['22005', 'LA', 'Ascension Parish', 0],
  ['22007', 'LA', 'Assumption Parish', 0],
  ['22009', 'LA', 'Avoyelles Parish', 0],
  ['22011', 'LA', 'Beauregard Parish', 0],
  ['22013', 'LA', 'Bienville Parish', 0],
  ['22015', 'LA', 'Bossier Parish', 0],
  ['22017', 'LA', 'Caddo Parish', 0],
  ['22019', 'LA', 'Calcasieu Parish', 0],
  ['22021', 'LA', 'Caldwell Parish', 0],
  ['22023', 'LA', 'Cameron Parish', 0],
  ['22025', 'LA', 'Catahoula Parish', 0],
  ['22027', 'LA', 'Claiborne Parish', 0],
  ['22029', 'LA', 'Concordia Parish', 0],
  ['22031', 'LA', 'De Soto Parish', 0],
  ['22033', 'LA', 'East Baton Rouge Parish', 0],
  ['22035', 'LA', 'East Carroll Parish', 0],
  ['22037', 'LA', 'East Feliciana Parish', 0],
  ['22039', 'LA', 'Evangeline Parish', 0],
  ['22041', 'LA', 'Franklin Parish', 0],
  ['22043', 'LA', 'Grant Parish', 0],
  ['22045', 'LA', 'Iberia Parish', 0],
  ['22047', 'LA', 'Iberville Parish', 0],
  ['22049', 'LA', 'Jackson Parish', 0],
  ['22051', 'LA', 'Jefferson Parish', 0],
  ['22053', 'LA', 'Jefferson Davis Parish', 0],
  ['22055', 'LA', 'Lafayette Parish', 0],
  ['22057', 'LA', 'Lafourche Parish', 0],
  ['22059', 'LA', 'La Salle Parish', 0],
  ['22061', 'LA', 'Lincoln Parish', 0],
  ['22063', 'LA', 'Livingston Parish', 0],
  ['22065', 'LA', 'Madison Parish', 0],
  ['22067', 'LA', 'Morehouse Parish', 0],
  ['22069', 'LA', 'Natchitoches Parish', 0],
  ['22071', 'LA', 'Orleans Parish', 0],
  ['22073', 'LA', 'Ouachita Parish', 0],
  ['22075', 'LA', 'Plaquemines Parish', 0],
  ['22077', 'LA', 'Pointe Coupee Parish', 0],
  ['22079', 'LA', 'Rapides Parish', 0],
  ['22081', 'LA', 'Red River Parish', 0],
  ['22083', 'LA', 'Richland Parish', 0],
  ['22085', 'LA', 'Sabine Parish', 0],
  ['22087', 'LA', 'St. Bernard Parish', 0],
  ['22089', 'LA', 'St. Charles Parish', 0],
  ['22091', 'LA', 'St. Helena Parish', 0],
  ['22093', 'LA', 'St. James Parish', 0],
  ['22095', 'LA', 'St. John the Baptist Parish', 0],
  ['22097', 'LA', 'St. Landry Parish', 0],
  ['22099', 'LA', 'St. Martin Parish', 0],
  ['22101', 'LA', 'St. Mary Parish', 0],
  ['22103', 'LA', 'St. Tammany Parish', 0],
  ['22105', 'LA', 'Tangipahoa Parish', 0],
  ['22107', 'LA', 'Tensas Parish', 0],
  ['22109', 'LA', 'Terrebonne Parish', 0],
  ['22111', 'LA', 'Union Parish', 0],
  ['22113', 'LA', 'Vermilion Parish', 0],
  ['22115', 'LA', 'Vernon Parish', 0],
  ['22117', 'LA', 'Washington Parish', 0],
  ['22119', 'LA', 'Webster Parish', 0],
  ['22121', 'LA', 'West Baton Rouge Parish', 0],
  ['22123', 'LA', 'West Carroll Parish', 0],
  ['22125', 'LA', 'West Feliciana Parish', 0],
  ['22127', 'LA', 'Winn Parish', 0],
  ['23001', 'ME', 'Androscoggin'],
  ['23003', 'ME', 'Aroostook'],
  ['23005', 'ME', 'Cumberland'],
  ['23007', 'ME', 'Franklin'],
  ['23009', 'ME', 'Hancock'],
  ['23011', 'ME', 'Kennebec'],
  ['23013', 'ME', 'Knox'],
  ['23015', 'ME', 'Lincoln'],
  ['23017', 'ME', 'Oxford'],
  ['23019', 'ME', 'Penobscot'],
  ['23021', 'ME', 'Piscataquis'],
  ['23023', 'ME', 'Sagadahoc'],
  ['23025', 'ME', 'Somerset'],
  ['23027', 'ME', 'Waldo'],
  ['23029', 'ME', 'Washington'],
  ['23031', 'ME', 'York'],
  ['24001', 'MD', 'Allegany'],
  ['24003', 'MD', 'Anne Arundel'],
  ['24005', 'MD', 'Baltimore'],
  ['24009', 'MD', 'Calvert'],
  ['24011', 'MD', 'Caroline'],
  ['24013', 'MD', 'Carroll'],
  ['24015', 'MD', 'Cecil'],
  ['24017', 'MD', 'Charles'],
  ['24019', 'MD', 'Dorchester'],
  ['24021', 'MD', 'Frederick'],
  ['24023', 'MD', 'Garrett'],
  ['24025', 'MD', 'Harford'],
  ['24027', 'MD', 'Howard'],
  ['24029', 'MD', 'Kent'],
  ['24031', 'MD', 'Montgomery'],
  ['24033', 'MD', 'Prince George\'s'],
  ['24035', 'MD', 'Queen Anne\'s'],
  ['24037', 'MD', 'St. Mary\'s'],
  ['24039', 'MD', 'Somerset'],
  ['24041', 'MD', 'Talbot'],
  ['24043', 'MD', 'Washington'],
  ['24045', 'MD', 'Wicomico'],
  ['24047', 'MD', 'Worcester'],
  ['24510', 'MD', 'Baltimore', 0],
  ['25001', 'MA', 'Barnstable'],
  ['25003', 'MA', 'Berkshire'],
  ['25005', 'MA', 'Bristol'],
  ['25007', 'MA', 'Dukes'],
  ['25009', 'MA', 'Essex'],
  ['25011', 'MA', 'Franklin'],
  ['25013', 'MA', 'Hampden'],
  ['25015', 'MA', 'Hampshire'],
  ['25017', 'MA', 'Middlesex'],
  ['25019', 'MA', 'Nantucket'],
  ['25021', 'MA', 'Norfolk'],
  ['25023', 'MA', 'Plymouth'],
  ['25025', 'MA', 'Suffolk'],
  ['25027', 'MA', 'Worcester'],
  ['26001', 'MI', 'Alcona'],
  ['26003', 'MI', 'Alger'],
  ['26005', 'MI', 'Allegan'],
  ['26007', 'MI', 'Alpena'],
  ['26009', 'MI', 'Antrim'],
  ['26011', 'MI', 'Arenac'],
  ['26013', 'MI', 'Baraga'],
  ['26015', 'MI', 'Barry'],
  ['26017', 'MI', 'Bay'],
  ['26019', 'MI', 'Benzie'],
  ['26021', 'MI', 'Berrien'],
  ['26023', 'MI', 'Branch'],
  ['26025', 'MI', 'Calhoun'],
  ['26027', 'MI', 'Cass'],
  ['26029', 'MI', 'Charlevoix'],
  ['26031', 'MI', 'Cheboygan'],
  ['26033', 'MI', 'Chippewa'],
  ['26035', 'MI', 'Clare'],
  ['26037', 'MI', 'Clinton'],
  ['26039', 'MI', 'Crawford'],
  ['26041', 'MI', 'Delta'],
  ['26043', 'MI', 'Dickinson'],
  ['26045', 'MI', 'Eaton'],
  ['26047', 'MI', 'Emmet'],
  ['26049', 'MI', 'Genesee'],
  ['26051', 'MI', 'Gladwin'],
  ['26053', 'MI', 'Gogebic'],
  ['26055', 'MI', 'Grand Traverse'],
  ['26057', 'MI', 'Gratiot'],
  ['26059', 'MI', 'Hillsdale'],
  ['26061', 'MI', 'Houghton'],
  ['26063', 'MI', 'Huron'],
  ['26065', 'MI', 'Ingham'],
  ['26067', 'MI', 'Ionia'],
  ['26069', 'MI', 'Iosco'],
  ['26071', 'MI', 'Iron'],
  ['26073', 'MI', 'Isabella'],
  ['26075', 'MI', 'Jackson'],
  ['26077', 'MI', 'Kalamazoo'],
  ['26079', 'MI', 'Kalkaska'],
  ['26081', 'MI', 'Kent'],
  ['26083', 'MI', 'Keweenaw'],
  ['26085', 'MI', 'Lake'],
  ['26087', 'MI', 'Lapeer'],
  ['26089', 'MI', 'Leelanau'],
  ['26091', 'MI', 'Lenawee'],
  ['26093', 'MI', 'Livingston'],
  ['26095', 'MI', 'Luce'],
  ['26097', 'MI', 'Mackinac'],
  ['26099', 'MI', 'Macomb'],
  ['26101', 'MI', 'Manistee'],
  ['26103', 'MI', 'Marquette'],
  ['26105', 'MI', 'Mason'],
  ['26107', 'MI', 'Mecosta'],
  ['26109', 'MI', 'Menominee'],
  ['26111', 'MI', 'Midland'],
  ['26113', 'MI', 'Missaukee'],
  ['26115', 'MI', 'Monroe'],
  ['26117', 'MI', 'Montcalm'],
  ['26119', 'MI', 'Montmorency'],
  ['26121', 'MI', 'Muskegon'],
  ['26123', 'MI', 'Newaygo'],
  ['26125', 'MI', 'Oakland'],
  ['26127', 'MI', 'Oceana'],
  ['26129', 'MI', 'Ogemaw'],
  ['26131', 'MI', 'Ontonagon'],
  ['26133', 'MI', 'Osceola'],
  ['26135', 'MI', 'Oscoda'],
  ['26137', 'MI', 'Otsego'],
  ['26139', 'MI', 'Ottawa'],
  ['26141', 'MI', 'Presque Isle'],
  ['26143', 'MI', 'Roscommon'],
  ['26145', 'MI', 'Saginaw'],
  ['26147', 'MI', 'St. Clair'],
  ['26149', 'MI', 'St. Joseph'],
  ['26151', 'MI', 'Sanilac'],
  ['26153', 'MI', 'Schoolcraft'],
  ['26155', 'MI', 'Shiawassee'],
  ['26157', 'MI', 'Tuscola'],
  ['26159', 'MI', 'Van Buren'],
  ['26161', 'MI', 'Washtenaw'],
  ['26163', 'MI', 'Wayne'],
  ['26165', 'MI', 'Wexford'],
  ['27001', 'MN', 'Aitkin'],
  ['27003', 'MN', 'Anoka'],
  ['27005', 'MN', 'Becker'],
  ['27007', 'MN', 'Beltrami'],
  ['27009', 'MN', 'Benton'],
  ['27011', 'MN', 'Big Stone'],
  ['27013', 'MN', 'Blue Earth'],
  ['27015', 'MN', 'Brown'],
  ['27017', 'MN', 'Carlton'],
  ['27019', 'MN', 'Carver'],
  ['27021', 'MN', 'Cass'],
  ['27023', 'MN', 'Chippewa'],
  ['27025', 'MN', 'Chisago'],
  ['27027', 'MN', 'Clay'],
  ['27029', 'MN', 'Clearwater'],
  ['27031', 'MN', 'Cook'],
  ['27033', 'MN', 'Cottonwood'],
  ['27035', 'MN', 'Crow Wing'],
  ['27037', 'MN', 'Dakota'],
  ['27039', 'MN', 'Dodge'],
  ['27041', 'MN', 'Douglas'],
  ['27043', 'MN', 'Faribault'],
  ['27045', 'MN', 'Fillmore'],
  ['27047', 'MN', 'Freeborn'],
  ['27049', 'MN', 'Goodhue'],
  ['27051', 'MN', 'Grant'],
  ['27053', 'MN', 'Hennepin'],
  ['27055', 'MN', 'Houston'],
  ['27057', 'MN', 'Hubbard'],
  ['27059', 'MN', 'Isanti'],
  ['27061', 'MN', 'Itasca'],
  ['27063', 'MN', 'Jackson'],
  ['27065', 'MN', 'Kanabec'],
  ['27067', 'MN', 'Kandiyohi'],
  ['27069', 'MN', 'Kittson'],
  ['27071', 'MN', 'Koochiching'],
  ['27073', 'MN', 'Lac qui Parle'],
  ['27075', 'MN', 'Lake'],
  ['27077', 'MN', 'Lake of the Woods'],
  ['27079', 'MN', 'Le Sueur'],
  ['27081', 'MN', 'Lincoln'],
  ['27083', 'MN', 'Lyon'],
  ['27085', 'MN', 'McLeod'],
  ['27087', 'MN', 'Mahnomen'],
  ['27089', 'MN', 'Marshall'],
  ['27091', 'MN', 'Martin'],
  ['27093', 'MN', 'Meeker'],
  ['27095', 'MN', 'Mille Lacs'],
  ['27097', 'MN', 'Morrison'],
  ['27099', 'MN', 'Mower'],
  ['27101', 'MN', 'Murray'],
  ['27103', 'MN', 'Nicollet'],
  ['27105', 'MN', 'Nobles'],
  ['27107', 'MN', 'Norman'],
  ['27109', 'MN', 'Olmsted'],
  ['27111', 'MN', 'Otter Tail'],
  ['27113', 'MN', 'Pennington'],
  ['27115', 'MN', 'Pine'],
  ['27117', 'MN', 'Pipestone'],
  ['27119', 'MN', 'Polk'],
  ['27121', 'MN', 'Pope'],
  ['27123', 'MN', 'Ramsey'],
  ['27125', 'MN', 'Red Lake'],
  ['27127', 'MN', 'Redwood'],
  ['27129', 'MN', 'Renville'],
  ['27131', 'MN', 'Rice'],
  ['27133', 'MN', 'Rock'],
  ['27135', 'MN', 'Roseau'],
  ['27137', 'MN', 'St. Louis'],
  ['27139', 'MN', 'Scott'],
  ['27141', 'MN', 'Sherburne'],
  ['27143', 'MN', 'Sibley'],
  ['27145', 'MN', 'Stearns'],
  ['27147', 'MN', 'Steele'],
  ['27149', 'MN', 'Stevens'],
  ['27151', 'MN', 'Swift'],
  ['27153', 'MN', 'Todd'],
  ['27155', 'MN', 'Traverse'],
  ['27157', 'MN', 'Wabasha'],
  ['27159', 'MN', 'Wadena'],
  ['27161', 'MN', 'Waseca'],
  ['27163', 'MN', 'Washington'],
  ['27165', 'MN', 'Watonwan'],
  ['27167', 'MN', 'Wilkin'],
  ['27169', 'MN', 'Winona'],
  ['27171', 'MN', 'Wright'],
  ['27173', 'MN', 'Yellow Medicine'],
  ['28001', 'MS', 'Adams'],
  ['28003', 'MS', 'Alcorn'],
  ['28005', 'MS', 'Amite'],
  ['28007', 'MS', 'Attala'],
  ['28009', 'MS', 'Benton'],
  ['28011', 'MS', 'Bolivar'],
  ['28013', 'MS', 'Calhoun'],
  ['28015', 'MS', 'Carroll'],
  ['28017', 'MS', 'Chickasaw'],
  ['28019', 'MS', 'Choctaw'],
  ['28021', 'MS', 'Claiborne'],
  ['28023', 'MS', 'Clarke'],
  ['28025', 'MS', 'Clay'],
  ['28027', 'MS', 'Coahoma'],
  ['28029', 'MS', 'Copiah'],
  ['28031', 'MS', 'Covington'],
  ['28033', 'MS', 'DeSoto'],
  ['28035', 'MS', 'Forrest'],
  ['28037', 'MS', 'Franklin'],
  ['28039', 'MS', 'George'],
  ['28041', 'MS', 'Greene'],
  ['28043', 'MS', 'Grenada'],
  ['28045', 'MS', 'Hancock'],
  ['28047', 'MS', 'Harrison'],
  ['28049', 'MS', 'Hinds'],
  ['28051', 'MS', 'Holmes'],
  ['28053', 'MS', 'Humphreys'],
  ['28055', 'MS', 'Issaquena'],
  ['28057', 'MS', 'Itawamba'],
  ['28059', 'MS', 'Jackson'],
  ['28061', 'MS', 'Jasper'],
  ['28063', 'MS', 'Jefferson'],
  ['28065', 'MS', 'Jefferson Davis'],
  ['28067', 'MS', 'Jones'],
  ['28069', 'MS', 'Kemper'],
  ['28071', 'MS', 'Lafayette'],
  ['28073', 'MS', 'Lamar'],
  ['28075', 'MS', 'Lauderdale'],
  ['28077', 'MS', 'Lawrence'],
  ['28079', 'MS', 'Leake'],
  ['28081', 'MS', 'Lee'],
  ['28083', 'MS', 'Leflore'],
  ['28085', 'MS', 'Lincoln'],
  ['28087', 'MS', 'Lowndes'],
  ['28089', 'MS', 'Madison'],
  ['28091', 'MS', 'Marion'],
  ['28093', 'MS', 'Marshall'],
  ['28095', 'MS', 'Monroe'],
  ['28097', 'MS', 'Montgomery'],
  ['28099', 'MS', 'Neshoba'],
  ['28101', 'MS', 'Newton'],
  ['28103', 'MS', 'Noxubee'],
  ['28105', 'MS', 'Oktibbeha'],
  ['28107', 'MS', 'Panola'],
  ['28109', 'MS', 'Pearl River'],
  ['28111', 'MS', 'Perry'],
  ['28113', 'MS', 'Pike'],
  ['28115', 'MS', 'Pontotoc'],
  ['28117', 'MS', 'Prentiss'],
  ['28119', 'MS', 'Quitman'],
  ['28121', 'MS', 'Rankin'],
  ['28123', 'MS', 'Scott'],
  ['28125', 'MS', 'Sharkey'],
  ['28127', 'MS', 'Simpson'],
  ['28129', 'MS', 'Smith'],
  ['28131', 'MS', 'Stone'],
  ['28133', 'MS', 'Sunflower'],
  ['28135', 'MS', 'Tallahatchie'],
  ['28137', 'MS', 'Tate'],
  ['28139', 'MS', 'Tippah'],
  ['28141', 'MS', 'Tishomingo'],
  ['28143', 'MS', 'Tunica'],
  ['28145', 'MS', 'Union'],
  ['28147', 'MS', 'Walthall'],
  ['28149', 'MS', 'Warren'],
  ['28151', 'MS', 'Washington'],
  ['28153', 'MS', 'Wayne'],
  ['28155', 'MS', 'Webster'],
  ['28157', 'MS', 'Wilkinson'],
  ['28159', 'MS', 'Winston'],
  ['28161', 'MS', 'Yalobusha'],
  ['28163', 'MS', 'Yazoo'],
  ['29001', 'MO', 'Adair'],
  ['29003', 'MO', 'Andrew'],
  ['29005', 'MO', 'Atchison'],
  ['29007', 'MO', 'Audrain'],
  ['29009', 'MO', 'Barry'],
  ['29011', 'MO', 'Barton'],
  ['29013', 'MO', 'Bates'],
  ['29015', 'MO', 'Benton'],
  ['29017', 'MO', 'Bollinger'],
  ['29019', 'MO', 'Boone'],
  ['29021', 'MO', 'Buchanan'],
  ['29023', 'MO', 'Butler'],
  ['29025', 'MO', 'Caldwell'],
  ['29027', 'MO', 'Callaway'],
  ['29029', 'MO', 'Camden'],
  ['29031', 'MO', 'Cape Girardeau'],
  ['29033', 'MO', 'Carroll'],
  ['29035', 'MO', 'Carter'],
  ['29037', 'MO', 'Cass'],
  ['29039', 'MO', 'Cedar'],
  ['29041', 'MO', 'Chariton'],
  ['29043', 'MO', 'Christian'],
  ['29045', 'MO', 'Clark'],
  ['29047', 'MO', 'Clay'],
  ['29049', 'MO', 'Clinton'],
  ['29051', 'MO', 'Cole'],
  ['29053', 'MO', 'Cooper'],
  ['29055', 'MO', 'Crawford'],
  ['29057', 'MO', 'Dade'],
  ['29059', 'MO', 'Dallas'],
  ['29061', 'MO', 'Daviess'],
  ['29063', 'MO', 'DeKalb'],
  ['29065', 'MO', 'Dent'],
  ['29067', 'MO', 'Douglas'],
  ['29069', 'MO', 'Dunklin'],
  ['29071', 'MO', 'Franklin'],
  ['29073', 'MO', 'Gasconade'],
  ['29075', 'MO', 'Gentry'],
  ['29077', 'MO', 'Greene'],
  ['29079', 'MO', 'Grundy'],
  ['29081', 'MO', 'Harrison'],
  ['29083', 'MO', 'Henry'],
  ['29085', 'MO', 'Hickory'],
  ['29087', 'MO', 'Holt'],
  ['29089', 'MO', 'Howard'],
  ['29091', 'MO', 'Howell'],
  ['29093', 'MO', 'Iron'],
  ['29095', 'MO', 'Jackson'],
  ['29097', 'MO', 'Jasper'],
  ['29099', 'MO', 'Jefferson'],
  ['29101', 'MO', 'Johnson'],
  ['29103', 'MO', 'Knox'],
  ['29105', 'MO', 'Laclede'],
  ['29107', 'MO', 'Lafayette'],
  ['29109', 'MO', 'Lawrence'],
  ['29111', 'MO', 'Lewis'],
  ['29113', 'MO', 'Lincoln'],
  ['29115', 'MO', 'Linn'],
  ['29117', 'MO', 'Livingston'],
  ['29119', 'MO', 'McDonald'],
  ['29121', 'MO', 'Macon'],
  ['29123', 'MO', 'Madison'],
  ['29125', 'MO', 'Maries'],
  ['29127', 'MO', 'Marion'],
  ['29129', 'MO', 'Mercer'],
  ['29131', 'MO', 'Miller'],
  ['29133', 'MO', 'Mississippi'],
  ['29135', 'MO', 'Moniteau'],
  ['29137', 'MO', 'Monroe'],
  ['29139', 'MO', 'Montgomery'],
  ['29141', 'MO', 'Morgan'],
  ['29143', 'MO', 'New Madrid'],
  ['29145', 'MO', 'Newton'],
  ['29147', 'MO', 'Nodaway'],
  ['29149', 'MO', 'Oregon'],
  ['29151', 'MO', 'Osage'],
  ['29153', 'MO', 'Ozark'],
  ['29155', 'MO', 'Pemiscot'],
  ['29157', 'MO', 'Perry'],
  ['29159', 'MO', 'Pettis'],
  ['29161', 'MO', 'Phelps'],
  ['29163', 'MO', 'Pike'],
  ['29165', 'MO', 'Platte'],
  ['29167', 'MO', 'Polk'],
  ['29169', 'MO', 'Pulaski'],
  ['29171', 'MO', 'Putnam'],
  ['29173', 'MO', 'Ralls'],
  ['29175', 'MO', 'Randolph'],
  ['29177', 'MO', 'Ray'],
  ['29179', 'MO', 'Reynolds'],
  ['29181', 'MO', 'Ripley'],
  ['29183', 'MO', 'St. Charles'],
  ['29185', 'MO', 'St. Clair'],
  ['29186', 'MO', 'Ste. Genevieve'],
  ['29187', 'MO', 'St. Francois'],
  ['29189', 'MO', 'St. Louis'],
  ['29195', 'MO', 'Saline'],
  ['29197', 'MO', 'Schuyler'],
  ['29199', 'MO', 'Scotland'],
  ['29201', 'MO', 'Scott'],
  ['29203', 'MO', 'Shannon'],
  ['29205', 'MO', 'Shelby'],
  ['29207', 'MO', 'Stoddard'],
  ['29209', 'MO', 'Stone'],
  ['29211', 'MO', 'Sullivan'],
  ['29213', 'MO', 'Taney'],
  ['29215', 'MO', 'Texas'],
  ['29217', 'MO', 'Vernon'],
  ['29219', 'MO', 'Warren'],
  ['29221', 'MO', 'Washington'],
  ['29223', 'MO', 'Wayne'],
  ['29225', 'MO', 'Webster'],
  ['29227', 'MO', 'Worth'],
  ['29229', 'MO', 'Wright'],
  ['29510', 'MO', 'St. Louis', 0],
  ['30001', 'MT', 'Beaverhead'],
  ['30003', 'MT', 'Big Horn'],
  ['30005', 'MT', 'Blaine'],
  ['30007', 'MT', 'Broadwater'],
  ['30009', 'MT', 'Carbon'],
  ['30011', 'MT', 'Carter'],
  ['30013', 'MT', 'Cascade'],
  ['30015', 'MT', 'Chouteau'],
  ['30017', 'MT', 'Custer'],
  ['30019', 'MT', 'Daniels'],
  ['30021', 'MT', 'Dawson'],
  ['30023', 'MT', 'Deer Lodge'],
  ['30025', 'MT', 'Fallon'],
  ['30027', 'MT', 'Fergus'],
  ['30029', 'MT', 'Flathead'],
  ['30031', 'MT', 'Gallatin'],
  ['30033', 'MT', 'Garfield'],
  ['30035', 'MT', 'Glacier'],
  ['30037', 'MT', 'Golden Valley'],
  ['30039', 'MT', 'Granite'],
  ['30041', 'MT', 'Hill'],
  ['30043', 'MT', 'Jefferson'],
  ['30045', 'MT', 'Judith Basin'],
  ['30047', 'MT', 'Lake'],
  ['30049', 'MT', 'Lewis and Clark'],
  ['30051', 'MT', 'Liberty'],
  ['30053', 'MT', 'Lincoln'],
  ['30055', 'MT', 'McCone'],
  ['30057', 'MT', 'Madison'],
  ['30059', 'MT', 'Meagher'],
  ['30061', 'MT', 'Mineral'],
  ['30063', 'MT', 'Missoula'],
  ['30065', 'MT', 'Musselshell'],
  ['30067', 'MT', 'Park'],
  ['30069', 'MT', 'Petroleum'],
  ['30071', 'MT', 'Phillips'],
  ['30073', 'MT', 'Pondera'],
  ['30075', 'MT', 'Powder River'],
  ['30077', 'MT', 'Powell'],
  ['30079', 'MT', 'Prairie'],
  ['30081', 'MT', 'Ravalli'],
  ['30083', 'MT', 'Richland'],
  ['30085', 'MT', 'Roosevelt'],
  ['30087', 'MT', 'Rosebud'],
  ['30089', 'MT', 'Sanders'],
  ['30091', 'MT', 'Sheridan'],
  ['30093', 'MT', 'Silver Bow'],
  ['30095', 'MT', 'Stillwater'],
  ['30097', 'MT', 'Sweet Grass'],
  ['30099', 'MT', 'Teton'],
  ['30101', 'MT', 'Toole'],
  ['30103', 'MT', 'Treasure'],
  ['30105', 'MT', 'Valley'],
  ['30107', 'MT', 'Wheatland'],
  ['30109', 'MT', 'Wibaux'],
  ['30111', 'MT', 'Yellowstone'],
  ['31001', 'NE', 'Adams'],
  ['31003', 'NE', 'Antelope'],
  ['31005', 'NE', 'Arthur'],
  ['31007', 'NE', 'Banner'],
  ['31009', 'NE', 'Blaine'],
  ['31011', 'NE', 'Boone'],
  ['31013', 'NE', 'Box Butte'],
  ['31015', 'NE', 'Boyd'],
  ['31017', 'NE', 'Brown'],
  ['31019', 'NE', 'Buffalo'],
  ['31021', 'NE', 'Burt'],
  ['31023', 'NE', 'Butler'],
  ['31025', 'NE', 'Cass'],
  ['31027', 'NE', 'Cedar'],
  ['31029', 'NE', 'Chase'],
  ['31031', 'NE', 'Cherry'],
  ['31033', 'NE', 'Cheyenne'],
  ['31035', 'NE', 'Clay'],
  ['31037', 'NE', 'Colfax'],
  ['31039', 'NE', 'Cuming'],
  ['31041', 'NE', 'Custer'],
  ['31043', 'NE', 'Dakota'],
  ['31045', 'NE', 'Dawes'],
  ['31047', 'NE', 'Dawson'],
  ['31049', 'NE', 'Deuel'],
  ['31051', 'NE', 'Dixon'],
  ['31053', 'NE', 'Dodge'],
  ['31055', 'NE', 'Douglas'],
  ['31057', 'NE', 'Dundy'],
  ['31059', 'NE', 'Fillmore'],
  ['31061', 'NE', 'Franklin'],
  ['31063', 'NE', 'Frontier'],
  ['31065', 'NE', 'Furnas'],
  ['31067', 'NE', 'Gage'],
  ['31069', 'NE', 'Garden'],
  ['31071', 'NE', 'Garfield'],
  ['31073', 'NE', 'Gosper'],
  ['31075', 'NE', 'Grant'],
  ['31077', 'NE', 'Greeley'],
  ['31079', 'NE', 'Hall'],
  ['31081', 'NE', 'Hamilton'],
  ['31083', 'NE', 'Harlan'],
  ['31085', 'NE', 'Hayes'],
  ['31087', 'NE', 'Hitchcock'],
  ['31089', 'NE', 'Holt'],
  ['31091', 'NE', 'Hooker'],
  ['31093', 'NE', 'Howard'],
  ['31095', 'NE', 'Jefferson'],
  ['31097', 'NE', 'Johnson'],
  ['31099', 'NE', 'Kearney'],
  ['31101', 'NE', 'Keith'],
  ['31103', 'NE', 'Keya Paha'],
  ['31105', 'NE', 'Kimball'],
  ['31107', 'NE', 'Knox'],
  ['31109', 'NE', 'Lancaster'],
  ['31111', 'NE', 'Lincoln'],
  ['31113', 'NE', 'Logan'],
  ['31115', 'NE', 'Loup'],
  ['31117', 'NE', 'McPherson'],
  ['31119', 'NE', 'Madison'],
  ['31121', 'NE', 'Merrick'],
  ['31123', 'NE', 'Morrill'],
  ['31125', 'NE', 'Nance'],
  ['31127', 'NE', 'Nemaha'],
  ['31129', 'NE', 'Nuckolls'],
  ['31131', 'NE', 'Otoe'],
  ['31133', 'NE', 'Pawnee'],
  ['31135', 'NE', 'Perkins'],
  ['31137', 'NE', 'Phelps'],
  ['31139', 'NE', 'Pierce'],
  ['31141', 'NE', 'Platte'],
  ['31143', 'NE', 'Polk'],
  ['31145', 'NE', 'Red Willow'],
  ['31147', 'NE', 'Richardson'],
  ['31149', 'NE', 'Rock'],
  ['31151', 'NE', 'Saline'],
  ['31153', 'NE', 'Sarpy'],
  ['31155', 'NE', 'Saunders'],
  ['31157', 'NE', 'Scotts Bluff'],
  ['31159', 'NE', 'Seward'],
  ['31161', 'NE', 'Sheridan'],
  ['31163', 'NE', 'Sherman'],
  ['31165', 'NE', 'Sioux'],
  ['31167', 'NE', 'Stanton'],
  ['31169', 'NE', 'Thayer'],
  ['31171', 'NE', 'Thomas'],
  ['31173', 'NE', 'Thurston'],
  ['31175', 'NE', 'Valley'],
  ['31177', 'NE', 'Washington'],
  ['31179', 'NE', 'Wayne'],
  ['31181', 'NE', 'Webster'],
  ['31183', 'NE', 'Wheeler'],
  ['31185', 'NE', 'York'],
  ['32001', 'NV', 'Churchill'],
  ['32003', 'NV', 'Clark'],
  ['32005', 'NV', 'Douglas'],
  ['32007', 'NV', 'Elko'],
  ['32009', 'NV', 'Esmeralda'],
  ['32011', 'NV', 'Eureka'],
  ['32013', 'NV', 'Humboldt'],
  ['32015', 'NV', 'Lander'],
  ['32017', 'NV', 'Lincoln'],
  ['32019', 'NV', 'Lyon'],
  ['32021', 'NV', 'Mineral'],
  ['32023', 'NV', 'Nye'],
  ['32027', 'NV', 'Pershing'],
  ['32029', 'NV', 'Storey'],
  ['32031', 'NV', 'Washoe'],
  ['32033', 'NV', 'White Pine'],
  ['32510', 'NV', 'Carson', 0],
  ['33001', 'NH', 'Belknap'],
  ['33003', 'NH', 'Carroll'],
  ['33005', 'NH', 'Cheshire'],
  ['33007', 'NH', 'Coos'],
  ['33009', 'NH', 'Grafton'],
  ['33011', 'NH', 'Hillsborough'],
  ['33013', 'NH', 'Merrimack'],
  ['33015', 'NH', 'Rockingham'],
  ['33017', 'NH', 'Strafford'],
  ['33019', 'NH', 'Sullivan'],
  ['34001', 'NJ', 'Atlantic'],
  ['34003', 'NJ', 'Bergen'],
  ['34005', 'NJ', 'Burlington'],
  ['34007', 'NJ', 'Camden'],
  ['34009', 'NJ', 'Cape May'],
  ['34011', 'NJ', 'Cumberland'],
  ['34013', 'NJ', 'Essex'],
  ['34015', 'NJ', 'Gloucester'],
  ['34017', 'NJ', 'Hudson'],
  ['34019', 'NJ', 'Hunterdon'],
  ['34021', 'NJ', 'Mercer'],
  ['34023', 'NJ', 'Middlesex'],
  ['34025', 'NJ', 'Monmouth'],
  ['34027', 'NJ', 'Morris'],
  ['34029', 'NJ', 'Ocean'],
  ['34031', 'NJ', 'Passaic'],
  ['34033', 'NJ', 'Salem'],
  ['34035', 'NJ', 'Somerset'],
  ['34037', 'NJ', 'Sussex'],
  ['34039', 'NJ', 'Union'],
  ['34041', 'NJ', 'Warren'],
  ['35001', 'NM', 'Bernalillo'],
  ['35003', 'NM', 'Catron'],
  ['35005', 'NM', 'Chaves'],
  ['35006', 'NM', 'Cibola'],
  ['35007', 'NM', 'Colfax'],
  ['35009', 'NM', 'Curry'],
  ['35011', 'NM', 'De Baca'],
  ['35013', 'NM', 'Dona Ana'],
  ['35015', 'NM', 'Eddy'],
  ['35017', 'NM', 'Grant'],
  ['35019', 'NM', 'Guadalupe'],
  ['35021', 'NM', 'Harding'],
  ['35023', 'NM', 'Hidalgo'],
  ['35025', 'NM', 'Lea'],
  ['35027', 'NM', 'Lincoln'],
  ['35028', 'NM', 'Los Alamos'],
  ['35029', 'NM', 'Luna'],
  ['35031', 'NM', 'McKinley'],
  ['35033', 'NM', 'Mora'],
  ['35035', 'NM', 'Otero'],
  ['35037', 'NM', 'Quay'],
  ['35039', 'NM', 'Rio Arriba'],
  ['35041', 'NM', 'Roosevelt'],
  ['35043', 'NM', 'Sandoval'],
  ['35045', 'NM', 'San Juan'],
  ['35047', 'NM', 'San Miguel'],
  ['35049', 'NM', 'Santa Fe'],
  ['35051', 'NM', 'Sierra'],
  ['35053', 'NM', 'Socorro'],
  ['35055', 'NM', 'Taos'],
  ['35057', 'NM', 'Torrance'],
  ['35059', 'NM', 'Union'],
  ['35061', 'NM', 'Valencia'],
  ['36001', 'NY', 'Albany'],
  ['36003', 'NY', 'Allegany'],
  ['36005', 'NY', 'Bronx'],
  ['36007', 'NY', 'Broome'],
  ['36009', 'NY', 'Cattaraugus'],
  ['36011', 'NY', 'Cayuga'],
  ['36013', 'NY', 'Chautauqua'],
  ['36015', 'NY', 'Chemung'],
  ['36017', 'NY', 'Chenango'],
  ['36019', 'NY', 'Clinton'],
  ['36021', 'NY', 'Columbia'],
  ['36023', 'NY', 'Cortland'],
  ['36025', 'NY', 'Delaware'],
  ['36027', 'NY', 'Dutchess'],
  ['36029', 'NY', 'Erie'],
  ['36031', 'NY', 'Essex'],
  ['36033', 'NY', 'Franklin'],
  ['36035', 'NY', 'Fulton'],
  ['36037', 'NY', 'Genesee'],
  ['36039', 'NY', 'Greene'],
  ['36041', 'NY', 'Hamilton'],
  ['36043', 'NY', 'Herkimer'],
  ['36045', 'NY', 'Jefferson'],
  ['36047', 'NY', 'Kings'],
  ['36049', 'NY', 'Lewis'],
  ['36051', 'NY', 'Livingston'],
  ['36053', 'NY', 'Madison'],
  ['36055', 'NY', 'Monroe'],
  ['36057', 'NY', 'Montgomery'],
  ['36059', 'NY', 'Nassau'],
  ['36061', 'NY', 'New York'],
  ['36063', 'NY', 'Niagara'],
  ['36065', 'NY', 'Oneida'],
  ['36067', 'NY', 'Onondaga'],
  ['36069', 'NY', 'Ontario'],
  ['36071', 'NY', 'Orange'],
  ['36073', 'NY', 'Orleans'],
  ['36075', 'NY', 'Oswego'],
  ['36077', 'NY', 'Otsego'],
  ['36079', 'NY', 'Putnam'],
  ['36081', 'NY', 'Queens'],
  ['36083', 'NY', 'Rensselaer'],
  ['36085', 'NY', 'Richmond'],
  ['36087', 'NY', 'Rockland'],
  ['36089', 'NY', 'St. Lawrence'],
  ['36091', 'NY', 'Saratoga'],
  ['36093', 'NY', 'Schenectady'],
  ['36095', 'NY', 'Schoharie'],
  ['36097', 'NY', 'Schuyler'],
  ['36099', 'NY', 'Seneca'],
  ['36101', 'NY', 'Steuben'],
  ['36103', 'NY', 'Suffolk'],
  ['36105', 'NY', 'Sullivan'],
  ['36107', 'NY', 'Tioga'],
  ['36109', 'NY', 'Tompkins'],
  ['36111', 'NY', 'Ulster'],
  ['36113', 'NY', 'Warren'],
  ['36115', 'NY', 'Washington'],
  ['36117', 'NY', 'Wayne'],
  ['36119', 'NY', 'Westchester'],
  ['36121', 'NY', 'Wyoming'],
  ['36123', 'NY', 'Yates'],
  ['37001', 'NC', 'Alamance'],
  ['37003', 'NC', 'Alexander'],
  ['37005', 'NC', 'Alleghany'],
  ['37007', 'NC', 'Anson'],
  ['37009', 'NC', 'Ashe'],
  ['37011', 'NC', 'Avery'],
  ['37013', 'NC', 'Beaufort'],
  ['37015', 'NC', 'Bertie'],
  ['37017', 'NC', 'Bladen'],
  ['37019', 'NC', 'Brunswick'],
  ['37021', 'NC', 'Buncombe'],
  ['37023', 'NC', 'Burke'],
  ['37025', 'NC', 'Cabarrus'],
  ['37027', 'NC', 'Caldwell'],
  ['37029', 'NC', 'Camden'],
  ['37031', 'NC', 'Carteret'],
  ['37033', 'NC', 'Caswell'],
  ['37035', 'NC', 'Catawba'],
  ['37037', 'NC', 'Chatham'],
  ['37039', 'NC', 'Cherokee'],
  ['37041', 'NC', 'Chowan'],
  ['37043', 'NC', 'Clay'],
  ['37045', 'NC', 'Cleveland'],
  ['37047', 'NC', 'Columbus'],
  ['37049', 'NC', 'Craven'],
  ['37051', 'NC', 'Cumberland'],
  ['37053', 'NC', 'Currituck'],
  ['37055', 'NC', 'Dare'],
  ['37057', 'NC', 'Davidson'],
  ['37059', 'NC', 'Davie'],
  ['37061', 'NC', 'Duplin'],
  ['37063', 'NC', 'Durham'],
  ['37065', 'NC', 'Edgecombe'],
  ['37067', 'NC', 'Forsyth'],
  ['37069', 'NC', 'Franklin'],
  ['37071', 'NC', 'Gaston'],
  ['37073', 'NC', 'Gates'],
  ['37075', 'NC', 'Graham'],
  ['37077', 'NC', 'Granville'],
  ['37079', 'NC', 'Greene'],
  ['37081', 'NC', 'Guilford'],
  ['37083', 'NC', 'Halifax'],
  ['37085', 'NC', 'Harnett'],
  ['37087', 'NC', 'Haywood'],
  ['37089', 'NC', 'Henderson'],
  ['37091', 'NC', 'Hertford'],
  ['37093', 'NC', 'Hoke'],
  ['37095', 'NC', 'Hyde'],
  ['37097', 'NC', 'Iredell'],
  ['37099', 'NC', 'Jackson'],
  ['37101', 'NC', 'Johnston'],
  ['37103', 'NC', 'Jones'],
  ['37105', 'NC', 'Lee'],
  ['37107', 'NC', 'Lenoir'],
  ['37109', 'NC', 'Lincoln'],
  ['37111', 'NC', 'McDowell'],
  ['37113', 'NC', 'Macon'],
  ['37115', 'NC', 'Madison'],
  ['37117', 'NC', 'Martin'],
  ['37119', 'NC', 'Mecklenburg'],
  ['37121', 'NC', 'Mitchell'],
  ['37123', 'NC', 'Montgomery'],
  ['37125', 'NC', 'Moore'],
  ['37127', 'NC', 'Nash'],
  ['37129', 'NC', 'New Hanover'],
  ['37131', 'NC', 'Northampton'],
  ['37133', 'NC', 'Onslow'],
  ['37135', 'NC', 'Orange'],
  ['37137', 'NC', 'Pamlico'],
  ['37139', 'NC', 'Pasquotank'],
  ['37141', 'NC', 'Pender'],
  ['37143', 'NC', 'Perquimans'],
  ['37145', 'NC', 'Person'],
  ['37147', 'NC', 'Pitt'],
  ['37149', 'NC', 'Polk'],
  ['37151', 'NC', 'Randolph'],
  ['37153', 'NC', 'Richmond'],
  ['37155', 'NC', 'Robeson'],
  ['37157', 'NC', 'Rockingham'],
  ['37159', 'NC', 'Rowan'],
  ['37161', 'NC', 'Rutherford'],
  ['37163', 'NC', 'Sampson'],
  ['37165', 'NC', 'Scotland'],
  ['37167', 'NC', 'Stanly'],
  ['37169', 'NC', 'Stokes'],
  ['37171', 'NC', 'Surry'],
  ['37173', 'NC', 'Swain'],
  ['37175', 'NC', 'Transylvania'],
  ['37177', 'NC', 'Tyrrell'],
  ['37179', 'NC', 'Union'],
  ['37181', 'NC', 'Vance'],
  ['37183', 'NC', 'Wake'],
  ['37185', 'NC', 'Warren'],
  ['37187', 'NC', 'Washington'],
  ['37189', 'NC', 'Watauga'],
  ['37191', 'NC', 'Wayne'],
  ['37193', 'NC', 'Wilkes'],
  ['37195', 'NC', 'Wilson'],
  ['37197', 'NC', 'Yadkin'],
  ['37199', 'NC', 'Yancey'],
  ['38001', 'ND', 'Adams'],
  ['38003', 'ND', 'Barnes'],
  ['38005', 'ND', 'Benson'],
  ['38007', 'ND', 'Billings'],
  ['38009', 'ND', 'Bottineau'],
  ['38011', 'ND', 'Bowman'],
  ['38013', 'ND', 'Burke'],
  ['38015', 'ND', 'Burleigh'],
  ['38017', 'ND', 'Cass'],
  ['38019', 'ND', 'Cavalier'],
  ['38021', 'ND', 'Dickey'],
  ['38023', 'ND', 'Divide'],
  ['38025', 'ND', 'Dunn'],
  ['38027', 'ND', 'Eddy'],
  ['38029', 'ND', 'Emmons'],
  ['38031', 'ND', 'Foster'],
  ['38033', 'ND', 'Golden Valley'],
  ['38035', 'ND', 'Grand Forks'],
  ['38037', 'ND', 'Grant'],
  ['38039', 'ND', 'Griggs'],
  ['38041', 'ND', 'Hettinger'],
  ['38043', 'ND', 'Kidder'],
  ['38045', 'ND', 'LaMoure'],
  ['38047', 'ND', 'Logan'],
  ['38049', 'ND', 'McHenry'],
  ['38051', 'ND', 'McIntosh'],
  ['38053', 'ND', 'McKenzie'],
  ['38055', 'ND', 'McLean'],
  ['38057', 'ND', 'Mercer'],
  ['38059', 'ND', 'Morton'],
  ['38061', 'ND', 'Mountrail'],
  ['38063', 'ND', 'Nelson'],
  ['38065', 'ND', 'Oliver'],
  ['38067', 'ND', 'Pembina'],
  ['38069', 'ND', 'Pierce'],
  ['38071', 'ND', 'Ramsey'],
  ['38073', 'ND', 'Ransom'],
  ['38075', 'ND', 'Renville'],
  ['38077', 'ND', 'Richland'],
  ['38079', 'ND', 'Rolette'],
  ['38081', 'ND', 'Sargent'],
  ['38083', 'ND', 'Sheridan'],
  ['38085', 'ND', 'Sioux'],
  ['38087', 'ND', 'Slope'],
  ['38089', 'ND', 'Stark'],
  ['38091', 'ND', 'Steele'],
  ['38093', 'ND', 'Stutsman'],
  ['38095', 'ND', 'Towner'],
  ['38097', 'ND', 'Traill'],
  ['38099', 'ND', 'Walsh'],
  ['38101', 'ND', 'Ward'],
  ['38103', 'ND', 'Wells'],
  ['38105', 'ND', 'Williams'],
  ['39001', 'OH', 'Adams'],
  ['39003', 'OH', 'Allen'],
  ['39005', 'OH', 'Ashland'],
  ['39007', 'OH', 'Ashtabula'],
  ['39009', 'OH', 'Athens'],
  ['39011', 'OH', 'Auglaize'],
  ['39013', 'OH', 'Belmont'],
  ['39015', 'OH', 'Brown'],
  ['39017', 'OH', 'Butler'],
  ['39019', 'OH', 'Carroll'],
  ['39021', 'OH', 'Champaign'],
  ['39023', 'OH', 'Clark'],
  ['39025', 'OH', 'Clermont'],
  ['39027', 'OH', 'Clinton'],
  ['39029', 'OH', 'Columbiana'],
  ['39031', 'OH', 'Coshocton'],
  ['39033', 'OH', 'Crawford'],
  ['39035', 'OH', 'Cuyahoga'],
  ['39037', 'OH', 'Darke'],
  ['39039', 'OH', 'Defiance'],
  ['39041', 'OH', 'Delaware'],
  ['39043', 'OH', 'Erie'],
  ['39045', 'OH', 'Fairfield'],
  ['39047', 'OH', 'Fayette'],
  ['39049', 'OH', 'Franklin'],
  ['39051', 'OH', 'Fulton'],
  ['39053', 'OH', 'Gallia'],
  ['39055', 'OH', 'Geauga'],
  ['39057', 'OH', 'Greene'],
  ['39059', 'OH', 'Guernsey'],
  ['39061', 'OH', 'Hamilton'],
  ['39063', 'OH', 'Hancock'],
  ['39065', 'OH', 'Hardin'],
  ['39067', 'OH', 'Harrison'],
  ['39069', 'OH', 'Henry'],
  ['39071', 'OH', 'Highland'],
  ['39073', 'OH', 'Hocking'],
  ['39075', 'OH', 'Holmes'],
  ['39077', 'OH', 'Huron'],
  ['39079', 'OH', 'Jackson'],
  ['39081', 'OH', 'Jefferson'],
  ['39083', 'OH', 'Knox'],
  ['39085', 'OH', 'Lake'],
  ['39087', 'OH', 'Lawrence'],
  ['39089', 'OH', 'Licking'],
  ['39091', 'OH', 'Logan'],
  ['39093', 'OH', 'Lorain'],
  ['39095', 'OH', 'Lucas'],
  ['39097', 'OH', 'Madison'],
  ['39099', 'OH', 'Mahoning'],
  ['39101', 'OH', 'Marion'],
  ['39103', 'OH', 'Medina'],
  ['39105', 'OH', 'Meigs'],
  ['39107', 'OH', 'Mercer'],
  ['39109', 'OH', 'Miami'],
  ['39111', 'OH', 'Monroe'],
  ['39113', 'OH', 'Montgomery'],
  ['39115', 'OH', 'Morgan'],
  ['39117', 'OH', 'Morrow'],
  ['39119', 'OH', 'Muskingum'],
  ['39121', 'OH', 'Noble'],
  ['39123', 'OH', 'Ottawa'],
  ['39125', 'OH', 'Paulding'],
  ['39127', 'OH', 'Perry'],
  ['39129', 'OH', 'Pickaway'],
  ['39131', 'OH', 'Pike'],
  ['39133', 'OH', 'Portage'],
  ['39135', 'OH', 'Preble'],
  ['39137', 'OH', 'Putnam'],
  ['39139', 'OH', 'Richland'],
  ['39141', 'OH', 'Ross'],
  ['39143', 'OH', 'Sandusky'],
  ['39145', 'OH', 'Scioto'],
  ['39147', 'OH', 'Seneca'],
  ['39149', 'OH', 'Shelby'],
  ['39151', 'OH', 'Stark'],
  ['39153', 'OH', 'Summit'],
  ['39155', 'OH', 'Trumbull'],
  ['39157', 'OH', 'Tuscarawas'],
  ['39159', 'OH', 'Union'],
  ['39161', 'OH', 'Van Wert'],
  ['39163', 'OH', 'Vinton'],
  ['39165', 'OH', 'Warren'],
  ['39167', 'OH', 'Washington'],
  ['39169', 'OH', 'Wayne'],
  ['39171', 'OH', 'Williams'],
  ['39173', 'OH', 'Wood'],
  ['39175', 'OH', 'Wyandot'],
  ['40001', 'OK', 'Adair'],
  ['40003', 'OK', 'Alfalfa'],
  ['40005', 'OK', 'Atoka'],
  ['40007', 'OK', 'Beaver'],
  ['40009', 'OK', 'Beckham'],
  ['40011', 'OK', 'Blaine'],
  ['40013', 'OK', 'Bryan'],
  ['40015', 'OK', 'Caddo'],
  ['40017', 'OK', 'Canadian'],
  ['40019', 'OK', 'Carter'],
  ['40021', 'OK', 'Cherokee'],
  ['40023', 'OK', 'Choctaw'],
  ['40025', 'OK', 'Cimarron'],
  ['40027', 'OK', 'Cleveland'],
  ['40029', 'OK', 'Coal'],
  ['40031', 'OK', 'Comanche'],
  ['40033', 'OK', 'Cotton'],
  ['40035', 'OK', 'Craig'],
  ['40037', 'OK', 'Creek'],
  ['40039', 'OK', 'Custer'],
  ['40041', 'OK', 'Delaware'],
  ['40043', 'OK', 'Dewey'],
  ['40045', 'OK', 'Ellis'],
  ['40047', 'OK', 'Garfield'],
  ['40049', 'OK', 'Garvin'],
  ['40051', 'OK', 'Grady'],
  ['40053', 'OK', 'Grant'],
  ['40055', 'OK', 'Greer'],
  ['40057', 'OK', 'Harmon'],
  ['40059', 'OK', 'Harper'],
  ['40061', 'OK', 'Haskell'],
  ['40063', 'OK', 'Hughes'],
  ['40065', 'OK', 'Jackson'],
  ['40067', 'OK', 'Jefferson'],
  ['40069', 'OK', 'Johnston'],
  ['40071', 'OK', 'Kay'],
  ['40073', 'OK', 'Kingfisher'],
  ['40075', 'OK', 'Kiowa'],
  ['40077', 'OK', 'Latimer'],
  ['40079', 'OK', 'Le Flore'],
  ['40081', 'OK', 'Lincoln'],
  ['40083', 'OK', 'Logan'],
  ['40085', 'OK', 'Love'],
  ['40087', 'OK', 'McClain'],
  ['40089', 'OK', 'McCurtain'],
  ['40091', 'OK', 'McIntosh'],
  ['40093', 'OK', 'Major'],
  ['40095', 'OK', 'Marshall'],
  ['40097', 'OK', 'Mayes'],
  ['40099', 'OK', 'Murray'],
  ['40101', 'OK', 'Muskogee'],
  ['40103', 'OK', 'Noble'],
  ['40105', 'OK', 'Nowata'],
  ['40107', 'OK', 'Okfuskee'],
  ['40109', 'OK', 'Oklahoma'],
  ['40111', 'OK', 'Okmulgee'],
  ['40113', 'OK', 'Osage'],
  ['40115', 'OK', 'Ottawa'],
  ['40117', 'OK', 'Pawnee'],
  ['40119', 'OK', 'Payne'],
  ['40121', 'OK', 'Pittsburg'],
  ['40123', 'OK', 'Pontotoc'],
  ['40125', 'OK', 'Pottawatomie'],
  ['40127', 'OK', 'Pushmataha'],
  ['40129', 'OK', 'Roger Mills'],
  ['40131', 'OK', 'Rogers'],
  ['40133', 'OK', 'Seminole'],
  ['40135', 'OK', 'Sequoyah'],
  ['40137', 'OK', 'Stephens'],
  ['40139', 'OK', 'Texas'],
  ['40141', 'OK', 'Tillman'],
  ['40143', 'OK', 'Tulsa'],
  ['40145', 'OK', 'Wagoner'],
  ['40147', 'OK', 'Washington'],
  ['40149', 'OK', 'Washita'],
  ['40151', 'OK', 'Woods'],
  ['40153', 'OK', 'Woodward'],
  ['41001', 'OR', 'Baker'],
  ['41003', 'OR', 'Benton'],
  ['41005', 'OR', 'Clackamas'],
  ['41007', 'OR', 'Clatsop'],
  ['41009', 'OR', 'Columbia'],
  ['41011', 'OR', 'Coos'],
  ['41013', 'OR', 'Crook'],
  ['41015', 'OR', 'Curry'],
  ['41017', 'OR', 'Deschutes'],
  ['41019', 'OR', 'Douglas'],
  ['41021', 'OR', 'Gilliam'],
  ['41023', 'OR', 'Grant'],
  ['41025', 'OR', 'Harney'],
  ['41027', 'OR', 'Hood River'],
  ['41029', 'OR', 'Jackson'],
  ['41031', 'OR', 'Jefferson'],
  ['41033', 'OR', 'Josephine'],
  ['41035', 'OR', 'Klamath'],
  ['41037', 'OR', 'Lake'],
  ['41039', 'OR', 'Lane'],
  ['41041', 'OR', 'Lincoln'],
  ['41043', 'OR', 'Linn'],
  ['41045', 'OR', 'Malheur'],
  ['41047', 'OR', 'Marion'],
  ['41049', 'OR', 'Morrow'],
  ['41051', 'OR', 'Multnomah'],
  ['41053', 'OR', 'Polk'],
  ['41055', 'OR', 'Sherman'],
  ['41057', 'OR', 'Tillamook'],
  ['41059', 'OR', 'Umatilla'],
  ['41061', 'OR', 'Union'],
  ['41063', 'OR', 'Wallowa'],
  ['41065', 'OR', 'Wasco'],
  ['41067', 'OR', 'Washington'],
  ['41069', 'OR', 'Wheeler'],
  ['41071', 'OR', 'Yamhill'],
  ['42001', 'PA', 'Adams'],
  ['42003', 'PA', 'Allegheny'],
  ['42005', 'PA', 'Armstrong'],
  ['42007', 'PA', 'Beaver'],
  ['42009', 'PA', 'Bedford'],
  ['42011', 'PA', 'Berks'],
  ['42013', 'PA', 'Blair'],
  ['42015', 'PA', 'Bradford'],
  ['42017', 'PA', 'Bucks'],
  ['42019', 'PA', 'Butler'],
  ['42021', 'PA', 'Cambria'],
  ['42023', 'PA', 'Cameron'],
  ['42025', 'PA', 'Carbon'],
  ['42027', 'PA', 'Centre'],
  ['42029', 'PA', 'Chester'],
  ['42031', 'PA', 'Clarion'],
  ['42033', 'PA', 'Clearfield'],
  ['42035', 'PA', 'Clinton'],
  ['42037', 'PA', 'Columbia'],
  ['42039', 'PA', 'Crawford'],
  ['42041', 'PA', 'Cumberland'],
  ['42043', 'PA', 'Dauphin'],
  ['42045', 'PA', 'Delaware'],
  ['42047', 'PA', 'Elk'],
  ['42049', 'PA', 'Erie'],
  ['42051', 'PA', 'Fayette'],
  ['42053', 'PA', 'Forest'],
  ['42055', 'PA', 'Franklin'],
  ['42057', 'PA', 'Fulton'],
  ['42059', 'PA', 'Greene'],
  ['42061', 'PA', 'Huntingdon'],
  ['42063', 'PA', 'Indiana'],
  ['42065', 'PA', 'Jefferson'],
  ['42067', 'PA', 'Juniata'],
  ['42069', 'PA', 'Lackawanna'],
  ['42071', 'PA', 'Lancaster'],
  ['42073', 'PA', 'Lawrence'],
  ['42075', 'PA', 'Lebanon'],
  ['42077', 'PA', 'Lehigh'],
  ['42079', 'PA', 'Luzerne'],
  ['42081', 'PA', 'Lycoming'],
  ['42083', 'PA', 'McKean'],
  ['42085', 'PA', 'Mercer'],
  ['42087', 'PA', 'Mifflin'],
  ['42089', 'PA', 'Monroe'],
  ['42091', 'PA', 'Montgomery'],
  ['42093', 'PA', 'Montour'],
  ['42095', 'PA', 'Northampton'],
  ['42097', 'PA', 'Northumberland'],
  ['42099', 'PA', 'Perry'],
  ['42101', 'PA', 'Philadelphia'],
  ['42103', 'PA', 'Pike'],
  ['42105', 'PA', 'Potter'],
  ['42107', 'PA', 'Schuylkill'],
  ['42109', 'PA', 'Snyder'],
  ['42111', 'PA', 'Somerset'],
  ['42113', 'PA', 'Sullivan'],
  ['42115', 'PA', 'Susquehanna'],
  ['42117', 'PA', 'Tioga'],
  ['42119', 'PA', 'Union'],
  ['42121', 'PA', 'Venango'],
  ['42123', 'PA', 'Warren'],
  ['42125', 'PA', 'Washington'],
  ['42127', 'PA', 'Wayne'],
  ['42129', 'PA', 'Westmoreland'],
  ['42131', 'PA', 'Wyoming'],
  ['42133', 'PA', 'York'],
  ['44001', 'RI', 'Bristol'],
  ['44003', 'RI', 'Kent'],
  ['44005', 'RI', 'Newport'],
  ['44007', 'RI', 'Providence'],
  ['44009', 'RI', 'Washington'],
  ['45001', 'SC', 'Abbeville'],
  ['45003', 'SC', 'Aiken'],
  ['45005', 'SC', 'Allendale'],
  ['45007', 'SC', 'Anderson'],
  ['45009', 'SC', 'Bamberg'],
  ['45011', 'SC', 'Barnwell'],
  ['45013', 'SC', 'Beaufort'],
  ['45015', 'SC', 'Berkeley'],
  ['45017', 'SC', 'Calhoun'],
  ['45019', 'SC', 'Charleston'],
  ['45021', 'SC', 'Cherokee'],
  ['45023', 'SC', 'Chester'],
  ['45025', 'SC', 'Chesterfield'],
  ['45027', 'SC', 'Clarendon'],
  ['45029', 'SC', 'Colleton'],
  ['45031', 'SC', 'Darlington'],
  ['45033', 'SC', 'Dillon'],
  ['45035', 'SC', 'Dorchester'],
  ['45037', 'SC', 'Edgefield'],
  ['45039', 'SC', 'Fairfield'],
  ['45041', 'SC', 'Florence'],
  ['45043', 'SC', 'Georgetown'],
  ['45045', 'SC', 'Greenville'],
  ['45047', 'SC', 'Greenwood'],
  ['45049', 'SC', 'Hampton'],
  ['45051', 'SC', 'Horry'],
  ['45053', 'SC', 'Jasper'],
  ['45055', 'SC', 'Kershaw'],
  ['45057', 'SC', 'Lancaster'],
  ['45059', 'SC', 'Laurens'],
  ['45061', 'SC', 'Lee'],
  ['45063', 'SC', 'Lexington'],
  ['45065', 'SC', 'McCormick'],
  ['45067', 'SC', 'Marion'],
  ['45069', 'SC', 'Marlboro'],
  ['45071', 'SC', 'Newberry'],
  ['45073', 'SC', 'Oconee'],
  ['45075', 'SC', 'Orangeburg'],
  ['45077', 'SC', 'Pickens'],
  ['45079', 'SC', 'Richland'],
  ['45081', 'SC', 'Saluda'],
  ['45083', 'SC', 'Spartanburg'],
  ['45085', 'SC', 'Sumter'],
  ['45087', 'SC', 'Union'],
  ['45089', 'SC', 'Williamsburg'],
  ['45091', 'SC', 'York'],
  ['46003', 'SD', 'Aurora'],
  ['46005', 'SD', 'Beadle'],
  ['46007', 'SD', 'Bennett'],
  ['46009', 'SD', 'Bon Homme'],
  ['46011', 'SD', 'Brookings'],
  ['46013', 'SD', 'Brown'],
  ['46015', 'SD', 'Brule'],
  ['46017', 'SD', 'Buffalo'],
  ['46019', 'SD', 'Butte'],
  ['46021', 'SD', 'Campbell'],
  ['46023', 'SD', 'Charles Mix'],
  ['46025', 'SD', 'Clark'],
  ['46027', 'SD', 'Clay'],
  ['46029', 'SD', 'Codington'],
  ['46031', 'SD', 'Corson'],
  ['46033', 'SD', 'Custer'],
  ['46035', 'SD', 'Davison'],
  ['46037', 'SD', 'Day'],
  ['46039', 'SD', 'Deuel'],
  ['46041', 'SD', 'Dewey'],
  ['46043', 'SD', 'Douglas'],
  ['46045', 'SD', 'Edmunds'],
  ['46047', 'SD', 'Fall River'],
  ['46049', 'SD', 'Faulk'],
  ['46051', 'SD', 'Grant'],
  ['46053', 'SD', 'Gregory'],
  ['46055', 'SD', 'Haakon'],
  ['46057', 'SD', 'Hamlin'],
  ['46059', 'SD', 'Hand'],
  ['46061', 'SD', 'Hanson'],
  ['46063', 'SD', 'Harding'],
  ['46065', 'SD', 'Hughes'],
  ['46067', 'SD', 'Hutchinson'],
  ['46069', 'SD', 'Hyde'],
  ['46071', 'SD', 'Jackson'],
  ['46073', 'SD', 'Jerauld'],
  ['46075', 'SD', 'Jones'],
  ['46077', 'SD', 'Kingsbury'],
  ['46079', 'SD', 'Lake'],
  ['46081', 'SD', 'Lawrence'],
  ['46083', 'SD', 'Lincoln'],
  ['46085', 'SD', 'Lyman'],
  ['46087', 'SD', 'McCook'],
  ['46089', 'SD', 'McPherson'],
  ['46091', 'SD', 'Marshall'],
  ['46093', 'SD', 'Meade'],
  ['46095', 'SD', 'Mellette'],
  ['46097', 'SD', 'Miner'],
  ['46099', 'SD', 'Minnehaha'],
  ['46101', 'SD', 'Moody'],
  ['46102', 'SD', 'Oglala Lakota'],
  ['46103', 'SD', 'Pennington'],
  ['46105', 'SD', 'Perkins'],
  ['46107', 'SD', 'Potter'],
  ['46109', 'SD', 'Roberts'],
  ['46111', 'SD', 'Sanborn'],
  ['46113', 'SD', 'Shannon'],
  ['46115', 'SD', 'Spink'],
  ['46117', 'SD', 'Stanley'],
  ['46119', 'SD', 'Sully'],
  ['46121', 'SD', 'Todd'],
  ['46123', 'SD', 'Tripp'],
  ['46125', 'SD', 'Turner'],
  ['46127', 'SD', 'Union'],
  ['46135', 'SD', 'Yankton'],
  ['46137', 'SD', 'Ziebach'],
  ['47001', 'TN', 'Anderson'],
  ['47003', 'TN', 'Bedford'],
  ['47005', 'TN', 'Benton'],
  ['47007', 'TN', 'Bledsoe'],
  ['47009', 'TN', 'Blount'],
  ['47011', 'TN', 'Bradley'],
  ['47013', 'TN', 'Campbell'],
  ['47015', 'TN', 'Cannon'],
  ['47017', 'TN', 'Carroll'],
  ['47019', 'TN', 'Carter'],
  ['47021', 'TN', 'Cheatham'],
  ['47023', 'TN', 'Chester'],
  ['47025', 'TN', 'Claiborne'],
  ['47027', 'TN', 'Clay'],
  ['47029', 'TN', 'Cocke'],
  ['47031', 'TN', 'Coffee'],
  ['47033', 'TN', 'Crockett'],
  ['47035', 'TN', 'Cumberland'],
  ['47037', 'TN', 'Davidson'],
  ['47039', 'TN', 'Decatur'],
  ['47041', 'TN', 'DeKalb'],
  ['47043', 'TN', 'Dickson'],
  ['47045', 'TN', 'Dyer'],
  ['47047', 'TN', 'Fayette'],
  ['47049', 'TN', 'Fentress'],
  ['47051', 'TN', 'Franklin'],
  ['47053', 'TN', 'Gibson'],
  ['47055', 'TN', 'Giles'],
  ['47057', 'TN', 'Grainger'],
  ['47059', 'TN', 'Greene'],
  ['47061', 'TN', 'Grundy'],
  ['47063', 'TN', 'Hamblen'],
  ['47065', 'TN', 'Hamilton'],
  ['47067', 'TN', 'Hancock'],
  ['47069', 'TN', 'Hardeman'],
  ['47071', 'TN', 'Hardin'],
  ['47073', 'TN', 'Hawkins'],
  ['47075', 'TN', 'Haywood'],
  ['47077', 'TN', 'Henderson'],
  ['47079', 'TN', 'Henry'],
  ['47081', 'TN', 'Hickman'],
  ['47083', 'TN', 'Houston'],
  ['47085', 'TN', 'Humphreys'],
  ['47087', 'TN', 'Jackson'],
  ['47089', 'TN', 'Jefferson'],
  ['47091', 'TN', 'Johnson'],
  ['47093', 'TN', 'Knox'],
  ['47095', 'TN', 'Lake'],
  ['47097', 'TN', 'Lauderdale'],
  ['47099', 'TN', 'Lawrence'],
  ['47101', 'TN', 'Lewis'],
  ['47103', 'TN', 'Lincoln'],
  ['47105', 'TN', 'Loudon'],
  ['47107', 'TN', 'McMinn'],
  ['47109', 'TN', 'McNairy'],
  ['47111', 'TN', 'Macon'],
  ['47113', 'TN', 'Madison'],
  ['47115', 'TN', 'Marion'],
  ['47117', 'TN', 'Marshall'],
  ['47119', 'TN', 'Maury'],
  ['47121', 'TN', 'Meigs'],
  ['47123', 'TN', 'Monroe'],
  ['47125', 'TN', 'Montgomery'],
  ['47127', 'TN', 'Moore'],
  ['47129', 'TN', 'Morgan'],
  ['47131', 'TN', 'Obion'],
  ['47133', 'TN', 'Overton'],
  ['47135', 'TN', 'Perry'],
  ['47137', 'TN', 'Pickett'],
  ['47139', 'TN', 'Polk'],
  ['47141', 'TN', 'Putnam'],
  ['47143', 'TN', 'Rhea'],
  ['47145', 'TN', 'Roane'],
  ['47147', 'TN', 'Robertson'],
  ['47149', 'TN', 'Rutherford'],
  ['47151', 'TN', 'Scott'],
  ['47153', 'TN', 'Sequatchie'],
  ['47155', 'TN', 'Sevier'],
  ['47157', 'TN', 'Shelby'],
  ['47159', 'TN', 'Smith'],
  ['47161', 'TN', 'Stewart'],
  ['47163', 'TN', 'Sullivan'],
  ['47165', 'TN', 'Sumner'],
  ['47167', 'TN', 'Tipton'],
  ['47169', 'TN', 'Trousdale'],
  ['47171', 'TN', 'Unicoi'],
  ['47173', 'TN', 'Union'],
  ['47175', 'TN', 'Van Buren'],
  ['47177', 'TN', 'Warren'],
  ['47179', 'TN', 'Washington'],
  ['47181', 'TN', 'Wayne'],
  ['47183', 'TN', 'Weakley'],
  ['47185', 'TN', 'White'],
  ['47187', 'TN', 'Williamson'],
  ['47189', 'TN', 'Wilson'],
  ['48001', 'TX', 'Anderson'],
  ['48003', 'TX', 'Andrews'],
  ['48005', 'TX', 'Angelina'],
  ['48007', 'TX', 'Aransas'],
  ['48009', 'TX', 'Archer'],
  ['48011', 'TX', 'Armstrong'],
  ['48013', 'TX', 'Atascosa'],
  ['48015', 'TX', 'Austin'],
  ['48017', 'TX', 'Bailey'],
  ['48019', 'TX', 'Bandera'],
  ['48021', 'TX', 'Bastrop'],
  ['48023', 'TX', 'Baylor'],
  ['48025', 'TX', 'Bee'],
  ['48027', 'TX', 'Bell'],
  ['48029', 'TX', 'Bexar'],
  ['48031', 'TX', 'Blanco'],
  ['48033', 'TX', 'Borden'],
  ['48035', 'TX', 'Bosque'],
  ['48037', 'TX', 'Bowie'],
  ['48039', 'TX', 'Brazoria'],
  ['48041', 'TX', 'Brazos'],
  ['48043', 'TX', 'Brewster'],
  ['48045', 'TX', 'Briscoe'],
  ['48047', 'TX', 'Brooks'],
  ['48049', 'TX', 'Brown'],
  ['48051', 'TX', 'Burleson'],
  ['48053', 'TX', 'Burnet'],
  ['48055', 'TX', 'Caldwell'],
  ['48057', 'TX', 'Calhoun'],
  ['48059', 'TX', 'Callahan'],
  ['48061', 'TX', 'Cameron'],
  ['48063', 'TX', 'Camp'],
  ['48065', 'TX', 'Carson'],
  ['48067', 'TX', 'Cass'],
  ['48069', 'TX', 'Castro'],
  ['48071', 'TX', 'Chambers'],
  ['48073', 'TX', 'Cherokee'],
  ['48075', 'TX', 'Childress'],
  ['48077', 'TX', 'Clay'],
  ['48079', 'TX', 'Cochran'],
  ['48081', 'TX', 'Coke'],
  ['48083', 'TX', 'Coleman'],
  ['48085', 'TX', 'Collin'],
  ['48087', 'TX', 'Collingsworth'],
  ['48089', 'TX', 'Colorado'],
  ['48091', 'TX', 'Comal'],
  ['48093', 'TX', 'Comanche'],
  ['48095', 'TX', 'Concho'],
  ['48097', 'TX', 'Cooke'],
  ['48099', 'TX', 'Coryell'],
  ['48101', 'TX', 'Cottle'],
  ['48103', 'TX', 'Crane'],
  ['48105', 'TX', 'Crockett'],
  ['48107', 'TX', 'Crosby'],
  ['48109', 'TX', 'Culberson'],
  ['48111', 'TX', 'Dallam'],
  ['48113', 'TX', 'Dallas'],
  ['48115', 'TX', 'Dawson'],
  ['48117', 'TX', 'Deaf Smith'],
  ['48119', 'TX', 'Delta'],
  ['48121', 'TX', 'Denton'],
  ['48123', 'TX', 'DeWitt'],
  ['48125', 'TX', 'Dickens'],
  ['48127', 'TX', 'Dimmit'],
  ['48129', 'TX', 'Donley'],
  ['48131', 'TX', 'Duval'],
  ['48133', 'TX', 'Eastland'],
  ['48135', 'TX', 'Ector'],
  ['48137', 'TX', 'Edwards'],
  ['48139', 'TX', 'Ellis'],
  ['48141', 'TX', 'El Paso'],
  ['48143', 'TX', 'Erath'],
  ['48145', 'TX', 'Falls'],
  ['48147', 'TX', 'Fannin'],
  ['48149', 'TX', 'Fayette'],
  ['48151', 'TX', 'Fisher'],
  ['48153', 'TX', 'Floyd'],
  ['48155', 'TX', 'Foard'],
  ['48157', 'TX', 'Fort Bend'],
  ['48159', 'TX', 'Franklin'],
  ['48161', 'TX', 'Freestone'],
  ['48163', 'TX', 'Frio'],
  ['48165', 'TX', 'Gaines'],
  ['48167', 'TX', 'Galveston'],
  ['48169', 'TX', 'Garza'],
  ['48171', 'TX', 'Gillespie'],
  ['48173', 'TX', 'Glasscock'],
  ['48175', 'TX', 'Goliad'],
  ['48177', 'TX', 'Gonzales'],
  ['48179', 'TX', 'Gray'],
  ['48181', 'TX', 'Grayson'],
  ['48183', 'TX', 'Gregg'],
  ['48185', 'TX', 'Grimes'],
  ['48187', 'TX', 'Guadalupe'],
  ['48189', 'TX', 'Hale'],
  ['48191', 'TX', 'Hall'],
  ['48193', 'TX', 'Hamilton'],
  ['48195', 'TX', 'Hansford'],
  ['48197', 'TX', 'Hardeman'],
  ['48199', 'TX', 'Hardin'],
  ['48201', 'TX', 'Harris'],
  ['48203', 'TX', 'Harrison'],
  ['48205', 'TX', 'Hartley'],
  ['48207', 'TX', 'Haskell'],
  ['48209', 'TX', 'Hays'],
  ['48211', 'TX', 'Hemphill'],
  ['48213', 'TX', 'Henderson'],
  ['48215', 'TX', 'Hidalgo'],
  ['48217', 'TX', 'Hill'],
  ['48219', 'TX', 'Hockley'],
  ['48221', 'TX', 'Hood'],
  ['48223', 'TX', 'Hopkins'],
  ['48225', 'TX', 'Houston'],
  ['48227', 'TX', 'Howard'],
  ['48229', 'TX', 'Hudspeth'],
  ['48231', 'TX', 'Hunt'],
  ['48233', 'TX', 'Hutchinson'],
  ['48235', 'TX', 'Irion'],
  ['48237', 'TX', 'Jack'],
  ['48239', 'TX', 'Jackson'],
  ['48241', 'TX', 'Jasper'],
  ['48243', 'TX', 'Jeff Davis'],
  ['48245', 'TX', 'Jefferson'],
  ['48247', 'TX', 'Jim Hogg'],
  ['48249', 'TX', 'Jim Wells'],
  ['48251', 'TX', 'Johnson'],
  ['48253', 'TX', 'Jones'],
  ['48255', 'TX', 'Karnes'],
  ['48257', 'TX', 'Kaufman'],
  ['48259', 'TX', 'Kendall'],
  ['48261', 'TX', 'Kenedy'],
  ['48263', 'TX', 'Kent'],
  ['48265', 'TX', 'Kerr'],
  ['48267', 'TX', 'Kimble'],
  ['48269', 'TX', 'King'],
  ['48271', 'TX', 'Kinney'],
  ['48273', 'TX', 'Kleberg'],
  ['48275', 'TX', 'Knox'],
  ['48277', 'TX', 'Lamar'],
  ['48279', 'TX', 'Lamb'],
  ['48281', 'TX', 'Lampasas'],
  ['48283', 'TX', 'La Salle'],
  ['48285', 'TX', 'Lavaca'],
  ['48287', 'TX', 'Lee'],
  ['48289', 'TX', 'Leon'],
  ['48291', 'TX', 'Liberty'],
  ['48293', 'TX', 'Limestone'],
  ['48295', 'TX', 'Lipscomb'],
  ['48297', 'TX', 'Live Oak'],
  ['48299', 'TX', 'Llano'],
  ['48301', 'TX', 'Loving'],
  ['48303', 'TX', 'Lubbock'],
  ['48305', 'TX', 'Lynn'],
  ['48307', 'TX', 'McCulloch'],
  ['48309', 'TX', 'McLennan'],
  ['48311', 'TX', 'McMullen'],
  ['48313', 'TX', 'Madison'],
  ['48315', 'TX', 'Marion'],
  ['48317', 'TX', 'Martin'],
  ['48319', 'TX', 'Mason'],
  ['48321', 'TX', 'Matagorda'],
  ['48323', 'TX', 'Maverick'],
  ['48325', 'TX', 'Medina'],
  ['48327', 'TX', 'Menard'],
  ['48329', 'TX', 'Midland'],
  ['48331', 'TX', 'Milam'],
  ['48333', 'TX', 'Mills'],
  ['48335', 'TX', 'Mitchell'],
  ['48337', 'TX', 'Montague'],
  ['48339', 'TX', 'Montgomery'],
  ['48341', 'TX', 'Moore'],
  ['48343', 'TX', 'Morris'],
  ['48345', 'TX', 'Motley'],
  ['48347', 'TX', 'Nacogdoches'],
  ['48349', 'TX', 'Navarro'],
  ['48351', 'TX', 'Newton'],
  ['48353', 'TX', 'Nolan'],
  ['48355', 'TX', 'Nueces'],
  ['48357', 'TX', 'Ochiltree'],
  ['48359', 'TX', 'Oldham'],
  ['48361', 'TX', 'Orange'],
  ['48363', 'TX', 'Palo Pinto'],
  ['48365', 'TX', 'Panola'],
  ['48367', 'TX', 'Parker'],
  ['48369', 'TX', 'Parmer'],
  ['48371', 'TX', 'Pecos'],
  ['48373', 'TX', 'Polk'],
  ['48375', 'TX', 'Potter'],
  ['48377', 'TX', 'Presidio'],
  ['48379', 'TX', 'Rains'],
  ['48381', 'TX', 'Randall'],
  ['48383', 'TX', 'Reagan'],
  ['48385', 'TX', 'Real'],
  ['48387', 'TX', 'Red River'],
  ['48389', 'TX', 'Reeves'],
  ['48391', 'TX', 'Refugio'],
  ['48393', 'TX', 'Roberts'],
  ['48395', 'TX', 'Robertson'],
  ['48397', 'TX', 'Rockwall'],
  ['48399', 'TX', 'Runnels'],
  ['48401', 'TX', 'Rusk'],
  ['48403', 'TX', 'Sabine'],
  ['48405', 'TX', 'San Augustine'],
  ['48407', 'TX', 'San Jacinto'],
  ['48409', 'TX', 'San Patricio'],
  ['48411', 'TX', 'San Saba'],
  ['48413', 'TX', 'Schleicher'],
  ['48415', 'TX', 'Scurry'],
  ['48417', 'TX', 'Shackelford'],
  ['48419', 'TX', 'Shelby'],
  ['48421', 'TX', 'Sherman'],
  ['48423', 'TX', 'Smith'],
  ['48425', 'TX', 'Somervell'],
  ['48427', 'TX', 'Starr'],
  ['48429', 'TX', 'Stephens'],
  ['48431', 'TX', 'Sterling'],
  ['48433', 'TX', 'Stonewall'],
  ['48435', 'TX', 'Sutton'],
  ['48437', 'TX', 'Swisher'],
  ['48439', 'TX', 'Tarrant'],
  ['48441', 'TX', 'Taylor'],
  ['48443', 'TX', 'Terrell'],
  ['48445', 'TX', 'Terry'],
  ['48447', 'TX', 'Throckmorton'],
  ['48449', 'TX', 'Titus'],
  ['48451', 'TX', 'Tom Green'],
  ['48453', 'TX', 'Travis'],
  ['48455', 'TX', 'Trinity'],
  ['48457', 'TX', 'Tyler'],
  ['48459', 'TX', 'Upshur'],
  ['48461', 'TX', 'Upton'],
  ['48463', 'TX', 'Uvalde'],
  ['48465', 'TX', 'Val Verde'],
  ['48467', 'TX', 'Van Zandt'],
  ['48469', 'TX', 'Victoria'],
  ['48471', 'TX', 'Walker'],
  ['48473', 'TX', 'Waller'],
  ['48475', 'TX', 'Ward'],
  ['48477', 'TX', 'Washington'],
  ['48479', 'TX', 'Webb'],
  ['48481', 'TX', 'Wharton'],
  ['48483', 'TX', 'Wheeler'],
  ['48485', 'TX', 'Wichita'],
  ['48487', 'TX', 'Wilbarger'],
  ['48489', 'TX', 'Willacy'],
  ['48491', 'TX', 'Williamson'],
  ['48493', 'TX', 'Wilson'],
  ['48495', 'TX', 'Winkler'],
  ['48497', 'TX', 'Wise'],
  ['48499', 'TX', 'Wood'],
  ['48501', 'TX', 'Yoakum'],
  ['48503', 'TX', 'Young'],
  ['48505', 'TX', 'Zapata'],
  ['48507', 'TX', 'Zavala'],
  ['49001', 'UT', 'Beaver'],
  ['49003', 'UT', 'Box Elder'],
  ['49005', 'UT', 'Cache'],
  ['49007', 'UT', 'Carbon'],
  ['49009', 'UT', 'Daggett'],
  ['49011', 'UT', 'Davis'],
  ['49013', 'UT', 'Duchesne'],
  ['49015', 'UT', 'Emery'],
  ['49017', 'UT', 'Garfield'],
  ['49019', 'UT', 'Grand'],
  ['49021', 'UT', 'Iron'],
  ['49023', 'UT', 'Juab'],
  ['49025', 'UT', 'Kane'],
  ['49027', 'UT', 'Millard'],
  ['49029', 'UT', 'Morgan'],
  ['49031', 'UT', 'Piute'],
  ['49033', 'UT', 'Rich'],
  ['49035', 'UT', 'Salt Lake'],
  ['49037', 'UT', 'San Juan'],
  ['49039', 'UT', 'Sanpete'],
  ['49041', 'UT', 'Sevier'],
  ['49043', 'UT', 'Summit'],
  ['49045', 'UT', 'Tooele'],
  ['49047', 'UT', 'Uintah'],
  ['49049', 'UT', 'Utah'],
  ['49051', 'UT', 'Wasatch'],
  ['49053', 'UT', 'Washington'],
  ['49055', 'UT', 'Wayne'],
  ['49057', 'UT', 'Weber'],
  ['50001', 'VT', 'Addison'],
  ['50003', 'VT', 'Bennington'],
  ['50005', 'VT', 'Caledonia'],
  ['50007', 'VT', 'Chittenden'],
  ['50009', 'VT', 'Essex'],
  ['50011', 'VT', 'Franklin'],
  ['50013', 'VT', 'Grand Isle'],
  ['50015', 'VT', 'Lamoille'],
  ['50017', 'VT', 'Orange'],
  ['50019', 'VT', 'Orleans'],
  ['50021', 'VT', 'Rutland'],
  ['50023', 'VT', 'Washington'],
  ['50025', 'VT', 'Windham'],
  ['50027', 'VT', 'Windsor'],
  ['51001', 'VA', 'Accomack'],
  ['51003', 'VA', 'Albemarle'],
  ['51005', 'VA', 'Alleghany'],
  ['51007', 'VA', 'Amelia'],
  ['51009', 'VA', 'Amherst'],
  ['51011', 'VA', 'Appomattox'],
  ['51013', 'VA', 'Arlington'],
  ['51015', 'VA', 'Augusta'],
  ['51017', 'VA', 'Bath'],
  ['51019', 'VA', 'Bedford'],
  ['51021', 'VA', 'Bland'],
  ['51023', 'VA', 'Botetourt'],
  ['51025', 'VA', 'Brunswick'],
  ['51027', 'VA', 'Buchanan'],
  ['51029', 'VA', 'Buckingham'],
  ['51031', 'VA', 'Campbell'],
  ['51033', 'VA', 'Caroline'],
  ['51035', 'VA', 'Carroll'],
  ['51036', 'VA', 'Charles'],
  ['51037', 'VA', 'Charlotte'],
  ['51041', 'VA', 'Chesterfield'],
  ['51043', 'VA', 'Clarke'],
  ['51045', 'VA', 'Craig'],
  ['51047', 'VA', 'Culpeper'],
  ['51049', 'VA', 'Cumberland'],
  ['51051', 'VA', 'Dickenson'],
  ['51053', 'VA', 'Dinwiddie'],
  ['51057', 'VA', 'Essex'],
  ['51059', 'VA', 'Fairfax'],
  ['51061', 'VA', 'Fauquier'],
  ['51063', 'VA', 'Floyd'],
  ['51065', 'VA', 'Fluvanna'],
  ['51067', 'VA', 'Franklin'],
  ['51069', 'VA', 'Frederick'],
  ['51071', 'VA', 'Giles'],
  ['51073', 'VA', 'Gloucester'],
  ['51075', 'VA', 'Goochland'],
  ['51077', 'VA', 'Grayson'],
  ['51079', 'VA', 'Greene'],
  ['51081', 'VA', 'Greensville'],
  ['51083', 'VA', 'Halifax'],
  ['51085', 'VA', 'Hanover'],
  ['51087', 'VA', 'Henrico'],
  ['51089', 'VA', 'Henry'],
  ['51091', 'VA', 'Highland'],
  ['51093', 'VA', 'Isle of Wight'],
  ['51095', 'VA', 'James'],
  ['51097', 'VA', 'King and Queen'],
  ['51099', 'VA', 'King George'],
  ['51101', 'VA', 'King William'],
  ['51103', 'VA', 'Lancaster'],
  ['51105', 'VA', 'Lee'],
  ['51107', 'VA', 'Loudoun'],
  ['51109', 'VA', 'Louisa'],
  ['51111', 'VA', 'Lunenburg'],
  ['51113', 'VA', 'Madison'],
  ['51115', 'VA', 'Mathews'],
  ['51117', 'VA', 'Mecklenburg'],
  ['51119', 'VA', 'Middlesex'],
  ['51121', 'VA', 'Montgomery'],
  ['51125', 'VA', 'Nelson'],
  ['51127', 'VA', 'New Kent'],
  ['51131', 'VA', 'Northampton'],
  ['51133', 'VA', 'Northumberland'],
  ['51135', 'VA', 'Nottoway'],
  ['51137', 'VA', 'Orange'],
  ['51139', 'VA', 'Page'],
  ['51141', 'VA', 'Patrick'],
  ['51143', 'VA', 'Pittsylvania'],
  ['51145', 'VA', 'Powhatan'],
  ['51147', 'VA', 'Prince Edward'],
  ['51149', 'VA', 'Prince George'],
  ['51153', 'VA', 'Prince William'],
  ['51155', 'VA', 'Pulaski'],
  ['51157', 'VA', 'Rappahannock'],
  ['51159', 'VA', 'Richmond'],
  ['51161', 'VA', 'Roanoke'],
  ['51163', 'VA', 'Rockbridge'],
  ['51165', 'VA', 'Rockingham'],
  ['51167', 'VA', 'Russell'],
  ['51169', 'VA', 'Scott'],
  ['51171', 'VA', 'Shenandoah'],
  ['51173', 'VA', 'Smyth'],
  ['51175', 'VA', 'Southampton'],
  ['51177', 'VA', 'Spotsylvania'],
  ['51179', 'VA', 'Stafford'],
  ['51181', 'VA', 'Surry'],
  ['51183', 'VA', 'Sussex'],
  ['51185', 'VA', 'Tazewell'],
  ['51187', 'VA', 'Warren'],
  ['51191', 'VA', 'Washington'],
  ['51193', 'VA', 'Westmoreland'],
  ['51195', 'VA', 'Wise'],
  ['51197', 'VA', 'Wythe'],
  ['51199', 'VA', 'York'],
  ['51510', 'VA', 'Alexandria', 0],
  ['51515', 'VA', 'Bedford', 0],
  ['51520', 'VA', 'Bristol', 0],
  ['51530', 'VA', 'Buena Vista', 0],
  ['51540', 'VA', 'Charlottesville', 0],
  ['51550', 'VA', 'Chesapeake', 0],
  ['51570', 'VA', 'Colonial Heights', 0],
  ['51580', 'VA', 'Covington', 0],
  ['51590', 'VA', 'Danville', 0],
  ['51595', 'VA', 'Emporia', 0],
  ['51600', 'VA', 'Fairfax', 0],
  ['51610', 'VA', 'Falls Church', 0],
  ['51620', 'VA', 'Franklin', 0],
  ['51630', 'VA', 'Fredericksburg', 0],
  ['51640', 'VA', 'Galax', 0],
  ['51650', 'VA', 'Hampton', 0],
  ['51660', 'VA', 'Harrisonburg', 0],
  ['51670', 'VA', 'Hopewell', 0],
  ['51678', 'VA', 'Lexington', 0],
  ['51680', 'VA', 'Lynchburg', 0],
  ['51683', 'VA', 'Manassas', 0],
  ['51685', 'VA', 'Manassas Park', 0],
  ['51690', 'VA', 'Martinsville', 0],
  ['51700', 'VA', 'Newport News', 0],
  ['51710', 'VA', 'Norfolk', 0],
  ['51720', 'VA', 'Norton', 0],
  ['51730', 'VA', 'Petersburg', 0],
  ['51735', 'VA', 'Poquoson', 0],
  ['51740', 'VA', 'Portsmouth', 0],
  ['51750', 'VA', 'Radford', 0],
  ['51760', 'VA', 'Richmond', 0],
  ['51770', 'VA', 'Roanoke', 0],
  ['51775', 'VA', 'Salem', 0],
  ['51790', 'VA', 'Staunton', 0],
  ['51800', 'VA', 'Suffolk', 0],
  ['51810', 'VA', 'Virginia Beach', 0],
  ['51820', 'VA', 'Waynesboro', 0],
  ['51830', 'VA', 'Williamsburg', 0],
  ['51840', 'VA', 'Winchester', 0],
  ['53001', 'WA', 'Adams'],
  ['53003', 'WA', 'Asotin'],
  ['53005', 'WA', 'Benton'],
  ['53007', 'WA', 'Chelan'],
  ['53009', 'WA', 'Clallam'],
  ['53011', 'WA', 'Clark'],
  ['53013', 'WA', 'Columbia'],
  ['53015', 'WA', 'Cowlitz'],
  ['53017', 'WA', 'Douglas'],
  ['53019', 'WA', 'Ferry'],
  ['53021', 'WA', 'Franklin'],
  ['53023', 'WA', 'Garfield'],
  ['53025', 'WA', 'Grant'],
  ['53027', 'WA', 'Grays Harbor'],
  ['53029', 'WA', 'Island'],
  ['53031', 'WA', 'Jefferson'],
  ['53033', 'WA', 'King'],
  ['53035', 'WA', 'Kitsap'],
  ['53037', 'WA', 'Kittitas'],
  ['53039', 'WA', 'Klickitat'],
  ['53041', 'WA', 'Lewis'],
  ['53043', 'WA', 'Lincoln'],
  ['53045', 'WA', 'Mason'],
  ['53047', 'WA', 'Okanogan'],
  ['53049', 'WA', 'Pacific'],
  ['53051', 'WA', 'Pend Oreille'],
  ['53053', 'WA', 'Pierce'],
  ['53055', 'WA', 'San Juan'],
  ['53057', 'WA', 'Skagit'],
  ['53059', 'WA', 'Skamania'],
  ['53061', 'WA', 'Snohomish'],
  ['53063', 'WA', 'Spokane'],
  ['53065', 'WA', 'Stevens'],
  ['53067', 'WA', 'Thurston'],
  ['53069', 'WA', 'Wahkiakum'],
  ['53071', 'WA', 'Walla Walla'],
  ['53073', 'WA', 'Whatcom'],
  ['53075', 'WA', 'Whitman'],
  ['53077', 'WA', 'Yakima'],
  ['54001', 'WV', 'Barbour'],
  ['54003', 'WV', 'Berkeley'],
  ['54005', 'WV', 'Boone'],
  ['54007', 'WV', 'Braxton'],
  ['54009', 'WV', 'Brooke'],
  ['54011', 'WV', 'Cabell'],
  ['54013', 'WV', 'Calhoun'],
  ['54015', 'WV', 'Clay'],
  ['54017', 'WV', 'Doddridge'],
  ['54019', 'WV', 'Fayette'],
  ['54021', 'WV', 'Gilmer'],
  ['54023', 'WV', 'Grant'],
  ['54025', 'WV', 'Greenbrier'],
  ['54027', 'WV', 'Hampshire'],
  ['54029', 'WV', 'Hancock'],
  ['54031', 'WV', 'Hardy'],
  ['54033', 'WV', 'Harrison'],
  ['54035', 'WV', 'Jackson'],
  ['54037', 'WV', 'Jefferson'],
  ['54039', 'WV', 'Kanawha'],
  ['54041', 'WV', 'Lewis'],
  ['54043', 'WV', 'Lincoln'],
  ['54045', 'WV', 'Logan'],
  ['54047', 'WV', 'McDowell'],
  ['54049', 'WV', 'Marion'],
  ['54051', 'WV', 'Marshall'],
  ['54053', 'WV', 'Mason'],
  ['54055', 'WV', 'Mercer'],
  ['54057', 'WV', 'Mineral'],
  ['54059', 'WV', 'Mingo'],
  ['54061', 'WV', 'Monongalia'],
  ['54063', 'WV', 'Monroe'],
  ['54065', 'WV', 'Morgan'],
  ['54067', 'WV', 'Nicholas'],
  ['54069', 'WV', 'Ohio'],
  ['54071', 'WV', 'Pendleton'],
  ['54073', 'WV', 'Pleasants'],
  ['54075', 'WV', 'Pocahontas'],
  ['54077', 'WV', 'Preston'],
  ['54079', 'WV', 'Putnam'],
  ['54081', 'WV', 'Raleigh'],
  ['54083', 'WV', 'Randolph'],
  ['54085', 'WV', 'Ritchie'],
  ['54087', 'WV', 'Roane'],
  ['54089', 'WV', 'Summers'],
  ['54091', 'WV', 'Taylor'],
  ['54093', 'WV', 'Tucker'],
  ['54095', 'WV', 'Tyler'],
  ['54097', 'WV', 'Upshur'],
  ['54099', 'WV', 'Wayne'],
  ['54101', 'WV', 'Webster'],
  ['54103', 'WV', 'Wetzel'],
  ['54105', 'WV', 'Wirt'],
  ['54107', 'WV', 'Wood'],
  ['54109', 'WV', 'Wyoming'],
  ['55001', 'WI', 'Adams'],
  ['55003', 'WI', 'Ashland'],
  ['55005', 'WI', 'Barron'],
  ['55007', 'WI', 'Bayfield'],
  ['55009', 'WI', 'Brown'],
  ['55011', 'WI', 'Buffalo'],
  ['55013', 'WI', 'Burnett'],
  ['55015', 'WI', 'Calumet'],
  ['55017', 'WI', 'Chippewa'],
  ['55019', 'WI', 'Clark'],
  ['55021', 'WI', 'Columbia'],
  ['55023', 'WI', 'Crawford'],
  ['55025', 'WI', 'Dane'],
  ['55027', 'WI', 'Dodge'],
  ['55029', 'WI', 'Door'],
  ['55031', 'WI', 'Douglas'],
  ['55033', 'WI', 'Dunn'],
  ['55035', 'WI', 'Eau Claire'],
  ['55037', 'WI', 'Florence'],
  ['55039', 'WI', 'Fond du Lac'],
  ['55041', 'WI', 'Forest'],
  ['55043', 'WI', 'Grant'],
  ['55045', 'WI', 'Green'],
  ['55047', 'WI', 'Green Lake'],
  ['55049', 'WI', 'Iowa'],
  ['55051', 'WI', 'Iron'],
  ['55053', 'WI', 'Jackson'],
  ['55055', 'WI', 'Jefferson'],
  ['55057', 'WI', 'Juneau'],
  ['55059', 'WI', 'Kenosha'],
  ['55061', 'WI', 'Kewaunee'],
  ['55063', 'WI', 'La Crosse'],
  ['55065', 'WI', 'Lafayette'],
  ['55067', 'WI', 'Langlade'],
  ['55069', 'WI', 'Lincoln'],
  ['55071', 'WI', 'Manitowoc'],
  ['55073', 'WI', 'Marathon'],
  ['55075', 'WI', 'Marinette'],
  ['55077', 'WI', 'Marquette'],
  ['55078', 'WI', 'Menominee'],
  ['55079', 'WI', 'Milwaukee'],
  ['55081', 'WI', 'Monroe'],
  ['55083', 'WI', 'Oconto'],
  ['55085', 'WI', 'Oneida'],
  ['55087', 'WI', 'Outagamie'],
  ['55089', 'WI', 'Ozaukee'],
  ['55091', 'WI', 'Pepin'],
  ['55093', 'WI', 'Pierce'],
  ['55095', 'WI', 'Polk'],
  ['55097', 'WI', 'Portage'],
  ['55099', 'WI', 'Price'],
  ['55101', 'WI', 'Racine'],
  ['55103', 'WI', 'Richland'],
  ['55105', 'WI', 'Rock'],
  ['55107', 'WI', 'Rusk'],
  ['55109', 'WI', 'St. Croix'],
  ['55111', 'WI', 'Sauk'],
  ['55113', 'WI', 'Sawyer'],
  ['55115', 'WI', 'Shawano'],
  ['55117', 'WI', 'Sheboygan'],
  ['55119', 'WI', 'Taylor'],
  ['55121', 'WI', 'Trempealeau'],
  ['55123', 'WI', 'Vernon'],
  ['55125', 'WI', 'Vilas'],
  ['55127', 'WI', 'Walworth'],
  ['55129', 'WI', 'Washburn'],
  ['55131', 'WI', 'Washington'],
  ['55133', 'WI', 'Waukesha'],
  ['55135', 'WI', 'Waupaca'],
  ['55137', 'WI', 'Waushara'],
  ['55139', 'WI', 'Winnebago'],
  ['55141', 'WI', 'Wood'],
  ['56001', 'WY', 'Albany'],
  ['56003', 'WY', 'Big Horn'],
  ['56005', 'WY', 'Campbell'],
  ['56007', 'WY', 'Carbon'],
  ['56009', 'WY', 'Converse'],
  ['56011', 'WY', 'Crook'],
  ['56013', 'WY', 'Fremont'],
  ['56015', 'WY', 'Goshen'],
  ['56017', 'WY', 'Hot Springs'],
  ['56019', 'WY', 'Johnson'],
  ['56021', 'WY', 'Laramie'],
  ['56023', 'WY', 'Lincoln'],
  ['56025', 'WY', 'Natrona'],
  ['56027', 'WY', 'Niobrara'],
  ['56029', 'WY', 'Park'],
  ['56031', 'WY', 'Platte'],
  ['56033', 'WY', 'Sheridan'],
  ['56035', 'WY', 'Sublette'],
  ['56037', 'WY', 'Sweetwater'],
  ['56039', 'WY', 'Teton'],
  ['56041', 'WY', 'Uinta'],
  ['56043', 'WY', 'Washakie'],
  ['56045', 'WY', 'Weston'],
];

// export const countyMap = countyData.reduce((m, c) => ({ ...m, [`${c[1]}-${c[2]}`.toLowerCase()]: { fips: c[0], name: `${c[2]}${c[3] === 0 ? '' : ' County'}, ${c[1]}` } }), {});

export const fipsMap = countyData.reduce((m, c) => ({
  ...m,
  [c[0]]: {
    fips: c[0],
    state: c[1],
    county: c[3] === undefined,
    name: `${c[2]}${c[3] === 0 ? '' : ' County'}, ${c[1]}`,
    search: c[2].toLowerCase().replace(/[^a-z]+/g, ' ').trim(),
  },
}), {});

export const countyMap = countyData.reduce((m, c) => ({ ...m, [c[1]]: (m[c[1]] || []).concat(fipsMap[c[0]]) }), {});








// export const PropertyTypes = [
//   { value: 'SFR', label: 'Single Family' },
//   { value: 'CONDO', label: 'Condo/Townhouse' },
//   { value: 'MFR', label: 'Multi-Family' },
//   { value: 'LAND', label: 'Land' },
//   { value: 'OTHER', label: 'Other' },
// ];
//
// export const PropTypeCode = {
//   SFR: 'SFR',
//   CONDO: 'CONDO',
//   MFR: 'MFR',
//   LAND: 'LAND',
//   OTHER: 'OTHER',
// };
//
// export const PhotoComingSoonUrl = 'https://images.listing.propstream.com/photo_coming_soon_sm8.jpg';
//
// export const PropName = {
//   SFR: 'Single Family',
//   CONDO: 'Condo/Townhouse',
//   MFR: 'Multi-Family',
//   LAND: 'Land',
//   OTHER: 'Other',
// };
//
// export const OwnerCorporate = [
//   { label: 'Corporate', value: 'true' },
//   { label: 'Individual', value: 'false' },
//   { label: 'Any', value: '' },
// ];
//

// export const MlsListingStatusList = fromJS(MlsListingStatus);
// export const MlsListingStatusNames = MlsListingStatus.reduce((m, s) => ({ ...m, [s.value]: s.label }), {});
// export const LoanTypeList = fromJS(LoanType);
// export const ListingTypesList = fromJS(ListingTypes);
//
// export const DocumentTypeCode = [
//   { label: 'Notice of Trustee\'s Sale', value: 'NT' },
//   { label: 'Notice of Default', value: 'ND' },
//   { label: 'Lis Pendens', value: 'LP' },
//   { label: 'Notice of Rescission', value: 'NR' },
//   { label: 'Trustee\'s Deed', value: 'TD' },
//   { label: 'Other', value: 'OTHER' },
// ];
//
// export const ForeclosureStatus = [
//   { label: 'Pre-Foreclosure', value: 'PRE_FORECLOSURE' },
//   { label: 'Foreclosure', value: 'FORECLOSURE' },
//   { label: 'None', value: 'NONE' },
// ];
//
// export const OwnerOccupied = [
//   { label: 'Yes', value: true },
//   { label: 'No', value: false },
//   { label: 'Any', value: null },
// ];
//
// export const BelowMarketPrice = [
//   { label: 'Yes', value: true },
//   { label: 'No', value: false },
//   { label: 'Any', value: null },
// ];






export default ListingTypes;
