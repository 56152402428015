import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fromJS } from 'immutable';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import loadable from 'components/hoc/loadable';
import Table, { Column } from 'components/base/Table';
import Checkbox from 'components/base/Checkbox';
import SVG from 'components/base/SVG';
import Button from 'components/base/Button';
import { join } from 'utils/string';
import { dragStartWrapper } from 'utils/DOM/dragDrop';

import ColumnFilter from './ColumnFilter';
import css from './style.scss';


export const idMap = list => list.reduce((m, g) => ({ ...m, [g.get('id')]: g }), {});

export const idRenderer = map => val => (val ? map[val].get('name') : null);

export const groupIdRenderer = groupMap => val => (val ? val.split(',').filter((id, i, ar) => groupMap[id] && ar.indexOf(id) === i).reduce((names, id) => join(names, groupMap[id].get('name')), '') : null);

export const linkRenderer = url => (value, _, data) => <Link to={`${url}/${data.get('id')}`} className={css.link}>{value}</Link>;

export const multilineRenderer = value => (value || '').split('\n').map(line => <div key={line}>{line}</div>);

class SearchTable extends PureComponent {
  /* :: selectCheckboxCellProps: Function */

  constructor(props) {
    super(props);

    this.handleSort = this.handleSort.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleSelectRemainingClick = this.handleSelectRemainingClick.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleToggleFilter = this.handleToggleFilter.bind(this);
    this.handleLoadNext = this.handleLoadNext.bind(this);
    this.handleLoadRemaining = this.handleLoadRemaining.bind(this);

    this.timer = null;
    this.searchContext = null;
  }

  componentWillReceiveProps(props) {
    const { onSearch, context } = props;
    if (this.searchContext) {
      this.searchContext = null;
      onSearch(context);
    }
  }

  killTimer() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
  }

  updateAndSearch(context) {
    this.searchContext = context;
    this.props.onUpdate(context);
  }

  handleSort({ fieldName: field = '' }) {
    const { context } = this.props;
    const cur = context.getIn(['sortFields', '0']);
    this.updateAndSearch(context.set('sortFields', fromJS([cur !== field ? field : `!${field}`])));
  }

  handleSelectClick(value, coords) {
    this.props.onSelect(!value, coords.row);
  }

  handleSelectAllClick(ev) {
    this.props.onSelect(ev.target.checked);
  }

  handleSelectRemainingClick(ev) {
    this.props.onSelect(ev.target.checked, -1);
  }

  handleFilterChange(field, value) {
    const { onUpdate, onSearch, context } = this.props;
    const search = context.setIn(['filters', field], value);

    onUpdate(search);

    this.killTimer();
    this.timer = setTimeout(() => {
      this.killTimer();
      onSearch(this.props.context.set('index', 0));
    }, search.getIn(['info', 'data']) ? 300 : 1000);
  }

  handleToggleFilter(ev) {
    const { context } = this.props;
    ev.stopPropagation();
    this.updateAndSearch(context.set('filterEnabled', !context.get('filterEnabled')));
  }

  handleLoadNext() {
    this.doLoadNext(this.props.context.get('limit'));
  }

  handleLoadRemaining() {
    this.doLoadNext(0);
  }

  doLoadNext(limit) {
    const { context, onSearch } = this.props;
    onSearch(context.merge({ index: context.get('data').size, limit }));
  }

  render() {
    const { context, onSelect, fields, loading, indexRenderer, className, viewMode, onDragStart, onDragEnd } = this.props;

    const size = context.get('size');
    const limit = context.get('limit');
    const data = context.get('data');
    const filterEnabled = context.get('filterEnabled');
    const filters = context.get('filters');
    const remaining = context.get('remaining');

    let sortField = context.getIn(['sortFields', '0']);
    let sortDescending = false;
    if (sortField && sortField.startsWith('!')) {
      sortField = sortField.substr(1);
      sortDescending = true;
    }

    const caretIcon = (<SVG icon={sortDescending ? 'iconCaretDown' : 'iconCaretUp'} />);
    const selectEnabled = !!onSelect && !viewMode;
    const btnProps = { kind: Button.kind.grayGhost, className: css.btn, isLoading: loading };

    const displayFields = fields.filter(({ display = true }) => !!display);
    displayFields[displayFields.length - 1].extra = <SVG icon={filterEnabled ? 'iconFilterFull' : 'iconFilter'} className={css.filter} onClick={this.handleToggleFilter} />;

    return (
      <div className={classNames(css.tableContainer, className)}>
        <Table
          keyField="id"
          className="type_5"
          data={data}
          isHoverable
          isSortable
          onColumnSort={this.handleSort}
          sortableFields={fromJS(displayFields.filter(f => f.sortable !== false).map(f => f.name))}
          sortFields={fromJS(sortField ? [sortField] : [])}
        >
          {!selectEnabled ? null : (
            <Column
              key="selected"
              field="selected"
              cellClassName={css.indexCell}
              renderCell={(value, coords, data) => (
                <div
                  className={classNames(css.selectCell, { [css.draggable]: !!onDragStart })}
                  draggable={!!onDragStart}
                  onDragStart={dragStartWrapper(() => onDragStart({ recordId: data.get('id'), selected: data.get('selected') }))}
                  onDragEnd={onDragEnd}
                >
                  <Checkbox checked={data.get('selected')} onClick={() => this.handleSelectClick(value, coords, data)} />
                  {!indexRenderer ? null : indexRenderer(value, coords, data)}
                </div>
              )}
            >
              {!onSelect ? null : <Checkbox checked={context.get('allSelected')} onClick={this.handleSelectAllClick} />}
            </Column>
          )}
          {displayFields.map(f => <Column key={f.name} field={f.name} sortIcon={caretIcon} renderCell={f.renderer}><div>{f.label}{f.extra}</div></Column>)}
          {!filterEnabled || !selectEnabled ? null : <Column key="selected" field="selected" row={1} />}
          {!filterEnabled ? null : displayFields.map(f => (
            <Column key={f.name} field={f.name} row={1}>
              <ColumnFilter
                onFilterChange={this.handleFilterChange}
                field={f.filterField || f.name}
                value={filters.get(f.filterField || f.name) || null}
                readOnly={loading}
                options={f.options}
                modes={f.modes}
                searchable={f.searchable}
                valueSep={f.valueSep}
              />
            </Column>
          ))}
        </Table>
        {size == null || remaining <= 0 ? null : (
          <div className={css.footer}>
            <div className={css.remaining}>
              <Checkbox label={`Remaining ${remaining} Selected`} checked={context.get('remainingSelected')} onClick={this.handleSelectRemainingClick} />
            </div>
            <div className={css.buttons}>
              {remaining <= limit ? null : <Button {...btnProps} onClick={this.handleLoadNext}>Load Next {limit} Results</Button>}
              <Button {...btnProps} onClick={this.handleLoadRemaining}>Load Remaining {remaining} Results</Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SearchTable.propTypes = {
  viewMode: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
};

SearchTable.defaultProps = {
  viewMode: false,
};

export default connect()(SearchTable);

