import Attached from '../../../assets/images/attached.svg';
import Detached from '../../../assets/images/detached.svg';
import AboveLiving from '../../../assets/images/aboveLiving.svg';
import AboveGarage from '../../../assets/images/aboveGarage.svg';
import GarageConversion from '../../../assets/images/garageConversion.svg';


export const AduTypes = {
  ADU_TYPE: 'AduType',
  ADU_INTERNAL_TYPE: 'AduInternalType',
  ADU_SIZE: 'AduSize',
  ADU_QUALITY: 'AduQuality',
};

export const getAduTypeIcon = (type) => {
  switch (type) {
    case 'attached':
      return Attached;
    case 'detached':
      return Detached;
    case 'abovelivingarea':
      return AboveLiving;
    case 'abovegarage':
      return AboveGarage;
    case 'garageconversion':
      return GarageConversion;
    default:
      return '';
  }
};

export const chartColors = [
  '#D34F0D',
  '#F16722',
  '#F58D5C',
  '#FFC8AC',
  '#0E6673',
  '#128292',
  '#53A6B2',
  '#94C9D1',
  '#DE9C1E',
  '#F4AC21',
  '#F6BA47',
  '#F9D081',
  '#5B68C3',
  '#747FCD',
  '#929BD9',
  '#AFB6E5',
  '#ca1551',
];

export const Messages = {
  ALERT1: 'The ADU calculation service is down, due to that we are showing an older calculation on the dashboard, please try again later to see the updated calculation.',
  ALERT2: 'The ADU calculation service is down, please try again later.',
  SERVICE_DOWN: 'Service is down!',
};
