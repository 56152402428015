import React from 'react';

import css from './style.scss';


const valueCell = ({ name, value, description, addClass = '' }) => (
  <div className={`${css.value} ${addClass}`}>
    <div className={css.name}>{name}</div>
    <div className={css.label}>
      {value || 'N/A'}
      {!description ? ((name && (name === 'Public Record' || name === 'MLS')) ? <div className={css.description}>&nbsp;</div> : null) : <div className={css.description}>{description}</div>}
    </div>
  </div>
);

export default valueCell;
