import React, { PureComponent } from 'react';
import { preventStop, allowStop } from 'utils/DOM/propagation';


export function preventPropagationStop(Component) {
  return class PropagationStoper extends PureComponent {
    constructor(props) {
      super(props);
      this.handleRef = this.handleRef.bind(this);
    }

    handleRef(element, ...rest) {
      if (element) {
        this.element = element;
        this.element.addEventListener('click', preventStop);
      } else if (this.saveButton) {
        this.element.removeEventListener('click', preventStop);
      }
      if (this.props.onRef) {
        this.props.onRef(element, ...rest);
      }
    }

    render() {
      return <Component {...this.props} onRef={this.handleRef} />;
    }
  };
}

export function allowPropagationStop(Component) {
  // eslint-disable-next-line react/no-multi-comp
  return class PropagationAllower extends PureComponent {
    constructor(props) {
      super(props);
      this.handleRef = this.handleRef.bind(this);
    }

    handleRef(element, ...rest) {
      if (element) {
        this.element = element;
        this.element.addEventListener('click', allowStop);
      } else if (this.saveButton) {
        this.element.removeEventListener('click', allowStop);
      }
      if (this.props.onRef) {
        this.props.onRef(element, ...rest);
      }
    }

    render() {
      return <Component {...this.props} onRef={this.handleRef} />;
    }
  };
}
