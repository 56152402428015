/** @flow */
import React, { PureComponent } from 'react';
import Field from './Field';


export default function createField(Component) {
  return class ComponentField extends PureComponent {
    render() {
      return <Field {...this.props} component={Component} />;
    }
  };
}
