/** @flow */
import React, { PureComponent } from 'react';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './style.scss';


class Input extends PureComponent {
  render() {
    const { className, input, meta, ...rest } = this.props;
    const inputClass = classNames(css.input, className);
    return (
      <Field {...rest} className={inputClass} {...input} component="input" />
    );
  }
}


Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  input: {},
};

export default Input;
