import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPopupRegistration, openPopup, closePopup } from 'app/PopupHolder';
import Modal from 'components/base/Modal';
import Checkbox from 'components/base/Checkbox';
import Button from 'components/base/Button';
import { withRouter } from 'react-router-dom';
import { disableAlert, selectAlertLoading } from 'data/user';

import css from './style.scss';


class Alert extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleDisableClick = this.handleDisableClick.bind(this);

    this.unlisten = null;
    this.initialPath = null;

    const { alerts } = this.props;
    this.state = { alerts, disable: false };
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen(({ pathname }) => {
      if (!this.initialPath) this.initialPath = pathname;
      else if (this.initialPath !== pathname) {
        this.unlisten();
        this.unlisten = null;
        this.props.closePopup();
      }
    });
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten();
  }

  handleDisableClick(ev) {
    this.setState({ disable: ev.target.checked });
  }

  handleClose() {
    const { closePopup, disableAlert } = this.props;
    const { alerts } = this.state;

    if (!this.state.disable) closePopup();
    else {
      disableAlert(alerts[0].id, () => {
        if (alerts.length > 1) this.setState({ disable: false, alerts: alerts.slice(1) });
        else closePopup();
      });
    }
  }

  render() {
    const { closePopup, title, loading } = this.props;
    const { content, width, height } = this.state.alerts[0];

    return (
      <Modal
        isOpen
        uniqId="alert"
        padding="25px 10px"
        caption={title}
        maxWidth="95%"
        maxHeight="95%"
        width={`${width}px`}
        height={`${height}px`}
        onClose={closePopup}
      >
        <div className={css.body}>
          <div className={css.content} dangerouslySetInnerHTML={{ __html: content }} />
          <div className={css.buttonContainer}>
            <Button kind={Button.kind.ghost} onClick={this.handleClose} isLoading={loading}>Close</Button>
            <Checkbox label="Do not show this message again." checked={this.state.disable} onClick={this.handleDisableClick} containerClassName={css.checkbox} />
          </div>
        </div>
      </Modal>
    );
  }
}

Alert.propType = {
  alerts: PropTypes.array,
};

const AlertPopup = withRouter(connect(state => ({ loading: selectAlertLoading(state) }), { disableAlert })(Alert));

const registrationId = getPopupRegistration(AlertPopup);
AlertPopup.open = props => openPopup(registrationId, props);
AlertPopup.close = props => closePopup(registrationId, props);

export default AlertPopup;
