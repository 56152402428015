import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import Confirm from 'app/components/Confirm';
import UpgradeAccount from 'app/components/UpgradeAccount';
import Button from 'components/Button';
import Modal from 'components/base/Modal';
import { pluralize } from 'utils/string';
import { selectProfile, selectLoading } from 'data/user';
import formatNumber from 'utils/number/format';
import formatDate from 'utils/date/formatDate';
import { withRouter } from 'react-router-dom';

import css from './style.scss';

const ExportPropertyConfirmation = ({ loading, exportResponse, profile, closePopup, history }) => {
  const handleUpgradeClick = () => {
    closePopup();
    history.push('/account/services');
  };

  const handlePurchaseClick = () => {
    openUpgradeAccount({ bumpType: 'exportProperty' });
  };

  const { remainingExportProperties, exportPropertyLimit, catalogProducts, csPhone, subUser, nextCycleDate } = profile;
  const { propertyQuantity, fileName } = exportResponse;
  const cycleDate = formatDate(nextCycleDate);

  return (
    <Modal isOpen uniqId="exportPropertyConfirmation" width="auto">
      <div className={css.export}>
        <div className={css.caption}>{formatNumber(propertyQuantity)} {pluralize('Property', propertyQuantity)} Exported</div>
        {!propertyQuantity || fileName ? null : <div>Your export is being processed. You will receive a confirmation prompt once your file has been downloaded.</div>}
        {propertyQuantity ? null : (
          <div>
            <div><b>Oops!</b> Apparently, you don&apos;t have enough exports left for the month to perform that operation.</div>
            {subUser ? <div>Please contact your team leader about increasing your export limit.</div> : (
              <div>
                {!catalogProducts || !catalogProducts.find(p => p.exportPropertyQuantity >= 0) ? `Please contact Customer Support at ${csPhone}.` : (
                  <div>Your account will refresh with the full month&apos;s exports on {cycleDate} or you can <span onClick={handleUpgradeClick}>upgrade your account</span> or <span onClick={handlePurchaseClick}>Click Here</span> to purchase additional exports.</div>
                )}
              </div>
            )}
          </div>
        )}
        <div>You have {formatNumber(remainingExportProperties)} properties left to export for this billing period. Your Export quantity will reset to {formatNumber(exportPropertyLimit)} on {cycleDate}.</div>
        <div className={css.buttons}>
          <Button onClick={closePopup} loading={loading}>Close</Button>
        </div>
      </div>
    </Modal>
  );
};

ExportPropertyConfirmation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  exportResponse: PropTypes.object,
};

const mapStateToProps = state => ({
  loading: selectLoading(state),
  profile: selectProfile(state).toJS(),
});

const mapDispatchToProps = {
  confirm: Confirm.open,
  openUpgradeAccount: UpgradeAccount.open,
};

const ExportPropertyConfirmationPopup = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportPropertyConfirmation));

ExportPropertyConfirmationPopup.open = props => openPopup(getPopupRegistration(ExportPropertyConfirmationPopup), { ...props, priority: Priority.MEDIUM_HIGH });

export default ExportPropertyConfirmationPopup;
