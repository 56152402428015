import classNames from "classnames";
import SVG from "components/base/SVG";
import React from "react";
import numberToPrice from "utils/currency/numberToPrice";
import number from "utils/number/format";
import formatPercent from "utils/percent/formatPercent";
import css from "./style.scss";

const arrowClassName = (base, direction = "up") =>
  classNames(base, { [css.arrowDown]: direction === "down" });

const formattingOptions = { maximumFractionDigits: 2 };

function formatValue(value, format) {
  switch (format) {
    case "number":
      return number(value, "", formattingOptions);
    case "percent":
      return formatPercent(value, "", formattingOptions);
    case "currency":
      return numberToPrice(value, "", formattingOptions);
    default:
      return value;
  }
}

const StatUnit = (props) => {
  const { className, stat } = props;
  return (
    <div className={classNames(className, css.container)}>
      <div className={css.topSide}>
        <SVG
          icon="iconArrowUp"
          className={arrowClassName(css.arrow, stat.direction)}
        />
        <div className={css.values}>
          <div>{formatPercent(stat.percents, "", formattingOptions)}</div>
          <div>{formatValue(stat.value, stat.format)}</div>
        </div>
      </div>
      <div className={css.label}>{stat.name}</div>
    </div>
  );
};

export default StatUnit;
