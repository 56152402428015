/** @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Layout from './Layout';


class Purchase extends PureComponent {
  render() {
    return (
      <Layout {...this.props} />
    );
  }
}

export default connect()(Purchase);
