/** @flow */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SVG from 'components/base/SVG';
import Button from './Button';

import css from './style.scss';


/**
 * ButtonLink class
 *
 * You can use any button attributes like type, disabled, etc
 * @param to            {String}  (required) href
 * @param isLoading     {Boolean} (optional) flag for loading state
 * @param spinnerColor  {String}  (optional) color of flag, can be white or blue (by default is blue)
 *
 * @returns {Component}
 *
 *
 * How you can use it
 *
 * <ButtonLink
 *   to='path/to/'
 *   kind={ButtonLink.kind.blue}
 *   size={ButtonLink.size.large}
 *   isLoading={isLoading}
 *   spinnerColor="white"
 * >
 *    My Link
 * </ButtonLink>
 */
class ButtonLink extends PureComponent {
  /* :: static kind: Object */
  /* :: static size: Object */
  /* :: link: ?Link */
  /* :: node: ?HTMLElement */
  constructor(props) {
    super(props);
    const { width = null } = props;
    this.state = {
      style: { width },
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { width = null } = nextProps;
    const { style } = this.state;
    if (style.width !== width) {
      this.setState({ style: { ...style, width } });
    }
  }

  /* :: handleClick: Function */
  handleClick(event) {
    if (this.link) this.link?.handleClick(event);
  }

  /* :: handleRef: Function */
  handleRef(ref) {
    if (ref) {
      this.link = ref;
      this.node = ((findDOMNode(ref)));
      // this.node.addEventListener('click', this.handleClick);
    } else if (this.node) {
      // (this.node).removeEventListener('click', this.handleClick);
      this.link = null;
      this.node = null;
    }
  }

  renderSpinner() {
    const { isLoading, spinnerColor } = this.props;

    return isLoading ? (
      <SVG icon="spinner" className={classNames(css.spinner, css[spinnerColor])} />
    ) : null;
  }

  render() {
    const { children, isLoading, kind, size, className, external, download, to, ...rest } = this.props;
    const btnClass = classNames(css.button, css[kind], css[size], className, { [css.loading]: isLoading });
    const ComponentType = external ? 'a' : Link;
    const href = external ? { href: to } : { to };
    const anchorProps = external && download ? { download } : {};

    return (
      <ComponentType className={btnClass} {...href} {...rest} {...anchorProps} ref={this.handleRef}>
        {this.renderSpinner()}
        <span className={css.text}>
          {children}
        </span>
      </ComponentType>
    );
  }
}

ButtonLink.kind = Button.kind;
ButtonLink.size = Button.size;

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  kind: PropTypes.oneOf(Object.values(ButtonLink.kind)),
  size: PropTypes.oneOf(Object.values(ButtonLink.size)),
  name: PropTypes.string,
  download: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ButtonLink.defaultProps = {
  kind: ButtonLink.kind.ghost,
  size: ButtonLink.size.medium,
  disabled: false,
  isLoading: false,
  download: false,
};


export default ButtonLink;
