import React, { Component } from 'react';
import Button from 'components/Button';
import { Permissions } from 'data/user/constants';
import RestrictedContent from 'app/components/RestrictedContent';

import css from './style.scss';


class DetailCellRenderer extends Component {
  render() {
    const { data: { id, propertyId }, context: { saveProperty } } = this.props;
    const url = `/search/${propertyId || id}`;

    return (
      <div className={css.detail}>
        <Button url={url} target="_blank">Details</Button>
        <RestrictedContent permission={Permissions.analysisView}>
          <Button url={`${url}/analysis`} target="_blank">Analysis</Button>
        </RestrictedContent>
        <Button onClick={() => saveProperty(null, propertyId)}>Save</Button>
      </div>
    );
  }
}

export default DetailCellRenderer;
