import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import css from './style.scss';


const CustomTabs = (props) => {
    const { kind, className } = props;
    const tabsClass = classNames(css.tabs, css[kind], className);
    return <div {...props} className={tabsClass} />;
}

CustomTabs.defaultProps = {
  kind: 'default',
};

CustomTabs.propTypes = {
  kind: PropTypes.oneOf(['default', 'line']),
  className: PropTypes.string,
};


export default CustomTabs;
