import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Confirm from 'app/components/Confirm';
import { selectCampaign, CampaignTypeList } from 'data/campaigns';
import { IconButton } from 'components/Button';
import { campaignPath } from 'app/routes';
import { selectProfile, getMarketingUrl } from 'data/user';
import { getBrandRedirectMessage } from 'utils/brand';

import css from './style.scss';


class Activities extends PureComponent {
  render() {
    const { campaign, profile, onClick, confirm } = this.props;

    return (
      <div className={css.activities}>
        <div className={css.activitiesInner}>
          {CampaignTypeList.filter(type => type.enabled).map((type) => {
            const url = getMarketingUrl(profile, type);

            const redirectService = () => {
              if (onClick) onClick();
              confirm({
                question: getBrandRedirectMessage(url),
                okLabel: 'Continue',
                onOk: () => {
                  document.contactAppendForm.target = type.name;
                  document.contactAppendForm.action = url;
                  document.contactAppendForm.submit();
                },
              });
            };

            return (
              <div className={css.activity}>
                <IconButton
                  key={type.title}
                  text={type.title}
                  icon={type.icon}
                  url={!url && campaignPath(`${campaign.get('id')}/${type.route}`)}
                  target={url ? type.name : null}
                  onClick={url ? redirectService : null}
                />
                <div>{type.description}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  campaign: selectCampaign(state),
  profile: selectProfile(state),
}), {
  confirm: Confirm.open,
})(Activities);

/*
      <Modal isOpen isCloseButton uniqId="addActivity" caption="Add Activity" width="auto" padding="20px 40px 40px" onClose={this.handleClose}>
      </Modal>

CampaignTypeList.map(type => (
              <div className={css.emptyCard} key={type.code}>
                <div className={css.caption}>{type.name}</div>
                <SVG icon="iconActivityPlaceholder" className={css.icon} />
                <div className={css.text}>{`You haven’t ${type.setupVerb} ${type.name.toLowerCase()} for this campaign yet.`}</div>
                {type.enabled ? <Button href={getMarketingUrl(profile, type) || campaignPath(`${id}/${type.route}`)} target={getMarketingUrl(profile, type) ? type.name : null}>Get Started</Button> : <div className={css.comingSoon}>Coming Soon!</div>}
              </div>
 */
