import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';

import Confirm from 'app/components/Confirm';
import { getPopupRegistration, openPopup, closePopup } from 'app/PopupHolder/index';
import { ContactAssignmentPopup } from 'app/components/GroupAssignment';
import { selectProfile, selectFullAccessPermission } from 'data/user/selectors';
import { selectListManagementEnabled } from 'data/user';
import { selectLoading, selectAppendJob, updateAppendJob, appendContacts, getAppendJobEstimate } from 'data/contacts';
import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import { pluralize } from 'utils/string';
import numberToPrice from 'utils/currency/numberToPrice';
import FormControlWraper from 'components/base/FormControlWraper';
import { validate } from 'utils/validation';
import { selectContactAppendRates } from 'data/campaigns';
import CheckoutSummary from 'app/components/CheckoutSummary';
import ListManagementInfo from 'app/components/ListManagementInfo';
import { isLaAvailable, getBrandRedirectMessage, isReiAutomator } from 'utils/brand';
import Checkbox from 'components/base/Checkbox';

import DncDisclaimerPopup from './dnc';
import css from './style.scss';
import { withRouter } from 'react-router-dom';


// const PriceOptions = [
//   { name: 'Phones Only', field: 'phoneRate' },
//   { name: 'Emails Only', field: 'emailRate' },
//   { name: 'Phones & Emails', field: 'rate', bold: true },
// ];

class AppendJobEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleStepClick = this.handleStepClick.bind(this);
    this.handleSelectContactsClick = this.handleSelectContactsClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetState = this.resetState.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleSummaryLoad = this.handleSummaryLoad.bind(this);
    this.handleLearnMoreClick = this.handleLearnMoreClick.bind(this);

    this.ref = {};
    this.state = {
      currentStepIndex: 0,
      activeStepIndex: 0,
      contactIds: [],
      contactQuantity: 0,
      reprocessEnabled: false,
      name: '',
      id: null,
      mode: 'BOTH',
    };

    this.steps = [
      {
        title: 'Select Contacts',
        description: 'Select all contacts that you wish to append contact information to. Any duplicate contacts you select, or any contacts that have already been appended to, will not be processed.',
      },
      {
        title: 'Payment',
        description: 'Please review your order. You will be charged $rate cents per successfully appended record. If a record comes back with multiple telephone numbers and emails addresses, you still only pay $rate cents one time.\n\nYour card will be authorized for the total number of records selected. Once the process is complete, you will be refunded for any contacts we were not able to find contact information for.',
        next: 'Place Order',
      },
      {
        title: 'Confirmation',
        description: 'Your order is complete. The process can take several minutes or more depending on the number of contacts selected. Please check back to the Contacts screen periodically to see the status of your order. If you would like to enter a custom name for this order, please enter it below and click "Done".',
        next: 'Done',
      },
    ];
  }

  componentWillMount() {
    const { contactIds = [], profile, closePopup, confirm } = this.props;

    if (!profile.contactAppendUrl) this.setState({ contactIds }, this.resetState);
    else {
      closePopup();
      confirm({
        question: getBrandRedirectMessage(profile.contactAppendUrl),
        okLabel: 'Continue',
        onOk: () => {
          document.contactAppendForm.target = 'contactAppend';
          document.contactAppendForm.action = profile.contactAppendUrl;
          document.contactAppendForm.submit();
        },
      });
    }
  }

  isNextEnabled() {
    const { currentStepIndex, contactQuantity } = this.state;
    const { appendJob: { lines = [] }, profile: { overdueAccount } } = this.props;

    return (currentStepIndex === 0 && !!contactQuantity)
      || (currentStepIndex === 1 && lines.length && !overdueAccount)
      || currentStepIndex > 1;
  }

  handleCloseClick() {
    this.props.closePopup();
  }

  handleSummaryLoad(api) {
    this.summaryApi = api;
  }

  handleNextClick() {
    const { currentStepIndex, contactIds, id, name, mode, reprocessEnabled } = this.state;
    const { confirm, updateAppendJob, appendContacts, getAppendJobEstimate, openDncDisclaimerPopup, appendJob, alert } = this.props;
    const next = () => this.setState({ activeStepIndex: currentStepIndex + 1 }, () => this.handleStepClick(currentStepIndex + 1));

    if (this.isNextEnabled()) {
      if (currentStepIndex === 0) getAppendJobEstimate({ contactIds, appendMode: mode, reprocessEnabled }, next);
      else if (currentStepIndex === 1) {
        validate(() => {
          const { paymentMethodId, cardCode, paymentAmount, creditAmount } = this.summaryApi.validate();

          openDncDisclaimerPopup({
            onAccept: () => {
              appendContacts({ ...appendJob, contactIds, reprocessEnabled }, paymentMethodId, cardCode, paymentAmount, creditAmount, ({ response }) => {
                const { id, name } = response.find(g => g.modified);
                this.setState({ id: id.substr(1), name }, () => {
                  alert('Thank you for placing your skip tracing order. We\'re hard at work locating contact information for these property owners. It will be just a few minutes, so feel free to grab a cup of coffee or perfect your pitch while you wait!\n\n' +
                    'We\'ll email you when your order is ready. You can always access your order in the "Contacts" section under "Skip Tracing."');
                  next();
                });
              });
            },
          });
        });
      } else if (currentStepIndex === 2) {
        if (name.trim() === '') confirm({ question: 'Name is required.', cancelLabel: null });
        else updateAppendJob({ id, name }, this.handleCloseClick);
      } else next();
    }
  }

  handleBackClick() {
    this.handleStepClick(this.state.currentStepIndex - 1);
  }

  handleStepClick(stepIndex) {
    const { activeStepIndex } = this.state;
    const currentStepIndex = Math.max(0, Math.min(activeStepIndex, stepIndex));
    this.setState({ currentStepIndex }, this.resetState);
  }

  resetState() {
    const { contactIds } = this.state;

    this.setState({ contactQuantity: (contactIds && contactIds.length) || 0 });
  }

  handleSelectContactsClick() {
    this.props.openContactAssignmentPopup({ title: 'Select Contacts', ids: this.state.contactIds, onSave: contactIds => this.setState({ contactIds }, () => { this.resetState(); }) });
  }

  handleChange(ev) {
    const { name, value, checked } = ev.target || ev;

    this.setState({ [name]: ['reprocessEnabled'].includes(name) ? checked : value });
  }

  handleRef(element) {
    if (element) {
      const el = element.tagName ? element : findDOMNode(element);
      this.ref[el.name] = el;
    }
  }

  handleLearnMoreClick() {
    // this.handleCloseClick();
    // this.props.router.push('/account/services');
    this.props.openListManagementInfo();
  }

  render() {
    const { loading, appendJob, rates, fullAccess, lmEnabled, openListManagementInfo } = this.props;
    const { currentStepIndex, activeStepIndex, contactQuantity, name, reprocessEnabled } = this.state;
    const steps = this.steps;
    const { lines, message, newQuantity } = appendJob;
    const currentStep = steps[currentStepIndex];
    const nextEnabled = this.isNextEnabled();
    const { next = 'Next' } = currentStep;
    const complete = currentStepIndex === steps.length - 1;
    const omittedQuantity = contactQuantity - newQuantity;
    // const brand = getBrandName();
    const code = isReiAutomator() ? 'rei' : 'gen';

    if (!fullAccess) return null;

    // TODO: Move below code to common area and out of render (same logic used for postcards)
    let appliedRate = {};
    const line = (lines && lines[0]) || {};
    const { quantity } = line;
    // const rates2 =
    rates.map((r, i) => {
      const rate = { ...r, quantityMin: i ? (rates[i - 1].quantity + 1) : 1, quantityMax: r.quantity };

      if (quantity >= rate.quantityMin && quantity <= (rate.quantity || quantity)) appliedRate = { ...rate, ...line };

      return rate;
    });

    return (
      <Modal
        isOpen
        uniqId="ContactAppendEditor"
        width="auto"
        padding="30px 40px 25px"
        onClose={this.handleCloseClick}
      >
        <div className={css.editor}>
          <div className={css.header}>Skip Trace</div>
          <div className={css.top}>
            <div className={css.left}>
              <div className={classNames(css.subHeader, css.border)}>Search Options</div>
              <p>Skip Tracing is the process of locating the phone numbers and email addresses for your prospects.</p>
              <p>Once you have skip traced a list, you can call or text your leads and take advantage of PropStream&apos;s integrated marketing features to send customized emails.</p>
              <p>Follow the steps below to append your list.</p>
              <div className={css.items}>
                {steps.map((step, i) => (
                  <div
                    className={classNames(css.item, { [css.unavailable]: i > activeStepIndex, [css.current]: i === currentStepIndex, [css.disabled]: complete || loading })}
                    key={step.title}
                    onClick={() => this.handleStepClick(i)}
                  >{i + 1}. {step.title}</div>
                ))}
              </div>
            </div>
            <div className={css.right}>
              <div className={css.subHeader}>Step {activeStepIndex + 1}: {steps[activeStepIndex].title}</div>
              <div className={css.stepContainer}>
                <div className={css.steps} style={{ left: `${currentStepIndex * -100}%` }}>
                  {steps.map((step, i) => (
                    <div className={css.step} key={step.title}>
                      <div className={css.inner}>
                        {/* <div className={css.description}>{step.description}</div>*/}
                        <div className={css.stepContent}>
                          {[
                            (<div className={css.contactStep}>
                              <img className={css.banner} src={`/assets/images/skip-${code}-${lmEnabled ? '10' : '12'}c.png`} alt="Skip Tracing Banner" />
                              <div className={css.center}>
                                <SolidButton onClick={this.handleSelectContactsClick}>{contactQuantity ? 'Modify' : 'Select'} Contacts</SolidButton>
                                {!contactQuantity ? null : <div className={css.confirmation}>{pluralize('Contact', contactQuantity, true)} Selected</div>}
                                {lmEnabled || !isLaAvailable() ? null : (
                                  <div className={css.listAutomator}>
                                    <div className={css.bar} />
                                    <img className={css.banner} src={`/assets/images/skip-${code}-10c-la.png`} alt="List Automator Banner" />
                                    <Button onClick={openListManagementInfo}>Learn More</Button>
                                  </div>
                                )}
                              </div>
                            </div>),
                            (<div className={css.paymentStep}>
                              {!lines || !lines.length ? (
                                <div className={css.message}>{message}</div>
                              ) : (
                                <CheckoutSummary
                                  lines={lines}
                                  rate={appliedRate}
                                  instructions={step.description.replaceAll('$rate', numberToPrice(appliedRate.amount))}
                                  detailHeader={`${pluralize('Contact', newQuantity, true)} Selected`}
                                  detailSubheader={omittedQuantity && `${pluralize('contact', omittedQuantity, true)} ${omittedQuantity === 1 ? 'has been omitted due to being a duplicate' : 'have been omitted due to being duplicates'}, having been appended already, or having insufficient information.`}
                                  onLoad={this.handleSummaryLoad}
                                />
                              )}
                            </div>),
                            (<div className={css.confirmStep}>
                              <FormControlWraper label="Name Your List" large>
                                <input ref={this.handleRef} onChange={this.handleChange} className={css.input} type="text" name="name" value={name} />
                              </FormControlWraper>
                            </div>),
                          ][i]}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={classNames(css.reprocess, { [css.hidden]: !!currentStepIndex })}>
                <Checkbox name="reprocessEnabled" checked={reprocessEnabled} onChange={this.handleChange} />
                <div>
                  <b>Re-Skip Trace</b> - Check here to have any previously skip traced contacts in this list rerun. I agree to<br />pay associated fees if results are returned. If this option is not selected, contacts skip traced within<br />the last 6 months will be automatically suppressed.
                </div>
              </div>
            </div>
          </div>
          <div className={css.bottom}>
            {complete ? null : <Button onClick={this.handleCloseClick} disabled={loading} loading={loading}>Cancel</Button>}
            {complete ? null : <Button onClick={this.handleBackClick} disabled={loading || !currentStepIndex} loading={loading}>Back</Button>}
            <SolidButton onClick={this.handleNextClick} disabled={loading || !nextEnabled} loading={loading}>{next}</SolidButton>
            <div className={css.border} />
          </div>
        </div>
      </Modal>
    );
  }
}

/*
                              <PriceList title="Skip Trace Pricing" options={PriceOptions} rates={rates2} icon="iconIdCard" />
                              <div className={css.rate}>
                                <div>
                                  <SolidButton onClick={this.handleSelectContactsClick}>{contactQuantity ? 'Modify' : 'Select'} Contacts</SolidButton>
                                </div>
                                {'PHONE|Phones,EMAIL|Emails,BOTH|Phones & Emails'.split(',').map(m => m.split('|')).map(m => (
                                  <div key={m[0]}>
                                    <Radio onClick={this.handleChange} label={m[1]} name="mode" value={m[0]} checked={mode === m[0]} />
                                  </div>
                                ))}
                              </div>
 */

const AppendJobEditorPopup = withRouter(connect(state => ({
  profile: selectProfile(state).toJS(),
  loading: selectLoading(state),
  appendJob: selectAppendJob(state).toJS(),
  rates: selectContactAppendRates(state).toJS(),
  fullAccess: selectFullAccessPermission(state),
  lmEnabled: selectListManagementEnabled(state),
}), {
  appendContacts,
  updateAppendJob,
  getAppendJobEstimate,
  confirm: Confirm.open,
  alert: Confirm.alert,
  openContactAssignmentPopup: ContactAssignmentPopup.open,
  openDncDisclaimerPopup: DncDisclaimerPopup.open,
  openListManagementInfo: ListManagementInfo.open,
})(AppendJobEditor));

const registrationId = getPopupRegistration(AppendJobEditorPopup);
AppendJobEditorPopup.open = props => openPopup(registrationId, { ...props });
AppendJobEditorPopup.close = props => closePopup(registrationId, { ...props });

export default AppendJobEditorPopup;
