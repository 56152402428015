import React, { useState, useEffect, useRef } from 'react';
import css from './style.scss';
import classNames from "classnames";
import { v4 as uuidv4 } from 'uuid';

const FilterYearDropdown = ({ options, defaultOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption ? defaultOption : null);
  const dropdownRef = useRef(null);


  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div className={classNames(css.clsYearDropdown, css.clsMonthDropdown)} ref={dropdownRef}>
      <button className={css.clsDropbtn} onClick={toggleDropdown}>
        {selectedOption ? selectedOption : 'Dropdown'}
      </button>
      {isOpen && (
        <div className={css.clsDropdownContent}>
          <div className={css.clsInnerScroll}>
            {options && options.length > 0 && options.map((option, index) => (
              <a id={'newuifilterRef'+ uuidv4()} key={index} onClick={() => handleOptionClick(option)}>
                {option}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterYearDropdown;
