import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LoadablePanel from '../hoc/loadable/Panel';
import css from './style.scss';
import { ReactSVG } from 'react-svg';
import MLS from '../../assets/images/mls.svg';
import PreForeClosure from '../../assets/images/pre-foreclosures.svg';
import Auction from '../../assets/images/auction.svg';
import BankOwned from '../../assets/images/bank-owned.svg';
import CashBuyer from '../../assets/images/cash-buyer.svg';
import Liens from '../../assets/images/liens.svg';
import Vacant from '../../assets/images/vacant.svg';
import HighEquity from '../../assets/images/high-equity.svg';
import House from '../../assets/images/house-solid.svg';
import { withoutPrefix } from '../../utils/currency/numberToPrice';
import { trimAndAddEllipsis } from '../../utils/string';


class HeaderSearchItem extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.getSearchPanelIcons = this.getSearchPanelIcons.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.tileName);
  }

  getSearchPanelIcons() {
    const { label } = this.props;
    switch (label) {
      case 'MLS':
        return MLS;
      case 'Pre-Foreclosures':
        return PreForeClosure;
      case 'Auctions':
        return Auction;
      case 'Bank Owned':
        return BankOwned;
      case 'Cash Buyers':
        return CashBuyer;
      case 'Liens':
        return Liens;
      case 'Vacant':
        return Vacant;
      case 'High Equity':
        return HighEquity;
      case 'Loading' :
        return '';
      default:
        return House;
    }
  }

  render() {
    const { label, value, isActive, onClick, className, disabled, loading } =
      this.props;
      const buttonProps = {
        onClick: this.onClick,
        className: `${classNames(
          className,
          { [css.active]: isActive },
          css.dashboardSearchItem,
        )} btn__dashboardSearchItem`,
        disabled: !onClick || disabled,
        title: label,
    };

    return (
      <button type="button" {...buttonProps}>
        <div
          className={classNames(css.searchInner, { [css.loading]: loading })}
        >
          <LoadablePanel loading={loading}>
            <div className={css.value}>
              <div className={css.valueIcon}>
                <ReactSVG src={this.getSearchPanelIcons(value)} className={css.svgIcon} />
              </div>
              <div className={css.valueSection}>
                <div className={css.valueTxt}>{value < 100000 ? withoutPrefix(value, '') : '99,999+'}</div>
                <div className={css.label}>{trimAndAddEllipsis(label, 16)}</div>
              </div>
            </div>
          </LoadablePanel>
          {/* <NewFeature visible={!loading && newFeature} center />*/}
        </div>
      </button>
    );
  }
}

HeaderSearchItem.propTypes = {
  label: PropTypes.string.isRequired,
  tileName: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isActive: PropTypes.bool,
  loading: PropTypes.bool,
  newFeature: PropTypes.bool,
};

HeaderSearchItem.defaultProps = {
  value: null,
  isActive: false,
  loading: false,
  newFeature: false,
};

export default HeaderSearchItem;
