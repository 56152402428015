import { CALL_API, CHAIN_API } from 'store/middleware/api';
import { joinPath } from 'utils/string';
import { getFormData } from 'utils/type/isFormData';

import { GroupSearchModes } from './constants';


export const SEARCH_GROUP_CONTEXT = ('SEARCH_GROUP_CONTEXT');
export const SEARCH_GROUP_CONTEXT_SUCCESS = ('SEARCH_GROUP_CONTEXT_SUCCESS');
export const SEARCH_GROUP_CONTEXT_ERROR = ('SEARCH_GROUP_CONTEXT_ERROR');
export const DELETE_PROPERTY_GROUP = ('DELETE_PROPERTY_GROUP');
export const DELETE_PROPERTY_GROUP_SUCCESS = ('DELETE_PROPERTY_GROUP_SUCCESS');
export const DELETE_PROPERTY_GROUP_ERROR = ('DELETE_PROPERTY_GROUP_ERROR');
export const SAVE_PROPERTY_GROUP_OPTIONS = ('SAVE_PROPERTY_GROUP_OPTIONS');
export const SAVE_PROPERTY_GROUP_OPTIONS_SUCCESS = ('SAVE_PROPERTY_GROUP_OPTIONS_SUCCESS');
export const SAVE_PROPERTY_GROUP_OPTIONS_ERROR = ('SAVE_PROPERTY_GROUP_OPTIONS_ERROR');
export const LOAD_GROUP_PUSHPINS = ('LOAD_GROUP_PUSHPINS');
export const LOAD_GROUP_PUSHPINS_SUCCESS = ('LOAD_GROUP_PUSHPINS_SUCCESS');
export const LOAD_GROUP_PUSHPINS_ERROR = ('LOAD_GROUP_PUSHPINS_ERROR');
export const SAVE_PROPERTY = ('SAVE_PROPERTY');
export const SAVE_PROPERTY_SUCCESS = ('SAVE_PROPERTY_SUCCESS');
export const SAVE_PROPERTY_ERROR = ('SAVE_PROPERTY_ERROR');
export const SAVE_PROPERTIES = ('SAVE_PROPERTIES');
export const SAVE_PROPERTIES_SUCCESS = ('SAVE_PROPERTIES_SUCCESS');
export const SAVE_PROPERTIES_ERROR = ('SAVE_PROPERTIES_ERROR');
export const GET_PROPERTY = ('GET_PROPERTY');
export const GET_PROPERTY_SUCCESS = ('GET_PROPERTY_SUCCESS');
export const GET_PROPERTY_ERROR = ('GET_PROPERTY_ERROR');
export const RETRIEVE_PROPERTY = ('RETRIEVE_PROPERTY');
export const RETRIEVE_PROPERTY_SUCCESS = ('RETRIEVE_PROPERTY_SUCCESS');
export const RETRIEVE_PROPERTY_ERROR = ('RETRIEVE_PROPERTY_ERROR');
export const SEARCH_SURROUNDING_PROPERTIES = ('SEARCH_SURROUNDING_PROPERTIES');
export const SEARCH_SURROUNDING_PROPERTIES_SUCCESS = ('SEARCH_SURROUNDING_PROPERTIES_SUCCESS');
export const SEARCH_SURROUNDING_PROPERTIES_ERROR = ('SEARCH_SURROUNDING_PROPERTIES_ERROR');
export const SEARCH_COMPARABLES = ('SEARCH_COMPARABLES');
export const SEARCH_COMPARABLES_SUCCESS = ('SEARCH_COMPARABLES_SUCCESS');
export const SEARCH_COMPARABLES_ERROR = ('SEARCH_COMPARABLES_ERROR');
export const SEARCH_NEIGHBORS = ('SEARCH_NEIGHBORS');
export const SEARCH_NEIGHBORS_SUCCESS = ('SEARCH_NEIGHBORS_SUCCESS');
export const SEARCH_NEIGHBORS_ERROR = ('SEARCH_NEIGHBORS_ERROR');
export const DOWNLOAD_COMPARABLES = ('DOWNLOAD_COMPARABLES');
export const DOWNLOAD_COMPARABLES_SUCCESS = ('DOWNLOAD_COMPARABLES_SUCCESS');
export const DOWNLOAD_COMPARABLES_ERROR = ('DOWNLOAD_COMPARABLES_ERROR');
export const EXPORT_COMPARABLES = ('EXPORT_COMPARABLES');
export const EXPORT_COMPARABLES_SUCCESS = ('EXPORT_COMPARABLES_SUCCESS');
export const EXPORT_COMPARABLES_ERROR = ('EXPORT_COMPARABLES_ERROR');
export const SEARCH_FLIP_COMPARABLES = ('SEARCH_FLIP_COMPARABLES');
export const SEARCH_FLIP_COMPARABLES_SUCCESS = ('SEARCH_FLIP_COMPARABLES_SUCCESS');
export const SEARCH_FLIP_COMPARABLES_ERROR = ('SEARCH_FLIP_COMPARABLES_ERROR');
export const EXPORT_FLIP_COMPARABLES = ('EXPORT_FLIP_COMPARABLES');
export const EXPORT_FLIP_COMPARABLES_SUCCESS = ('EXPORT_FLIP_COMPARABLES_SUCCESS');
export const EXPORT_FLIP_COMPARABLES_ERROR = ('EXPORT_FLIP_COMPARABLES_ERROR');
export const SAVE_SURROUNDING_SEARCH = ('SAVE_SURROUNDING_SEARCH');
export const SAVE_SURROUNDING_SEARCH_SUCCESS = ('SAVE_SURROUNDING_SEARCH_SUCCESS');
export const SAVE_SURROUNDING_SEARCH_ERROR = ('SAVE_SURROUNDING_SEARCH_ERROR');
export const LOAD_PROPERTY_DOCUMENTS = ('LOAD_PROPERTY_DOCUMENTS');
export const LOAD_PROPERTY_DOCUMENTS_SUCCESS = ('LOAD_PROPERTY_DOCUMENTS_SUCCESS');
export const LOAD_PROPERTY_DOCUMENTS_ERROR = ('LOAD_PROPERTY_DOCUMENTS_ERROR');
export const EDIT_PROPERTY_DOCUMENT = ('EDIT_PROPERTY_DOCUMENT');
export const EDIT_PROPERTY_DOCUMENT_SUCCESS = ('EDIT_PROPERTY_DOCUMENT_SUCCESS');
export const EDIT_PROPERTY_DOCUMENT_ERROR = ('EDIT_PROPERTY_DOCUMENT_ERROR');
export const UPLOAD_PROPERTY_DOCUMENT = ('UPLOAD_PROPERTY_DOCUMENT');
export const UPLOAD_PROPERTY_DOCUMENT_SUCCESS = ('UPLOAD_PROPERTY_DOCUMENT_SUCCESS');
export const UPLOAD_PROPERTY_DOCUMENT_ERROR = ('UPLOAD_PROPERTY_DOCUMENT_ERROR');
export const DOWNLOAD_PROPERTY_DOCUMENT = ('DOWNLOAD_PROPERTY_DOCUMENT');
export const DOWNLOAD_PROPERTY_DOCUMENT_SUCCESS = ('DOWNLOAD_PROPERTY_DOCUMENT_SUCCESS');
export const DOWNLOAD_PROPERTY_DOCUMENT_ERROR = ('DOWNLOAD_PROPERTY_DOCUMENT_ERROR');
export const DELETE_PROPERTY_DOCUMENT = ('DELETE_PROPERTY_DOCUMENT');
export const DELETE_PROPERTY_DOCUMENT_SUCCESS = ('DELETE_PROPERTY_DOCUMENT_SUCCESS');
export const DELETE_PROPERTY_DOCUMENT_ERROR = ('DELETE_PROPERTY_DOCUMENT_ERROR');
export const PURCHASE_REPORT = ('PURCHASE_REPORT');
export const PURCHASE_REPORT_SUCCESS = ('PURCHASE_REPORT_SUCCESS');
export const PURCHASE_REPORT_ERROR = ('PURCHASE_REPORT_ERROR');
export const DOWNLOAD_PROPERTY_REPORT = ('DOWNLOAD_PROPERTY_REPORT');
export const DOWNLOAD_PROPERTY_REPORT_SUCCESS = ('DOWNLOAD_PROPERTY_REPORT_SUCCESS');
export const DOWNLOAD_PROPERTY_REPORT_ERROR = ('DOWNLOAD_PROPERTY_REPORT_ERROR');
export const GET_STREET_VIEW = ('GET_STREET_VIEW');
export const GET_STREET_VIEW_SUCCESS = ('GET_STREET_VIEW_SUCCESS');
export const GET_STREET_VIEW_ERROR = ('GET_STREET_VIEW_ERROR');
export const IMPORT_PROPERTIES = ('IMPORT_PROPERTIES');
export const IMPORT_PROPERTIES_SUCCESS = ('IMPORT_PROPERTIES_SUCCESS');
export const IMPORT_PROPERTIES_ERROR = ('IMPORT_PROPERTIES_ERROR');
export const GET_IMPORT_JOBS = ('GET_IMPORT_JOBS');
export const GET_IMPORT_JOBS_SUCCESS = ('GET_IMPORT_JOBS_SUCCESS');
export const GET_IMPORT_JOBS_ERROR = ('GET_IMPORT_JOBS_ERROR');
export const DELETE_IMPORT_JOB = ('DELETE_IMPORT_JOB');
export const DELETE_IMPORT_JOB_SUCCESS = ('DELETE_IMPORT_JOB_SUCCESS');
export const DELETE_IMPORT_JOB_ERROR = ('DELETE_IMPORT_JOB_ERROR');
export const DOWNLOAD_IMPORT_JOB_FILE = ('DOWNLOAD_IMPORT_JOB_FILE');
export const DOWNLOAD_IMPORT_JOB_FILE_SUCCESS = ('DOWNLOAD_IMPORT_JOB_FILE_SUCCESS');
export const DOWNLOAD_IMPORT_JOB_FILE_ERROR = ('DOWNLOAD_IMPORT_JOB_FILE_ERROR');
export const DOWNLOAD_IMPORT_JOB_REPORT = ('DOWNLOAD_IMPORT_JOB_REPORT');
export const DOWNLOAD_IMPORT_JOB_REPORT_SUCCESS = ('DOWNLOAD_IMPORT_JOB_REPORT_SUCCESS');
export const DOWNLOAD_IMPORT_JOB_REPORT_ERROR = ('DOWNLOAD_IMPORT_JOB_REPORT_ERROR');

export const SEARCH_GROUP_CONTEXT_CACHED = ('SEARCH_GROUP_CONTEXT_CACHED');
export const LOAD_GROUP_CONTEXT = ('LOAD_GROUP_CONTEXT');
export const UPDATE_GROUP_CONTEXT = ('UPDATE_GROUP_CONTEXT');
export const SELECT_GROUP_CONTEXT = ('SELECT_GROUP_CONTEXT');
export const UPDATE_SURROUNDING_PROPERTY_SEARCH = ('UPDATE_SURROUNDING_PROPERTY_SEARCH');
export const RESET_SURROUNDING_PROPERTY_SEARCH = ('RESET_SURROUNDING_PROPERTY_SEARCH');
export const UPDATE_SURROUNDING_PROPERTY_SELECTION = ('UPDATE_SURROUNDING_PROPERTY_SELECTION');
export const UPDATE_SURROUNDING_PROPERTY_TYPE = ('UPDATE_SURROUNDING_PROPERTY_TYPE');
export const SORT_SURROUNDING_PROPERTIES = ('SORT_SURROUNDING_PROPERTIES');
export const ASSIGN_PROPERTIES = ('ASSIGN_PROPERTIES');
export const UNASSIGN_PROPERTIES = ('UNASSIGN_PROPERTIES');

// TEMP actions for working with one saved property group at a time
export const SET_PROPERTY_SELECTION = ('SET_PROPERTY_SELECTION');
export const CLEAR_PROPERTY_SELECTION = ('CLEAR_PROPERTY_SELECTION');

function request(path, method, startType, successType, errorType, body, { unifier = '', afterSuccess, afterError, params, successParams, download = false, dislodging = false, query } = {}) {
  return {
    [CALL_API]: {
      method,
      startType,
      successType,
      errorType,
      body,
      afterSuccess,
      afterError,
      params,
      successParams,
      query,
      download,
      dislodging,
      unifier: `${path}-${unifier}`,
      path: joinPath('/resource/auth/ps4', path),
      // ...(method === 'post' && !isFormData(body) ? { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } } : {}),
    },
  };
}


function mapToObj(map){
  const obj = {}
  for (let [k,v] of map)
    obj[k] = v
  return obj
}



function requestMapToObj(path, method, startType, successType, errorType, body, { unifier = '', afterSuccess, afterError, params, successParams, download = false, dislodging = false, query } = {}) {
  return {
    [CALL_API]: {
      method,
      startType,
      successType,
      errorType,
      body : body ? JSON.stringify(mapToObj(body)) : null,
      afterSuccess,
      afterError,
      params,
      successParams,
      query,
      download,
      dislodging,
      unifier: `${path}-${unifier}`,
      path: joinPath('/resource/auth/ps4', path),
      headers: { 'Content-Type': 'application/json' }
      // ...(method === 'post' && !isFormData(body) ? { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } } : {}),
    },
  };
}

const getSearch = context => context.merge({ data: null, info: null, fullSelection: null, name: context.getIn(['info', 'name']), type: context.getIn(['info', 'type']) });

const property = (path, method, startType, successType, errorType, body, params) => request(joinPath('property', path), method, startType, successType, errorType, body, params);

const propertyNew = (path, method, startType, successType, errorType, body, params) => request(joinPath('property/new', path), method, startType, successType, errorType, body, params);

const propertyNewUpload = (path, method, startType, successType, errorType, body, params) => requestMapToObj(joinPath('property/new', path), method, startType, successType, errorType, body, params);

const user = (path, method, startType, successType, errorType, body, params) => request(joinPath('user/new/properties', path), method, startType, successType, errorType, body, params);

const group = (path, method, startType, successType, errorType, body, params) => user(joinPath('groups', path), method, startType, successType, errorType, body, params);

const imports = (path, method, startType, successType, errorType, body, params) => request(joinPath('user/properties/imports', path), method, startType, successType, errorType, body, params);

export const saveProperty = (id, group, afterSuccess) => user(id, 'post', SAVE_PROPERTY, SAVE_PROPERTY_SUCCESS, SAVE_PROPERTY_ERROR, group || {}, { afterSuccess });

export const saveProperties = (ids, groupName, groupType, listingType, afterSuccess) => user(null, 'post', SAVE_PROPERTIES, SAVE_PROPERTIES_SUCCESS, SAVE_PROPERTIES_ERROR, ids, { afterSuccess, query: { groupName, groupType, listingType } });

export const getProperty = (id, mode, afterSuccess) => {
  const params = { afterSuccess, query: { m: mode } };

  // Load the active full property detail. FIXME: Pulling full detail for map until it's modified to accept partial detail.
  if (mode === 'F' || mode === 'M') return property(id, 'get', GET_PROPERTY, GET_PROPERTY_SUCCESS, GET_PROPERTY_ERROR, null, params);

  // Load partial/transient detail (map info box, gallery).
  return property(id, 'get', RETRIEVE_PROPERTY, RETRIEVE_PROPERTY_SUCCESS, RETRIEVE_PROPERTY_ERROR, null, params);
};

export const searchGroupContext = (context) => {
  if (context.getIn(['info', 'data'])) return { type: SEARCH_GROUP_CONTEXT_CACHED, context };
  return group(null, 'post', SEARCH_GROUP_CONTEXT, SEARCH_GROUP_CONTEXT_SUCCESS, SEARCH_GROUP_CONTEXT_ERROR, getSearch(context).set('selection', null), { successParams: { context } });
};

export const importProperties = (file, groupName, afterSuccess) => imports(null, 'post', IMPORT_PROPERTIES, IMPORT_PROPERTIES_SUCCESS, IMPORT_PROPERTIES_ERROR, getFormData([{ name: 'file', file }, { name: 'groupName', value: groupName || '' }]), { afterSuccess });

export const getImportJobs = afterSuccess => imports(null, 'get', GET_IMPORT_JOBS, GET_IMPORT_JOBS_SUCCESS, GET_IMPORT_JOBS_ERROR, null, { afterSuccess });

export const deleteImportJob = id => imports(id, 'delete', DELETE_IMPORT_JOB, DELETE_IMPORT_JOB_SUCCESS, DELETE_IMPORT_JOB_ERROR);

export const downloadImportJobFile = id => imports(`${id}/file`, 'get', DOWNLOAD_IMPORT_JOB_FILE, DOWNLOAD_IMPORT_JOB_FILE_SUCCESS, DOWNLOAD_IMPORT_JOB_FILE_ERROR, null, { download: true });

export const downloadImportJobReport = id => imports(`${id}/report`, 'get', DOWNLOAD_IMPORT_JOB_REPORT, DOWNLOAD_IMPORT_JOB_REPORT_SUCCESS, DOWNLOAD_IMPORT_JOB_REPORT_ERROR, null, { download: true });

export const loadContextIds = (context, selection) => group(null, 'post', SEARCH_GROUP_CONTEXT, SEARCH_GROUP_CONTEXT_SUCCESS, SEARCH_GROUP_CONTEXT_ERROR, { selection }, { successParams: { context } });

export const deletePropertyGroup = (id, deleteContacts, listingType,afterSuccess) => request(`user/new2/properties/groups/${id}`, 'delete', DELETE_PROPERTY_GROUP, DELETE_PROPERTY_GROUP_SUCCESS, DELETE_PROPERTY_GROUP_ERROR, null, {afterSuccess, query: { deleteContacts, listingType } });

export const savePropertyGroupOptions = (group, afterSuccess) => request('user/new/properties/groups/options', 'put', SAVE_PROPERTY_GROUP_OPTIONS, SAVE_PROPERTY_GROUP_OPTIONS_SUCCESS, SAVE_PROPERTY_GROUP_OPTIONS_ERROR, group, { afterSuccess });

export const loadGroupPushpins = (context, afterSuccess) => group(null, 'post', LOAD_GROUP_PUSHPINS, LOAD_GROUP_PUSHPINS_SUCCESS, LOAD_GROUP_PUSHPINS_ERROR, getSearch(context).set('mode', GroupSearchModes.PUSHPIN), { afterSuccess });

export const loadGroupContext = (name, groupId, defaults) => ({ type: LOAD_GROUP_CONTEXT, name, groupId, defaults });

export const updateGroupContext = context => ({ type: UPDATE_GROUP_CONTEXT, context });

export const updateGroupContextSelection = (context, select, index) => ({ type: SELECT_GROUP_CONTEXT, context, select, index });

export const updateSurroundingPropertySearch = (context, search, runSearch) => ({ type: UPDATE_SURROUNDING_PROPERTY_SEARCH, context, search, runSearch });

export const resetSurroundingPropertySearch = context => ({ type: RESET_SURROUNDING_PROPERTY_SEARCH, context });

// TEMP actions for working with one saved property group at a time
export const setPropertySelection = selection => ({ type: SET_PROPERTY_SELECTION, selection });

export const clearPropertySelection = selection => ({ type: CLEAR_PROPERTY_SELECTION, selection });

export const searchComparables = (subjectPropertyId, context, search, afterSuccess) => requestMapToObj('property/comparables', 'post', SEARCH_COMPARABLES, SEARCH_COMPARABLES_SUCCESS, SEARCH_COMPARABLES_ERROR, search.merge({ subjectPropertyId }).update('shapeDefinition', def => (def ? def.join('/') : null)), { dislodging: true, params: { context }, afterSuccess: afterSuccess ? () => afterSuccess(context) : null });

export const searchNeighbors = (subjectPropertyId, context, search, afterSuccess) => propertyNew(`${subjectPropertyId}/neighbors`, 'post', SEARCH_NEIGHBORS, SEARCH_NEIGHBORS_SUCCESS, SEARCH_NEIGHBORS_ERROR, search.update('shapeDefinition', def => (def ? def.join('/') : null)), { dislodging: true, params: { context }, afterSuccess: afterSuccess ? () => afterSuccess(context) : null });

export const downloadComparables = (subjectPropertyId, search) => propertyNewUpload('comparables/report', 'post', DOWNLOAD_COMPARABLES, DOWNLOAD_COMPARABLES_SUCCESS, DOWNLOAD_COMPARABLES_ERROR, search.merge({ subjectPropertyId }).update('shapeDefinition', def => (def ? def.join('/') : null)), { download: true, query: { mls: true } });

export const exportComparables = (subjectPropertyId, search, csv) => requestMapToObj('property/comparables/export', 'post', EXPORT_COMPARABLES, EXPORT_COMPARABLES_SUCCESS, EXPORT_COMPARABLES_ERROR, search.merge({ subjectPropertyId }).update('shapeDefinition', def => (def ? def.join('/') : null)), { download: true, query: { csv } });

export const searchFlipComparables = (subjectPropertyId, context, search, afterSuccess) => request(`property/${subjectPropertyId}/flipcomps`, 'post', SEARCH_FLIP_COMPARABLES, SEARCH_FLIP_COMPARABLES_SUCCESS, SEARCH_FLIP_COMPARABLES_ERROR, search.update('shapeDefinition', def => (def ? def.join('/') : null)), { dislodging: true, params: { context }, afterSuccess: afterSuccess ? () => afterSuccess(context) : null });

export const exportFlipComparables = (subjectPropertyId, search, csv) => requestMapToObj(`property/${subjectPropertyId}/flipcomps/export`, 'post', EXPORT_FLIP_COMPARABLES, EXPORT_FLIP_COMPARABLES_SUCCESS, EXPORT_FLIP_COMPARABLES_ERROR, search.update('shapeDefinition', def => (def ? def.join('/') : null)), { download: true, query: { csv } });

export const saveSurroundingSearch = (type, search, afterSuccess) => requestMapToObj(`user/properties/surrounding/${type}`, 'put', SAVE_SURROUNDING_SEARCH, SAVE_SURROUNDING_SEARCH_SUCCESS, SAVE_SURROUNDING_SEARCH_ERROR, search.update('shapeDefinition', def => (def ? def.join('/') : null)), { afterSuccess });

// export const exportGroupProperties = (search, name, csv, afterSuccess) => request('user/properties/exportnew', 'post', EXPORT_GROUP_PROPERTIES, EXPORT_GROUP_PROPERTIES_SUCCESS, EXPORT_GROUP_PROPERTIES_ERROR, search, { afterSuccess, query: { name, csv } });


export const searchSurroundingProperties = (subjectPropertyId, context, listingType, search, onSuccess, savedPropertyId) => {
  // Sending listingType as query param as hack to get it to reducer. Should be a way to pass params to reducer in all stages.
  const afterSuccess = onSuccess ? () => onSuccess(context) : null;

  if (listingType) return propertyNew(`${subjectPropertyId}/surrounding/${listingType}`, 'post', SEARCH_SURROUNDING_PROPERTIES, SEARCH_SURROUNDING_PROPERTIES_SUCCESS, SEARCH_SURROUNDING_PROPERTIES_ERROR, search.update('shapeDefinition', def => (def ? def.join('/') : null)), { params: { listingType, context, afterSuccess }, query: { listingType } });
  return propertyNew(`${subjectPropertyId}/surrounding`, 'get', SEARCH_SURROUNDING_PROPERTIES, SEARCH_SURROUNDING_PROPERTIES_SUCCESS, SEARCH_SURROUNDING_PROPERTIES_ERROR, null, { params: { listingType, context, afterSuccess }, query: { savedPropertyId } });
};

export const sortSurroundingProperties = (context, sort) => ({ type: SORT_SURROUNDING_PROPERTIES, context, sort });

export const updateSurroundingPropertySelection = (context, index, selected, typeCode) => ({ type: UPDATE_SURROUNDING_PROPERTY_SELECTION, context, index, selected, typeCode });

export const updateSurroundingPropertyType = (context, newType) => ({ type: UPDATE_SURROUNDING_PROPERTY_TYPE, context, newType });

export const loadPropertyDocuments = savedPropertyId => request(`user/properties/${savedPropertyId}/documents`, 'get', LOAD_PROPERTY_DOCUMENTS, LOAD_PROPERTY_DOCUMENTS_SUCCESS, LOAD_PROPERTY_DOCUMENTS_ERROR);

export const editPropertyDocument = document => request('user/documents', 'put', EDIT_PROPERTY_DOCUMENT, EDIT_PROPERTY_DOCUMENT_SUCCESS, EDIT_PROPERTY_DOCUMENT_ERROR, document);

export const uploadPropertyDocument = (savedPropertyId, document) => request(`user/properties/${savedPropertyId}/documents`, 'post', UPLOAD_PROPERTY_DOCUMENT, UPLOAD_PROPERTY_DOCUMENT_SUCCESS, UPLOAD_PROPERTY_DOCUMENT_ERROR, document);

export const downloadPropertyDocument = documentId => request(`user/documents/${documentId}`, 'get', DOWNLOAD_PROPERTY_DOCUMENT, DOWNLOAD_PROPERTY_DOCUMENT_SUCCESS, DOWNLOAD_PROPERTY_DOCUMENT_ERROR, null, { download: true });

export const deletePropertyDocument = (documentId, afterSuccess) => request(`user/documents/${documentId}`, 'delete', DELETE_PROPERTY_DOCUMENT, DELETE_PROPERTY_DOCUMENT_SUCCESS, DELETE_PROPERTY_DOCUMENT_ERROR, null, { afterSuccess });

export const purchaseReport = (savedPropertyId, reportCode, confirmedPrice, propertySaleId, preForeclosureId, afterSuccess) => request(`user/properties/${savedPropertyId}/reports/${reportCode}`, 'post', PURCHASE_REPORT, PURCHASE_REPORT_SUCCESS, PURCHASE_REPORT_ERROR, null, { query: { confirmedPrice, propertySaleId, preForeclosureId }, afterSuccess });

export const downloadReport = (propertyId, report) => request(`property/${propertyId}/reports/${report}`, 'get', DOWNLOAD_PROPERTY_REPORT, DOWNLOAD_PROPERTY_REPORT_SUCCESS, DOWNLOAD_PROPERTY_REPORT_ERROR, null, { download: true });

export const getStreetView = (propertyId, afterSuccess) => request(`map/streetside/${propertyId}`, 'get', GET_STREET_VIEW, GET_STREET_VIEW_SUCCESS, GET_STREET_VIEW_ERROR, null, { afterSuccess });

export const purchaseReportAndLoad = (propertyId, reportCode, confirmedPrice, propertySaleId, preForeclosureId, afterSuccess) => ({
  [CHAIN_API]: [
    () => purchaseReport(propertyId, reportCode, confirmedPrice, propertySaleId, preForeclosureId, afterSuccess),
    response => downloadPropertyDocument(response.find(d => d.modified).id),
  ],
});

export const assignContext = () => ({ type: ASSIGN_PROPERTIES });

export const unassignContext = () => ({ type: UNASSIGN_PROPERTIES });


export const GET_GROUP_SEARCH = ('GET_GROUP_SEARCH');
export const GET_GROUP_SEARCH_SUCCESS = ('GET_GROUP_SEARCH_SUCCESS');
export const GET_GROUP_SEARCH_ERROR = ('GET_GROUP_SEARCH_ERROR');
export const SAVE_GROUP_SEARCH = ('SAVE_GROUP_SEARCH');
export const SAVE_GROUP_SEARCH_SUCCESS = ('SAVE_GROUP_SEARCH_SUCCESS');
export const SAVE_GROUP_SEARCH_ERROR = ('SAVE_GROUP_SEARCH_ERROR');
export const SAVE_GROUP_PROPERTIES = ('SAVE_GROUP_PROPERTIES');
export const SAVE_GROUP_PROPERTIES_SUCCESS = ('SAVE_GROUP_PROPERTIES_SUCCESS');
export const SAVE_GROUP_PROPERTIES_ERROR = ('SAVE_GROUP_PROPERTIES_ERROR');
export const SEARCH_GROUP_PROPERTIES = ('SEARCH_GROUP_PROPERTIES');
export const SEARCH_GROUP_PROPERTIES_SUCCESS = ('SEARCH_GROUP_PROPERTIES_SUCCESS');
export const SEARCH_GROUP_PROPERTIES_ERROR = ('SEARCH_GROUP_PROPERTIES_ERROR');
export const GET_GROUP_PROPERTY_COUNTS = ('GET_GROUP_PROPERTY_COUNTS');
export const GET_GROUP_PROPERTY_COUNTS_SUCCESS = ('GET_GROUP_PROPERTY_COUNTS_SUCCESS');
export const GET_GROUP_PROPERTY_COUNTS_ERROR = ('GET_GROUP_PROPERTY_COUNTS_ERROR');
export const DELETE_GROUP_PROPERTIES = ('DELETE_GROUP_PROPERTIES');
export const DELETE_GROUP_PROPERTIES_SUCCESS = ('DELETE_GROUP_PROPERTIES_SUCCESS');
export const DELETE_GROUP_PROPERTIES_ERROR = ('DELETE_GROUP_PROPERTIES_ERROR');
export const GET_SAVED_PROPERTY_DETAIL = ('GET_SAVED_PROPERTY_DETAIL');
export const GET_SAVED_PROPERTY_DETAIL_SUCCESS = ('GET_SAVED_PROPERTY_DETAIL_SUCCESS');
export const GET_SAVED_PROPERTY_DETAIL_ERROR = ('GET_SAVED_PROPERTY_DETAIL_ERROR');
export const EXPORT_GROUP_PROPERTIES = ('EXPORT_GROUP_PROPERTIES');
export const EXPORT_GROUP_PROPERTIES_SUCCESS = ('EXPORT_GROUP_PROPERTIES_SUCCESS');
export const EXPORT_GROUP_PROPERTIES_ERROR = ('EXPORT_GROUP_PROPERTIES_ERROR');
export const GET_PROPERTY_EXPORT = ('GET_PROPERTY_EXPORT');
export const GET_PROPERTY_EXPORT_SUCCESS = ('GET_PROPERTY_EXPORT_SUCCESS');
export const GET_PROPERTY_EXPORT_ERROR = ('GET_PROPERTY_EXPORT_ERROR');
export const CONFIRM_PROPERTY_EXPORT = ('CONFIRM_PROPERTY_EXPORT');
export const CONFIRM_PROPERTY_EXPORT_SUCCESS = ('CONFIRM_PROPERTY_EXPORT_SUCCESS');
export const CONFIRM_PROPERTY_EXPORT_ERROR = ('CONFIRM_PROPERTY_EXPORT_ERROR');
export const SEARCH_PROPERTY_CONTACTS = ('SEARCH_PROPERTY_CONTACTS');
export const SEARCH_PROPERTY_CONTACTS_SUCCESS = ('SEARCH_PROPERTY_CONTACTS_SUCCESS');
export const SEARCH_PROPERTY_CONTACTS_ERROR = ('SEARCH_PROPERTY_CONTACTS_ERROR');
export const SAVE_PROPERTY_FILTER = ('SAVE_PROPERTY_FILTER');
export const SAVE_PROPERTY_FILTER_SUCCESS = ('SAVE_PROPERTY_FILTER_SUCCESS');
export const SAVE_PROPERTY_FILTER_ERROR = ('SAVE_PROPERTY_FILTER_ERROR');
export const DELETE_PROPERTY_FILTER = ('DELETE_PROPERTY_FILTER');
export const DELETE_PROPERTY_FILTER_SUCCESS = ('DELETE_PROPERTY_FILTER_SUCCESS');
export const DELETE_PROPERTY_FILTER_ERROR = ('DELETE_PROPERTY_FILTER_ERROR');
export const SAVE_PROPERTY_GROUP_FILTER = ('SAVE_PROPERTY_GROUP_FILTER');
export const SAVE_PROPERTY_GROUP_FILTER_SUCCESS = ('SAVE_PROPERTY_GROUP_FILTER_SUCCESS');
export const SAVE_PROPERTY_GROUP_FILTER_ERROR = ('SAVE_PROPERTY_GROUP_FILTER_ERROR');
export const DELETE_PROPERTY_GROUP_FILTER = ('DELETE_PROPERTY_GROUP_FILTER');
export const DELETE_PROPERTY_GROUP_FILTER_SUCCESS = ('DELETE_PROPERTY_GROUP_FILTER_SUCCESS');
export const DELETE_PROPERTY_GROUP_FILTER_ERROR = ('DELETE_PROPERTY_GROUP_FILTER_ERROR');
export const DELETE_SAVED_PROPERTY = ('DELETE_SAVED_PROPERTY');
export const DELETE_SAVED_PROPERTY_SUCCESS = ('DELETE_SAVED_PROPERTY_SUCCESS');
export const DELETE_SAVED_PROPERTY_ERROR = ('DELETE_SAVED_PROPERTY_ERROR');
export const DOWNLOAD_PROPERTY_DATA = ('DOWNLOAD_PROPERTY_DATA');
export const DOWNLOAD_PROPERTY_DATA_SUCCESS = ('DOWNLOAD_PROPERTY_DATA_SUCCESS');
export const DOWNLOAD_PROPERTY_DATA_ERROR = ('DOWNLOAD_PROPERTY_DATA_ERROR');
export const SAVE_PROPERTY_FOLDER = ('SAVE_PROPERTY_GROUP_FILTER');
export const SAVE_PROPERTY_FOLDER_SUCCESS = ('SAVE_PROPERTY_FOLDER_SUCCESS');
export const SAVE_PROPERTY_FOLDER_ERROR = ('SAVE_PROPERTY_FOLDER_ERROR');
export const DELETE_PROPERTY_FOLDER = ('DELETE_PROPERTY_FOLDER');
export const DELETE_PROPERTY_FOLDER_SUCCESS = ('DELETE_PROPERTY_FOLDER_SUCCESS');
export const DELETE_PROPERTY_FOLDER_ERROR = ('DELETE_PROPERTY_FOLDER_ERROR');
export const SAVE_PROPERTY_STATUS = ('SAVE_PROPERTY_STATUS');
export const SAVE_PROPERTY_STATUS_SUCCESS = ('SAVE_PROPERTY_STATUS_SUCCESS');
export const SAVE_PROPERTY_STATUS_ERROR = ('SAVE_PROPERTY_STATUS_ERROR');

export const searchGroupProperties = (search, refreshFilters, afterSuccess, afterError) => request('user/properties', 'post', SEARCH_GROUP_PROPERTIES, SEARCH_GROUP_PROPERTIES_SUCCESS, SEARCH_GROUP_PROPERTIES_ERROR, search, { afterSuccess, afterError, query: { refreshFilters }, unifier: String(Math.random()) });

export const getGroupPropertyCounts = id => request(`user/properties/groups/${id}/counts`, 'get', GET_GROUP_PROPERTY_COUNTS, GET_GROUP_PROPERTY_COUNTS_SUCCESS, GET_GROUP_PROPERTY_COUNTS_ERROR);

export const deleteGroupProperties = (search, deleteContacts, duplicateMode, afterSuccess) => request('user/ag/properties', 'delete', DELETE_GROUP_PROPERTIES, DELETE_GROUP_PROPERTIES_SUCCESS, DELETE_GROUP_PROPERTIES_ERROR, search, { afterSuccess, query: { deleteContacts, duplicateMode } });

export const exportGroupProperties = (search, name, csv, isXML, afterSuccess) => request('user/properties/exportnew', 'post', EXPORT_GROUP_PROPERTIES, EXPORT_GROUP_PROPERTIES_SUCCESS, EXPORT_GROUP_PROPERTIES_ERROR, search, { afterSuccess, query: { name, csv, isXML } });

export const getPropertyExport = id => request(`user/properties/export/${id}`, 'get', GET_PROPERTY_EXPORT, GET_PROPERTY_EXPORT_SUCCESS, GET_PROPERTY_EXPORT_ERROR);

export const confirmPropertyExport = (id, afterSuccess) => request(`user/properties/export/${id}/confirmation`, 'post', CONFIRM_PROPERTY_EXPORT, CONFIRM_PROPERTY_EXPORT_SUCCESS, CONFIRM_PROPERTY_EXPORT_ERROR, null, { afterSuccess });

export const downloadPropertyData = id => request(`property/${id}/data`, 'get', DOWNLOAD_PROPERTY_DATA, DOWNLOAD_PROPERTY_DATA_SUCCESS, DOWNLOAD_PROPERTY_DATA_ERROR, null, { download: true });

export const getSavedPropertyDetail = (id, afterSuccess) => request(`user/ag/properties/${id}`, 'get', GET_SAVED_PROPERTY_DETAIL, GET_SAVED_PROPERTY_DETAIL_SUCCESS, GET_SAVED_PROPERTY_DETAIL_ERROR, null, { afterSuccess, unifier: String(Math.random()) });

export const deleteSavedProperty = (id, detailId, deleteContacts, afterSuccess) => request(`user/properties/${id}`, 'delete', DELETE_SAVED_PROPERTY, DELETE_SAVED_PROPERTY_SUCCESS, DELETE_SAVED_PROPERTY_ERROR, null, { query: { detailId, deleteContacts }, afterSuccess });

export const getGroupSearch = (id, afterSuccess) => request(`user/properties/groups/${id}/search`, 'get', GET_GROUP_SEARCH, GET_GROUP_SEARCH_SUCCESS, GET_GROUP_SEARCH_ERROR, null, { afterSuccess });

export const saveGroupSearch = (id, search, afterSuccess) => request(`user/properties/groups/${id}/search`, 'put', SAVE_GROUP_SEARCH, SAVE_GROUP_SEARCH_SUCCESS, SAVE_GROUP_SEARCH_ERROR, search, { afterSuccess });

export const savePropertyGroupFilter = (search, afterSuccess) => request('user/properties/groups/filters', 'put', SAVE_PROPERTY_GROUP_FILTER, SAVE_PROPERTY_GROUP_FILTER_SUCCESS, SAVE_PROPERTY_GROUP_FILTER_ERROR, search, { afterSuccess });

export const deletePropertyGroupFilter = (id, afterSuccess) => request(`user/properties/groups/filters/${id}`, 'delete', DELETE_PROPERTY_GROUP_FILTER, DELETE_PROPERTY_GROUP_FILTER_SUCCESS, DELETE_PROPERTY_GROUP_FILTER_ERROR, null, { afterSuccess });

export const saveGroupProperties = (group, search, deleteOriginal = false, afterSuccess) => request('user/ag/properties', 'put', SAVE_GROUP_PROPERTIES, SAVE_GROUP_PROPERTIES_SUCCESS, SAVE_GROUP_PROPERTIES_ERROR, { group, search }, { afterSuccess, query: { deleteOriginal } });

export const searchPropertyContacts = (search, afterSuccess) => request('user/ag/properties/contacts/ids', 'post', SEARCH_PROPERTY_CONTACTS, SEARCH_PROPERTY_CONTACTS_SUCCESS, SEARCH_PROPERTY_CONTACTS_ERROR, search, { afterSuccess });

export const saveFilter = (layout, savedPropertyGroupId, afterSuccess) => request('user/properties/filters', 'put', SAVE_PROPERTY_FILTER, SAVE_PROPERTY_FILTER_SUCCESS, SAVE_PROPERTY_FILTER_ERROR, layout, { afterSuccess, query: { savedPropertyGroupId } });

export const deleteFilter = (id, savedPropertyGroupId, afterSuccess) => request(`user/properties/filters/${id}`, 'delete', DELETE_PROPERTY_FILTER, DELETE_PROPERTY_FILTER_SUCCESS, DELETE_PROPERTY_FILTER_ERROR, null, { afterSuccess, query: { savedPropertyGroupId } });

export const savePropertyFolder = (folder, groupId, afterSuccess) => request('user/properties/folders', 'put', SAVE_PROPERTY_FOLDER, SAVE_PROPERTY_FOLDER_SUCCESS, SAVE_PROPERTY_FOLDER_ERROR, folder, { afterSuccess, query: { groupId } });

export const deletePropertyFolder = (id, afterSuccess) => request(`user/properties/folders/${id}`, 'delete', DELETE_PROPERTY_FOLDER, DELETE_PROPERTY_FOLDER_SUCCESS, DELETE_PROPERTY_FOLDER_ERROR, null, { afterSuccess });

export const savePropertyStatus = (id, status, afterSuccess) => request(`property/${id}/status`, 'put', SAVE_PROPERTY_STATUS, SAVE_PROPERTY_STATUS_SUCCESS, SAVE_PROPERTY_STATUS_ERROR, status, { afterSuccess });