import React, { PureComponent } from 'react';


export default function wrap(Component) {
  return class NumberField extends PureComponent {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event, ...rest) {
      const { value: val, name } = event.target;
      const value = val.replace(/\D+/g, '');
      this.props.onChange({ value, name }, ...rest);
    }

    render() {
      const { value, onInputRef, ...rest } = this.props;
      return <Component {...rest} ref={onInputRef} value={value} onChange={this.handleChange} />;
    }
  };
}
