import React from 'react';
import StatUnit from 'components/StatUnit';
import Dropdown from 'components/base/Dropdown';
import classNames from 'classnames';

import css from './style.scss';

const Trend = ({ className, items, periods, title, onPeriodChange, currentPeriod, periodName }) => {
  return (
    <div className={classNames(className, css.trend)}>
      <div className={css.header}>
        <span>{title}</span>
        <Dropdown className={css.dropdown} name={periodName} options={periods} onChange={onPeriodChange} value={currentPeriod} />
      </div>
      <div className={css.trendItems}>
        {items.map(item => <StatUnit key={item.name} className={css.trendItem} stat={item} />)}
      </div>
    </div>
  );
};

export default Trend;
