import React from 'react';

import ColorDot from 'components/ColorDot';
import Checkbox from 'components/base/Checkbox';
import css from './style.scss';


export default function renderCheckboxCell(
  counter,
  indexes,
  data,
  _,
  extraProps = {},
) {
  const selectId = data.get('id');
  const checked = data.get('selected', false);

  const { activeMarker, onSelect } = extraProps;

  return (
    <div className={css.flex}>
      <Checkbox
        changeValue={selectId}
        onChange={onSelect}
        name={`SearchResultsItem-${counter}`}
        id={`SearchResultsItem-${counter}`}
        checked={checked}
      />
      <ColorDot isBig={false}>{counter}</ColorDot>
    </div>
  );
}
