import React, { createContext, useContext, useState } from "react";

const ActiveSectionContext = createContext();

export const useActiveSection = () => useContext(ActiveSectionContext);

export const ActiveSectionProvider = ({ children }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [openSections, setOpenSections] = useState({
    "ll": false,
    "pb": false,
    'pi':false,
    "mls": false,
    "pfbo": false,
    "oio": false,
    "lbd": false,
    "ve": false,
    "m": false
  });
  const [allSectionsOpen, setAllSectionsOpen] = useState(false);

  const saveStateToLocalStorage = (openSections, allSectionsOpen) => {
    localStorage.setItem("openSections", JSON.stringify(openSections));
    localStorage.setItem("allSectionsOpen", JSON.stringify(allSectionsOpen));
  };

  const toggleAllSections = () => {
    setAllSectionsOpen(prevState => !prevState);
    const updatedSections = {};
    for (let item of Object.keys(openSections)) {
      updatedSections[item] = !allSectionsOpen;
    }
    setOpenSections(updatedSections);
    saveStateToLocalStorage(updatedSections, !allSectionsOpen);
  };

  return (
    <ActiveSectionContext.Provider value={{ activeSection, setActiveSection,  openSections, setOpenSections, allSectionsOpen, setAllSectionsOpen, toggleAllSections, saveStateToLocalStorage}}>
      {children}
    </ActiveSectionContext.Provider>
  );
};
