import React from 'react';
import { pure } from 'recompose';

import classNames from 'classnames';
import Accordion from 'components/base/Accordion';
import Radio from 'components/base/Radio';
import AdditionalField from 'components/base/AdditionalField';
import { ExpenseIncreaseTypeValues } from 'data/analyses/constants';
import CurrencyInput from '../CurrencyInput';
import AnnualCurrencyInput from '../AnnualCurrencyInput';
import PercentInput from '../PercentInput';
import DetailsInput from '../DetailsInput';
import css from '../style.scss';


const Expenses = (props) => {
  const { analysisParams: a, onNumberParamsChange, onDetailsChange, onParamsChange, onManualTotalChange } = props;

  return (
    <div className={css.root}>
      <Accordion caption="Monthly Expense Amount" isOpened isLarge>
        <AnnualCurrencyInput label="Total Monthly Expenses" name="expenseTotal" value={a.oneYearPeriod} onChange={event => onManualTotalChange(event, 'expenses')} />
        <Accordion caption="Detail Breakdown" isOpened={a.detailStatus && a.detailStatus.expenses} isArrowRight>
          <div className={css.inlinesContainer}>
            {a.expenses.map(expense => (
              <DetailsInput className={css.inline} prop="expenses" detail={expense} key={expense.field.id} onDetailsChange={onDetailsChange} isInline annual />
            ))}
          </div>
        </Accordion>
      </Accordion>
      <Accordion caption="Annual Expense Increase" isOpened isLarge>
        <div className={css.label}>Increase Type</div>
        <div className={css.row}>
          {ExpenseIncreaseTypeValues.map(option => (
            <div className={css.col_3} key={option.label}>
              <Radio {...option} name="expenseIncreaseType" onChange={onParamsChange} checked={a.expenseIncreaseType === option.value} hasArrow />
            </div>
          ))}
        </div>
        <AdditionalField isActive={a.expenseIncreaseType === ExpenseIncreaseTypeValues[0].value}>
          <PercentInput label="Increase Rate" name="expenseIncreaseRate" value={a} onChange={onNumberParamsChange} />
        </AdditionalField>
        <AdditionalField isActive={a.expenseIncreaseType === ExpenseIncreaseTypeValues[1].value}>
          <div className={css.row}>
            <div className={classNames(css.col_3, css.col_offset_3)}>
              <CurrencyInput label="Increase Amount" name="expenseIncreaseAmount" value={a} onChange={onNumberParamsChange} />
            </div>
          </div>
        </AdditionalField>
      </Accordion>
    </div>
  );
};

export default pure(Expenses);
