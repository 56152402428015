import React from 'react';
import { pure } from 'recompose';

import Accordion from 'components/base/Accordion';
import Radio from 'components/base/Radio';
import AdditionalField from 'components/base/AdditionalField';
import { PropertyTypeValues, BooleanValues } from 'data/analyses/constants';

import DetailsInput from '../DetailsInput';
import CurrencyInput from '../CurrencyInput';
import css from '../style.scss';


const Purchase = (props) => {
  const {
    onParamsChange,
    analysisParams: a,
    onBooleanFieldChange,
    onDetailsChange,
    onNumberParamsChange,
    onManualTotalChange,
   } = props;

  return (
    <div className={css.root}>
      <Accordion caption="Type" isOpened isLarge>
        <div className={css.row}>
          <div className={css.col_6}>
            <div className={css.label}>What type of property is this?</div>
            <div className={css.row}>
              {PropertyTypeValues.map(option => (
                <div className={css.col_4} key={option.label}>
                  <Radio {...option} name="propertyType" onChange={onParamsChange} checked={a.propertyType === option.value} />
                </div>
                ))}
            </div>
          </div>
          <div className={css.col_6}>
            <div className={css.label}>Is the property in foreclosure?</div>
            <div className={css.row}>
              {BooleanValues.map(option => (
                <div className={css.col_4} key={option.label}>
                  <Radio {...option} name="foreclosure" onChange={onBooleanFieldChange} checked={a.foreclosure === option.value} hasArrow={option.value} />
                </div>
                ))}
            </div>
          </div>
        </div>
        <AdditionalField isActive={a.foreclosure}>
          <div className={css.row}>
            <div className={css.col_4}>
              <CurrencyInput label="Amount Of Default" name="defaultAmount" value={a} onChange={onNumberParamsChange} />
            </div>
            <div className={css.col_4}>
              <CurrencyInput label="Accrued Interest" name="accruedInterestAmount" value={a} onChange={onNumberParamsChange} />
            </div>
            <div className={css.col_4}>
              <CurrencyInput label="Other Fees" name="otherFeeAmount" value={a} onChange={onNumberParamsChange} />
            </div>
          </div>
        </AdditionalField>
      </Accordion>
      <Accordion caption="Value" isOpened isLarge>
        <div className={css.row}>
          <div className={css.col_6}>
            <CurrencyInput label="Purchase Price" name="purchaseAmount" value={a} onChange={onNumberParamsChange} />
          </div>
          <div className={css.col_6}>
            <CurrencyInput label="Estimated Market Value" name="marketValue" value={a} onChange={onNumberParamsChange} />
          </div>
        </div>
      </Accordion>
      <Accordion caption="Improvements" isOpened isLarge>
        <div>
          <CurrencyInput label="Improvements" name="improvementTotal" value={a} onChange={event => onManualTotalChange(event, 'improvements')} />
        </div>
        <Accordion caption="Detail Breakdown" isOpened={a.detailStatus && a.detailStatus.improvements} isArrowRight>
          <div className={css.inlinesContainer}>
            {a.improvements.map(improvement => (
              <DetailsInput className={css.inline} prop="improvements" detail={improvement} key={improvement.field.id} onDetailsChange={onDetailsChange} />
            ))}
          </div>
        </Accordion>
      </Accordion>
    </div>
  );
};


export default pure(Purchase);
