/** @flow */
import React, { cloneElement, PureComponent } from 'react';
import PropTypes from 'prop-types';


export default class HeaderCell extends PureComponent {
  isSimpleChild() {
    const { children } = this.props;
    const { type = '' } = children;
    return typeof type === 'string';
  }

  render() {
    const { children, meta } = this.props;
    if (!children) {
      return null;
    } else if (typeof children === 'string') {
      return <div>{children}</div>;
    } else if (this.isSimpleChild()) {
      return children;
    }
    return cloneElement(children, { meta });
  }
}

HeaderCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  meta: PropTypes.shape({
    field: PropTypes.string.isRequired,
  }),
};

