/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *  Don't update files without changing analisys.source
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
import AnalysisTermTypes from './AnalysisTermTypes';
import AnalysisPropertyTypes from './AnalysisPropertyTypes';
import AnalysisRateIncreaseTypes from './AnalysisRateIncreaseTypes';
import AnalysisDepreciationMethods from './AnalysisDepreciationMethods';
import AnalysisValueIncreaseTypes from './AnalysisValueIncreaseTypes';
import AnalysisValueBases from './AnalysisValueBases';
import getFieldAmount from './getFieldAmount';
import AnalysisFieldTypes from './AnalysisFieldTypes';
import AnalysisDepreciationPeriod from './AnalysisDepreciationPeriod';
import Mortgage from '../Mortgage/Mortgage';
import AnalysisClosingCosts from './AnalysisClosingCosts';
import AnalysisPeriod from './AnalysisPeriod';
import AnalysisMortgagePeriod from './AnalysisMortgagePeriod';
import AnalysisRentalUnit from './AnalysisRentalUnit';
import AnalysisIncome from './AnalysisIncome';
import AnalysisExpense from './AnalysisExpense';
import getField from './getField';
import AnalysisClosingCost from './AnalysisClosingCost';
import AnalysisImprovement from './AnalysisImprovement';
import AnalysisField from './AnalysisField';
import AnalysisExpenses from './AnalysisExpenses';
import MortgageTypes from '../Mortgage/MortgageTypes';

/* eslint-disable eqeqeq */
/* eslint-disable default-case */

function round(d) {
  return Math.round(d * 100) / 100;
}

function roundPct(d) {
  return Math.round(d * 1000000) / 1000000;
}

function today() {
  const d = new Date();

  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

function setFieldAmount(fields, type, fieldId, amount) {
  let f = getField(fields, fieldId);

  if (!amount && f != null) { fields.splice(fields.indexOf(f), 1); } else if (amount !== 0) {
    if (f == null) {
      switch (type) {
        case AnalysisFieldTypes.CLOSING_COST:
          f = new AnalysisClosingCost();
          break;
        case AnalysisFieldTypes.IMPROVEMENT:
          f = new AnalysisImprovement();
          break;
        case AnalysisFieldTypes.INCOME:
          f = new AnalysisIncome();
          break;
        case AnalysisFieldTypes.EXPENSE:
          f = new AnalysisExpense();
          break;
      }

      f.field = new AnalysisField(fieldId);
      fields.push(f);
    }

    f.amount = amount;
  }
}

export default class Analysis {
  constructor(analysis = {}, property) {
    Object.assign(this, analysis);

    if (property) this.applyProperty(property);

    this.process();
  }

  setNonNullDefaults() {
    this.firstPaymentDate = this.firstPaymentDate || today();
    this.propertyType = this.propertyType || AnalysisPropertyTypes.INVESTMENT;
    this.expenseIncreaseType = this.expenseIncreaseType || AnalysisRateIncreaseTypes.PERCENTAGE;
    this.incomeIncreaseType = this.incomeIncreaseType || AnalysisRateIncreaseTypes.PERCENTAGE;
    this.saleCostIncreaseType = Object.values(AnalysisRateIncreaseTypes).includes(this.saleCostIncreaseType)
      ? this.saleCostIncreaseType : null;
    this.depreciationType = Object.values(AnalysisRateIncreaseTypes).includes(this.depreciationType)
      ? this.depreciationType : null;
    this.depreciationMethod = Object.values(AnalysisDepreciationMethods).includes(this.depreciationMethod)
      ? this.depreciationMethod : null;
    this.propertyValueIncreaseType = this.propertyValueIncreaseType ||
      AnalysisValueIncreaseTypes.MARKET_VALUE_INCREASE_RATE;
    this.termType = this.termType || AnalysisTermTypes.MANUAL;
    this.propertyValueBasis = this.propertyValueBasis || AnalysisValueBases.PURCHASE_PRICE;

    this.mortgages = this.mortgages || [];
    this.closingCosts = this.closingCosts || [];
    this.improvements = this.improvements || [];
    this.incomes = this.incomes || [];
    this.expenses = this.expenses || [];
    this.rentalUnits = this.rentalUnits || [];
    this.depreciableYears = typeof this.depreciableYears === 'number' ? this.depreciableYears : 27.5;
    this.term = typeof this.term === 'number' ? this.term : 60;

    let props = ['purchaseAmount', 'marketValue', 'expenseIncreaseAmount', 'expenseIncreaseRate', 'defaultAmount',
      'accruedInterestAmount', 'otherFeeAmount', 'leasingCommissionRate', 'incomeIncreaseAmount',
      'incomeIncreaseRate', 'propertyManagementRate', 'capitalGainTaxRate', 'stateTaxRate', 'federalTaxRate',
      'marketValueIncreaseRate', 'capRate', 'inflationRate', 'saleCostAmount',
      'saleCostRate', 'depreciableAmount', 'propertyValue', 'saleAmount'];
    for (let i = 0; i < props.length; i++) {
      if (typeof this[props[i]] !== 'number') { this[props[i]] = 0; }
    }

    props = ['defaultAnalysis', 'foreclosure', 'expensePerRentalUnit'];
    for (let i = 0; i < props.length; i++) {
      if (typeof this[props[i]] !== 'boolean') { this[props[i]] = false; }
    }

    this.useCorrectPrototypesForStructs();
  }

  useCorrectPrototypesForStructs() { // Saritasa's code
    this.closingCosts = this.closingCosts
      .map(cost => (cost instanceof AnalysisClosingCost ? cost : Object.assign(new AnalysisClosingCost(), cost)));
    this.expenses = this.expenses
      .map(exp => (exp instanceof AnalysisExpense ? exp : Object.assign(new AnalysisExpense(), exp)));
    this.improvements = this.improvements
      .map(imp => (imp instanceof AnalysisImprovement ? imp : Object.assign(new AnalysisImprovement(), imp)));
    this.incomes = this.incomes
      .map(inc => (inc instanceof AnalysisIncome ? inc : Object.assign(new AnalysisIncome(), inc)));
    this.mortgages = this.mortgages
      .map(mort => (mort instanceof Mortgage ? mort : Object.assign(new Mortgage(), mort)));
  }

  getClosingCostAmount(closingCostId) {
    return getFieldAmount(this.closingCosts, closingCostId);
  }

  setClosingCostAmount(closingCostId, amount) {
    setFieldAmount(this.closingCosts, AnalysisFieldTypes.CLOSING_COST, closingCostId, amount);
  }

  getImprovementAmount(improvementId) {
    return getFieldAmount(this.improvements, improvementId);
  }

  setImprovementAmount(improvementId, amount) {
    setFieldAmount(this.improvements, AnalysisFieldTypes.IMPROVEMENT, improvementId, amount);
  }

  getIncomeAmount(incomeId) {
    return getFieldAmount(this.incomes, incomeId);
  }

  setIncomeAmount(incomeId, amount) {
    setFieldAmount(this.incomes, AnalysisFieldTypes.INCOME, incomeId, amount);
  }

  getExpenseAmount(expenseId) {
    return getFieldAmount(this.expenses, expenseId);
  }

  setExpenseAmount(expenseId, amount) {
    setFieldAmount(this.expenses, AnalysisFieldTypes.EXPENSE, expenseId, amount);
  }

  getEffectiveDepreciableAmount() {
    return this.depreciationType == AnalysisRateIncreaseTypes.DOLLAR_AMOUNT ?
      this.depreciableAmount : this.purchaseAmount * this.depreciableAmount;
  }

  getFirstPeriod() {
    return this.periods && this.periods.length ? this.periods[0] : null;
  }

  setLeasingCommissionAmount(leasingCommissionAmount) {
    const p = this.getFirstPeriod();

    if (p) {
      this.leasingCommissionRate = p.rentAmount == 0 ? 0 : roundPct(leasingCommissionAmount / p.rentAmount);
      p.leasingCommissionAmount = leasingCommissionAmount;
    }
  }

  applyProperty(property) {
    // Extract property if the object passed in is a saved property
    if (property.fullProperty) {
      // eslint-disable-next-line no-param-reassign
      property = property.fullProperty;
    }

    // TODO: income should be estimated rent value
    this.setExpenseAmount(AnalysisExpenses.PROPERTY_TAXES_ID, property.taxAmount || 0);
    this.setExpenseAmount(AnalysisExpenses.ASSOCIATION_FEES_ID, property.hoaFeeAnnualTotal || 0);
    this.marketValue = property.estimatedValue || 0;
    if (property.foreclosure) {
      this.foreclosure = true;
      this.defaultAmount = property.foreclosure.defaultAmount;
    }
  }

  /**
   * In double declining balance, you take the normal straightline depreciation
   * (Depreciation=DepreciableAmount/DepreciableYears), get the percentage that that is
   * of the depreciable amount times 2 (DepreciationDdb=2*Depreciation/DepreciableAmount),
   * and use that percentage of the remaining depreciable amount
   * to determine the depreciation each period (Depreciation=DepreciationDdb*RemainingDereciableAmount).
   * At the point where the ddb amount would go under
   * the straightline depreciation, stop using ddb and go back to the fixed straightline amount.
   *
   * @param depreciableAmount
   * @return
   */

  getDepreciationSchedule(depreciableAmount) {
    const schedule = [];

    if (depreciableAmount > 0 && this.depreciableYears > 0) {
      // Determine depreciation amounts
      let remainingAmount = depreciableAmount;
      const straightlineAmount = round(depreciableAmount / this.depreciableYears);
      const ddbRate = 2 * round(straightlineAmount / depreciableAmount);

      while (remainingAmount > 0) {
        // Determine whether to use DDB or straigtline amount
        let amount = remainingAmount * ddbRate;
        if (this.depreciationMethod == AnalysisDepreciationMethods.STRAIGHT_LINE || amount < straightlineAmount) {
          amount = straightlineAmount;
        }

        // Depreciation is an annual occurrece, but we need a monthly amount for cash flow purposes
        amount = Math.min(amount, remainingAmount);
        const monthlyAmount = round(amount / 12);

        for (let i = 0; i <= 11; i++) {
          // Make sure amount is not greater than remaining depreciable amount
          amount = (i == 11 && remainingAmount < straightlineAmount) ?
            remainingAmount : Math.min(monthlyAmount, remainingAmount);

          remainingAmount -= amount || remainingAmount;

          const p = new AnalysisDepreciationPeriod();
          schedule.push(p);

          p.amount = amount;
          p.date = new Date(this.firstPaymentDate);
          p.date.setMonth(p.date.getMonth() + schedule.length);
        }
      }
    }

    return schedule;
  }

  process() {
    this.setNonNullDefaults();

    // Mortgages

    let mortgageTerm = 0;
    const mortgages = [];

    this.mortgageTotal = 0;
    this.mortgagePaymentTotal = 0;
    this.downPaymentTotal = 0;
    for (let i = 0; i < this.mortgages.length; i++) {
      // Process source mortgage so summary values are available to caller.
      const m2 = this.mortgages[i];
      m2.process();

      // Make copies of mortgages, with first payment date set to analysis start date.
      const m = new Mortgage();
      m.copy(m2);
      m.firstPaymentDate = new Date(this.firstPaymentDate);
      m.process();

      // Saved copy of amortization schedule
      mortgages.push(m.periods);

      // Tally totals and find out the longest mortgage term
      this.mortgageTotal += m.amount;
      this.mortgagePaymentTotal += m.type == MortgageTypes.INTEREST_ONLY ? m.interestOnlyPaymentAmount : m.fixedInterestPaymentAmount;
      this.downPaymentTotal += m.downPaymentAmount;
      mortgageTerm = Math.max(mortgageTerm, m.getEffectiveTerm());
    }

    this.upFrontCashAmount = Math.max(0, this.purchaseAmount - this.mortgageTotal - this.downPaymentTotal);
    this.additionalExpenseTotal = this.accruedInterestAmount + this.defaultAmount + this.otherFeeAmount;


    // Closing costs
    this.closingCostNonPointTotal = 0;
    this.closingCostPointTotal = this.closingCostNonPointTotal;
    for (let i = 0; i < this.closingCosts.length; i++) {
      const c = this.closingCosts[i];
      if (!c.paidBySeller) {
        if (c.field != null && c.field.id == AnalysisClosingCosts.POINTS_ID) {
          this.closingCostPointTotal = c.amount; // mortgageTotal * c.getAmount() / 100;
        } else {
          this.closingCostNonPointTotal += c.amount;
        }
      }
    }

    this.closingCostTotal = this.closingCostPointTotal + this.closingCostNonPointTotal;


    // Improvements

    this.improvementTotal = 0;
    for (let i = 0; i < this.improvements.length; i++) this.improvementTotal += this.improvements[i].amount;

    this.upFrontExpenseTotal = this.upFrontCashAmount + this.downPaymentTotal + this.closingCostTotal + this.additionalExpenseTotal + this.improvementTotal;


    // Determine analysis term

    switch (this.termType) {
      case AnalysisTermTypes.ONE_YEAR:
        this.effectiveTerm = 12;
        break;

      case AnalysisTermTypes.THREE_YEAR:
        this.effectiveTerm = 36;
        break;

      case AnalysisTermTypes.FIVE_YEAR:
        this.effectiveTerm = 60;
        break;

      case AnalysisTermTypes.TEN_YEAR:
        this.effectiveTerm = 120;
        break;

      case AnalysisTermTypes.MORTGAGE:
        this.effectiveTerm = mortgageTerm;
        break;

      case AnalysisTermTypes.MANUAL:
        this.effectiveTerm = this.term;
        break;
    }

    // If period term is not explicitly set, go month-by-month if less than a year.
    const tmp = this.effectiveTerm < 12 ? 1 : 12;
    const periodTerm = this.periodTerm != null ? this.periodTerm : tmp;

    // Generate depreciation schedules
    const improvementDepreciation = this.getDepreciationSchedule(this.improvementTotal);
    const propertyDepreciation = this.getDepreciationSchedule(this.getEffectiveDepreciableAmount());

    // Generate all periods for the term selected
    this.periods = this.processPeriods(this.effectiveTerm, periodTerm, mortgages, improvementDepreciation, propertyDepreciation);

    // If this was an empty analysis (effectiveTerm == 0), set last period to empty period just to avoid null references.
    this.lastPeriod = this.periods.length ? this.periods[this.periods.length - 1] : new AnalysisPeriod();

    // If there is no period for one full/even year (which will be the case if running by month or less than a year), generate one.
    this.oneYearPeriod = (periodTerm == 12 && this.effectiveTerm >= 12 ?
      this.periods : this.processPeriods(12, 12, mortgages, improvementDepreciation, propertyDepreciation))[0];
  }

  processPeriods(term, periodTerm, mortgages, improvementDepreciation, propertyDepreciation) {
    let rentalUnitQuantity = 0;
    const periods = [];
    const rentalUnits = [];

    for (let month = 0; month < term;) {
      // Apply annual rates if this is the first period after a year has passed.
      // Note - this requires a period's start date to fall exactly on a new year.
      const firstPeriod = month == 0;
      const applyAnnualRates = !firstPeriod && month % 12 == 0;
      const p = new AnalysisPeriod();
      const prev = firstPeriod ? null : periods[periods.length - 1];

      periods.push(p);

      if (!firstPeriod) {
        // Copy cumulative amounts over from previous period. They'll be incremented with this period's values.
        p.seq = prev.seq + 1;
        p.saleAmount = prev.saleAmount;
        p.cashBeforeTaxCumulativeAmount = prev.cashBeforeTaxCumulativeAmount;
        p.netIncomeCumulativeTotal = prev.netIncomeCumulativeTotal;
        p.depreciationCumulativeTotal = prev.depreciationCumulativeTotal;
        p.interestCumulativeAmount = prev.interestCumulativeAmount;
        p.principalCumulativeAmount = prev.principalCumulativeAmount;
        p.operatingExpenseCumulativeAmount = prev.operatingExpenseCumulativeAmount;
        p.grossScheduledIncomeCumulativeAmount = prev.grossScheduledIncomeCumulativeAmount;
        p.grossOperatingIncomeCumulativeAmount = prev.grossOperatingIncomeCumulativeAmount;
        p.netOperatingIncomeCumulativeAmount = prev.netOperatingIncomeCumulativeAmount;
        p.monthlyExpenseCumulativeTotal = prev.monthlyExpenseCumulativeTotal;
        p.taxCumulativeTotal = prev.taxCumulativeTotal;
      }

      // Set current period term to standard term, or to remaining months if lesser
      p.term = Math.min(periodTerm, term - month);

      // Advance month and set date
      month += p.term;
      p.date = new Date(this.firstPaymentDate);
      p.date.setMonth(p.date.getMonth() + month);

      // Mortgage payments
      for (let i = 0; i < mortgages.length; i++) {
        const m = mortgages[i];

        const ap = new AnalysisMortgagePeriod();
        p.mortgages.push(ap);

        // Process all periods up to current date
        while (m.length) {
          if (m[0].date.getTime() >= p.date.getTime()) { break; }

          // Remove mortgage period and add to current period totals.
          const mp = m.splice(0, 1)[0];
          ap.interestAmount += mp.interest;
          ap.balance = mp.balance;

          p.interestAmount += mp.interest;
          p.principalAmount += mp.principal;
          p.mortgageBalance = mp.balance;
        }
      }

      p.interestCumulativeAmount += p.interestAmount;
      p.principalCumulativeAmount += p.principalAmount;


      // Income

      // Get multipliers for annual amounts, in case this period represents a term shorter than a full year.
      const coef = p.term / 12;

      const units = firstPeriod ? this.rentalUnits : rentalUnits;
      for (let i = 0; i < units.length; i++) {
        const u = units[i];

        if (firstPeriod) {
          u.seq = i;
          u.pricePerSquareFoot = u.squareFeet == 0 ? 0 : round(u.amount / u.squareFeet);

          // Keep copy of rent units to track annual rent increases
          const cu = new AnalysisRentalUnit();
          cu.copy(u);
          rentalUnits.push(cu);

          rentalUnitQuantity += u.quantity;
        } else if (applyAnnualRates) {
          let amount = u.amount;
          if (u.rentIncreaseRate != null) amount += amount * u.rentIncreaseRate;
          else if (p.incomeIncreaseType === AnalysisRateIncreaseTypes.DOLLAR_AMOUNT) amount += this.incomeIncreaseAmount / 12;
          else amount += amount * this.incomeIncreaseRate;

          u.amount = round(amount);
        }

        u.netTotal = u.quantity * u.amount * u.occupancyRate;

        const grossRentAmount = p.term * u.quantity * u.amount;
        p.grossRentAmount += grossRentAmount;
        p.rentAmount += round(grossRentAmount * u.occupancyRate);
      }

      p.leasingCommissionAmount = round(p.rentAmount * this.leasingCommissionRate);
      p.propertyManagementAmount = round(p.rentAmount * this.propertyManagementRate);

      const incomes = firstPeriod ? this.incomes : prev.incomes;
      for (let i = 0; i < incomes.length; i++) {
        const c = incomes[i];
        if (c.amount != 0) {
          const n = new AnalysisIncome(c.field, c.amount);

          if (applyAnnualRates) {
            n.amount += (
              this.incomeIncreaseType == AnalysisRateIncreaseTypes.DOLLAR_AMOUNT ?
                this.incomeIncreaseAmount : round(n.amount * this.incomeIncreaseRate)
            );
          }

          p.additionalIncomeTotal += n.amount * coef;
          p.incomes.push(n);
        }
      }

      p.vacancyLossAmount = p.grossRentAmount - p.rentAmount;
      p.grossIncomeAmount = p.rentAmount + p.additionalIncomeTotal;
      p.incomeTotal = p.grossIncomeAmount - p.leasingCommissionAmount - p.propertyManagementAmount;
      p.grossScheduledIncomeAmount = p.grossRentAmount + p.additionalIncomeTotal;
      p.grossScheduledIncomeCumulativeAmount += p.grossScheduledIncomeAmount;
      p.grossOperatingIncomeAmount = p.grossScheduledIncomeAmount - p.vacancyLossAmount - p.leasingCommissionAmount;
      p.grossOperatingIncomeCumulativeAmount += p.grossOperatingIncomeAmount;

      // Expenses

      const expenses = firstPeriod ? this.expenses : prev.expenses;
      for (let i = 0; i < expenses.length; i++) {
        const e = expenses[i];
        if (e.amount != 0) {
          const n = new AnalysisExpense(e.field, e.amount);

          // If this is the first period
          if (applyAnnualRates) {
            n.amount += (
              this.expenseIncreaseType == AnalysisRateIncreaseTypes.DOLLAR_AMOUNT ?
                this.expenseIncreaseAmount : round(n.amount * this.expenseIncreaseRate)
            );
          }

          p.expenseUnitTotal += round(n.amount * coef);
          p.expenses.push(n);
        }
      }

      p.expenseTotal = p.expenseUnitTotal * (this.expensePerRentalUnit ? rentalUnitQuantity : 1);

      p.operatingExpenseAmount = p.expenseTotal + p.propertyManagementAmount;
      p.operatingExpenseCumulativeAmount += p.operatingExpenseAmount;
      p.netOperatingIncomeAmount = p.grossOperatingIncomeAmount - p.operatingExpenseAmount;
      p.netOperatingIncomeCumulativeAmount += p.netOperatingIncomeAmount;

      p.monthlyExpenseTotal = p.operatingExpenseAmount + p.interestAmount + p.principalAmount;
      p.monthlyExpenseCumulativeTotal += p.monthlyExpenseTotal;

      // For Analysis summary tab. Just monthlyExpenseTotal less management expenses, since that's deducted from the monthly income there already.
      p.monthlyExpenseTotal2 = p.expenseTotal + p.interestAmount + p.principalAmount;

      // Only add improvements to cash flow for first period
      p.cashBeforeTaxAmount = p.grossOperatingIncomeAmount - p.monthlyExpenseTotal - (
        firstPeriod ? this.improvementTotal : 0
      );
      p.cashBeforeTaxCumulativeAmount += p.cashBeforeTaxAmount;

      p.cashOnCashReturnRate = p.upFrontExpenseTotal == 0 ?
        0 : p.cashBeforeTaxCumulativeAmount / p.upFrontExpenseTotal;
      p.capRate = this.purchaseAmount == 0 ? 0 : p.netOperatingIncomeAmount / this.purchaseAmount;


      // Sale

      if (firstPeriod) {
        // Determine initial sale amount

        if (this.propertyValueIncreaseType != AnalysisValueIncreaseTypes.DOLLAR_AMOUNT) {
          switch (this.propertyValueBasis) {
            case AnalysisValueBases.DOLLAR_AMOUNT:
              p.saleAmount = this.propertyValue;
              break;

            case AnalysisValueBases.PURCHASE_PRICE:
              p.saleAmount = this.purchaseAmount;
              break;

            case AnalysisValueBases.MARKET_VALUE:
              p.saleAmount = this.marketValue;
              break;
          }
        } else {
          p.saleAmount = this.saleAmount;
        }
      } else if (applyAnnualRates) {
        switch (this.propertyValueIncreaseType) {
          case AnalysisValueIncreaseTypes.MARKET_VALUE_INCREASE_RATE:
            p.saleAmount += round(p.saleAmount * this.marketValueIncreaseRate);
            break;

          case AnalysisValueIncreaseTypes.INFLATION_RATE:
            p.saleAmount += round(p.saleAmount * this.inflationRate);
            break;

          case AnalysisValueIncreaseTypes.CAP_RATE:
            if (this.capRate != 0) { p.saleAmount = round((p.incomeTotal - p.expenseTotal) / this.capRate); }
            break;

          case AnalysisValueIncreaseTypes.DOLLAR_AMOUNT:
            // Sale price stays fixed
            break;
        }
      }

      p.saleCostAmount = this.saleCostIncreaseType == AnalysisRateIncreaseTypes.DOLLAR_AMOUNT ?
        this.saleCostAmount : (p.saleAmount * this.saleCostRate);

      // Depreciation
      const depreciations = [improvementDepreciation, propertyDepreciation];
      for (let i = 0; i < depreciations.length; i++) {
        const d = depreciations[i];

        while (d.length) {
          if (d[0].date.getTime() > p.date.getTime()) {
            break;
          }

          const dp = d.splice(0, 1)[0];
          if (d == improvementDepreciation) {
            p.improvementDepreciationAmount += dp.amount;
          } else {
            p.propertyDepreciationAmount += dp.amount;
          }
        }
      }

      p.depreciationTotal = p.propertyDepreciationAmount + p.improvementDepreciationAmount;
      p.depreciationCumulativeTotal += p.depreciationTotal;

      // Cash Flow

      p.taxDeductionTotal = p.interestAmount + p.depreciationTotal;
      p.taxableIncomeAmount = p.netOperatingIncomeAmount - p.taxDeductionTotal;
      p.stateTaxAmount = round(p.taxableIncomeAmount * this.stateTaxRate);
      p.federalTaxAmount = round((p.taxableIncomeAmount - p.stateTaxAmount) * this.federalTaxRate);
      p.taxTotal = p.stateTaxAmount + p.federalTaxAmount;
      p.taxCumulativeTotal += p.taxTotal;
      p.netIncomeTotal = p.cashBeforeTaxAmount - p.taxTotal;
      p.netIncomeCumulativeTotal += p.netIncomeTotal;

      // Sale

      p.adjustedTaxBasisAmount = (this.purchaseAmount + this.improvementTotal) - p.depreciationCumulativeTotal;
      p.exchangeExpenseAmount = this.closingCostTotal + this.accruedInterestAmount +
        this.defaultAmount + this.otherFeeAmount + p.saleCostAmount;
      p.saleExpenseAmount = this.upFrontCashAmount + this.improvementTotal + this.downPaymentTotal +
        p.exchangeExpenseAmount + p.mortgageBalance;
      p.depreciationRecoveryTaxAmount = round(this.federalTaxRate * p.depreciationCumulativeTotal);
      p.taxableGainAmount = p.saleAmount - p.adjustedTaxBasisAmount - p.exchangeExpenseAmount;
      p.capitalGainTaxAmount = round(this.capitalGainTaxRate * (p.taxableGainAmount - p.depreciationCumulativeTotal));

      p.saleTaxTotal = p.depreciationRecoveryTaxAmount + p.capitalGainTaxAmount;
      p.saleNetGainAmount = p.saleAmount - p.saleExpenseAmount - p.saleTaxTotal;

      p.saleNetIncomeAmount = p.netOperatingIncomeCumulativeAmount - p.principalCumulativeAmount -
        p.interestCumulativeAmount - p.taxCumulativeTotal;
      p.saleNetProfitAmount = p.saleNetGainAmount + p.saleNetIncomeAmount;
    }

    return periods;
  }
}

/* eslint-enable default-case */
/* eslint-enable eqeqeq */

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *  Don't update files without changing analisys.source
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
