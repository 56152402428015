import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withGoogleMap, GoogleMap, Marker, Polyline, Polygon, InfoWindow } from 'react-google-maps';
import { Map as Map2, fromJS, List } from 'immutable';
import { compose, withProps } from 'recompose';
import config from 'config';
import { MAP_NO_METRIC, searchParcel } from 'data/search';
import * as onKeyPress from 'utils/DOM/onKeyPress';
import SVG from 'components/base/SVG';
import Button from 'components/base/Button';
import withProperty from 'app/components/withProperty';
import PropertyInfo from './PropertyInfo';
import Analytics from './Analytics';
import css from './style.scss';
import { withRouter } from 'react-router-dom';


const { constants: { API: { BASE_URL } } } = config;

const MIN_PARCEL_ZOOM = 19;

const emptyMap = Map2();

const drawProps = { strokeColor: 'royalblue', strokeWeight: 1, strokeOpacity: 0.8, clickable: false, fillColor: 'limegreen', fillOpacity: 0.3 };

const parseShape = def => def.split('/').map(p => ({ lat: Number(p.substr(0, p.indexOf(','))), lng: Number(p.substr(p.indexOf(',') + 1)) }));

const tileXYToQuadKey = (x, y, z) => {
  let quadKey = '';
  for (let i = z; i > 0; i--) {
    let digit = 0;

    const mask = 1 << (i - 1);
    if (x & mask) digit++;
    if (y & mask) digit = digit + 2;

    quadKey = `${quadKey}${digit}`;
  }

  return quadKey;
};


class Map extends PureComponent {
  constructor(props) {
    super(props);

    this.onRef = this.onRef.bind(this);
    this.onLocation = this.onLocation.bind(this);
    this.onLocationError = this.onLocationError.bind(this);
    this.zoomToFit = this.zoomToFit.bind(this);

    this.handleClick = this.handleClick.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.handleDrawBegin = this.handleDrawBegin.bind(this);
    this.handleDrawCancel = this.handleDrawCancel.bind(this);
    this.handleDrawComplete = this.handleDrawComplete.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMetricChange = this.handleMetricChange.bind(this);
    this.handleInfoClose = this.handleInfoClose.bind(this);
    this.handleSaveProperty = this.handleSaveProperty.bind(this);

    this.map = null;
    this.innerMap = null;

    this.state = {
      bounds: '',
      drawing: false,
      drawCoords: [],
      cursorCoord: null,
      infoProperty: null,
      parcelProperty: null,
      metric: MAP_NO_METRIC,
    };
  }

  componentDidMount() {
    if (navigator.geolocation) navigator.geolocation.getCurrentPosition(this.onLocation, this.onLocationError, { timeout: 3000 });

    onKeyPress.addListener('Escape', this.handleDrawCancel);
    onKeyPress.addListener('Enter', this.handleDrawComplete);
  }

  componentDidUpdate(prevProps) {
    const { properties, activeResult } = this.props;
    const { infoProperty } = this.state;

    // Clear shape if search has changed
    if (prevProps.shapeDefinition && !this.props.shapeDefinition) this.setState({ drawCoords: [] });
    else if (this.props.shapeDefinition && prevProps.shapeDefinition !== this.props.shapeDefinition) this.setState({ drawCoords: parseShape(this.props.shapeDefinition) });

    let infoProp = null;
    (properties || []).forEach((p) => {
      if (activeResult && activeResult !== prevProps.activeResult) {
        if (activeResult === p.get('id')) infoProp = p;
      } else if (infoProperty && infoProperty.get('id') === p.get('id')) infoProp = p;
    });

    if (infoProp !== infoProperty) this.setInfoProperty(infoProp);

    this.zoomToFit();
  }

  componentWillUnmount() {
    onKeyPress.removeListener('Escape', this.handleDrawCancel);
    onKeyPress.removeListener('Enter', this.handleDrawComplete);
  }

  onRef(ref) {
    this.map = ref;

    if (this.map) {
      this.innerMap = this.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;  // Lol

      this.innerMap.overlayMapTypes.push(new window.google.maps.ImageMapType({
        getTileUrl: (coord, zoom) => {
          return (zoom < MIN_PARCEL_ZOOM ? '' : `${BASE_URL}/resource/auth/ps4/map/parcels/${coord.x}/${coord.y}/${zoom}/tile`);
        },
      }));

      // Recent change by Google prevents control from zooming / panning if attempted immediately.
      setTimeout(this.zoomToFit, 1);
    }
  }

  onLocation(location) {
    console.log(this, location);
  }

  onLocationError(err) {
    console.log(this, err);
  }

  setInfoProperty(infoProperty = null) {
    this.setState({ infoProperty, parcelProperty: null });
  }

  handleClick(ev) {
    const { searchParcel, parcelClickEnabled } = this.props;
    const { drawing, drawCoords } = this.state;
    const ll = ev.latLng;

    if (drawing) this.setState({ drawCoords: [...drawCoords, ev.latLng] });
    else if (parcelClickEnabled && this.map.getZoom() >= MIN_PARCEL_ZOOM) {
      this.setState({ infoProperty: null, parcelProperty: null }, () => {
        searchParcel(ll.lat(), ll.lng(), ({ response }) => {
          let parcelProperty;
          if (response && response.id) {
            const { latitude, longitude, parcelBoundaryDefinition } = response;
            parcelProperty = fromJS(response).set('position', { lat: latitude, lng: longitude }).set('path', parseShape(parcelBoundaryDefinition));
          }

          this.setState({ parcelProperty });
        });
      });
    }
  }

  handleRightClick() {
    if (this.state.drawing) this.handleDrawComplete();
  }

  handleDrawBegin() {
    this.setState({ drawing: true, drawCoords: [], cursorCoord: null })
  }

  handleDrawCancel() {
    if (this.state.drawing) this.setState({ drawing: false, drawCoords: [] })
  }

  handleDrawComplete() {
    const { drawing, drawCoords } = this.state;
    const { onDraw } = this.props;

    if (drawing) {
      if (drawCoords.length < 3) this.handleDrawCancel();
      else {
        this.setState({ drawing: false, cursorCoord: null });

        onDraw(drawCoords.map(c => `${c.lat()},${c.lng()}`));
      }
    }
  }

  handleMouseMove(ev) {
    const { drawing, drawCoords } = this.state;

    if (drawing && drawCoords.length) this.setState({ cursorCoord: ev.latLng });
  }

  handleMetricChange(metric) {
    const overlays = this.innerMap.overlayMapTypes;
    if (overlays.length > 1) overlays.removeAt(1);

    if (metric !== MAP_NO_METRIC) overlays.push(new window.google.maps.ImageMapType({ getTileUrl: ({ x, y }, z) => `${BASE_URL}/resource/auth/ps4/map/tiles/${tileXYToQuadKey(x, y, z)}/statistics/${metric}` }));

    this.setState({ metric });
  }

  handleInfoClose() {
    this.setState({ infoProperty: null, parcelProperty: null });
  }

  zoomToFit() {
    const { properties, initialMapType } = this.props;

    const props = (properties || List()).filter(p => p.get('latitude'));

    if (!props.size) this.setState({ bounds: null });
    else {
      const bounds = new window.google.maps.LatLngBounds();
      props.forEach((p) => {
        bounds.extend({ lat: p.get('latitude'), lng: p.get('longitude') });
      });

      const boundsStr = bounds.toString();
      if (boundsStr !== this.state.bounds) {
        this.setState({ bounds: boundsStr }, () => {
          this.map.fitBounds(bounds);
          setTimeout(() => {
            if (initialMapType === 'roadmap' && this.map.getZoom() > 19) this.innerMap.setZoom(19);
          }, 10);
        });
      }
    }
  }

  handleSaveProperty = (propertyData) => {
    const { saveProperty } = this.props;
    saveProperty(null, propertyData);
  };

  render() {
    const { properties, onDraw, infoEnabled, analyticsEnabled, initialZoom, initialMapType, saveProperty, propertySaved, loading, property, getProperty } = this.props;
    const { drawing, drawCoords, cursorCoord, metric, infoProperty, parcelProperty } = this.state;

    const { ControlPosition } = window.google.maps;

    let cursorPolyline;
    let drawPolygon;
    if (drawCoords.length) {
      if (drawing && cursorCoord) {
        cursorPolyline = [drawCoords[drawCoords.length - 1], cursorCoord];
        if (drawCoords.length > 1) cursorPolyline.push(drawCoords[0]);
      }

      if ((drawCoords.length + (cursorCoord ? 1 : 0) > 2)) {
        drawPolygon = [...drawCoords];
        if (drawPolygon.length < 3) drawPolygon.push(cursorCoord);
      }
    }

    const ul = window.UserLocation;

    return (
      <GoogleMap
        defaultZoom={initialZoom || ul.zoom}
        defaultMapTypeId={initialMapType}
        defaultCenter={{ lat: ul.latitude, lng: ul.longitude }}
        options={{
          draggableCursor: drawing ? 'crosshair' : null,
          mapTypeControlOptions: {
            position: ControlPosition.LEFT_BOTTOM,
          },
          // fullScreenControl: false,
          // fullScreenControlOptions: {
          //   // style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          //   position: ControlPosition.RIGHT_BOTTOM,
          // },
        }}
        onClick={this.handleClick}
        onRightClick={this.handleRightClick}
        onMouseMove={this.handleMouseMove}
        ref={this.onRef}
      >
        {(properties || emptyMap).filter(p => p.get('latitude')).map((p, i) => {
          const text = String(p.get('seq') || p.get('resultIndex') || ' ');

          let size;
          if (text.length < 4) size = 14;
          else if (text.length === 4) size = 12;
          else size = 10;

          const info = infoEnabled && infoProperty && infoProperty.get('id') === p.get('id');

          let fillColor = p.get('pinColor');
          if (!fillColor) fillColor = info ? 'darkblue' : 'red';

          return (
            <Marker
              key={p.get('id')}
              position={{ lat: p.get('latitude'), lng: p.get('longitude') }}
              label={{ text, color: 'white', fontSize: `${size}px` }}
              onClick={() => this.setInfoProperty(p)}
              icon={{ path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z', fillColor, strokeColor: 'white', fillOpacity: 1, scale: 1.5, labelOrigin: { x: 0, y: -29 } }}
              zIndex={100 + (properties.size - i) + (info ? 10000000 : 0)}
            >
              {!info ? null : (
                <InfoWindow onCloseClick={this.handleInfoClose}>
                  <PropertyInfo info={infoProperty} history={this.props.history} handleSaveProperty={this.handleSaveProperty} isSaved={propertySaved} loading={loading} property={property} getProperty={getProperty} />
                </InfoWindow>
              )}
            </Marker>
          );
        })}
        {!cursorPolyline ? null : <Polyline path={cursorPolyline} options={{ ...drawProps }} />}
        {!drawPolygon ? null : <Polygon path={drawPolygon} options={drawProps} />}
        {!analyticsEnabled ? null : <Analytics metric={metric} onChange={this.handleMetricChange} />}

        {!parcelProperty ? null : <Polygon path={parcelProperty.get('path')} options={drawProps} />}
        {!parcelProperty ? null : (
          <Marker
            key={'parcel'}
            position={parcelProperty.get('position')}
          // onClick={() => this.setInfoProperty(p)}
          >
            <InfoWindow onCloseClick={this.handleInfoClose}>
              <PropertyInfo info={parcelProperty} history={this.props.history} handleSaveProperty={this.handleSaveProperty} isSaved={propertySaved} loading={loading} property={property} getProperty={getProperty} />
            </InfoWindow>
          </Marker>
        )}
        {!onDraw ? null : (
          <div>
            <button className={classNames(css.button, css.drawButton, { [css.active]: drawing })} onClick={drawing ? this.handleDrawCancel : this.handleDrawBegin}>
              <SVG icon="iconMapDraw" className={css.iconMapDraw} />
            </button>
            {!drawing ? null : (
              <div className={css.draw}>
                <div className={css.drawInner}>
                  <div>
                    <div className={css.text}>Draw a shape on the map in the area you would like to search.</div>
                    <div className={css.text}>Right Click or press Enter to Search.</div>
                  </div>
                  <div>
                    <Button kind="link-default" size="middle" onClick={this.handleDrawCancel}>Cancel</Button>
                    <Button kind="border-grey" size="middle" onClick={this.handleDrawComplete}>Search</Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </GoogleMap>
    );
  }
}


Map.propTypes = {
  onDraw: PropTypes.func,
  infoEnabled: PropTypes.bool,
  analyticsEnabled: PropTypes.bool,
  parcelClickEnabled: PropTypes.bool,
  initialZoom: PropTypes.number,
  initialMapType: PropTypes.string,
};

Map.defaultProps = {
  infoEnabled: false,
  analyticsEnabled: false,
  parcelClickEnabled: false,
  initialMapType: 'hybrid',
};

const MapDetails = withProperty((Map), state => ({}), {
});

export default withRouter(connect(state => ({
  shapeDefinition: state.getIn(['search', 'location', 'shapeDefinition'], null),
}), {
  searchParcel,
})(compose(withProps({
  // googleMapURL: 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCYDyZhWkrliwoG-8Bvx6cCG8sNacAWsjM',
  loadingElement: <div style={{ height: '100%' }} />,
  containerElement: <div style={{ height: '100%', width: '100%' }} />,
  mapElement: <div style={{ height: '100%' }} />,
}),
  withGoogleMap,
)(MapDetails)));
