import React from 'react';
import PropTypes from 'prop-types';

import css from './style.scss';

const GroupInfo = (props) => {
    const { label, children } = props;
    return (
      <div className={css.info}>
        <div className={css.label}>{label}</div>
        <div className={css.value}>{children}</div>
      </div>
    );
}

GroupInfo.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default GroupInfo;
