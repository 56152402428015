import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Dropdown, { dropdownable } from 'components/base/Dropdown';
import Button from 'components/base/Button';
import { SolidButton } from 'components/Button';
import Radio from 'components/base/Radio';
import { savePropertyGroupOptions, selectGroupContext } from 'data/property';
import { getOptionsWithDefault } from 'utils/normalizers/dropdown';
import { MAIN_CONTEXT } from 'reducers';

import css from './style.scss';


const AddOptions = [
  { label: 'Ignore', value: '' },
  { label: 'Add to this list', value: 'AUTO' },
  { label: 'Add to another list', value: 'MOVE' },
  { label: 'I\'ll do it myself', value: 'MANUAL' },
];

const RemoveOptions = [
  { label: 'Ignore', value: '' },
  { label: 'Remove from this list', value: 'AUTO' },
  { label: 'Move to another list', value: 'MOVE' },
  { label: 'I\'ll do it myself', value: 'MANUAL' },
];

const NotificationIntervals = [
  { label: 'Never', value: '' },
  { label: 'Immediately', value: 'IMMEDIATE' },
  { label: 'Daily', value: 'DAILY' },
  { label: 'Weekly', value: 'WEEKLY' },
];

const getState = (props) => {
  const { id, addAction = '', addNotificationInterval = '', addGroupId = '', removeAction = '', removeNotificationInterval = '', removeGroupId = '' } = props.context.get('info').toJS();

  return { id, addAction, addNotificationInterval, addGroupId, removeAction, removeNotificationInterval, removeGroupId };
};

class PropertyGroupOptions extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = getState(props);
  }

  componentWillReceiveProps(props) {
    if (props.context.get('id') !== this.state.id || (props.isOpen && !this.props.isOpen)) this.setState(getState(props));
  }

  handleClick(i) {
    const { closeDropdown, onSelect } = this.props;
    closeDropdown();
    (i.onSelect || onSelect)(i);
  }

  handleSave() {
    const { savePropertyGroupOptions, closeDropdown } = this.props;
    const { id, addAction, addNotificationInterval, addGroupId, removeAction, removeNotificationInterval, removeGroupId } = this.state;

    savePropertyGroupOptions({
      id,
      addAction: addAction === '' ? null : addAction,
      addNotificationInterval: addNotificationInterval === '' || addAction === '' ? null : addNotificationInterval,
      addGroupId: addGroupId === '' || addAction === '' ? null : addGroupId,
      removeAction: removeAction === '' ? null : removeAction,
      removeNotificationInterval: removeNotificationInterval === '' || removeAction === '' ? null : removeNotificationInterval,
      removeGroupId: removeGroupId === '' || removeAction === '' ? null : removeGroupId,
    }, closeDropdown);
  }

  handleChange(ev) {
    const { name, value } = ev.target || ev;
    this.setState({ [name]: value });
  }

  render() {
    const { isOpen, onClick, closeDropdown, loading, lists, onRef } = this.props;
    const { addAction, addNotificationInterval, addGroupId, removeAction, removeNotificationInterval, removeGroupId } = this.state;
    const btnProps = { kind: Button.kind.grayGhost, className: css.btn, isLoading: loading };
    const ddProps = { onChange: this.handleChange };

    return (
      <div className={classNames(css.options, { [css.open]: isOpen })} onClick={onClick} name="dropdown-btn">
        <SolidButton loading={loading} text="Automate List" icon="iconSpeaker" />
        <div className={css.dropdown} ref={onRef}>
          <div className={css.header}>Automate List</div>
          <div className={css.section}>
            <div className={css.title}>New Properties</div>
            <div className={css.description}>When new properties that match your exact search criteria come available, do the following:</div>
            <div className={css.content}>
              <div className={css.item}>
                <Dropdown {...ddProps} name="addAction" value={addAction} options={AddOptions} />
              </div>
              {addAction !== 'MOVE' ? null : (
                <div className={css.item}>
                  <div className={css.label}>Target List:</div>
                  <Dropdown {...ddProps} name="addGroupId" value={addGroupId} options={getOptionsWithDefault(lists, 'Select List')} />
                </div>
              )}
            </div>
            {addAction === '' ? null : (
              <div className={css.subSection}>
                <div className={css.description}>Email me when new properties become available that match my search criteria for this list:</div>
                <div className={css.content}>
                  <div className={css.item}>
                    {NotificationIntervals.map(({ value, label }) => (
                      <Radio key={value} name="addNotificationInterval" value={value} label={label} checked={addNotificationInterval === value} onChange={this.handleChange} />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={css.section}>
            <div className={css.title}>Property Changes</div>
            <div className={css.description}>When new properties change from your exact search criteria in this list, do the following:</div>
            <div className={css.content}>
              <div className={css.item}>
                <Dropdown {...ddProps} name="removeAction" value={removeAction} options={RemoveOptions} />
              </div>
              {removeAction !== 'MOVE' ? null : (
                <div className={css.item}>
                  <div className={css.label}>Target List:</div>
                  <Dropdown {...ddProps} name="removeGroupId" value={removeGroupId} options={getOptionsWithDefault(lists, 'Select List')} />
                </div>
              )}
            </div>
            {removeAction === '' ? null : (
              <div className={css.subSection}>
                <div className={css.description}>Alert me when new when properties no longer match my search criteria for this list:</div>
                <div className={css.content}>
                  <div className={css.item}>
                    {NotificationIntervals.map(({ value, label }) => (
                      <Radio key={value} name="removeNotificationInterval" value={value} label={label} checked={removeNotificationInterval === value} onChange={this.handleChange} />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={css.buttons}>
            <Button {...btnProps} onClick={closeDropdown}>Close</Button>
            <Button {...btnProps} kind={Button.kind.blue} onClick={this.handleSave}>Save</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default dropdownable(connect(state => ({
  context: selectGroupContext(MAIN_CONTEXT)(state),
}), {
  savePropertyGroupOptions,
})(PropertyGroupOptions));
