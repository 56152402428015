import API from './API';
import fileFormats from './fileFormats';
import FORMAT from './FORMAT';
import STATE from './STATE';
import wysiwyg from './wysiwyg';
import onlineAd from './onlineAd';


export default {
  API,
  fileFormats,
  FORMAT,
  STATE,
  wysiwyg,
  onlineAd,
};
