import React from 'react';
import { pure } from 'recompose';

import config from 'config';
import { froalaDefaultConfig, froalaConfigWithoutImages } from 'config/constants/wysiwyg';

import 'froala-editor/js/froala_editor.pkgd.min';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'font-awesome/css/font-awesome.css'; // it need for FroalaEditor - repalced through cdn
import FroalaEditor from 'react-froala-wysiwyg';
//import 'froala-editor/js/third_party/image_aviary.min'; // Import this plugin last. Otherwise, this causes an error
import 'froala-editor/js/plugins/image.min.js'; 
import 'froala-editor/js/third_party/image_tui.min.js'; 

import './style.scss';


const { constants } = config;
const { API } = constants;

const FroalaWysisyg = ({
  tag = 'textarea',
  config,
  model = '',
  onModelChange,
  withoutImages = false,
}) => {
  const configuration = config || froalaDefaultConfig;
  configuration.imageUploadURL = `${API.EXTRA_URL.SARITASA}/api/v1/images`;

  return (
    <FroalaEditor
      tag={tag}
      config={withoutImages ? froalaConfigWithoutImages : configuration}
      model={model}
      onModelChange={onModelChange}
    />
  );
};

export default pure(FroalaWysisyg);
