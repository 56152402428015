/** @flow */
import React, { PureComponent } from 'react';
import FormControlWraper from 'components/base/FormControlWraper';
import PropTypes from 'prop-types';

import CustomInput from './CustomInput';
import css from './style.scss';


class YearMonthInput extends PureComponent {
  /* :: state: Object */

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.setValue = this.setValue.bind(this);

    this.state = { years: null, months: null };
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(newProps) {
    const { name, value } = newProps;
    this.setValue((typeof value === 'object' ? value[name] : value) || 0);
  }

  /* :: setValue: Function */
  setValue(value) {
    this.setState({ years: Math.floor(value / 12), months: value % 12 });
  }

  /* :: handleChange: Function */
  handleChange(event) {
    const { name, value } = event.target || event;
    this.setState({ [name]: Number(value) || 0 }, () => {
      const { name, onChange, value: { currentValue } } = this.props;
      const { years, months } = this.state;
      const value = Math.min((years * 12) + months, (12 * 99) + 11);
      this.setValue(value);

      // Trigger onChange if value has changed.
      if (currentValue !== value) onChange({ name, value });
    });
  }

  render() {
    const { handleChange, state, props } = this;
    const { months, years } = state;
    return (
      <FormControlWraper id={String(Math.random())} label={props.label}>
        <div className={css.row}>
          <div className={css.col_2}>
            <CustomInput name="years" value={years} onChange={handleChange} maxWholeDigits={2} />
            <label className={css.subLabel}>Years</label>
          </div>
          <div className={css.col_2}>
            <CustomInput name="months" value={months} onChange={handleChange} maxWholeDigits={3} />
            <label className={css.subLabel}>Months</label>
          </div>
        </div>
      </FormControlWraper>
    );
  }
}


YearMonthInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
};

YearMonthInput.defaultProps = {
};

export default YearMonthInput;
