import React, { Component } from 'react';
import Button from '../Button/Button';
import PropTypes from 'prop-types';


class DropdownToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdown: false,
    };
    this.dropdownToggleRef = null;
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    if (this.dropdownToggleRef && this.dropdownToggleRef.current) {
      // Attach the onBlur event listener to the div element.
      this.dropdownToggleRef.current.addEventListener('blur', this.handleBlur);
    }
  }

  componentWillUnmount() {
    if (this.dropdownToggleRef && this.dropdownToggleRef.current) {
      // Remove the onBlur event listener when the component is unmounted.
      this.dropdownToggleRef.current.removeEventListener('blur', this.handleBlur);
    }
  }

  handleBlur(e) {
    // Check if the related target (where focus went to) is not within the div.
    if (this.dropdownToggleRef && !this.dropdownToggleRef.contains(e.relatedTarget)) {
      this.setState({ openDropdown: false });
    }
  }

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      <div onBlur={this.handleBlur} ref={ref => (this.dropdownToggleRef = ref)} tabIndex={0}>
        <Button onClick={() => this.setState({ openDropdown: !this.state.openDropdown })}>
          {this.props.buttonLabel}
        </Button>
        {this.state.openDropdown && this.props.children}
      </div>
    );
  }
}

DropdownToggle.propTypes = {
  children: PropTypes.node,
  buttonLabel: PropTypes.node,
};


export default DropdownToggle;
