import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import css from './style.scss';


class CampaignNameCellRenderer extends Component {
  render() {
    const { value, data: { id, type, marketingCampaignId } = {} } = this.props;

    return !id ? null : <Link to={`/campaign/${marketingCampaignId}/${type.toLowerCase()}/${id}`} className={css.link} target="detail">{value}</Link>;
  }
}

export default CampaignNameCellRenderer;
