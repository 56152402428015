import React, { PureComponent } from 'react';
import classNames from 'classnames';
import ListKeyValue from 'components/ListKeyValue';
import numberToPrice from 'utils/currency/numberToPrice';
import css from './style.scss';


const Fields = [
  {
    label: 'Purchase Price',
    key: 'purchaseAmount',
    path: ['purchaseAmount'],
    format: value => numberToPrice(value, ''),
    breadcrumbIndex: 0,
  },
  {
    label: 'Estimated Value',
    key: 'estimatedValue',
    path: ['marketValue'],
    format: value => numberToPrice(value, ''),
    breadcrumbIndex: 0,
  },
  {
    label: 'Improvements',
    key: 'improvementTotal',
    path: ['improvementTotal'],
    format: value => numberToPrice(value, ''),
    breadcrumbIndex: 0,
  },
  {
    label: 'Mortgage Amounts',
    key: 'mortgageTotal',
    path: ['mortgageTotal'],
    format: value => numberToPrice(value, ''),
    breadcrumbIndex: 1,
  },
  {
    label: 'Monthly Payments',
    key: 'mortgagePaymentTotal',
    path: ['mortgagePaymentTotal'],
    format: value => numberToPrice(value, ''),
    breadcrumbIndex: 1,
  },
  {
    label: 'Gross Monthly Income',
    key: 'grossIncomeAmount',
    path: ['oneYearPeriod', 'grossIncomeAmount'],
    format: value => numberToPrice(value / 12, ''),
    breadcrumbIndex: 2,
  },
  {
    label: 'Monthly Expenses',
    key: 'expenseTotal',
    path: ['oneYearPeriod', 'expenseTotal'],
    format: value => numberToPrice(value / 12, ''),
    breadcrumbIndex: 3,
  },
  {
    label: 'Sale Price',
    key: 'saleAmount',
    path: ['lastPeriod', 'saleAmount'],
    format: value => numberToPrice(value, ''),
    breadcrumbIndex: 4,
  },
];

class TabInformation extends PureComponent {
  render() {
    const { analysisParams, activeStepIndex } = this.props;

    return (
      <div>
        <div className={css.tabWrapper}>
          <div className="listKeyValue">
            <ListKeyValue>{Fields.filter(field => field.breadcrumbIndex <= activeStepIndex).map(field =>
              (<div key={field.key} className="row">
                <div className={classNames('key', css.key)}>{field.label}</div>
                <div className={classNames('value', css.value)}>{field.format(field.path.reduce((value, key) => value && value[key], analysisParams))}</div>
              </div>),
            )}</ListKeyValue>
          </div>
        </div>
      </div>
    );
  }
}

export default TabInformation;
