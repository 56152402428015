import React, { PureComponent } from 'react';
import classNames from 'classnames';

import Button, { ButtonUpload } from 'components/base/Button';
import SVG from 'components/base/SVG';
import FileReceiver from 'components/base/FileReceiver';
import { imageFormatList } from 'config/constants/fileFormats';
import { capitalize } from 'utils/string';
import { defaultGraphicElement, ElementTypes } from 'data/campaigns/constants';
import GraphicViewer from 'app/components/GraphicViewer';

import 'rc-slider/assets/index.css';
// import 'rc-tooltip/assets/bootstrap.css';
import css from './style.scss';


class PostcardSide extends PureComponent {
  render() {
    const { name, onChange, onEditColorClick, onGraphicClick, onCopyClick, onClearClick, immutable, loading, getOverlays, placeholders, campaign: { [name]: graphic } } = this.props;
    const { size, color, elements = [] } = graphic;
    const { widthInches, heightInches, width, height, dpi } = size;
    const bleed = dpi / 4;
    const displayWidth = 490;
    const displayHeight = Math.ceil(((height - bleed) / (width - bleed)) * displayWidth) || 0;
    const grayProps = { kind: Button.kind.grayGhost, size: Button.size.small };

    const backgroundImage = (elements || []).find(({ type }) => type === ElementTypes.BACKGROUND_IMAGE);
    const onChangeImage = ({ value: { document: image } }) => onChange({ name, value: Object.assign((backgroundImage || { ...defaultGraphicElement, type: ElementTypes.BACKGROUND_IMAGE }), { image }) });

    const empty = !elements.length;

    return (
      <div className={css.side}>
        <div className={css.sideHeader}>
          <div className={css.title}>{capitalize(name.substr(0, name.length - 7))} Side</div>
          { immutable || loading || empty ? null : <Button kind={Button.kind.redLink} size={Button.size.small} onClick={() => onClearClick(name)} className={css.link}>Clear</Button>}
        </div>
        <FileReceiver name={name} onChange={onChangeImage}>
          <div className={classNames(css.graphicContainer, { [css.empty]: empty })} style={{ height: displayHeight }}>
            {empty ? null : (
              <div className={css.graphic}>
                <GraphicViewer graphic={graphic} width={displayWidth} getOverlays={getOverlays} placeholders={placeholders} />
              </div>
            )}
            {immutable || loading ? null : (
              <div className={css.buttons}>
                <div>
                  {!empty ? null : (
                    <div>
                      <SVG icon="iconPicture" className={css.iconPicture} />
                      <div className={css.selectPhoto}>
                        Drag &amp; drop an image file here or click Browse to select an image for your Postcard.<br />
                        File should be a PNG or JPG with a minimum size of {widthInches}&quot; &times; {heightInches}&quot; at 300 DPI<br />
                        ({width}px &times; {height}px)
                      </div>
                    </div>
                  )}
                  <ButtonUpload size={Button.size.small} onChange={onChangeImage} className={css.button} accept={imageFormatList} name={name}>Browse Image</ButtonUpload>
                </div>
                <div className={css.selectColor}>
                  <Button {...grayProps} onClick={() => onEditColorClick(`${name}.color`, color)}>Select Color</Button>
                </div>
                <div className={css.selectColor}>
                  <Button {...grayProps} onClick={() => onCopyClick(name)}>Copy {name.startsWith('back') ? 'Front' : 'Back'} Side</Button>
                </div>
                <div className={css.selectColor}>
                  <Button {...grayProps} onClick={() => onGraphicClick(name)}>Advanced Edit</Button>
                </div>
              </div>
            )}
          </div>
        </FileReceiver>
      </div>
    );
  }
}

export default PostcardSide;
