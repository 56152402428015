import React from 'react';
import IntelliProgressBar from '../../../components/IntelliProgressBar';
import css from './style.scss';

const IntelligenceValueCell = ({ name, value, icon, addClass = '', score_percent = 0, score }) => {


    function mapValueToScore(value) {
        // Mapping provided by you
        const valueToFillMap = {
            1.0: 100, 1.1: 99, 1.2: 98, 1.3: 97, 1.4: 95, 1.5: 93, 1.6: 91, 1.7: 89, 1.8: 87, 1.9: 85,
            2.0: 83, 2.1: 81, 2.2: 79, 2.3: 77, 2.4: 75, 2.5: 73, 2.6: 71, 2.7: 69, 2.8: 67, 2.9: 65,
            3.0: 63, 3.1: 61, 3.2: 59, 3.3: 57, 3.4: 55, 3.5: 53, 3.6: 51, 3.7: 49, 3.8: 47, 3.9: 45,
            4.0: 43, 4.1: 41, 4.2: 39, 4.3: 37, 4.4: 35, 4.5: 33, 4.6: 31, 4.7: 29, 4.8: 27, 4.9: 25,
            5.0: 23, 5.1: 21, 5.2: 19, 5.3: 17, 5.4: 15, 5.5: 13, 5.6: 10, 5.7: 8, 5.8: 4, 5.9: 2, 6.0: 0
        };

        // Return the corresponding fill percentage for the value, or 0 if out of range
        return valueToFillMap[value] || 0;
    }

    return (
        <div className={`${css.value} ${addClass}`}>
            <div className={css.IntelligenceValueCellDiv}>
                <div className={css.IntelligenceCellIcon}>
                    <img className={css.IntelligenceCellImg} src={icon} alt={name} />
                </div>
                <div>
                    <div className={css.scoreTitle}>{name}</div>
                    <div className={`${css.scorelabel} ${score_percent > 0 ? css.additionalClass : ''}`}>
                        {value || 'N/A'}
                        {score_percent > 0 && (
                            <>
                                <div className={css.bar}></div>
                                <span className={css.percentLabel}>
                                    {`${score_percent}%`}
                                </span>
                            </>
                        )}
                    </div>
                    <IntelliProgressBar percent={score_percent > 0 ? score_percent : mapValueToScore(score)} size={'small'} />
                </div>
            </div>

        </div>
    );
};

export default IntelligenceValueCell;