import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import { Iterable } from "immutable";
import { createLogger } from 'redux-logger';
import rootReducer from 'reducers/rootReducer';

import apiMiddleware from './middleware/api';
import { thunk } from 'redux-thunk';


/* eslint-disable no-underscore-dangle */
const composeEnhancers = (function getComposeEnhancers() {
  if (process.env.ON_SERVER === false && process.env.NODE_ENV !== 'production') {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: true,
        actionSanitizer: (action) => {
          const type = String(action.type);
          return { ...action, type };
        },
      });
    }
  }
  return compose;
}());

const devTools = (function getDevTools() {
  if (process.env.ON_SERVER === false && process.env.NODE_ENV !== 'production') {
    if (!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      if (window.devToolsExtension) {
        return window.devToolsExtension({
          actionSanitizer: (action) => {
            const type = String(action.type);
            return { ...action, type };
          },
        });
      }
    }
  }
  return arg => arg;
}());
/* eslint-enable no-underscore-dangle */

function lazyTransformToJS (state) {
  if (process.env.NODE_ENV === 'production') return state;
  if (!Iterable.isIterable(state)) return state;

  const serialized = {};

  state.forEach((part, key) => Object.defineProperty(serialized, key, {
    get() {
      if (!Iterable.isIterable(part)) return part;
      return part.toJS();
    },
    enumerable: true,
  }));

  return serialized;
}

const logger = createLogger({
  level: 'info',
  collapsed: false,
  logger: console,
  predicate: () => (process.env.NODE_ENV !== 'production'),
  stateTransformer: lazyTransformToJS,
  actionTransformer: action => (Object.assign({}, action, {
    type: String(action.type),
  })),
});


const createStoreWithMiddleware = function createStoreWithMiddleware(reducer, initialState) {
  return createStore(reducer, initialState, composeEnhancers(
    applyMiddleware(
      thunk,
      apiMiddleware,
      logger,
      // createOpbeatMiddleware(),
    ),
    devTools,
    ),
  );
};

export default function configureStore(initialState) {
  const store = createStoreWithMiddleware(rootReducer, initialState);

  if (module.hot) {
    const hot = module.hot;
    // Enable Webpack hot module replacement for reducers
    hot.accept('../reducers/rootReducer', () => {
      const nextRootReducer = require('../reducers/rootReducer').default; // eslint-disable-line global-require
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
