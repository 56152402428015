import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ContactAssignmentPopup } from 'app/components/GroupAssignment';
import ColorPicker from 'components/ColorPicker';
import GraphicEditor from 'app/components/GraphicEditor/popup';
import Button, { SolidButton } from 'components/Button';
import SVG from 'components/base/SVG';
import FroalaEditor from 'components/FroalaEditor';
import Confirm, { type } from 'app/components/Confirm';
import { froalaMinimalConfig } from 'config/constants/wysiwyg';
import { MarketingCampaignStatuses } from 'data/campaigns/constants';
import { selectProfile } from 'data/user';
import { campaignPath, postcardPath } from 'app/routes';
import { getSampleContact } from 'data/contacts';
import { brightenColor } from 'utils/mediaUtils';
import Prompt from 'app/components/Prompt';
import {
  ElementTypes,
  loadPostcardCampaign,
  savePostcardCampaign,
  postcardCampaignChange,
  previewPostcardCampaign,
  selectCampaign,
  selectPostcardCampaign,
  selectPlaceholders,
  selectLoading,
  downloadPostcardDetail,
} from 'data/campaigns';

import PostcardPreviewPopup from './preview';
import Checkout from './Checkout';
import Settings from './settings';
import Payment from '../Payment';
import PostcardSide from './PostcardSide';
import css from './style.scss';
import css2 from '../style.scss';
import endicia from './endicia.png';
import { Priority } from '../../PopupHolder/constants';
import { v4 as uuidv4 } from 'uuid';


const clone = obj => JSON.parse(JSON.stringify(obj));
const cloneGraphic = (graphic) => {
  const g = clone(graphic);
  g.id = null;
  g.elements = (g.elements || []).map(el => Object.assign(el, { id: null }));

  return g;
};

const isReadOnly = ({ loading, campaign: { status } }) => loading || status !== MarketingCampaignStatuses.NEW;

class PostcardCampaign extends PureComponent {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleEditRecipientsClick = this.handleEditRecipientsClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditColorClick = this.handleEditColorClick.bind(this);
    this.handleGraphicClick = this.handleGraphicClick.bind(this);
    this.handleCopyClick = this.handleCopyClick.bind(this);
    this.handleHeaderChange = this.handleHeaderChange.bind(this);
    this.handleBodyChange = this.handleBodyChange.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleEditorInitialized = this.handleEditorInitialized.bind(this);
    this.getBackOverlays = this.getBackOverlays.bind(this);
    this.handleDownloadDetailClick = this.handleDownloadDetailClick.bind(this);

    this.editors = [];
    this.headerConfig = Object.assign({}, froalaMinimalConfig, { events: { 'froalaEditor.initialized': this.handleEditorInitialized } });
    this.bodyConfig = { ...this.headerConfig, placeholderText: 'Type the text that you want to appear in the body of your postcard. Example: We Pay All Cash For Your Home! Any price range, any location, any condition! Contact us today for an ALL CASH offer on your home, AS IS!' };

    this.state = {
      valid: false,
      changed: false,
      contactIds: [],
      postcardCampaignId: null,
    };
  }

  componentWillMount() {
    let { match: {params: { campaignId , postcardId }}, location, loadPostcardCampaign, profile, history } = this.props;
       const regex = /campaign\/(\d+)(?:\/postcard\/(\d+))?/;
       const matches = location.pathname.match(regex);
        campaignId = matches && matches.length > 0 ? matches[1] : 0
        postcardId = matches && matches.length > 1 ? matches[2] : null
    if (profile?.get('postcardUrl')) history.push(campaignPath(campaignId));
    else {
      const postcardCampaignId = postcardId ? Number(postcardId) : null;
      loadPostcardCampaign(postcardCampaignId);
      this.setState({ postcardCampaignId });
      // Update path to use campaign id "0" if there is no id set
      if (!postcardCampaignId && postcardId !== '0') history.push(postcardPath(campaignId, 0));
    }
  }

  componentDidMount() {
    let { match: {params: { postcardId }} } = this.props;

        // Regular expression to match the campaignId and optionally the postcardId
        const regex = /campaign\/(\d+)(?:\/postcard\/(\d+))?/;

        // Execute the regex on the URL string
        const matches = location.pathname.match(regex);
 
 
     postcardId = matches && matches.length > 1 ? matches[2] : null

    if ((Number(postcardId) || 0) === 0) this.handleEditClick('new');
  }

  componentWillReceiveProps(nextProps) {
    const {
      campaign,
      marketingCampaign: { contactIds },
      history,
      location: { state: locationState },
    } = nextProps;
    const previewModal = document.getElementById('PostcardPreview');
    if (this.props.location.pathname !== nextProps.location.pathname && nextProps.location.pathname.includes('/postcard/0')) {
      if (previewModal && previewModal?.style?.display === 'none') {
        previewModal.style.display = "block";
      }
    } else {
      if (previewModal && previewModal?.style?.display === 'visible') {
        previewModal.style.display = "none";
      }
    }
    const { state } = this;
    const { postcardCampaignId, savedPropertyGroupId } = state;
    let { origCampaign } = state;

    // Treat first campaign object we receive after loading screen as the "original" object, which we'll do change comparisons on.
    if (!origCampaign && typeof postcardCampaignId !== 'undefined' && (campaign.id === postcardCampaignId || postcardCampaignId === 0)) {
      origCampaign = Object.assign({}, campaign);
      this.setState({ origCampaign });
    }

    const readOnly = isReadOnly(nextProps);
    this.editors.forEach(editor => editor.edit[readOnly ? 'off' : 'on']());

    if (origCampaign) {
      // Update path if new campaign was just saved.
      if ((postcardCampaignId || 0) !== (campaign.id || 0)) this.setState({ postcardCampaignId: campaign.id }, () => history.push(postcardPath(campaign.marketingCampaignId, campaign.id)));

      // Determine the effective contacts for this campaign and whether any changes have been made since loading.
      this.setState({
        contactIds: campaign.contactIds || contactIds || (locationState || {}).contactIds || [],
        savedPropertyGroupId: savedPropertyGroupId || (locationState || {}).savedPropertyGroupId || 0,
        valid: true, // !!((campaign.front || campaign.frontColor) && (campaign.back || campaign.backColor)),
        changed: !campaign.id || true ||
          campaign.name !== origCampaign.name ||
          campaign.templateId !== origCampaign.templateId ||
          campaign.front !== origCampaign.front ||
          campaign.back !== origCampaign.back ||
          campaign.frontColor !== origCampaign.frontColor ||
          campaign.backColor !== origCampaign.backColor,
          // ((origCampaign.contactGroup && origCampaign.contactGroup.contactIds) || []).join(',') !== contactIds.join(','),
      });
    }
  }

  getBackOverlays(graphic) {
    const { profile } = this.props;
    const { size: { id: sizeId } } = graphic;
    const businessStreetAddress2 = profile.get('businessStreetAddress2') || '';

    return [(
      <div className={classNames(css.addressOverlay, { [css.lob1]: sizeId === 3, [css.lob2]: sizeId > 3 })} style={{ backgroundColor: brightenColor(graphic.color, { min: 50 }) }}>
        <div className={css.return}>
          <div>{profile.get('businessName', 'My Business Name')}</div>
          <div>{profile.get('businessStreetAddress', '123 Return St.')}</div>
          {businessStreetAddress2 === '' ? null : <div>{businessStreetAddress2}</div>}
          <div>{profile.get('businessCityStateZip', 'City, State, Zip')}</div>
        </div>
        <div className={css.address}>
          <div>JOHN DOE</div>
          <div>123 SAMPLE ST</div>
          <div>EMERALD CITY, CA 12345</div>
        </div>
        <img className={css.endicia} src={endicia} alt="endicia" />
      </div>
    )];
  }

  handleEditRecipientsClick() {
    const { postcardCampaignChange, getSampleContact, openContactAssignmentPopup, campaign: { status } } = this.props;

    openContactAssignmentPopup({
      ids: this.state.contactIds,
      viewMode: status !== MarketingCampaignStatuses.NEW,
      onSave: (contactIds) => {
        postcardCampaignChange(null, { contactIds });
        getSampleContact(contactIds);
      },
    });
  }

  handleEditColorClick(name, color) {
    this.props.openColorPicker({ name, color, onChange: this.handleChange });
  }

  handleCopyClick(name) {
    const { confirm, campaign: { [name]: destGraphic, [name === 'frontGraphic' ? 'backGraphic' : 'frontGraphic']: srcGraphic } } = this.props;
    const copy = () => this.handleChange({ name, value: cloneGraphic(srcGraphic) });

    if (!(destGraphic.elements || []).length) copy();
    else {
      confirm({
        caption: 'Confirm Copy',
        onOk: copy,
        okLabel: 'Copy',
        type: type.danger,
        question: `Are you sure you want to copy the postcard side? All existing contents of the ${name.replace('Graphic', '')} side of the postcard will be overwritten.`,
      });
    }
  }

  handleHeaderChange(text) {
    if (text && text !== '') this.changeText(ElementTypes.TITLE_HTML, text);
  }

  handleBodyChange(text) {
    if (text && text !== '') this.changeText(ElementTypes.BODY_HTML, text);
  }

  handleClearClick(name) {
    const { confirm, campaign: { [name]: graphic } } = this.props;

    confirm({
      caption: 'Confirm Content Removal',
      onOk: () => this.handleChange({ name, value: Object.assign(graphic, { color: '#ffffff', elements: [] }) }),
      okLabel: 'Clear',
      type: type.danger,
      question: `Are you sure you want to clear the ${name.replace('Graphic', '')} side of the postcard? All contents will be removed.`,
    });
  }

  handleEditorInitialized(e, editor) {
    this.editors.push(editor);
    editor.edit[isReadOnly(this.props) ? 'off' : 'on']();
  }

  changeText(type, text) {
    const { campaign: { frontGraphic: { elements = [] } } } = this.props;
    this.handleChange({ name: 'frontGraphic', value: Object.assign(elements.find(e => e.type === type) || { type }, { text }) });
  }

  handlePreviewClick() {
    const { campaign, openPreview, previewPostcardCampaign } = this.props;
    previewPostcardCampaign(campaign, false, () => openPreview({ campaign, priority: Priority.HIGH }));
  }

  handleSaveClick(publish, duplicate) {
    const { confirm, campaign, openSettings, openCheckout, savePostcardCampaign, previewPostcardCampaign, prompt, match: {params: { campaignId }} , marketingCampaign } = this.props;
    const { contactIds, savedPropertyGroupId } = this.state;
    const settings = { marketingCampaignId: campaignId ? campaignId : marketingCampaign.id, savedPropertyGroupId, contactIds: contactIds || [] };


    if (!settings.contactIds.length) confirm({ question: 'You must have at least one recipient selected.', cancelLabel: null });
    else {
      const newCampaign = { ...this.props.campaign, ...settings };

      const save = () => {
        savePostcardCampaign(newCampaign, duplicate);
      };

      if (duplicate) {
        prompt({
          title: 'Postcard Settings',
          label: 'Postcard Name (Optional)',
          value: `${campaign.name} - Duplicate`,
          onSubmit: (name) => {
            newCampaign.name = name;
            save();
          },
        });
      } else if (campaign.id && !publish) save(); // If we're saving for the first time or publishing, get preview images and load respective dialog. Otherwise simply save.
      else {
        previewPostcardCampaign({ ...this.props.campaign, ...settings }, publish, () => {
          if (!publish) openSettings({ mode: 'save', onSave: save, saveLabel: 'Save', title: 'Save Postcard - Settings', priority: Priority.HIGH });
          else openSettings({ mode: 'save', saveLabel: 'Next', title: 'Print and Mail - Settings', onSave: () => openCheckout(), priority: Priority.HIGH });
        });
      }
    }
  }

  handleGraphicClick(name) {
    const { campaign, placeholders, openGraphicEditor } = this.props;
    openGraphicEditor({ name, placeholders, graphic: campaign[name], getOverlays: name === 'backGraphic' ? this.getBackOverlays : null, onChange: this.handleChange });
  }

  handleEditClick(mode = '') {
    const { campaign, openSettings } = this.props;
    openSettings({ uuidv4: uuidv4(), onChange: this.handleChange, mode: mode === 'new' ? 'new' : 'full' });
  }

  handleChange(data) {
    const { campaign, postcardCampaignChange } = this.props;
    const target = data.target || data;
    let { name, value } = target;

    if (typeof value === 'object' && value.type && !value.elements) {
      const { elements = [] } = campaign[name];
      if (!elements.includes(value)) elements.push(value);

      name = `${name}.elements`;
      value = elements;
    }

    postcardCampaignChange(name, value);
  }

  handleDownloadDetailClick() {
    const { downloadPostcardDetail, campaign: { id } } = this.props;
    downloadPostcardDetail(id);
  }

  render() {
    const { params, campaign, loading, placeholders } = this.props;
    const { contactIds, changed, valid } = this.state;
    const { name, status, frontGraphic: { size, elements = [] } } = campaign;
    const immutable = status !== MarketingCampaignStatuses.NEW;

    const header = elements.find(e => e.type === ElementTypes.TITLE_HTML);
    const body = elements.find(e => e.type === ElementTypes.BODY_HTML);
    const btnProps = { loading };

    const sideProps = {
      onChange: this.handleChange,
      onEditColorClick: this.handleEditColorClick,
      onGraphicClick: this.handleGraphicClick,
      onCopyClick: this.handleCopyClick,
      onClearClick: this.handleClearClick,
      immutable,
      loading,
      campaign,
      placeholders,
    };

    return (
      <div className={css.postcard}>
        <div className={css2.header}>
          <div className={css2.left}>
            <div>
              <div className={css2.title}>
                Postcard ({size.name})
                {immutable || loading ? null : <SVG icon="iconPencil" size={12} onClick={this.handleEditClick} />}
              </div>
              <div className={css2.name}>{name}</div>
            </div>
          </div>
          <div className={css2.right}>
            <Button {...btnProps} url={campaignPath(this.props?.marketingCampaign?.id)}>Close</Button>
            {!campaign.id || status !== MarketingCampaignStatuses.COMPLETE ? null : <Button {...btnProps} onClick={this.handleDownloadDetailClick}>Download Detail</Button>}
            <Button {...btnProps} disabled={!valid} onClick={() => this.handlePreviewClick()}>Print Preview</Button>
            <SolidButton {...btnProps} onClick={this.handleEditRecipientsClick}>{immutable ? 'View' : 'Edit'} Recipients ({contactIds.length})</SolidButton>
            {immutable ? null : <SolidButton {...btnProps} disabled={!changed} onClick={() => this.handleSaveClick(false)}>{changed ? 'Save' : 'Saved'}</SolidButton>}
            {!campaign.id ? null : <SolidButton {...btnProps} onClick={() => this.handleSaveClick(false, true)}>Duplicate</SolidButton>}
            {immutable ? null : <SolidButton {...btnProps} disabled={!valid} onClick={() => this.handleSaveClick(true, false)}>Print and Mail</SolidButton>}
          </div>
        </div>
        <div className={classNames(css2.content, css.content)}>
          <div className={classNames(css2.contentInner, css.contentInner)}>
            <div className={css.sides}>
              <PostcardSide {...sideProps} name="frontGraphic" />
              <PostcardSide {...sideProps} getOverlays={this.getBackOverlays} name="backGraphic" />
            </div>
            <div className={css.textEditors}>
              <div className={css.textEditor}>
                <div className={css.captionText}>Header Text (Optional)</div>
                <div>
                  <FroalaEditor config={this.headerConfig} model={header ? header.text : ''} disabled={immutable} onModelChange={this.handleHeaderChange} />
                </div>
              </div>
              <div className={css.textEditor}>
                <div className={css.captionText}>Body Text (Optional)</div>
                <div>
                  <FroalaEditor config={this.bodyConfig} model={body ? body.text : ''} disabled={immutable} onModelChange={this.handleBodyChange} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(state => ({
  marketingCampaign: selectCampaign(state).toJS(),
  campaign: selectPostcardCampaign(state).toJS(),
  placeholders: selectPlaceholders(state),
  loading: selectLoading(state),
  profile: selectProfile(state),
}), {
  openContactAssignmentPopup: ContactAssignmentPopup.open,
  openPreview: PostcardPreviewPopup.open,
  closePreview: PostcardPreviewPopup.close,
  openPayment: Payment.open,
  closePayment: Payment.close,
  openSettings: Settings.open,
  closeSettings: Settings.close,
  openCheckout: Checkout.open,
  prompt: Prompt.open,
  confirm: Confirm.open,
  openColorPicker: ColorPicker.open,
  openGraphicEditor: GraphicEditor.open,
  loadPostcardCampaign,
  savePostcardCampaign,
  postcardCampaignChange,
  previewPostcardCampaign,
  getSampleContact,
  downloadPostcardDetail,
})(PostcardCampaign));
