import React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';
import { bindToState } from 'components/base/Form';

import Layout from './Layout';

const ModalSelectTransaction = ({ handleSubmit, transactions, onSelect, closePopup }) => {
  const handleClose = () => {
    closePopup();
  };

  const handleSubmitForm = values => {
    const transactionId = String(values.get('transaction'));
    const transaction = List(transactions).find(t => String(t.id) === transactionId);

    if (transaction.documentImagePurchased) {
      alert('You have already purchased this document image.');
    } else {
      handleClose();
      onSelect(transaction);
    }
  };

  return (
    <Layout
      transactions={transactions}
      onClose={handleClose}
      onSubmit={handleSubmit(handleSubmitForm)}
    />
  );
};

const formName = 'create-group-modal-form';

const mapStateToProps = () => ({
  form: formName,
});

const Popup = connect(mapStateToProps)(bindToState(formName)(ModalSelectTransaction));

Popup.registrationId = getPopupRegistration(Popup);
Popup.open = (props, ...rest) => openPopup(Popup.registrationId, { priority: Priority.HIGH, ...props }, ...rest);
Popup.close = () => closePopup({ popup: Popup.registrationId });

export default Popup;
