import React from 'react';

import { ButtonImport as RealButtonImport } from '../Button';


function ButtonImport(props) {
  console.warn([
    "Please, don't use `import ButtonImport from 'components/base/ButtonImport'`, it's deprecated. ",
    "Use `import { ButtonImport } from 'components/base/Button'` instead.",
  ].join(''));
  const { children, ...rest } = props;
  return (<RealButtonImport {...rest}>{children}</RealButtonImport>);
}

export default ButtonImport;

ButtonImport.kind = RealButtonImport.kind;
ButtonImport.size = RealButtonImport.size;
