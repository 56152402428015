import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { selectProfile } from 'data/user';

import urlSubscribe from 'app/Property/Detail/urlSubscribe';

import Modal from './AduCalculator';
import { withRouter } from 'react-router-dom';

const AduCalculatorLoader = ({ match: {params}, openPopup, history, routes, urls, location, children, profile }) => {
  openPopup({
    onClose: () => history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/"))),
    urls,
    params: { ...params },
    history,
    location,
    profile,
    route: routes && routes.length > 0 ? routes[routes.length - 1] : {}, // Taking the current route
  });

  return children;
};

const mapStateToProps = state => ({
  profile: selectProfile(state), // Getting profile from the Redux state
});

const mapActionToProps = {
  openPopup: Modal.open,
  closePopup: Modal.close,
};

export default withRouter(connect(
  mapStateToProps,
  mapActionToProps,
)(urlSubscribe(AduCalculatorLoader)));
