import SVG from 'components/base/SVG';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ImPropTypes from 'react-immutable-proptypes';
import { getName } from '../constants';
import css from './dropdownPriceUpdateUI.scss';

import classNames from 'classnames';
import { dropdownable } from 'components/base/Dropdown';
import Button from '../../../../../components/Button/Button';

const DropdownPriceUpdateUI = ({ data, type, isOpen, onClick, onRef, metric, closeDropdown, currentMetric, onChange }) => {
  const dropdownToggleRef = useRef(null);

  const changeValue = (name) => {
    onChange(name);
  };



  return (
    <div className={css.header} ref={dropdownToggleRef}>
      <div className={css.buttons}>
        <div className={css.dropDownToggle}>
          <Button onClick={onClick}>
            <div className={css.toolsButton}>
              {getName(type, currentMetric)}
              <SVG icon="iconCaretDown" className={css.iconCaretDown} />
            </div>
          </Button>
          <div
            className={classNames(css.dropdown, { [css.open]: isOpen })} onClick={closeDropdown} ref={onRef}
          >
            {data.map((menu, index) => {
              const name = menu.get('name');
              const isTopBorderItem = index === 0 ? css.topBorderItem : '';
              const isBotBorderItem = index === data.size - 1 ? css.botBorderItem : '';
              return (
                <div
                  key={name}
                  className={`${css.dropdownItem} ${name === metric ? css.active : ''} ${isTopBorderItem} ${isBotBorderItem}`}
                  onClick={() => changeValue(name)}
                >
                  {menu.get('displayName')}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

DropdownPriceUpdateUI.propTypes = {
  data: ImPropTypes.listOf(
    ImPropTypes.contains({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string,
    })
  ).isRequired,
};

export default dropdownable(DropdownPriceUpdateUI);