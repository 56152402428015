export default class MortgagePeriod {
  constructor() {
    this.paymentNumber = null;
    this.date = null;
    this.payment = 0;
    this.interest = 0;
    this.principal = 0;
    this.cumulativeInterest = 0;
    this.cumulativePrincipal = 0;
    this.balance = 0;
  }
}
