import { SolidButton } from 'components/Button';
import React, { Component } from 'react';

export default class CancelConfirmedModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { profile } = this.props;
    const [year, month, date]= this.props.nextCycleDate.split('-');
    let nextBillingDate = `${month}/${date}/${year.slice(2)}`;
    return (
      <span>
        {/* <Activities onClick={this.handleClose} /> */}
        <div
          style={{ width: '1160px', padding: '15px 25px 0', display: 'flex' }}
        >
          <div style={{ width: '50%', paddingBottom: '25px' }}>
          <img
              src='https://signup.propstream.com/resource/logo?c=CA02937056'
              placeholder='https://signup.propstream.com/resource/logo?c=CA02937056'
              class='_159lC__logoSmall'
              style={{ width: '138px', height: '76px', visibility: `${this.props.brandCode.includes('propstream')? 'visible': 'hidden'}` }}
            />
            <div
              style={{
                marginTop: '30px',
                color: '#3D3D3D',
                font: 'normal normal bold 24px/24px Helvetica Neue',
                lineHeight: 1.2,
              }}
            >
              We are totally bummed out to see you go!
            </div>
            <div
              style={{
                marginTop: '25px',
                font: 'normal normal bold 14px Helvetica Neue',
                color: '#3D3D3D',
                lineHeight: 1.5,
              }}
            >
              But we are grateful for the time we spent with you! Below is some
              important information about your cancelation.
            </div>
            <div
              style={{
                marginTop: '21px',
                font: 'normal normal bold 14px Helvetica Neue',
                color: '#3D3D3D',
                lineHeight: 1.5,
              }}
            >
              Here is your cancelation verification number:{' '}
              <span style={{ color:  `${this.props.brandCode.includes('propstream')?'#F16722': '#297CCF'}` }}>
                {this.props.verificationNumber}
              </span>
            </div>
            <div
              style={{
                font: 'normal normal 14px Helvetica Neue',
                color: '#707070',
                lineHeight: 1.5,
              }}
            >
              {this.props.accountType ? <span>Your Account is scheduled to be canceled before your next billing
              cycle:{' '}
              <span style={{ color:  `${this.props.brandCode.includes('propstream')?'#F16722': '#297CCF'}` }}>
                {nextBillingDate}.
              </span>
              <span>You can use your {this.props.brandName} account until then.</span>
              </span>:
              <span>Your Trial Account will be deactivated immediately.</span>}
            </div>
            <div
              style={{
                marginTop: '21px',
                font: 'normal normal 14px Helvetica Neue',
                color: '#707070',
                lineHeight: 1.5,
              }}
            >
              A cancelation confirmation has been sent to the email on file. Thank you for your business, and we look forward to seeing you soon.
            </div>
            <div
              style={{
                marginTop: '21px',
                font: 'normal normal bold 14px Helvetica Neue',
                color: '#3D3D3D',
                lineHeight: 1.5,
              }}
            >
              Just in case you change your mind….
            </div>
            <div
              style={{
                font: 'normal normal 14px Helvetica Neue',
                color: '#707070',
                lineHeight: 1.5,
              }}
            >
              You can always reinstate your account by logging back in with your
              username and password and following the prompt to re-activate your
              account.
            </div>
            <div
              style={{
                marginTop: '21px',
                font: 'normal normal bold 14px Helvetica Neue',
                color: '#3D3D3D',
                lineHeight: 1.5,
              }}
            >
              Got Questions?
            </div>
            <div
              style={{
                font: 'normal normal 14px Helvetica Neue',
                color: '#707070',
                lineHeight: 1.5,
              }}
            >
              <div>Please contact our support team:</div>
              <div>M-F: 6 am-6 pm PT</div>
              <div>Toll Free: {profile.get('csPhone')}</div>
              <div>
                Email:{' '}
                <a
                  style={{
                    paddingLeft: '2px',
                    color:  `${this.props.brandCode.includes('propstream')?'#F16722': '#297CCF'}`,
                    textDecoration: 'none',
                  }}
                  href={profile.get('csEmail')}
                >
                  {profile.get('csEmail')}
                </a>
              </div>
            </div>
            <div
              style={{
                margin: '25px 0 20px',
              }}
            >
              <SolidButton onClick={() => this.props.handleClose()}>
                Close {this.props.brandCode.includes('propstream') && 'PropStream'}
              </SolidButton>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <img src={'/assets/images/cancelConfirmed.jpg'} alt='' height='640px' />
          </div>
        </div>
      </span>
    );
  }
}
