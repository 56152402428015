/** @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Confirm from './Confirm';
import confirmType from './type';


/* eslint-disable no-unused-vars */
const noop = (...rest) => {};
const noExtraProps = (...rest) => ({});
const alwaysTrue = (...rest) => true;
const alwaysFalse = (...rest) => false;
/* eslint-enable no-unused-vars */


export default function createConfirmable(Comp) {
  const selectConfirmable = createSelector(
    v => v,
    (dispatch) => function confirmable(options) {
      const {
        question: quest,
        onCancel = noop,
        onOk,
        type = confirmType.default,
        skipIfTrue = alwaysFalse,
        skipIfFalse = alwaysTrue,
        getExtraProps = noExtraProps,
        caption: capt,
        okLabel,
        cancelLabel,
      } = options;

      return function (...rest) {
        if (skipIfTrue(...rest)) return onOk(...rest);
        if (skipIfFalse(...rest) === false) return onOk(...rest);

        let caption = null;

        if (typeof capt === 'function') caption = capt(...rest);
        else if (typeof capt === 'string') caption = capt;

        const extraProps = getExtraProps();
        return dispatch(Confirm.open({
          ...extraProps,
          type,
          caption,
          okLabel,
          cancelLabel,
          question: typeof quest === 'string' ? quest : quest(...rest),
          onOk: () => onOk(...rest),
          onCancel: () => onCancel(...rest),
        }));
      };
    },
  );

  class Confirmable extends PureComponent {
    render() {
      const { dispatch, ...rest } = this.props;

      return <Comp {...rest} confirmable={selectConfirmable(dispatch)} />;
    }
  }

  return connect()(Confirmable);
}
