import { Component } from 'react';
import { connect } from 'react-redux';
import { onlyUpdateForKeys } from 'recompose';

import { selectError, removeError } from 'data/errors';
import { setLogout } from 'data/user';

import ErrorModal from './ErrorModal';


export class ErrorDisplay extends Component {
  componentDidMount() {
    this.tryDisplayError();
  }

  componentDidUpdate() {
    this.tryDisplayError();
  }

  tryDisplayError() {
    const { error, setLogout } = this.props;
    if (!error) return;

    const message = error.get('message');
    const response = error.getIn(['action', 'response']) || {};
    const { forbidden, headers = {} } = response;

    if (forbidden) {
      setLogout(headers['logout-message'] || null);
    } else {
      this.props.openPopup({
        message,
        caption: error.get('caption'),
      });

      this.props.removeError(error.get('id'));
    }
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    error: selectError(state),
  };
}

const mapActionToProps = {
  removeError,
  setLogout,
  openPopup: ErrorModal.open,
};

export default connect(mapStateToProps, mapActionToProps)(onlyUpdateForKeys(['error'])(ErrorDisplay));
