import React, { PureComponent } from 'react';

import Button from './Button';


class SolidButton extends PureComponent {
  render() {
    return <Button solid {...this.props} />;
  }
}

export default SolidButton;
