const fontList = ['A Hundred Miles', 'AvenirNext', 'Arial', 'Comic Sans MS', 'Courier New', 'CTorres', 'FG Bonnies Font', 'Georgia', 'Givens', 'Impact', 'Levy', 'Lindy', 'Lindy Bold', 'Michele Hand', 'SignPainter', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'You Wont Bring Me Down', 'Your Font'];
const fonts = fontList.reduce((fonts, font) => {
  const idx = font.indexOf(':');
  return Object.assign(fonts, { [font.substr(0, idx === -1 ? font.length : idx)]: font.substr(idx + 1) });
}, {});

const froalaDefaultConfig = {
  key: 'kI2B5C1C1B2C1F1wG1G1B2C2B1C7F6E1E4B3jXa1TEWUf1d1QSDb1HAc1==',
  placeholderText: 'We Buy Houses, Fast & Fair Offer Today!',
  heightMin: 100,
  charCounterCount: false,
  fontFamilySelection: true,
  paragraphFormatSelection: true,
  quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr'],
  fontFamily: fonts,
  toolbarButtons: [
    'fontFamily', '|', 'paragraphFormat', '|', 'bold', 'italic', 'underline', 'strikeThrough', '|', 'color', '|',
    'formatOL', 'formatUL',
    // '|', 'insertImage', '|', 'insertLink', '|', 'fullscreen', '|', 'undo', 'redo',
  ],
  paragraphFormat: {
    H1: 'Heading 1',
    H2: 'Heading 2',
    H3: 'Heading 3',
    P: 'Normal',
  },
  aviaryKey: 'dbb82b6f78df449080e6e5a4d7429828',
  imageEditButtons: [
    'imageReplace', 'imageRemove', 'linkOpen', 'linkEdit', 'imageSize', 'aviary',
  ],
  wordPasteModal: false,
  wordAllowedStyleProps: [
    'font-family', 'font-size', 'font-weight', 'font-style', 'color', 'background', 'background-color',
    'text-align', 'text-decoration',
  ],
  htmlAllowedAttrs: [
    'accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'async',
    'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'border', 'charset', 'cellpadding',
    'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu',
    'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download',
    'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden',
    'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang',
    'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple',
    'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster',
    'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox',
    'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc',
    'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type',
    'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap',
  ],

  imageUploadParam: 'file',
  imageUploadMethod: 'POST',
  imageUploadParams: { public: 1 },
};

const froalaConfigWithoutImages = Object.assign({}, froalaDefaultConfig, {
  quickInsertButtons: ['table', 'ul', 'ol', 'hr'],
  toolbarButtons: [
    'fontFamily', '|', 'paragraphFormat', '|', 'bold', 'italic', 'underline', 'strikeThrough', '|', 'color', '|',
    'formatOL', 'formatUL', '-', 'insertLink', '|', 'fullscreen', '|', 'undo', 'redo',
  ],
});

const fontSizes = [];
for (let i = 6; i <= 300; i += (i >= 28 ? 4 : 1)) fontSizes.push(String(i));

const froalaMinimalConfig = {
  key: 'kI2B5C1C1B2C1F1wG1G1B2C2B1C7F6E1E4B3jXa1TEWUf1d1QSDb1HAc1==',
  aviaryKey: 'dbb82b6f78df449080e6e5a4d7429828',
  heightMin: 100,
  charCounterCount: false,
  fontFamilySelection: true,
  fontFamily: fonts,
  fontFamilyDefaultSelection: 'AvenirNext',
  fontSizeDefaultSelection: '32',
  fontSize: fontSizes,
  fontSizeSelection: true,
  toolbarButtons: ['fontFamily', '|', 'fontSize', '|', 'color', '|', 'bold', 'italic', 'underline', 'strikeThrough', 'align'],
  wordPasteModal: false,
  wordAllowedStyleProps: ['font-family', 'font-size', 'font-weight', 'font-style', 'color', 'background', 'background-color', 'text-align', 'text-decoration'],
  htmlAllowedAttrs: ['style'],
};

export default froalaDefaultConfig;

export { froalaDefaultConfig, froalaConfigWithoutImages, froalaMinimalConfig };
