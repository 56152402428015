import React, { PureComponent } from 'react';

import classNames from 'classnames';
import css from './style.scss';

import PropTypes from 'prop-types'

class FormControlWraper extends PureComponent {
  renderLabel() {
    const { id, label, showEmptyLabel, labelClassName } = this.props;

    if (!label && !showEmptyLabel) return null;

    return (
      <label
        htmlFor={id}
        className={classNames(css.label, labelClassName, { [css.showEmptyLabel]: showEmptyLabel })}
      >
        {label}
      </label>
    );
  }

  render() {
    const { children, hasMarginBottom, large, className } = this.props;
    return (
      <div className={classNames(css.formControl, { [css.mb]: hasMarginBottom, [css.large]: large }, className)}>
        {this.renderLabel()}
        <div>
          {children}
        </div>
      </div>
    );
  }
}

FormControlWraper.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasMarginBottom: PropTypes.bool,
  className: PropTypes.string,
  large: PropTypes.bool,
};

FormControlWraper.defaultProps = {
  hasMarginBottom: false,
};


export default FormControlWraper;
