/** @flow */
import React, { PureComponent } from 'react';
import { List } from 'immutable';
import { BarChart as Layout, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';
import classNames from 'classnames';
import CustomLabel from './CustomLabel';
import CustomTick from './CustomTick';
import PropTypes from 'prop-types';

import css from './style.scss';


const DEFAULT_CHART_COLOR = '#109e2f';
const DEFAULT_AXIS_COLOR = '#9fa7b0';
const CHART_WIDTH = 256;
const CHART_HEIGHT = 120;
const AXIS_HEIGHT = 15;
const X_INTERVAL = 0;
const Y_INTERVAL = 'preserveStartEnd';
const CHART_MARGIN = {
  top: 15,
  right: 15,
  left: 15,
  bottom: 15,
};
const Y_AXIS_DOMAIN = [0, 'auto'];

const LEGEND_WRAPPER_STYLE = { bottom: 12, color: DEFAULT_AXIS_COLOR };

export class BarChart extends PureComponent {
  getLegendPayload() {
    const {
      chartStyleRules,
      categories,
      colors,
    } = this.props;
    const {
      legendPayload,
    } = chartStyleRules;
    if (!legendPayload) return legendPayload;
    return legendPayload.map((part, i) => ({ ...part, color: colors[categories.get(i)] || DEFAULT_CHART_COLOR }));
  }

  render() {
    const {
      caption,
      className,
      xOrientation,
      yOrientation,
      data,
      xAxisLabel,
      yAxisLabel,
      chartStyleRules,
      categories,
      colors,
      yTickFormatter,
      tooltipFormatter,
      tooltipLabelFormatter,
      chartWidth,
      chartHeight,
    } = this.props;
    const {
      showGrid,
      axisLine,
      verticalGrid,
      tickLine,
      axisColor = DEFAULT_AXIS_COLOR,
      showLegend,
      legendIcon,
      legendIconSize,
      axisWidth,
    } = chartStyleRules;
    const grid = showGrid ? (<CartesianGrid vertical={verticalGrid} />) : null;
    const legend = showLegend ? (
      <Legend
        verticalAlign="bottom"
        payload={this.getLegendPayload()}
        iconType={legendIcon}
        iconSize={legendIconSize}
        wrapperStyle={LEGEND_WRAPPER_STYLE}
        layout="horizontal"
      />
    ) : null;
    const bars = categories.map((category) => {
      const color = colors[category] || DEFAULT_CHART_COLOR;
      return (<Bar
        key={category}
        dataKey={category}
        fill={color}
      />);
    });

    return (
      <div className={classNames(className, css.root)} >
        <div className={css.caption}>{caption}</div>
        <Layout
          width={chartWidth}
          height={chartHeight}
          data={data}
          margin={CHART_MARGIN}
        >
          <XAxis
            orientation={xOrientation}
            axisLine={axisLine}
            tickLine={tickLine}
            stroke={axisColor}
            height={AXIS_HEIGHT}
            interval={X_INTERVAL}
            dataKey="label"
            label={<CustomLabel stroke={axisColor} value={xAxisLabel} orientation={xOrientation} />}
          />
          <YAxis
            orientation={yOrientation}
            axisLine={axisLine}
            tickLine={tickLine}
            stroke={axisColor}
            width={axisWidth}
            label={<CustomLabel stroke={axisColor} value={yAxisLabel} orientation={yOrientation} />}
            tick={<CustomTick tickFormatter={yTickFormatter} />}
            domain={Y_AXIS_DOMAIN}
            allowDecimals={false}
            interval={Y_INTERVAL}
          />
          {grid}
          {legend}
          <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} />
          {bars}
        </Layout>
      </div>
    );
  }
}

BarChart.defaultProps = {
  data: [],
  categories: List(),
  xOrientation: 'bottom',
  yOrientation: 'left',
  chartStyleRules: {},
  colors: {},
  chartWidth: CHART_WIDTH,
  chartHeight: CHART_HEIGHT,
};

BarChart.propTypes = {
  yTickFormatter: PropTypes.func,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  categories: PropTypes.instanceOf(List).isRequired,
  caption: PropTypes.string.isRequired,
  xOrientation: PropTypes.oneOf(['bottom', 'top']),
  yOrientation: PropTypes.oneOf(['left', 'right']),
};

export default BarChart;
