export default class AnalysisRentalUnit {
  constructor(name, amount, occupancyRate, rentIncreaseRate, quantity, squareFeet) {
    this.seq = null;
    this.name = name || '';
    this.amount = amount || 0;
    this.occupancyRate = occupancyRate || 1;
    this.rentIncreaseRate = rentIncreaseRate || 0.04;
    this.quantity = quantity || 1;
    this.squareFeet = squareFeet || 0;
  }

  copy(u) {
    // Copy user-entered fields
    this.seq = u.seq;
    this.name = u.name;
    this.amount = u.amount;
    this.occupancyRate = u.occupancyRate;
    this.rentIncreaseRate = u.rentIncreaseRate;
    this.quantity = u.quantity;
    this.squareFeet = u.squareFeet;
  }

  setPricePerSquareFoot(pricePerSquareFoot) {
    this.amount = this.squareFeet * pricePerSquareFoot;
  }
}

