import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import css from './style.scss';


const ColorDot = ({ children, color, className = '', title = null, isBig }) => (
  <div
    title={title}
    className={classNames(css.dot, className, { [css.small]: !isBig })}
    style={{ background: color }}
  >
    {children}
  </div>
);

ColorDot.propTypes = {
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  isBig: PropTypes.bool,
};

ColorDot.defaultProps = {
  type: 'legend-dot',
  isBig: true,
  color: '#66707d',
};

export default pure(ColorDot);
