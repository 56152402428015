/** @flow */
const ANY_KEY = ('ANY_KEY');

const realCallbacks = new Map();
const realKeyedCallbacks = new Map();
const noop = () => {};

function getRealCallback(key, callback) {
  if (key === ANY_KEY) {
    realCallbacks.set(callback, callback);
    return callback;
  }
  const k = ((key));
  if (!realKeyedCallbacks.has(k)) {
    realKeyedCallbacks.set(k, new Map());
  }

  const realCallback = function onKeyPressCallback(event) {
    if (event.key === key) callback(event);
  };

  ((realKeyedCallbacks.get(k))).set(callback, realCallback);

  return realCallback;
}

function getForRemoveRealCallback(key, callback) {
  if (key === ANY_KEY) {
    realCallbacks.delete(callback);
    return callback;
  }

  const k = ((key));
  if (!realKeyedCallbacks.has(k)) {
    return noop;
  }

  const realCallbackMap = realKeyedCallbacks.get(k) || new Map();

  const realCallback = realCallbackMap.get(callback) || noop;

  realCallbackMap.delete(callback);

  if (realCallbackMap.size === 0) {
    realKeyedCallbacks.delete(k);
  }

  return realCallback;
}

export function addListener(key, callback) {
  if (typeof key === 'function') {
    addListener(ANY_KEY, ((key)));
    return;
  }
  if (!document) throw new Error('addEventListener can be called only on client');
  if (!document.documentElement) throw new Error('addEventListener can be called only on client');

  document.documentElement.addEventListener('keyup', getRealCallback(key, callback));
}

export function removeListener(key, callback) {
  if (typeof key === 'function') {
    addListener(ANY_KEY, ((key)));
    return;
  }
  if (!document) throw new Error('removeListener can be called only on client');
  if (!document.documentElement) throw new Error('removeListener can be called only on client');

  document.documentElement.addEventListener('keyup', getForRemoveRealCallback(key, callback));
}
