/** @flow */
import React, { PureComponent } from 'react';
import { Field } from 'redux-form/immutable';

import DropDown from './DropDown';


export default class DropdownFormed extends PureComponent {
  render() {
    return (
      <Field {...this.props} component={DropDown} />
    );
  }
}
