import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { phoneFormatter } from 'utils/formatter';
import { placeholderFields } from './constants';
import { selectProfile } from '../user/selectors';


const emptyMap = Map();

const checkState = state => state;
export const selectState = createSelector(checkState, state => state.get('campaigns', emptyMap));

export const selectPropIn = path => createSelector(selectState, state => state.getIn(path));
export const selectProp = property => selectPropIn([property]);

export const selectLoading = selectProp('loading');

export const selectSmsEnabled = selectProp('smsEnabled');

export const selectSmsRegistered = selectProp('smsRegistered');

export const selectPostcardCampaign = selectProp('postcardCampaign');

export const selectPostcardTemplates = selectProp('postcardTemplates');

export const selectPostcardCategories = selectProp('postcardCategories');

export const selectEmailCampaign = selectProp('emailCampaign');

export const selectEmailLayouts = selectProp('emailLayouts');

export const selectEmailTemplates = selectProp('emailTemplates');

export const selectWebsite = selectProp('website');

export const selectWebsiteTemplates = selectProp('websiteTemplates');

export const selectCampaigns = selectProp('campaigns');

export const selectCampaign = selectProp('campaign');

export const selectVoicemailAudioPin = selectProp('voicemailAudioPin');

export const selectVoicemailCallerId = selectProp('voicemailCallerId');

export const selectVoicemailRecording = selectProp('voicemailRecording');

export const selectVoicemailSchedule = selectProp('voicemailSchedule');

export const selectVoicemailCampaign = selectProp('voicemailCampaign');

export const selectVoicemailCallerIds = selectProp('voicemailCallerIds');

export const selectVoicemailRecordings = selectProp('voicemailRecordings');

export const selectVoicemailSchedules = selectProp('voicemailSchedules');

export const selectPostcardRates = selectProp('postcardRates');

export const selectMailingLabelLayouts = selectProp('mailingLabelLayouts');

export const selectContactAppendRates = selectProp('contactAppendRates');

export const selectSampleRecipient = selectProp('sampleRecipient');

export const selectPlaceholders = createSelector(
  [selectProfile, selectSampleRecipient],
  (profile, sampleRecipient) => {
    const placeholders = placeholderFields.map(({ field, source, expression }) => {
      const val = (source === 'profile' ? profile : sampleRecipient).get(field);

      return {
        expression,
        value: field.endsWith('Phone') && val && val.length >= 10 ? phoneFormatter(val) : val,
      };
    });

    return placeholders;
  },
);
