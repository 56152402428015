/** @flow */
/** @module utils/date/formatAddress */
import { Map } from 'immutable';
import { join, joinWith } from '../string';


export default function formatAddress(address, invalidAddress = 'Invalid address') {
  if (!Map.isMap(address)) {
    return invalidAddress;
  }
  const streetAddress = address.get('streetAddress', '');
  const cityName = address.get('cityName', '');
  const stateCode = address.get('stateCode', '');
  const zip = address.get('zip', '');

  const city = [streetAddress, cityName].filter(item => item).join('\n');
  const state = [stateCode, zip].filter(item => item).join(' ');
  return [city, state].filter(item => item).join(', ');
}

export function formatCityStateZip(city, state, zip) {
  return joinWith(' ', join(city, state), zip);
}

export function formatFullAddress(streetAddress, city, state, zip, singleLine = false) {
  return joinWith(singleLine ? ' ' : '\n', streetAddress, formatCityStateZip(city, state, zip));
}

export function formatFullAddressObject(address, singleLine = false) {
  return !address.size ? formatFullAddress(address.streetAddress, address.cityName, address.stateCode, address.zip, singleLine) :
    formatFullAddress(address.get('streetAddress'), address.get('cityName'), address.get('stateCode'), address.get('zip'), singleLine);
}
