import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import css from './style.scss';


class Info extends PureComponent {
  render() {
    const { caption, onClick } = this.props;
    return (
      <span className={css.info}>
        <button className={css.icon} type="button" onClick={onClick}>i</button>
        <div className={css.caption}>{caption}</div>
      </span>
    );
  }
}

Info.propTypes = {
  caption: PropTypes.string,
};

export default Info;
