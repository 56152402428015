import React, { useRef } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import MaskedInput from 'react-maskedinput';
import PropTypes from 'prop-types';

import Confirm from 'app/components/Confirm';
import Dropdown from 'components/base/Dropdown';
import { selectProfile } from 'data/user';
import { CcMonthOptions, CountryOptions, CountryMap, getCcYearOptions } from 'data/user/constants';
import { validate, verify, verifyRequired, isValidCardNumber, isValidCardCode, isValidCardExpiration } from 'utils/validation';
import { inferCardType } from 'utils/payment';

import css from './style.scss';

const YearOptions = getCcYearOptions();

const PaymentMethod = ({ model, onRef, onChange, allowSelect, profile }) => {
  const ref = useRef({});

  const validate = () => {
    const r = ref.current;
    const { cardNumber, cardCode, cardName, cardExpMonth, cardExpYear, billingStreetAddress, billingCity, billingState, billingZip, billingCountry, paymentMethodId } = model;

    if (paymentMethodId === '') {
      verifyRequired(r.cardNumber, cardNumber, 'Credit Card Number is required.');
      verify(r.cardNumber, isValidCardNumber(cardNumber), 'Credit Card Number is invalid.');
      verifyRequired(r.cardCode, cardCode, 'Credit Card CVV is required.');
      verify(r.cardCode, isValidCardCode(cardCode, cardNumber), 'Credit Card CVV is invalid.');

      verifyRequired(r.cardName, cardName, 'Name on Card is required.');
      verifyRequired(r.cardExpMonth, cardExpMonth, 'Expiration Month is required.');
      verifyRequired(r.cardExpYear, cardExpYear, 'Expiration Year is required.');

      const country = CountryMap[billingCountry];
      verify(r.cardExpMonth, isValidCardExpiration(cardExpYear, cardExpMonth), 'Credit card is expired.');
      verifyRequired(r.billingStreetAddress, billingStreetAddress, 'Billing Street Address is required.');
      verifyRequired(r.billingCity, billingCity, 'Billing City is required.');
      verifyRequired(r.billingState, billingState, `Billing ${country.stateLabel} is required.`);
      verifyRequired(r.billingZip, billingZip, `Billing ${country.postalCodeLabel} is required.`);
      verify(r.billingZip, !country.postalCodePattern || new RegExp(country.postalCodePattern, 'i').test(billingZip), `${country.postalCodeLabel} is invalid.`);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const stateChange = {};
    stateChange[name] = value;
    if (name === 'cardNumber') stateChange[name] = value.replace(/\D/g, '');
    else if (name === 'billingCountry') stateChange.billingState = '';
    onChange(stateChange);
  };

  const handleRef = (el) => {
    if (el) ref.current[(el.target || el).name] = el.focus ? el : el;
  };

  const { cardExpMonth, cardExpYear, cardName, cardNumber, cardNumberMasked, paymentMethodId, billingStreetAddress, billingStreetAddress2, billingCity, billingState, billingZip, billingCountry } = model;
  
  // Get country state list. If no country is selected, default to US.
  const country = CountryMap[billingCountry] || CountryMap.US;
  const stateOptions = (country.states || []).map(state => ({ label: state.name, value: state.code }));
  if (stateOptions.length) stateOptions.unshift({ value: '' });

  const { numberMask, codeMask } = inferCardType(cardNumber);

  const inputProps = { ref: handleRef, onChange: handleChange };
  const textProps = { ...inputProps, type: 'text', className: css.input };
  const expProps = { ...inputProps, className: classNames(css.dropdown, css.dropdownCardExpiration) };

  const paymentMethodOptions = (profile.paymentMethods || []).map(({ id, description }) => ({ label: description, value: id }));
  paymentMethodOptions.push({ label: 'Use New Credit Card', value: '' });

  return (
    <div className={css.paymentMethod}>
      {!allowSelect ? null : (
        <div className={css.section}>
          <div className={css.row}>
            <div className={css.field}>
              <label htmlFor="paymentMethod">Select Credit Card</label>
              <Dropdown {...inputProps} className={css.dropdown} name="paymentMethodId" options={paymentMethodOptions} value={paymentMethodId} onChange={handleChange} />
            </div>
          </div>
        </div>
      )}
      {paymentMethodId !== '' ? null : (
        <div>
          <div className={css.section}>
            <div className={css.row}>
              <div className={css.field}>
                <label htmlFor="card">Card Number*</label>
                <MaskedInput
                  {...inputProps}
                  {...(cardNumberMasked && cardNumberMasked.length ? { placeholder: cardNumberMasked } : {})}
                  mask={numberMask}
                  className={css.input}
                  name="cardNumber"
                  value={cardNumber}
                />
              </div>
              <div className={classNames(css.field, css.width1)}>
                <label htmlFor="cardCode">CVV*</label>
                <MaskedInput {...inputProps} mask={codeMask} className={css.input} name="cardCode" />
              </div>
            </div>
            <div className={css.row}>
              <div className={css.field}>
                <label htmlFor="credit-card-name">Name on Card*</label>
                <input {...textProps} name="cardName" value={cardName} />
              </div>
              <div className={classNames(css.field, css.width1)}>
                <label htmlFor="expiryMonth">Expiration Date*</label>
                <Dropdown {...expProps} name="cardExpMonth" options={CcMonthOptions} value={cardExpMonth} style={{ marginRight: '10px' }} />
                <Dropdown {...expProps} name="cardExpYear" options={YearOptions} value={cardExpYear} />
              </div>
            </div>
          </div>
          <div className={css.section}>
            <div className={css.row}>
              <div className={css.field}>
                <label htmlFor="billingStreetAddress">Billing Street Address*</label>
                <input {...textProps} name="billingStreetAddress" value={billingStreetAddress} />
              </div>
            </div>
            <div className={css.row}>
              <div className={css.field}>
                <label htmlFor="billingStreetAddress2">Billing Street Address 2</label>
                <input {...textProps} name="billingStreetAddress2" value={billingStreetAddress2} />
              </div>
            </div>
            <div className={css.row}>
              <div className={css.field}>
                <label htmlFor="billingCity">City*</label>
                <input {...textProps} name="billingCity" value={billingCity} />
              </div>
              <div className={css.field}>
                <label htmlFor="billingState">{country.stateLabel}*</label>
                {stateOptions.length ? <Dropdown {...inputProps} className={css.dropdown} name="billingState" value={billingState} options={stateOptions} /> : (
                  <input {...textProps} name="billingState" value={billingState} className={classNames(css.input, css.inputState)} />
                )}
              </div>
              <div className={css.field}>
                <label htmlFor="billingZip">{country.postalCodeLabel}*</label>
                <input {...textProps} name="billingZip" value={billingZip} />
              </div>
            </div>
            <div className={css.row}>
              <div className={css.field}>
                <label htmlFor="billingCountry">Country*</label>
                <Dropdown {...inputProps} name="billingCountry" className={classNames(css.dropdown, css.dropdownCountry)} options={CountryOptions} value={country.code} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


PaymentMethod.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  model: PropTypes.object.isRequired,
  onRef: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  allowSelect: PropTypes.bool,
};

PaymentMethod.defaultProps = {
  allowSelect: true,
};

export default connect(state => ({
  profile: selectProfile(state).toJS(),
}), {
  confirm: Confirm.open,
})(PaymentMethod);
