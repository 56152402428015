import React, { PureComponent } from 'react';


const stopEvent = (ev) => { ev.preventDefault(); ev.stopPropagation(); };

class GraphicDraggable extends PureComponent {
  constructor(props) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.endEdit = this.endEdit.bind(this);

    this.state = {
      editing: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      pageX: 0,
      pageY: 0,
    };
  }

  handleMouseMove(ev) {
    stopEvent(ev);

    const { onElementChange, translate, element, size, resizeX, resizeY } = this.props;
    const { x, y, width, height, pageX, pageY } = this.state;
    const moving = !(resizeX || resizeY);
    const maintainAspect = true; // ev.shiftKey;
    // const maintainIsotropic = false; // ev.shiftKey;
    const boundary = 25;
    // const aspect = width / height;

    const { canvasWidth, canvasHeight } = translate({ canvasWidth: size.width, canvasHeight: size.height });
    let { x: newX, y: newY, newWidth, newHeight } = translate({ x, y, newWidth: width || size.width, newHeight: height || size.height });
    if (!newWidth) newWidth = canvasWidth;
    if (!newHeight) newHeight = canvasHeight;

    const calcDelta = (diff, resize, coordinate, size, canvasSize) => {
      let delta = diff;

      if (moving) delta = Math.min(Math.max(delta, -(coordinate + size + boundary)), (canvasSize - coordinate) + boundary);
      else if (resize < 0) delta = Math.min(delta, size - 1);
      else if (resize > 0) delta = Math.max(delta, -size + 1);
      else delta = 0;

      return delta;
    };

    if (maintainAspect) {
      if (resizeX && resizeY) {
        // const aspect = width / height;
        // const aspect2 = newWidth / newHeight;
        //
        //
        // if (aspect < aspect2) newHeight = newWidth / aspect;
        // else newWidth = newHeight * aspect;
        //
      }
    }

    const deltaX = calcDelta(ev.pageX - pageX, resizeX, newX, newWidth, canvasWidth);
    const deltaY = calcDelta(ev.pageY - pageY, resizeY, newY, newHeight, canvasHeight);

    if (moving || resizeX < 0) newX += deltaX;
    if (!moving) newWidth += deltaX * resizeX;

    if (moving || resizeY < 0) newY += deltaY;
    if (!moving) newHeight += deltaY * resizeY;

    onElementChange(translate({ x: newX, y: newY, width: newWidth, height: newHeight }, false), element, true);
  }

  handleMouseUp(ev) {
    stopEvent(ev);

    this.endEdit(ev, true);
  }

  handleKeyDown(ev) {
    stopEvent(ev);

    if (ev.key === 'Escape') this.endEdit(ev, false);
  }

  handleMouseDown(ev) {
    stopEvent(ev);

    const { pageX, pageY, button } = ev;

    if (!button) {
      const { element: { x, y, width, height } } = this.props;

      this.setState({ editing: true, x, y, width, height, pageX, pageY });

      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('keydown', this.handleKeyDown);
      document.addEventListener('mouseup', this.handleMouseUp);
    } else if (this.state.editing) this.endEdit(ev, false);
  }

  endEdit(ev, update) {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mouseup', this.handleMouseUp);

    const { element, onElementChange, onSelectElement } = this.props;
    const { x, y, width, height, pageX, pageY } = this.state;

    if (!update) onElementChange({ x, y, width, height }, element);
    else if (ev.pageX === pageX && ev.pageY === pageY) onSelectElement(null);
    else onElementChange({}, element);  // Send so host can process a non-dragging "change" for recording undo/redo history.

    this.setState({ editing: false });
  }

  render() {
    const { style, className, children } = this.props;
    return <div onMouseDown={this.handleMouseDown} onClick={stopEvent} style={style} className={className}>{children}</div>;
  }
}

GraphicDraggable.defaultProps = {
  resizeX: 0,
  resizeY: 0,
};

export default GraphicDraggable;
