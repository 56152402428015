/** @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  removeAnalysisMortgage,
  addAnalysisMortgage,
  loadMortgageAmortizationReport,
} from 'data/analyses/actions';
import Confirm from 'app/components/Confirm';

import Layout from './Layout';


class Mortgage extends PureComponent {
  constructor(props) {
    super(props);
    this.handleEditMortgageClick = this.handleEditMortgageClick.bind(this);
    this.handleRemoveMortgageClick = this.handleRemoveMortgageClick.bind(this);
    this.handleAmortizeClick = this.handleAmortizeClick.bind(this);
  }

  /* :: handleEditMortgageClick : Function */
  handleEditMortgageClick(index) {
    this.props.onParamsChange({ name: 'mortgageEditIndex', value: index });
  }

  /* :: handleAmortizeClick: Function */
  handleAmortizeClick(mortgage) {
    this.props.loadMortgageAmortizationReport(mortgage);
  }

  /* :: handleRemoveMortgageClick: Function */
  handleRemoveMortgageClick(seq) {
    const { confirmAction, removeAnalysisMortgage } = this.props;
    confirmAction({
      question: 'Are you sure you want to remove this mortgage?',
      onOk: () => removeAnalysisMortgage(seq),
    });
  }

  render() {
    const analysisMortgages = this.props.analysisParams.mortgages;

    return (
      <Layout
        {...this.props}
        onEditMortgageClick={this.handleEditMortgageClick}
        onRemoveMortgageClick={this.handleRemoveMortgageClick}
        onAmortizeClick={this.handleAmortizeClick}
        showAddMortgageButton={analysisMortgages.length < 2}
        analysisMortgages={analysisMortgages}
      />
    );
  }
}

const mapDispatchToProps = {
  removeAnalysisMortgage,
  addAnalysisMortgage,
  loadMortgageAmortizationReport,
  confirmAction: Confirm.open,
};

export default connect(null, mapDispatchToProps)(Mortgage);
