/** @flow */
import React from 'react';
import { pure } from 'recompose';

import {
  getFormValues,
  getFormInitialValues,
  getFormSyncErrors,
  getFormMeta,
  getFormAsyncErrors,
  getFormSyncWarnings,
  getFormSubmitErrors,
  getFormNames,
  isDirty,
  isPristine,
  isValid,
  isInvalid,
  isSubmitting,
  hasSubmitSucceeded,
  hasSubmitFailed,
} from 'redux-form';

import Field from './Field';
import Form from './Form';
import createField from './createField';
import createForm from './createForm';
import createFormValueSelector from './createFormValueSelector';


export default function fabric(formName, options) {
  const FormRenderer = pure(props => <Form {...props} form={formName} />);

  return {
    Form: createForm(formName, options)(FormRenderer),
    createField,
    Field,
    selectField: createFormValueSelector(formName),
    selectFormValues: getFormValues(formName),
    selectFormInitialValues: getFormInitialValues(formName),
    selectFormSyncErrors: getFormSyncErrors(formName),
    selectFormMeta: getFormMeta(formName),
    selectFormAsyncErrors: getFormAsyncErrors(formName),
    selectFormSyncWarnings: getFormSyncWarnings(formName),
    selectFormSubmitErrors: getFormSubmitErrors(formName),
    selectFormNames: getFormNames(formName),
    selectIsDirty: isDirty(formName),
    selectIsPristine: isPristine(formName),
    selectIsValid: isValid(formName),
    selectIsInvalid: isInvalid(formName),
    selectIsSubmitting: isSubmitting(formName),
    selectHasSubmitSucceeded: hasSubmitSucceeded(formName),
    selectHasSubmitFailed: hasSubmitFailed(formName),
  };
}
