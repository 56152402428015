import classNames from "classnames";
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import AutoSearchDropdown from '../Autosearch';
import CustomDatePicker from '../DateRanger';
import css from "../style.scss";
import { formatDateShort } from 'utils/date/formatDate';

const SelectAnyoneByMinMax = ({ headerTitle, box2Placeholder, defaultValues, orText, options, onValueChange, headerClassName, isHighlightIndex }) => {
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    if (defaultValues?.min) {
      setMinValue(new Date(defaultValues?.min));
    } else {
      setMinValue(null);
    }
    if (defaultValues?.max) {
      setMaxValue(new Date(defaultValues?.max));
    } else {
      setMaxValue(null);
    }
  }, [defaultValues]);


  useEffect(() => {
    const defaultSelectedOption = options.find(option => {
      const optionMinDate = option?.fromDate ? formatDateShort(option.fromDate) : null;
      const optionMaxDate = option?.toDate ? formatDateShort(option.toDate) : null;
      const defaultMinDate = defaultValues?.min ? formatDateShort(defaultValues.min) : null;
      const defaultMaxDate = defaultValues?.max ? formatDateShort(defaultValues.max) : null;
      return (
        defaultMinDate === optionMinDate &&
        defaultMaxDate === optionMaxDate
      );
    });

    if (defaultSelectedOption && defaultSelectedOption.value) {
      setSelectedOption(defaultSelectedOption.value);
    } else {
      setSelectedOption('');
    }
  }, [defaultValues, options]);


  const onSelectedValue = (value) => {
    const selectedOption = options.find(option => option?.value === value);

    if (selectedOption && selectedOption?.id !== 0) {
      const fromDate = selectedOption?.fromDate ? new Date(selectedOption.fromDate) : null;
      const toDate = selectedOption?.toDate ? new Date(selectedOption.toDate) : null;
      onValueChange(fromDate, toDate);
      setSelectedOption(value)
    } else {
      // if user remove value from input
      onValueChange(null, null);
      setSelectedOption('')
    }
  };

  const handleSelectedDate = (date, type) => {
    if (type === 'min') {
      setMinValue(date);
      onValueChange(date, maxValue);
    } else if (type === 'max') {
      setMaxValue(date);
      onValueChange(minValue, date);
    }
  };


  return (
    <div key={headerTitle}>
      <Row className={css.clsCenterCol}>
        <Col className={css.clsCol}>
          {/* <h4 className={css.clsSubHeading}>{headerTitle}</h4> */}
          <h4 className={classNames(( headerClassName === "" ? css.clsSubHeading: headerClassName), (isHighlightIndex ? css.highlightOrange : ""))}>{headerTitle}</h4>
          <div className={css.flexBoxNowrap}>
            <CustomDatePicker
              name={'startDate'}
              placeholder="From"
              selected={minValue}
              onChange={date => handleSelectedDate(date, 'min')}
            />
            <CustomDatePicker
              name={'endDate'}
              placeholder="To"
              selected={maxValue}
              onChange={date => handleSelectedDate(date, 'max')}
            />
          </div>
        </Col>
        <Col className={css.clsCol}>
          <h4 className={css.clsSubHeading}>&nbsp;</h4>
          <p className={css.clsColTxt}>{orText}</p>
        </Col>
        <Col className={css.clsCol}>
          <h4 className={css.clsSubHeading}>&nbsp;</h4>
          <div className={classNames(css.flexBoxNowrap, css.clsFilterSelect)}>
            <AutoSearchDropdown items={options} placeHolder={box2Placeholder} defaultValue={selectedOption} onChangeValue={onSelectedValue}
              key={headerTitle} searchDisabled={true}
            />
          </div>
        </Col>
      </Row> 
    </div>
  );
};

export default SelectAnyoneByMinMax;
