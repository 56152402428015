import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import Button from 'components/base/Button';
import ButtonUpload from 'components/base/Button/ButtonUpload';
import Table, { Column } from 'components/base/Table';
import CellWithInput from 'components/base/Table/CellWithInput';
import { selectSavedAnalyses, loadAnalyses, loadSavedAnalysisReport } from 'data/analyses';
import { selectPremiumReports, selectPropertyReports } from 'data/user/selectors';
import ReportTypes from 'data/user/constants';
import Confirm from 'app/components/Confirm';
import Checkout from 'app/components/Checkout';
import ModalSelectTransaction from 'app/components/ModalSelectTransaction';
import ModalSelectAnalysis from 'app/components/ModalSelectAnalysis';
import numberToPrice from 'utils/currency/numberToPrice';
import Image from 'components/base/Image';
import formatServerUrl from 'utils/URL';
import formatDate from 'utils/date/formatDate';
import {
  uploadPropertyDocument,
  editPropertyDocument,
  purchaseReportAndLoad,
  loadPropertyDocuments,
  deletePropertyDocument,
  downloadPropertyDocument,
  downloadReport,
  selectDocuments,
  selectDocumentLoading,
} from 'data/property';

import { currencyRenderer } from '../Table';
import Panel from '../Panel';
import css from './style.scss';


const buttonRenderer = (val, coords, data, _, { buttons, loading }) => (
  <div className={css.buttonContainer}>
    <div>{formatDate(val)}</div>
    <div className={css.hiddenCell}>
      {buttons.filter(({ name }) => name !== 'Delete' || (data.get('editable') || data.get('typeDescription') === 'Premium Report')).map(({ name, onClick }) => (
        <Button key={name} kind={Button.kind.grayGhost} size={Button.size.small} onClick={() => onClick(data)} isLoading={loading}>{name}</Button>
      ))}
    </div>
  </div>
);

const inputRenderer = (val, { fieldPath }, data, _, { onSave }) => <CellWithInput keyPath={fieldPath} id={data.get('id')} data={data} onSave={onSave}>{val}</CellWithInput>;
const defaultPreviewUrl = '/assets/images/preview/assessor_map.png';

class PropertyDocuments extends PureComponent {
  constructor(props) {
    super(props);
    //console.log("props in constructor: ", props);

    this.handleUpload = this.handleUpload.bind(this);
    this.handlePurchase = this.handlePurchase.bind(this);
    this.handleUpdateDocument = this.handleUpdateDocument.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleViewClick = this.handleViewClick.bind(this);
    // //ID23-880 add savedpropertyId to state so it can be updated
    this.state = { previewUrl: defaultPreviewUrl, savedPropertyId: props.savedPropertyId };
  }

  componentDidMount() {
    const { loadPropertyDocuments, loadAnalyses, savedPropertyId } = this.props;
    if (savedPropertyId) {
      loadPropertyDocuments(savedPropertyId);
      loadAnalyses(savedPropertyId);
    }
  }
  //ID23-880 update the savedPropertyId in state object
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.savedPropertyId !== this.props.savedPropertyId) {
      this.setState({
        savedPropertyId: this.props.savedPropertyId
      });
    }
  }

  handleUpload({ value: { file } }) {
    const { uploadPropertyDocument, checkSavedProperty } = this.props;
    checkSavedProperty((savedPropertyId) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name.replace(/\.[^/.]+$/, ''));
      formData.append('description', '');
      uploadPropertyDocument(savedPropertyId, formData);
    });
  }

  purchaseReport(savedPropertyId, report, sale) {
    const { purchaseReportAndLoad } = this.props;
    const id = (sale && String(sale.id)) || null;

    purchaseReportAndLoad(savedPropertyId, report.get('code'), report.get('price'), id && id.startsWith('F') ? null : id, id && id.startsWith('F') ? id.substr(1) : null);
  }

  confirmPurchase(savedPropertyId, report, sale) {
    this.props.confirm({
      question: `This report costs ${numberToPrice(report.get('price'))}. Click "Purchase" to accept the charge. 
      If there is not a document available, you will not be charged.`,
      onOk: () => this.purchaseReport(savedPropertyId, report, sale),
      okLabel: 'Purchase',
    });
  }

  handlePurchase(report) {
    const { selectTransaction, property, checkSavedProperty, openCheckout } = this.props;

    const order = {
      lines: [{ description: 'Document Image', quantity: 1, total: 50 }],
      recipientTotal: 1,
      // message: 'Blah',
      success: false,
    };

    // openCheckout({ onConfirm: (a1, a2) => { console.log('save', a1, a2) }, selectLoading: selectDocumentLoading, order });
    // ID23-880 - use this.state.savedPropertyId instead of the parameter.
    checkSavedProperty((savedPropertyId) => {
      if (report.get('propertySaleRequired')) {
        selectTransaction({
          transactions: property.get('sales', List()).concat(property.getIn(['foreclosure', 'historicPreForeclosures'], List())).filter(sale => sale.get('documentImageAvailable')),
          onSelect: sale => this.confirmPurchase(this.state.savedPropertyId, report, sale),
        });
      } else this.confirmPurchase(this.state.savedPropertyId, report);
    });

  }

  handleUpdateDocument(document) {
    this.props.editPropertyDocument(document);
  }

  handleRowClick(event, data) {
    this.setState({ previewUrl: data.get('previewUrl') });
  }

  handleDeleteClick(data) {
    const { confirm, deletePropertyDocument } = this.props;
    confirm({
      question: 'Are you sure you want to delete this document?',
      onOk: () => deletePropertyDocument(data.get('id'), () => this.setState({ previewUrl: defaultPreviewUrl })),
    });
  }

  loadAnalysisReport(analysis, reportCode) {
    this.props.loadSavedAnalysisReport(analysis.get('id'), reportCode);
  }

  handleViewClick(data) {
    const { propertyId, savedPropertyId, downloadReport, analyses, selectAnalysis, confirm } = this.props;
    const { id, code, type } = data.toJS();
    const propertyAnalyses = analyses.filter(analysis => analysis.get('savedPropertyId') === savedPropertyId);

    if (id) this.props.downloadPropertyDocument(id);
    else if (type === ReportTypes.analysis) {
      if (!propertyAnalyses.size) confirm({ cancelLabel: null, question: 'This property has never been analyzed. Please save an analysis prior to running this report.' });
      else if (propertyAnalyses.size === 1) this.loadAnalysisReport(propertyAnalyses.get(0), code);
      else selectAnalysis({ analyses: propertyAnalyses, onSelect: analysis => this.loadAnalysisReport(analysis, code) });
    } else downloadReport(propertyId, code);
  }

  render() {
    const { premiumReports, propertyReports, documents, loading } = this.props;
    const inputProps = { renderCell: inputRenderer, extraProps: { onSave: this.handleUpdateDocument } };

    return (
      <Panel>
        <div className={css.row}>
          <div className={css.left}>
            <div className={css.justifyContent}>
              <div className={css.title}>Documents & Reports</div>
              <ButtonUpload className={css.btn} size={Button.size.small} loading={loading} onChange={this.handleUpload} document={false}>Add Document</ButtonUpload>
            </div>
            <div className={css.tableDocument}>
              <Table
                data={propertyReports.map(r => r.set('key', r.get('code'))).concat(documents.map(d => d.set('key', d.get('id'))))}
                keyField="key"
                isHoverable
                className="type_9"
                onRowClick={this.handleRowClick}
              >
                <Column {...inputProps} field="name">Name</Column>
                <Column {...inputProps} field="description">Description</Column>
                <Column field="typeDescription">Type</Column>
                <Column
                  renderCell={buttonRenderer}
                  field="createDate"
                  style={{ paddingRight: '200px' }}
                  extraProps={{
                    loading,
                    buttons: [
                      { name: 'Delete', onClick: this.handleDeleteClick },
                      { name: 'View', onClick: this.handleViewClick },
                    ],
                  }}
                >Date Added</Column>
              </Table>
            </div>
          </div>
          <div className={css.right}>
            <div className={css.justifyContent}>
              <div className={css.title}>Premium Reports</div>
            </div>
            <div className={css.mapPreview}>
              <Image src={formatServerUrl(this.state.previewUrl)} alt="Report Preview" />
            </div>
            <div className={css.tableDocument}>
              <Table data={premiumReports} keyField="code" isHoverable className="type_9" onRowClick={this.handleRowClick}>
                <Column field="name">Name</Column>
                <Column field="price" renderCell={currencyRenderer}>Price</Column>
                <Column field="editButtons" renderCell={buttonRenderer} extraProps={{ loading, buttons: [{ name: 'Purchase', onClick: this.handlePurchase }] }} />
              </Table>
            </div>
          </div>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    premiumReports: selectPremiumReports(state),
    propertyReports: selectPropertyReports(state),
    documents: selectDocuments(state),
    loading: selectDocumentLoading(state),
    analyses: selectSavedAnalyses(state),
  };
}

const mapActionToProps = {
  uploadPropertyDocument,
  editPropertyDocument,
  purchaseReportAndLoad,
  deletePropertyDocument,
  loadPropertyDocuments,
  downloadPropertyDocument,
  downloadReport,
  loadAnalyses,
  loadSavedAnalysisReport,
  selectTransaction: ModalSelectTransaction.open,
  selectAnalysis: ModalSelectAnalysis.open,
  confirm: Confirm.open,
  openCheckout: Checkout.open,
};

export default connect(mapStateToProps, mapActionToProps)(PropertyDocuments);
