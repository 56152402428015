import { PureComponent } from 'react';
import { connect } from 'react-redux';

import urlSubscribe from '../Detail/urlSubscribe';

import Modal from './Gallery';


export class PropertyGalleryLoader extends PureComponent {
  componentDidMount() {
    const { params, openPopup, urls, history } = this.props;
    openPopup({ urls, params, onClose: () => history.push(`${urls.getState().detailsRoot}`) });
  }

  componentWillUnmount() {
    this.props.closePopup();
  }

  render() {
    return this.props.children;
  }
}

export default connect(null, { openPopup: Modal.open, closePopup: Modal.close })(urlSubscribe(PropertyGalleryLoader));
