import React, { PureComponent } from 'react';
import pluralize from 'pluralize';

import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import { getBrandName } from 'utils/brand';
import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';

import css from './style.scss';


class LaunchControlConfirmation extends PureComponent {
  constructor(props) {
    super(props);

    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  handleLoginClick() {
    const { redirectUrl, closePopup } = this.props;

    const newWindow = window.open(redirectUrl, 'sms');
    if (newWindow) newWindow.opener = null;

    closePopup();
  }

  render() {
    const { closePopup, leadQuantity } = this.props;

    return (
      <Modal isOpen uniqId="modalLaunchControlConfirmation" width="770px" padding="30px 50px">
        <div className={css.launchControl}>
          <div className={css.header}>{pluralize('property', leadQuantity, true)} were successfully transferred to Launch Control for texting</div>
          <div className={css.body}>*Only properties that are linked with mobile phone numbers are successfully transferred.</div>
          <div className={css.footer}>
            <SolidButton onClick={this.handleLoginClick}>Open Launch Control</SolidButton>
            <Button onClick={closePopup}>Stay with {getBrandName()}</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const LaunchControlConfirmationPopup = LaunchControlConfirmation;

const registrationId = getPopupRegistration(LaunchControlConfirmationPopup);
LaunchControlConfirmationPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });

export default LaunchControlConfirmationPopup;
