import { PureComponent } from 'react';
import { connect } from 'react-redux';

import urlSubscribe from 'app/Property/Detail/urlSubscribe';

import OfferWizardPopup from './offer';


export class OfferWizardLoader extends PureComponent {
  componentDidMount() {
    const { params, openPopup, history, routes, urls, location } = this.props;
    openPopup({
      onClose: () => history.push(urls.getState().detailsRoot),
      urls,
      params: { ...params },
      history,
      location,
      route: routes[routes.length - 1] || {},
    });
  }

  componentWillUnmount() {
    this.props.closePopup();
  }

  render() {
    return null;
  }
}

OfferWizardLoader.defaultProps = {
  params: {},
  history: {},
};

export default connect(null, {
  openPopup: OfferWizardPopup.open,
  closePopup: OfferWizardPopup.close,
})(urlSubscribe(OfferWizardLoader));
