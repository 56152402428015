import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { List, Map } from 'immutable';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';
import LeftPart from 'app/components/LeftPart';
import ToggleList from 'app/components/ToggleList';
import { campaignPath } from 'app/routes';
import RestrictedSection from 'app/components/RestrictedSection';
import ActivityPopup from "./Dashboard/ActivityPopup";

import { loadCampaign } from 'data/campaigns/actions';
import { selectCampaign, selectCampaigns, selectLoading } from 'data/campaigns/selectors';

import css from './style.scss';
import CampaignEditor from './CampaignEditor';
import Dashboard from './Dashboard';
import Email from './Email';
import EmailSettings from './Email/Settings';
import WebsiteSettings from './Website/Settings';
import Website from './Website';
import Postcard from './Postcard';
import CampaignCreated from './Dashboard/CampaignCreated';
import NoCampaigns from './NoCampaigns';


const redirect = (props) => {
  const { loading, campaign, loadCampaign, history, activeCampaigns, campaigns, location: { pathname } } = props;

   const regex = /campaign\/(\d+)(?:\/email\/(\d+))?/;
   const matches = location.pathname.match(regex);
   let campaignId = matches && matches.length > 0 ? matches[1] : 0
   
  if (!loading && campaigns) {
    const targetId = campaignId == 0 ? 0 : (campaigns.find(c => c.get('id') === Number(campaignId)) || Map()).get('id');

    if (targetId && campaign.get('id') !== targetId) loadCampaign(targetId);
    
    const defaultPath = campaignPath(activeCampaigns.getIn([0, 'id']));
    setTimeout(() => {
      if (!targetId && pathname.includes('campaign') && !pathname.includes('postcard') && pathname !== campaignPath('new')) {
        if (activeCampaigns.getIn([0, 'id'])) {
          history.push(`${defaultPath}`)
        }
      }
    }, 1000)
  }
};

class Campaigns extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAddClick = this.handleAddClick.bind(this);
  }

  componentWillMount() {
    redirect(this.props);
  }

  componentWillReceiveProps(props) {
    redirect(props);
  }

  handleAddClick() {
    this.props.history.push(campaignPath('new'));
  }

  render() {
    const { activeCampaigns, inactiveCampaigns } = this.props;
    const campaignsPath = this.props?.location?.pathname?.split('/');
    const campaignId = campaignsPath[campaignsPath.length - 1];
    return (
      <div className={`${css.campaigns} ${css.container}`}>
        <Helmet title="Campaigns" />
        <RestrictedSection />
        <div className={css.body} id={`campaign-${JSON.stringify(this.props.campaign?.id)}`}>
          <div className={css.leftSide}>
            <LeftPart caption="Marketing Campaigns" onAddClick={this.handleAddClick} addLabel="New Campaign +" headerClass={css.header}>
              <ToggleList data={activeCampaigns} caption="Active Campaigns" selectedId={campaignId} location={campaignPath()} />
              <ToggleList data={inactiveCampaigns} caption="Inactive Campaigns" selectedId={campaignId} location={campaignPath()} />
            </LeftPart>
          </div>
          <div className={css.rightSide}>
            {this.props.location.pathname.includes('new') ? <CampaignEditor /> : 
              this.props.campaigns && this.props.campaigns.size > 0 ?
              <Dashboard>
                {this.props.location.pathname.includes('created') && <CampaignCreated />}
                {this.props.location.pathname.includes('activity') && <ActivityPopup />}
                {this.props.location.pathname.includes('email') && <Email />}
                {this.props.location.pathname.includes('email') && this.props.location.pathname.includes('settings') && <Email><EmailSettings /></Email>}
                {this.props.location.pathname.includes('website') && <Website />}
                {this.props.location.pathname.includes('website/settings') && <Website><WebsiteSettings /></Website>}
                {this.props.location.pathname.includes('postcard') && <Postcard />}
              </Dashboard>
              : this.props.location.pathname === '/campaign' && <NoCampaigns />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect((state) => {
  const campaigns = selectCampaigns(state);

  return {
    campaigns,
    campaign: selectCampaign(state),
    activeCampaigns: (campaigns || List()).filter(c => c.get('active')),
    inactiveCampaigns: (campaigns || List()).filter(c => !c.get('active')),
    loading: selectLoading(state),
  };
}, {
  loadCampaign,
})(Campaigns));