import React, { useState } from 'react';
import config from 'config';
import Panel from '../Panel';
import LoadablePanel from 'components/hoc/loadable/Panel';
import css from './style.scss';
import classNames from 'classnames';
import BarChart from '../../../components/BarChart';
import DonutChart from '../../../components/DonutChart';
import { useEffect } from 'react';
import formatFullAddressObject from 'utils/address/formatAddress';

import Commute_Icon from '../../../../assets/images/demographics_commute_icon.svg';
import Owner_Renter_Icon from '../../../../assets/images/demographics_owners_renters_icon.svg';
import Income_Icon from '../../../../assets/images/demographics_income_icon.svg';
import Household_Icon from '../../../../assets/images/demographics_household_composition_icon.svg';
import Education_Icon from '../../../../assets/images/demographics_highest_education_level_icon.svg';
import { Col, Row } from 'react-bootstrap';

const {
  constants: {
    API: { DEMOGRAPHICS_URL },
  },
} = config;

const Demographics = ({property, profile}) => {

    const [demographicsData, setDemographicsData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [totalPopulation, setTotalPopulation] = useState('');
    const [populationData, setPopulationData] = useState([]);
    const [propertyAddress, setPropertyAddress] = useState('');

    useEffect(()=>{
        fetchDemographicsData();
    },[]);

  const fetchDemographicsData = () =>{
    const address = property.get("address");

    let requestData = {
      "lat": property.get("latitude"),
      "lng": property.get("longitude"),
      "userId": profile.get("id"),
      "propertyId": property.get("id"),
      "propertyAddress": address.get("title")
    };

    setPropertyAddress(address.get('streetNumber') + ' ' + address.get('streetName') + ' ' + address.get('streetSuffix'));

    fetch(`${DEMOGRAPHICS_URL}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestData)
    })
    .then(response => {
      if(response.ok) return response.json();
      throw 'Error';
    })
    .then((response)=>{
      setDemographicsData(response);
      setLoading(false);
      setTotalPopulation(response.population_total.variables[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
      let populationVars = response.population_age.variables;
      let population = [];
      for(let i=0; i< populationVars.length; i=i+2){
        let data = {
          value: populationVars[i].value + populationVars[i+1].value,
          label: populationVars[i].label.split(' to ')[0] + (populationVars[i+1].label.includes('and')?'+':' to '+populationVars[i+1].label.split(' to ')[1])
        };
        population.push(data);        
      }
      setPopulationData(population);
      console.log('Original data: ',response.population_age.variables);
      console.log('Combined data: ', populationData);
    })
    .catch(error=>{
        setLoading(false);
        setIsError(true);
    });
  }

    if(loading){
      return (
        <div>
          <Panel className={classNames(css.halfPanel, css.first, css.loading)}>                
            <LoadablePanel loading={loading}></LoadablePanel>
          </Panel>
          <Panel className={classNames(css.halfPanel,css.barchartPanel, css.loading)}>
            <LoadablePanel loading={loading}></LoadablePanel>
          </Panel>
          <Panel className={classNames(css.halfPanel, css.first, css.loading)}>                
            <LoadablePanel loading={loading}></LoadablePanel>
          </Panel>
          <Panel className={classNames(css.halfPanel,css.barchartPanel, css.loading)}>
            <LoadablePanel loading={loading}></LoadablePanel>
          </Panel>
          <Panel className={classNames(css.halfPanel, css.first, css.loading)}>                
            <LoadablePanel loading={loading}></LoadablePanel>
          </Panel>
          <Panel className={classNames(css.halfPanel, css.loading)}>                
            <LoadablePanel loading={loading}></LoadablePanel>
          </Panel>
          <Panel className={classNames(css.halfPanel, css.first, css.loading)}>                
            <LoadablePanel loading={loading}></LoadablePanel>
          </Panel>
          <Panel className={classNames(css.halfPanel, css.loading)}>                
            <LoadablePanel loading={loading}></LoadablePanel>
          </Panel>
        </div>
      );
    } else if(isError){
      return(
        <div>
          <h3>No Data Found.</h3>
          <h4>At this time the demographic information for this property is unavailable. Please check back soon.</h4>
        </div>
      );
    } else {

    return (
          <div>
            <Panel className={css.panel}>
              <p className={css.title}>
                All Demographics information is within 1 mile of <span>{formatFullAddressObject(property.get('address'), true)}</span>
              </p>
            </Panel>
            <Panel className={classNames(css.halfPanel, css.first)}>                
                <DonutChart 
                  data={demographicsData.property_tenure.variables} 
                  title={'Percentage of Owners vs Renters'}
                  image={Owner_Renter_Icon}
                />
            </Panel>
            <Panel className={classNames(css.halfPanel,css.barchartPanel)}>
                <p className={css.title}>Type of Housing (%)</p>
                <BarChart 
                    data={demographicsData.housing_type.variables}
                    bottomMargin={45}
                />
            </Panel>
            <Panel className={classNames(css.halfPanel, css.first)}>                
                <DonutChart 
                    data={demographicsData.employment_status.variables} 
                    title={'Employment Level'} 
                    additionalTile = {'Average Income'}
                    additonalData = {demographicsData.income_stats.variables}
                    image={Income_Icon}
                />
            </Panel>
            <Panel className={classNames(css.halfPanel,css.barchartPanel)}>
                <p className={css.title}>Income Brackets (%)</p>
                <BarChart 
                    data={demographicsData.income_level.variables}
                    bottomMargin={60}
                />
            </Panel>
            <Panel className={classNames(css.halfPanel, css.first)}>                
                <DonutChart 
                  data={demographicsData.household_composition.variables} 
                  title={'Household Composition'} 
                  image={Household_Icon}
                />
            </Panel>
            <Panel className={css.halfPanel}>
              <div>
                <Row>
                  <Col sm={6}>
                    <p className={css.title}>Total Population by Age Group</p>
                  </Col>
                  <Col sm={6}>
                    <p className={classNames(css.title, css.right)}>Estimated Population: <span>{totalPopulation}</span></p>
                  </Col>
                </Row>
              </div>                
                
                <BarChart 
                    data={populationData}
                    tooltipText={'years'}
                    bottomMargin={25}
                />
            </Panel>
            <Panel className={classNames(css.halfPanel, css.first)}>                
                <DonutChart 
                  data={demographicsData.education_level.variables} 
                  title={'Highest Education Level Achieved'} 
                  image={Education_Icon}
                />
            </Panel>
            <Panel className={css.halfPanel}>                
                <DonutChart 
                  data={demographicsData.commute_mode.variables} 
                  title={'Commute'} 
                  sort = {true}
                  image = {Commute_Icon}
                />
            </Panel>
        </div>
    );
  }
}

export default Demographics;
