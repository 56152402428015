import React from 'react';
import { pure } from 'recompose';

import Accordion from 'components/base/Accordion';
import Radio from 'components/base/Radio';
import AdditionalField from 'components/base/AdditionalField';
import InputDate from 'components/base/InputDate';
import FormControlWraper from 'components/base/FormControlWraper';
import {
  MortgageTypes,
  MortgageTypeValues,
  MortgageTypeInputs,
  BooleanValues,
  MortgageFrequencyValues,
  ArmTypeValues,
  ArmTypes,
  ArmHybridCustomType,
  ArmHybridTypeValues,
  ArmRateCapTypeValues,
} from 'data/analyses/constants';
import CustomInput from '../CustomInput';
import CurrencyInput from '../CurrencyInput';
import PercentInput from '../PercentInput';
import CustomSelect from '../CustomSelect';
import YearMonthInput from '../YearMonthInput';
import css from '../style.scss';


const Mortgage = (props) => {
  const { onChange, onNumberChange, analysisMortgageParams: m, onBooleanChange } = props;
  const currentMortgageTypeInputs = MortgageTypeInputs[m.type] || {};

  return (
    <div className={css.root}>
      <div className={css.mortgageHeader}>{m.ordinal} Mortgage</div>
      <Accordion caption="Type" isOpened isLarge>
        <div className={css.label}>What type of mortgage is this?</div>
        <div className={css.row}>
          {MortgageTypeValues.map(option => (
            <div className={css.col_2} key={option.label}>
              <Radio {...option} name="type" onChange={onChange} checked={m.type === option.value} />
            </div>
            ))}
        </div>
      </Accordion>
      <Accordion caption="Amount" isOpened isLarge>
        <div className={css.row}>
          <div className={css.col_5}>
            <CurrencyInput label="What is the mortgage amount?" name="amount" value={m} onChange={onNumberChange} />
          </div>
          <div className={css.col_7}>
            <CurrencyInput label="How much is the down payment?" name="downPaymentAmount" value={m} onChange={onNumberChange} />
          </div>
        </div>
      </Accordion>
      {!currentMortgageTypeInputs.fixedInterest ? null : (
        <Accordion caption="Fixed Interest" isOpened isLarge>
          <div className={css.row}>
            <div className={css.col_5}>
              <YearMonthInput label="What is the fixed rate term of your mortgage?" name="fixedInterestTerm" value={m} onChange={onNumberChange} />
            </div>
            <div className={css.col_7}>
              <PercentInput label="What is the fixed rate of your mortgage?" name="fixedInterestRate" value={m} onChange={onNumberChange} />
            </div>
          </div>
        </Accordion>
      )}
      {!currentMortgageTypeInputs.interestOnly ? null : (
        <Accordion caption="Interest Only" isOpened isLarge>
          <div className={css.row}>
            <div className={css.col_5}>
              <YearMonthInput label="What is the interest only term of your mortgage?" name="interestOnlyTerm" value={m} onChange={onNumberChange} />
            </div>
            <div className={css.col_7}>
              <PercentInput label="What is the interest rate of your mortgage?" name="interestOnlyRate" value={m} onChange={onNumberChange} />
            </div>
          </div>
        </Accordion>
      )}
      {m.type !== MortgageTypes.accelerated ? null : (
        <Accordion caption="Acceleration" isOpened isLarge>
          <div className={css.row}>
            <div className={css.col_5}>
              <div className={css.label}>Will you be making bi-weekly payments?</div>
              <div className={css.row}>
                {BooleanValues.map(option => (
                  <div className={css.col_3} key={option.label}>
                    <Radio {...option} name="biWeekly" onChange={onBooleanChange} checked={m.biWeekly === option.value} />
                  </div>
                  ))}
              </div>
            </div>
            <div className={css.col_7}>
              <div className={css.label}>Will you be making extra payments?</div>
              <div className={css.row}>
                {BooleanValues.map(option => (
                  <div className={css.col_3} key={option.label}>
                    <Radio {...option} name="extraPayments" onChange={onBooleanChange} checked={m.extraPayments === option.value} hasArrow={option.value} />
                  </div>
                  ))}
              </div>
            </div>
          </div>
          <AdditionalField isActive={m.extraPayments}>
            <div className={css.row}>
              <div className={css.col_5}>
                <CurrencyInput label="Extra Payment Amount" name="extraPaymentAmount" value={m} onChange={onNumberChange} />
              </div>
              <div className={css.col_7}>
                <div className={css.label}>Extra Payment Frequency</div>
                <div className={css.row}>
                  {MortgageFrequencyValues.map(option => (
                    <div className={css.col} key={option.label}>
                      <Radio {...option} name="extraPaymentFrequency" onChange={onChange} checked={m.extraPaymentFrequency === option.value} />
                    </div>
                    ))}
                </div>
              </div>
            </div>
          </AdditionalField>
        </Accordion>
      )}
      <Accordion caption="Advanced" isOpened isLarge>
        <div className={css.row}>
          <div className={css.col_5}>
            <div className={css.label}>What is the first mortgage payment date?</div>
            <div className={css.row}>
              <FormControlWraper id="firstPaymentDate" className={css.date}>
                <InputDate
                  name="firstPaymentDate"
                  onChange={onChange}
                  value={m.firstPaymentDate}
                  selectsStart
                  isClearable
                  showYearDropdown
                />
              </FormControlWraper>
            </div>
          </div>
          <div className={css.col_7}>
            <div className={css.label}>What frequency is the interest compounded?</div>
            <div className={css.row}>
              {MortgageFrequencyValues.map(option => (
                <div className={css.col} key={option.label}>
                  <Radio {...option} name="compoundFrequency" onChange={onChange} checked={m.compoundFrequency === option.value} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={css.row}>
          <div className={css.col_5}>
            <div className={css.label}>Will there be a balloon payment?</div>
            <div className={css.row}>
              {BooleanValues.map(option => (
                <div className={css.col_3} key={option.label}>
                  <Radio {...option} name="balloon" onChange={onBooleanChange} checked={m.balloon === option.value} hasArrow={option.value} />
                </div>
              ))}
            </div>
          </div>
          <div className={css.col_7}>
            <div className={css.label}>Will there be an adjustable rate mortgage?</div>
            <div className={css.row}>
              {BooleanValues.map(option => (
                <div className={css.col_3} key={option.label}>
                  <Radio {...option} name="adjustableRate" onChange={onBooleanChange} checked={m.adjustableRate === option.value} hasArrow={option.value} />
                </div>
                ))}
            </div>
          </div>
        </div>
        <AdditionalField isActive={m.adjustableRate || m.balloon}>
          <div className={css.row}>
            <div className={css.col_3}>
              {!m.balloon ? null : <CustomInput label="After How Many Payments?" name="balloonPaymentNumber" value={m} onChange={onNumberChange} />}
            </div>
            <div className={css.col_9}>
              {!m.adjustableRate ? null : (<div>
                <div className={css.row}>
                  <div className={css.col_4}>
                    <CustomSelect label="Adjustable Rate Type" name="armType" options={ArmTypeValues} onChange={onChange} value={m} />
                  </div>
                  <div className={css.col_4}>
                    <CustomSelect label="Hybrid ARM Type" name="armHybridType" options={ArmHybridTypeValues} onChange={onChange} value={m} disabled={m.armType === ArmTypes.standard} />
                  </div>
                </div>
                <div className={css.row}>
                  <div className={css.col_4}>
                    <CustomInput
                      label="Fixed Rate Term"
                      name="armFixedInterestTerm"
                      value={m}
                      onChange={onNumberChange}
                      disabled={m.armType === ArmTypes.standard || (m.armType === ArmTypes.hybrid && m.armHybridType !== ArmHybridCustomType)}
                    />
                  </div>
                  <div className={css.col_5}>
                    <CustomInput
                      label="Rate Change Interval"
                      name="armRateIncreaseTerm"
                      value={m}
                      onChange={onNumberChange}
                      disabled={m.armType === ArmTypes.hybrid && m.armHybridType !== ArmHybridCustomType}
                    />
                  </div>
                  <div className={css.col_3}>
                    <PercentInput label="Expected Rate Change" name="armIncreaseRate" value={m} onChange={onNumberChange} />
                  </div>
                </div>
                <div className={css.row}>
                  <div className={css.col_12}>
                    <div className={css.label}>Lifetime Rate Cap:</div>
                  </div>
                  <div className={css.col_12}>
                    {ArmRateCapTypeValues.map(option => (
                      <div className={css.flexContainer} key={option.label}>
                        <Radio onChange={onChange} name="armRateCapType" label={`${option.label}:`} value={option.value} checked={m.armRateCapType === option.value} />
                        <CustomInput
                          type={option.value === 'RATE' ? 'percent' : 'currency'}
                          name={option.field}
                          value={m[option.field]}
                          onChange={onNumberChange}
                          disabled={m.armRateCapType !== option.value}
                          showSymbol
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </AdditionalField>
      </Accordion>
    </div>
  );
};

export default pure(Mortgage);
