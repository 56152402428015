/** @flow */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { getPopupClass } from './popupRepository';


function getBackdrop() {
  return <div className="fade" />;
}

function getPopupContent(popupId, popupProps, rest) {
  const PopupClass = getPopupClass(popupId);
  return PopupClass ? <PopupClass {...rest} {...popupProps} /> : null;
}

const noop = () => {};

class PopupLayerHolder extends PureComponent {
  /* :: lastCloseCb: ?Function */
  constructor(props) {
    super(props);
    this.lastCloseCb = null;
    this.handleClose = this.handleClose.bind(this);
  }

  getChildContext() {
    return {
      closePopup: this.handleClose,
    };
  }

  componentDidUpdate(oldProps) {
    const oldPopup = oldProps.layer.getIn([0]);
    const popup = this.props.layer.getIn([0]);

    if (oldPopup && oldPopup !== popup) {
      const onClose = oldPopup.getIn(['props', 'onClose'], noop);
      this.close(onClose, { manual: false });
    }
  }

  /* :: handleClose: Function */
  handleClose() {
    const popup = this.props.layer.getIn([0]);

    const { closePopup } = this.props;

    if (popup) {
      const onClose = popup.getIn(['props', 'onClose'], noop);
      closePopup({ manual: true });
      this.close(onClose, { manual: true });
    }
  }

  close(onClose, ...args) {
    if (this.lastCloseCb === onClose) return;
    this.lastCloseCb = onClose;
    this.lastCloseCb(...args);
  }

  render() {
    const { layer, style } = this.props;
    const currentPopupId = layer && layer.getIn([0, 'id']);
    const props = layer && layer.getIn([0, 'props']);

    if (!currentPopupId) return null;

    const content = getPopupContent(currentPopupId, { closePopup: this.handleClose }, props.toJS());
    const backdrop = getBackdrop();

    return (
      <div style={style}>
        {backdrop}
        {content}
      </div>
    );
  }
}

const selectStyle = createSelector(
  priority => priority,
  priority => ({ zIndex: 10 + (10 * priority) }),
);

function mapStateToProps(state, ownProps) {
  return {
    style: selectStyle(ownProps.priority),
  };
}

PopupLayerHolder.propTypes = {
  closePopup: PropTypes.func.isRequired,
};

PopupLayerHolder.childContextTypes = {
  closePopup: PropTypes.func.isRequired,
};

export { PopupLayerHolder };
export default connect(mapStateToProps)(PopupLayerHolder);
