import React, { Component } from 'react';
import classNames from 'classnames';
import Confirm from 'app/components/Confirm';
import { selectProperty, purchaseReportAndLoad, selectDocumentLoading } from 'data/property';
import { selectPremiumReports } from 'data/user/selectors';
import withProperty from 'app/components/withProperty';
import numberToPrice from 'utils/currency/numberToPrice';

import css from './style.scss';


class DocumentImageCellRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { data: { id, internalId }, purchaseReportAndLoad, confirm, alert, report, checkSavedProperty } = this.props;

    checkSavedProperty(() => {
      confirm({
        question: `This report costs ${numberToPrice(report.get('price'))}. Click "Purchase" to accept the charge. 
        If there is not a document available, you will not be charged.`,
        onOk: () => purchaseReportAndLoad(this.props.savedPropertyId, report.get('code'), report.get('price'), internalId, internalId ? null : id.substr(1), () => alert('Purchase successful! You can retrieve this report in the future via the Documents & Reports tab.')),
        okLabel: 'Purchase',
      });
    });
  }

  render() {
    const { value, loading, documentLoading, data: { documentImageAvailable } = {} } = this.props;

    return (
      <div>
        <div className={classNames(css.documentTypeImage, { [css.loading]: loading || documentLoading })}>
          <div>{value}</div>
          {!documentImageAvailable ? null : <div onClick={this.handleClick}><img className={css.image} src={'/assets/images/pdf.png'} alt="PDF" /></div>}
        </div>
      </div>
    );
  }
}

export default withProperty(DocumentImageCellRenderer, state => ({
  property: selectProperty(state),
  documentLoading: selectDocumentLoading(state),
  report: selectPremiumReports(state).find(r => r.get('code') === 'DOCUMENT_IMAGE'),
}), {
  purchaseReportAndLoad,
  confirm: Confirm.open,
  alert: Confirm.alert,
});
