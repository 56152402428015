import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';
import Table, { Column } from './';


class ExpandedComp extends PureComponent {
  render() {
    const {
      data,
      index,
      columnsMeta,
    } = this.props;

    return (
      <div>
        <p>
          data: {data}
        </p>
        <p>
          index: {index}
        </p>
        <div>
          columnsMeta:
          <pre>
            <code>
              {JSON.stringify(columnsMeta, null, 2)}
            </code>
          </pre>
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
export default class Example extends PureComponent {
  render() {
    const data = fromJS([
      { field1: 'sadsa', field2: 'val1 field2' },
      { field1: 'val2 field1', field2: 'val2 field2' },
      { field1: 'val3 field1', field2: 'val3 field2' },
      { field1: 'val4 field1', field2: 'val4 field2' },
      { field1: 'val5 field1', field2: 'val5 field2' },
      { field1: 'val6 field1' },
      { field1: 'val7 field2' },
      {},
    ]);
    return (
      <Table
        data={data}
        expandableRow={rowIndex => rowIndex === 2}
        expandComponent={ExpandedComp}
        type={1}
      >
        <Column field="field1" isKey defaultValue="default field1 value">
          My column1
        </Column>
        <Column field="field2" defaultValue="default field2 value">
          <span>My column2</span>
        </Column>
        <Column field="field3" />
      </Table>
    );
  }
}
