import { combineReducers } from 'redux-immutable';
import { reducer as form } from 'redux-form/immutable';

import app from 'app/reducer';
import search from 'data/search';
import property from 'data/property';
import user from 'data/user';
import contracts from 'data/contracts';
import campaigns from 'data/campaigns';
import errors from 'data/errors';
import analyses from 'data/analyses';
import contacts from 'data/contacts';

import routing from './routing';


const treeReducer = combineReducers({
  form,
  app,
  property,
  search,
  routing,
  user,
  contracts,
  campaigns,
  errors,
  analyses,
  contacts,
});

const order = [treeReducer];

export default function rootReducer(state, action) {
  return order.reduce((currentState, reducer) => reducer(currentState, action), state);
}
