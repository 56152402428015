import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CurrencyInput from './CurrencyInput';


class DetailsInput extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { prop, detail: { field: { id } }, onDetailsChange } = this.props;
    onDetailsChange(event, prop, id);
  }

  render() {
    const { detail, className, annual } = this.props;
    const { field, amount } = detail; // paidBySeller
    const { name } = field;

    // Removing for now until we figure out layout.
    /*
    const checkbox = 'paidBySeller' in detail ? (<Checkbox
      name="paidBySeller"
      label="Paid By Seller"
      checked={paidBySeller}
      onChange={this.handleChange}
    />) : null;
    */

    return (
      <div className={className}>
        <CurrencyInput
          label={name}
          name="amount"
          isInline
          value={amount}
          factor={annual ? 1 / 12 : null}
          onChange={this.handleChange}
        />
        {/* checkbox */}
      </div>
    );
  }
}

DetailsInput.propTypes = {
  prop: PropTypes.string,
  annual: PropTypes.bool,
  onDetailsChange: PropTypes.func,
};

export default DetailsInput;
