import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';
import PropTypes from 'prop-types';

import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import Dropdown from 'components/base/Dropdown';
import FromControlWrapper from 'components/base/FormControlWraper';
import { selectProfile } from 'data/user';

import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';

import css from './style.scss';


const SelectPaymentMethod = ({ paymentMethods, onSelect, closePopup }) => {
  const [id, setId] = useState(paymentMethods.length ? paymentMethods[0].id : null);

  const handleClose = useCallback(() => {
    closePopup();
  }, [closePopup]);

  const handleChange = useCallback((ev) => {
    const { name, value } = ev.target || ev;
    setId(Number(value));
  }, []);

  const handleSelect = useCallback(() => {
    if (id) onSelect(paymentMethods.find(pm => pm.id === id));
    handleClose();
  }, [id, onSelect, paymentMethods, handleClose]);

  return (
    <Modal isOpen uniqId="SelectPaymentMethod" padding="25px" width="325px" caption="Select Payment Method" onClose={handleClose}>
      <div className={css.body}>
        <FromControlWrapper label="Payment Method" hasMarginBottom>
          <Dropdown onChange={handleChange} className={css.dropdown} name="id" options={paymentMethods.map(({ id, cardName, cardNumber }) => ({ label: `${cardNumber} (${cardName})`, value: id }))} value={id} />
        </FromControlWrapper>

        <div className={css.buttonContainer}>
          <Button kind={Button.kind.ghost} onClick={handleClose}>Cancel</Button>
          <Button kind={Button.kind.ghost} onClick={handleSelect}>Select</Button>
        </div>
      </div>
    </Modal>
  );
};

SelectPaymentMethod.propType = {
  id: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  paymentMethods: selectProfile(state).get('paymentMethods', List()).toJS(),
});

const mapDispatchToProps = {
  closePopup,
};

const SelectPaymentMethodPopup = connect(mapStateToProps, mapDispatchToProps)(SelectPaymentMethod);

const registrationId = getPopupRegistration(SelectPaymentMethodPopup);
SelectPaymentMethodPopup.open = props => openPopup(registrationId, { priority: Priority.HIGHEST, ...props });
SelectPaymentMethodPopup.close = props => closePopup(registrationId, props);

export default SelectPaymentMethodPopup;
