import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import ContactSearchTable from 'app/components/SearchTable/ContactSearchTable';
import PropertySearchTable from 'app/components/SearchTable/PropertySearchTable';
import Dropdown from 'components/base/Dropdown';
import classNames from 'classnames';
import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import { MAIN_CONTEXT, AVAILABLE_CONTEXT, ASSIGNED_CONTEXT, assignedGroupId, getGroupOptions } from 'reducers';
import { pluralize } from 'utils/string';
import * as Property from 'data/property';
import * as Contact from 'data/contacts';

import css from './style.scss';


let i = 0;

class GroupAssignmentEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);

    const { ids = [], groupId = null } = this.props;

    this.state = { id: null, loadId: groupId, loadIds: ids.length ? ids : null };
  }

  componentDidMount() {
    const { loadGroupContext } = this.props;
    loadGroupContext(MAIN_CONTEXT, 0, { filterEnabled: true }); // FIXME: Loading as a HACK to get assigned/available contexts to load properly. Cause currently unknown!
    loadGroupContext(ASSIGNED_CONTEXT, assignedGroupId);
    loadGroupContext(AVAILABLE_CONTEXT);

    this.handleProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.handleProps(props);
  }

  handleProps(props) {
    const { searchGroupContext, loading, loadContextIds, availableContext, assignedContext, loadGroupContext } = props;
    const { id, loadId, loadIds } = this.state;

    if (!loading) {
      if (loadIds) this.setState({ loadIds: null }, () => loadContextIds(assignedContext, loadIds));
      else if (loadId) this.setState({ loadId: null, id: loadId }, () => loadGroupContext(AVAILABLE_CONTEXT, loadId, { limit: 0 }));
      else if (id && availableContext.get('size') == null && i++ < 100) searchGroupContext(availableContext);
    }
  }

  handleClose() {
    this.props.closePopup();
  }

  handleAddClick() {
    this.props.assignContext();
  }

  handleRemoveClick() {
    this.props.unassignContext();
  }

  handleSave() {
    const { onSave, assignedContext } = this.props;
    if (onSave) onSave((assignedContext.getIn(['info', 'ids']) || List()).toJS());
    this.handleClose(this);
  }

  handleGroupChange(event) {
    const { value: selectedGroup } = event.target;
    const { groups, loadGroupContext } = this.props;
    const id = groups.getIn([selectedGroup, 'id']);

    i = 0;
    this.setState({ id }, () => loadGroupContext(AVAILABLE_CONTEXT, id, { limit: 0 }));
  }

  render() {
    const {
      type,
      availableContext,
      assignedContext,
      loading,
      groups,
      viewMode,
      groupId,
      SearchTable,
    } = this.props;

    const btn = { kind: Button.kind.blue, size: Button.size.large, className: css.btn, isLoading: loading };
    const availableSelected = availableContext.get('selected');
    const availableSize = availableContext.get('size');
    const assignedSize = assignedContext.get('size');
    const typeName = pluralize(type);

    return (
      <Modal isOpen uniqId="groupAssignment" padding="0 10px 10px 10px" caption={<div className={css.header}>{viewMode ? 'View' : 'Edit'} {typeName}</div>} width="1300px">
        <div className={css.body}>
          <div className={css.recipientContainer}>
            {viewMode ? null : (
              <div className={css.recipientRow}>
                <div className={css.assignmentContainer}>
                  <div>
                    <label>Available {typeName}:</label>
                    {!availableContext.get('id') ? null : <div className={css.remaining}>{!availableSize ? null : `${availableSize} Remaining`}</div>}
                  </div>
                  <Dropdown groups={getGroupOptions(groups)} onChange={this.handleGroupChange} value={groups.findIndex(g => g.get('id') === this.state.id)} className={css.dropDown} disabled={loading || groupId} />
                  <div>
                    <Button {...{ ...btn, kind: Button.kind.blueLink, className: css.blueLink }} disabled={!availableSelected} onClick={this.handleAddClick}>Add Selected {typeName}</Button>
                    {!availableSelected ? null : (<div className={css.remaining}>{availableSelected} Selected</div>)}
                  </div>
                </div>
                <div className={css.tableContainer}>
                  <SearchTable context={availableContext} />
                </div>
              </div>
            )}
            <div className={classNames(css.recipientRow, { [css.full]: viewMode })}>
              <div className={css.assignmentContainer}>
                <div className={css.title}>Selected {typeName} {!assignedSize ? null : `(${assignedSize})`}</div>
              </div>
              <div className={css.tableContainer}>
                <SearchTable context={assignedContext} viewMode={viewMode} />
              </div>
            </div>
          </div>
        </div>
        <div className={css.footer}>
          <div>
            {viewMode ? null : <Button {...{ ...btn, kind: Button.kind.redGhost }} onClick={this.handleRemoveClick} disabled={!assignedContext.get('selected')}>Remove Selected</Button>}
          </div>
          <div>
            <Button {...{ ...btn, kind: Button.kind.grayGhost }} onClick={this.handleClose}>{viewMode ? 'Close' : 'Cancel'}</Button>
            {viewMode ? null : <Button {...btn} onClick={this.handleSave}>Done</Button>}
          </div>
        </div>
      </Modal>
    );
  }
}

GroupAssignmentEditor.propTypes = {
  title: PropTypes.string.isRequired,
  viewMode: PropTypes.bool.isRequired,
  groupId: PropTypes.string,
};

GroupAssignmentEditor.defaultProps = {
  title: 'Edit Recipients',
  viewMode: false,
};

export default GroupAssignmentEditor;

const GroupsAssignmentPopup = (Type, SearchTable, type) => {
  const popup = connect(state => ({
    loading: Type.selectLoading(state),
    groups: Type.selectGroups(state),
    availableContext: Type.selectGroupContext(AVAILABLE_CONTEXT)(state),
    assignedContext: Type.selectGroupContext(ASSIGNED_CONTEXT)(state),
  }), {
    loadGroupContext: Type.loadGroupContext,
    loadContextIds: Type.loadContextIds,
    searchGroupContext: Type.searchGroupContext,
    assignContext: Type.assignContext,
    unassignContext: Type.unassignContext,
  })(GroupAssignmentEditor);

  popup.open = (props = {}) => openPopup(getPopupRegistration(popup), { ...props, SearchTable, type, priority: Priority.HIGH });

  return popup;
};

export const ContactAssignmentPopup = GroupsAssignmentPopup(Contact, ContactSearchTable, 'Contact');
export const PropertyAssignmentPopup = GroupsAssignmentPopup(Property, PropertySearchTable, 'Property');
