import React, { Component } from 'react';
import { SolidButton } from 'components/Button';
import AddonSection from './AddonSection';
import { getbrandCode, getBrandHelpUrl, getBrandLogoUrl, getBrandSmallLogoUrl, getBrandLogoAltUrl } from 'utils/brand';

export default class CancelModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { profile } = this.props;
    console.log(this.props.brandCode, getBrandLogoAltUrl())
    return (
      <span>
        {/* <Activities onClick={this.handleClose} /> */}
        <div
          style={{ width: '1160px', padding: '15px 25px 0', display: 'flex' }}
        >
          <div style={{ width: '50%', paddingBottom: '25px' }}>
          <img
              src='https://signup.propstream.com/resource/logo?c=CA02937056'
              placeholder='https://signup.propstream.com/resource/logo?c=CA02937056'
              class='_159lC__logoSmall'
              style={{ width: '138px', height: '76px', visibility: `${this.props.brandCode.includes('propstream')? 'visible': 'hidden'}` }}
            />
            <div
              style={{
                marginTop: '30px',
                color: `${this.props.brandCode.includes('propstream')?'#F16722': '#3D3D3D'}`,
                font: 'normal normal bold 24px/24px Helvetica Neue',
              }}
            >
              We are sorry to see you go!
            </div>
            <div
              style={{
                marginTop: '7px',
                font: 'normal normal 16px/20px Helvetica Neue',
                fontWeight: `${this.props.brandCode.includes('propstream')?'bold': 'normal'}`,
                color: '#3D3D3D',
              }}
            >
               {this.props.brandCode.includes('propstream')? "While we don’t like to see you cancel your subscription" : 'While we’d love you to continue being part of our user community'}, we understand that things change.
            </div>
            {/* { profile.get('csEmail').includes('propstream') && */}
              <span>
                <div
                  style={{
                    marginTop: '12px',
                    color: '#3D3D3D',
                    font: 'normal normal bold 14px/20px Helvetica Neue',
                  }}
                >
                  Before you go{this.props.brandCode.includes('propstream')?'…': ', did you know we have a variety of helpful add-ons?'}
                </div>
                {!this.props.brandCode.includes('propstream') && <AddonSection brandCode={this.props.brandCode}/>}
                {this.props.brandCode.includes('propstream') && <span><div style={{ lineHeight: 2 }}>
                  Did you know we have free resources to train you on the PropStream
                  platform?
                </div>
                <ul
                  style={{
                    margin: 0,
                    lineHeight: 1.5,
                  }}
                >
                  <li>
                    Daily live webinars (
                    <a
                      style={{ color: '#F16722', textDecoration: 'none' }}
                      href='https://www.propstream.com/webinars-training'
                      target='_blank'
                    >
                      Click here for the schedule
                    </a>
                    ).
                  </li>
                  <li>
                    Free 5-star learning with the{' '}
                    <a
                      style={{ color: '#F16722', textDecoration: 'none' }}
                      href='https://theacademy.propstream.com/learn'
                      target='_blank'
                    >
                      PropStream Academy
                    </a>
                    .
                  </li>
                </ul></span>}
              </span>  
            
            <div
              style={{
                lineHeight: 2,
                marginTop: '12px',
                color: '#3D3D3D',
                font: 'normal normal bold 14px/20px Helvetica Neue',
              }}
            >
              {this.props.brandCode.includes('propstream') ? 'Get personal support from our team:':<span style={{display: 'inline-block',marginBottom: 10}}>Do you need personalized support?</span>}
            </div>
            <div style={{ lineHeight: 1.5 }}>M-F: 6 am-6 pm PT</div>
            <div style={{ lineHeight: 1.5 }}>Toll-Free: {profile.get('csPhone')}</div>
            <div style={{ lineHeight: 1.5 }}>
              Email:{' '}
              <a
                style={{
                  paddingLeft: '2px',
                  color: `${this.props.brandCode.includes('propstream')?'#F16722': '#297CCF'}`,
                  textDecoration: 'none',
                }}
                href= {`mailto:${profile.get('csEmail')}`}
              >
               {profile.get('csEmail')}
              </a>
            </div>
            {this.props.brandCode.includes('propstream') &&
            <span><div
              style={{
                marginTop: '12px',
                color: '#3D3D3D',
                font: 'normal normal bold 14px/20px Helvetica Neue',
              }}
            >
              Don’t Forget These Great Add-ons…
            </div>
             <AddonSection brandCode={this.props.brandCode}/></span>}
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: `${this.props.brandCode.includes('propstream')?'flex-end': 'flex-start'}`,
                marginTop: `${this.props.brandCode.includes('propstream')?'0px': '20px'}`,
                paddingRight: '5px',
              }}
            >
              <SolidButton onClick={() => this.props.handleCancelClick()}>
                Cancel My Account
              </SolidButton>
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <img src={'/assets/images/cancelmodal.jpg'} alt='' height='640px' />
          </div>
        </div>
      </span>
    );
  }
}
