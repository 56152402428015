import { EventEmitter } from 'events';
import React, { PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import getCurrentRoute from 'utils/getCurrentRoute';
import getParentRoute from 'utils/getParentRoute';


export default class UrlStorage extends PureComponent {
  constructor(props) {
    super(props);
    if (this.props.urls) {
      this.ee = this.props.urls;
      return;
    }

    this.ee = new EventEmitter();
    this.ee.getState = () => {
      const root = getParentRoute({
        ...this.props,
        route: this.props?.routes?.find(route => route && route.path === ':id'),
      });
      const detailsRoot = getCurrentRoute(this.props);
      return {
        root,
        detailsRoot,
      };
    };
  }

  getChildContext() {
    return {
      urls: this.ee,
    };
  }

  componentDidUpdate(oldProps) {
    if (oldProps.route !== this.props.route || oldProps.routes !== this.props.routes) {
      this.ee.emit('changes');
    }
  }

  render() {
    const { children, ...rest } = this.props;
    if (!children) return null;
    return cloneElement(children, { ...rest, urls: this.ee });
  }
}

UrlStorage.childContextTypes = {
  urls: PropTypes.object,
};


export function decorate(Comp) {
  // eslint-disable-next-line react/no-multi-comp
  return class UrlStorageDecorator extends PureComponent {
    render() {
      return (
        <UrlStorage {...this.props}>
          <Comp {...this.props} />
        </UrlStorage>
      );
    }
  };
}
