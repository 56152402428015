const getOptions = (data, defaultDisabled = true, valueField = 'id', labelField = 'name', defaultLabel = '') => [
  { value: '', label: defaultLabel, disabled: defaultDisabled },
  ...data.map(o => ({ value: typeof o.get === 'function' ? o.get(valueField) : o[valueField], label: typeof o.get === 'function' ? o.get(labelField) : o[labelField] })),
];

export const getOptionsWithDefault = (data, defaultLabel) => getOptions(data, undefined, undefined, undefined, defaultLabel);
export const getOptionsValueLabel = (data, defaultDisabled = true, defaultLabel = '') => getOptions(data, defaultDisabled, 'value', 'label', defaultLabel);

export const getOptionsNoDefault = (data, valueField = 'id', labelField = 'name') => getOptions(data, false, valueField, labelField).slice(1);

export const getDropdownOptions = (options, labelProperty, valueProperty, defaultLabel, formatter) => {
  const opts = options.map((opt) => {
    const value = typeof opt === 'object' ? opt[valueProperty] : opt;
    const label = typeof opt === 'object' ? opt[labelProperty] : opt;
    return { label: formatter ? formatter(label) : label, value };
  });

  if (defaultLabel) opts.unshift({ label: defaultLabel, value: '' });

  return opts;
};

export default getOptions;
