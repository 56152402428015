import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { List, fromJS } from 'immutable';
import Modal from 'components/base/Modal';
import { SolidButton } from 'components/Button';
import { formatFullAddressObject } from 'utils/address/formatAddress';
import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';
import { selectLoading, selectStreetViewLoading, getProperty } from 'data/property';
import formatDate from 'utils/date/formatDate';
import Map from 'app/components/Map';

import Panel from '../Detail/Panel';
import css from './style.scss';
import css2 from '../Detail/style.scss';
import UrlStorage from '../Detail/UrlStorage';
import { GALLERY_FIELDS, writeFields } from '../Detail/Table';

import houseImgNotAvailable from 'components/base/Image/house-img-not-available.png'


const Modes = {
  // STREET: 'Street View',
  BIRD: 'Map View',
  GALLERY: 'Gallery',
};

export class PropertyGallery extends PureComponent {

  constructor(props) {
    super(props);

    this.handleRef = this.handleRef.bind(this);
    // this.handleImageError = this.handleImageError.bind(this);

    this.state = { mode: null, modes: [], imageIndex: 0 };
  }

  componentDidMount() {
    const { getProperty, id } = this.props;

    getProperty(id, 'G', ({ response }) => {
      const { address, imageSets, latitude, longitude, streetLatitude, streetLongitude } = response;
      const images = (imageSets && imageSets.length && imageSets[0].images.map(({ url }) => ({ date: formatDate(imageSets[0].date, '', false, 'MMMM YYYY'), url, original: url, thumbnail: url }))) || null;

      const modes = [Modes.BIRD];
      if (images) modes.unshift(Modes.GALLERY);
      // if (streetLatitude) modes.push(Modes.STREET);

      this.setState({
        modes,
        mode: modes[0],
        address: formatFullAddressObject(address),
        images,
        center: { latitude, longitude },
        streetCenter: { latitude: streetLatitude, longitude: streetLongitude },
        property: fromJS(response),
        imageUrl: null,
        badUrls: [],
      });
    });
  }

  handleImageClick(imageIndex) {
    this.imageGallery.slideToIndex(imageIndex);
    this.setState({ imageIndex, mode: Modes.GALLERY });
  }

  handleRef(ref) {
    if (ref) this.imageGallery = ref;
  }

  // handleImageError(url) {
  //   const { badUrls, images, imageIndex, mode, modes } = this.state;
  //
  //   if (!badUrls.includes(url)) {
  //     const urls = [...badUrls, url];
  //     const remainingImages = images.length - badUrls.length;
  //
  //     console.log('image error', url, images, imageIndex, mode, modes);
  //
  //     this.setState({
  //       badUrls: urls,
  //       imageIndex: Math.min(imageIndex, remainingImages - 1),
  //       mode: remainingImages ? mode : Modes.BIRD,
  //       modes: remainingImages ? modes : [Modes.BIRD],
  //     });
  //   }
  // }

  render() {
    const { urls, closePopup, loading } = this.props;
    const { mode, address, center, streetCenter, modes, images, property, imageIndex, badUrls } = this.state;
    // const map = { zoom: 18, color: Color[Active], entity: center };
    const galleryMode = mode === Modes.GALLERY;
    const date = galleryMode && images && images[0].date;

    // const selectedIndex = (this.imageGallery && this.imageGallery.getCurrentIndex()) || 0;

    return (
      <UrlStorage urls={urls}>
        <Modal
          isLoading={loading}
          onClose={closePopup}
          isOpen
          isCloseButton
          uniqId="PropertyGallery"
          modalClassName={css.galleryOuter}
          caption={(
            <div className={css.header}>
              <div className={css.headerTitle}>{address}</div>
            </div>
          )}
        >
          {!mode ? null : (
            <div className={css.gallery}>
              {!images ? null : (
                <div className={css.left}>
                  <Panel className={css.imagePanel}>
                    <div className={css.date}>{images[0].date}</div>
                    <div className={css.images}>
                      {images.filter(i => !badUrls.includes(i.url)).map((i, seq) => (
                        <div className={classNames(css.imageOuter, { [css.selected]: mode === Modes.GALLERY && seq === imageIndex })} key={i.url}>
                          <div className={css.image} onClick={() => this.handleImageClick(seq)}>
                            <img src={i.url} alt={`Image ${seq + 1}`} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Panel>
                </div>
              )}
              <div className={css.right}>
                <div className={css.viewer}>
                  <div className={classNames(css.container, { [css.galleryContainer]: galleryMode })}>
                    {mode !== Modes.BIRD ? null : <Map properties={List([property])} initialZoom={21} initialMapType="satellite" />}
                    {/*{mode !== Modes.BIRD ? null : <BirdViewMap {...map} center={center} />}*/}
                    {!galleryMode ? null : (
                      <ImageGallery
                        items={images}
                        slideInterval={10}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showNav={false}
                        showPlayButton={false}
                        ref={this.handleRef}
                        startIndex={imageIndex}
                        defaultImage={houseImgNotAvailable}
                      />
                    )}
                  </div>
                </div>
                <div className={css.bottom}>
                  <div className={css.date}>{date}</div>
                  <Panel className={css.details}>
                    <div className={classNames(css2.items, css.items)}>
                      <div className={css2.item}>
                        <div className={css2.label}>
                          Beds:
                          <span>{property.get('bedrooms')}</span>
                        </div>
                        <div className={css2.label}>
                          Baths:
                          <span>{property.get('bathrooms')}</span>
                        </div>
                      </div>
                      {writeFields(property, GALLERY_FIELDS)}
                    </div>
                  </Panel>
                  <div className={css.buttons}>
                    {modes.filter(m => m !== mode).map(mode => <SolidButton key={mode} loading={loading} onClick={() => this.setState({ mode })}>{mode}</SolidButton>)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </UrlStorage>
    );
  }
}

const PropertyGalleryPopup = connect(state => ({
  loading: selectLoading(state) || selectStreetViewLoading(state),
}), { getProperty })(PropertyGallery);

const registrationId = getPopupRegistration(PropertyGalleryPopup);
PropertyGalleryPopup.open = props => openPopup(registrationId, { priority: Priority.HIGH, ...props });
PropertyGalleryPopup.close = () => closePopup({ popup: registrationId });

export default PropertyGalleryPopup;
