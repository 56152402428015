import React, { Component } from 'react';
import classNames from 'classnames';
import Checkbox from 'components/base/Checkbox';
import SVG from 'components/base/SVG';

import css from './style.scss';


class IndexCellRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect() {
    const { context: { getSurroundingProps, hackHandleIgnoreNextRowClick }, data: { recordSeq, selected } } = this.props;
    const { context, typeCode, updateSurroundingPropertySelection } = getSurroundingProps();

    if (hackHandleIgnoreNextRowClick) hackHandleIgnoreNextRowClick();
    updateSurroundingPropertySelection(context, recordSeq, !selected, typeCode);
  }

  render() {
    const { data: { id, saleSituation, seq, selected } = {} } = this.props;

    return (
      <div className={classNames(css.indexCell)}>
        {!id ? <div /> : <Checkbox checked={selected} onClick={this.handleSelect} defaultChecked={false} />}
        {!id ? <SVG icon="iconHouse2" /> : (
          <div
            className={classNames({ [css.saleSituation]: !!saleSituation })}
            style={{ backgroundColor: (saleSituation && saleSituation.color) || 'transparent' }}
          >
            {seq}
          </div>
        )}
      </div>
    );
  }
}

export default IndexCellRenderer;
