import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import MaskedInput from 'react-maskedinput';
import Radio from 'components/base/Radio';

import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import Confirm from 'app/components/Confirm';
import Button from 'components/base/Button';
import Modal from 'components/base/Modal';
import Checkbox from 'components/base/Checkbox';
import Dropdown from 'components/base/Dropdown';
import Dagger from 'components/base/Dagger';
import { selectLoading, selectProfile, upgradeAccount } from 'data/user';
import { CcMonthOptions, CountryOptions, CountryMap, getCcYearOptions, CVV_PAYMENT_THRESHOLD } from 'data/user/constants';
import { getBrandTermsUrl } from 'utils/brand';
import numberFormat from 'utils/number/format';
import numberToPrice from 'utils/currency/numberToPrice';
import { validate, verify, verifyRequired, isValidCardNumber, isValidCardCode, isValidCardExpiration } from 'utils/validation';
import { inferCardType } from 'utils/payment';

import css from './style.scss';


const YearOptions = getCcYearOptions();

class UpgradeAccount extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRef = this.handleRef.bind(this);

    const { profile: { fullAccount, overdueAccount, catalogProducts = [], paymentMethods } } = this.props;

    this.propertyMode = props.bumpType === 'savedProperty';
    this.bumpField = this.propertyMode ? 'savedPropertyQuantity' : 'exportPropertyQuantity';
    this.catalogProducts = catalogProducts.filter(p => p[this.bumpField] >= 0);
    this.maxBumpQuantity = this.catalogProducts.reduce((max, p) => Math.max(max, p[this.bumpField]), 0);

    this.ref = {};
    this.state = {
      complete: false,
      productMode: fullAccount && !overdueAccount,
      message: '',
      cardExpMonth: 1,
      cardExpYear: new Date().getFullYear(),
      cardName: '',
      cardNumber: '',
      cardCode: '',
      selectedCardCode: '',
      quantity: '10000',
      paymentMethodId: (paymentMethods && paymentMethods.length && paymentMethods[0].id) || '',
      billingStreetAddress: '',
      billingStreetAddress2: '',
      billingCity: '',
      billingState: '',
      billingZip: '',
      billingCountry: 'US',
      termsAccepted: false,
      catalogProductId: (this.catalogProducts.length && this.catalogProducts[0].id) || null,
    };
  }

  getCatalogProduct() {
    return (this.catalogProducts || []).find(c => c.id === this.state.catalogProductId);
  }

  getSelectedCardInfo() {
    const { cardNumber } = this.props.profile.paymentMethods.find(pm => pm.id === Number(this.state.paymentMethodId)) || {};

    return { ...inferCardType(cardNumber), cardNumber };
  }

  getProductLabel(plural) {
    const suffix = plural ? 's' : '';

    return this.isPropertyMode() ? [`Save/Import${suffix}`, <Dagger key="dagger" />] : `Export${suffix}`;
  }

  isPropertyMode() {
    return this.state.productMode && this.propertyMode;
  }

  handleSubmit(ev) {
    ev.preventDefault();

    const { upgradeAccount, confirm, profile: { mrcTotal } } = this.props;
    const r = this.ref;
    const { cardNumber, cardCode, selectedCardCode, cardName, cardExpMonth, cardExpYear, billingStreetAddress, billingCity, billingState, billingZip, billingCountry, paymentMethodId, termsAccepted, productMode, catalogProductId, quantity } = this.state;
    const qty = Number(quantity);
    const catalogProduct = this.getCatalogProduct();
    const { unitPrice, [this.bumpField]: bumpQuantity } = catalogProduct || {};
    const amount = productMode ? unitPrice * (bumpQuantity ? 1 : Number(quantity)) : mrcTotal;
    const { codePosition, codeLength, cardNumber: selectedCardNumber } = this.getSelectedCardInfo();

    validate(() => {
      if (productMode && !bumpQuantity) {
        verifyRequired(r.quantity, quantity, 'Quantity is required.');
        verify(r.quantity, !!qty, 'Quantity is invalid.');
        verify(r.quantity, qty > this.maxBumpQuantity, `Quantity must be at least ${numberToPrice(this.maxBumpQuantity + 1)}.`);
      }

      const paymentInfo = { ...this.state };

      if (paymentMethodId === '') {
        // Validate new card entry
        verifyRequired(r.cardNumber, cardNumber, 'Credit Card Number is required.');
        verify(r.cardNumber, isValidCardNumber(cardNumber), 'Credit Card Number is invalid.');
        verifyRequired(r.cardCode, cardCode, 'Credit Card CVV is required.');
        verify(r.cardCode, isValidCardCode(cardCode, cardNumber), 'Credit Card CVV is invalid.');

        verifyRequired(r.cardName, cardName, 'Name on Card is required.');
        verifyRequired(r.cardExpMonth, cardExpMonth, 'Expiration Month is required.');
        verifyRequired(r.cardExpYear, cardExpYear, 'Expiration Year is required.');

        const country = CountryMap[billingCountry];
        verify(r.cardExpMonth, isValidCardExpiration(cardExpYear, cardExpMonth), 'Credit card is expired.');
        verifyRequired(r.billingStreetAddress, billingStreetAddress, 'Billing Street Address is required.');
        verifyRequired(r.billingCity, billingCity, 'Billing City is required.');
        verifyRequired(r.billingState, billingState, `Billing ${country.stateLabel} is required.`);
        verifyRequired(r.billingZip, billingZip, `Billing ${country.postalCodeLabel} is required.`);
        verify(r.billingZip, !country.postalCodePattern || new RegExp(country.postalCodePattern, 'i').test(billingZip), `${country.postalCodeLabel} is invalid.`);
      } else {
        // Clear the card code since it's technically possible there's still a value from a new card entry if user switches back and forth.
        paymentInfo.cardCode = null;

        if (amount >= CVV_PAYMENT_THRESHOLD) {
          // Enforce card code entry for amounts greater than the set minimum on existing cards, and assign the value to the data being passed to the server.
          verifyRequired(r.selectedCardCode, selectedCardCode, `Please enter the ${codeLength}-Digit Credit Card CVV number on the ${codePosition} of your card.`);
          verify(r.selectedCardCode, isValidCardCode(selectedCardCode, selectedCardNumber), 'Credit Card CVV is invalid.');
          paymentInfo.cardCode = selectedCardCode;
        }
      }

      verify(r.termsAccepted, termsAccepted, 'You must accept the Terms and Conditions.');

      confirm({
        question: `Your card will be charged ${numberToPrice(amount)}. Click Ok to proceed.`,
        onOk: () => upgradeAccount(
          paymentInfo,
          productMode ? catalogProductId : null,
          null,
          productMode && !bumpQuantity ? qty : null,
          amount,
          null,
          ({ response: { success: complete, message } }) => this.setState({ complete, message })),
      });
    });
  }

  handleChange(event) {
    const { name, value, checked } = event.target;
    const stateChange = {};

    const val = name === 'catalogProductId' ? Number(value) : value;

    stateChange[name] = name === 'termsAccepted' ? checked : val;
    if (name === 'cardNumber') stateChange[name] = value.replace(/\D/g, '');
    else if (name === 'billingCountry') stateChange.billingState = '';

    this.setState(stateChange);
  }

  handleRef(el) {
    if (el) this.ref[(el.target || el).name] = el.focus ? el : findDOMNode(el);
  }

  render() {
    const { profile, closePopup, loading } = this.props;
    const { fullAccount, paymentMethods, mrcTotal, assignedSavedPropertyLimit, csPhone, csEmail } = profile;
    const { complete, productMode, catalogProductId, message, cardExpMonth, cardExpYear, cardName, cardNumber, cardNumberMasked, paymentMethodId, billingStreetAddress, billingStreetAddress2, billingCity, billingState, billingZip, billingCountry, termsAccepted, quantity } = this.state;

    const propertyMode = this.isPropertyMode();
    const catalogProduct = this.getCatalogProduct();
    const { unitPrice, [this.bumpField]: bumpQuantity } = catalogProduct || {};
    const effectiveBumpQuantity = bumpQuantity || Number(quantity);
    const validQuantity = !productMode || bumpQuantity || effectiveBumpQuantity > this.maxBumpQuantity;

    const total = (validQuantity && (productMode ? unitPrice * (bumpQuantity ? 1 : effectiveBumpQuantity) : mrcTotal)) || 0;
    const totalFormatted = total ? numberToPrice(total) : '--';
    const properties = numberFormat(productMode ? effectiveBumpQuantity : assignedSavedPropertyLimit);
    const upgradeCaption = fullAccount ? 'Renew' : 'Activate';

    const productLabel = this.getProductLabel();
    const productLabelPlural = this.getProductLabel(true);

    // Get country state list. If no country is selected, default to US.
    const country = CountryMap[billingCountry] || CountryMap.US;
    const stateOptions = (country.states || []).map(state => ({ label: state.name, value: state.code }));
    if (stateOptions.length) stateOptions.unshift({ value: '' });

    const { numberMask, codeMask } = inferCardType(cardNumber);
    const { codeMask: selectedCodeMask } = this.getSelectedCardInfo();
    const newPaymentMethod = paymentMethodId === '';
    const paymentMethodOptions = (paymentMethods || []).map(({ id, description }) => ({ label: description, value: id }));
    paymentMethodOptions.push({ label: 'Use New Credit Card', value: '' });

    const btnProps = { size: Button.size.large, className: css.button, isLoading: loading };
    const inputProps = { ref: this.handleRef, onChange: this.handleChange };
    const textProps = { ...inputProps, type: 'text', className: css.input };
    const expProps = { ...inputProps, className: classNames(css.dropdown, css.dropdownCardExpiration) };

    return (
      <Modal
        isOpen
        uniqId="upgradeAccount"
        caption={productMode ? [propertyMode ? 'Property ' : '', productLabel, ' Limit Increase'] : `${upgradeCaption} Subscription`}
        forceCaptionClass
        width="515px"
        padding="0 25px 20px 25px"
      >
        <div className={css.upgrade}>
          {complete ? (
            <div>
              <div className={css.info}>
                Congratulations! Your payment was successful and you now have access to {productMode ? `${properties} additional records` : `the full ${properties} properties.`}.
              </div>
              <div className={css.buttons}>
                <Button {...btnProps} kind={Button.kind.grayGhost} onClick={closePopup}>Close</Button>
              </div>
            </div>
          ) : (
            <div>
              <div className={css.info}>
                {productMode ? (
                  <div>
                    <div className={css.products}>
                      <div className={css.productsInner}>
                        <div className={css.title}>Pricing Options</div>
                        {this.catalogProducts.map(({ id, unitPrice, [this.bumpField]: bumpQuantity }) => (
                          <div className={css.product} key={id}>
                            <Radio onChange={this.handleChange} label={`${bumpQuantity ? numberFormat(bumpQuantity) : ((this.maxBumpQuantity && `${numberFormat(this.maxBumpQuantity + 1)}+`) || '')} Additional ${propertyMode ? 'Properties' : 'Exports'} - ${numberToPrice(unitPrice)} ${bumpQuantity ? '' : ' / each'}`.trim()} value={id} checked={id === catalogProductId} name="catalogProductId" />
                          </div>
                        ))}
                      </div>
                    </div>
                    {bumpQuantity ? null : (
                      <div className={css.quantity}>
                        <label htmlFor="quantity">Quantity:</label>
                        <input {...textProps} name="quantity" value={quantity} />
                      </div>
                    )}
                    <div>Your credit card will be billed a one-time charge of {totalFormatted} for immediate access to {validQuantity ? properties : '--'} additional {productLabel} records. Please note that purchased {productLabelPlural} must be used in the current billing cycle and your account will reset to the default {productLabel} limit on your next billing cycle. Any unused {productLabelPlural} will not carry over into the next billing cycle.</div>
                    {propertyMode && <div><br /><Dagger />Imports require List Automator add-on service to import records.</div>}
                  </div>
                ) : (
                  <div>Your credit card will be billed {totalFormatted}. Once complete, your subscription will {fullAccount ? 'resume' : 'begin'} and you will have access to the full {properties} saved property limit.</div>
                )}
              </div>
              <div className={css.section}>
                <div className={css.row}>
                  <div className={css.field}>
                    <label htmlFor="paymentMethod">Select Credit Card</label>
                    <Dropdown {...inputProps} className={css.dropdown} name="paymentMethodId" options={paymentMethodOptions} value={paymentMethodId} onChange={this.handleChange} />
                  </div>
                  {!newPaymentMethod && total >= CVV_PAYMENT_THRESHOLD && (
                    <div className={classNames(css.field, css.width1)}>
                      <label htmlFor="selectedCardCode">CVV*</label>
                      <MaskedInput {...inputProps} mask={selectedCodeMask} className={css.input} name="selectedCardCode" />
                    </div>
                  )}
                </div>
              </div>
              {newPaymentMethod && (
                <div>
                  <div className={css.section}>
                    <div className={css.row}>
                      <div className={css.field}>
                        <label htmlFor="card">Card Number*</label>
                        <MaskedInput
                          {...inputProps}
                          {...(cardNumberMasked && cardNumberMasked.length ? { placeholder: cardNumberMasked } : {})}
                          mask={numberMask}
                          className={css.input}
                          name="cardNumber"
                          value={cardNumber}
                        />
                      </div>
                      <div className={classNames(css.field, css.width1)}>
                        <label htmlFor="cardCode">CVV*</label>
                        <MaskedInput {...inputProps} mask={codeMask} className={css.input} name="cardCode" />
                      </div>
                    </div>
                    <div className={css.row}>
                      <div className={css.field}>
                        <label htmlFor="credit-card-name">Name on Card*</label>
                        <input {...textProps} name="cardName" value={cardName} />
                      </div>
                      <div className={classNames(css.field, css.width1)}>
                        <label htmlFor="expiryMonth">Expiration Date*</label>
                        <Dropdown {...expProps} name="cardExpMonth" options={CcMonthOptions} value={cardExpMonth} style={{ marginRight: '10px' }} />
                        <Dropdown {...expProps} name="cardExpYear" options={YearOptions} value={cardExpYear} />
                      </div>
                    </div>
                  </div>
                  <div className={css.section}>
                    <div className={css.row}>
                      <div className={css.field}>
                        <label htmlFor="billingStreetAddress">Billing Street Address*</label>
                        <input {...textProps} name="billingStreetAddress" value={billingStreetAddress} />
                      </div>
                    </div>
                    <div className={css.row}>
                      <div className={css.field}>
                        <label htmlFor="billingStreetAddress2">Billing Street Address 2</label>
                        <input {...textProps} name="billingStreetAddress2" value={billingStreetAddress2} />
                      </div>
                    </div>
                    <div className={css.row}>
                      <div className={css.field}>
                        <label htmlFor="billingCity">City*</label>
                        <input {...textProps} name="billingCity" value={billingCity} />
                      </div>
                      <div className={css.field}>
                        <label htmlFor="billingState">{country.stateLabel}*</label>
                        {stateOptions.length ? <Dropdown {...inputProps} className={css.dropdown} name="billingState" value={billingState} options={stateOptions} /> : (
                          <input {...textProps} name="billingState" value={billingState} className={classNames(css.input, css.inputState)} />
                        )}
                      </div>
                      <div className={css.field}>
                        <label htmlFor="billingZip">{country.postalCodeLabel}*</label>
                        <input {...textProps} name="billingZip" value={billingZip} />
                      </div>
                    </div>
                    <div className={css.row}>
                      <div className={css.field}>
                        <label htmlFor="billingCountry">Country*</label>
                        <Dropdown {...inputProps} name="billingCountry" className={classNames(css.dropdown, css.dropdownCountry)} options={CountryOptions} value={country.code} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={css.disclaimer}>
                <Checkbox {...inputProps} checked={termsAccepted} name="termsAccepted" ref={this.handleRef} />
                {productMode ? (
                  <div>
                    I agree to the <a href={getBrandTermsUrl()} target="terms">Terms and Conditions</a> and understand that
                    <span>I will be billed a one-time charge of {totalFormatted} for the {productLabel} limit increase.</span>
                    For all inquiries, please contact us at {csPhone}.
                  </div>
                ) : (
                  <div>
                    I agree to the <a href={getBrandTermsUrl()} target="terms">Terms and Conditions</a> and understand that
                    <span>I will be billed {totalFormatted} per month on a month-to-month basis until I cancel my subscription.</span>
                    To cancel at any time, please email customer support at {csEmail} to discontinue your subscription. For all other inquiries, please contact us at {csPhone}.
                  </div>
                )}
              </div>
              {message === '' ? null : <div className={css.error}>{message}</div>}
              <div className={css.buttons}>
                <Button {...btnProps} kind={Button.kind.grayGhost} onClick={closePopup}>Cancel</Button>
                <Button {...btnProps} kind={Button.kind.blue} onClick={this.handleSubmit}>Submit Payment</Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

UpgradeAccount.propTypes = {
  bumpType: PropTypes.string.isRequired,
};

UpgradeAccount.defaultProps = {
  bumpType: 'savedProperty',
};

const UpgradeAccountPopup = connect(state => ({
  loading: selectLoading(state),
  profile: selectProfile(state).toJS(),
}), {
  confirm: Confirm.open,
  upgradeAccount,
})(UpgradeAccount);

UpgradeAccountPopup.open = props => openPopup(getPopupRegistration(UpgradeAccountPopup), { ...props, priority: Priority.HIGH });

export default UpgradeAccountPopup;
