import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';


function getRangeValue(value, min, max) {
  return value == null || isNaN(value) || String(value).trim() === '' ? null : Math.max(min, Math.min(max, Math.floor(Number(value)) || 0));
}

function getState(props) {
  const min = props.min || 0;
  const max = Math.max(min, props.max || 0);
  const value = getRangeValue(props.value, min, max);

  return { value, min, max };
}

class MinMaxInput extends PureComponent {
  constructor(props) {
    super(props);

    this.handleEvent = this.handleEvent.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.state = getState(props);
    this.state.focused = false;
  }

  componentWillReceiveProps(nextProps) {
    this.setState(getState(nextProps));
  }

  handleEvent(event, trigger = false, newValue) {
    const { props: { name, value: curValue, onChange }, state: { min, max } } = this;
    let value = typeof newValue === 'undefined' ? event.target.value : newValue;
    if (trigger) value = getRangeValue(value, min, max);

    event.preventDefault();
    this.setState({ value }, () => {
      if (trigger && value !== curValue && onChange) onChange({ name, value });
    });
  }

  handleKeyDown(event) {
    const { handleEvent, props: { value } } = this;
    if (event.key === 'Enter') handleEvent(event, true);
    else if (event.key === 'Escape') handleEvent(event, true, value);
  }

  handleFocus() {
    this.setState({ focused: true });
  }

  handleBlur(event) {
    this.setState({ focused: false });
    this.handleEvent(event, true);
  }

  render() {
    const { handleEvent, handleKeyDown, handleBlur, handleFocus, state: { value, focused }, props: { placeholder, className } } = this;
    return (
      <input
        type="text"
        className={className}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={event => handleEvent(event)}
        onKeyDown={handleKeyDown}
        value={value == null ? '' : String(value)}
        placeholder={focused ? null : placeholder}
      />
    );
  }
}

MinMaxInput.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};


export default MinMaxInput;
