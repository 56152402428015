import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import fileFormats from 'config/constants/fileFormats';
import Modal from 'components/base/Modal';
import Button, { ButtonImport } from 'components/base/Button';
import AddToGroup from 'app/components/AddToGroup';
import numberFormat from 'utils/number/format';
import formatDate from 'utils/date/formatDate';
import SVG from 'components/base/SVG';
import Confirm from 'app/components/Confirm';
import { toProperCase } from 'utils/string';

import { selectImportLoading, selectOriginalLists, importProperties, getImportJobs, deleteImportJob, selectImportJobs, downloadImportJobFile, downloadImportJobReport } from 'data/property';
import { getPopupRegistration, openPopup, closePopup } from 'app/PopupHolder';

import css from './style.scss';
import csv from './properties.csv';
import xls from './properties.xls';
import xlsx from './properties.xlsx';


class PropertyImport extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSave = this.handleSave.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.checkStatus = this.checkStatus.bind(this);

    this.refreshTimer = null;

    this.state = {
      file: null,
      processing: false,
      countdown: 0,
    };
  }

  componentWillMount() {
    this.refresh();
  }

  componentWillUnmount() {
    this.killTimer();
  }

  refresh() {
    this.props.getImportJobs(this.checkStatus);
  }

  killTimer() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
    }
  }

  checkStatus() {
    this.killTimer();

    const processing = !!this.props.jobs.find(j => j.get('status') === 'PROCESSING');
    let countdown = 0;

    if (processing) {
      countdown = 15;
      this.refreshTimer = setInterval(() => {
        const countdown = this.state.countdown - 1;

        if (!countdown) {
          this.killTimer();
          this.refresh();
        }

        this.setState({ countdown });
      }, 1000);
    }

    this.setState({ processing, countdown });
  }

  handleFile(ev) {
    const file = ev.target.files[0];

    if (file.type === 'text/csv') {
      this.handleUpdateFile(file)
    } else {
      this.setState({ file }, file ? this.handleSave : null);
    }

  }

  handleUpdateFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const contents = event.target.result;
      const parsedOldFile = contents.split('\n').map(row => row.split(','));
      const updatedContent = this.updateCellValue([...parsedOldFile]);
      const updatedFileContent = updatedContent.map(row => row.join(',')).join('\n');
      const updatedBlob = new Blob([updatedFileContent], { type: file.type });
      const updatedFile = new File([updatedBlob], file.name, { type: file.type });
      this.setState({ file: updatedFile }, updatedFile ? this.handleSave : null);
    };
    reader.readAsText(file);
  }

  updateCellValue = (parsedOldFile) => {
    const dangerousChars = ['=', '+', '-', '@', '\t', '\r'];
    const updatedFile = parsedOldFile.map((row, index) => {
      return row.map(cell => {
        let trimmedValue = cell.replace(/^"(.*)"$/, '$1');
        const startsWithDangerousChars = dangerousChars.some(char => trimmedValue.startsWith(char));
        if (startsWithDangerousChars) {
          trimmedValue = `'${trimmedValue}`
          // Escape double quotes within the cell value
          trimmedValue = `""${trimmedValue}""`;
          // Wrap the cell value in double quotes
          return `"${trimmedValue}"`;

        } else {
          return `"${trimmedValue}"`;
        }
      });
    });
    return updatedFile;
  };

  handleSave() {
    const { importProperties, openAddToGroup, groups } = this.props;

    openAddToGroup({
      selectLoading: selectImportLoading,
      groups,
      size: 1,
      groupType: 'Marketing List',
      addPropertyMode: true,
      add: (name, onComplete) => importProperties(this.state.file, name, ({ response: { savePropertyResponse: { quantity, message } } }) => this.setState({ file: null }, () => {
        onComplete(quantity, message);
        this.checkStatus();
      })),
    });
  }

  handleDelete(id) {
    const { deleteImportJob, confirmRemoval } = this.props;

    confirmRemoval({
      question: 'Are you sure you want to remove this file? This will only remove the file from this list and NOT remove any imported properties.',
      onOk: () => deleteImportJob(id),
    })();
  }

  handleDownloadFile(id) {
    this.props.downloadImportJobFile(id);
  }

  handleDownloadReport(id) {
    this.props.downloadImportJobReport(id);
  }

  render() {
    const { loading, jobs } = this.props;
    const { file, processing, countdown } = this.state;
    const btn = { size: Button.size.middle, className: css.button, isLoading: loading };
    const accept = fileFormats.tables.map(format => `.${format}`).join(',');

    return (
      <Modal
        isOpen
        isCloseButton
        uniqId="ImportProperties"
        width="900px"
        height="90%"
        border="0"
        padding="20px 35px 35px"
        className={css.import}
        caption="Import Properties"
      >
        <div className={css.inner}>
          {file ? (
            <div className={css.upload}>
              <SVG icon="iconUpload" className={css.icon} />
              <div className={css.fileName}>{file.name}</div>
              <div className={css.buttons}>
                <Button {...btn} kind={Button.kind.redGhost} onClick={() => this.setState({ file: null })}>Cancel</Button>
                <Button {...btn} kind={Button.kind.blue} onClick={this.handleSave}>Import</Button>
              </div>
            </div>
          ) : (
            <div className={css.upload}>
              <SVG icon="iconUpload" className={css.icon} />
              <div className={css.buttons}>
                <a href={csv} download style={{ marginRight: '10px' }}>
                  <Button {...btn} kind={Button.kind.grayGhost} size={Button.size.middle}>Download Template</Button>
                </a>
                <ButtonImport {...btn} kind={Button.kind.blue} accept={accept} onChange={this.handleFile}>Choose File</ButtonImport>
              </div>
              <div>To Import Your Existing Properties:</div>
              <div>
                1) Download and use one of the following sample templates:&#160;
                <a href={csv} download>*.csv</a>, <a href={xls} download>*.xls</a>, <a href={xlsx} download>*.xlsx</a>
              </div>
              <div>2) Enter your existing Properties into the template using the example provided</div>
              <div>3) Click the Choose File button to upload your saved template</div>
            </div>
          )}
          {!jobs || !jobs.size ? null : (
            <div className={css.jobs}>
              <table cellSpacing={0}>
                <tr>
                  <th />
                  <th className={css.left} colSpan={2}>Property Imports</th>
                  {/* <th className={css.left}>File Name</th>*/}
                  <th>Status</th>
                  <th>Date</th>
                  <th>Loaded</th>
                  <th>Processed</th>
                  <th>Success</th>
                  <th>Fail</th>
                </tr>
                {jobs.map((j, i) => (
                  <tr key={j.get('id')} className={classNames({ [css.deleted]: j.get('deleted') })}>
                    <td className={css.actions}>&nbsp;</td>
                    <td>{i + 1}.</td>
                    <td>{j.get('name')}</td>
                    <td className={classNames(css.center, { [css.processing]: j.get('status') === 'PROCESSING' })}>{toProperCase(j.get('status'))}</td>
                    {/* <td>{j.get('fileName')}</td>*/}
                    <td>{formatDate(j.get('createDate'))}</td>
                    <td className={css.center}>{numberFormat(j.get('recordQuantity'))}</td>
                    <td className={css.center}>{numberFormat(j.get('processedQuantity'))}</td>
                    <td className={css.center}>{numberFormat(j.get('successQuantity'))}</td>
                    <td className={css.center}>{numberFormat(j.get('failQuantity'))}</td>
                    <td className={css.actions}>
                      <div>
                        {process.env.NODE_ENV === 'production' ? null : <SVG icon="iconExport" title="Download File" onClick={() => this.handleDownloadFile(j.get('id'))} />}
                        {j.get('recordQuantity') !== j.get('processedQuantity') ? null : <SVG icon="iconArrowDown" title="Download Report" onClick={() => this.handleDownloadReport(j.get('id'))} />}
                        {j.get('recordQuantity') !== j.get('processedQuantity') ? null : <SVG icon="iconTrash" title="Remove File" onClick={() => this.handleDelete(j.get('id'))} />}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )}
          {!processing ? null : <div className={css.countdown}>Refreshing in {countdown}...</div>}
        </div>
      </Modal>
    );
  }
}

const Popup = connect(state => ({
  loading: selectImportLoading(state),
  groups: selectOriginalLists(state),
  jobs: selectImportJobs(state),
}), {
  importProperties,
  getImportJobs,
  deleteImportJob,
  downloadImportJobFile,
  downloadImportJobReport,
  openAddToGroup: AddToGroup.open,
  confirmRemoval: Confirm.confirmRemoval,
  alert: Confirm.alert,
})(PropertyImport);

const registrationId = getPopupRegistration(Popup);
Popup.open = (...rest) => openPopup(registrationId, ...rest);
Popup.close = () => closePopup({ popup: registrationId });

export default Popup;
