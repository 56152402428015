export const phoneFormatter = (phone) => {
  const str = (phone || '').replace(/\D/g, '');
  if (str.length !== 10) return str;

  const parts = str.match(/^(\d{3})(\d{3})(\d{4})$/);
  return `(${parts[1]}) ${parts[2]}-${parts[3]}`;
};

export const timeFormatter = (time) => {
  const parts = time.split(':');
  const hour = Number(parts[0]);
  return `${hour % 12 || 12}:${parts[1]} ${hour < 12 ? 'AM' : 'PM'}`;
};
