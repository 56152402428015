import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import Example from 'components/base/Table/EXAMPLE';


class Components extends Component {
  render() {
    const divStyle = {
      backgroundColor: '#fff',
      padding: '30px',
    };
    return (
      <div style={divStyle}>
        <Helmet title="Components" />

        <Example />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    search: state.getIn(['search', 'list', 'data']),
  };
}

const mapActionToProps = {
};

export { Components };
export default connect(mapStateToProps, mapActionToProps)(Components);
