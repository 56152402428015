import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';

import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';
import { bindToState } from 'components/base/Form';

import Layout from './Layout';

const ModalSelectAnalysis = ({ handleSubmit, analyses, onSelect, closePopup }) => {
  const handleClose = () => {
    closePopup();
  };

  const handleSubmitForm = values => {
    handleClose();
    const selectedAnalysis = analyses.find(analysis => analysis.id === Number(values.get('id')));
    onSelect(Map(selectedAnalysis));
  };

  return (
    <Layout
      onClose={handleClose}
      onSubmit={handleSubmit(handleSubmitForm)}
    />
  );
};

const formName = 'create-group-modal-form';

const mapStateToProps = () => ({
  form: formName,
});

const Popup = connect(mapStateToProps)(bindToState(formName)(ModalSelectAnalysis));

Popup.registrationId = getPopupRegistration(Popup);
Popup.open = (props, ...rest) => openPopup(Popup.registrationId, { priority: Priority.HIGH, ...props }, ...rest);
Popup.close = () => closePopup({ popup: Popup.registrationId });

export default Popup;
