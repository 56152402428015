import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Button from 'components/base/Button';
import tableCss from 'components/base/Table/styles.scss';
import Dropdown, { dropdownable } from 'components/base/Dropdown';

import Confirm from 'app/components/Confirm';
import { assignSearchSlot, selectFavoriteSearchSlotOptions } from 'data/search';

import css from './style.scss';


const stop = ev => ev.stopPropagation();

export class SavedSearchAssign extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      top: 0,
      bottom: null,
      index: 1,
    };
  }

  componentDidMount() {
    this.props.setActiveRowClassName(tableCss.active);
  }

  componentDidUpdate(props) {
    const { isOpen, indexes, closeDropdown, direction, setRowActive, registerScrollCallback } = this.props;

    const reposition = (offset = 0) => this.setState({ top: this.span.getBoundingClientRect().top + offset, bottom: offset ? 'unset' : null });

    if (props.isOpen !== isOpen) {
      if (!isOpen) setRowActive(null);
      else {
        setRowActive(indexes.row);
        registerScrollCallback(closeDropdown);
      }
    }

    if (props.direction !== direction || props.isOpen !== isOpen) reposition(direction === 'bottom' ? 0 : -400);
  }

  handleChange(ev) {
    this.setState({ index: Number((ev.target || ev).value) });
  }

  handleSubmit(ev) {
    const { item, closeDropdown, closeParentDropdown, confirm, slots, assignSearchSlot } = this.props;
    const { index } = this.state;

    const slot = slots.find(s => s.value === index);

    ev.preventDefault();

    const save = () => {
      assignSearchSlot(item.get('id'), index);
      closeDropdown();
      closeParentDropdown();
    };

    if (slot.id) confirm({ question: `Do you want to replace "${slot.label}" with "${item.get('name')}"?`, onOk: save });
    else save();
  }

  handleRef(ref) {
    this.span = ref;
  }

  render() {
    const { isOpen, closeDropdown, openDropdown, onRef, slots, direction, item } = this.props;
    const { top, bottom, index } = this.state;

    return (
      <div className={css.assignSearch} onClick={stop}>
        <span className={tableCss.hidden} onClick={openDropdown} ref={this.handleRef}>+</span>
        {!isOpen ? null : (
          <div className={classNames(css.root, css.addRoot, css.leftAlign, { [css.toTop]: direction === 'top' })} ref={onRef} style={{ top, bottom }}>
            <form onSubmit={this.handleSubmit}>
              <div className={css.addHeader}>Add to Home Screen</div>
              <div className={css.addBody}>
                <div>
                  <div className={css.caption}>Name:</div>
                  <div>{item.get('name')}</div>
                </div>
                <div className={css.selectContainer}>
                  <div className={css.caption}>Select spot to replace</div>
                </div>
                <div>
                  <Dropdown name="slot" options={slots} value={index} onChange={this.handleChange} />
                </div>
                <div className={css.buttonsContainer}>
                  <Button kind={Button.kind.default} size={Button.size.large} onClick={closeDropdown}>Cancel</Button>
                  <Button kind={Button.kind.blue} size={Button.size.large} type="submit">Save</Button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default dropdownable(connect(state => ({
  slots: selectFavoriteSearchSlotOptions(state),
}), {
  assignSearchSlot,
  confirm: Confirm.open,
})(SavedSearchAssign));
