/**
 * Loadable HOC shows Loader component if isLoading flag is true, ErrorDisplay if isError flag is true,
 * otherwise it shows your component
 *
 * @param isLoading   {Boolean} (optional) flag for loading state
 * @param isHover     {Boolean} (optional) show loader above of component
 * @param isError     {Boolean} (optional) flag for error state
 * @param errorText   {Boolean} (optional) error message
 *
 * @returns {Component}
 *
 *
 * How you can use it
 *
 * const MyComponentLoadable = loadable(MyComponent);
 * <MyComponentLoadable
 *   isLoading={isLoadingState}
 *   isError={isErrorState}
 *   errorText={errorText}
 *   ...any props
 * />
 *
 * Or with children
 *
 * <MyComponentLoadable
 *   isLoading={isLoadingState}
 *   isError={isErrorState}
 *   errorText={errorText}
 *   ...any props
 * >
 *   <ChildrenComponent />
 * </MyComponentLoadable>
 */

import React from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';

import Loader from '../../Loader';

import css from '../../Loader/style.scss';


export default WrappedComponent => pure(
  (props) => {
    const { isLoading, loading, isHover, errorText, loaderSize, loaderDirection, ...rest } = props;

    return (
      <div className={css.withHoverLoader}>
        {(loading || isLoading) && <Loader loaderSize={loaderSize} loaderDirection={loaderDirection} isHover />}
        <div className={classNames(css.content, { [css.hidden]: !isHover && (loading || isLoading) })}>
          <WrappedComponent {...rest} />
        </div>
      </div>
    );
  },
);
