import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { selectChatVisible, setChatVisible, getHsIdentificationToken } from 'data/user';
import SVG from 'components/base/SVG';

import css from './style.scss';


class SessionOverlay extends PureComponent {
  constructor(props) {
    super(props);

    this.handleOverlayClick = this.handleOverlayClick.bind(this);

    this.state = { loaded: false };
  }

  componentWillReceiveProps(props) {
    const { getHsIdentificationToken, visible } = this.props;

    if (props.visible && !this.state.loaded) {
      if (!this.state.loaded) {
        this.setState({ loaded: true }, () => {
          const loadHs = () => window.HubSpotConversations.widget.load({ widgetOpen: true });

          getHsIdentificationToken(({ response: { email, token } }) => {
            window.hsConversationsSettings.identificationEmail = email;
            window.hsConversationsSettings.identificationToken = token;

            loadHs();
          }, loadHs);
        });
      } else if (!visible) window.HubSpotConversations.widget.open();
    }
  }

  handleOverlayClick() {
    this.props.setChatVisible(false);
  }

  render() {
    const { visible } = this.props;

    return (
      <div className={classNames(css.chatOverlay, { [css.visible]: visible })} onClick={this.handleOverlayClick}>
        <div className={css.chatContainer} id="hsChat">
          <SVG icon="spinner" />
          <div className={css.closeBtn}>
            <SVG icon="iconClose" />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  visible: selectChatVisible(state),
}), {
  setChatVisible,
  getHsIdentificationToken,
})(SessionOverlay);
