import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { List } from 'immutable';
import { ElementTypes, selectPlaceholders, selectEmailCampaign } from 'data/campaigns';
import { selectProfile } from 'data/user';
import { formatDocumentUrl } from 'utils/URL';

import css from './style.scss';

import layout1 from './layout1.png';
import layout2 from './layout2.png';
import layout3 from './layout3.png';
import layout4 from './layout4.png';
import layout5 from './layout5.png';


const style1 = (headerText, bodyText, banner = {}, logo = {}, logo2 = {}) => (
  <div>
    {!logo.image ? null : (
      <div className={css.logo}>
        <img src={formatDocumentUrl(logo.image)} alt="" style={{ width: `${logo.width}px` }} />
      </div>
    )}
    {!banner.image ? null : (
      <div className={css.banner}>
        <img src={formatDocumentUrl(banner.image)} alt="" />
      </div>
    )}
    {headerText === '' ? null : ([
      <div className={css.title} dangerouslySetInnerHTML={{ __html: headerText }} />,
      <div className={css.sep} />,
    ])}
    {bodyText === '' ? null : <div className={css.text} dangerouslySetInnerHTML={{ __html: bodyText }} />}
    {!logo2.image ? null : (
      <div className={classNames(css.logo2, css.center)}>
        <img src={formatDocumentUrl(logo2.image)} alt="" style={{ width: `${logo2.width}px` }} />
      </div>
    )}
  </div>
);

const style2 = (headerText, bodyText, banner = {}, logo = {}, logo2 = {}) => (
  <div>
    {!logo.image ? null : (
      <div className={css.logo}>
        <img src={formatDocumentUrl(logo.image)} alt="" style={{ width: `${logo.width}px` }} />
      </div>
    )}
    {headerText === '' ? null : ([
      <div className={css.title} dangerouslySetInnerHTML={{ __html: headerText }} />,
      <div className={css.sep} />,
    ])}
    {bodyText === '' ? null : <div className={css.text} dangerouslySetInnerHTML={{ __html: bodyText }} />}
    <div className={css.center}>
      <button>LEARN MORE</button>
      {!banner.image ? null : (
        <div className={css.banner}>
          <img src={formatDocumentUrl(banner.image)} alt="" />
        </div>
      )}
      {!logo2.image ? null : (
        <div className={css.logo2}>
          <img src={formatDocumentUrl(logo2.image)} alt="" style={{ width: `${logo2.width}px` }} />
        </div>
      )}
    </div>
  </div>
);

const style3 = (headerText, bodyText, banner = {}, logo = {}, logo2 = {}) => (
  <div>
    {!logo.image ? null : (
      <div className={css.logo}>
        <img src={formatDocumentUrl(logo.image)} alt="" style={{ width: `${logo.width}px` }} />
      </div>
    )}
    {headerText === '' ? null : <div className={css.title} dangerouslySetInnerHTML={{ __html: headerText }} />}
    {!banner.image ? null : (
      <div className={css.banner}>
        <img src={formatDocumentUrl(banner.image)} alt="" />
      </div>
    )}
    {bodyText === '' ? null : <div className={css.text} dangerouslySetInnerHTML={{ __html: bodyText }} />}
    <div className={css.center}>
      <button>LEARN MORE</button>
      {!logo2.image ? null : (
        <div className={css.logo2}>
          <img src={formatDocumentUrl(logo2.image)} alt="" style={{ width: `${logo2.width}px` }} />
        </div>
      )}
    </div>
  </div>
);

export const layouts = [
  {
    id: 1,
    icon: layout1,
    generate: style1,
  },
  {
    id: 2,
    icon: layout2,
    generate: style1,
  },
  {
    id: 3,
    icon: layout3,
    generate: style2,
  },
  {
    id: 4,
    icon: layout4,
    generate: style3,
  },
  {
    id: 5,
    icon: layout5,
    generate: style3,
  },
];

class EmailPreview extends PureComponent {
  getText(field) {
    const { campaign, placeholders } = this.props;

    let val = (campaign.get(field) || '').trim();
    if (val !== '' && placeholders) val = placeholders.reduce((val, p) => val.replace(new RegExp(p.expression, 'g'), p.value), val);

    return val;
  }

  render() {
    const { campaign, profile, placeholders, confirmation } = this.props;
    const layoutId = campaign.get('layoutId');
    const elements = (campaign.get('elements') || List()).toJS();

    const logo = elements.find(e => e.type === ElementTypes.LOGO) || {};
    const logo2 = elements.find(e => e.type === ElementTypes.LOGO2) || {};

    if (!logo.width) logo.width = 175;
    if (!logo2.width) logo2.width = 125;

    return (
      <div style={{ width: '500px' }}>
        <div className={classNames(css.emailPreviewContainer, { [css.confirmation]: confirmation })}>
          <div className={classNames(css.preview, css.layout, css[`layout${campaign.get('layoutId')}`])}>
            <div className={css.content}>
              <div className={css.top}>
                Having trouble viewing this email? <a>View the online version</a>
              </div>
              {
                layouts[layoutId - 1].generate(
                  this.getText('headerText'),
                  this.getText('bodyText'),
                  elements.find(e => e.type === ElementTypes.BANNER),
                  logo,
                  logo2,
                )
              }
              <div className={css.sep} />
              <div className={css.bottom}>
                {profile.get('businessName', 'Business Name')} | {profile.get('businessStreetAddress', '12345 Business St')} | {profile.get('businessCity', 'City')}, {profile.get('businessState', 'ST')} {profile.get('businessZip', '00000')}
                <br />
                Not interested any longer? <a>Click here to unsubscribe</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmailPreview.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  campaign: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  profile: PropTypes.object,
  confirmation: PropTypes.boolean,
};

EmailPreview.defaultProps = {
  confirmation: false,
};

export default connect(state => ({
  placeholders: selectPlaceholders(state),
  campaign: selectEmailCampaign(state),
  profile: selectProfile(state),
}))(EmailPreview);
