import getField from './getField';


export default function getFieldAmount(fields, ...args) {
  let amount = 0;

  for (let i = 0; i < args.length; i++) {
    const f = getField(fields, args[i]);
    amount += f == null ? 0 : f.amount;
  }

  return amount;
}
