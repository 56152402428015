/** @flow */
import { Priority } from './constants';


export const OPEN_POPUP = ('OPEN_POPUP');
export const CLOSE_POPUP = ('CLOSE_POPUP');
export const CLOSE_ALL_POPUP = ('CLOSE_ALL_POPUP');

export function openPopup(popup, popupProperties = {}) {
  const { priority = Priority.DEFAULT } = popupProperties;

  return { type: OPEN_POPUP, popup, priority, props: { ...popupProperties, priority } };
}

export const closePopup = (target= {}) => ({ type: CLOSE_POPUP, target });

export const closeAllPopup = (priority) => ({ type: CLOSE_ALL_POPUP, priority });
