import Button from 'components/Button';
import React, { Component } from 'react';

export default class CancelModalOfferAccept extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { profile } = this.props;
    const [year, month, date]= this.props.nextBillingDate.split('-');
    let nextBillingDate = `${month}/${date}/${year.slice(2)}`;
    return (
      <span>
        {/* <Activities onClick={this.handleClose} /> */}
        <div
          style={{ width: '1160px', padding: '15px 25px 0', display: 'flex' }}
        >
          <div style={{ width: '50%', paddingBottom: '25px' }}>
          <img
              src='https://signup.propstream.com/resource/logo?c=CA02937056'
              placeholder='https://signup.propstream.com/resource/logo?c=CA02937056'
              class='_159lC__logoSmall'
              style={{ width: '138px', height: '76px', visibility: `${this.props.brandCode.includes('propstream')? 'visible': 'hidden'}` }}
            />
            <div
              style={{
                marginTop: '30px',
                color: '#3D3D3D',
                font: 'normal normal bold 30px/30px Helvetica Neue',
                lineHeight: 1.2,
              }}
            >
              We are so happy that you decided to stay with us!
            </div>
            <div
              style={{
                marginTop: '15px',
                font: 'normal normal bold 16px/16px Helvetica Neue',
                color: '#3D3D3D',
                lineHeight: 1.2,
              }}
            >
              Congratulations, your account has now been extended for another<br/>
              {this.props.accountType ? 'month' : '7 days'}! Your {this.props.brandCode.includes('propstream')?'next': 'new'} billing
              date will be{' '}
              <span style={{ color: `${this.props.brandCode.includes('propstream')?'#F16722': '#297CCF'}` }}>
                {nextBillingDate}
              </span>
              .
            </div>
            <div
              style={{
                marginTop: '21px',
                font: 'normal normal bold 14px Helvetica Neue',
                color: '#3D3D3D',
                lineHeight: 1.2,
              }}
            >
              {this.props.brandCode.includes('propstream') && <span style={{ color: '#F16722' }}>Fun fact:</span>} Did you know
              that the profit from one fix and flip project could pay for your{' '}
              {this.props.brandCode.includes('propstream') && 'PropStream'} subscription for 25 years?
            </div> 
            <span style={{visibility: `${this.props.brandCode.includes('propstream')? 'visible': 'hidden'}`}}>
            <div
              style={{
                marginTop: '21px',
                font: 'normal normal bold 14px Helvetica Neue',
                color: '#3D3D3D',
              }}
            >
              To help you get your first deal in the next
              {this.props.accountType ? ' month' : ' 7 days'}, make sure you:
            </div>
            <ul
              style={{
                margin: 0,
                marginTop: '10px',
                lineHeight: 1.5,
              }}
            >
              <li>
                Go to our daily live webinars (
                <a
                  style={{ color: '#F16722', textDecoration: 'none' }}
                  href='https://www.propstream.com/webinars-training'
                  target='_blank'
                >
                  Click here for the schedule
                </a>
                ).
              </li>
              <li>
                Take a free course with the{' '}
                <a
                  style={{ color: '#F16722', textDecoration: 'none' }}
                  href='https://theacademy.propstream.com/learn'
                  target='_blank'
                >
                  PropStream Academy
                </a>
                .
              </li>
              <li>
                Perform a skip trace to get homeowner contact information.
              </li>
              <li>
                Make calls, send postcards, and make your first deal. Check out
                our{' '}
                <a
                  style={{ color: '#F16722', textDecoration: 'none' }}
                  href='https://www.propstream.com/7-day-free-trial-getting-started'
                  target='_blank'
                >
                  free online guide here
                </a>
                .
              </li>
            </ul>
            </span>
            <div
              style={{
                marginTop: '32px',
                font: 'normal normal bold 12px Helvetica Neue',
                color: '#3D3D3D',
                lineHeight: 1.5,
              }}
            >
              Terms and Conditions
            </div>
            <div
              style={{
                font: 'normal normal 11px Helvetica Neue',
                color: '#3D3D3D',
                lineHeight: 1.5,
              }}
            >
              {this.props.accountType
                ? 'This promotional offer is only available to customers with a full paying subscription and may only be used one time. The extension period will be added to the end of your current billing cycle date. Following the extension period, your card will be charged, and your subscription will continue on a month-to-month basis until canceled. To cancel your account at any time, you may follow the steps of the Cancel button within your account. Any unused promotional days will expire upon cancelation. No cash value. Void where prohibited.'
                : 'This promotional offer is only available to customers within their trial period and may only be used one time. The extension period will be added to the end of your current billing cycle date. Following the extension period, your card will be charged, and your subscription will continue on a month-to-month basis until canceled. To cancel your account at any time, you may follow the steps of the Cancel button within your account. Any unused promotional or trial days will expire upon cancelation. No cash value. Void where prohibited.'}
            </div>
          </div>
          <div style={{ width: '50%' }}>
            <img src={'/assets/images/cancelOfferAccept.jpg'} alt='' height='640px' />
            <div
              style={{ position: 'absolute', bottom: '20px', right: '60px' }}
            >
              <Button onClick={() => this.props.handleClose()}>Close</Button>
            </div>
          </div>
        </div>
      </span>
    );
  }
}
