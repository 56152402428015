import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import { selectProfile, selectLoading } from 'data/user';
import Text, { Password } from 'components/Text';
import formatCurrency from 'utils/currency/numberToPrice';
import { formatDateShort } from 'utils/date/formatDate';

import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';
import { validate, verifyRequired } from 'utils/validation';

import css from './style.scss';


class AddServiceConfirmation extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRef = this.handleRef.bind(this);

    const { planMrcId, profile: { planMrcs } } = props;

    this.planMrc = planMrcs.find(p => p.id === Number(planMrcId));

    // get value for password from Bitwarden = Propstream local devuser
    this.state = process.env.NODE_ENV === 'dev-server' ? { cardCode: '333', password: '' } : { cardCode: '', password: '' };
    this.ref = {};
  }

  handleChange(ev) {
    const { name, value } = ev.target || ev;
    this.setState({ [name]: value });
  }

  handleRef(element) {
    if (element) {
      const el = element.tagName ? element : findDOMNode(element);
      this.ref[el.name] = el;
    }
  }

  handleSubmit() {
    const { onPurchase, closePopup, chargeAmount } = this.props;
    const { cardCode, password } = this.state;
    const { prorateAmount } = this.planMrc;
    const amt = chargeAmount || prorateAmount;

    const r = this.ref;

    validate(() => {
      verifyRequired(r.cardCode, !amt || cardCode, 'Card CVV is required.');
      verifyRequired(r.password, password, 'Password is required.');

      onPurchase(chargeAmount || prorateAmount, cardCode, password, closePopup);
    });
  }

  render() {
    const { profile, paymentMethodId, loading, closePopup, chargeAmount } = this.props;
    const { amount, prorateAmount, /* billDate, */ trialAvailable, trialDays } = this.planMrc;
    const { paymentMethods, nextCycleDate } = profile;
    const paymentInfo = paymentMethods.find(p => String(p.id) === String(paymentMethodId));
    const cardNumber = paymentInfo?.cardNumber;
    const inputProps = { onInputRef: this.handleRef, onChange: this.handleChange, value: this.state };
    const amt = chargeAmount || prorateAmount;
    const prorated = amt < amount;

    const fullBillDate = moment(nextCycleDate); // moment(billDate).add(1, 'M'); Unsure why month was being added before; cycle date should always be appropriate.
    const trial = trialAvailable ? `After a ${trialDays} trial, y` : 'Y';

    let message;
    if (prorated) message = `${trial}our card will be charged a prorated amount of ${formatCurrency(amt)} and charged ${formatCurrency(amount)} on a month-to-month basis beginning ${formatDateShort(fullBillDate)}.`;
    else message = `${trial}our card will be charged ${formatCurrency(amount)} on a month-to-month basis.`;

    return (
      <Modal isOpen={!!cardNumber} uniqId="AddServiceConfirmation" padding="25px" width="400px" onClose={closePopup}>
        <div className={css.confirmPurchase}>
          <div className={css.header}>Confirm Purchase</div>
          <div className={css.message}>{message}</div>
          <div className={css.fields}>
            {!amount ? null : <Text {...inputProps} name="cardCode" label={`CVV for Card ${cardNumber}`} />}
            <Password {...inputProps} name="password" label="Account Password" />
          </div>
          <div className={css.buttonContainer}>
            <Button onClick={closePopup} loading={loading}>Cancel</Button>
            <SolidButton onClick={this.handleSubmit} loading={loading}>Purchase</SolidButton>
          </div>
        </div>
      </Modal>
    );
  }
}

AddServiceConfirmation.propType = {
  paymentMethodId: PropTypes.number,
  amount: PropTypes.number,
  onPurchase: PropTypes.func.isRequired,
};

const AddServiceConfirmationPopup = connect(state => ({
  profile: selectProfile(state).toJS(),
  loading: selectLoading(state),
}))(AddServiceConfirmation);

const registrationId = getPopupRegistration(AddServiceConfirmationPopup);
AddServiceConfirmationPopup.open = props => openPopup(registrationId, { priority: Priority.HIGH, ...props });
AddServiceConfirmationPopup.close = props => closePopup(registrationId, props);

export default AddServiceConfirmationPopup;

