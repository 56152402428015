export const ReportTypes = {
  analysis: 'ANALYSIS',
  premium: 'PREMIUM',
  property: 'PROPERTY',
};

export default ReportTypes;

export const Reports = {
  comparativeMarketAnalysis: 'COMPARATIVE_MARKET_ANALYSIS',
  comparableProperty: 'COMPARABLE_PROPERTY',
};

export const Permissions = {
  contactView: 'CONTACT_VIEW',
  marketingView: 'MARKETING_VIEW',
  rehabView: 'REHAB_VIEW',
  analysisView: 'ANALYSIS_VIEW',
  searchFull: 'SEARCH_FULL',
};

export const UserTypes = [
  { value: 'FULL', label: 'Full User', colorDark: '#2468c7', colorLight: '#e5eaf1' },
  { value: 'TEAM', label: 'Pro User', colorDark: '#218c0a', colorLight: '#eff4e6' },
  { value: 'TEAM_FULL', label: 'Pro User', colorDark: '#218c0a', colorLight: '#eff4e6' },
  { value: 'SCOUT', label: 'Scout User', colorDark: '#e64f15', colorLight: '#fbebe2' },
];

export const UserTypeMap = UserTypes.reduce((m, t) => ({ ...m, [t.value]: t }), {});

export const Countries = [
  {
    code: 'US',
    states: [
      { code: 'AL', name: 'Alabama' },
      { code: 'AK', name: 'Alaska' },
      { code: 'AS', name: 'American Samoa' },
      { code: 'AZ', name: 'Arizona' },
      { code: 'AR', name: 'Arkansas' },
      { code: 'CA', name: 'California' },
      { code: 'CO', name: 'Colorado' },
      { code: 'CT', name: 'Connecticut' },
      { code: 'DE', name: 'Delaware' },
      { code: 'DC', name: 'District of Columbia' },
      { code: 'FM', name: 'Federated States of Micronesia' },
      { code: 'FL', name: 'Florida' },
      { code: 'GA', name: 'Georgia' },
      { code: 'GU', name: 'Guam' },
      { code: 'HI', name: 'Hawaii' },
      { code: 'ID', name: 'Idaho' },
      { code: 'IL', name: 'Illinois' },
      { code: 'IN', name: 'Indiana' },
      { code: 'IA', name: 'Iowa' },
      { code: 'KS', name: 'Kansas' },
      { code: 'KY', name: 'Kentucky' },
      { code: 'LA', name: 'Louisiana' },
      { code: 'ME', name: 'Maine' },
      { code: 'MH', name: 'Marshall Islands' },
      { code: 'MD', name: 'Maryland' },
      { code: 'MA', name: 'Massachusetts' },
      { code: 'MI', name: 'Michigan' },
      { code: 'MN', name: 'Minnesota' },
      { code: 'MS', name: 'Mississippi' },
      { code: 'MO', name: 'Missouri' },
      { code: 'MT', name: 'Montana' },
      { code: 'NE', name: 'Nebraska' },
      { code: 'NV', name: 'Nevada' },
      { code: 'NH', name: 'New Hampshire' },
      { code: 'NJ', name: 'New Jersey' },
      { code: 'NM', name: 'New Mexico' },
      { code: 'NY', name: 'New York' },
      { code: 'NC', name: 'North Carolina' },
      { code: 'ND', name: 'North Dakota' },
      { code: 'MP', name: 'Northern Mariana Islands' },
      { code: 'OH', name: 'Ohio' },
      { code: 'OK', name: 'Oklahoma' },
      { code: 'OR', name: 'Oregon' },
      { code: 'PW', name: 'Palau' },
      { code: 'PA', name: 'Pennsylvania' },
      { code: 'PR', name: 'Puerto Rico' },
      { code: 'RI', name: 'Rhode Island' },
      { code: 'SC', name: 'South Carolina' },
      { code: 'SD', name: 'South Dakota' },
      { code: 'TN', name: 'Tennessee' },
      { code: 'TX', name: 'Texas' },
      { code: 'AE', name: 'U.S. Armed Forces - Europe' },
      { code: 'AP', name: 'U.S. Armed Forces - Pacific' },
      { code: 'AA', name: 'U.S. Armed Forces - Americas' },
      { code: 'UT', name: 'Utah' },
      { code: 'VT', name: 'Vermont' },
      { code: 'VI', name: 'Virgin Islands' },
      { code: 'VA', name: 'Virginia' },
      { code: 'WA', name: 'Washington' },
      { code: 'WV', name: 'West Virginia' },
      { code: 'WI', name: 'Wisconsin' },
      { code: 'WY', name: 'Wyoming' },
    ],
    name: 'United States',
    stateLabel: 'State',
    postalCodePattern: '^\\d{5}(\\-?\\d{4})?$',
  },
  {
    code: 'CA',
    states: [
      { code: 'AB', name: 'Alberta' },
      { code: 'BC', name: 'British Columbia' },
      { code: 'MB', name: 'Manitoba' },
      { code: 'NB', name: 'New Brunswick' },
      { code: 'NL', name: 'Newfoundland and Labrador' },
      { code: 'NT', name: 'Northwest Territories' },
      { code: 'NS', name: 'Nova Scotia' },
      { code: 'NU', name: 'Nunavut' },
      { code: 'ON', name: 'Ontario' },
      { code: 'PE', name: 'Prince Edward Island' },
      { code: 'QC', name: 'Quebec' },
      { code: 'SK', name: 'Saskatchewan' },
      { code: 'YT', name: 'Yukon' },
    ],
    name: 'Canada',
    stateLabel: 'Province',
    postalCodePattern: '^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]\\d[a-zA-Z] *\\d[a-zA-Z]\\d$',
  },
  { code: 'AF', states: [], name: 'Afghanistan' },
  { code: 'AX', states: [], name: 'Aland Island' },
  { code: 'AL', states: [], name: 'Albania' },
  { code: 'DZ', states: [], name: 'Algeria' },
  { code: 'AD', states: [], name: 'Andorra' },
  { code: 'AO', states: [], name: 'Angola' },
  { code: 'AI', states: [], name: 'Anguilla' },
  { code: 'AG', states: [], name: 'Antigua and Barbuda' },
  { code: 'AR', states: [], name: 'Argentina' },
  { code: 'AM', states: [], name: 'Armenia' },
  { code: 'AW', states: [], name: 'Aruba' },
  { code: 'AU',
    states: [
      { code: 'ACT', name: 'Australian Capital Territory' },
      { code: 'JBT', name: 'Jervis Bay Territory' },
      { code: 'NSW', name: 'New South Wales' },
      { code: 'NT', name: 'Northern Territory' },
      { code: 'QLD', name: 'Queensland' },
      { code: 'SA', name: 'South Australia' },
      { code: 'TAS', name: 'Tasmania' },
      { code: 'VIC', name: 'Victoria' },
      { code: 'WA', name: 'Western Australia' },
    ],
    name: 'Australia',
    stateLabel: 'State' },
  { code: 'AT', states: [], name: 'Austria' },
  { code: 'AZ', states: [], name: 'Azerbaijan' },
  { code: 'BS', states: [], name: 'Bahamas' },
  { code: 'BH', states: [], name: 'Bahrain' },
  { code: 'BD', states: [], name: 'Bangladesh' },
  { code: 'BB', states: [], name: 'Barbados' },
  { code: 'BY', states: [], name: 'Belarus' },
  { code: 'BE', states: [], name: 'Belgium' },
  { code: 'BZ', states: [], name: 'Belize' },
  { code: 'BJ', states: [], name: 'Benin' },
  { code: 'BM',
    states: [
      { code: 'HA', name: 'City of Hamilton' },
      { code: 'DEV', name: 'Devonshire' },
      { code: 'HAM', name: 'Hamilton' },
      { code: 'PAG', name: 'Paget' },
      { code: 'PEM', name: 'Pembroke' },
      { code: 'SAN', name: 'Sandys' },
      { code: 'SMI', name: 'Smith\u0027s' },
      { code: 'SOU', name: 'Southampton' },
      { code: 'SGE', name: 'St. George\u0027s' },
      { code: 'SG', name: 'Town of St. George\u0027s' },
      { code: 'WAR', name: 'Warwick' },
    ],
    name: 'Bermuda',
    stateLabel: 'Parish' },
  { code: 'BT', states: [], name: 'Bhutan' },
  { code: 'BO', states: [], name: 'Bolivia' },
  { code: 'BQ', states: [], name: 'Bonaire' },
  { code: 'BA', states: [], name: 'Bosnia-Herzegovina' },
  { code: 'BW', states: [], name: 'Botswana' },
  { code: 'BR', states: [], name: 'Brazil' },
  { code: 'BN', states: [], name: 'Brunei Darussalam' },
  { code: 'BG', states: [], name: 'Bulgaria' },
  { code: 'BF', states: [], name: 'Burkina Faso' },
  { code: 'BI', states: [], name: 'Burundi' },
  { code: 'KH', states: [], name: 'Cambodia' },
  { code: 'CM', states: [], name: 'Cameroon' },
  { code: 'KY', states: [], name: 'Cayman Islands' },
  { code: 'CF', states: [], name: 'Central African Republic' },
  { code: 'TD', states: [], name: 'Chad' },
  { code: 'CL', states: [], name: 'Chile' },
  { code: 'CN', states: [], name: 'China' },
  { code: 'CX', states: [], name: 'Christmas Island' },
  { code: 'CC', states: [], name: 'Cocos Island' },
  { code: 'CO', states: [], name: 'Colombia' },
  { code: 'KM', states: [], name: 'Comoros' },
  { code: 'CG', states: [], name: 'Congo' },
  { code: 'CD', states: [], name: 'Congo, Democratic Republic of the' },
  { code: 'CK', states: [], name: 'Cook Islands' },
  { code: 'CR', states: [], name: 'Costa Rica' },
  { code: 'HR', states: [], name: 'Croatia' },
  { code: 'CW', states: [], name: 'Curacao' },
  { code: 'CY', states: [], name: 'Cyprus' },
  { code: 'CZ', states: [], name: 'Czech Republic' },
  { code: 'DK', states: [], name: 'Denmark' },
  { code: 'DJ', states: [], name: 'Djibouti' },
  { code: 'DM', states: [], name: 'Dominica' },
  { code: 'DO', states: [], name: 'Dominican Republic' },
  { code: 'EC', states: [], name: 'Ecuador' },
  { code: 'EG', states: [], name: 'Egypt' },
  { code: 'SV', states: [], name: 'El Salvador' },
  { code: 'GQ', states: [], name: 'Equatorial Guinea' },
  { code: 'ER', states: [], name: 'Eritrea' },
  { code: 'EE', states: [], name: 'Estonia' },
  { code: 'ET', states: [], name: 'Ethiopia' },
  { code: 'FK', states: [], name: 'Falkland Islands' },
  { code: 'FO', states: [], name: 'Faroe Islands' },
  { code: 'FJ', states: [], name: 'Fiji' },
  { code: 'FI', states: [], name: 'Finland' },
  { code: 'FR', states: [], name: 'France' },
  { code: 'GF', states: [], name: 'French Guiana' },
  { code: 'PF', states: [], name: 'French Polynesia' },
  { code: 'GA', states: [], name: 'Gabon' },
  { code: 'GM', states: [], name: 'Gambia' },
  { code: 'GE', states: [], name: 'Georgia' },
  { code: 'DE', states: [], name: 'Germany' },
  { code: 'GH', states: [], name: 'Ghana' },
  { code: 'GI', states: [], name: 'Gibraltar' },
  { code: 'GR', states: [], name: 'Greece' },
  { code: 'GL', states: [], name: 'Greenland' },
  { code: 'GD', states: [], name: 'Grenada' },
  { code: 'GP', states: [], name: 'Guadeloupe' },
  { code: 'GU', states: [], name: 'Guam' },
  { code: 'GT', states: [], name: 'Guatemala' },
  { code: 'GG', states: [], name: 'Guernsey' },
  { code: 'GN', states: [], name: 'Guinea' },
  { code: 'GW', states: [], name: 'Guinea-Bissau' },
  { code: 'GY', states: [], name: 'Guyana' },
  { code: 'HT', states: [], name: 'Haiti' },
  { code: 'HN', states: [], name: 'Honduras' },
  { code: 'HK', states: [], name: 'Hong Kong' },
  { code: 'HU', states: [], name: 'Hungary' },
  { code: 'IS', states: [], name: 'Iceland' },
  { code: 'IN', states: [], name: 'India' },
  { code: 'ID', states: [], name: 'Indonesia' },
  { code: 'IR', states: [], name: 'Iran' },
  { code: 'IQ', states: [], name: 'Iraq' },
  { code: 'IE', states: [], name: 'Ireland' },
  { code: 'IM', states: [], name: 'Isle of Man' },
  { code: 'IL', states: [], name: 'Israel' },
  { code: 'IT', states: [], name: 'Italy' },
  { code: 'JM', states: [], name: 'Jamaica' },
  { code: 'JP', states: [], name: 'Japan' },
  { code: 'JE', states: [], name: 'Jersey' },
  { code: 'JO', states: [], name: 'Jordan' },
  { code: 'KZ', states: [], name: 'Kazakhstan' },
  { code: 'KE', states: [], name: 'Kenya' },
  { code: 'KI', states: [], name: 'Kiribati' },
  { code: 'KW', states: [], name: 'Kuwait' },
  { code: 'KG', states: [], name: 'Kyrgyzstan' },
  { code: 'LA', states: [], name: 'Laos' },
  { code: 'LV', states: [], name: 'Latvia' },
  { code: 'LB', states: [], name: 'Lebanon' },
  { code: 'LS', states: [], name: 'Lesotho' },
  { code: 'LR', states: [], name: 'Liberia' },
  { code: 'LY', states: [], name: 'Libya' },
  { code: 'LI', states: [], name: 'Liechtenstein' },
  { code: 'LT', states: [], name: 'Lithuania' },
  { code: 'LU', states: [], name: 'Luxembourg' },
  { code: 'MO', states: [], name: 'Macao' },
  { code: 'MK', states: [], name: 'Macedonia' },
  { code: 'MG', states: [], name: 'Madagascar' },
  { code: 'MW', states: [], name: 'Malawi' },
  { code: 'MY',
    states: [
      { code: 'KUL', name: 'Federal Territory of Kuala Lumpur' },
      { code: 'LBN', name: 'Federal Territory of Labuan' },
      { code: 'PJY', name: 'Federal Territory of Putrajaya' },
      { code: 'JHR', name: 'Johor' },
      { code: 'KDH', name: 'Kedah' },
      { code: 'KTN', name: 'Kelantan' },
      { code: 'MLK', name: 'Malacca' },
      { code: 'NSN', name: 'Negeri Sembilan' },
      { code: 'PHG', name: 'Pahang' },
      { code: 'PNG', name: 'Penang' },
      { code: 'PRK', name: 'Perak' },
      { code: 'PLS', name: 'Perlis' },
      { code: 'SBH', name: 'Sabah' },
      { code: 'SRW', name: 'Sarawak' },
      { code: 'SGR', name: 'Selangor' },
      { code: 'TRG', name: 'Terengganu' },
    ],
    name: 'Malaysia',
    stateLabel: 'State' },
  { code: 'MV', states: [], name: 'Maldives' },
  { code: 'ML', states: [], name: 'Mali' },
  { code: 'MT', states: [], name: 'Malta' },
  { code: 'MQ', states: [], name: 'Martinique' },
  { code: 'MR', states: [], name: 'Mauritania' },
  { code: 'MU', states: [], name: 'Mauritius' },
  { code: 'YT', states: [], name: 'Mayotte' },
  { code: 'MX',
    states: [
      { code: 'DU', name: 'Durango' },
      { code: 'AG', name: ' Aguascalientes' },
      { code: 'BN', name: ' Baja California' },
      { code: 'BS', name: ' Baja California Sur' },
      { code: 'CM', name: ' Campeche' },
      { code: 'CP', name: ' Chiapas' },
      { code: 'CH', name: ' Chihuahua' },
      { code: 'CA', name: ' Coahuila' },
      { code: 'CL', name: ' Colima' },
      { code: 'DF', name: ' Federal District' },
      { code: 'GT', name: ' Guanajuato' },
      { code: 'GR', name: ' Guerrero' },
      { code: 'HI', name: ' Hidalgo' },
      { code: 'JA', name: ' Jalisco' },
      { code: 'MX', name: ' Mexico State' },
      { code: 'MC', name: ' Michoacán' },
      { code: 'MR', name: ' Morelos' },
      { code: 'NA', name: ' Nayarit' },
      { code: 'NL', name: ' Nuevo León' },
      { code: 'OA', name: ' Oaxaca' },
      { code: 'PU', name: ' Puebla' },
      { code: 'QE', name: ' Querétaro' },
      { code: 'QR', name: ' Quintana Roo' },
      { code: 'SL', name: ' San Luis Potosí' },
      { code: 'SI', name: ' Sinaloa' },
      { code: 'SO', name: ' Sonora' },
      { code: 'TB', name: ' Tabasco' },
      { code: 'TM', name: ' Tamaulipas' },
      { code: 'TL', name: ' Tlaxcala' },
      { code: 'VE', name: ' Veracruz' },
      { code: 'YU', name: ' Yucatán' },
      { code: 'ZA', name: ' Zacatecas' },
    ],
    name: 'Mexico',
    stateLabel: 'State',
  },
  { code: 'MD', states: [], name: 'Moldova' },
  { code: 'MC', states: [], name: 'Monaco' },
  { code: 'MN', states: [], name: 'Mongolia' },
  { code: 'ME', states: [], name: 'Montenegro' },
  { code: 'MS', states: [], name: 'Montserrat' },
  { code: 'MA', states: [], name: 'Morocco' },
  { code: 'MZ', states: [], name: 'Mozambique' },
  { code: 'MM', states: [], name: 'Myanmar' },
  { code: 'NA', states: [], name: 'Namibia' },
  { code: 'NR', states: [], name: 'Nauru' },
  { code: 'NP', states: [], name: 'Nepal' },
  { code: 'NL', states: [], name: 'Netherlands' },
  { code: 'NC', states: [], name: 'New Caledonia' },
  { code: 'NZ', states: [], name: 'New Zealand' },
  { code: 'NI', states: [], name: 'Nicaragua' },
  { code: 'NE', states: [], name: 'Niger' },
  { code: 'NG', states: [], name: 'Nigeria' },
  { code: 'NU', states: [], name: 'Niue' },
  { code: 'NF', states: [], name: 'Norfolk Island' },
  { code: 'NO', states: [], name: 'Norway' },
  { code: 'OM', states: [], name: 'Oman' },
  { code: 'PK', states: [], name: 'Pakistan' },
  { code: 'PA', states: [], name: 'Panama' },
  { code: 'PG', states: [], name: 'Papua New Guinea' },
  { code: 'PY', states: [], name: 'Paraguay' },
  { code: 'PE', states: [], name: 'Peru' },
  { code: 'PH', states: [], name: 'Philippines' },
  { code: 'PN', states: [], name: 'Pitcairn Island' },
  { code: 'PL', states: [], name: 'Poland' },
  { code: 'PT', states: [], name: 'Portugal' },
  { code: 'PR', states: [], name: 'Puerto Rico' },
  { code: 'QA', states: [], name: 'Qatar' },
  { code: 'RE', states: [], name: 'Reunion' },
  { code: 'RO', states: [], name: 'Romania' },
  { code: 'RU', states: [], name: 'Russia' },
  { code: 'RW', states: [], name: 'Rwanda' },
  { code: 'BL', states: [], name: 'Saint Barthelemy' },
  { code: 'SH', states: [], name: 'Saint Helena' },
  { code: 'KN', states: [], name: 'Saint Kitts' },
  { code: 'LC', states: [], name: 'Saint Lucia' },
  { code: 'MF', states: [], name: 'Saint Martin' },
  { code: 'PM', states: [], name: 'Saint Pierre and Miquelon' },
  { code: 'VC', states: [], name: 'Saint Vincent and the Grenadines' },
  { code: 'SM', states: [], name: 'San Marino' },
  { code: 'ST', states: [], name: 'Sao Tome and Principe' },
  { code: 'SA', states: [], name: 'Saudi Arabia' },
  { code: 'SN', states: [], name: 'Senegal' },
  { code: 'RS', states: [], name: 'Serbia' },
  { code: 'SC', states: [], name: 'Seychelles' },
  { code: 'SL', states: [], name: 'Sierra Leone' },
  { code: 'SG', states: [], name: 'Singapore' },
  { code: 'SX', states: [], name: 'Sint Maarten' },
  { code: 'SK', states: [], name: 'Slovakia' },
  { code: 'SI', states: [], name: 'Slovenia' },
  { code: 'SB', states: [], name: 'Solomon Islands' },
  { code: 'ZA', states: [], name: 'South Africa' },
  { code: 'KR', states: [], name: 'South Korea' },
  { code: 'ES', states: [], name: 'Spain' },
  { code: 'LK', states: [], name: 'Sri Lanka' },
  { code: 'SD', states: [], name: 'Sudan' },
  { code: 'SR', states: [], name: 'Suriname' },
  { code: 'SZ', states: [], name: 'Swaziland' },
  { code: 'SE', states: [], name: 'Sweden' },
  { code: 'CH', states: [], name: 'Switzerland' },
  { code: 'TW', states: [], name: 'Taiwan' },
  { code: 'TJ', states: [], name: 'Tajikistan' },
  { code: 'TZ', states: [], name: 'Tanzania' },
  { code: 'TH', states: [], name: 'Thailand' },
  { code: 'TL', states: [], name: 'Timor-Leste' },
  { code: 'TG', states: [], name: 'Togo' },
  { code: 'TK', states: [], name: 'Tokelau' },
  { code: 'TO', states: [], name: 'Tonga' },
  { code: 'TT', states: [], name: 'Trinidad and Tobago' },
  { code: 'TN', states: [], name: 'Tunisia' },
  { code: 'TR', states: [], name: 'Turkey' },
  { code: 'TM', states: [], name: 'Turkmenistan' },
  { code: 'TC', states: [], name: 'Turks and Caicos Islands' },
  { code: 'TV', states: [], name: 'Tuvalu' },
  { code: 'UG', states: [], name: 'Uganda' },
  { code: 'UA', states: [], name: 'Ukraine' },
  { code: 'AE', states: [], name: 'United Arab Emirates' },
  { code: 'GB', states: [], name: 'United Kingdom' },
  { code: 'UY', states: [], name: 'Uruguay' },
  { code: 'UZ', states: [], name: 'Uzbekistan' },
  { code: 'VU', states: [], name: 'Vanuatu' },
  { code: 'VE', states: [], name: 'Venezuela' },
  { code: 'VN', states: [], name: 'Viet Nam' },
  { code: 'VG', states: [], name: 'Virgin Islands (British)' },
  { code: 'WF', states: [], name: 'Wallis and Futuna Islands' },
  { code: 'YE', states: [], name: 'Yemen' },
  { code: 'ZM', states: [], name: 'Zambia' },
  { code: 'ZW', states: [], name: 'Zimbabwe' },
];

export const CountryOptions = Countries.map(country => ({ label: country.name, value: country.code }));
export const CountryMap = Countries.reduce((map, country) => {
  const c = Object.assign({}, country);
  const m = map;

  if (!c.postalCodeLabel) c.postalCodeLabel = country.code === 'US' ? 'Zip Code' : 'Postal Code';
  if (!c.stateLabel) c.stateLabel = 'State';

  m[c.code] = c;
  return m;
}, {});

export const UsStateOptions = [{ value: '', label: '' }].concat(CountryMap.US.states.map(state => ({ label: state.name, value: state.code })));
export const UsStateShortOptions = UsStateOptions.map(s => ({ value: s.value, label: s.value }));

export const CcMonthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => ({ label: month, value: month }));

export const getCcYearOptions = (selectedYear) => {
  const year = new Date().getFullYear();
  const yearOptions = [];
  if (selectedYear < year) yearOptions.push({ label: selectedYear, value: selectedYear });
  for (let i = 0; i < 15; i++) yearOptions.push({ label: year + i, value: year + i });

  return yearOptions;
};

export const CVV_PAYMENT_THRESHOLD = 100;
