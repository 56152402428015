const a = document.createElement('a');
document.body.appendChild(a);
a.style.display = 'none';

function getFileName(response) {
  const header = response.headers['content-disposition'];
  if (!header) return null;
  const matches = header.match(new RegExp('(?:^|; )filename="([^;]*)"'));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export default function (response) {
  if (!response || !response.xhr || !(response.xhr.response instanceof Blob)) return;

  // Check for IE. Normal method results in "Access is Denied" error, and IE's special function must be used instead.
  if (window.navigator && window.navigator.msSaveOrOpenBlob) window.navigator.msSaveOrOpenBlob(response.xhr.response);
  else {
    const blobUrl = window.URL.createObjectURL(response.xhr.response);
    setTimeout(() => window.URL.revokeObjectURL(blobUrl), 0);
    const fileName = getFileName(response);
    a.href = blobUrl;
    a.download = fileName || 'download';
    a.click();
  }
}
