import React, { createContext } from 'react';

const deferRegistrator = (Comp) => {
  const DeferRegistrator = (props) => {
    const { context } = createContext();

    const defer = (cb) => {
      context.registerDeferred(cb);
    };

    return <Comp {...props} defer={defer} />;
  };
  return DeferRegistrator;
};

export default deferRegistrator;