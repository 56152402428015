import React from 'react';

import Modal from 'components/base/Modal';
import map from './map-modal.jpg';
import css from './style.scss';

const SavedSearchInfo = (props) => {
  return (
    <Modal
      {...props}
      isCloseButton
      padding="50px"
      width="815px"
      outerClassName={css.savedSearchInfo}
      uniqId="SavedSearchInfo"
      caption={(
        <div className={css.header}>
          <div>
            <div className={css.headerTitle}>Home Screen</div>
            <div className={css.tinyCaption}>Add a saved search to the home screen for quick and easy access!</div>
          </div>
        </div>
      )}
    >
      <img className={css.map} src={map} alt="" />
    </Modal>
  );
};

export default SavedSearchInfo;
