import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateFilter, clearFilter, selectLocation, selectFilter } from 'data/search';

import Button from 'components/base/Button';
import { dropdownable } from 'components/base/Dropdown';
import SearchFilterEditor from 'app/components/SearchFilter';
import SVG from 'components/base/SVG';
import css from './style.scss';


export class SearchFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(data) {
    // test here how data coming
    console.log(data)
    this.props.updateFilter(data);
  }

  render() {
    const { closeDropdown, isOpen, onRef, onClick, location, filter, clearFilter } = this.props;

    return (
      <div onClick={onClick} className={css.filter} name="dropdown-btn">
        <div className={css.value}>Filter</div>
        <SVG icon={isOpen ? 'iconCaretUp' : 'iconCaretDown'} className={css.iconCaretDown} />
        {!isOpen ? null : (
          <div className={css.dropdown} ref={onRef}>
            <div className={css.header}>
              <div className={css.caption}>Search Options</div>
              <Button kind="link-default" className={css.btnClose} onClick={closeDropdown}>
                <SVG icon="iconClose" className={css.iconClose} />
              </Button>
            </div>
            <SearchFilterEditor onClose={closeDropdown} onReset={clearFilter} onChange={this.handleChange} location={location} search={filter} />
          </div>
        )}
      </div>
    );
  }
}

export default dropdownable(connect(state => ({
  location: selectLocation(state),
  filter: selectFilter(state),
}), {
  updateFilter,
  clearFilter,
})(SearchFilter));
