import React from 'react';
import { Field } from 'components/ReduxForm';


export const Layout = () => (
  <div>
    <div>
      <Field name="myField1" component="input" />
    </div>
    <div>
      <Field name="myField1" component="input" />
    </div>

    <div>
      <Field name="myField2" component="input" />
    </div>

    <button>Submit</button>
  </div>
);

export default Layout;
