/** @flow */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { selectAnalysisReports } from 'data/user/selectors';
import { selectIsLoading } from 'data/analyses/selectors';
import { loadAnalysisReport } from 'data/analyses/actions';
import Button from 'components/base/Button';

import css from '../style.scss';


class Finish extends PureComponent {
  constructor(props) {
    super(props);
    this.handleReportDownload = this.handleReportDownload.bind(this);
  }

  /* :: handleReportDownload: Function */
  handleReportDownload(reportCode) {
    const { analysisParams, loadAnalysisReport } = this.props;
    loadAnalysisReport(analysisParams, reportCode);
  }

  render() {
    const { isLoading, analysisReports } = this.props;

    return (
      <div className={css.root}>
        {analysisReports.map(report => (
          <div className={css.report} key={report.get('code')}>
            <div className={css.caption}>{report.get('name')}</div>
            <div className={css.description}>{report.get('description')}</div>
            <Button kind={Button.kind.grayGhost} size={Button.size.large} onClick={() => this.handleReportDownload(report.get('code'))} isLoading={isLoading}>
              Download
            </Button>
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    analysisReports: selectAnalysisReports(state),
    isLoading: selectIsLoading(state),
  };
}

const mapActionToProps = {
  loadAnalysisReport,
};

export default connect(mapStateToProps, mapActionToProps)(Finish);
