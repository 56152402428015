import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import classNames from 'classnames';

import { Button, ButtonUpload } from 'components/base/Button';
import Dropdown from 'components/base/Dropdown';
import { imageFormatList } from 'config/constants/fileFormats';
import { defaultGraphicElement, ElementTypes, placeholderOptions } from 'data/campaigns';
import FroalaEditor from 'components/FroalaEditor';
import { froalaMinimalConfig } from 'config/constants/wysiwyg';
import { createConfirmable, type } from 'app/components/Confirm';

import GraphicViewer from '../GraphicViewer';
import ContentTile from './ContentTile';
import TextContent from './TextContent';
import Color from './Color';
import css from './style.scss';


let elementKeyCounter = 1;

const clone = obj => JSON.parse(JSON.stringify(obj));
const cloneGraphic = (graphic) => {
  const g = clone(graphic);
  g.elements = (g.elements || []).map(el => Object.assign(el, { key: elementKeyCounter++ }));

  return g;
};

class GraphicEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSelectElement = this.handleSelectElement.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleEditorInitialized = this.handleEditorInitialized.bind(this);
    this.handleBrightnessChange = this.handleBrightnessChange.bind(this);
    this.handleOpacityChange = this.handleOpacityChange.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleAddTextClick = this.handleAddTextClick.bind(this);
    this.handleAddImage = this.handleAddImage.bind(this);
    this.handleSizeClick = this.handleSizeClick.bind(this);
    this.handlePlaceholderChange = this.handlePlaceholderChange.bind(this);

    this.editor = null;
    this.config = Object.assign({}, froalaMinimalConfig, { events: { 'froalaEditor.initialized': this.handleEditorInitialized } });

    this.state = {
      graphic: cloneGraphic(props.graphic),
      selectedElement: null,
      textElementSelected: false,
      history,
    };

    this.historyIndex = 0;
    this.history = [];
  }

  componentWillReceiveProps(props) {
    if (props.graphic.id !== this.state.graphic.id) this.setState({ graphic: cloneGraphic(props.graphic) });
  }

  handleChange(data, element, dragging = false) {
    const graphic = { ...this.state.graphic };
    const { selectedElement: origSelectedElement } = this.state;
    let selectedElement = origSelectedElement;

    // Hack: Doing this here rather than constructor because we need to wait for images to load (with their size) info
    // before cloning. Eventually get image size data persisted so we don't have to rely on the browser for it.
    if (!this.history.length) this.history.push(clone(graphic));

    if (!element) Object.assign(graphic, data);
    else {
      const { elements } = graphic;
      const { type } = element;

      if (!element.key) {
        const { image } = data;
        let x = 0;
        let y = 0;
        let width = 0;
        let height = 0;

        if (!image) {
          width = 600;
          height = 300;
        } else if (type !== ElementTypes.BACKGROUND_IMAGE) {
          width = image.width;
          height = image.height;
        }

        if (width || height) {
          const { size } = graphic;
          x = Math.round((size.width / 2) - (width / 2));
          y = Math.round((size.height / 2) - (height / 2));
        }

        selectedElement = Object.assign({}, defaultGraphicElement, element, { key: elementKeyCounter++ }, data, { x, y, width, height });
        elements.splice(type === ElementTypes.BACKGROUND_IMAGE ? 0 : elements.length, 0, selectedElement);
      } else {
        const index = elements.indexOf(element);
        const prevElement = elements[index];
        if (data) elements[index] = Object.assign({}, prevElement, data);
        else elements.splice(index, 1);
        if (prevElement === selectedElement) selectedElement = data ? elements[index] : null;
      }
    }

    if (!dragging) this.history.splice(this.historyIndex + 1, this.history.length - (this.historyIndex++) - 1, clone(graphic));
    if (selectedElement !== origSelectedElement) this.handleSelectElement(selectedElement);

    this.setState({ graphic });
  }

  handleTextChange(text) {
    this.handleChange({ text }, this.state.selectedElement);
  }

  handleSelectElement(selectedElement) {
    const textElementSelected = selectedElement && selectedElement.type.indexOf('HTML') >= 0;
    this.editor.edit[textElementSelected ? 'on' : 'off']();
    this.setState({ selectedElement, textElementSelected });
    if (textElementSelected) this.editor.events.focus();
  }

  handleSaveClick() {
    const { closePopup, name, onChange } = this.props;
    onChange({ name, value: this.state.graphic });
    if (closePopup) closePopup();
  }

  handleEditorInitialized(e, editor) {
    editor.edit.off();
    this.editor = editor;
  }

  handleBrightnessChange(brightness) {
    this.handleChange({ brightness }, this.state.selectedElement);
  }

  handleOpacityChange(opacity) {
    this.handleChange({ opacity }, this.state.selectedElement);
  }

  handleRemoveClick() {
    const { confirmable } = this.props;
    const { selectedElement } = this.state;

    if (selectedElement) {
      confirmable({
        caption: 'Confirm Removal',
        onOk: () => this.handleChange(null, selectedElement),
        okLabel: 'Remove',
        type: type.danger,
        question: `Are you sure you want to remove this ${selectedElement.image ? 'image' : 'text'}?`,
      })();
    }
  }

  handleSizeClick(mode) {
    const { selectedElement, graphic: { size: { width, height } } } = this.state;
    const { originalHeight, originalWidth } = selectedElement.image || { originalHeight: 300, originalWidth: 600 };
    const originalAspect = originalWidth / originalHeight;
    const aspect = width / height;
    let data = {};

    if (mode === 'stretch') data = { x: 0, y: 0, height: 0, width: 0 };
    else if (mode === 'original') data = { height: originalHeight, width: originalWidth };
    else {
      const cover = mode === 'cover';
      const wider = originalAspect >= aspect;

      data.width = ((cover && wider) || (!cover && !wider)) ? height * originalAspect : width;

      data.height = data.width / originalAspect;
      data.x = (width - data.width) / 2;
      data.y = (height - data.height) / 2;
    }

    this.handleChange(data, selectedElement);
  }

  handleKeyDown(ev) {
    if (ev.keyCode === 46) this.handleRemoveClick();
  }

  handleAddTextClick() {
    this.handleChange({ text: '<span style="font-size: 40px;"> </span>' }, { type: ElementTypes.HTML });
    this.editor.events.focus();
    this.editor.commands.selectAll();
  }

  handleAddImage({ value: { document: image } }) {
    this.handleChange({ image }, { type: ElementTypes.IMAGE });
  }

  handlePlaceholderChange({ target: { value } }) {
    this.editor.html.insert(value, false);
    this.handleChange({ text: this.editor.html.get() }, this.state.selectedElement);
  }

  moveSelectedElement(offset) {
    const { graphic, selectedElement } = this.state;
    const { elements } = graphic;
    const idx = elements.indexOf(selectedElement);
    elements.splice(idx, 1);
    elements.splice(idx + offset, 0, selectedElement);

    this.handleChange({ elements }); // Not needed except for history
  }

  moveHistory(move) {
    this.historyIndex = !move ? 0 : Math.max(0, Math.min(this.history.length - 1, this.historyIndex + move));
    const graphic = clone(this.history[this.historyIndex]);
    let { selectedElement } = this.state;
    if (selectedElement) selectedElement = (graphic.elements || []).find(el => el.key === selectedElement.key) || null;
    this.setState({ graphic, selectedElement });
  }

  render() {
    const { historyIndex, history } = this;
    const { closePopup, getOverlays, placeholders } = this.props;
    const { graphic, selectedElement, textElementSelected } = this.state;
    const { color = '#ffffff', elements } = graphic;
    const tileProps = { onChange: this.handleChange, onSelectElement: this.handleSelectElement, selectedElement };
    const idx = elements.indexOf(selectedElement);
    const sel = selectedElement || defaultGraphicElement;
    const { backgroundColor, image } = sel;
    const selection = !!selectedElement;
    const imageSelection = !!image;

    const getElement = type => elements.find(e => e.type === type) || { type };
    const customImages = elements.filter(e => e.type === ElementTypes.IMAGE);
    const grayBtnProps = { kind: Button.kind.grayGhost, size: Button.size.large };
    const blueLinkProps = { kind: Button.kind.blueLink, className: css.blueLink };
    const smallBtnProps = { kind: Button.kind.grayGhost, size: Button.size.small, disabled: !selection };
    const sliderProps = { min: 0, max: 1, step: 0.01, disabled: !selectedElement };

    return (
      <div className={css.editor} onKeyDown={ev => this.handleKeyDown(ev)} tabIndex={0} role="button">
        <div className={css.topSection}>
          <div className={css.sideSection}>
            <div className={css.caption}>Postcard Editor</div>
            <div className={css.instructions}>To prevent white space from appearing on the edges of your printed postcards, please ensure that your background image extends to the red line. The postcards will be cut along the dotted line.</div>
            <div className={css.controls} style={{ display: 'none' }}>
              <Slider {...sliderProps} value={sel.brightness} onChange={this.handleBrightnessChange} />
              <Slider {...sliderProps} value={sel.opacity} onChange={this.handleOpacityChange} />
            </div>
            <div>
              <div className={css.editSection}>
                <div className={css.title}>Element Position</div>
                <div className={css.elementButtons}>
                  <Button {...smallBtnProps} onClick={() => this.moveSelectedElement(-1)} disabled={idx < 2}>Move Back</Button>
                  <Button {...smallBtnProps} onClick={() => this.moveSelectedElement(1)} disabled={!selection || !(idx && idx < elements.length - 1)}>Move Forward</Button>
                  <Button {...smallBtnProps} onClick={this.handleRemoveClick} kind={Button.kind.redGhost}>Remove</Button>
                </div>
              </div>
              <div className={css.editSection}>
                <div className={css.title}>Element Size</div>
                <div className={css.elementButtons}>
                  <Button {...smallBtnProps} onClick={() => this.handleSizeClick('stretch')}>Stretch</Button>
                  <Button {...smallBtnProps} onClick={() => this.handleSizeClick('cover')} disabled={!imageSelection}>Cover</Button>
                  <Button {...smallBtnProps} onClick={() => this.handleSizeClick('fit')} disabled={!imageSelection}>Fit</Button>
                  <Button {...smallBtnProps} onClick={() => this.handleSizeClick('original')}>Original</Button>
                </div>
              </div>
              <div className={classNames(css.editSection, css.backgroundColors)}>
                <Color color={color} title="Postcard Background" onChange={this.handleChange} />
                <Color color={backgroundColor} title="Element Background" element={selectedElement} enabled={selection} transparencyEnabled name="backgroundColor" onChange={this.handleChange} />
              </div>
              <div className={css.raisedPanel}>
                <div className={css.sectionTitle}>
                  Postcard Images
                  <ButtonUpload {...blueLinkProps} accept={imageFormatList} onChange={this.handleAddImage}>Add Custom</ButtonUpload>
                </div>
                {customImages.length ? null : <div className={css.grayInstructions}>To add a custom image, click &quot;Add Custom&quot; above,<br /> or drag an image file to the preview area on the right.</div>}
                <div className={css.images}>
                  <ContentTile title="Background Image" element={getElement(ElementTypes.BACKGROUND_IMAGE)} {...tileProps} />
                  <ContentTile title="Logo" element={getElement(ElementTypes.LOGO)} {...tileProps} />
                  {customImages.map((element, i) => <ContentTile key={element.key} title={`Custom Image ${i + 1}`} element={element} {...tileProps} />)}
                </div>
              </div>
            </div>
          </div>
          <div className={css.mainSection}>
            <GraphicViewer graphic={graphic} selectedElement={selectedElement} getOverlays={getOverlays} placeholders={placeholders} onElementChange={this.handleChange} onSelectElement={this.handleSelectElement} onRemoveElement={this.handleRemoveClick} />
          </div>
        </div>
        <div className={css.bottomSection}>
          <div className={css.sideSection}>
            <div className={css.raisedPanel}>
              <div className={css.sectionTitle}>
                Postcard Text
                <Button {...blueLinkProps} onClick={this.handleAddTextClick}>Add Custom</Button>
              </div>
              <div className={css.textContainer}>
                <TextContent title="Header Text" element={getElement(ElementTypes.TITLE_HTML)} {...tileProps} />
                <TextContent title="Body Text" element={getElement(ElementTypes.BODY_HTML)} {...tileProps} />
                {elements.filter(e => e.type === ElementTypes.HTML).map((element, i) => <TextContent key={element.key} title={`Custom Text ${i + 1}`} element={element} {...tileProps} />)}
              </div>
            </div>
          </div>
          <div className={classNames(css.wysiwyg, css.mainSection)}>
            <div className={css.sectionTitle}>
              <div>Text Editor</div>
              <Dropdown
                className={css.placeholderDropdown}
                disabled={!textElementSelected}
                options={placeholderOptions}
                onChange={this.handlePlaceholderChange}
                value=""
              />
            </div>
            <FroalaEditor config={this.config} model={(selectedElement || {}).text} onModelChange={this.handleTextChange} />
          </div>
        </div>
        <div className={css.buttons}>
          <div>
            <Button {...grayBtnProps} kind={Button.kind.redGhost} onClick={() => this.moveHistory(0)} disabled={!historyIndex}>Undo All</Button>
            <Button {...grayBtnProps} onClick={() => this.moveHistory(-1)} disabled={!historyIndex}>Undo</Button>
            <Button {...grayBtnProps} onClick={() => this.moveHistory(1)} disabled={historyIndex >= history.length - 1}>Redo</Button>
          </div>
          <div>
            {!closePopup ? null : <Button {...grayBtnProps} onClick={closePopup}>Cancel</Button>}
            <Button kind={Button.kind.blue} size={Button.size.large} onClick={this.handleSaveClick}>Done</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default createConfirmable(connect()(GraphicEditor));
