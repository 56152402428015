/** @flow */
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import ImPropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import css from './styles.scss';

// eslint-disable-next-line no-unused-vars
const noop = (...params) => {};

class RealColumn extends PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  /* :: onClick: Function */
  onClick(event) {
    const {
      onClick = noop,
      onColumnSort,
      isSortable,
      fieldName,
      tableName,
    } = this.props;
    onClick(event);
    if (onColumnSort && isSortable && fieldName) {
      onColumnSort({ fieldName, tableName });
    }
  }

  render() {
    const {
      onColumnSort,
      onClick,
      isSortable,
      fieldName,
      tableName,
      sortIcon,
      sortFields,
      ...rest
    } = this.props;

    const sortedByColumn = fieldName && isSortable && sortFields.contains(fieldName);
    const className = classNames(css.th, { [css.sortableTh]: isSortable, [css.sorted]: sortedByColumn }, `th-${fieldName}`);
    const sortIconContainer = sortedByColumn ? <div className={css.caretIcon}>{sortIcon}</div> : null;

    return (
      <th {...rest} onClick={this.onClick} className={className}>
        {this.props.children}
        {sortIconContainer}
      </th>
    );
  }
}

RealColumn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  onColumnSort: PropTypes.func,
  isSortable: PropTypes.bool,
  fieldName: PropTypes.string,
  tableName: PropTypes.string,
  sortFields: ImPropTypes.listOf(PropTypes.string), // current sorting
};

export default RealColumn;
