import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from './Button';
import { handleFileChange } from '../FileReceiver';

import css from './style.scss';


class ButtonUpload extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev) {
    const { name, document, onChange } = this.props;
    handleFileChange(ev.target.files && ev.target.files[0], name, onChange, document);
  }

  render() {
    const { children, name = `file${Math.random()}`, kind, size, isLoading, accept, className, onChange, ...rest } = this.props;
    const btnClass = classNames(css[kind], css[size], css.importButton, className, { [css.loading]: isLoading });

    return (
      <div
        className={btnClass}
        {...rest}
      >
        <input
          type="file"
          className={css.importInput}
          name={name}
          id={name}
          accept={accept}
          onChange={this.handleChange}
        />
        {children}
      </div>
    );
  }
}

ButtonUpload.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,
  accept: PropTypes.string,
  document: PropTypes.bool,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
};

ButtonUpload.defaultProps = {
  kind: Button.kind.grayGhost,
  size: Button.size.middle,
  document: true,
  loading: false,
};

export default ButtonUpload;
