import React, { useState } from "react";
import newsearchfilter from "../SearchFilterNew.scss";
import { LeftPanel } from "./LeftPanel";
import { RightPanel } from "./RightPanel";
import { Col, Row } from "react-bootstrap/lib";
import { useDispatch, useSelector } from "react-redux";
import { leftPanelCategories } from "../shared/ConstantsNew";
import { selectLocalFilter, selectShowAllEditOption, clearButtonEditOption } from "../../../../../data/search";

export const MainPanel = () => {
  // State to track selected index
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Function to handle item click
  const handleItemClick = index => {
    setSelectedIndex(index);
  };

  return (
    <Row className={newsearchfilter.row}>
      <Col md={4} xs={12} className={newsearchfilter.col}>
        <div className={newsearchfilter.clsLeftBody}>
          <LeftPanel onItemClick={handleItemClick} />
        </div>
      </Col>
      <Col md={8} xs={12} className={newsearchfilter.col}>
        <div className={newsearchfilter.clsRightBody}>
          <RightPanel selectedIndex={selectedIndex} />
        </div>
      </Col>
    </Row>
  );
};
