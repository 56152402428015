import { fromJS, Map, List } from 'immutable';
import * as UserActionType from 'data/user';
import * as PropertyActionType from 'data/property/actions';
import { AnalysisRentalUnit } from 'vendor/Analysis';
import * as ActionType from './actions';
import { AnalysisFieldsTypes } from './constants';
import AnalysisExpenses from '../../vendor/Analysis/AnalysisExpenses';


const emptyList = List();
const emptyMap = Map({});


const defaultState = fromJS({
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  error: null,
  savingError: null,
  deletingError: null,
  analyses: [],
  analysisParams: {},
  analysisFields: [],
  activeProperty: {},
  activePropertyLoading: true,
  activePropertyError: null,
  isReportLoading: false,
  reportLoadingError: null,
});

function roundPct(d) {
  return Math.round(d * 1000000) / 1000000;
}

function modified(state, field) {
  return state.setIn(['analysisParams', 'modifiedField'], field);
}

function resequenceMortgages(mortgages = emptyList) {
  return mortgages.map((mortgage, seq) => mortgage.set('seq', seq).set('ordinal', seq ? 'Second' : 'First'));
}

function addAnalysisMortgage(state) {
  const mortgages = state.getIn(['analysisParams', 'mortgages'], emptyList);
  if (mortgages.size > 1) return state;

  return modified(state.setIn(['analysisParams', 'mortgages'], resequenceMortgages(mortgages.push(emptyMap)))
    .setIn(['analysisParams', 'mortgageEditIndex'], mortgages.size), 'mortgages');
}

function changeAnalysisRentalUnits(rentalUnits = emptyList, index = 0, data = {}) {
  return rentalUnits.update(index, (item = emptyMap) => {
    if (data.name === 'pricePerSquareFoot') {
      return item.merge({
        [data.name]: data.value,
        amount: Number(item.get('squareFeet', 0)) * data.value,
      });
    }
    return item.set(data.name, data.value);
  });
}

function findLastCreatedAnalysis(analyses = []) {
  return analyses.reduce((lastAnalisis, analisis) => (
    lastAnalisis.createDate > analisis.createDate ? lastAnalisis : analisis
  ), {});
}

function findAnalysisById(state, id) {
  const analyses = (state.get('analyses', emptyList));
  return analyses.find(analysis => analysis.get('id') === id, null, emptyMap);
}

function transformParamsToJS(id, response) {
  const transformedParams = response;
  return {
    isSaving: false,
    analyses: transformedParams,
    analysisParams: id ? transformedParams.find(analysis => analysis.id === id) || {}
      : findLastCreatedAnalysis(transformedParams),
  };
}

function getDetailItem(id, name, value) {
  return fromJS({
    [name]: value,
    field: { id },
  });
}

function getAnalysisFields(state, type) {
  return List(state.getIn(['analysisFields'])).filter(field => field.get('type') === AnalysisFieldsTypes[type]);
}

function getDefaultAnalysisFields(state, type) {
  return getAnalysisFields(state, type).filter(field => field.get('defaultAmount'));
}

function setManualRentAmount(analysisParams, amount) {
  return analysisParams.set('rentalUnits', !amount ? emptyList : emptyList.push(Map(Object.assign(new AnalysisRentalUnit(), { rentIncreaseRate: null, amount: amount / 12 }))));
}

function applyProperty(analysisParams, prop) {
  let params = analysisParams;
  let property = prop;

  // If a saved property was passed in, get internal full property object
  if (property.fullProperty) property = property.fullProperty;
  if (property.taxAmount) params = params.update('expenses', (expenses = emptyList) => expenses.push(getDetailItem(AnalysisExpenses.PROPERTY_TAXES_ID, 'amount', property.taxAmount)));
  if (property.hoaFeeAnnualTotal) params = params.update('expenses', (expenses = emptyList) => expenses.push(getDetailItem(AnalysisExpenses.ASSOCIATION_FEES_ID, 'amount', property.hoaFeeAnnualTotal)));
  if (property.estimatedValue) params = params.set('marketValue', property.estimatedValue);
  if (property.rentAmount) params = setManualRentAmount(params, property.rentAmount * 12);
  if (property.foreclosure) {
    params = params.set('foreclosure', true);
    const { listingAmount } = property.foreclosure;
    if (listingAmount) params = params.set('defaultAmount', listingAmount);
  }

  return params;
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {

    case ActionType.LOAD_ANALYSES:
      return state.merge({
        isLoading: true,
        error: null,
        analyses: [],
      });

    case ActionType.LOAD_ANALYSES_SUCCESS:
      return state.merge({
        isLoading: false,
        error: null,
        analyses: action.response,
      });

    case ActionType.LOAD_ANALYSES_ERROR:
      return state.merge({
        isLoading: false,
        error: action.error,
      });

    case ActionType.SAVE_ANALYSIS:
      return state.merge({
        isSaving: true,
        savingError: null,
      });

    case ActionType.SAVE_ANALYSIS_SUCCESS:
      return state.merge(transformParamsToJS(action.id, action.response));

    case ActionType.SAVE_ANALYSIS_ERROR:
      return state.merge({
        isSaving: false,
        savingError: action.error,
      });

    case ActionType.DELETE_ANALYSIS:
      return state.merge({
        isDeleting: true,
        deletingError: null,
      });

    case ActionType.DELETE_ANALYSIS_SUCCESS:
      return state.merge({
        isDeleting: false,
        analysisParams: {},
        analyses: action.response,
      });

    case ActionType.DELETE_ANALYSIS_ERROR:
      return state.merge({
        isDeleting: false,
        deletingError: action.error,
      });

    case UserActionType.AUTHENTICATE:
    case UserActionType.AUTHENTICATION_ERROR:
      return state.merge({
        analysisFields: [],
      });

    case UserActionType.AUTHENTICATION_SUCCESS:
      return state.merge({
        analysisFields: action.response.analysisFields,
      });

    case PropertyActionType.GET_PROPERTY: {
      return state.merge({
        activeProperty: {},
        activePropertyLoading: true,
        activePropertyError: null,
        analyses: [],
      });
    }

    case ActionType.LOAD_ACTIVE_PROPERTY:
      return state.merge({
        activeProperty: {},
        activePropertyLoading: true,
        activePropertyError: null,
      });

    case PropertyActionType.GET_PROPERTY_SUCCESS:
    case ActionType.LOAD_ACTIVE_PROPERTY_SUCCESS: {
      let params = state.get('analysisParams');
      if (!params.get('id')) params = applyProperty(params, action.response);

      return state.set('analysisParams', params).merge({
        activeProperty: action.response,
        activePropertyLoading: false,
      });
    }

    case PropertyActionType.GET_PROPERTY_ERROR:
    case ActionType.LOAD_ACTIVE_PROPERTY_ERROR:
      return state.merge({
        activePropertyError: action.error,
        activePropertyLoading: false,
      });

    case ActionType.LOAD_ANALYSIS_REPORT:
      return state.merge({
        isReportLoading: true,
        reportLoadingError: null,
      });

    case ActionType.LOAD_ANALYSIS_REPORT_SUCCESS:
      return state.merge({
        isReportLoading: false,
      });

    case ActionType.LOAD_ANALYSIS_REPORT_ERROR:
      return state.merge({
        isReportLoading: false,
        reportLoadingError: action.error,
      });

    case ActionType.CLEAR_ANALYSIS_CHANGES:
      return state.merge({});

    case ActionType.NEW_ANALYSIS: {
      // Clear analysis data and add all appropriate defaults.
      let params = emptyMap;

      // Add any default detail items (Should only be closing costs)
      Object.keys(AnalysisFieldsTypes).forEach((type) => {
        params = params.set(type, getDefaultAnalysisFields(state, type).map(field => getDetailItem(field.get('id'), 'amount', field.get('defaultAmount'))));
      });

      // Apply property info if property was provided.
      const { property } = action.data;
      if (property) params = applyProperty(params, property.toJS());

      // Add a default mortgage and clear modification flag.
      return addAnalysisMortgage(state.set('analysisParams', params)).deleteIn(['analysisParams', 'modifiedField']);
    }
    case ActionType.CHANGE_ANALYSIS_PARAMS: {
      const { name, value, oneYearPeriod } = action.data;
      let params = state.get('analysisParams').set(name, value);

      // Calculate leasing commission percentage if a dollar amount was entered
      if (name === 'leasingCommissionAmount' && oneYearPeriod) params = params.set('leasingCommissionRate', oneYearPeriod.rentAmount ? roundPct(value / oneYearPeriod.rentAmount) : 0);

      // Set the first mortgage amount if none has been entered and the purchase price is being set.
      if (name === 'purchaseAmount' && value) {
        let mortgage = params.getIn(['mortgages', 0]);
        if (mortgage) {
          mortgage = mortgage.toJS();
          if (!mortgage.amount && !mortgage.downPaymentAmount) {
            mortgage.amount = value;
            params = params.setIn(['mortgages', 0], Map(mortgage));
          }
        }
      }

      return modified(state.set('analysisParams', params), name);
    }
    case ActionType.CHANGE_ANALYSIS_MORTGAGE_PARAMS: {
      let params = state.get('analysisParams');
      const mortgageEditIndex = params.get('mortgageEditIndex');
      let mortgage = params.getIn(['mortgages', mortgageEditIndex]);
      if (mortgage) {
        // Update the field that was just set
        const { name, value } = action.data;
        mortgage = mortgage.set(name, value).toJS();
        // If this is the first mortgage and there is a purchase price entered, infer any missing mortgage/down payment amounts.
        const purchaseAmount = params.get('purchaseAmount');
        if (mortgageEditIndex === 0 && purchaseAmount) {
          const { amount, downPaymentAmount } = mortgage;
          if (name === 'amount' && amount && !downPaymentAmount && purchaseAmount > amount) mortgage.downPaymentAmount = purchaseAmount - amount;
          else if (name === 'downPaymentAmount' && !amount && downPaymentAmount && purchaseAmount > downPaymentAmount) mortgage.amount = purchaseAmount - downPaymentAmount;
        }
        params = params.setIn(['mortgages', mortgageEditIndex], Map(mortgage));
      }

      return modified(state.set('analysisParams', params), 'mortgages');
    }
    case ActionType.CHANGE_ANALYSIS_RENTAL_UNITS:
      return state.updateIn(['analysisParams', 'rentalUnits'],
        rentalUnits => changeAnalysisRentalUnits(rentalUnits, action.id, action.data))
        .setIn(['analysisParams', 'modifiedField'], `rentalUnits.${action.id}.${action.data.name}`);

    case ActionType.ADD_ANALYSIS_RENTAL_UNIT:
      return state.updateIn(['analysisParams', 'rentalUnits'],
        (rentalUnits = emptyList) => rentalUnits.push(emptyMap.merge(new AnalysisRentalUnit())))
        .setIn(['analysisParams', 'modifiedField'], 'rentalUnits');

    case ActionType.REMOVE_ANALYSIS_RENTAL_UNIT:
      return state.updateIn(['analysisParams', 'rentalUnits'],
        (rentalUnits = emptyList) => rentalUnits.delete(action.id))
        .setIn(['analysisParams', 'modifiedField'], 'rentalUnits');

    case ActionType.CHANGE_ANALYSIS_DETAILS_PARAMS:
      // Update the "amount" or "paidBySeller" value for an Analysis Detail field. Add new item if there is no data yet.
      return modified(state.updateIn(['analysisParams', action.prop],
        (details = emptyList) => {
          const { id, data } = action;
          const index = details.findIndex(item => item.getIn(['field', 'id']) === id);
          if (index !== -1) return details.update(index, item => item.set(data.name, data.value));

          return details.push(getDetailItem(id, data.name, data.value));
        }), action.prop);

    case ActionType.CHANGE_ANALYSIS_MANUAL_TOTAL: {
      const { prop, amount } = action;
      let newState = state;

      // Remove custom rental units. If there is an amount, add a "default" unit with the amount.
      if (prop === 'rentalUnits') newState = newState.update('analysisParams', params => setManualRentAmount(params, amount));
      else {
      // Remove all details for the respective type. Add in the "manual" detail with the provided amount.
      // This should clear out all detail fields so that only the "other"/"misc" field is set.
        const field = getAnalysisFields(state, prop).find(field => field.get('manual')) || emptyMap;
        newState = newState.setIn(['analysisParams', prop], emptyList.push(getDetailItem(field.get('id'), 'amount', amount)));
      }

      return newState;
    }
    case ActionType.CHANGE_ACTIVE_ANALYSIS:
      return state.set('analysisParams', findAnalysisById(state, action.id));

    case ActionType.REMOVE_ANALYSIS_MORTGAGE:
      return modified(state.updateIn(['analysisParams', 'mortgages'], mortgages => resequenceMortgages(mortgages.delete(action.index))), 'mortgages');

    case ActionType.ADD_ANALYSIS_MORTGAGE:
      return addAnalysisMortgage(state);

    default:
      return state;
  }
}
