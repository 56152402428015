import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import { createConfirmable } from 'app/components/Confirm';
import Modal from 'components/base/Modal';
import Button from 'components/base/Button';

import css from './style.scss';


class DncDisclaimer extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAccept = this.handleAccept.bind(this);
  }

  handleAccept() {
    const { onAccept, closePopup } = this.props;
    closePopup();
    onAccept();
  }

  render() {
    return (
      <Modal isOpen uniqId="modalCreateGroup" padding="15px 20px" caption={'National "Do Not Call" Registry Certification of Registration'} width="500px">
        <div className={css.dncDisclaimer}>
          <div className={css.disclaimer}>
          <p>Customer acknowledges that certain laws, rules, and regulations restrict telemarketing activities, including those that permit consumers to give notice that they do not wish to receive telephone solicitations, known as &#34;do not call&#34; laws, rules, and regulations. Customer acknowledges and agrees that Customer is directly responsible for Customer&#39;s compliance with all Federal and State &#34;do not call&#34; laws, rules, and regulations. Company does not warrant the correctness, completeness, currentness, merchantability or fitness for a particular purpose of the information provided and is not liable to Customer for any loss or injury arising out of, or caused in whole or in part by Company&#39;s provision of any information or Customer&#39;s use thereof. Customer agrees to defend, indemnify, and hold harmless Company and Company&#39;s affiliates and their respective directors, officers, members, managers, employees, agents and successors and assigns from and against any claim made by any third party that arises from or is in any way connected with Customer&#39;s use of the provided information in violation of federal or state &#34;do not call&#34; laws, rules, or regulations, or this certification. Customer agrees that Customer will not provide any telephone number information provided by Company to Customer to any third party unless such third party either: (a) completes a Certification of Registration itself or (b) has made certifications and provided information to Customer similar in all material respects to the certifications made and information provided by Customer to Company herein.</p>          </div>
          <div className={css.buttons}>
            <Button kind={Button.kind.grayGhost} size={Button.size.large} onClick={this.props.closePopup}>Cancel</Button>
            <Button kind={Button.kind.redGhost} size={Button.size.large} onClick={this.handleAccept}>I Accept</Button>
          </div>
        </div>
      </Modal>
    );
  }
}
/*
            <p>Under Federal law, it is necessary for Company and other list service providers to obtain proof of certification of compliance from customers that wish to purchase consumer lists containing telephone numbers.</p>
            <p>Please be aware that Company will do everything within its reasonable control to suppress telephone numbers of consumers who have registered with the National &quot;Do Not Call&quot; Registry (the &quot;Registry&quot;). However, as specified under applicable laws, rules and regulations, the ultimate responsibility lies with the Customer to ensure that consumers registered in the Registry are not contacted unlawfully by telephone (within the specified time period of consumer registration).</p>
            <p>Please complete the form below stating Customer&#39;s full Legal Name, Subscription Account Number (&quot;SAN&quot;) and SAN Expiration Date. This form must be signed by an authorized officer of Customer prior to receiving any list with telephone numbers.</p>
            <br />
            <p>Customer hereby certifies, under penalty of law, that Customer: (a) seeks access to the Registry, (b) shall comply with the provisions of the Federal &quot;do not call&quot; laws, rules and regulations, (c) shall otherwise prevent telephone calls to telephone numbers on the Registry, (d) is not participating in any arrangement to unlawfully share the cost of accessing the Registry, (e) has paid the required fee, if applicable, for access to the Registry, and (f) certifies that the information provided is Customer&#39;s valid, current SAN.</p>
 */

const DncDisclaimerPopup = createConfirmable(connect()(DncDisclaimer));

DncDisclaimerPopup.open = props => openPopup(getPopupRegistration(DncDisclaimerPopup), { priority: Priority.HIGHEST, ...props });

export default DncDisclaimerPopup;
