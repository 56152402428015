import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/base/Button';
import Modal from 'components/base/Modal';

import css from './style.scss';

class Layout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showErrorDisplay: true
    }
    this.closeErrorDisplay = this.closeErrorDisplay.bind(this);
  }
  componentDidMount() {
    this.setState({ showErrorDisplay: true })
  }
  closeErrorDisplay = () => {
    this.setState({ showErrorDisplay: false })
    this.props.onOk();
  }
  render() {
    const { message, caption } = this.props;
    return (<Modal isOpen={this.state.showErrorDisplay} uniqId="error-modal" caption={caption} width="auto">
      <div className={css.message}>{message}</div>
      <div className={css.buttons}>
        <Button kind={Button.kind.default} size="large" name="ok" onClick={this.closeErrorDisplay} width="100px">Ok</Button>
      </div>
    </Modal>);
  }
}

Layout.propTypes = {
  onOk: PropTypes.func.isRequired,
};

export default Layout;
