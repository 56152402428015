import React from 'react';
import { pure } from 'recompose';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import css from './style.scss';


const BulletPoint = ({ children, color, className }) => (
  <div className={classNames(css.bulletPoint, className)}>
    <div style={{ color }}>&bull;</div>
    <div>{children}</div>
  </div>
);

BulletPoint.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

BulletPoint.defaultProps = {
  color: '#1e6ce1',
};

export default pure(BulletPoint);
