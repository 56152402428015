import React, { useState } from 'react';

import { Priority, closePopup, getPopupRegistration, openPopup } from 'app/PopupHolder';
import Button from 'components/base/Button';
import Modal from 'components/base/Modal';
import { SketchPicker } from 'react-color';

import css from './style.scss';


const ColorPicker = (props) => {
  const [color, setColor] = useState(props.color || 'fff')

  const choose = (color = null) => {
    const { name, onChange, closePopup } = props;
    onChange({ name, value: color });
    closePopup();
  }

    return (
      <Modal isOpen uniqId="ColorPicker" caption="Select Color" padding="10px" width="auto">
        <div>
          <div className={css.picker}>
            <SketchPicker disableAlpha color={color || '#ffffff'} onChangeComplete={color => setColor(color.hex)} />
          </div>
          {!props.transparencyEnabled ? null : (
            <div className={css.transparent}>
              <Button kind={Button.kind.grayGhost} size={Button.size.large} onClick={choose}>Make Transparent</Button>
            </div>
          )}
          <div className={css.buttons}>
            <Button kind={Button.kind.grayGhost} size={Button.size.large} onClick={props.closePopup}>Cancel</Button>
            <Button kind={Button.kind.blue} size={Button.size.large} onClick={() => choose(color)}>Done</Button>
          </div>
        </div>
      </Modal>
    );
}

ColorPicker.defaultProps = {
  transparencyEnabled: false,
};

ColorPicker.registrationId = getPopupRegistration(ColorPicker);
ColorPicker.open = props => openPopup(ColorPicker.registrationId, { ...props, priority: Priority.HIGHEST });
ColorPicker.close = () => closePopup({ popup: ColorPicker.registrationId });

export default ColorPicker;
