/** @flow */
import underscore from 'underscore';


const _ = underscore;


const registeredPopups = new Map();
const popupsById = new Map();

const uniqueIdGenerator = () => {
  let uniqueId = _.uniqueId('popup_');
  while (popupsById.has(uniqueId)) {
    uniqueId = _.uniqueId('popup_');
  }
  return uniqueId;
};

export function getPopupRegistration(PopupComponent) {
  if (!registeredPopups.has(PopupComponent)) {
    const popupId = uniqueIdGenerator();
    registeredPopups.set(PopupComponent, popupId);
    popupsById.set(popupId, PopupComponent);
  }
  return registeredPopups.get(PopupComponent) || '';
}

export function getPopupClass(popupId) {
  return popupsById.get(popupId);
}

