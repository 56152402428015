/** @flow */
let activationCode;

export function setCode(actCode) {
  activationCode = actCode;
}

export function getCode() {
  return activationCode;
}

/*
export default function activate(requestObject: Object) {
  if (!activationCode) return requestObject;
  const { query = {}, ...rest } = requestObject;

  if (!('dev' in query) && process.env.NODE_ENV !== 'production') query.dev = true;

  return {
    ...rest,
    query: {
      ActivationCode: activationCode,
      ...query,
    },
  };
*/

export function isLmEnabled() {
  // return !(activationCode || '').startsWith('nolmtest');
  return false;
}
