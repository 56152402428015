import React, { PureComponent } from 'react';
import { pure } from 'recompose';
import houseImgNotAvailable from './house-img-not-available.png';
import PropTypes from 'prop-types';


class Image extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      src: props.src || props.placeholder,
      alt: '',
    };
    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.state.src || nextProps.placeholder !== this.state.placeholder) {
      this.setState({
        error: false,
        src: nextProps.src || nextProps.placeholder,
      });
    }
  }

  onLoad() {
    this.setState({
      alt: this.props.alt,
    });
  }

  onError() {
    if (this.state.src === this.props.src && this.props.placeholder) {
      this.setState({
        src: this.props.placeholder,
      });
    } else {
      this.setState({
        error: true,
      });
    }
  }

  render() {
    const { src, alt, hideOnEmpty, hideOnError, ...rest } = this.props;
    let image = null;

    if (this.state.error) {
      if (!this.props.hideOnError) {
        image = <img src={houseImgNotAvailable} alt="" {...rest} />;
      }
    } else if (!this.state.src) {
      if (!hideOnEmpty) {
        image = <img src={houseImgNotAvailable} alt="" {...rest} />;
      }
    } else {
      image = <img src={this.state.src} alt={this.state.alt} onLoad={this.onLoad} onError={this.onError} {...rest} />;
    }

    return image;
  }
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  hideOnEmpty: PropTypes.bool,
  hideOnError: PropTypes.bool,
};

export default pure(Image);
