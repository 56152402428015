import React from 'react';
import { BarChart, XAxis, YAxis, Bar, Tooltip } from 'recharts';
import css from './style.scss';


const BarChartComponent = ({data, tooltipText, bottomMargin}) => {

    const chartColors = [
        '#D34F0D',
        '#F16722',
        '#F58D5C',
        '#FFC8AC',
        '#0E6673',
        '#128292',
        '#53A6B2',
        '#94C9D1',
        '#DE9C1E',
        '#F4AC21',
        '#F6BA47',
        '#F9D081',
        '#5B68C3',
        '#747FCD',
        '#929BD9',
        '#AFB6E5',
        '#ca1551',
      ];
  
  const formatData = () => {
    let total = data.reduce((prev, next)=> prev + next.value, 0);
    let fdata = data.map((d,index) => {
      let fillData = {
        ...d,
        fill: chartColors[index],
        percentage: d.value/total * 100
      }
      return fillData;
    });
    console.log('Data: ', fdata);
    return fdata;
  }

  const AxisLabel = ({ axisType, x, y, width, height, text }) => {
    const isVert = axisType === 'yAxis';
    const rot = isVert ? -90 : 0;
    const cx = isVert ? -height / 2 : x + (width / 2);
    const cy = isVert ? y + 14 : y + (height / 2) + 14;
    return (
    <text x={cx} y={cy} transform={`rotate(${rot})`} textAnchor="middle">
        {text}
    </text>
    );
}

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={css.tooltipBg}>
            <p className={css.tooltipText}>{`${label}${tooltipText?' '+tooltipText:''}: ${payload[0].value.toFixed(2)}%`}</p>
        </div>
      );
    } else return <div></div>;
  };

    const fdata = formatData();
    console.log(bottomMargin);
    return (
      <BarChart 
        width ={600}
        height = {290}
        margin={{
          bottom: bottomMargin
        }}
        data ={fdata}
      >
        <XAxis 
          dataKey="label" 
          angle={-45}
          textAnchor='end'
          dx={-5}
          dy={5}
          interval={0}
          tick={{fontSize: 10}}
        />
        <YAxis dataKey="percentage" label={
          <AxisLabel 
            axisType='yAxis' 
            text={'Percentage'}
            x={10}
              y={10}
              height={0}
              width={0}
            />
          }/>

        <Bar dataKey={"percentage"} />
        <Tooltip content={<CustomTooltip active={false} payload={[]} label="" tooltipText={tooltipText}/>} />
      </BarChart>
    );
}

export default BarChartComponent;