/** @flow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { onlyUpdateForKeys } from 'recompose';
import { List } from 'immutable';
import classNames from 'classnames';

import Row from './Row';
import css from './styles.scss';


const KEYS_TO_UPDATE = [
  'data',
  'expandComponent',
  'isExpandedRow',
  'keyField',
  'meta',
  'onRowClick',
  'activeRowClassName',
  'isActiveRow',
];

const noDataClassName = classNames(css.td, css.noDataCell);

export class TableBody extends Component {
  renderRows() {
    const {
      data,
      expandComponent,
      isExpandedRow,
      keyField,
      meta,
      onRowClick,
      activeRowClassName,
      isActiveRow,
      ...rest
    } = this.props;

    return data.reduce((result, dataPart, rowIndex) => {
      const key = dataPart.get(keyField);
      const trClass = classNames(
        css.tr,
        {
          [css.expander]: isExpandedRow && isExpandedRow(rowIndex),
          [activeRowClassName]: isActiveRow && isActiveRow(rowIndex),
          [css.assigned]: !!dataPart.get('assigned'),
        },
      );
      const nextRes = result.push(
        <Row className={trClass} key={key} data={dataPart} onRowClick={onRowClick} index={rowIndex} columnsMeta={meta} />,
      );
      if (!isExpandedRow) return nextRes;
      if (!isExpandedRow(rowIndex)) return nextRes;

      const Component = expandComponent;
      return nextRes.push(
        <tr key={`EXP_${key}`} className={css.expandedRow}>
          <td colSpan={meta.length}>
            <Component data={dataPart} index={rowIndex} columnsMeta={meta} {...rest} />
          </td>
        </tr>,
      );
    }, List());
  }

  renderNoData() {
    const { data, meta } = this.props;

    if (data.size !== 0) return null;

    return (
      <tr className={css.tr}>
        <td className={noDataClassName} colSpan={meta.length}>
          No rows to display
        </td>
      </tr>
    );
  }

  render() {
    const rows = this.renderRows();
    const noData = this.renderNoData();

    return (
      <tbody>
        {rows}
        {noData}
      </tbody>
    );
  }
}

TableBody.propTypes = {
  keyField: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(List).isRequired,
  meta: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
    renderCell: PropTypes.func.isRequired,
    renderTooltip: PropTypes.func.isRequired,
  })).isRequired,
};

export default onlyUpdateForKeys(KEYS_TO_UPDATE)(TableBody);
