import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { formatDocumentUrl } from 'utils/URL';

import css from './style.scss';


class GraphicElement extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  handleClick(ev) {
    ev.stopPropagation();

    const { onSelectElement, element } = this.props;
    onSelectElement(element);
  }

  handleLoad(ev) {
    const { element } = this.props;
    const { width, height } = ev.target;

    Object.assign(element.image, { originalWidth: width, originalHeight: height });
  }

  render() {
    const { selected, placeholders, element: { image, x, y, width, height, text, opacity, brightness, backgroundColor } } = this.props;
    // const coords = translate({ x, y, width, height });

    let val = text;
    if (text && placeholders) val = placeholders.reduce((val, p) => val.replace(new RegExp(p.expression, 'g'), p.value), val);

    return (
      <div
        onClick={this.handleClick}
        className={classNames(css.element, { [css.selected]: selected })}
        style={{
          opacity,
          filter: `brightness(${brightness})`,
          transform: `translateX(${x}px) translateY(${y}px)`,
          width: width || '100%',
          height: height || '100%',
          backgroundColor: backgroundColor || 'transparent',
        }}
      >
        {!image ? null : <img className={css.content} src={formatDocumentUrl(image)} alt="" onLoad={this.handleLoad} />}
        {!text ? null : <div className={classNames(css.content, css.textContent)} dangerouslySetInnerHTML={{ __html: val }} />}
      </div>
    );
  }
}

export default GraphicElement;
