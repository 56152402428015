import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import fileFormats from 'config/constants/fileFormats';
import Modal from 'components/base/Modal';
import Button, { ButtonImport } from 'components/base/Button';
import AddToGroup from 'app/components/AddToGroup';

import { selectContactAssignableGroups, importContacts, selectLoading } from 'data/contacts';
import { getPopupRegistration, openPopup, closePopup } from 'app/PopupHolder';

import css from './style.scss';
import csv from './contacts.csv';
import xls from './contacts.xls';
import xlsx from './contacts.xlsx';


class ContactImport extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSave = this.handleSave.bind(this);
    this.handleSaveToGroup = this.handleSaveToGroup.bind(this);
    this.handleFileContact = this.handleFileContact.bind(this);

    this.state = { file: null };
  }

  handleSave() {
    const { importContacts, closePopup } = this.props;
    importContacts(this.state.file, null, closePopup);
  }

  handleSaveToGroup() {
    const { importContacts, closePopup, openAddToGroup, groups } = this.props;

    openAddToGroup({
      groups,
      size: 1,
      itemType: 'Contact',
      selectLoading,
      onClose: closePopup,
      add: (name, afterSuccess) => importContacts(this.state.file, name, ({ response }) => afterSuccess((response.find(c => c.modified) || {}).affected || 0)),
    });
  }

  handleFileContact(ev) {
    const file = ev.target.files[0];
    if (file.type === 'text/csv') {
      
      this.handleUpdateFileContact(file)
    } else {
      this.setState({ file });
    }

  }

  handleUpdateFileContact = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const contents = event.target.result;
      const parsedOldFile = contents.split('\n').map(row => row.split(','));
      const updatedContent = this.updateCellValueContact([...parsedOldFile]);
      const updatedFileContent = updatedContent.map(row => row.join(',')).join('\n');
      const updatedBlob = new Blob([updatedFileContent], { type: file.type });
      const updatedFile = new File([updatedBlob], file.name, { type: file.type });
      this.setState({ file: updatedFile });
    };
    reader.readAsText(file);
  }

  updateCellValueContact = (parsedOldFile) => {
    const dangerousChars = ['=', '+', '-', '@', '\t', '\r'];
    const updatedFile = parsedOldFile.map((row, index) => {
      return row.map(cell => {
        let trimmedValue = cell.replace(/^"(.*)"$/, '$1');
        const startsWithDangerousChars = dangerousChars.some(char => trimmedValue.startsWith(char));
        if (startsWithDangerousChars) {
          trimmedValue = `'${trimmedValue}`
          // Escape double quotes within the cell value
          trimmedValue = `""${trimmedValue}""`;
          // Wrap the cell value in double quotes
          return `"${trimmedValue}"`;

        } else {
          return `"${trimmedValue}"`;
        }
      });
    });
    return updatedFile;
  };

  render() {
    const { loading, closePopup } = this.props;
    const { file } = this.state;
    const btn = { size: Button.size.large, className: css.button, isLoading: loading };
    const accept = fileFormats.tables.map(format => `.${format}`).join(',');

    return (
      <Modal
        isOpen
        uniqId="ImportContacts"
        width="calc(100% - 225px)"
        height="100%"
        border="0"
        padding="20px 35px 35px"
        className={css.root}
        caption={(
          <div className={css.header}>
            <div className={css.caption}>Import Contacts</div>
            <div>
              <Button {...btn} kind={Button.kind.grayGhost} onClick={closePopup}>Cancel</Button>
              <Button {...btn} kind={Button.kind.blue} onClick={this.handleSaveToGroup} disabled={!file}>Import</Button>
            </div>
          </div>
        )}
      >
        {file ? (
          <div className={css.upload}>
            <div>{file.name}</div>
            <Button className={css.removeButton} kind={Button.kind.redGhost} size={Button.size.middle} onClick={() => this.setState({ file: null })}>Remove</Button>
          </div>
        ) : (
          <div className={css.upload}>
            <div>To Import Your Existing Contacts:</div>
            <div>
              1) Download and use one of the following sample templates:&#160;
              <a href={csv} download>*.csv</a>, <a href={xls} download>*.xls</a>, <a href={xlsx} download>*.xlsx</a>
            </div>
            <div>2) Enter your existing Contacts into the template using the example provided</div>
            <div>3) Click the Choose File button to upload your saved template</div>
            <div className={css.buttons}>
              <a href={csv} download style={{ marginRight: '10px' }}>
                <Button className={css.addButton} kind={Button.kind.grayGhost} size={Button.size.middle}>Download Template</Button>
              </a>
              <ButtonImport className={css.addButton} kind={Button.kind.blue} size={Button.size.middle} accept={accept} onChange={this.handleFileContact}>Choose File</ButtonImport>
            </div>
          </div>
        )
        }
      </Modal>
    );
  }
}

const Popup = connect(state => ({
  loading: selectLoading(state),
  groups: selectContactAssignableGroups(state),
}), {
  importContacts,
  openAddToGroup: AddToGroup.open,
})(ContactImport);

const registrationId = getPopupRegistration(Popup);
Popup.open = (...rest) => openPopup(registrationId, ...rest);
Popup.close = () => closePopup({ popup: registrationId });

export default Popup;
