/** @flow */
import { addGlobalErrorType } from 'store/middleware/api';


export const ADD_API_ERROR = ('ADD_API_ERROR');

addGlobalErrorType(ADD_API_ERROR);


export const REMOVE_API_ERROR = ('REMOVE_API_ERROR');

export function removeError(id) {
  return {
    type: REMOVE_API_ERROR,
    id,
  };
}


export const REMOVE_ALL_ERRORS = ('REMOVE_ALL_ERRORS');

export function removeAllErrors() {
  return {
    type: REMOVE_ALL_ERRORS,
  };
}
