import React, { PureComponent } from 'react';
import classNames from 'classnames';
import FileReceiver from 'components/base/FileReceiver';
import { ElementTypes } from 'data/campaigns';

import GraphicElement from './element';
import GraphicSelection from './selection';
import css from './style.scss';


const px = size => `${size}px`;
const pxFixedWidth = width => ({ width: px(width), minWidth: px(width) });
const pxMinWidth = width => ({ minWidth: px(width) });
const pxHeight = height => ({ height: px(height) });

class GraphicViewer extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleDropImage = this.handleDropImage.bind(this);
  }

  handleClick() {
    this.props.onSelectElement(null);
  }

  handleDropImage({ value: { document: image } }) {
    const { onElementChange } = this.props;
    onElementChange({ image }, { type: ElementTypes.IMAGE });
  }

  render() {
    const { graphic, selectedElement, width, onElementChange, onSelectElement, getOverlays, placeholders } = this.props;
    const { size, color, elements = [] } = graphic;
    const viewMode = !onElementChange || false;
    const frameThickness = viewMode ? 0 : 2;
    const marginThickness = viewMode ? 0 : 28;
    const bleedPixels = size.dpi / 8;
    const sizeWidth = size.width - (viewMode ? 2 * bleedPixels : 0);
    const sizeHeight = size.height - (viewMode ? 2 * bleedPixels : 0);
    const bleedThickness = Math.ceil((width / sizeWidth) * (bleedPixels));
    const height = Math.ceil((sizeHeight / sizeWidth) * width) || 0;
    const cssTranslate = viewMode ? ` translate(-${bleedPixels}px, -${bleedPixels}px)` : '';

    const screenAspect = width / sizeWidth;
    const translate = (data, screen = true) => {
      const res = {};
      Object.keys(data).forEach((key) => { res[key] = Math.round(data[key] * (screen ? screenAspect : 1 / screenAspect)); });
      return res;
    };

    let key = 10000;

    return (
      <FileReceiver onChange={this.handleDropImage}>
        <div className={classNames(css.viewer, { [css.viewMode]: viewMode })} style={{ height: height + (2 * marginThickness) + (2 * frameThickness) }} onClick={this.handleClick}>
          <div className={css.viewportContainer} style={{ marginTop: marginThickness + frameThickness }}>
            <div className={css.left} />
            <div className={css.viewport} style={{ ...pxFixedWidth(width), ...pxHeight(height), backgroundColor: color || '#fff' }}>
              <div className={css.elementContainer} style={{ ...pxFixedWidth(size.width), ...pxHeight(size.height), transform: `scale(${screenAspect})${cssTranslate}` }}>
                {elements.map(element => <GraphicElement key={element.key || key++} selected={element === selectedElement} placeholders={placeholders} {...{ element, onSelectElement, translate }} />)}
                {getOverlays && getOverlays(graphic)}
              </div>
            </div>
            <div className={css.right} />
          </div>
          <div className={classNames(css.frameContainer, css.overlay)}>
            <div className={css.top} style={{ ...pxHeight(marginThickness) }} />
            <div className={css.middle}>
              <div className={css.left} style={pxMinWidth(marginThickness)} />
              <div className={css.frame} style={{ ...pxFixedWidth(width + (2 * frameThickness)), ...pxHeight(height + (2 * frameThickness)) }}>
                {!selectedElement ? null : <GraphicSelection element={selectedElement} {...{ onElementChange, onSelectElement, translate, size }} />}
                <div className={css.frameContainer}>
                  <div className={css.top} style={pxHeight(bleedThickness)} />
                  <div className={css.middle}>
                    <div className={css.left} />
                    <div className={css.bleed} style={pxFixedWidth(width - (2 * bleedThickness))} />
                    <div className={css.right} />
                  </div>
                  <div className={css.bottom} style={pxHeight(bleedThickness)} />
                </div>
              </div>
              <div className={css.right} style={pxMinWidth(marginThickness)} />
            </div>
            <div className={css.bottom} style={{ ...pxHeight(marginThickness) }} />
          </div>
        </div>
      </FileReceiver>
    );
  }
}

GraphicViewer.defaultProps = {
  width: 700,
};

export default GraphicViewer;
