import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import numberToPrice from 'utils/currency/numberToPrice';
import formatNumber from 'utils/number/format';

import css from './style.scss';


class BasicPriceList extends PureComponent {
  render() {
    const { rates, options } = this.props;

    return (
      <div className={css.priceList}>
        <div className={css.pricing}>
          <div className={css.rates}>
            <div className={`${css.rate} ${css.header}`}>
              <div>Contacts</div>
              {options.map(o => <div>{o.name}</div>)}
            </div>
            {rates.map(r => (
              <div className={css.rate} key={r.quantityMin}>
                <div>{`${formatNumber(r.quantityMin)}${!r.quantityMax ? '+' : ' - '}${formatNumber(r.quantityMax) || ''}`}</div>
                {options.map(o => <div key={o.field} className={classNames({ [css.bold]: !!o.bold })}>{numberToPrice(r[o.field])} each</div>)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}


BasicPriceList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  rates: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BasicPriceList;
