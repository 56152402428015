/** @flow */
import React, { PureComponent } from 'react';
import number from 'utils/number/format';
import numberToPrice from 'utils/currency/numberToPrice';
import PropTypes from 'prop-types';

import LineChart from './LineChart';
import BarChart from './BarChart';


function formatBigNumbers(value) {
  if (Math.abs(value) >= 10 ** 6) {
    return `${number(value / (10 ** 6), '', { maximumFractionDigits: 2 })}M`;
  }
  if (Math.abs(value) >= 10 ** 3) {
    return `${number(value / (10 ** 3), '', { maximumFractionDigits: 2 })}K`;
  }
  return value;
}

function formatCurrency(value) {
  return `$${formatBigNumbers(value)}`;
}

function formatTooltipCurrency(value) {
  return numberToPrice(value, '');
}

function formatTooltipBedroom(value) {
  if (value === '1') return `${value} Bedroom`;
  return `${value} Bedrooms`;
}

const BEDROOM_LEGEND = [{ value: 'Bedrooms', type: 'square' }];
const defaultFormat = null;
const emptyData = [{}];

class Chart extends PureComponent {
  /* :: static chartStyle: Object */
  /* :: static yTickFormat: Object */
  /* :: static xTickFormat: Object */
  /* :: static getChartElement: Function */

  static getChartElement(type) {
    switch (type) {
      case 'LINE':
        return LineChart;
      case 'BAR':
        return BarChart;
      default:
        return null;
    }
  }

  getChartStyle() {
    const axisWidth = this.props.yTickFormat === 'big_number' ? 25 : 35;
    switch (this.props.chartStyle) {
      case 'type_1':
        return {
          showGrid: true,
          verticalGrid: false,
          axisLine: false,
          tickLine: false,
          dot: () => true,
          axisWidth,
        };
      case 'type_2':
        return {
          showGrid: false,
          verticalGrid: false,
          axisLine: true,
          tickLine: false,
          dot: (color) => ({ stroke: 'white', fill: color, r: 4 }),
          axisWidth,
        };
      case 'type_3':
        return {
          showGrid: true,
          verticalGrid: true,
          axisLine: true,
          tickLine: true,
          axisColor: '#000000',
          dot: () => false,
          axisWidth,
        };
      case 'type_4':
        return {
          showGrid: true,
          verticalGrid: false,
          axisLine: false,
          tickLine: false,
          dot: () => true,
          axisWidth,
          showLegend: true,
          legendIcon: 'line',
        };
      case 'bedroom':
        return {
          showGrid: true,
          verticalGrid: false,
          axisLine: false,
          tickLine: false,
          dot: () => true,
          axisWidth,
          showLegend: true,
          legendPayload: BEDROOM_LEGEND,
          legendIcon: 'square',
          legendIconSize: 0,
        };
      default:
        return {
          showGrid: false,
          axisLine: false,
          dot: () => false,
          axisWidth,
        };
    }
  }

  getYTickFormatter() {
    switch (this.props.yTickFormat) {
      case 'big_number':
        return formatBigNumbers;
      case 'currency':
        return formatCurrency;
      case 'no_format':
      default:
        return defaultFormat;
    }
  }

  getTooltipFormatter() {
    switch (this.props.yTickFormat) {
      case 'currency':
        return formatTooltipCurrency;
      default:
        return defaultFormat;
    }
  }

  getTooltipLabelFormatter() {
    switch (this.props.xTickFormat) {
      case 'bedroom':
        return formatTooltipBedroom;
      case 'no_format':
      default:
        return defaultFormat;
    }
  }

  render() {
    const { type: typeParam, data: dataParam, categories: catParam, graph, ...rest } = this.props;
    const chartStyleRules = this.getChartStyle();
    const yTickFormatter = this.getYTickFormatter();
    const tooltipFormatter = this.getTooltipFormatter();
    const tooltipLabelFormatter = this.getTooltipLabelFormatter();

    let type = typeParam;
    let data = dataParam;
    let categories = catParam;
    if (graph) {
      type = graph.get('type');
      data = graph.get('points');
      categories = graph.get('categories');
    }

    const ChartElement = Chart.getChartElement(type);
    if (!ChartElement) return null;

    return (
      <ChartElement
        {...rest}
        chartStyleRules={chartStyleRules}
        yTickFormatter={yTickFormatter}
        tooltipFormatter={tooltipFormatter}
        tooltipLabelFormatter={tooltipLabelFormatter}
        categories={categories}
        data={data && data.size ? data.toJS() : emptyData}
      />
    );
  }
}

Chart.defaultProps = {
  type: 'LINE',
  chartStyle: 'type_1',
  yTickFormat: 'no_format',
  xTickFormat: 'no_format',
};

Chart.chartStyle = {
  type1: 'type_1',
  type2: 'type_2',
  type3: 'type_3',
  type4: 'type_4',
  bedroom: 'bedroom',
};

Chart.yTickFormat = {
  noFormat: 'no_format',
  bigNumber: 'big_number',
  currency: 'currency',
};

Chart.xTickFormat = {
  noFormat: 'no_format',
  bedroom: 'bedroom',
};

Chart.propTypes = {
  type: PropTypes.oneOf(['LINE', 'BAR']),
  chartStyle: PropTypes.oneOf(Object.values(Chart.chartStyle)),
  yTickFormat: PropTypes.oneOf(Object.values(Chart.yTickFormat)),
};


export default Chart;
