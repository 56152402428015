/** @flow */
import { CALL_API } from 'store/middleware/api';


export const LOAD_CONTRACTS = ('LOAD_CONTRACTS');
export const LOAD_CONTRACTS_SUCCESS = ('LOAD_CONTRACTS_SUCCESS');
export const LOAD_CONTRACTS_ERROR = ('LOAD_CONTRACTS_ERROR');

export function loadContracts() {
  const result = {};
  result[CALL_API] = {
    method: 'get',
    path: '/resource/auth/ps4/user/contracts',
    startType: LOAD_CONTRACTS,
    successType: LOAD_CONTRACTS_SUCCESS,
    errorType: LOAD_CONTRACTS_ERROR,
  };

  return result;
}

export const VIEW_CONTRACT = ('VIEW_CONTRACT');
export const VIEW_CONTRACT_SUCCESS = ('VIEW_CONTRACT_SUCCESS');
export const VIEW_CONTRACT_ERROR = ('VIEW_CONTRACT_ERROR');

export function loadContract(contractId) {
  const result = {};
  result[CALL_API] = {
    method: 'get',
    download: true,
    path: `/resource/auth/ps4/user/documents/${contractId}`,
    startType: VIEW_CONTRACT,
    successType: VIEW_CONTRACT_SUCCESS,
    errorType: VIEW_CONTRACT_ERROR,
  };

  return result;
}

export const DELETE_CONTRACT = ('DELETE_CONTRACT');
export const DELETE_CONTRACT_SUCCESS = ('DELETE_CONTRACT_SUCCESS');
export const DELETE_CONTRACT_ERROR = ('DELETE_CONTRACT_ERROR');

export function deleteContract(contractId) {
  const result = {};
  result[CALL_API] = {
    method: 'delete',
    path: `/resource/auth/ps4/user/documents/${contractId}`,
    startType: DELETE_CONTRACT,
    successType: DELETE_CONTRACT_SUCCESS,
    errorType: DELETE_CONTRACT_ERROR,
  };

  return result;
}

export const EDIT_CONTRACT = ('EDIT_CONTRACT');
export const EDIT_CONTRACT_SUCCESS = ('EDIT_CONTRACT_SUCCESS');
export const EDIT_CONTRACT_ERROR = ('EDIT_CONTRACT_ERROR');

export function saveContract(contract) {
  const result = {};
  result[CALL_API] = {
    method: 'put',
    path: '/resource/auth/ps4/user/documents',
    body: contract,
    startType: EDIT_CONTRACT,
    successType: EDIT_CONTRACT_SUCCESS,
    errorType: EDIT_CONTRACT_ERROR,
  };

  return result;
}

export const UPLOAD_CONTRACT = ('UPLOAD_CONTRACT');
export const UPLOAD_CONTRACT_SUCCESS = ('UPLOAD_CONTRACT_SUCCESS');
export const UPLOAD_CONTRACT_ERROR = ('UPLOAD_CONTRACT_ERROR');

export function uploadContract(body) {
  const result = {};
  result[CALL_API] = {
    method: 'post',
    path: '/resource/auth/ps4/user/contracts',
    body,
    startType: UPLOAD_CONTRACT,
    successType: UPLOAD_CONTRACT_SUCCESS,
    errorType: UPLOAD_CONTRACT_ERROR,
  };

  return result;
}

export const LOAD_CONTRACT_PROPERTIES = ('LOAD_CONTRACT_PROPERTIES');
export const LOAD_CONTRACT_PROPERTIES_SUCCESS = ('LOAD_CONTRACT_PROPERTIES_SUCCESS');
export const LOAD_CONTRACT_PROPERTIES_ERROR = ('LOAD_CONTRACT_PROPERTIES_ERROR');

export function loadContractProperties(contractId, callbacks = {}) {
  const result = {};
  result[CALL_API] = {
    method: 'get',
    path: `/resource/auth/ps4/user/documents/${contractId}/properties`,
    startType: LOAD_CONTRACT_PROPERTIES,
    successType: LOAD_CONTRACT_PROPERTIES_SUCCESS,
    errorType: LOAD_CONTRACT_PROPERTIES_ERROR,
    afterSuccess: callbacks.afterSuccess,
  };

  return result;
}

export const SAVE_CONTRACT_PROPERTIES = ('SAVE_CONTRACT_PROPERTIES');
export const SAVE_CONTRACT_PROPERTIES_SUCCESS = ('SAVE_CONTRACT_PROPERTIES_SUCCESS');
export const SAVE_CONTRACT_PROPERTIES_ERROR = ('SAVE_CONTRACT_PROPERTIES_ERROR');

export function saveContractProperties(contractId, contractProperties, callbacks = {}) {
  const result = {};
  result[CALL_API] = {
    method: 'put',
    path: `/resource/auth/ps4/user/documents/${contractId}/properties`,
    body: contractProperties,
    startType: SAVE_CONTRACT_PROPERTIES,
    successType: SAVE_CONTRACT_PROPERTIES_SUCCESS,
    errorType: SAVE_CONTRACT_PROPERTIES_ERROR,
    afterSuccess: callbacks.afterSuccess,
  };

  return result;
}
