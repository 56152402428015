import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import Modal from 'components/base/Modal';
import { campaignPath } from 'app/routes';

import Activities from './Activities';


class ActivityPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { campaign, history } = this.props;

    history.push(campaignPath(campaign ? campaign.get('id') : null));
  }

  render() {
    return (
      <Modal isOpen isCloseButton uniqId="ActivityPopup" caption="Add New Activity" width="auto" padding="20px 40px 40px" onClose={this.handleClose}>
        <Activities onClick={this.handleClose} />
      </Modal>
    );
  }
}

export default withRouter(ActivityPopup);
