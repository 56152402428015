import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SearchNotificationIntervals, saveSearch, selectSavedSearchLoading, selectLocation, selectFilter, selectFavoriteSearchSlotOptions } from 'data/search';

import Confirm from 'app/components/Confirm';

import classNames from 'classnames';

import RestrictedContent from 'app/components/RestrictedContent';
import Button from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';
import Radio from 'components/base/Radio';
import Dropdown, { dropdownable } from 'components/base/Dropdown';
import Info from 'components/base/Info';
import FormControlWraper from 'components/base/FormControlWraper';
import { Permissions } from 'data/user/constants';

import SavedSearchInfo from './SavedSearchInfo';
import css from './style.scss';


const notificationIntervals = Object.keys(SearchNotificationIntervals).map(key => SearchNotificationIntervals[key]);


const defaultState = {
  infoOpen: false,
  favoriteEnabled: false,
  name: '',
  description: '',
  interval: SearchNotificationIntervals.NEVER.value,
  slot: 1,
};

class SavedSearch extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShowHome = this.handleShowHome.bind(this);
    this.handleHideHome = this.handleHideHome.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = defaultState;
  }

  handleShowHome() {
    this.setState({ infoOpen: true });
  }

  handleHideHome() {
    this.setState({ infoOpen: false });
  }

  handleChange(ev) {
    const { name, value, type, checked } = ev.target || ev;

    this.setState({ [name]: type === 'checkbox' ? checked : value });
  }

  handleSubmit(ev) {
    const { closeDropdown, location, filter, saveSearch, confirm, slots } = this.props;
    const { favoriteEnabled, name, slot, description, interval } = this.state;
    const s = slots.find(s => s.value === Number(slot));

    ev.preventDefault();

    const save = () => {
      saveSearch({ ...filter.toJS(), ...(location ? location.toJS() : {}), name, description, notificationInterval: SearchNotificationIntervals[interval].apiValue, slot: favoriteEnabled ? slot : null });
      this.setState(defaultState, closeDropdown);
    };

    if (name.trim() === '') confirm({ question: 'Please enter a name.', cancelLabel: null });
    else if (favoriteEnabled && s.id) confirm({ question: `Do you want to replace "${s.label}" with "${name}"?`, onOk: save });
    else save();
  }

  render() {
    const { loading, isOpen, onRef, onClick, slots, closeDropdown } = this.props;
    const { favoriteEnabled, name, description, interval, infoOpen, slot } = this.state;
    const disabled = loading;

    return (
      <div className={css.savedSearch} name="dropdown-btn">
        <RestrictedContent permission={Permissions.searchFull}>
          <Button className={css.buttonSaveSearch} kind="border-blue" size="large" disabled={disabled} onClick={onClick} isLoading={loading}>Save Search</Button>
        </RestrictedContent>
        {!isOpen ? null : (
          <div className={css.dropdown} ref={onRef}>
            <form onSubmit={this.handleSubmit}>
              <div className={css.dropdownTitle}>New Saved Search</div>
              <div className={css.dropdownBody}>
                <FormControlWraper label="Name:" hasMarginBottom>
                  <input type="text" name="name" value={name} onChange={this.handleChange} />
                </FormControlWraper>
                <FormControlWraper label="Details:" hasMarginBottom>
                  <textarea name="description" rows="3" value={description} onChange={this.handleChange} />
                </FormControlWraper>
                <div className={css.flexInclude}>
                  <Checkbox label="Include on Home Screen and Replace..." name="favoriteEnabled" onClick={this.handleChange} checked={favoriteEnabled} />
                  <Info onClick={this.handleShowHome} />
                </div>
                <div className={classNames(css.row, { [css.disabled]: !favoriteEnabled })}>
                  <Dropdown name="slot" options={slots} disabled={!favoriteEnabled} value={slot} onChange={this.handleChange} />
                </div>
                <FormControlWraper label="Email when new properties become available:" hasMarginBottom>
                  <div className={css.inlineRadio}>
                    {notificationIntervals.map(({ value, label }) => (
                      <Radio key={value} name="interval" value={value} label={label} checked={interval === value} onChange={this.handleChange} />
                    ))}
                  </div>
                </FormControlWraper>
                <div className={css.buttons}>
                  <Button kind="border-default" size="large" type="reset" onClick={closeDropdown}>Cancel</Button>
                  <Button kind="button-blue" size="large" type="submit">Save</Button>
                </div>
              </div>
            </form>
            <SavedSearchInfo onClose={this.handleHideHome} isOpen={infoOpen} />
          </div>
        )}
      </div>
    );
  }
}


export default dropdownable(connect(state => ({
  loading: selectSavedSearchLoading(state),
  location: selectLocation(state),
  filter: selectFilter(state),
  slots: selectFavoriteSearchSlotOptions(state),
}), {
  confirm: Confirm.open,
  saveSearch,
})(SavedSearch));
