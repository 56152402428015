/** @flow */
import React from 'react';
import { pure } from 'recompose';

import loadable from 'components/hoc/loadable';
import FormControlWraper from 'components/base/FormControlWraper';
import Dropdown from './DropDown';


const LoadableDropdown = ({ id, label, options, ...rest }) => {
  if (!options.size) {
    return null;
  }
  return (
    <FormControlWraper id={id} label={label} >
      <Dropdown {...rest} name={id} options={options.toJS()} />
    </FormControlWraper>
  );
};

export default loadable(pure(LoadableDropdown));
