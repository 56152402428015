import { fromJS, Map } from 'immutable';
// import moment from 'moment';

// import { getISOString, getDate } from 'utils/date/formatDate';
import { capitalize } from 'utils/string';
import { AUTHENTICATION_SUCCESS } from 'data/user';
import { success, error, loading } from 'reducers';

import { MarketingCampaignStatuses } from './constants';
import * as ActionType from './actions';
import * as ContactActionType from '../contacts/actions';


const defaultWebsite = {
  id: null,
  publishDate: null,
  layoutId: null,
  templateId: null,
  active: false,
  path: '',
  contactEmail: '',
  headerText: '<p style="text-align: center;"><span style="font-size: 44px">We Buy Houses, Fast & Fair Offer Today!</span></p>',
  bodyText: '<p style="text-align: center;"><span style="font-size: 32px">We Pay All Cash For Your Home! Any price range, any location, any condition! Contact us today for an ALL CASH offer on your home, AS IS!</span></p>',
  elements: [],
};

const voicemailDefaults = {
  voicemailCallerId: {
    id: null,
    phone: '',
  },
  voicemailRecording: {
    id: null,
    name: '',
  },
  voicemailSchedule: {
    id: null,
    name: '',
    monStart: '09:00:00',
    tueStart: '09:00:00',
    wedStart: '09:00:00',
    thuStart: '09:00:00',
    friStart: '09:00:00',
    satStart: '09:00:00',
    sunStart: '09:00:00',
    monEnd: '17:00:00',
    tueEnd: '17:00:00',
    wedEnd: '17:00:00',
    thuEnd: '17:00:00',
    friEnd: '17:00:00',
    satEnd: '17:00:00',
    sunEnd: '17:00:00',
  },
};

const defaultMarketingCampaign = {
  id: null,
  name: '',
  website: defaultWebsite,
};

const defaultGraphicSize = {
  id: null,
  dpi: 300,
  height: 1350,
  heightInches: 4.5,
  name: '4.25x6',
  width: 1875,
  widthInches: 6.25,
  templates: [],
};

const defaultGraphic = {
  id: null,
  color: '#ffffff',
  size: defaultGraphicSize,
  elements: [],
};

const defaultCampaign = {
  id: null,
  marketingCampaignId: null,
  templateId: null,
  status: MarketingCampaignStatuses.NEW,
  contactIds: null,
};

const defaultSampleRecipient = {
  firstName: 'John',
  lastName: 'Doe',
  fullName: 'John Doe',
  streetAddress: '123 Sample St',
  city: 'Emerald City',
  state: 'CA',
  zip: '12345',
  cityStateZip: 'Emerald City, CA 12345',
};

const defaultVoicemailCampaign = {
  ...defaultCampaign,
  callerIdId: null,
  recordingId: null,
  scheduleId: null,
  ports: 10,
  editMode: null,
};

const defaultPostcardCampaign = {
  ...defaultCampaign,
  name: 'New Postcard Campaign',
  frontGraphic: defaultGraphic,
  backGraphic: defaultGraphic,
  addressOverlayEnabled: true,
};

const defaultEmailCampaign = {
  ...defaultCampaign,
  name: 'New Email Campaign',
  url: '',
  subject: '',
  friendlyFrom: '',
  replyToEmail: '',
  elements: [],
  startDate: null,
  startTimezone: null,
  templateEnabled: false,
  layoutId: 1,
  templateId: null,
  headerText: '<p style="text-align: center;"><span style="font-size: 44px"> </span></p>',
  bodyText: '<p style="text-align: center;"><span style="font-size: 18px"> </span></p>',
  // headerText: '<p style="text-align: center;"><span style="font-size: 44px">We Buy Houses, Fast &amp; Fair Offer Today!</span></p>',
  // bodyText: '<p style="text-align: center;"><span style="font-size: 18px">We Pay All Cash For Your Home! Any price range, any location, any condition! Contact us today for an ALL CASH offer on your home, AS IS!</span></p>',
};

export const defaultState = fromJS({
  loading: false,
  error: null,
  campaigns: null,
  campaign: defaultMarketingCampaign,
  voicemailAudioPin: null,
  voicemailCampaign: defaultVoicemailCampaign,
  ...voicemailDefaults,
  voicemailCallerIds: [],
  voicemailRecordings: [],
  voicemailSchedules: [],
  voicemailCampaigns: [],
  smsEnabled: false,          // Whehter SMS campaigns are available to this user, and respective buttons displayed.
  smsRegistered: false,       // Whether user is already registered / signed up with the SMS provider.
  postcardCampaign: defaultPostcardCampaign,
  postcardTemplates: null,
  postcardCategories: null,
  postcardRates: [],
  emailCampaign: defaultEmailCampaign,
  emailTemplates: null,
  emailLayouts: [],
  website: defaultWebsite,
  websiteTemplates: null,
  sampleRecipient: defaultSampleRecipient,
  contactAppendRates: [],
  mailingLabelLayouts: [],
});

export default function reducer(state = defaultState, action) {
  const { response } = action;
  const { name, value } = action;

  const merge = data => success(state.merge(data));
  const setIn = (path, value) => success(state.setIn(path, value));

  const getCampaign = () => state.get('campaign');
  const getVoicemailCampaign = (useState = state) => useState.get('voicemailCampaign');
  const getPostcardCampaign = (useState = state) => useState.get('postcardCampaign');

  const mergeVoicemail = (data = {}) => {
    let newState = merge(data);
    const campaign = getVoicemailCampaign(newState);
    if (!campaign.get('id')) campaign.set('marketingCampaignId', getCampaign().get('id'));

    ['callerId', 'recording', 'schedule'].forEach((field) => {
      const idField = `${field}Id`;
      const voicemailField = `voicemail${capitalize(field)}`;
      const idValue = campaign.get(idField);
      if (idValue !== newState.getIn([voicemailField, 'id'])) {
        const value = newState.get(`${voicemailField}s`).find(f => f.get('id') === idValue) || Map(voicemailDefaults[voicemailField]);
        newState = newState.set(voicemailField, value).setIn(['voicemailCampaign', idField], value.get('id'));
      }
    });

    return newState;
  };

  // const mergeVoicemailCampaign = data => state.merge('voicemailCampaign', data);

  const mergeVoicemailList = (type, list = []) => (
    // Set the new list, and set the active list to the one that was modified, or null if there is no modified item.
    mergeVoicemail({
      [`voicemail${capitalize(type)}s`]: list,
      voicemailCampaign: state.get('voicemailCampaign').set(`${type}Id`, (list.find(item => item.modified) || { id: null }).id).set('editMode', null),
    })
  );

  const loadVoicemailCampaign = (voicemailCampaign = defaultVoicemailCampaign, voicemailCampaigns = state.get('voicemailCampaigns')) => (
    mergeVoicemail({
      voicemailCampaigns,
      voicemailCampaign,
      ...voicemailDefaults,
    })
  );

  const loadPostcardCampaign = (postcardCampaign = defaultPostcardCampaign) => state.merge({ postcardCampaign });
  const loadEmailCampaign = (emailCampaign = defaultEmailCampaign) => state.merge({ emailCampaign });

  const mergeCampaigns = (state, campaigns) => state.merge({ campaigns });

  const mergeCampaign = (state, newCampaign) => {
    const campaign = { ...defaultMarketingCampaign, ...newCampaign };
    campaign.website = { ...defaultWebsite, ...(campaign.website || {}) };
    return state.merge({ campaign, website: campaign.website });
  };

  switch (action.type) {
    case AUTHENTICATION_SUCCESS: {
      const { voicemailCallerIds, voicemailRecordings, voicemailSchedules, voicemailCampaigns, marketingCampaigns, postcardCategories, postcardRates, contactAppendRates, reportLayouts, profile: { smsEnabled, smsRegistered } = {} } = response;

      return state.merge({
        campaigns: marketingCampaigns || [],
        voicemailCallerIds: voicemailCallerIds || [],
        voicemailRecordings: voicemailRecordings || [],
        voicemailSchedules: voicemailSchedules || [],
        voicemailCampaigns: voicemailCampaigns || [],
        postcardRates: postcardRates || [],
        contactAppendRates: contactAppendRates || [],
        mailingLabelLayouts: reportLayouts.filter(l => l.type === 'MAILING_LABEL'),
        postcardCategories,
        smsEnabled,
        smsRegistered,
      });
    }

    case ActionType.LOAD_CAMPAIGN:
    case ActionType.SAVE_CAMPAIGN:
    case ActionType.CHECK_WEBSITE_AVAILABILITY:
    case ActionType.SAVE_WEBSITE:
    case ActionType.LOAD_VOICEMAIL_CAMPAIGNS:
    case ActionType.SAVE_VOICEMAIL_CAMPAIGN:
    case ActionType.PREVIEW_VOICEMAIL_CAMPAIGN:
    case ActionType.DELETE_VOICEMAIL_CAMPAIGN:
    case ActionType.LOAD_VOICEMAIL_CALLER_IDS:
    case ActionType.SAVE_VOICEMAIL_CALLER_ID:
    case ActionType.DELETE_VOICEMAIL_CALLER_ID:
    case ActionType.LOAD_VOICEMAIL_RECORDINGS:
    case ActionType.SAVE_VOICEMAIL_RECORDING:
    case ActionType.DELETE_VOICEMAIL_RECORDING:
    case ActionType.LOAD_VOICEMAIL_SCHEDULES:
    case ActionType.SAVE_VOICEMAIL_SCHEDULE:
    case ActionType.DELETE_VOICEMAIL_SCHEDULE:
    case ActionType.GET_VOICEMAIL_AUDIO_PIN:
    case ActionType.DOWNLOAD_VOICEMAIL_DETAIL:
    case ActionType.DOWNLOAD_EMAIL_DETAIL:
    case ActionType.LOAD_POSTCARD_CAMPAIGN:
    case ActionType.GET_POSTCARD_CAMPAIGN:
    case ActionType.LOAD_POSTCARD_TEMPLATES:
    case ActionType.SAVE_POSTCARD_CAMPAIGN:
    case ActionType.PREVIEW_POSTCARD_CAMPAIGN:
    case ActionType.DELETE_POSTCARD_CAMPAIGN:
    case ActionType.DOWNLOAD_POSTCARD_CAMPAIGN_PDF:
    case ActionType.DOWNLOAD_POSTCARD_DETAIL:
    case ActionType.SAVE_EMAIL_CAMPAIGN:
    case ActionType.PREVIEW_EMAIL_CAMPAIGN:
    case ActionType.DELETE_EMAIL_CAMPAIGN:
    case ActionType.LOAD_WEBSITE_TEMPLATES:
    case ActionType.LOAD_EMAIL_CAMPAIGN:
    case ActionType.LOAD_EMAIL_TEMPLATES:
    case ActionType.CHECK_URL_VALIDITY:
    case ActionType.GET_SMS_LOGIN:
    case ActionType.CREATE_SMS_CAMPAIGN:
    case ActionType.GENERATE_MAILING_LABELS:
    case ContactActionType.GET_SAMPLE:
      return loading(state);

    case ActionType.LOAD_CAMPAIGN_ERROR:
    case ActionType.SAVE_CAMPAIGN_ERROR:
    case ActionType.CHECK_WEBSITE_AVAILABILITY_ERROR:
    case ActionType.CHECK_URL_VALIDITY_ERROR:
    case ActionType.SAVE_WEBSITE_ERROR:
    case ActionType.LOAD_VOICEMAIL_CAMPAIGNS_ERROR:
    case ActionType.SAVE_VOICEMAIL_CAMPAIGN_ERROR:
    case ActionType.PREVIEW_VOICEMAIL_CAMPAIGN_ERROR:
    case ActionType.DELETE_VOICEMAIL_CAMPAIGN_ERROR:
    case ActionType.LOAD_VOICEMAIL_CALLER_IDS_ERROR:
    case ActionType.SAVE_VOICEMAIL_CALLER_ID_ERROR:
    case ActionType.DELETE_VOICEMAIL_CALLER_ID_ERROR:
    case ActionType.LOAD_VOICEMAIL_RECORDINGS_ERROR:
    case ActionType.SAVE_VOICEMAIL_RECORDING_ERROR:
    case ActionType.DELETE_VOICEMAIL_RECORDING_ERROR:
    case ActionType.LOAD_VOICEMAIL_SCHEDULES_ERROR:
    case ActionType.SAVE_VOICEMAIL_SCHEDULE_ERROR:
    case ActionType.DELETE_VOICEMAIL_SCHEDULE_ERROR:
    case ActionType.GET_VOICEMAIL_AUDIO_PIN_ERROR:
    case ActionType.DOWNLOAD_EMAIL_DETAIL_ERROR:
    case ActionType.DOWNLOAD_VOICEMAIL_DETAIL_ERROR:
    case ActionType.LOAD_POSTCARD_CAMPAIGN_ERROR:
    case ActionType.GET_POSTCARD_CAMPAIGN_ERROR:
    case ActionType.LOAD_POSTCARD_TEMPLATES_ERROR:
    case ActionType.SAVE_POSTCARD_CAMPAIGN_ERROR:
    case ActionType.PREVIEW_POSTCARD_CAMPAIGN_ERROR:
    case ActionType.DELETE_POSTCARD_CAMPAIGN_ERROR:
    case ActionType.DOWNLOAD_POSTCARD_CAMPAIGN_PDF_ERROR:
    case ActionType.DOWNLOAD_POSTCARD_DETAIL_ERROR:
    case ActionType.SAVE_EMAIL_CAMPAIGN_ERROR:
    case ActionType.PREVIEW_EMAIL_CAMPAIGN_ERROR:
    case ActionType.DELETE_EMAIL_CAMPAIGN_ERROR:
    case ActionType.LOAD_WEBSITE_TEMPLATES_ERROR:
    case ActionType.LOAD_EMAIL_CAMPAIGN_ERROR:
    case ActionType.LOAD_EMAIL_TEMPLATES_ERROR:
    case ActionType.GET_SMS_LOGIN_ERROR:
    case ActionType.CREATE_SMS_CAMPAIGN_ERROR:
    case ActionType.GENERATE_MAILING_LABELS_ERROR:
    case ContactActionType.GET_SAMPLE_ERROR:
      return error(state, action);

    case ActionType.LOAD_CAMPAIGN_SUCCESS:
    case ActionType.SAVE_WEBSITE_SUCCESS:
      return success(mergeCampaign(state, response));

    case ActionType.SAVE_CAMPAIGN_SUCCESS:
      return success(mergeCampaigns(mergeCampaign(state, response.campaign), response.campaigns));

    case ActionType.DOWNLOAD_POSTCARD_CAMPAIGN_PDF_SUCCESS:
    case ActionType.DOWNLOAD_VOICEMAIL_DETAIL_SUCCESS:
    case ActionType.DOWNLOAD_EMAIL_DETAIL_SUCCESS:
    case ActionType.DOWNLOAD_POSTCARD_DETAIL_SUCCESS:
    case ActionType.CHECK_WEBSITE_AVAILABILITY_SUCCESS:
    case ActionType.CHECK_URL_VALIDITY_SUCCESS:
    case ActionType.GET_POSTCARD_CAMPAIGN_SUCCESS:
    case ActionType.GENERATE_MAILING_LABELS_SUCCESS:
      return success(state);

    case ActionType.GET_VOICEMAIL_AUDIO_PIN_SUCCESS:
      return merge({ voicemailAudioPin: (response && response.pin) || null });

    case ActionType.LOAD_VOICEMAIL_CAMPAIGN:
      return loadVoicemailCampaign(state.get('voicemailCampaigns').find(campaign => campaign.get('id') === action.id));

    case ActionType.SAVE_VOICEMAIL_CAMPAIGN_SUCCESS:
    case ActionType.DELETE_VOICEMAIL_CAMPAIGN_SUCCESS: {
      const campaigns = action.response || [];
      return loadVoicemailCampaign(campaigns.find(campaign => campaign.modified), campaigns);
    }

    case ActionType.LOAD_VOICEMAIL_CALLER_IDS_SUCCESS:
    case ActionType.SAVE_VOICEMAIL_CALLER_ID_SUCCESS:
    case ActionType.DELETE_VOICEMAIL_CALLER_ID_SUCCESS:
      return mergeVoicemailList('callerId', action.response);

    case ActionType.LOAD_VOICEMAIL_RECORDINGS_SUCCESS:
    case ActionType.DELETE_VOICEMAIL_RECORDING_SUCCESS:
      return mergeVoicemailList('recording', action.response);

    case ActionType.SAVE_VOICEMAIL_RECORDING_SUCCESS:
      return mergeVoicemailList('recording', action.response).merge({ voicemailAudioPin: ((response || []).filter(a => a.modified)[0] || {}).activeAudioPin || null });

    case ActionType.LOAD_VOICEMAIL_SCHEDULES_SUCCESS:
    case ActionType.SAVE_VOICEMAIL_SCHEDULE_SUCCESS:
    case ActionType.DELETE_VOICEMAIL_SCHEDULE_SUCCESS:
      return mergeVoicemailList('schedule', action.response);

    case ActionType.PREVIEW_VOICEMAIL_CAMPAIGN_SUCCESS:
      return loadVoicemailCampaign(action.response);

    case ActionType.VOICEMAIL_CAMPAIGN_CHANGE: {
      const names = action.name.split('.');
      if (names.length > 1) return setIn(names, action.value);

      return mergeVoicemail({ voicemailCampaign: getVoicemailCampaign().set(name, value) });
    }

    case ActionType.LOAD_POSTCARD_CAMPAIGN_SUCCESS:
      return success(loadPostcardCampaign(response)).merge({ sampleRecipient: response.sampleRecipient });

    case ContactActionType.GET_SAMPLE_SUCCESS:
      return success(state.merge({ sampleRecipient: response }));

    case ActionType.SAVE_POSTCARD_CAMPAIGN_SUCCESS:
      return success(mergeCampaigns(mergeCampaign(loadPostcardCampaign(response.campaign), response.marketingCampaign), response.marketingCampaigns).merge({ postcardTemplates: null }));

    case ActionType.DELETE_POSTCARD_CAMPAIGN_SUCCESS:
      return success(mergeCampaign(loadPostcardCampaign(response.campaign), response.marketingCampaign).merge({ postcardTemplates: null }));

    case ActionType.PREVIEW_POSTCARD_CAMPAIGN_SUCCESS:
      return success(loadPostcardCampaign(action.response));

    case ActionType.POSTCARD_CAMPAIGN_CHANGE: {
      let postcardCampaign = getPostcardCampaign();
      const val = fromJS(value);
      postcardCampaign = name ? postcardCampaign.setIn(name.split('.'), val) : postcardCampaign.merge(val);

      return loadPostcardCampaign(postcardCampaign);
    }

    case ActionType.CLEAR_POSTCARD_CAMPAIGN:
      return loadPostcardCampaign();

    case ActionType.LOAD_POSTCARD_TEMPLATES_SUCCESS:
      return success(state.merge({ postcardTemplates: response }));

    case ActionType.LOAD_EMAIL_TEMPLATES_SUCCESS:
      return success(state.merge({ emailTemplates: response }));

    case ActionType.LOAD_WEBSITE_TEMPLATES_SUCCESS:
      return success(state.merge({ websiteTemplates: response }));

    case ActionType.UPDATE_WEBSITE:
      return state.merge({ website: action.website });

    case ActionType.LOAD_WEBSITE:
      return state.merge({ website: state.getIn(['campaign', 'website']) });

    case ActionType.CLEAR_EMAIL_CAMPAIGN:
      return loadEmailCampaign();

    case ActionType.LOAD_EMAIL_CAMPAIGN_SUCCESS:
      return success(loadEmailCampaign(response));

    case ActionType.PREVIEW_EMAIL_CAMPAIGN_SUCCESS:
    case ActionType.SAVE_EMAIL_CAMPAIGN_SUCCESS:
    case ActionType.DELETE_EMAIL_CAMPAIGN_SUCCESS:
      return success(mergeCampaign(loadEmailCampaign(response.campaign), response.marketingCampaign).merge({ emailTemplates: null }));

    case ActionType.UPDATE_EMAIL_CAMPAIGN:
      return loadEmailCampaign(action.campaign);

    case ActionType.GET_SMS_STATUS_SUCCESS:
      return state.set('smsRegistered', response.registered);

    case ActionType.CREATE_SMS_CAMPAIGN_SUCCESS:
    case ActionType.GET_SMS_LOGIN_SUCCESS: {
      return success(state.set('smsRegistered', response.registered));
    }

    default:
      return state;
  }
}
