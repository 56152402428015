import { createSelector } from 'reselect';
import { defaultContextMap, defaultGroupContextMap } from 'reducers';

import PropertyGroupTypes, {SurroundingTypeIndexes} from './constants';


const filterType = (list, type) => list.filter(g => g.get('type') === type);

const filterOriginalType = (list, type) => list.filter(g => g.get('originalType') === type);

const checkState = state => state;

export const selectState = createSelector(checkState, state => state.get('property'));

export const selectProp = property => createSelector(selectState, state => state.get(property));

export const selectGroups = createSelector(selectState, state => state.get('groups').filter(g => !!g.get('type')));

export const selectContexts = createSelector(selectState, state => state.get('contexts'));

export const selectPushpins = createSelector(selectState, state => state.get('pushpins'));

export const selectPushpinLoading = createSelector(selectState, state => state.get('pushpinLoading'));

export const selectGroupContext = name => createSelector(selectState, s => s.getIn(['contexts', name], defaultContextMap).get('group') || defaultGroupContextMap);

export const selectFavorites = createSelector(selectGroups, groups => filterType(groups, PropertyGroupTypes.FAVORITE));

export const selectLists = createSelector(selectGroups, groups => filterType(groups, PropertyGroupTypes.MARKETING));

export const selectMonitoredLists = createSelector(selectGroups, groups => filterType(groups, PropertyGroupTypes.MONITORED));

export const selectOriginalFavorites = createSelector(selectGroups, groups => filterOriginalType(groups, PropertyGroupTypes.FAVORITE));

export const selectOriginalLists = createSelector(selectGroups, groups => filterOriginalType(groups, PropertyGroupTypes.MARKETING));

export const selectAlerts = createSelector(selectGroups, groups => filterType(groups, PropertyGroupTypes.ALERT));

export const selectLoading = selectProp('loading');

export const selectProperty = selectProp('property');

const selectSurroundingPropertyContext = (state, context) => selectProp('surroundingPropertyContexts')(state).get(context);

export const selectComparableSearch = (state, context) => selectSurroundingPropertyContext(state, context).getIn(['types', 0, 'search']);

export const selectComparableLoading = selectProp('comparableLoading');

export const selectSurroundingPropertyStale = (state, context) => selectSurroundingPropertyContext(state, context).get('stale');

export const selectSurroundingPropertyType = (state, context, typeCode) => selectSurroundingPropertyContext(state, context).getIn(typeCode ? ['types', SurroundingTypeIndexes[typeCode]] : ['type']);

export const selectSurroundingPropertyTypes = (state, context) => selectSurroundingPropertyContext(state, context).get('types');

export const selectDocuments = selectProp('documents');

export const selectDocumentLoading = selectProp('documentLoading');

export const selectStreetView = selectProp('streetView');

export const selectStreetViewLoading = selectProp('streetViewLoading');

export const selectImportJobs = selectProp('importJobs');

export const selectImportLoading = selectProp('importLoading');

export const selectSelection = selectProp('selection');

export const selectFilters = selectProp('filters');

export const selectSearches = selectProp('searches');

export const selectGroupCounts = selectProp('groupCounts');

export const selectPropertyExport = selectProp('propertyExport');

export const selectPendingPropertyExportId = selectProp('pendingPropertyExportId');

export const selectPropertyExportLoading = selectProp('propertyExportLoading');

export const selectFolders = selectProp('folders');

export const selectTags = selectProp('tags');

export const selectStatuses = selectProp('statuses');
