import React from "react";
import PropTypes from 'prop-types';
import css from "./style.scss";

const ProgressBar = (props) => {
  const { percent } = props;
  return (
    <div className={css.container}>
      <div className={css.section}>
        <div className={css.progressBar} style={{ width: `${percent}%` }} />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  percent: 0,
};

export default ProgressBar;
