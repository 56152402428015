/** @flow */
import { PureComponent } from 'react';
import { connect } from 'react-redux';

// TODO: Needed? Analysis should be able to run independently of a property.
import urlSubscribe from 'app/Property/Detail/urlSubscribe';

import Modal from './Modal/index';
import { withRouter } from 'react-router-dom';


const Page = ({ match: { params }, openPopup, history, routes, urls, location }) => {
    openPopup({
      urls,
      params: { ...params },
      history,
      location,
      route: routes && routes.length > 0 ? routes[routes.length - 1] : {}, // Taking the current route
    });


    return null;
}

Page.defaultProps = {
  params: {},
  history: {},
};

const mapStateToProps = () => ({});

const mapActionToProps = {
  openPopup: Modal.open,
  closePopup: Modal.close,
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(urlSubscribe(Page)));
