import React from 'react';

import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import Select from 'components/base/Select';
import Form from 'components/base/Form';
import FormControlWrapper from 'components/base/FormControlWraper';

import css from './style.scss';

const Layout = ({ form, transactions, onClose, onSubmit }) => {
  const header = <div className={css.header}>Select Transaction</div>;

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(event.target.elements.transaction.value);
  };

  return (
    <Modal isOpen uniqId="modalCreateGroup" padding="25px 10px" caption={header} onClose={onClose}>
      <Form onSubmit={handleSubmit} name={form} className={css.body}>
        <FormControlWrapper id="new-group-in-modal" label="Transaction" hasMarginBottom>
          <Select
            autoFocus
            required
            id="new-group-in-modal"
            name="transaction"
            className={css.dropdown}
            options={transactions?.map(transaction => ({ label: transaction.reportDescription, value: transaction.id }))}
          />
        </FormControlWrapper>

        <div className={css.buttonContainer}>
          <Button
            kind={Button.kind.ghost}
            name="cancel"
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            kind={Button.kind.ghost}
            name="create"
            type="submit"
          >
            Select
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default Layout;
