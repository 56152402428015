/** @flow */
import React from 'react';
import { fromJS } from 'immutable';
import { pure } from 'recompose';

import classNames from 'classnames';
import Table, { Column } from 'components/base/Table';
import tableCss from 'components/base/Table/styles.scss';
import Button from 'components/base/Button';
import Accordion from 'components/base/Accordion';
import Radio from 'components/base/Radio';
import AdditionalField from 'components/base/AdditionalField';
import { IncomeIncreaseTypeValues } from 'data/analyses/constants';
import FormControlWraper from 'components/base/FormControlWraper';
import numberToPrice from 'utils/currency/numberToPrice';
import CustomInput from '../CustomInput';
import CurrencyInput from '../CurrencyInput';
import AnnualCurrencyInput from '../AnnualCurrencyInput';
import PercentInput from '../PercentInput';
import DetailsInput from '../DetailsInput';
import css from '../style.scss';


function renderTableColumn(
  value,
  indexes,
  data,
  _,
  extraProps,
) {
  const { type, onChange, allowNull } = extraProps;
  const { fieldPath, row } = indexes;
  const name = fieldPath[0];

  switch (type) {
    case 'string':
      return (
        <FormControlWraper id={String(Math.random())}>
          <input type="text" name={name} value={value} onChange={event => onChange(event, row)} />
        </FormControlWraper>
      );

    case 'static':
      return numberToPrice(value);

    default:
      return <CustomInput type={type} name={name} value={value} onChange={event => onChange(event, row)} allowNull={!!allowNull} />;
  }
}

const Income = (props) => {
  const {
    analysisParams: a,
    onNumberParamsChange,
    onDetailsChange,
    onManualTotalChange,
    onParamsChange,
    onRentalUnitsChange,
    addAnalysisRentalUnit,
    onAnalysisRentalUnitRemove,
    rentalRemoveIsDisabled,
    activeRentalUnit,
    onActiveRentalUnitChange,
  } = props;
  const p = a.oneYearPeriod;
  const params = { renderCell: renderTableColumn };

  return (
    <div className={css.root}>
      <Accordion caption="Monthly Income Amount" isOpened isLarge>
        <div className={css.row}>
          <div className={css.col_6}>
            <AnnualCurrencyInput label="Gross Monthly Rental Income" name="rentAmount" value={p} onChange={event => onManualTotalChange(event, 'rentalUnits')} />
          </div>
          <div className={classNames(css.col_6, css.inputColumn)}>
            <AnnualCurrencyInput label="Total Net Monthly Income" name="incomeTotal" value={p} disabled />
          </div>
        </div>
        <Accordion caption="Detail Breakdown" isOpened={a.detailStatus && a.detailStatus.rentalUnits} isArrowRight>
          <div className={css.tableColumn}>
            <Button kind={Button.kind.grayGhost} size={Button.size.middle} onClick={addAnalysisRentalUnit}>Add Unit Type</Button>
            <Button kind={Button.kind.grayGhost} size={Button.size.middle} onClick={onAnalysisRentalUnitRemove} disabled={rentalRemoveIsDisabled}>Remove Unit Type</Button>
            <Table
              data={fromJS(a.rentalUnits)}
              keyField="seq"
              activeRow={activeRentalUnit}
              activeRowClassName={tableCss.active}
              onRowClick={onActiveRentalUnitChange}
              isHoverable
            >
              <Column field="name" {...params} extraProps={{ type: 'string', onChange: onRentalUnitsChange }}>Unit Type</Column>
              <Column field="quantity" {...params} extraProps={{ type: 'digit', onChange: onRentalUnitsChange }}>Quantity</Column>
              <Column field="amount" {...params} extraProps={{ type: 'currency', onChange: onRentalUnitsChange }}>Rent/Mo</Column>
              <Column field="squareFeet" {...params} extraProps={{ type: 'digit', onChange: onRentalUnitsChange }}>SqFt</Column>
              <Column field="pricePerSquareFoot" {...params} extraProps={{ type: 'currency', onChange: onRentalUnitsChange }}>$/SqFt</Column>
              <Column field="occupancyRate" {...params} extraProps={{ type: 'percent', onChange: onRentalUnitsChange }}>Occupancy %</Column>
              <Column field="rentIncreaseRate" {...params} extraProps={{ type: 'percent', onChange: onRentalUnitsChange, allowNull: true }}>Annual % Increase</Column>
              <Column field="netTotal" {...params} extraProps={{ type: 'static' }}>Avg. Monthly Rent</Column>
            </Table>
          </div>
        </Accordion>
        <Accordion caption="Miscellaneous Income" isOpened={a.detailStatus && a.detailStatus.incomes} isArrowRight>
          <div className={classNames(css.row, css.inputRow)}>
            {a.incomes.map(income => (
              <DetailsInput className={classNames(css.col_3, css.inputColumn)} prop="incomes" annual detail={income} key={income.field.id} onDetailsChange={onDetailsChange} />
            ))}
          </div>
        </Accordion>
        <Accordion caption="Management Fees" isArrowRight>
          <div className={classNames(css.row, css.inputRow)}>
            <div className={classNames(css.col_3, css.inputColumn)}>
              <PercentInput label="Property Management Rate" name="propertyManagementRate" value={a} onChange={onNumberParamsChange} />
            </div>
            <div className={classNames(css.col_3, css.inputColumn)}>
              <PercentInput label="Leasing Commission Rate" name="leasingCommissionRate" value={a} onChange={onNumberParamsChange} />
            </div>
            <div className={classNames(css.col_3, css.inputColumn)}>
              <CurrencyInput label="Leasing Commission (1st Year)" name="leasingCommissionAmount" value={p} onChange={onNumberParamsChange} />
            </div>
          </div>
        </Accordion>
      </Accordion>
      <Accordion caption="Annual Income Increase" isOpened isLarge>
        <div className={css.label}>Increase Type</div>
        <div className={css.row}>
          {IncomeIncreaseTypeValues.map(option => (
            <div className={css.col_3} key={option.label}>
              <Radio {...option} name="incomeIncreaseType" onChange={onParamsChange} checked={a.incomeIncreaseType === option.value} hasArrow />
            </div>
          ))}
        </div>
        <AdditionalField isActive={a.incomeIncreaseType === IncomeIncreaseTypeValues[0].value}>
          <PercentInput label="Increase Rate" name="incomeIncreaseRate" value={a} onChange={onNumberParamsChange} />
        </AdditionalField>
        <AdditionalField isActive={a.incomeIncreaseType === IncomeIncreaseTypeValues[1].value}>
          <div className={css.row}>
            <div className={classNames(css.col_3, css.col_offset_2)}>
              <CurrencyInput label="Increase Amount" name="incomeIncreaseAmount" value={a} onChange={onNumberParamsChange} />
            </div>
          </div>
        </AdditionalField>
      </Accordion>
    </div>
  );
};

export default pure(Income);
