import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaskedInput from 'react-maskedinput';

import css from './style.scss';


class Text extends PureComponent {
  constructor(props) {
    super(props);

    const { name } = props;

    this.id = `${(props ? `${name}-` : '')}${Math.random()}`;
  }

  render() {
    const { className, disabled, inputClassName, label, loading, mask, name, onRef, onInputRef, onChange, password, placeholder, readOnly, value } = this.props;

    let Input;
    const inputProps = {};
    if (mask) {
      Input = MaskedInput;
      inputProps.mask = mask;
    } else {
      Input = 'input';
      inputProps.type = password ? 'password' : 'text';
    }

    return (
      <div
        ref={onRef}
        className={classNames(css.text, { [css.disabled]: disabled, [css.loading]: loading }, className)}
      >
        {!label ? null : <label htmlFor={this.id}>{label}</label>}
        <Input
          {...inputProps}
          className={classNames(css.input, inputClassName)}
          disabled={disabled}
          id={this.id}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          ref={onInputRef}
          readOnly={readOnly}
          value={typeof value === 'object' ? value[name] : value}
        />
      </div>
    );
  }
}

Text.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  password: PropTypes.bool,
  mask: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onRef: PropTypes.func,
  onInputRef: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

Text.defaultProps = {
  disabled: false,
  loading: false,
  password: false,
};

export default Text;
