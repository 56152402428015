import React, { PureComponent } from 'react';
import classNames from 'classnames';
import ListKeyValue from 'components/ListKeyValue';
import numberToPrice from 'utils/currency/numberToPrice';
import formatPercent from 'utils/percent/formatPercent';
import css from './style.scss';


const Fields = [
  {
    label: 'Total Out of Pocket ($)',
    key: 'upFrontExpenseTotal',
    path: ['upFrontExpenseTotal'],
    format: value => numberToPrice(value, ''),
  },
  {
    label: 'Monthly Expenses',
    key: 'monthlyExpenseTotal',
    path: ['oneYearPeriod', 'monthlyExpenseTotal'],
    format: value => numberToPrice(value / 12, ''),
  },
  {
    label: 'Monthly Income',
    key: 'incomeTotal',
    path: ['oneYearPeriod', 'incomeTotal'],
    format: value => numberToPrice(value / 12, ''),
  },
  {
    label: 'Total Return ($)',
    key: 'saleNetProfitAmount',
    path: ['lastPeriod', 'saleNetProfitAmount'],
    format: value => numberToPrice(value, ''),
  },
  {
    label: 'Cap Rate',
    key: 'capRate',
    path: ['lastPeriod', 'capRate'],
    format: value => formatPercent(100 * value, ''),
  },
  {
    label: '1 Yr Return',
    key: 'oneYearNetIncomeCumulativeTotal',
    path: ['oneYearPeriod', 'netIncomeCumulativeTotal'],
    format: value => numberToPrice(value, ''),
  },
];

class TabAnalysis extends PureComponent {
  render() {
    return (
      <div>
        <div className={css.tabWrapper}>
          <div className="listKeyValue">
            <ListKeyValue>{Fields.map(field =>
              (<div key={field.key} className="row">
                <div className={classNames('key', css.key)}>{field.label}</div>
                <div className={classNames('value', css.value)}>{field.format(field.path.reduce((value, key) => value && value[key], this.props.analysisParams))}</div>
              </div>),
            )}</ListKeyValue>
          </div>
        </div>
      </div>
    );
  }
}

export default TabAnalysis;
