import React from "react";
import PropTypes from 'prop-types';
import css from "./style.scss";

const IntelliProgressBar = (props) => {
  const { percent, size } = props;

  // Example vertical line positions (in percentage)
  const verticalLinePositions = [10, 30, 50, 70, 90];

  const getSectionClass = () => {
    switch (size) {
      case 'big':
        return css.bigSection;
      case 'medium':
        return css.mediumSection; // New medium section class
      default:
        return css.section;
    }
  };

  return (
    <div className={css.container}>
      <div className={getSectionClass()}>
        {verticalLinePositions.map((position, index) => (
          <div
            key={index}
            className={css.verticalLine}
            style={{ left: `${position}%` }}
          />
        ))}
        <div className={css.progressBar} style={{ width: `${percent}%` }} />
      </div>
    </div>
  );
};

IntelliProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'big']), // Added medium size option
};

IntelliProgressBar.defaultProps = {
  percent: 0,
  size: 'small', // Default size remains small
};

export default IntelliProgressBar;
