import React from 'react';
import { Map } from 'immutable';

import ColorDot from 'components/ColorDot';
import css from './style.scss';


export default function renderColorDot(counter,
) {
  return (
    <div className={css.flex}>
      <ColorDot isBig={false}>{counter}</ColorDot>
    </div>
  );
}
