import React, { PureComponent } from 'react';
import { List } from 'immutable';
import classNames from 'classnames';
import moment from 'moment';

import { getPopupRegistration, openPopup, closePopup, Priority } from 'app/PopupHolder';
import Confirm from 'app/components/Confirm';
import withProperty from 'app/components/withProperty';
import Button from 'components/base/Button';
import Image from 'components/base/Image';
import { LoadableDropdown } from 'components/base/Dropdown';
import Breadcrumbs from 'components/base/Breadcrumbs';
import ListKeyValue from 'components/ListKeyValue';
import { SurroundingPropertyContexts, defaultSurroundingPropertySearch, selectSurroundingPropertyType, updateSurroundingPropertySearch, sortSurroundingProperties, updateSurroundingPropertySelection } from 'data/property';
import { loadOffers, saveOffer, deleteOffer, selectOffers, selectOfferLoading } from 'data/user';
import Prompt from 'app/components/Prompt';
import { formatFullAddressObject } from 'utils/address/formatAddress';

import UrlStorage from 'app/Property/Detail/UrlStorage';
import Modal from 'components/base/Modal';
import numberToPrice from 'utils/currency/numberToPrice';
import formatPercent from 'utils/percent/formatPercent';
import formatNumber from 'utils/number/format';
import { getBrandCode, getBrandLogoUrl, getBrandLogoAltUrl } from 'utils/brand';

import css from './style.scss';
import CurrencyInput from '../Analysis/Modal/TabContent/CurrencyInput';
import PercentInput from '../Analysis/Modal/TabContent/PercentInput';
import IntegerInput from '../Analysis/Modal/TabContent/IntegerInput';
import Surrounding from '../Surrounding';


const Steps = [
  { name: 'ARV', link: 'arv', context: SurroundingPropertyContexts.OFFER_ARV },
  { name: 'Exit Price', link: 'exit', context: SurroundingPropertyContexts.OFFER_EXIT_PRICE },
  { name: 'Max Offer', link: 'max' },
  { name: 'Goal Target Offer', link: 'goal' },
  { name: 'Finish', link: 'finish' },
];

const SummaryFields = [
  {
    label: 'ARV (After Repair Value)',
    field: 'arv',
  },
  {
    label: 'Exit Price',
    field: 'exitPrice',
  },
  {
    label: 'Max Allowable Offer',
    field: 'maxOffer',
  },
  {
    label: 'Goal Target Offer',
    field: 'goalTarget',
  },
  {
    label: 'Your Profit',
    field: 'profit',
  },
];

const PropertyFields = [
  { label: 'Beds', field: 'bedrooms' },
  { label: 'Baths', field: 'bathrooms' },
  { label: 'SqFt', field: 'squareFeet' },
  { label: 'Year', field: 'yearBuilt' },
];

const DefaultOffer = {
  id: 0,
  arv: 0,
  squareFeet: null,
  exitPriceRate: 0,
  desiredProfit: 20000,
  wiggleRoom: 0.05,
  arvSearch: { ...defaultSurroundingPropertySearch },
  exitPriceSearch: { ...defaultSurroundingPropertySearch },
};

function findStepIndexByPath(path) {
  return Math.max(0, Steps.findIndex(link => link.link === path));
}

function getChangeData(event) {
  return event.target ? { name: event.target.name, value: event.target.value } : event;
}

function squareFeetDefaults(squareFeet) {
  return {
    squareFeetMin: Math.round(squareFeet - (squareFeet * 0.1)) || null,
    squareFeetMax: Math.round(squareFeet + (squareFeet * 0.1)) || null,
  };
}

class Offer extends PureComponent {
  constructor(props) {
    super(props);

    this.handlePreviousClick = this.handlePreviousClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleActiveChange = this.handleActiveChange.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleNewClick = this.handleNewClick.bind(this);
    this.handleRenameClick = this.handleRenameClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.openDefaultRoute = this.openDefaultRoute.bind(this);
    this.handleNumberParamsChange = this.handleNumberParamsChange.bind(this);
    this.handleSearchComplete = this.handleSearchComplete.bind(this);
    this.handleSquareFeetChange = this.handleSquareFeetChange.bind(this);
    this.moveStep = this.moveStep.bind(this);
    this.loadOffer = this.loadOffer.bind(this);
    this.updateOffer = this.updateOffer.bind(this);

    const activeStepIndex = findStepIndexByPath(props.route.path);
    this.state = {
      activeStepIndex,
      savedPropertyAction: null,
      arvManual: false,
      arvCalc: 0,
      exitPriceRateManual: false,
      exitPriceRateCalc: 0,
      steps: {},
      offer: { ...DefaultOffer },
      context: Steps[activeStepIndex].context || null,
    };
  }

  componentWillMount() {
    this.loadOffer();
  }

  componentDidMount() {
    const { savedPropertyId, loadOffers } = this.props;
    if (savedPropertyId) loadOffers(savedPropertyId);

    this.updateOffer();
  }

  componentWillReceiveProps(newProps) {
    const { savedPropertyAction, steps, arvManual, offer: { arv, exitPriceRate, squareFeet } } = this.state;
    const { currentPathname, savedPropertyId, arvType, exitPriceType, property, updateSurroundingPropertySearch } = newProps;

    if (currentPathname && currentPathname !== this.props.currentPathname) {
      const activeStepIndex = findStepIndexByPath(currentPathname.last());
      const loaded = !!steps[activeStepIndex];
      this.setState({ activeStepIndex, context: Steps[activeStepIndex].context || null, steps: { ...steps, [activeStepIndex]: { ready: true } } }, () => {
        if (!loaded && activeStepIndex === 1 && !exitPriceRate) updateSurroundingPropertySearch(SurroundingPropertyContexts.OFFER_EXIT_PRICE, arvType.get('search'), true);
        // this.setState({ steps: { ...this.state.steps, [activeStepIndex]: { ready: true } } });
        // this.state.steps[activeStepIndex].ready = true;
      });
    }

    if (savedPropertyAction && savedPropertyId && savedPropertyId !== this.props.savedPropertyId) savedPropertyAction(savedPropertyId);

    const arvCalc = Math.round(arvType.get('pricePerSquareFoot') * squareFeet || 0);
    const effectiveArv = arvManual ? arv : arvCalc;
    // const exitPriceRateCalc = Math.round((effectiveArv ? (getBrandCode() === 'reiautomator' ? exitPriceType.get('saleAmount') : exitPriceType.get('pricePerSquareFoot') * squareFeet || 0) / effectiveArv : 0) * 100000) / 100000;
    const exitPriceRateCalc = Math.round((effectiveArv ? (exitPriceType.get('pricePerSquareFoot') * squareFeet || 0) / effectiveArv : 0) * 100000) / 100000;

    if (arvCalc !== this.state.arvCalc || exitPriceRateCalc !== this.state.exitPriceRateCalc) this.setState({ arvCalc, exitPriceRateCalc }, this.updateOffer);
  }

  getFullTabLink(stepIndex) {
    const { params, urls } = this.props;
    const tabLink = Steps[stepIndex].link;
    const { root } = urls.getState();
    return `${root}/${params.id}/offer/${tabLink}`;
  }

  openDefaultRoute() {
    const { params, urls } = this.props;
    const { root } = urls.getState();
    this.props.history.push(`${root}/${params.id}/offer`);
  }

  moveStep(forward) {
    const { activeStepIndex } = this.state;
    const { history } = this.props;

    const stepIndex = activeStepIndex + (forward ? 1 : -1);
    if (Steps[stepIndex]) history.push(this.getFullTabLink(stepIndex));
  }

  updateOffer(val = {}, field) {
    const { offer: curOffer, arvManual, arvCalc, exitPriceRateManual, exitPriceRateCalc } = this.state;
    const offer = { ...curOffer, ...val };
    const { arv, exitPriceRate, desiredProfit, wiggleRoom } = offer;

    const state = { offer, arvManual, exitPriceRateManual };
    if (['arv', 'exitPriceRate'].includes(field)) state[`${field}Manual`] = !!Number(offer[field]);

    offer.arv = state.arvManual ? arv : arvCalc;
    offer.exitPriceRate = state.exitPriceRateManual ? exitPriceRate : exitPriceRateCalc;
    offer.exitPrice = Math.round(offer.arv * offer.exitPriceRate);
    offer.maxOffer = offer.exitPriceRate ? offer.exitPrice - desiredProfit : 0;
    offer.goalTarget = offer.maxOffer - Math.round(offer.maxOffer * wiggleRoom);
    offer.profit = offer.exitPrice - offer.maxOffer;
    offer.profit2 = offer.exitPrice - offer.goalTarget;

    this.setState(state);
  }

  clearField(field) {
    this.updateOffer({ [field]: 0 }, field);
  }

  loadOffer(newOffer) {
    const { property } = this.props;
    const squareFeet = property.get('squareFeet');

    let offer = newOffer;
    if (!offer) {
      const yearBuilt = property.get('yearBuilt');

      const search = {
        ...defaultSurroundingPropertySearch,
        ...squareFeetDefaults(squareFeet),
        distanceFromSubjectMax: 1,
        yearBuiltMin: yearBuilt - 10 || null,
        yearBuiltMax: (yearBuilt && Math.min(yearBuilt + 10, new Date().getFullYear())) || null,
        mlsListingStatus: 'SOLD',
        listingType: property.get('nonDisclosure') ? 'M' : 'U',
        saleDateMin: moment().startOf('day').subtract(6, 'M'),
      };

      offer = { ...DefaultOffer, squareFeet, arvSearch: { ...search }, exitPriceSearch: { ...search } };
    } else {
      const { arvSearch, exitPriceSearch } = offer;
      const getDate = d => (typeof d === 'number' ? moment(d) : d);
      arvSearch.saleDateMin = getDate(arvSearch.saleDateMin);
      arvSearch.saleDateMax = getDate(arvSearch.saleDateMax);
      exitPriceSearch.saleDateMin = getDate(exitPriceSearch.saleDateMin);
      exitPriceSearch.saleDateMax = getDate(exitPriceSearch.saleDateMax);

      if (!offer.squareFeet) offer.squareFeet = squareFeet; // For offers saved before sqft was required entry
    }

    const manual = !!offer.id;

    this.setState({ arvManual: manual, exitPriceRateManual: manual, steps: {}, offer: { ...offer } }, () => {
      this.openDefaultRoute();
      this.props.updateSurroundingPropertySearch(SurroundingPropertyContexts.OFFER_ARV, offer.arvSearch, true);
      this.props.updateSurroundingPropertySearch(SurroundingPropertyContexts.OFFER_EXIT_PRICE, offer.exitPriceSearch, true);
      this.updateOffer();
    });
  }

  handlePreviousClick() {
    this.moveStep(false);
  }

  handleNextClick() {
    this.moveStep(true);
  }

  handleNewClick() {
    this.loadOffer();
  }

  handleActiveChange(ev) {
    const id = Number(ev.target.value);
    this.loadOffer(id ? this.props.offers.find(o => o.get('id') === id).toJS() : DefaultOffer);
  }

  handleRenameClick() {
    const { prompt, offers, saveOffer } = this.props;
    const { offer: { id, name } } = this.state;

    prompt({
      text: 'Please enter a new name for this offer.',
      title: 'Rename Offer',
      value: name,
      onSubmit: name => saveOffer(offers.find(o => o.get('id') === id).set('name', name)),
    });
  }

  handleDeleteClick() {
    const { confirmAction, deleteOffer } = this.props;
    const { offer: { id } } = this.state;

    if (id) {
      confirmAction({
        question: 'Delete this offer?',
        onOk: () => deleteOffer(id, this.handleNewClick),
      });
    }
  }

  handleSaveClick() {
    const { saveOffer, checkSavedProperty, prompt, arvType, exitPriceType } = this.props;

    const getSearch = type => ({ ...type.get('search').toJS(), selectedIds: type.get('selection').map(s => s.get('id')).toJS().join(',') });

    const offer = { ...this.state.offer, arvSearch: getSearch(arvType), exitPriceSearch: getSearch(exitPriceType) };
    const { id } = offer;

    checkSavedProperty(() => {
      if (id) saveOffer(offer);
      else {
        prompt({
          text: 'Please enter a name for this offer.',
          title: 'Save Offer',
          value: 'New Offer',
          onSubmit: name => saveOffer({ ...offer, name, savedPropertyId: this.props.savedPropertyId, id: null }, ({ response }) => this.loadOffer(response.find(({ modified }) => modified))),
        });
      }
    });
  }

  handleNumberParamsChange(event) {
    const { name, value } = getChangeData(event);
    this.updateOffer({ [name]: value }, name);
  }

  handleSquareFeetChange(ev) {
    const { updateSurroundingPropertySearch, arvType, exitPriceType } = this.props;
    const { value } = getChangeData(ev);
    const { offer } = this.state;
    const { squareFeet, arvSearch: curArv, exitPriceSearch: curExitPrice } = offer;

    this.setState({ offer: { ...offer, squareFeet: value } }, () => {
      // Update and re-run search if criteria was already defaulted and sqft has changed
      const { squareFeetMin: curMin, squareFeetMax: curMax } = squareFeetDefaults(squareFeet);
      const { squareFeetMin, squareFeetMax } = squareFeetDefaults(value);

      const getUpdatedSearch = (search) => {
        const { squareFeetMin: min, squareFeetMax: max } = search;
        return (value && value !== squareFeet && ((!min && !max) || (min === curMin && max === curMax))) ? { ...search, squareFeetMin, squareFeetMax } : null;
      }

      const newArv = getUpdatedSearch(arvType.get('search').toJS());
      const newExitPrice = getUpdatedSearch(exitPriceType.get('search').toJS());

      if (newArv || newExitPrice) {
        this.setState({ offer: { ...this.state.offer, arvSearch: newArv || curArv, exitPriceSearch: newExitPrice || curExitPrice } }, () => {
          const { arvSearch, exitPriceSearch } = this.state.offer;
          if (newArv) updateSurroundingPropertySearch(SurroundingPropertyContexts.OFFER_ARV, arvSearch, true);
          if (newExitPrice) updateSurroundingPropertySearch(SurroundingPropertyContexts.OFFER_EXIT_PRICE, exitPriceSearch, true);
          this.updateOffer();
        });
      }
    });
  }

  handleSearchComplete(context) {
    const { sortSurroundingProperties, updateSurroundingPropertySelection, arvType, exitPriceType, property } = this.props;
    const { offer } = this.state;
    const arv = context === SurroundingPropertyContexts.OFFER_ARV;
    const type = arv ? arvType : exitPriceType;
    const selectedIds = (offer[arv ? 'arvSearch' : 'exitPriceSearch'].selectedIds || '').split(',').filter(id => Number(id)).reduce((m, id) => ({ ...m, [Number(id)]: true }), {});
    const selection = type.get('properties').map((p, i) => (selectedIds[p.get('id')] ? i : -1)).filter(i => i >= 0);

    offer[arv ? 'arvSearch' : 'exitPriceSearch'].selectedIds = null;

    sortSurroundingProperties(context, arv ? '!pricePerSquareFoot' : 'saleAmount');
    updateSurroundingPropertySelection(context, null, false);

    if (selection.size) selection.forEach(i => updateSurroundingPropertySelection(context, i, true));
    else {
      // Default to selecting the top 3 comps. If displaying both MLS & PR, ensure that only unique properties are checked.
      const properties = type.get('properties');
      const propertyIds = [];
      for (let i = 0; propertyIds.length < 3 && i < properties.size; i++) {
        const id = properties.getIn([i, 'propertyId']);
        if (!propertyIds.includes(id)) {
          updateSurroundingPropertySelection(context, i, true);
          propertyIds.push(id);
        }
      }
    }
  }

  render() {
    const { activeStepIndex, offer, arvCalc, exitPriceRateCalc, steps } = this.state;
    const { loading: propertyLoading, offerLoading, offers, property } = this.props;

    const isLoading = propertyLoading || offerLoading;
    const { id, arv, exitPriceRate } = offer;
    const context = Steps[activeStepIndex].context;
    const stepReady = (steps[activeStepIndex] || {}).ready;

    const offerOptions = List([{ value: 0, label: '--New Offer--' }].concat(offers.toJS().map(({ id: value, name: label }) => ({ value, label }))));

    const buttonProps = { isLoading, kind: Button.kind.blue, size: Button.size.middle };
    const buttonProps2 = { isLoading, kind: Button.kind.blueGhost, size: Button.size.middle };

    const maxStep = (arv && (exitPriceRate ? Steps.length - 1 : 1)) || 0;
    const inputProps = { value: offer, onChange: this.handleNumberParamsChange };
    const currencyProps = { ...inputProps, maxFractionDigits: 0 };
    const percentProps = { ...currencyProps, maxFractionDigits: 3 };

    return (
      <UrlStorage urls={this.props.urls}>
        <Modal
          isOpen
          isCloseButton
          uniqId="offerWizard"
          width="1360px"
          height="100%"
          padding="0"
          caption={(
            <div className={css.header}>
              <div className={css.subject}>
                <div className={css.address}>{formatFullAddressObject(property.get('address'))}</div>
                <div className={css.details}>
                  {PropertyFields.map(f => ({ ...f, val: property.get(f.field) })).filter(f => !!f.val).map(({ label, field, val }) => <div className={css.detail} key={label}>{label}: {field === 'yearBuilt' ? val : formatNumber(val)}</div>)}
                </div>
              </div>
              <div className={css.savedAnalyses}>
                <LoadableDropdown label="Saved Offers" options={offerOptions} disabled={isLoading} loaderSize="tiny" loaderDirection="horizontal" onChange={this.handleActiveChange} value={id} />
              </div>
              <Button {...buttonProps} onClick={this.handleRenameClick} disabled={!id}>Rename</Button>
              <Button {...buttonProps} onClick={this.handleDeleteClick} disabled={!id}>Delete</Button>
            </div>
          )}
        >
          <div className={css.body}>
            <div className={css.left}>
              <Breadcrumbs
                breadcrumbs={Steps.map((step, index) => ({
                  ...step,
                  link: this.getFullTabLink(index),
                  isActive: index === activeStepIndex,
                  isPast: index < activeStepIndex,
                  disabled: index > maxStep,
                }))}
              />
              <div className={css.fieldContainer}>
                {[
                  (<div className={css.fields}>
                    <div className={css.field}>
                      <IntegerInput label="SqFt." name="squareFeet" {...inputProps} onChange={this.handleSquareFeetChange} />
                    </div>
                    <div className={css.field}>
                      <CurrencyInput label="ARV" name="arv" {...currencyProps} />
                    </div>
                    {arv === arvCalc ? null : (
                      <div className={css.info} onClick={() => this.clearField('arv')}>
                        Calculated ARV is now {numberToPrice(arvCalc)} based on current comps. Click here to update.
                      </div>
                    )}
                  </div>),
                  (<div className={css.fields}>
                    <div className={css.field}>
                      <CurrencyInput label="ARV" name="arv" {...currencyProps} />
                    </div>
                    <div className={css.field}>
                      <PercentInput label=" " name="exitPriceRate" value={offer} {...percentProps} />
                    </div>
                    <div className={css.field}>
                      <CurrencyInput label="Exit Price" name="exitPrice" {...currencyProps} readOnly />
                    </div>
                    {exitPriceRate === exitPriceRateCalc ? null : (
                      <div className={css.info} onClick={() => this.clearField('exitPriceRate')}>
                        Calculated Exit Price Rate is now {formatPercent(exitPriceRateCalc * 100)} based on current comps. Click here to update.
                      </div>
                    )}
                  </div>),
                  (<div className={css.fields}>
                    <div className={css.field}>
                      <CurrencyInput label="Exit Price" name="exitPrice" {...currencyProps} readOnly />
                    </div>
                    <div className={css.field}>
                      <CurrencyInput label="Desired Profit" name="desiredProfit" {...currencyProps} />
                    </div>
                    <div className={css.field}>
                      <CurrencyInput label="Max Allowable Offer" name="maxOffer" {...currencyProps} readOnly />
                    </div>
                  </div>),
                  (<div className={css.fields}>
                    <div className={css.field}>
                      <CurrencyInput label="Max Allowable Offer" name="maxOffer" {...currencyProps} readOnly />
                    </div>
                    <div className={css.field}>
                      <PercentInput label="Wiggle Room" name="wiggleRoom" {...percentProps} />
                    </div>
                    <div className={css.field}>
                      <CurrencyInput label="Goal Target Number" name="goalTarget" {...currencyProps} readOnly />
                    </div>
                  </div>),
                  (<div className={css.fields}>
                    <div className={css.profit}>
                      Your Profit:
                      <span>{numberToPrice(offer.profit)} - {numberToPrice(offer.profit2)}</span>
                    </div>
                  </div>),
                ][activeStepIndex]}
              </div>
              <div className={css.bottom} onScroll={this.handleScroll}>
                {context ? (
                  <div className={css.surrounding}>
                    {!stepReady ? null : <Surrounding embedded context={context} onSearchComplete={this.handleSearchComplete} />}
                  </div>
                ) : (
                  <div className={css.logo}>
                    <Image src={getBrandLogoUrl()} placeholder={getBrandLogoAltUrl()} />
                  </div>
                )}
              </div>
            </div>
            <div className={css.right}>
              <div className={css.title}>Offer Analysis</div>
              <div className={css.items}>
                <div className="listKeyValue">
                  <ListKeyValue>{SummaryFields.map(({ label, field }) =>
                    (<div key={field} className={classNames('row', css.row)}>
                      <div className={classNames('key', css.key)}>{label}</div>
                      <div className={classNames('value', css.value)}>
                        {numberToPrice(offer[field])}
                        {!(`${field}2` in offer) || offer[field] === offer[`${field}2`] ? null : ` - ${numberToPrice(offer[`${field}2`])}`}
                      </div>
                    </div>),
                  )}</ListKeyValue>
                </div>
              </div>
            </div>
          </div>
          <div className={css.footer}>
            <div className={css.left}>
              <Button {...buttonProps} onClick={this.handlePreviousClick} disabled={!activeStepIndex}>{'< Back'}</Button>
              <Button {...buttonProps} onClick={this.handleNextClick} disabled={activeStepIndex >= maxStep}>{'Next >'}</Button>
            </div>
            <div className={css.right}>
              <Button {...buttonProps2} onClick={this.handleNewClick}>Start New Offer</Button>
              <Button {...buttonProps2} onClick={this.handleSaveClick}>Save</Button>
            </div>
          </div>
        </Modal>
      </UrlStorage>
    );
  }
}

const OfferWizardPopup = withProperty(Offer, state => ({
  offers: selectOffers(state),
  offerLoading: selectOfferLoading(state),
  currentPathname: List(state.getIn(['routing', 'locationBeforeTransitions', 'pathname'], '').split('/')),
  arvType: selectSurroundingPropertyType(state, SurroundingPropertyContexts.OFFER_ARV),
  exitPriceType: selectSurroundingPropertyType(state, SurroundingPropertyContexts.OFFER_EXIT_PRICE),
}), {
  loadOffers,
  saveOffer,
  deleteOffer,
  updateSurroundingPropertySearch,
  sortSurroundingProperties,
  updateSurroundingPropertySelection,
  prompt: Prompt.open,
  confirmAction: Confirm.open,
});

const registrationId = getPopupRegistration(OfferWizardPopup);

OfferWizardPopup.open = (props, ...rest) => openPopup(registrationId, { priority: Priority.MEDIUM, ...props }, ...rest);
OfferWizardPopup.close = () => closePopup({ popup: registrationId });

export default OfferWizardPopup;
