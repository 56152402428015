import React from 'react';
import Checkbox from 'components/base/Checkbox';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import css from './style.scss';

const checkboxContainerClassName = classNames(css.part, css.checkboxOnly);

const CheckboxField = ({ name, label, fieldMap, search, onChange, onInputRef, containerClass }) => {
  const effectiveLabel = label || fieldMap[name].label;

  return (
    <div className={containerClass}>
      <div className={css.formElementContainer}>
        <Checkbox name={name} label={effectiveLabel} onChange={onChange} onInputRef={onInputRef} checked={search.get(name, false)} value="true" />
      </div>
    </div>
  );
};

CheckboxField.propTypes = {
  containerClass: PropTypes.string,
};

CheckboxField.defaultProps = {
  containerClass: checkboxContainerClassName,
};

export default CheckboxField;
