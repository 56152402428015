let promise = Promise.resolve();

const deferQueue = (queue) => {
  promise = queue.reduce(
    (accum, cb) => accum.then(
      () => new Promise(
        resolve => requestAnimationFrame(
          () => {
            if (typeof cb === 'function') cb();
            requestAnimationFrame(resolve);
          },
        ),
      ),
    ),
    promise,
  );
};

export default deferQueue;