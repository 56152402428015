const events = {};

export function run(name, { scrollTop: y, scrollLeft: x }) {
  if (events[name]) events[name].forEach(fn => fn({ y, x }));
}

export function initScrollElement(name) {
  events[name] = events[name] || [];
}

export function destructScrollElement(name) {
  events[name] = null;
}

export function bind(name, fn) {
  return (events[name] || []).push(fn);
}

export function unbind(name, fn) {
  const index = (events[name] || []).indexOf(fn);
  if (index !== -1) {
    events[name].splice(index, 1);
    return true;
  }
  return false;
}
