import { getCode } from 'store/middleware/api/activate';
import config from 'config';


const { constants: { API: { BASE_URL } } } = config;


export function getBrandDomain() {
  const hostname = window.location.hostname.toLowerCase();

  // if (process.env.NODE_ENV !== 'production') return 'local.premierpropertylocator.com';
  // if (process.env.NODE_ENV !== 'production') return 'local.automaterei.com';
  // if (process.env.NODE_ENV !== 'production') return 'local.exitrealty.propstream.com';
  // if (process.env.NODE_ENV !== 'production') return 'local.propfinderonline.com';
  // if (process.env.NODE_ENV !== 'production') return 'local.eliteress.com';
  // if (process.env.NODE_ENV !== 'production') return 'local.flipproperties.net';
  // if (process.env.NODE_ENV !== 'production') return 'local.investorsedgesoftware.com';
  // if (process.env.NODE_ENV !== 'production') return 'local.fixflippros.com';
  // if (process.env.NODE_ENV !== 'production') return 'local.propstream.com';

  return hostname.replace(/^app\./, '');
}

export default function getBrandUrl(subDomain, path = '') {
  const domain = getBrandDomain();

  return `${window.location.protocol}//${subDomain}.${domain}/${path}`;
}

/**
 * Return the web app domain without the leading "app." and dev/qa/local/staging environment subdomains.
 *
 * @returns {string}
 */
export function stripBrandDomain(domain) {
  return domain.replace('dev.', '').replace('qa.', '').replace('local.', '').replace('staging.', '');
}

/**
 * Extract the dev/qa/local/staging environment subdomain.
 *
 * @returns {string}
 */
export function getEnvironmentCode() {
  const match = getBrandDomain().match(/^(local|dev|qa|staging)\./);

  return match ? match[1] : undefined;
}

/**
 * Derive a domain based on the environment subdomain.
 *
 * @returns {string}
 */
export function getEnvironmentDomain(subdomain, domain) {
  const env = getEnvironmentCode();
  const dom = `${subdomain ? `${subdomain}.` : ''}${env ? `${env}.` : ''}${domain}`;

  return dom;
}

/**
 * Derive a url based on the environment subdomain.
 *
 * @returns {string}
 */
export function getEnvironmentUrl(subdomain, domain, path = '') {
  const dom = getEnvironmentDomain(subdomain, domain);
  const url = `${window.location.protocol}//${dom}/${path}`;

  return url;
}

export function getBrandCode() {
  const code = stripBrandDomain(getBrandDomain()).split('.')[0];

  switch (code) {
    case 'eliteress':
      return 'elite';

    case 'investorsformulatools':
      return 'investorsformula';

    case 'propfinderonline':
    case 'propfinderweb':
      return 'repropfinder';

    case 'buildingwealthsoftware':
      return 'buildingwealth';

    case 'rpp5online':
      return 'propertypro';

    case 'streetsmartpropwiz':
      return 'streetsmart';

    case 'themavrix':
    case 'flippingedge':
      return 'flipout';

    case 'investorsedgesoftware':
      return 'investorsedge';

    case 'automaterei':
      return 'reiautomator';

    case 'lender-demo':
      return 'lenderdemo';

    case 'kkhousefinder':
      return 'kriskrohnhousefinder';

    case 'fixflippros':
    case 'propstreamkw':
      return 'propstream';

    default:
      return code; // propstream, repropfinder, padhawk, nationalproplocator, propertyedgepro, epicpropertyfinder, premierpropertylocator, exitrealty
  }
}

export function isProduction() {
  const domain = getBrandDomain();

  return !(domain.startsWith('dev.') || domain.startsWith('qa.') || domain.startsWith('local.'));
}

export function isPropStream() {
  return getBrandCode() === 'propstream';
}

export function isPropStreamHost() {
  return stripBrandDomain(getBrandDomain()) === 'propstream.com';
}

export function isElite() {
  return getBrandCode() === 'elite';
}

export function isReiAutomator() {
  return getBrandCode() === 'reiautomator';
}

export function isInvestorsEdge() {
  return getBrandCode() === 'investorsedge';
}

export function isExitRealty() {
  return getBrandCode() === 'exitrealty';
}

export function isLaAvailable() {
  return !['investorsedge','exitrealty'].includes(getBrandCode());
}

export function getBrandWebUrl(path) {
  return getBrandUrl(isPropStreamHost() ? 'signup' : 'www', path);
}

export function getBrandLoginUrl() {
  const ps = isPropStreamHost();
  return getBrandUrl(ps ? 'login' : 'www', ps ? '' : 'login');
}

export function getBrandHelpUrl() {
  const ps = isPropStreamHost();
  return `${getBrandUrl(ps ? 'help' : 'www', ps ? '' : 'help')}?c=${getCode()}`;
}

export function getBrandTermsUrl() {
  const ps = isPropStreamHost();
  return getBrandUrl(ps ? 'resource' : 'www', 'terms.jsp');
}

export function getBrandLogoUrl() {
  // Remove dev/staging replacements here when testing local resources (i.e when testing a new brand).
  const code = getBrandCode();
  if (['exitrealty'].includes(code)) return `/assets/images/logos/${code}.png`;

  return `${getBrandWebUrl('resource/logo')}?c=${getCode()}`;
}

export function getBrandSmallLogoUrl() {
  return `${getBrandWebUrl('resource/logo-sm')}?c=${getCode()}`;
}

export function getBrandLogoAltUrl() {
  return stripBrandDomain(getBrandLogoUrl());
}

export function getBrandIconUrl() {
  return `${getBrandWebUrl('resource/icon')}?c=${getCode()}`;
}

export function getUserLogoUrl(uid) {
  return `${BASE_URL}/resource/auth/ps4/user/${uid ? `logo/${uid}?${Math.random()}` : 'defaultLogo'}`;
}

export function getAerialImageUrl(pid) {
  return `${BASE_URL}/resource/auth/ps4/property/${pid}/image`;
}

const brands = {
  buildingwealth: { name: 'Building Wealth with Russ Whitney' },
  elite: { name: 'Real Estate Success Software' },
  investorsformula: { name: 'Investor\'s Formula' },
  padhawk: { name: 'PadHawk' },
  propertypro: { name: 'Real Estate Property Pro' },
  propstream: { name: 'PropStream' },
  repropfinder: { name: 'RE PropFinder' },
  streetsmart: { name: 'PropStream' },
  flipout: { name: 'Flipout Academy' },
  investorsedge: { name: 'Investor\'s Edge' },
  reiautomator: { name: 'REI Automator' },
  lenderdemo: { name: 'Lender Demo' },
  nationalproplocator: { name: 'National Prop Locator' },
  propertyedgepro: { name: 'Property Edge Pro' },
  exitrealty: { name: 'Exit Realty' },
  kriskrohnhousefinder: { name: 'Kris Krohn House Finder' },
  epicpropertyfinder: { name: 'Epic Property Finder' },
  premierpropertylocator: { name: 'Premier Property Locator' },
  flipproperties: { name: 'Flip Properties' },
};

export function getBrand() {
  return brands[getBrandCode()];
}

export function getBrandName() {
  return getBrand().name;
  return "PropStream"
  // return getBrand().name;
}

export const getBrandRedirectMessage = (url) => {
  const brand = getBrandName();
  let service;

  if (url.includes('reiskip.com')) service = 'REISkip - Skip Tracing';
  else if (url.includes('reirail.com')) service = 'REIRail - Ringless Voicemail';
  else if (url.includes('supermpower.com/skiptracing')) service = 'M-Power - Skip Tracing';
  else if (url.includes('supermpower.com/ringless-voicemail')) service = 'M-Power - Ringless Voicemail';
  else if (url.includes('supermpower.com/direct-mail')) service = 'M-Power - Direct Mail';
  else if (url.includes('needtoskip.com')) service = 'NeedToSkip';
  else service = url;

  return `You are being redirected to ${service}, a third-party product not operated by ${brand}. By clicking "Continue", you acknowledge that you are leaving the ${brand} service, and agree to be redirected to this third-party product`;
};
