/** @flow */
/*
 * If has not some API's you need, please check react-bootstrap-table package.
 * If there is API you need, please ask Konstantin Petryaev about update this component.
 */
import React, { PureComponent, Component, Children } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ImPropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import Column from './Column';
import RealColumn from './RealColumn';
import HeaderCell from './HeaderCell';
import TableBody from './TableBody';

import css from './styles.scss';


const renderSimple = val => val;

const renderNothing = () => null;

const noExtraProps = {};
Object.freeze(noExtraProps);

const emptyList = List();

export {
  Column,
};

export default class Table extends PureComponent {
  /* :: state: { expandedRow: ?number } */
  constructor(props) {
    super(props);
    this.state = {
      expandedRow: null,
    };
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.isExpandedRow = this.isExpandedRow.bind(this);
    this.isActiveRow = this.isActiveRow.bind(this);
  }

  getColumns() {
    const meta = [];
    const columns = [];
    let keyField = null;
    const { sortableFields } = this.props;
    const allKeys = new Set();

    Children.forEach(this.props.children, (child) => {
      if (child) {
        switch (child.type) {
          case Column: {
            const {
              props: {
                field,
                renderCell = renderSimple,
                renderTooltip = renderNothing,
                cellClassName,
                defaultValue = null,
                isKey = false,
                row,
                extraProps = noExtraProps,
                addIndex = false,
                fieldName = field,
                ...rest
              },
            } = child;

            const columnMeta = {
              field,
              ...rest,
            };

            if (row === 0) {
              meta.push({
                field,
                renderCell,
                renderTooltip,
                cellClassName,
                defaultValue,
                extraProps,
                addIndex,
              });

              if (isKey) keyField = field;
            }

            columns[row] = columns[row] || [];

            let key = field;
            while (allKeys.has(key)) key += '%';
            allKeys.add(key);

            const isSortable = sortableFields === emptyList ?
              this.props.isSortable
              :
              sortableFields.includes(field);

            return columns[row].push(
              <RealColumn
                {...rest}
                key={key}
                fieldName={fieldName}
                onColumnSort={this.handleColumnSort}
                isSortable={isSortable}
                sortFields={this.props.sortFields}
                tableName={this.props.tableName}
              >
                <HeaderCell meta={columnMeta}>
                  {rest.children}
                </HeaderCell>
              </RealColumn>);
          }
          default:
        }
      }
      return null;
    });

    return { columns, meta, keyField };
  }

  /* :: handleRowClick: Function */
  handleRowClick(event, data, rowIndex) {
    if (typeof this.props.onRowClick === 'function') {
      this.props.onRowClick(event, data, rowIndex);
    }

    if (!this.props.expandableRow(rowIndex) || this.isExpandedRow(rowIndex)) {
      this.setState({ expandedRow: null });
    } else {
      this.setState({ expandedRow: rowIndex });
    }
  }

  /* :: handleColumnSort: Function */
  handleColumnSort(columnName) {
    if (typeof this.props.onColumnSort === 'function') {
      this.props.onColumnSort(columnName);
    }
  }

  /* :: isExpandedRow: Function */
  isExpandedRow(rowIndex) {
    return (this.state.expandedRow) === rowIndex;
  }

  /* :: isActiveRow: Function */
  isActiveRow(rowIndex) {
    return (this.props.activeRow) === rowIndex;
  }

  render() {
    // TODO: replace className property into type property with moving classNames into some clearly values
    const {
      children,
      data,
      className,
      tableName,
      isHoverable,
      isSortable,
      keyField: keyFromProps,
      onRowClick,
      onColumnSort,
      expandableRow,
      expandComponent,
      sortFields,
      activeRow,
      sortableFields,
      activeRowClassName,
      save,
      ...rest
    } = this.props;
    const { columns, meta, keyField } = this.getColumns();

    // eslint-disable-next-line react/no-array-index-key
    const theadContent = columns.map((columnsRow, index) => (<tr key={index} className={css.headTr}>
      {columnsRow}
    </tr>));
    const rootClasses = classNames(css.table, css[className], { [css.isHoverable]: isHoverable });

    return (
      <table {...rest} className={rootClasses}>
        <thead>{theadContent}</thead>
        <TableBody
          data={data}
          save={save}
          isExpandedRow={this.isExpandedRow}
          expandComponent={expandComponent}
          isActiveRow={this.isActiveRow}
          activeRowClassName={activeRowClassName}
          onRowClick={this.handleRowClick}
          keyField={keyFromProps || keyField}
          meta={meta}
          {...rest}
        />
      </table>
    );
  }
}

Table.defaultProps = {
  data: emptyList,
  sortableFields: emptyList,
  sortFields: emptyList,
  className: 'type_1',
  isHoverable: false,
  expandableRow: () => false,
  expandComponent: () => null,
};

Table.propTypes = {
  data: PropTypes.instanceOf(List).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOf([
    'type_1',
    'type_2',
    'type_3',
    'type_4',
    'type_5',
    'type_6',
    'type_7',
    'type_8',
    'type_9',
    'type_10',
    'type_11',
    'type_12']),
  tableName: PropTypes.string,
  isHoverable: PropTypes.bool,
  expandableRow: PropTypes.func,
  expandComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(Component),
  ]),
  isSortable: PropTypes.bool,
  sortableFields(props) {
    if (props.isSortable && props.sortableFields === emptyList) {
      return new Error(
        '`isSortable = true` without sortableFields enumeration is deprecated.',
      );
    }
    return null;
  },
  sortFields: ImPropTypes.listOf(PropTypes.string), // current sorting
};
