import React, { Component } from 'react';

import { joinWith, toProperCase } from '../string';


class AddressLinkCellRenderer extends Component {
  render() {
    const { data: { streetAddress, cityName, stateCode, zip, id, propertyId } = {} } = this.props;
    const url = `/search/${propertyId || id}`;
    const Component = id ? 'a' : 'div';
    const props = !id ? {} : { href: url, target: '_blank' };

    return (
      <div>
        <Component {...props}>
          {toProperCase(streetAddress)}
          <br />
          {joinWith(' ', joinWith(', ', toProperCase(cityName), stateCode), zip)}
        </Component>
      </div>
    );
  }
}

export default AddressLinkCellRenderer;
