import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getOptionsNoDefault } from 'utils/normalizers/dropdown';
import {
  searchGroupContext,
  updateGroupContext,
  updateGroupContextSelection,
  selectLoading,
  selectAssignableGroups,
  selectContactStatuses,
  selectContactTypes,
} from 'data/contacts';

import SearchTable, { idMap, groupIdRenderer, idRenderer } from '.';
import css from './style.scss';


const nameRenderer = url => (value, _, data) => <Link to={`${url}/${data.get('id')}`} className={css.link}>{value}</Link>;

class ContactSearchTable extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSearch(context) {
    this.props.searchGroupContext(context);
  }

  handleUpdate(context) {
    this.props.updateGroupContext(context);
  }

  handleSelect(select, index) {
    const { context, updateGroupContextSelection } = this.props;
    updateGroupContextSelection(context, select, index);
  }

  render() {
    const { context, groups, loading, types, statuses, viewMode, fullMode, onDragStart, onDragEnd } = this.props;

    const modes = 'Any,None,All'.split(',').map(label => ({ label, value: label.toUpperCase() }));
    const modes2 = modes.slice(0, 2);

    const fields = [
      { name: 'name', label: 'Name', renderer: fullMode ? nameRenderer(`/contact/${context.get('id')}`) : undefined },
      { name: 'mobilePhone', label: 'Mobile' },
      { name: 'landlinePhone', label: 'Landline' },
      { name: 'otherPhone', label: 'Other' },
      { name: 'email', label: 'Email' },
      { name: 'address', label: 'Property Address' },
      { name: 'mailAddress', label: 'Mailing Address' },
      { name: 'typeId', label: 'Type', renderer: idRenderer(idMap(types)), options: getOptionsNoDefault(types.toJS()), modes: modes2 },
      { name: 'statusId', label: 'Status', renderer: idRenderer(idMap(statuses)), options: getOptionsNoDefault(statuses.toJS()), modes: modes2 },
      { name: 'groupIds', label: 'Groups', filterField: 'groupIds', renderer: groupIdRenderer(idMap(groups)), options: getOptionsNoDefault(groups), modes, searchable: true, sortable: false, display: fullMode },
    ];

    const props = { context, loading, fields, onDragStart, onDragEnd, viewMode };

    return (
      <SearchTable
        {...props}
        className={css.contactSearchTable}
        onSearch={this.handleSearch}
        onUpdate={this.handleUpdate}
        onSelect={this.handleSelect}
      />
    );
  }
}

ContactSearchTable.propTypes = {
  viewMode: PropTypes.bool,
  fullMode: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
};

ContactSearchTable.defaultProps = {
  viewMode: false,
  fullMode: false,
};

export default connect(state => ({
  groups: selectAssignableGroups(state),
  statuses: selectContactStatuses(state),
  types: selectContactTypes(state),
  loading: selectLoading(state),
}), {
  searchGroupContext,
  updateGroupContext,
  updateGroupContextSelection,
})(ContactSearchTable);
