/** @flow */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Priority } from './constants';
import { closePopup } from './actions';
import PopupLayerHolder from './PopupLayerHolder';


const style = { zIndex: 10 };

const BODY_CLASS_NAME = 'bodyModal';


class PopupWrapperContainer extends Component {
  /* :: popupClosers: Array<Function> */
  constructor(props) {
    super(props);
    this.popupClosers = Object.keys(Priority)
      .map((name, index) => ((...rest) => this.closePopup(index, ...rest)));
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.layers !== this.props.layers;
  }

  componentDidUpdate(oldProps) {
    const { body } = document;
    if (!body) return;
    const { classList } = body;
    if (!classList) return;

    if (oldProps.hasAnyPopup) {
      if (!this.props.hasAnyPopup) {
        classList.remove(BODY_CLASS_NAME);
      }
    } else if (this.props.hasAnyPopup) {
      classList.add(BODY_CLASS_NAME);
    }
  }

  closePopup(index, arg = {}, ...rest) {
    const priority = Priority[Object.keys(Priority)[index]];
    const target = { ...arg, priority };
    this.props.closePopup(target, ...rest);
  }

  renderNoPriority() {
    const nextLayers = this.props.layers.slice(1);

    if (nextLayers.every(layer => layer.size === 0)) {
      return (<PopupLayerHolder
        layer={this.props.layers.first()}
        priority={0}
        closePopup={this.popupClosers[0]}
      />);
    }

    return null;
  }

  renderLayers() {
    const nextLayers = this.props.layers.slice(1);

    return nextLayers.map((layer, index) => (<PopupLayerHolder
      key={index} // eslint-disable-line react/no-array-index-key
      layer={layer}
      priority={index + 1}
      closePopup={this.popupClosers[index + 1]}
    />));
  }

  render() {
    return (
      <div style={style}>
        {this.renderNoPriority()}
        {this.renderLayers()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    layers: state.getIn(['app', 'popup', 'queues']),
    hasAnyPopup: state.getIn(['app', 'popup', 'queues']).some(queue => queue.has(0)),
  };
}

const mapActionToProps = {
  closePopup,
};

export { PopupWrapperContainer };
export default connect(mapStateToProps, mapActionToProps)(PopupWrapperContainer);

