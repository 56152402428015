import config from 'config';
import { isProduction } from 'utils/brand';


const { constants: { API: { BASE_URL } } } = config;

const cssUrl = (url, css) => (url && css && `url(${url})`) || url || null;
const formatServerUrl = (path, css) => cssUrl(`${BASE_URL}/${path}?${isProduction() ? '' : 'dev=true'}`, css);
export const formatDocumentUrl = (doc, css, preview) => (doc ? cssUrl(doc.dataUrl, css) || formatServerUrl(preview ? doc.previewUrl : doc.url, css) : null);
export const formatGraphicUrl = ({ rendering }, css, preview) => (rendering ? formatDocumentUrl(rendering, css, preview) : null);

export const campaignWebsiteUrl = path => `${window.location.hostname.includes('dev.') ? 'http://www.dev' : 'https://www'}.relistingsite.com/${path || ''}`;

export default formatServerUrl;
