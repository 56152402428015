import { MAP_NO_METRIC } from 'data/search';

const METRIC_TYPES = {
  [MAP_NO_METRIC]: [0, 1, 2, 3],
  ESTVALUE: [0],
  MLS_LISTING_AMOUNT: [1],
  MLS_SQUARE_FOOT_AMOUNT: [1],
  SQFEET: [1],
  LOTSIZE: [1],
  BEDROOMS: [1],
  ESTVALUE_SQFEET: [0],
  ESTVALUE_LOTSIZE: [0],
  ESTVALUE_BEDROOMS: [0],
  ESTVALUE_1MONTH: [2],
  ESTVALUE_6MONTH: [2],
  ESTVALUE_1YEAR: [2],
  ESTVALUE_3YEAR: [2],
  ESTVALUE_5YEAR: [2],
  RENT_AMOUNT: [3],
  BEDROOM_RENT_AMOUNT: [3],
  SQUARE_FOOT_RENT_AMOUNT: [3],
};

const DEFAULT_METRIC_TYPE = []; // Don't display unknown types

const METRIC_NAME = [
  'Value',
  'MLS Info',
  'Growth',
  'Rent',
];

export const getDefaultMetricName = activeTab => METRIC_NAME[activeTab];

export const usePrettyNaming = (type, metrics) => metrics.map(metric => (metric.get('name') === MAP_NO_METRIC ? metric.set('displayName', getDefaultMetricName(type)) : metric));

export const getName = (type, metric) => metric && metric.get('name') === MAP_NO_METRIC ? getDefaultMetricName(type) : metric.get('displayName');

export const getMetricsByType = (type, metrics) => usePrettyNaming(type, metrics.filter(metric => (METRIC_TYPES[metric.get('name')] || DEFAULT_METRIC_TYPE).includes(type)));
