import React from 'react';
import classNames from 'classnames';
import Radio from 'components/base/Radio';

import css from './style.scss';

const YesNoAnyOptions = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
  { label: 'Any', value: '' },
];

const BooleanRadioField = ({ name, label, fieldMap, search, onChange, onInputRef, section, className }) => {
  let value = search.get(name, '');
  if (value == null) value = '';

  const field = fieldMap[name];
  const effectiveLabel = label || field.label;
  const options = field.options || YesNoAnyOptions;

  return (
    <div className={classNames(section ? css.section : css.part, className)}>
      <div className={section ? css.sectionCaption : css.partCaption}>{effectiveLabel}</div>
      {options.map((opt, i) => (
        <div className={css.formElementContainer} key={opt.label}>
          <Radio onChange={onChange} name={name} label={opt.label} value={opt.value} checked={String(value) === opt.value} onInputRef={i === 0 ? onInputRef : null} />
        </div>
      ))}
    </div>
  );
};

export default BooleanRadioField;
