
/**
 Common reducer functionality.
 **/

const loadingLabel = prefix => (prefix ? `${prefix}Loading` : 'loading');
const errorLabel = prefix => (prefix ? `${prefix}Error` : 'error');

export const loading = (state, prefix) => state.merge({ [loadingLabel(prefix)]: true, [errorLabel(prefix)]: null });

export const success = (state, prefix) => state.merge({ [loadingLabel(prefix)]: false, [errorLabel(prefix)]: null });

export const error = (state, { error }, prefix) => state.merge({ [loadingLabel(prefix)]: false, [errorLabel(prefix)]: error });

export const compareNumber = (num1, num2) => {
  if (num1 === num2) return 0;
  return num1 > num2 ? 1 : -1;
};

export const getSortComparator = (field) => {
  const desc = field.startsWith('!');
  const sort = (desc ? field.substr(1) : field).split('.');

  return (obj1, obj2) => {
    const a = obj1.getIn(sort);
    const b = obj2.getIn(sort);
    if (a == null) return 1;
    if (b == null) return -1;
    return (typeof a === 'string' ? a.toLowerCase().localeCompare(b.toLowerCase()) : compareNumber(a, b)) * (desc ? -1 : 1);
  };
};

export * from './group';
