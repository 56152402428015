/** @flow */
import { createSelector } from 'reselect';
import { List, Map } from 'immutable';


const emptyList = List();

const checkState = state => state;

const selectContracts = createSelector(
  checkState,
  state => state.getIn(['contracts']),
);

export const selectContractsData = createSelector(
  selectContracts,
  (contracts) => {
    const data = contracts.get('data', emptyList);
    return data.map(item => Map({
      id: item.get('id'),
      name: item.get('name'),
      description: item.get('description'),
      standard: item.get('standard'),
      editable: !item.get('standard'),
    }));
  },
);

export const selectIsLoading = createSelector(
  selectContracts,
  contracts => contracts.get('contractsLoading') || contracts.get('contractUploading')
    || contracts.get('contractDeleting') || contracts.get('contractsSaving') || contracts.get('contractLoading'),
);

export const selectIsContractsLoading = createSelector(
  selectContracts,
  contracts => contracts.get('contractsLoading'),
);
