import React, { Component } from 'react';
import SVG from 'components/base/SVG';

import css from './style.scss';


class SavedPropertyActionCellRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    const { value, context: { handleDeleteProperty }, data: { name } } = this.props;

    handleDeleteProperty(value, name);
  }

  render() {
    const { value, context: { id, groupId } } = this.props;

    return (
      <div className={css.action}>
        {id === value && groupId > 1 ? null : <SVG icon="iconTrash" title="Delete" onClick={this.handleDelete} />}
      </div>
    );
  }
}


export default SavedPropertyActionCellRenderer;
