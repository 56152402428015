import React, { PureComponent } from 'react';

import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import PropTypes from 'prop-types';

import Layout from './Layout';


class ErrorModal extends PureComponent {
  static registrationId;
  static open;

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
  }

  handleOk() {
    this.props.closePopup();
  }


  render() {
    const { message } = this.props;
    return (<Layout
      onOk={this.handleOk}
      message={message}
      caption="Information:"
    />);
  }
}

ErrorModal.propTypes = {
  message: PropTypes.node.isRequired,
};

ErrorModal.registrationId = getPopupRegistration(ErrorModal);
ErrorModal.open = (obj = {}, ...rest) => openPopup(ErrorModal.registrationId, { ...obj, priority: Priority.HIGHEST }, ...rest);


export default ErrorModal;
