import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import Confirm from 'app/components/Confirm';
import Button, { SolidButton } from 'components/Button';
import Text from 'components/Text';
import Modal from 'components/base/Modal';
import { selectProfile } from 'data/user';
import { getBrandLogoUrl, getBrandLogoAltUrl } from 'utils/brand';
import Image from 'components/base/Image';

import css from './style.scss';

const LoanApplication = ({ closePopup, profile }) => {
  const [formData, setFormData] = useState({
    firstName: profile.firstName || '',
    lastName: profile.lastName || '',
    email: profile.username || '',
    phone: profile.businessPhone || '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    bedrooms: '',
    bathrooms: '',
    squareFeet: '',
    lotSquareFeet: '',
    yearBuilt: '',
    propertyType: '',
    apn: '',
  });

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Modal
      isOpen
      uniqId="LoanApplication"
      caption=""
      width="800px"
      padding="0 25px 20px 25px"
    >
      <div className={css.app}>
        <div className={css.top}>
          <Image src={getBrandLogoUrl()} placeholder={getBrandLogoAltUrl()} className={css.logo} />
          <div className={css.title}>Loan Application</div>
        </div>
        <div className={css.sectionTitle}>Personal Information</div>
        <div className={css.fields}>
          <Text onChange={handleChange} value={formData.firstName} name="firstName" label="First Name" />
          <Text onChange={handleChange} value={formData.lastName} name="lastName" label="Last Name" />
          <Text onChange={handleChange} value={formData.email} name="email" label="Email" />
          <Text onChange={handleChange} value={formData.phone} name="phone" label="Phone" />
        </div>
        <div className={css.sectionTitle}>Property Information</div>
        <div className={css.fields}>
          <Text onChange={handleChange} value={formData.streetAddress} name="streetAddress" label="Street Address" />
          <Text onChange={handleChange} value={formData.city} name="city" label="City" />
          <Text onChange={handleChange} value={formData.state} name="state" label="State" />
          <Text onChange={handleChange} value={formData.zip} name="zip" label="Zip" />
          <Text onChange={handleChange} value={formData.bedrooms} name="bedrooms" label="Bedrooms" />
          <Text onChange={handleChange} value={formData.bathrooms} name="bathrooms" label="Bathrooms" />
          <Text onChange={handleChange} value={formData.squareFeet} name="squareFeet" label="Square Footage" />
          <Text onChange={handleChange} value={formData.lotSquareFeet} name="lotSquareFeet" label="Lot Size" />
          <Text onChange={handleChange} value={formData.yearBuilt} name="yearBuilt" label="Year Built" />
          <Text onChange={handleChange} value={formData.propertyType} name="propertyType" label="Property Type" />
          <Text onChange={handleChange} value={formData.apn} name="apn" label="APN" />
        </div>
        <div className={css.buttons}>
          <Button onClick={closePopup}>Cancel</Button>
          <SolidButton>Submit</SolidButton>
        </div>
      </div>
    </Modal>
  );
};

const LoanApplicationPopup = connect(state => ({
  profile: selectProfile(state).toJS(),
}), {
  confirm: Confirm.open,
})(LoanApplication);

LoanApplicationPopup.open = props => openPopup(getPopupRegistration(LoanApplicationPopup), { ...props, priority: Priority.HIGH });

export default LoanApplicationPopup;
