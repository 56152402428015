/** @flow */

function getProdApiMessage(action) {
  switch (action.response.statusCode) {
    case (401): return 'You are not authorized';
    case (403): return 'Authorization failed';
    case (422): return 'You can\'t do such action with this.';

    case (502):
    case (503):
    case (504):
      return 'Server is in process of restart. Please try again later.';

    case (500):
    case (501):
      return `Internal server error. Please try again later.
Contact your administrator if error happens again.`;

    default: {
      const { resBody } = action;
      let message = 'Unexpected error.';

      if (resBody && (resBody.message || resBody.userMessage)) {
        let restMessage = '';
        if (resBody.errors) restMessage = '\nSee form for details.';
        message = `${resBody.userMessage || resBody.message}${restMessage}`;
      }
      return message;
    }
  }
}

export default function getErrorMessage(action) {
  let text = 'Server is unavailable now. Please try again later or contact your administrator';
  let errorText = null;

  if (action.response) {
    if (action.response.text && typeof action.response.text === 'string') {
      const errorMessage = JSON.parse(action.response.text);
      errorText = errorMessage.message;
    }

    if (process.env.NODE_ENV !== 'dev-server--NOTUSING') {
      text = getProdApiMessage(action);
    } else {
      text = errorText ? `> ${errorText}

` : '';
      text += `> Status: ${action.response.statusCode}

> Message will shown on production:
===============================
${getProdApiMessage(action)}
===============================
> Request: ${JSON.stringify(action.requestParams, null, 2)}
-------------------------------
> Response body: ${JSON.stringify(action.resBody, null, 2)}`;
    }
  }

  return text;
}
