import React, { PureComponent } from 'react';

import loadable from './index';


class LoadablePanel extends PureComponent {
  render() {
    return <div style={{ height: '100%' }}>{this.props.children}</div>;
  }
}


export default loadable(LoadablePanel);
