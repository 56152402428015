import { Component } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual } from 'recompose';


export default class DeferMaster extends Component {
  constructor(props) {
    super(props);
    this.waiters = [];
    this.state = { count: 0 };
    this.registerDeferred = this.registerDeferred.bind(this);
    this.callDeferred = this.callDeferred.bind(this);
  }

  getChildContext() {
    return {
      registerDeferred: this.registerDeferred,
      callDeferred: this.callDeferred,
    };
  }

  shouldComponentUpdate(nextProps) {
    return !shallowEqual(this.props, nextProps);
  }

  registerDeferred(cb) {
    this.waiters = [...this.waiters, cb];
  }

  callDeferred() {
    setTimeout(() => {
      this.setState({ count: this.state.count + 1 }, () => {
        this.waiters.forEach(waiter => waiter());
        this.waiters = [];
      });
    }, 100);
  }


  render() {
    const { children } = this.props;
    return children || null;
  }
}

DeferMaster.childContextTypes = {
  registerDeferred: PropTypes.func.isRequired,
  callDeferred: PropTypes.func.isRequired,
};
