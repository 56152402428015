import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import css from 'app/Property/Group/style.scss';
import { connect } from 'react-redux';
import { selectSelection } from 'data/property';
import { dragStartWrapper } from 'utils/DOM/dragDrop';


class PropertyAddressCellRenderer extends Component {
  render() {
    const { value, selection, data: { id, listingType, propertyId } = {}, context: { groupId, onDragStart, onDragEnd, onSelect } = {} } = this.props;
    const selected = !!selection.getIn(['items', String(id)]);
    return <Link to={`/property/group/${groupId}/${propertyId}`} className={css.link}
      draggable={!!onDragStart}
      onDragStart={dragStartWrapper(() => onDragStart({ recordId: id, selected }))}
      onDragEnd={onDragEnd}
    >{value}</Link>;
  }
}

export default connect(state => ({ selection: selectSelection(state) }))(PropertyAddressCellRenderer);
