import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SVG from 'components/base/SVG';
import css from './style.scss';


class Radio extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { onChange, changeValue } = this.props;
    if (changeValue) {
      onChange(changeValue, event);
    } else if (onChange) {
      onChange(event);
    }
  }

  render() {
    const { id = `radio${Math.random()}`, label, hasArrow, input, onInputRef, meta, changeValue, realValue, className, ...rest } = this.props;
    const arrow = hasArrow ? <div className={css.arrow} /> : null;
    const disabled = rest.disabled || input.disabled;

    return (
      <div className={classNames(css.radio, { [css.disabled]: disabled }, className)}>
        <label htmlFor={id} className={css.switch}>
          <input {...rest} id={id} className={css.input} type="radio" ref={onInputRef} onChange={this.handleChange} {...input} />
          <SVG icon="iconRadio" className={css.iconRadio} />
          <SVG icon="iconRadioChecked" className={css.iconRadioChecked} />
          <span className={css.label}>{label}</span>
          {arrow}
        </label>
      </div>
    );
  }
}

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  hasArrow: PropTypes.bool,
  className: PropTypes.string,
};

Radio.defaultProps = {
  input: {},
};

export default Radio;
