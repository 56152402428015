import React, { PureComponent } from 'react';
import FormControlWraper from 'components/base/FormControlWraper';
import * as outerClick from 'utils/DOM/onOuterClick';


export default class CellWithInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hiddenInput: true,
      value: props.data.getIn(props.keyPath),
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOuterClick = this.handleOuterClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onContainerRef = this.onContainerRef.bind(this);
    this.onInputRef = this.onInputRef.bind(this);
  }

  componentWillMount() {
    outerClick.addListener(this.handleOuterClick);
  }

  componentWillUnmount() {
    outerClick.removeListener(this.handleOuterClick);
  }

  /* :: onContainerRef: Function */
  onContainerRef(ref) {
    this.container = ref;
  }

  /* :: onInputRef: Function */
  onInputRef(ref) {
    this.input = ref;
  }

  handleSave() {
    this.setState({ hiddenInput: true });
    const { onSave, keyPath, data } = this.props;
    if (data.getIn(keyPath) !== this.state.value) onSave(data.setIn(keyPath, this.state.value).toJS());
  }

  handleKeyDown(event) {
    // TODO: Handle escape
    if (event.keyCode === 9 || event.keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
      this.handleSave();
    }
  }

  /* :: handleOuterClick: Function */
  handleOuterClick(event) {
    if (this.state.hiddenInput || outerClick.isOnElement(event, this.container, true)) return;
    this.handleSave();
  }

  /* :: handleClick: Function */
  handleClick() {
    if (this.props.data.get('editable')) this.setState({ hiddenInput: false });
  }

  /* :: handleChange: Function */
  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  /* :: renderField: Function */
  renderField() {
    const { children, keyPath, id, isLoading } = this.props;
    /* eslint-disable jsx-a11y/no-autofocus */
    const input = this.state.hiddenInput || isLoading ? null : (<input
      type="text"
      id={`input-${id}-edit-${keyPath.join('')}`}
      ref={this.onInputRef}
      value={this.state.value}
      onChange={this.handleChange}
      onKeyDown={this.handleKeyDown}
      autoFocus
    />);
    /* eslint-enable jsx-a11y/no-autofocus */

    if (this.state.hiddenInput) return children;

    return (
      <FormControlWraper id={`input-${id}-edit-${keyPath.join('')}`} label="">
        {input}
      </FormControlWraper>
    );
  }

  render() {
    // Adding min-height to prevent empty cells from collapsing and becoming unclickable.
    return (
      <div onClick={this.handleClick} ref={this.onContainerRef} style={{ minHeight: '14px' }}>
        {this.renderField()}
      </div>
    );
  }
}
