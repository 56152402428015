import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { selectFullAccessPermission } from 'data/user';


class RestrictedSection extends PureComponent {
  componentWillMount() {
    const { fullAccess, history } = this.props;

    if (!fullAccess) history.push('/');
  }

  render() {
    return null;
  }
}

// Extend if needed to support permission prop; currently just verifying full (non-lite) access.
RestrictedSection.propTypes = {
  permission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
};

export default withRouter(connect(state => ({ fullAccess: selectFullAccessPermission(state) }))(RestrictedSection));
