import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import classNames from 'classnames';

import SVG from 'components/base/SVG';

import css from './style.scss';


class Loader extends Component {
  constructor(props) {
    super(props);
    this.renderLoading = this.renderLoading.bind(this);
  }

  renderLoading() {
    if (this.props.loaderDirection === 'vertical') {
      return (<div className={css.text}>
        <span>Loading</span>
      </div>);
    }

    return null;
  }

  render() {
    const loaderClass = classNames(css.loader, css[this.props.loaderSize], {
      [css.minimalistic]: this.props.loaderDirection === 'minHorizontal',
      [css.hover]: this.props.isHover,
    });

    return (
      <div className={loaderClass}>
        <div className={css.wrapper}>
          <div className={css.spinner}>
            <SVG icon="spinner" />
          </div>
        </div>
      </div>
    );
  }
}


Loader.propTypes = {
  loaderSize: PropTypes.string,
  loaderDirection: PropTypes.string,
};

Loader.defaultProps = {
  loaderSize: 'large',
  loaderDirection: 'vertical',
};


export default pure(Loader);
