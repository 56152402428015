import React from 'react';
import { connect } from 'react-redux';

import { getPopupRegistration, openPopup, closePopup } from 'app/PopupHolder';
import { createConfirmable } from 'app/components/Confirm';
import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import FormControlWrapper from 'components/base/FormControlWraper';

import css from './style.scss';

const CreateGroup = ({ type = 'Group', createGroup, closePopup, confirmable }) => {
  const handleSubmit = (ev) => {
    ev.preventDefault();
    const name = ev.target.name.value.trim();
    if (name === '') {
      confirmable({ onCancel: null, question: 'Please enter a name.' })();
    } else {
      createGroup(name, closePopup);
    }
  };

  return (
    <Modal isOpen uniqId="modalCreateGroup" padding="25px 10px" caption={`Create ${type}`} width="350px" onClose={closePopup}>
      <form onSubmit={handleSubmit} className={css.body}>
        <FormControlWrapper label={`${type} Name`} hasMarginBottom>
          <input name="name" type="text" />
        </FormControlWrapper>
        <div className={css.buttonContainer}>
          <Button kind={Button.kind.ghost} onClick={closePopup}>Cancel</Button>
          <Button kind={Button.kind.ghost} type="submit">Create</Button>
        </div>
      </form>
    </Modal>
  );
};
CreateGroup.defaultProps = { type: 'Group' };

const CreateGroupPopup = createConfirmable(connect()(CreateGroup));

const registrationId = getPopupRegistration(CreateGroupPopup);
CreateGroupPopup.open = props => openPopup(registrationId, props);
CreateGroupPopup.close = props => closePopup(registrationId, props);

export default CreateGroupPopup;
