import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-maskedinput';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';

import { getPrepaidCredit, selectCardCodeRequired } from 'data/user';
import { selectProfile } from 'data/user/selectors';
import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import Image from 'components/base/Image';
import numberToPrice from 'utils/currency/numberToPrice';
import displayNewLines from 'utils/displayNewLines';
import { getPopupRegistration, closePopup, openPopup, Priority } from 'app/PopupHolder';
import { formatGraphicUrl } from 'utils/URL';
import UpgradeAccount from 'app/components/UpgradeAccount';
import SelectPaymentMethod from 'app/components/SelectPaymentMethod';
import { CVV_PAYMENT_THRESHOLD } from 'data/user/constants';
import { validate, verify, verifyRequired, isValidCardCode } from 'utils/validation';
import { inferCardType } from 'utils/payment';

import css from './style.scss';

const Checkout = (props) => {
  const [state, setState] = useState({
    cardCode: '',
    paymentMethod: props.profile.paymentMethods.find(pm => pm.id === props.profile.paymentMethodId) || {},
  });

  const ref = {};
  const { loading, cardCodeRequired, profile, order } = props;
  const { lines, frontGraphic, recipientTotal, message, success } = order;
  const { overdueAccount, remainingPrepaidCredit, paymentMethods } = profile;

  const handleLinkClick = () => {
    props.closeAll();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleUpgradeClick = () => {
    props.openUpgradeAccount();
  };

  const handleSelectPaymentMethod = (paymentMethod) => {
    setState(prevState => ({ ...prevState, paymentMethod }));
  };

  const handleSelectPaymentMethodClick = () => {
    props.openSelectPaymentMethod({ onSelect: handleSelectPaymentMethod, id: state.paymentMethod.id });
  };

  const handleRef = (element) => {
    if (element) {
      const el = element.tagName ? element : findDOMNode(element);
      ref[el.name] = el;
    }
  };

  const handleConfirm = () => {
    const { getPrepaidCredit, onConfirm } = props;
    const { cardCode, paymentMethod } = state;
    const { codePosition, codeLength } = inferCardType(paymentMethod.cardNumber);
    const { creditAmount, paymentAmount } = getAmounts();

    const r = ref;

    if (!overdueAccount) {
      getPrepaidCredit(() => {
        const amounts = getAmounts();
        setState(amounts);

        validate(() => {
          verify(null, amounts.paymentAmount === paymentAmount && amounts.creditAmount === creditAmount, 'Your remaining credit has changes. Please review the payment information and submit the order again.');
          verify(null, !paymentAmount || !!paymentMethod.id, 'Please select a payment method.');

          if (r.cardCode) {
            verifyRequired(r.cardCode, cardCode, `Please enter the ${codeLength}-Digit Credit Card CVV number on the ${codePosition} of your card.`);
            verify(r.cardCode, isValidCardCode(cardCode, paymentMethod.cardNumber), 'Credit Card CVV is invalid.');
          }

          onConfirm(paymentAmount ? paymentMethod.id : null, cardCode, creditAmount, paymentAmount);
        });
      });
    }
  };

  const getAmounts = () => {
    const { remainingPrepaidCredit } = profile;
    const total = lines[lines.length - 1].total;
    const creditAmount = Math.min(total, remainingPrepaidCredit);
    const paymentAmount = total - creditAmount;
    return { total, creditAmount, paymentAmount };
  };

  const streetAddress = `${paymentMethod.billingStreetAddress}\n${(paymentMethod.billingStreetAddress2 || '') === '' ? '' : `${paymentMethod.billingStreetAddress2}\n`}${paymentMethod.billingCity}, ${paymentMethod.billingState} ${paymentMethod.billingZip}\n${paymentMethod.billingCountry}`;
  const btn = { size: Button.size.large, isLoading: loading };
  const businessAddressError = message === 'ERROR_BUSINESS_ADDRESS';
  const { codeMask, codeLength } = inferCardType(state.paymentMethod.cardNumber);
  const { creditAmount, paymentAmount } = getAmounts();

  return (
    <Modal isOpen uniqId="modalPayment" caption="Finalize Order" width="400px" padding="35px 30px">
      <div className={css.order}>
        <div className={css.flex}>
          <div className={css.title}>Order Details</div>
        </div>
        {!frontGraphic ? null : (
          <div className={css.preview}>
            <Image src={formatGraphicUrl(frontGraphic)} alt="" />
          </div>
        )}
        <div>
          {lines.map(({ description, total, quantity }) => (
            <div key={description}>
              <div className={css.postcards}>
                <span className={css.key}>{description}</span>
                <span className={css.value}>{numberToPrice(total)}</span>
              </div>
              <div className={css.amount}>quantity: {quantity}</div>
            </div>
          ))}
        </div>
      </div>
      {!recipientTotal ? null : (
        <div>
          {overdueAccount ? (
            <div className={css.overdue}>
              Your subscription is currently past due and this feature is disabled. If you would like to renew your subscription and gain access to the Skip Tracing feature, <span onClick={handleUpgradeClick}>Click Here</span>.
            </div>
          ) : (
            <div className={css.billing}>
              {!creditAmount ? null : (
                <div className={css.credit}>You have {numberToPrice(remainingPrepaidCredit)} in pre-paid credits remaining. {numberToPrice(creditAmount)} will be deducted from your prepaid credits{!paymentAmount ? '' : ` and ${numberToPrice(paymentAmount)} will be charged to your card`}.</div>
              )}
              {!paymentAmount ? null : (
                <div>
                  <div className={css.billingTitle}>
                    <div className={css.smallTitle}>Billing Details</div>
                    {paymentMethods.length < 2 ? null : <div className={css.paymentMethod} onClick={handleSelectPaymentMethodClick}>Change Payment Method</div>}
                  </div>
                  {!state.paymentMethod.id ? <div>Please select a payment method.</div> : (
                    <div className={css.billingInfo}>
                      <div className={css.billingAddress}>
                        <div className={css.billingName}>{state.paymentMethod.cardName}</div>
                        <div>{displayNewLines(streetAddress)}</div>
                      </div>
                      <div className={css.billingNum}>
                        <div>{state.paymentMethod.cardNumber}</div>
                        <div>Expires {`${state.paymentMethod.cardExpMonth < 10 ? `0${state.paymentMethod.cardExpMonth}` : state.paymentMethod.cardExpMonth}/${state.paymentMethod.cardExpYear}`}</div>
                        {!cardCodeRequired && (!lines.length || lines[lines.length - 1].total < CVV_PAYMENT_THRESHOLD) ? null : (
                          <div className={css.cardCode}>
                            <label htmlFor="cardCode">{codeLength}-Digit CVV:</label>
                            <MaskedInput ref={handleRef} onChange={handleChange} className={css.input} mask={codeMask} name="cardCode" value={state.cardCode} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {!message || businessAddressError ? null : <div className={classNames(css.message, { [css.success]: success })}>{message}</div>}
      {!businessAddressError ? null : <div className={css.message}>There is no valid business address and / or name on file. A valid business address &amp; business name are required for the return address printed on the postcards. <Link to="/account" onClick={handleLinkClick}>CLICK HERE</Link> to update the Business information found in the &quot;My Account&quot; section of your application. Once updated, you may return to your postcard order and complete your transaction.</div>}
      <div className={css.buttons}>
        <Button {...btn} kind={Button.kind.grayGhost} onClick={closePopup}>{message ? 'Close' : 'Cancel'}</Button>
        {message && paymentMethods.length < 2 ? null : <Button {...btn} kind={Button.kind.blue} onClick={handleConfirm} disabled={!recipientTotal || overdueAccount}>Submit Order</Button>}
      </div>
    </Modal>
  );
};

const CheckoutPopup = connect((state, props) => ({
  profile: selectProfile(state).toJS(),
  loading: props.selectLoading(state),
  // campaign: props.selectCampaign(state).toJS(),
  cardCodeRequired: selectCardCodeRequired(state),
}), {
  openUpgradeAccount: UpgradeAccount.open,
  openSelectPaymentMethod: SelectPaymentMethod.open,
  getPrepaidCredit,
})(Checkout);

const registrationId = getPopupRegistration(CheckoutPopup);
CheckoutPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
CheckoutPopup.close = () => closePopup({ popup: registrationId });

export default CheckoutPopup;
