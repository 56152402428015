/** @flow */
import { fromJS, Map, List } from 'immutable';

import * as ActionType from './actions';
import getErrorMessage from './getErrorMessage';


const defaultState = fromJS({
  lastId: 0,
  queue: [],
});


export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ActionType.ADD_API_ERROR:
      return state
        .update('lastId', (id) => ((id) + 1))
        .update('queue', (errors) => (errors)
          .push(fromJS({
            id: action.id || state.get('lastId'),
            message: getErrorMessage(action),
            action,
          })),
        );

    case ActionType.REMOVE_API_ERROR:
      return state.update('queue', (errors) => errors.filter(error => error.get('id') !== action.id));

    case ActionType.REMOVE_ALL_ERRORS:
      return state.set('queue', defaultState.get('queue'));

    default:
      return state;
  }
}
