import React, { Component } from 'react';
import moment from 'moment';
import InputDate from 'components/base/InputDate';


class GridDateComponent extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);

    this.state = { date: null };
  }

  getDate() {
    return this.state.date;
  }

  setDate(date) {
    this.setState({ date });
  }

  handleChange({ value }) {
    const date = value ? value.toDate() : null;

    this.setState({ date }, this.props.onDateChanged(date));
  }

  render() {
    const { date } = this.state;

    return <InputDate onChange={this.handleChange} selected={date ? moment(this.state.date) : null} dateFormat="MM/dd/yyyy" />;
  }
}

export default GridDateComponent;
