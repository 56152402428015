import { uncapitalize } from "utils/string";

/** @flow */
export const ActivityTypes = {
  ONLINE_AD: "onlineAd",
  POSTCARD: "postcard",
  EMAIL: "email"
};

export const MarketingCampaignStatuses = {
  NEW: "NEW",
  PROCESSING: "PROCESSING",
  PAUSED: "PAUSED",
  COMPLETE: "COMPLETE"
};

export const CampaignTypes = {
  VOICEMAIL: {
    name: "Voicemail",
    title: "Voicemail",
    enabled: true,
    setupVerb: "setup",
    icon: "iconPhoneWhite",
    description: "", // 'Ringless Voicemail Drops allow you to send personalized voicemail messages without causing the phone to ring.',
    stats: [
      { label: "Recipients", field: "recipientTotal" },
      { label: "Delivered", field: "deliveredQuantity" }
    ]
  },
  POSTCARD: {
    name: "Postcard",
    title: "Postcard",
    enabled: true,
    setupVerb: "created a",
    icon: "iconIdCardWhite",
    description: "",
    stats: [
      { label: "Recipients", field: "recipientTotal" },
      { label: "Sent", field: "deliveredQuantity" }
    ]
  },
  EMAIL: {
    name: "Emailer",
    title: "Email Marketing",
    enabled: true,
    setupVerb: "created an",
    icon: "iconMarkMail",
    description: "",
    stats: [
      { label: "Sent", field: "deliveredQuantity" },
      { label: "Click-Throughs", field: "clickThroughQuantity" }
    ]
  },
  AD: {
    name: "Online Ad",
    title: "Online Ads",
    enabled: false,
    setupVerb: "created an",
    icon: "iconWeb",
    stats: [
      { label: "Click-Throughs", field: "clickThroughQuantity" },
      { label: "Impressions", field: "deliveredQuantity" }
    ]
  }
};

export const placeholderFields = [
  { name: "My Business First Name", source: "profile" },
  { name: "My Business Last Name", source: "profile" },
  { name: "My Business First/Last Name", source: "profile" },
  { name: "My Business Name", source: "profile" },
  { name: "My Business Phone", source: "profile" },
  { name: "My Business Website", source: "profile" },
  { name: "My Business Email", source: "profile" },
  { name: "My Business Street", source: "profile" },
  { name: "My Business City", source: "profile" },
  { name: "My Business State", source: "profile" },
  { name: "My Business Zip", source: "profile" },
  { name: "My Business City/State/Zip", source: "profile" },
  { name: "Owner First Name", source: "recipient" },
  { name: "Owner Last Name", source: "recipient" },
  { name: "Owner Full Name", source: "recipient" },
  { name: "Property Street", source: "recipient" },
  { name: "Property City", source: "recipient" },
  { name: "Property State", source: "recipient" },
  { name: "Property Zip", source: "recipient" },
  { name: "Property City/State/Zip", source: "recipient" }
].map(v => ({
  ...v,
  // replace(/^Full /, '').
  field: uncapitalize(
    v.name
      .replace(/^((My)|(Owner)|(Property)) /, "")
      .replace(/[/,\s]/g, "")
      .replace(/Website$/, "Url")
      .replace(/FirstLast/, "Full")
      .replace(/Street$/, "StreetAddress")
  ),
  expression: `#${v.name.replace(/\s/g, "")}#`
}));

export const placeholderOptions = [
  { label: "Insert Field...", value: "" }
].concat(placeholderFields.map(v => ({ label: v.name, value: v.expression })));

Object.keys(CampaignTypes).forEach(key => {
  const type = CampaignTypes[key];
  CampaignTypes[key] = {
    ...type,
    code: key,
    route: key.toLowerCase()
  };
});

export const CampaignTypeList = [
  CampaignTypes.POSTCARD,
  CampaignTypes.EMAIL,
  CampaignTypes.AD
];

export const MarketingCampaignStatusNames = {
  NEW: "Unpublished",
  PROCESSING: "Processing",
  PAUSED: "Paused",
  COMPLETE: "Complete"
};

export const VoicemailRecordingTypes = {
  FILE: { code: "FILE", label: "Upload Audio File" },
  PIN: { code: "PIN", label: "Create New Recording" }
};

export const ElementTypes = {
  BACKGROUND_IMAGE: "BACKGROUND_IMAGE",
  BANNER: "BANNER",
  LOGO: "LOGO",
  LOGO2: "LOGO2",
  TITLE_HTML: "TITLE_HTML",
  BODY_HTML: "BODY_HTML",
  IMAGE: "IMAGE",
  HTML: "HTML"
};

export const defaultGraphicElement = {
  type: "",
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  brightness: 1,
  opacity: 1,
  backgroundColor: null
};

export default ActivityTypes;
