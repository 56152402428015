import React, { Component } from 'react';
import { connect } from 'react-redux';

import SVG from 'components/base/SVG';

import PropertyGallery from '../Gallery/Gallery';
import css from './style.scss';


class GalleryCellRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(ev) {
    const { openPropertyGallery, context, data: { id, propertyId } } = this.props;

    ev.stopPropagation();

    if (context && context.hackHandleIgnoreNextRowClick) context.hackHandleIgnoreNextRowClick(); // Above isn't being honored.

    openPropertyGallery({ id: propertyId || id });
  }

  render() {
    const { value } = this.props;
    // remove no_photo with house-img-not-available
    return !value || value.includes('photo_coming_soon') || value.includes('house-img-not-available') ? null : <SVG icon="iconCamera" className={css.camera} onClick={this.handleClick} />;
  }
}

export default connect(null, { openPropertyGallery: PropertyGallery.open })(GalleryCellRenderer);
