/** @flow */
import moment from 'moment';


const now = Date.now();
const date = new Date(now);
const saleDateMin = moment(date.setFullYear(date.getFullYear() - 1));


export default {
  VALIDATION: {
    DETAIL_FIELD: 'validationDetail',
    ERRORS_FIELD: 'validationErrors',
    IS_VALID_FIELD: '__isValid',
  },

  BEDROOMS: [
    { value: '0', label: 'Studio+' },
    { value: '1', label: '1+' },
    { value: '2', label: '2+' },
    { value: '3', label: '3+' },
    { value: '4', label: '4+' },
  ],

  BATHROOMS: [
    { value: '0', label: 'Any' },
    { value: '1', label: '1+' },
    { value: '2', label: '2+' },
    { value: '3', label: '3+' },
    { value: '4', label: '4+' },
    { value: '5', label: '5+' },
  ],

  PROPERTY_DETAILS_SEARCH_PARAMS: {
    saleDateMin,
    saleDateMax: moment(now),
    squareFeetMax: '',
    squareFeetMin: '',
    lotSquareFeetMax: '',
    lotSquareFeetMin: '',
    distanceFromSubjectMax: 0.5,
    shapeDefinition: '',
    bedroomsMin: '0',
    bathroomsMin: '0',
  },
};
