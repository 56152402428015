import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-maskedinput';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';

import { getPrepaidCredit, selectCardCodeRequired } from 'data/user';
import { selectProfile } from 'data/user/selectors';
import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import Image from 'components/base/Image';
import numberToPrice from 'utils/currency/numberToPrice';
import displayNewLines from 'utils/displayNewLines';
import { getPopupRegistration, closePopup, openPopup, Priority } from 'app/PopupHolder';
import { formatGraphicUrl } from 'utils/URL';
import UpgradeAccount from 'app/components/UpgradeAccount';
import SelectPaymentMethod from 'app/components/SelectPaymentMethod';
import { CVV_PAYMENT_THRESHOLD } from 'data/user/constants';
import { validate, verify, verifyRequired, isValidCardCode } from 'utils/validation';
import { inferCardType } from 'utils/payment';

import css from './style.scss';


class CampaignPayment extends PureComponent {
  constructor(props) {
    super(props);

    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpgradeClick = this.handleUpgradeClick.bind(this);
    this.handleSelectPaymentMethodClick = this.handleSelectPaymentMethodClick.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);

    const { paymentMethodId, paymentMethods } = this.props.profile;

    this.ref = {};
    this.state = {
      cardCode: '',
      paymentMethod: paymentMethods.find(pm => pm.id === paymentMethodId) || {},
    };
  }

  componentDidMount() {
    this.props.getPrepaidCredit();
  }

  getAmounts() {
    const { profile: { remainingPrepaidCredit }, campaign: { lines } } = this.props;

    const total = lines[lines.length - 1].total;
    const creditAmount = Math.min(total, remainingPrepaidCredit);
    const paymentAmount = total - creditAmount;

    return { total, creditAmount, paymentAmount };
  }

  handleLinkClick() {
    this.props.closeAll();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleUpgradeClick() {
    this.props.openUpgradeAccount();
  }

  handleSelectPaymentMethod(paymentMethod) {
    this.setState({ paymentMethod });
  }

  handleSelectPaymentMethodClick() {
    this.props.openSelectPaymentMethod({ onSelect: this.handleSelectPaymentMethod, id: this.state.paymentMethod.id });
  }

  handleRef(element) {
    if (element) {
      const el = element.tagName ? element : findDOMNode(element);
      this.ref[el.name] = el;
    }
  }

  handleConfirm() {
    const { profile: { overdueAccount }, onConfirm, getPrepaidCredit } = this.props;
    const { cardCode, paymentMethod: { id: paymentMethodId, cardNumber } } = this.state;
    const { codePosition, codeLength } = inferCardType(cardNumber);
    const { creditAmount, paymentAmount } = this.getAmounts();

    const r = this.ref;

    if (!overdueAccount) {
      getPrepaidCredit(() => {
        const amounts = this.getAmounts();

        this.setState(amounts);

        validate(() => {
          verify(null, amounts.paymentAmount === paymentAmount && amounts.creditAmount === creditAmount, 'Your remaining credit has changes. Please review the payment information and submit the order again.');

          verify(null, !paymentAmount || !!paymentMethodId, 'Please select a payment method.');

          if (r.cardCode) {
            verifyRequired(r.cardCode, cardCode, `Please enter the ${codeLength}-Digit Credit Card CVV number on the ${codePosition} of your card.`);
            verify(r.cardCode, isValidCardCode(cardCode, cardNumber), 'Credit Card CVV is invalid.');
          }

          onConfirm(paymentAmount ? paymentMethodId : null, cardCode, creditAmount, paymentAmount);
        });
      });
    }
  }

  render() {
    const { loading, cardCodeRequired, profile: { overdueAccount, remainingPrepaidCredit, paymentMethods = [] }, closePopup, campaign: { lines, frontGraphic, recipientTotal, message, success } } = this.props;
    const { cardCode, paymentMethod: { id: paymentMethodId, cardName, billingStreetAddress, billingStreetAddress2, billingCity, billingState, billingZip, billingCountry, cardNumber, cardExpMonth, cardExpYear } } = this.state;
    const streetAddress = `${billingStreetAddress}\n${(billingStreetAddress2 || '') === '' ? '' : `${billingStreetAddress2}\n`}${billingCity}, ${billingState} ${billingZip}\n${billingCountry}`;
    const btn = { size: Button.size.large, isLoading: loading };
    const businessAddressError = message === 'ERROR_BUSINESS_ADDRESS';
    const { codeMask, codeLength } = inferCardType(cardNumber);
    const { creditAmount, paymentAmount } = this.getAmounts();

    return (
      <Modal isOpen uniqId="modalPayment" caption="Finalize Order" width="400px" padding="35px 30px">
        <div className={css.order}>
          <div className={css.flex}>
            <div className={css.title}>Order Details</div>
          </div>
          {!frontGraphic ? null : (
            <div className={css.preview}>
              <Image src={formatGraphicUrl(frontGraphic)} alt="" />
            </div>
          )}
          <div>
            {lines.map(({ description, total, quantity }) => (
              <div key={description}>
                <div className={css.postcards}>
                  <span className={css.key}>{description}</span>
                  <span className={css.value}>{numberToPrice(total)}</span>
                </div>
                <div className={css.amount}>quantity: {quantity}</div>
              </div>
            ))}
          </div>
        </div>
        {!recipientTotal ? null : (
          <div>
            {overdueAccount ? (
              <div className={css.overdue}>
                Your subscription is currently past due and this feature is disabled. If you would like to renew your subscription and gain access to the Skip Tracing feature, <span onClick={this.handleUpgradeClick}>Click Here</span>.
              </div>
            ) : (
              <div className={css.billing}>
                {!creditAmount ? null : (
                  <div className={css.credit}>You have {numberToPrice(remainingPrepaidCredit)} in pre-paid credits remaining. {numberToPrice(creditAmount)} will be deducted from your prepaid credits{!paymentAmount ? '' : ` and ${numberToPrice(paymentAmount)} will be charged to your card`}.</div>
                )}
                {!paymentAmount ? null : (
                  <div>
                    <div className={css.billingTitle}>
                      <div className={css.smallTitle}>Billing Details</div>
                      {paymentMethods.length < 2 ? null : <div className={css.paymentMethod} onClick={this.handleSelectPaymentMethodClick}>Change Payment Method</div>}
                    </div>
                    {!paymentMethodId ? <div>Please select a payment method.</div> : (
                      <div className={css.billingInfo}>
                        <div className={css.billingAddress}>
                          <div className={css.billingName}>{cardName}</div>
                          <div>{displayNewLines(streetAddress)}</div>
                        </div>
                        <div className={css.billingNum}>
                          <div>{cardNumber}</div>
                          <div>Expires {`${cardExpMonth < 10 ? `0${cardExpMonth}` : cardExpMonth}/${cardExpYear}`}</div>
                          {!cardCodeRequired && (!lines.length || lines[lines.length - 1].total < CVV_PAYMENT_THRESHOLD) ? null : (
                            <div className={css.cardCode}>
                              <label htmlFor="cardCode">{codeLength}-Digit CVV:</label>
                              <MaskedInput ref={this.handleRef} onChange={this.handleChange} className={css.input} mask={codeMask} name="cardCode" value={cardCode} />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {!message || businessAddressError ? null : <div className={classNames(css.message, { [css.success]: success })}>{message}</div>}
        {!businessAddressError ? null : <div className={css.message}>There is no valid business address and / or name on file. A valid business address &amp; business name are required for the return address printed on the postcards. <Link to="/account" onClick={this.handleLinkClick}>CLICK HERE</Link> to update the Business information found in the &quot;My Account&quot; section of your application. Once updated, you may return to your postcard order and complete your transaction.</div>}
        <div className={css.buttons}>
          <Button {...btn} kind={Button.kind.grayGhost} onClick={closePopup}>{message ? 'Close' : 'Cancel'}</Button>
          {message && paymentMethods.length < 2 ? null : <Button {...btn} kind={Button.kind.blue} onClick={this.handleConfirm} disabled={!recipientTotal || overdueAccount}>Submit Order</Button>}
        </div>
      </Modal>
    );
  }
}

const CampaignPaymentPopup = connect((state, props) => ({
  profile: selectProfile(state).toJS(),
  loading: props.selectLoading(state),
  campaign: props.selectCampaign(state).toJS(),
  cardCodeRequired: selectCardCodeRequired(state),
}), {
  openUpgradeAccount: UpgradeAccount.open,
  openSelectPaymentMethod: SelectPaymentMethod.open,
  getPrepaidCredit,
})(CampaignPayment);

const registrationId = getPopupRegistration(CampaignPaymentPopup);
CampaignPaymentPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
CampaignPaymentPopup.close = () => closePopup({ popup: registrationId });

export default CampaignPaymentPopup;
