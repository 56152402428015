/** @flow */
/*
This is temp component. Does not write code like this!
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { deferExecutor } from 'app/DeferredOnLocation';


const routesSelector = props => props.routes;
const generateRoutesMap = (routes) => {
  if (process.env.NODE_ENV === 'production') return null;
  const result = [];
  const mapRoutes = (routes, prefix = []) => {
    routes.forEach((route) => {
      if (route.indexRoute) {
        result.push({
          name: [...prefix, route.path].join('/').replace(/\/\//g, '/'),
          url: [...prefix, route.path].join('/').replace(/\/\//g, '/'),
        });
      }
      if (route.component) {
        result.push({
          name: `${[...prefix, route.path].join('/')}_Comp`.replace(/\/\//g, '/'),
          url: [...prefix, route.path].join('/').replace(/\/\//g, '/'),
        });
      }
      if (route.childRoutes) {
        mapRoutes(route.childRoutes, [...prefix, route.path]);
      }
    });
  };
  mapRoutes(routes);
  return result.map(v => ({ url: v.url.replace(/\/\//g, '/'), name: v.name.replace(/\/\//g, '/') }));
};

const routesMapSelector = createSelector(
  routesSelector,
  generateRoutesMap,
);

function mapStateToProps(state, props) {
  return {
    routes: routesMapSelector(props),
  };
}

const SiteMap = (props) => {
  if (process.env.NODE_ENV === 'production' || !props.routes) return null;
  const routesAdded = new Set();
  const routes = props.routes
    .filter((r) => {
      if (routesAdded.has(r.url)) return false;
      routesAdded.add(r.url);
      return true;
    })
    .map(({ name, url }) => <p key={`${name} ${url}`}>{name} - <Link to={url}>go to</Link></p>);

  return (
    <div style={{ marginLeft: 12 }}>
      {routes}
    </div>
  );

}
export default connect(mapStateToProps)(deferExecutor(SiteMap));

/*
 This is temp component. Does not write code like this!
 */
