import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBrandName, isPropStream, isReiAutomator } from 'utils/brand';
import classNames from 'classnames';

import { selectProfile, selectLoading, upgradeAccount } from 'data/user';
import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import Radio from 'components/base/Radio';
import numberToPrice from 'utils/currency/numberToPrice';
import { getPopupRegistration, closePopup, openPopup, Priority } from 'app/PopupHolder';
import SelectPaymentMethod from 'app/components/SelectPaymentMethod';
import BulletPoint from 'components/BulletPoint';
import formatNumber from 'utils/number/format';
import Dropdown from 'components/base/Dropdown';
import Confirm from 'app/components/Confirm';
import AddServiceConfirmation from 'app/components/AddServiceConfirmation';

import css from './style.scss';


class ListManagementInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleChange = this.handleChange.bind(this);

    const { planMrcs, profile: { paymentMethods } } = props;

    const monthly = !!planMrcs.find(p => !p.annual);

    this.paymentMethodOptions = (paymentMethods || []).map(({ id, description }) => ({ label: description, value: id }));
    this.termOptions = planMrcs.filter((p, i, ar) => i === ar.findIndex(l => l.annual === p.annual)).map(p => ({ value: p.annual ? 'annual' : 'monthly', label: p.annual ? 'Annually' : 'Monthly' }));

    this.ref = {};
    this.state = {
      success: null,
      message: null,
      term: monthly ? 'monthly' : 'annual',
      planMrcId: planMrcs.find(p => p.annual === !monthly).id,
      paymentMethodId: (paymentMethods.find(p => p.billingPaymentMethod) || {}).id || null,
      // plan: planMrcs.find(p => p.id === listManagementPlanMrcId) || null,
    };
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: name === 'term' ? value : Number(value) });
  }

  handleAddClick() {
    const { openAddServiceConfirmation, upgradeAccount, confirm, closePopup, history, planMrcs } = this.props;
    const { paymentMethodId, planMrcId } = this.state;
    const { id, amount } = planMrcs.find(p => p.id === planMrcId);

    openAddServiceConfirmation({
      planMrcId: id,
      paymentMethodId,
      amount,
      onPurchase: (amount, cardCode, password, onSuccess) => {
        upgradeAccount({ cardCode, paymentMethodId }, null, id, null, amount, password, ({ response: { success, message } }) => {
          onSuccess();
          this.setState({ success, message });

          if (success) {
            closePopup();
            confirm({
              question: 'List Automator has been added successfully.',
              cancelLabel: null,
              okLabel: 'Start using List Automator',
              onOk: () => {
                history.push('/property/group');
              },
            });
          }
        });
      },
    });
  }

  render() {
    const { closePopup, planMrcs, profile: { listManagementEnabled } } = this.props;
    const { paymentMethodId, success, message, planMrcId, term } = this.state;
    const plan = planMrcs.find(p => p.id === planMrcId) || { trialAvailable: false };
    const reiAutomator = isReiAutomator();

    const brand = getBrandName();

    return (
      <Modal isOpen uniqId="modalListManagementInfo" width="1200px" padding="30px 50px">
        <div className={classNames(css.listManagement, { [css.reiautomator]: reiAutomator })}>
          <div className={css.header}>
            {planMrcs.length && plan.trialAvailable ? <div><span>14 Day Free Trial</span> List Automator</div> : <div><span>New</span> {brand} List Automator</div>}
          </div>
          <div className={css.body}>
            <div className={css.left}>
              <div className={css.title}>Data-Driven Automated List Management</div>
              <div className={css.subTitle}>Frustrated marketing to properties that no longer match your preferred criteria?</div>
              <div>A property owner&apos;s situation can change quickly, altering thier motivation to sell. Inevitably, properties in your lists are sold, get listed on the MLS, resolve their foreclosure issues, pay off their liens, etc., which significantly reduces your chances of acquisition.</div>
              <br />
              <div>List Automator, {brand}&apos;s new Data-Driven Automated List Management technology is a first of its kind tool. It utilizes all of {brand}&apos;s Big Data and automation capability to increase your odds of acquiring a property.</div>
              <br />
              <BulletPoint>Import lists from any other service to enhance, refresh, monitor, and to use for skip tracing and marketing.</BulletPoint>
              <BulletPoint>Identify, categorize, and display the situation of each property in your lists.</BulletPoint>
              <BulletPoint>Flags records that appear in multiple lists.</BulletPoint>
              <BulletPoint>Make changes on the fly to your list filters.</BulletPoint>
              <BulletPoint>Identify the number of times you&apos;ve sent a postcard and voice message to each record and link to the campaign for re-marketing.</BulletPoint>
              <BulletPoint>Resend postcards and voicemails to an entire list or send them to new records only.</BulletPoint>
              <BulletPoint>Identify which records have been skip traced and isolate the ones that haven&apos;t.</BulletPoint>
              <BulletPoint>Customize your view and export up to 108 additional database fields.</BulletPoint>
              {reiAutomator ? null : [
                <BulletPoint>Skip Tracing pricing, as low as 12 cents per record.</BulletPoint>,
                <BulletPoint>Postcard pricing, as low as 40 cents per postcard, postage included.</BulletPoint>
              ]}
              <BulletPoint>Many more features currently in development - to be released soon.</BulletPoint>
              <br />
              <SolidButton onClick={this.handleLearnMoreClick}>Learn More</SolidButton>
            </div>
            <div className={css.right}>
              <div className={css.sheet}>
                {reiAutomator ? null : <div className={css.title}>{isPropStream() ? 'PropStream ' : ''}List Automator</div>}
                <div className={css.content}>
                  <div className={css.pricing}>
                    <div className={css.rates}>
                      {planMrcs.filter(p => p.annual === (term === 'annual')).map(r => (
                        <div className={css.rate} key={r.quantity}>
                          <div>Monitored Listings</div>
                          <div>{formatNumber(r.quantity)}</div>
                          <div>{numberToPrice(r.amount)}</div>
                          <div>
                            <Radio name="planMrcId" checked={r.id === planMrcId} onChange={this.handleChange} value={r.id} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={css.form}>
                    <div className={css.field}>
                      <label htmlFor="term">Billed</label>
                      <Dropdown name="term" options={this.termOptions} value={term} onChange={this.handleChange} />
                    </div>
                    <div className={css.field}>
                      <label htmlFor="paymentMethodId">Payment Method</label>
                      <Dropdown name="paymentMethodId" options={this.paymentMethodOptions} value={paymentMethodId} onChange={this.handleChange} />
                    </div>
                    {success == null || success ? null : <div className={classNames(css.message, { [css.success]: success })}>{success ? 'Service added successfully!' : message}</div>}
                    <div className={css.buttons}>
                      <Button onClick={closePopup}>Close</Button>
                      <SolidButton onClick={this.handleAddClick}>{(plan.trialAvailable && 'Activate Trial') || (listManagementEnabled ? 'Upgrade' : 'Activate')}</SolidButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.footer}>
            {/* Billing Terms and Conditions Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
          </div>
        </div>
      </Modal>
    );
  }
}

const ListManagementInfoPopup = withRouter(connect((state) => {
  const profile = selectProfile(state).toJS();

  return {
    profile,
    loading: selectLoading(state),
    planMrcs: profile.planMrcs.filter(p => p.type === 'LIST_AUTOMATOR' && !p.annual),
  };
}, {
  openSelectPaymentMethod: SelectPaymentMethod.open,
  openAddServiceConfirmation: AddServiceConfirmation.open,
  upgradeAccount,
  confirm: Confirm.open,
})(ListManagementInfo));

const registrationId = getPopupRegistration(ListManagementInfoPopup);
ListManagementInfoPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });
ListManagementInfoPopup.close = () => closePopup({ popup: registrationId });

export default ListManagementInfoPopup;
