const AnalysisExpenses = {
  ADVERTISING_ID: 200,
  PROPERTY_TAXES_ID: 201,
  JANITORIAL_SERVICE_ID: 202,
  LEGAL_ID: 203,
  LICENSES_ID: 204,
  REPAIRS_MAINTENANCE_ID: 205,
  SUPPLIES_ID: 206,
  ELECTRICITY_ID: 207,
  GAS_ID: 208,
  SEWER_WATER_ID: 209,
  TELEPHONE_ID: 210,
  OTHER_UTILITIES_ID: 211,
  TRASH_ID: 212,
  ACCOUNTING_ID: 213,
  ASSOCIATION_FEES_ID: 214,
  LANDSCAPING_ID: 215,
  FIRE_INSURANCE_ID: 216,
  FLOOD_INSURANCE_ID: 217,
  MORTGAGE_INSURANCE_ID: 218,
  LIABILITY_INSURANCE_ID: 219,
  WORKMANS_COMP_INSURANCE_ID: 220,
  PAYROLL_ID: 221,
  POOL_SPA_SERVICE_ID: 222,
  MISCELLANEOUS_ID: 223,
};

export default AnalysisExpenses;
