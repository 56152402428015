// @flow
import routes from "./app/routes";
import configureStore from "./store/configureStore";
import { Map } from "immutable";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "ric";

const store = configureStore(Map());

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        {routes}
      </Router>
    </Provider>
  );
};

const domNode = document.getElementById("root");
const root = createRoot(domNode);
root.render(<App />);
