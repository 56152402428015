import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'components/Button';
import Modal from 'components/base/Modal';
import { logout } from 'data/user';
import { formatDateShort } from 'utils/date/formatDate';
import { CANCELATION_BASE_URL } from '../../../config/constants/API';
import Image from 'components/base/Image';
import { getBrandName, getBrandCode, getBrandHelpUrl, getBrandLogoUrl, getBrandSmallLogoUrl, getBrandLogoAltUrl } from 'utils/brand';

import formatDate from 'utils/date/formatDate';
import currencyFormat from 'utils/currency/numberToPrice';
import numberFormat from 'utils/number/format';
import SVG from 'components/base/SVG';
import ReactGA from 'react-ga';

import {
  selectLoading,
  selectProfile,
  getTransactions,
  selectTransactions,
} from 'data/user';

import css from './style.scss';
import cssAccount from '../style.scss';
import CancelModal from './CancelModal';
import CancelOfferModal from './CancelOfferModal';
import CancelOfferAcceptModal from './CancelOfferAcceptModal';
import CancelAccountFeedback from './CancelAccountFeedback';
import CancelConfirmedModal from './CancelConfirmedModal';

class AccountActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      cancelFlow: 'cancelModal',
      nextBillingDate: '',
      cancelid: '',
      apiError: false,
      showError: false,
      loader: false,
    };

    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleCancelOfferAccept = this.handleCancelOfferAccept.bind(this);
    this.handleCancelContinue = this.handleCancelContinue.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancelConfirm = this.handleCancelConfirm.bind(this);
    this.cancelationApiCall = this.cancelationApiCall.bind(this);
    this.outSideClick = this.outSideClick.bind(this);
  }

  componentWillMount() {
    const { loaded, getTransactions } = this.props;
    if (!loaded) getTransactions();
  }

  handleCancelClick() {
    const { profile } = this.props;
    if (this.state.apiError) {
      this.setState({ showError: true });
    }
    if (
      (profile.get('fullAccount') === false &&
        this.props.trial_count === '0') ||
      (profile.get('fullAccount') === true && this.props.extended_count === '0')
    ) {
      this.setState({ cancelFlow: 'cancelOfferModal' });
    } else {
      this.setState({ cancelFlow: 'cancelContineueModal' });
    }
  }

  handleCancelConfirm(serviceChecked, reasonChecked, roleChecked) {
    const { profile } = this.props;
    if(profile.get('fullAccount')){
      ReactGA.event({
        category: 'Button Click',
        action: 'Click',
        label: 'Cancelation by Subscriber',
        value: 1
      }); 
    } else{
      ReactGA.event({
        category: 'Button Click',
        action: 'Click',
        label: 'Cancelation Trial User',
        value: 1
      });  
    }
    if (this.state.apiError) {
      this.setState({ showError: true });
    }
    let accountType = profile.get('fullAccount') ? 2 : 1
    let requestObj = {
      accountid: this.props.account_id,
      userid: profile.get('id'),
      name: {
        first_name: profile.get('firstName'),
        last_name: profile.get('lastName'),
      },
      canceltype: accountType,
      cancelstatus: 2,
      email: profile.get('username'),
      note: 'User Trial extended',
      serviceused: JSON.stringify(serviceChecked),
      cancelreason: JSON.stringify(reasonChecked),
      userrole: JSON.stringify(roleChecked),
      nextbillingdate: formatDateShort(profile.get('nextCycleDate')).replace(
        /[/]/g,
        '.'
      ),
    };
    this.cancelationApiCall('cancel', requestObj, 'cancel');
  }

  handleCancelOfferAccept() {
    const { profile } = this.props;
    if(profile.get('fullAccount')){
      ReactGA.event({
        category: 'Button Click',
        action: 'Click',
        label: 'Cancelation extension for subscriber',
        value: 1
      }); 
    } else{
      ReactGA.event({
        category: 'Button Click',
        action: 'Click',
        label: '7-day extension',
        value: 1
      }); 
    }
    if (this.state.apiError) {
      this.setState({ showError: true });
    }
    let accountType = profile.get('fullAccount') ? 2 : 1
    let requestObj = {
      accountid: this.props.account_id,
      userid: profile.get('id'),
      name: {
        first_name: profile.get('firstName'),
        last_name: profile.get('lastName'),
      },
      canceltype: accountType,
      email: profile.get('username'),
      note: 'User Trial extended',
      nextbillingdate: formatDateShort(profile.get('nextCycleDate')).replace(
        /[/]/g,
        '.'
      ),
    };
    if (profile.get('fullAccount') === false) {
      requestObj.cancelstatus = 13;
      this.cancelationApiCall('sevendaystrial', requestObj, 'extend');
    } else if (profile.get('fullAccount') === true) {
      requestObj.cancelstatus = 14;
      this.cancelationApiCall('thirtydays/extend/trial', requestObj, 'extend');
    }
  }
  handleCancelContinue(ev) {
    ev.preventDefault();
    this.setState({ cancelFlow: 'cancelContineueModal' });
  }
  handleClose() {
    const { profile } = this.props;
    this.setState({ isOpen: false });
    setTimeout(() => {
      this.setState({ cancelFlow: 'cancelModal' });
    }, 400);
    if (
      profile.get('fullAccount') === false &&
      this.state.cancelFlow === 'cancelConfirmedModal'
    ) {
      this.props.logout('manual');
    }
  }

  outSideClick(event) {
    const { profile } = this.props;
    const target = document.getElementById('CancelationPopup')
    const withinBoundaries = event.composedPath().includes(target)
    if (withinBoundaries) {
    } else {
      if (
        profile.get('fullAccount') === false && this.state.cancelid
      ) {
        this.props.logout('manual');
      }
    }
  }

  cancelationApiCall(path, body, type) {
    this.setState({loader: true})
    fetch(`${CANCELATION_BASE_URL}/${path}`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': window.appAuthToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        let res = data.detail[0][0];
        if (type === 'cancel') {
          this.setState({
            cancelid: res.id,
            cancelFlow: 'cancelConfirmedModal',
            apiError: false,
            showError: false,
            loader: false,
            nextBillingDate: res.next_cycle_date,
          });
        } else if (type === 'extend') {
          this.setState({
            nextBillingDate: res.next_cycle_date,
            cancelFlow: 'cancelOfferAcceptModal',
            apiError: false,
            showError: false,
            loader: false,
          });
        }
        // this.props.getAccountPendingStatus();
        this.props.getAccountStatus();
      })
      .catch((error) => {
        console.error('Error:', error);
        this.setState({ apiError: true, showError: true, loader: false, });
      });
  }
  render() {
    const { billings, purchases, profile } = this.props;
    const trialOfferbutton =
      (profile.get('fullAccount') === false && this.props.trial_count === '0') ||
      (profile.get('fullAccount') === true && this.props.extended_count === '0')
        ? true
        : false;
    let modalComponent = null;
    let BrandLogo = getBrandLogoUrl();
    let BrandCode = getBrandCode();
    let BrandName = getBrandName();
    switch (this.state.cancelFlow) {
      case 'cancelModal':
        modalComponent = (
          <CancelModal handleCancelClick={this.handleCancelClick} profile={profile} brandCode={BrandCode} brandName={BrandName} />
        );
        break;
      case 'cancelOfferModal':
        modalComponent = (
          <CancelOfferModal
            handleCancelOfferAccept={this.handleCancelOfferAccept}
            handleCancelContinue={this.handleCancelContinue}
            accountType={profile.get('fullAccount')}
            brandCode={BrandCode}
            brandName={BrandName} 
          />
        );
        break;
      case 'cancelOfferAcceptModal':
        modalComponent = (
          <CancelOfferAcceptModal
            handleClose={this.handleClose}
            accountType={profile.get('fullAccount')}
            nextBillingDate={this.state.nextBillingDate}
            profile={profile}
            brandCode={BrandCode}
            brandName={BrandName} 
          />
        );
        break;
      case 'cancelContineueModal':
        modalComponent = (
          <CancelAccountFeedback
            handleCancelConfirm={this.handleCancelConfirm}
            handleCancelClick={this.handleCancelClick}
            trialOfferbutton={trialOfferbutton}
            brandCode={BrandCode}
            brandName={BrandName} 
          />
        );
        break;
      case 'cancelConfirmedModal':
        modalComponent = (
          <CancelConfirmedModal
            handleClose={this.handleClose}
            nextCycleDate={this.state.nextBillingDate}
            verificationNumber={this.state.cancelid}
            accountType={profile.get('fullAccount')}
            profile={profile} 
            brandCode={BrandCode}
            brandName={BrandName} 
          />
        );
        break;
      default:
        modalComponent = (
          <CancelModal handleCancelClick={this.handleCancelClick} brandCode={BrandCode} brandName={BrandName}  />
        );
    }
    return (
      <div className={css.activity}>
        <div className={cssAccount.left}>
          <div className={cssAccount.header}>Account Billing</div>
          <div>
            <table className={cssAccount.grid} cellSpacing={0}>
              <tr>
                <th>Bill Date</th>
                <th>Product</th>
                <th style={{ width: '180px' }}>
                  Access 
                  <span className={cssAccount.light}>(Monthly / Annual)</span>
                </th>
                <th>Amount</th>
              </tr>
              {!billings.length ? (
                <tr>
                  <td className={cssAccount.none} colSpan={4}>
                    No Billings
                  </td>
                </tr>
              ) : (
                billings.map((l) => (
                  <tr>
                    <td>{formatDate(l.createDate)}</td>
                    <td>{l.product}</td>
                    <td>{l.term}</td>
                    <td>
                      {currencyFormat(l.amount, null, { forceDecimal: true })}
                    </td>
                  </tr>
                ))
              )}
            </table>
          </div>
        </div>
        <div className={cssAccount.right} style={{ paddingBottom: '0px' }}>
          <div className={cssAccount.header}>In-App Purchases</div>
          <div>
            <table className={cssAccount.grid} cellSpacing={0}>
              <tr>
                <th>Bill Date</th>
                <th>Product / Service</th>
                <th>Quantity</th>
                <th>Amount</th>
                <th>User</th>
              </tr>
              {!purchases.length ? (
                <tr>
                  <td className={cssAccount.none} colSpan={5}>
                    No Purchases
                  </td>
                </tr>
              ) : (
                purchases.map((l) => (
                  <tr>
                    <td>{formatDate(l.createDate)}</td>
                    <td>{l.product}</td>
                    <td>{numberFormat(l.quantity)}</td>
                    <td>
                      {currencyFormat(l.amount, null, { forceDecimal: true })}
                    </td>
                    <td>{l.username}</td>
                  </tr>
                ))
              )}
            </table>
          </div>
          <div
            style={{
              background: '#fff',
              width: '110%',
              marginLeft: '-30px',
              marginTop: '30px',
              display: 'flex',
              padding: '20px 10px 10px',
              justifyContent: 'flex-end',
            }}
          >
            {/* {this.props.cancelButtonShow === '0' && <Button
              onClick={() => {   
                ReactGA.event({
                  category: 'Button Click',
                  action: 'Click',
                  label: 'Cancelation',
                  value: 1
                });            
                this.setState({ isOpen: true, showError: false, apiError: false });
                window.addEventListener('click', this.outSideClick)
              }}
            >
              Cancel My Account
            </Button>} */}
            <Modal
              isOpen={this.state.isOpen}
              // isCloseButton
              uniqId='CancelationPopup'
              caption=''
              width='auto'
              padding='20px 40px 0px'
            >
              <div
                style={{
                  position: 'absolute',
                  right: '-10px',
                  fontWeight: 400,
                  fontSize: '24px',
                  cursor: 'pointer',
                }}
                onClick={() => this.handleClose()}
              >
                x
              </div>
              {this.state.loader ? (
                <div className={css.cLoader}>
                  <SVG icon='spinner' fill='#5897F5' width='60' />
                </div>
              ) : (
                <span>
                  {this.state.showError ? (
                    <div
                      style={{
                        width: '400px',
                        height: '350px',
                        padding: '15px 25px 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        textAlign: 'center'
                      }}
                    >
                      <img
                        src='https://signup.propstream.com/resource/logo?c=CA02937056'
                        placeholder='https://signup.propstream.com/resource/logo?c=CA02937056'
                        class='_159lC__logoSmall'
                        style={{ width: '138px', height: '76px', visibility: `${this.props.brandCode.includes('propstream')? 'visible': 'hidden'}` }}
                      />
                      <div style={{color:'#3D3D3D', fontSize:'24px', fontFamily: 'Helvetica Neue', fontWeight: 'bold', marginTop: '48px'}}>Something Went Wrong</div>
                      <p style={{color:'#3D3D3D', fontSize:'14px', fontFamily: 'Helvetica Neue', margin: '24px 0 35px', lineHeight: 1.4}}>We’re sorry, we were unable to process your cancelation request. Please contact our support team at <b>(877) 204-9040</b> or 
                      {' '}<a
                        style={{
                          paddingLeft: '2px',
                          color: '#F16722',
                          textDecoration: 'none',
                        }}
                        href='mailto:Support@PropStream.com'
                      >
                        Support@PropStream.com
                      </a> to cancel your account.</p>
                    </div>
                  ) : (
                    <span>{modalComponent}</span>
                  )}
                </span>
              )}
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    const transactions = selectTransactions(state);
    const lines = transactions ? transactions.toJS() : [];

    return {
      loading: selectLoading(state),
      billings: lines.filter((l) => !l.purchase),
      purchases: lines.filter((l) => l.purchase),
      loaded: !!transactions,
      profile: selectProfile(state),
    };
  },
  {
    logout,
    getTransactions,
  }
)(AccountActivity);
