import React from 'react';
import IntelliProgressBar from '../../../components/IntelliProgressBar';
import css from './style.scss';

const IntelligenceInfoCell = ({ name, value, icon, addClass = '', score_percent = 0, score }) => {
    
    function mapValueToScore(value) {
        // Mapping score value to progress bar %
        const valueToFillMap = {
            1.0: 100, 1.1: 99, 1.2: 98, 1.3: 96, 1.4: 94, 1.5: 92, 1.6: 90, 1.7: 88, 1.8: 86, 1.9: 84,
            2.0: 82, 2.1: 80, 2.2: 78, 2.3: 76, 2.4: 74, 2.5: 72, 2.6: 70, 2.7: 68, 2.8: 66, 2.9: 64,
            3.0: 62, 3.1: 60, 3.2: 58, 3.3: 56, 3.4: 54, 3.5: 52, 3.6: 50, 3.7: 48, 3.8: 46, 3.9: 44,
            4.0: 42, 4.1: 40, 4.2: 38, 4.3: 36, 4.4: 34, 4.5: 32, 4.6: 30, 4.7: 28, 4.8: 26, 4.9: 24,
            5.0: 22, 5.1: 20, 5.2: 18, 5.3: 16, 5.4: 14, 5.5: 12, 5.6: 10, 5.7: 8, 5.8: 4, 5.9: 2, 6.0: 0
          };

        // Return the corresponding fill percentage for the value, or 0 if out of range
        return valueToFillMap[value] || 0;
    }

    return (
        <div className={`${css.value} ${addClass}`}>
            <div className={css.IntelligenceOuterDiv}>
                <div className={css.IntelligenceInnerDiv}>
                    <div className={css.IntelligenceCellIcon}>
                        <img className={css.IntelligenceCellImg} src={icon} alt={name} />
                    </div>
                    <div className={css.name}>{name}</div>
                </div>
                <div className={css.IntelligenceInnerDiv12}>

                    <div className={`${css.name} ${score_percent > 0 ? css.additionalClass : ''}`}>
                        {value || 'N/A'}
                        {score_percent > 0 && (
                            <>
                                <div className={css.bar}></div>
                                <span className={css.percentLabel}>
                                    {`${score_percent}%`}
                                </span>
                            </>
                        )}
                    </div>
                    <IntelliProgressBar percent={mapValueToScore(score)} size={'big'} />
                </div>
            </div>

        </div>
    );
};

export default IntelligenceInfoCell;