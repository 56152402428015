import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { selectLoading, getSmsLogin, selectSmsRegistered } from 'data/campaigns';
import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';

import css from './style.scss';


class LaunchControlInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSignupClick = this.handleSignupClick.bind(this);
  }

  componentDidMount() {
    const { smsRegistered, closePopup } = this.props;

    // Automatically load LaunchControl if already registered.
    if (smsRegistered) {
      this.handleSignupClick();
      closePopup();
    }
  }

  handleSignupClick() {
    const { getSmsLogin, closePopup } = this.props;

    getSmsLogin(({ response: { redirectUrl } }) => {
      if (redirectUrl) {
        const newWindow = window.open(redirectUrl, 'sms');
        if (newWindow) newWindow.opener = null;
      }

      closePopup();
    });
  }

  render() {
    const { loading } = this.props;

    return (
      <Modal isOpen uniqId="modalLaunchControlInfo" width="1430px" height="762px" background="white url('/assets/images/launchControl-bk.png')" padding="30px 50px" isCloseButton>
        <div className={css.launchControl}>
          <div className={css.footer}>
            <SolidButton onClick={this.handleSignupClick} loading={loading}>Start 7 Day Free Launch Control Trial</SolidButton>
          </div>
        </div>
      </Modal>
    );
  }
}

const LaunchControlInfoPopup = connect(state => ({
  loading: selectLoading(state),
  smsRegistered: selectSmsRegistered(state),
}), {
  getSmsLogin,
})(LaunchControlInfo);

const registrationId = getPopupRegistration(LaunchControlInfoPopup);
LaunchControlInfoPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.MEDIUM });

export default LaunchControlInfoPopup;
