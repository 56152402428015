/** @flow */
const images = ['jpg', 'jpeg', 'png', 'gif', 'tif', 'tiff', 'bmp'];
const tables = ['csv', 'xls', 'xlsx'];

export const imageFormatList = images.map(format => `.${format}`).join(',');
export const tableFormatList = tables.map(format => `.${format}`).join(',');

export default {
  images,
  tables,
};
