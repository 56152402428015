import React from 'react';
import { Col, Row } from 'react-bootstrap/lib';
import { Pie, PieChart, Sector, Tooltip } from 'recharts';
import css from './style.scss';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';


const DonutChart = ({data, title, additionalTile, additonalData, sort, propertyAddress, image}) =>{

    const chartColors = [
        '#D34F0D',
        '#F16722',
        '#F58D5C',
        '#FFC8AC',
        '#0E6673',
        '#128292',
        '#53A6B2',
        '#94C9D1',
        '#DE9C1E',
        '#F4AC21',
        '#F6BA47',
        '#F9D081',
        '#5B68C3',
        '#747FCD',
        '#929BD9',
        '#AFB6E5',
        '#ca1551',
        ];
  
  const formatData = () => {
    let total = data.reduce((prev, next)=> prev + next.value, 0);

    let fdata = sort? data.filter((d)=> d.value>0.005): data;

    if(sort){
      let others = fdata.filter((d)=> d.value<0.005)
      let value = others.reduce((prev, next)=> prev + next.value, 0);
      let indx = fdata.findIndex(d => d.label == 'Other');
      if(value>0){
        if(indx>0){
          fdata[indx].value=value;
        } else {
          fdata.push({
            'value': value,
            'label': 'Other',
          })
        }
      }

      fdata = fdata.sort((a,b)=> b.value-a.value);
    }

    fdata = fdata.map((d,index) => {
      let fillData = {
        ...d,
        fill: chartColors[index],
        percentage: d.value/total * 100
      }
      return fillData;
    });   

    return fdata;
  }

  const formatValue = (value) => {
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  
    return (
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius-2}
          outerRadius={outerRadius+2}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={css.tooltipBg}>
            <p className={css.tooltipText}>{`${payload[0].label}: ${payload[0].percentage.toFixed(2)}%`}</p>
        </div>
      );
    } else return <div></div>;
  };

  const onEnter = (e, index) => setActiveIndex(index);
  const onLeave = () => setActiveIndex(-1);

    const formattedData = formatData();
    const [activeIndex, setActiveIndex] = useState(-1);
    return (
      <Row className={css.graph}>
        <Col md={6} sm={12}>
          {additionalTile && <p className={css.title}>{additionalTile}</p>}
          {additonalData && additonalData.map(demograph => (
            <p><span className={css.incomeLabel}>{demograph.label}</span> ${formatValue(demograph.value)}</p>
          ))}
          <p className={css.title}>{title}</p>
          {!!formattedData.length && formattedData.map(demograph => (
                <div className={css.donutLegend}>
                  <p>{demograph.label} | {demograph.percentage.toFixed(2)}%</p>
                </div>
              ))
          }
          {propertyAddress &&
          <p className={css.title}>
            All Demographics information<br/>
            is within 1 mile of<br/>
            {propertyAddress}
          </p>
          }
        </Col>
        <Col md={6} sm={12}>
          {image &&
            <div className={css.imageContainer}>
            <ReactSVG
              src={image}
              className={css.image}
            />
            </div>
          }
          <PieChart width={300} height={320} className={css.chart}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={formattedData.slice().reverse()}
              cx={150}
              cy={160}
              innerRadius={100}
              outerRadius={140}
              paddingAngle={0}
              dataKey="value"
              startAngle={-270}
              onMouseEnter={onEnter}
              onMouseLeave={onLeave}
              isAnimationActive={false}
            />
            <Tooltip content={<CustomTooltip/>} />
          </PieChart>          
        </Col>
      </Row>
        
    );
}

export default DonutChart;