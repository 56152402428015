export const ADUReference = {
  "data": [
    {
      "ref_id": 7,
      "ref_label": "400",
      "ref_type": "AduSize",
      "ref_value": "400",
      "sort_order": "2",
      "dynamic_label": "400",
      "ref_type_id": "2"
    },
    {
      "ref_id": 8,
      "ref_label": "600",
      "ref_type": "AduSize",
      "ref_value": "600",
      "sort_order": "3",
      "dynamic_label": "600",
      "ref_type_id": "2"
    },
    {
      "ref_id": 10,
      "ref_label": "1200",
      "ref_type": "AduSize",
      "ref_value": "1200",
      "sort_order": "5",
      "dynamic_label": "1200",
      "ref_type_id": "2"
    },
    {
      "ref_id": 3,
      "ref_label": "Better",
      "ref_type": "AduQuality",
      "ref_value": "Better",
      "sort_order": "3",
      "dynamic_label": "A Better",
      "ref_type_id": "1"
    },
    {
      "ref_id": 2,
      "ref_label": "Good",
      "ref_type": "AduQuality",
      "ref_value": "Good",
      "sort_order": "2",
      "dynamic_label": "A Good",
      "ref_type_id": "1"
    },
    {
      "ref_id": 14,
      "ref_label": "Above Living Area",
      "ref_type": "AduType",
      "internal": "true",
      "ref_value": "abovelivingarea",
      "sort_order": "3",
      "dynamic_label": "Above Living Area",
      "ref_type_id": "3"
    },
    {
      "ref_id": 12,
      "ref_label": "Attached",
      "ref_type": "AduType",
      "internal": "false",
      "ref_value": "attached",
      "sort_order": "2",
      "dynamic_label": "Attached",
      "ref_type_id": "3"
    },
    {
      "ref_id": 9,
      "ref_label": "800",
      "ref_type": "AduSize",
      "ref_value": "800",
      "sort_order": "4",
      "dynamic_label": "800",
      "ref_type_id": "2"
    },
    {
      "ref_id": 4,
      "ref_label": "Best",
      "ref_type": "AduQuality",
      "ref_value": "Best",
      "sort_order": "4",
      "dynamic_label": "A Best",
      "ref_type_id": "1"
    },
    {
      "ref_id": 13,
      "ref_label": "Above Garage",
      "ref_type": "AduType",
      "internal": "true",
      "ref_value": "abovegarage",
      "sort_order": "4",
      "dynamic_label": "Above Garage",
      "ref_type_id": "3"
    },
    {
      "ref_id": 16,
      "ref_label": "ADU Landing Page",
      "ref_type": "AduPage",
      "ref_value": "AduLandingPage",
      "sort_order": "1",
      "dynamic_label": "ADU Landing Page",
      "ref_type_id": "4"
    },
    {
      "ref_id": 17,
      "ref_label": "ADU Calculate Button",
      "ref_type": "AduButtons",
      "ref_value": "AduCalculateButton",
      "sort_order": "2",
      "dynamic_label": "ADU Calculate Button",
      "ref_type_id": "5"
    },
    {
      "ref_id": 6,
      "ref_label": "200",
      "ref_type": "AduSize",
      "ref_value": "200",
      "sort_order": "1",
      "dynamic_label": "200",
      "ref_type_id": "2"
    },
    {
      "ref_id": 1,
      "ref_label": "Economy",
      "ref_type": "AduQuality",
      "ref_value": "Economy",
      "sort_order": "1",
      "dynamic_label": "An Economy",
      "ref_type_id": "1"
    },
    {
      "ref_id": 5,
      "ref_label": "Designer",
      "ref_type": "AduQuality",
      "ref_value": "Designer",
      "sort_order": "5",
      "dynamic_label": "A Designer",
      "ref_type_id": "1"
    },
    {
      "ref_id": 15,
      "ref_label": "Garage Conversion",
      "ref_type": "AduType",
      "internal": "true",
      "ref_value": "garageconversion",
      "sort_order": "5",
      "dynamic_label": "Garage Conversion",
      "ref_type_id": "3"
    },
    {
      "ref_id": 11,
      "ref_label": "Detached",
      "ref_type": "AduType",
      "internal": "false",
      "ref_value": "detached",
      "sort_order": "1",
      "dynamic_label": "Detached",
      "ref_type_id": "3"
    }
  ]
}