import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import classNames from 'classnames';

import css from './styles.scss';


const ErrorDisplay = ({ children, className }) => (
  <div className={classNames(css.warning, className)}>{children}</div>
);

ErrorDisplay.propTypes = {
  className: PropTypes.string,
};

export default pure(ErrorDisplay);
