import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { Col, Row } from 'react-bootstrap/lib';
import { Pie, PieChart } from 'recharts';
import formatCurrency from 'utils/currency/numberToPrice';
import css from './style.scss';


export default class DonutChart extends PureComponent {
  getAduChartLabel() {
    const { aduTotal, ratePerSqFt } = this.props;
    return (
      <text
        x={0}
        y={155}
        dy={0}
        fill="#3d405c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan
          x={120}
          dy={20}
          alignmentBaseline="middle"
          style={{
            fill: '#66707d',
            fontSize: '18px',
            fontWeight: '500',
            fontFamily: 'Inter',
          }}
        >Estimated Total</tspan>
        <tspan
          x={120}
          dy={40}
          alignmentBaseline="middle"
          style={{
            fill: '#3C4654',
            fontSize: '36px',
            fontWeight: '600',
            fontFamily: 'Inter',
          }}
        >{formatCurrency(Math.round(aduTotal))}</tspan>
        <tspan
          x={120}
          dy={28}
          style={{
            fill: '#66707D',
            fontSize: '16px',
            fontWeight: '500',
            fontFamily: 'Inter',
          }}
        >{formatCurrency(Math.round(ratePerSqFt))}/SqFt</tspan>
      </text>
    );
  }

  render() {
    const { data, dynamicAduLabel, property, aduTotal } = this.props;
    const reversedData = data.slice().reverse();
    const address = property.get('address');
    const zip = address.get('zip');
    const totalAmount = formatCurrency(Math.round(aduTotal));
    return (
      <div>
        <Row className={css.clsChartLft}>
          <Col md={6} sm={12}>
            <PieChart width={800} height={350} onMouseEnter={this.onPieEnter}>
              {!!aduTotal && this.getAduChartLabel()}
              <Pie
                data={reversedData}
                cx={120}
                cy={200}
                innerRadius={105}
                outerRadius={120}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
                startAngle={-270}
              />
            </PieChart>
          </Col>

          <Col md={6} sm={12}>
            <div className={css.note}>
              <h4>
                ADU Summary
              </h4>
              <p>
                {dynamicAduLabel.quality} quality {dynamicAduLabel.size} sqft {dynamicAduLabel.type} ADU
                can be built in the {zip} zip code for approximately {totalAmount}. This ADU calculation is an approximation based on
                the property’s zip code.
              </p>
            </div>
          </Col>
        </Row>

        <div className={css.clsChartLegend}>
          <div className={css.clsChartRit}>
            <Row className={classNames(css.flexWrapBox, css.mar0)}>
              {!!this.props.data.length && this.props.data.map(adu => (<Col lg={4} md={6} sm={12} className={css.clsChartValueList}>
                  <h6>{adu.projectGroupName}</h6>
                  <p>
                    {formatCurrency(Math.round(adu.subTotal))}<span />{adu.percentage.toFixed(1)}%
                  </p> 
              </Col>
                ))}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
