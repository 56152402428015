import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import classNames from 'classnames';


const SVG = (props) => {
  const { styles = {}, icon, className, onDragOver, onDragEnter, onDragLeave, onDrop, onClick, title, height, width, size, ...rest } = props;
  const use = icon ? <use xlinkHref={`#${icon}`} /> : null;

  if (height || size) styles.height = `${height || size}px`;
  if (width || size) styles.width = `${width || size}px`;

  return (
    <svg {...{ onDragOver, onDragEnter, onDragLeave, onDrop, onClick }} {...rest} style={styles} className={classNames(`icon-${icon}`, className)}>
      {!title ? null : <title>{title}</title>}
      {use}
    </svg>
  );
};

SVG.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  onDragOver: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDrop: PropTypes.func,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  size: PropTypes.number,
};

export default pure(SVG);
