import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Confirm from 'app/components/Confirm';
import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import CellWithInput from 'components/base/Table/CellWithInput';
import { getPopupRegistration, openPopup, Priority } from 'app/PopupHolder';
import ButtonImport from 'components/base/ButtonImport';
import { decorate } from 'app/Property/Detail/UrlStorage';
import Table, { Column } from 'components/base/Table';
import FormControlWraper from 'components/base/FormControlWraper';
import { PropertyAssignmentPopup } from 'app/components/GroupAssignment';

import {
  uploadContract,
  loadContracts,
  loadContract,
  deleteContract,
  saveContract,
  loadContractProperties,
  saveContractProperties,
  selectIsLoading,
  selectContractsData,
  selectIsContractsLoading,
} from 'data/contracts';

import css from './style.scss';


class ContractManager extends PureComponent {
  constructor(props) {
    super(props);

    this.handleUpload = this.handleUpload.bind(this);
    this.renderDefaultCell = this.renderDefaultCell.bind(this);
    this.renderButtonCell = this.renderButtonCell.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.state = { searchString: '' };
  }

  componentDidMount() {
    this.props.loadContracts();
  }

  handleUpload(event) {
    const files = event.target.files;
    if (files && files[0] && files[0] instanceof File) {
      const fileName = files[0].name.replace(/\.[^/.]+$/, '');
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('name', fileName);
      formData.append('description', '');
      this.props.uploadContract(formData);
    }
  }

  handleFilterChange(event) {
    const { value } = event.target || event;
    const searchString = value;
    this.setState({ searchString });
  }

  handleDelete(id) {
    const { confirmDelete, deleteContract } = this.props;
    confirmDelete({
      question: 'Do you want to delete contract?',
      onOk: () => deleteContract(id),
    });
  }

  handleAssignClick(contractId) {
    const { loadContractProperties, saveContractProperties, openPropertyAssignmentPopup } = this.props;

    loadContractProperties(contractId, {
      afterSuccess: ({ response: ids }) => openPropertyAssignmentPopup({ ids, onSave: ids => saveContractProperties(contractId, ids) }),
    });
  }

  renderDefaultCell(value, indexes, data) {
    const { loading, saveContract } = this.props;

    return (
      <CellWithInput keyPath={indexes.fieldPath} id={data.get('id')} data={data} onSave={saveContract} isLoading={loading}>
        <div>{value}</div>
      </CellWithInput>
    );
  }

  renderButtonCell(value, indexes, data) {
    const { loading, saveContract, loadContract } = this.props;
    const id = data.get('id');
    const btn = { kind: Button.kind.grayGhost, isLoading: loading };

    return (
      <CellWithInput keyPath={indexes.fieldPath} id={id} data={data} onSave={saveContract} isLoading={loading}>
        <div className={css.buttonContainer}>
          <div className={css.description}>{value}</div>
          <div className={css.hiddenCell}>
            {data.get('standard') ? null : <Button {...btn} onClick={() => this.handleDelete(id)}>Delete</Button>}
            <Button {...btn} onClick={() => loadContract(id)}>View Contract</Button>
            <Button {...btn} onClick={() => this.handleAssignClick(id)}>Assign</Button>
          </div>
        </div>
      </CellWithInput>
    );
  }

  render() {
    const { closePopup, loading, contracts } = this.props;
    const { searchString } = this.state;
    const data = contracts.filter(item => item.get('name', '').toLowerCase().includes(searchString.toLowerCase().trim()));

    return (
      <Modal isOpen uniqId="modalManageContracts" padding="25px 10px" caption="Manage Contracts" width="1050px">
        <div className={css.body}>
          <Table data={data} keyField="id" isHoverable className="type_12">
            <Column field="name" renderCell={this.renderDefaultCell}>
              <div className={css.inputContainer}>
                <span>Contract Name</span>
                <div className={css.thRow}>
                  <FormControlWraper id="contractNameFilter">
                    <input type="text" onChange={this.handleFilterChange} value={searchString} />
                  </FormControlWraper>
                </div>
              </div>
            </Column>
            <Column field="description" renderCell={this.renderButtonCell}>
              <div className={css.inputContainer}>
                <span>Description</span>
                <div className={css.thRow} />
              </div>
            </Column>
          </Table>
        </div>
        <div className={css.footer}>
          <ButtonImport kind={Button.kind.grayGhost} isLoading={loading} onChange={this.handleUpload}>Upload</ButtonImport>
          <Button kind={Button.kind.grayGhost} onClick={closePopup}>Close</Button>
        </div>
      </Modal>
    );
  }
}


const ContractManagerPopup = connect(state => ({
  loading: selectIsLoading(state),
  contracts: selectContractsData(state),
  isLoading: selectIsContractsLoading(state),
  somethingIsLoading: selectIsLoading(state),
}), {
  uploadContract,
  loadContracts,
  loadContract,
  deleteContract,
  saveContract,
  confirmDelete: Confirm.open,
  loadContractProperties,
  saveContractProperties,
  openPropertyAssignmentPopup: PropertyAssignmentPopup.open,
})(decorate(ContractManager));

const registrationId = getPopupRegistration(ContractManagerPopup);
ContractManagerPopup.open = (props = {}) => openPopup(registrationId, { ...props, priority: Priority.IF_EMPTY });

export default ContractManagerPopup;
