import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Checkbox from 'components/base/Checkbox';
import Radio from 'components/base/Radio';
import { selectProfile, selectLoading } from 'data/user/selectors';
import { saveProfile } from 'data/user';
import { toProperCase } from 'utils/string';
import { SolidButton } from 'components/Button';

import css from './style.scss';
import cssAccount from '../style.scss';


const Notifications = [
  { field: 'contactAppendSummary', name: 'Skip Tracing Summary' },
  { field: 'postcardSummary', name: 'Postcard Summary' },
  { field: 'listManagementSummary', name: 'List Management Add/Removals Summary' },
  { field: 'voicemailSummary', name: 'Ringless Voicemail' },
  { field: 'savedSearchSummary', name: 'Saved Search Notifications' },
  { field: 'subAccountSummary', name: 'Sub-Account Activity', frequencies: ['MONTHLY'] },
];

class AccountNotifications extends PureComponent {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.state = {};
  }

  componentWillMount() {
    this.setState({ ...this.props.profile });
  }

  handleChange(ev) {
    const { name, value, checked } = ev.target;

    this.setState({ [name]: ['newFeatureAlertEnabled'].includes(name) ? checked : value });
  }

  handleSave() {
  }

  render() {
    const { newFeatureAlertEnabled } = this.state;

    return (
      <div className={css.notifications}>
        <div className={cssAccount.left}>
          <div className={cssAccount.header}>Notification Settings</div>
          <div className={css.section}>
            <div className={css.sectionHeader}>General Email Notifications</div>
            {Notifications.map(n => (
              <div className={css.item} key={n.field}>
                <div>{n.name}</div>
                <div className={css.options}>
                  {(n.frequencies || ['DAILY', 'MONTHLY']).concat(['NONE']).map(f => (
                    <Radio name={n.field} label={toProperCase(f)} onChange={this.handleChange} checked={(this.state[n.field] || 'NONE') === f} value={f} key={f} />
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className={css.section}>
            <div className={css.sectionHeader}>In-App Notifications</div>
            <div className={css.item}>
              <div>Show News On Login Page</div>
              <Checkbox name="newFeatureAlertEnabled" onChange={this.handleChange} checked={newFeatureAlertEnabled} />
            </div>
          </div>
          <SolidButton onClick={this.handleSave}>Save Notification Settings</SolidButton>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  profile: selectProfile(state).toJS(),
  loading: selectLoading(state),
}), {
  saveProfile,
})(AccountNotifications);
