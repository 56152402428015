import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ColorDot from 'components/ColorDot';
import { SearchColors, SearchDefaultColor } from 'data/search';
import Checkbox from 'components/base/Checkbox';
import { dragStartWrapper } from 'utils/DOM/dragDrop';
import { selectSelection } from 'data/property';

import css from './style.scss';


class IndexCellRenderer extends Component {
  render() {
    const { value, selection, data: { id, listingType } = {}, context: { onDragStart, onDragEnd, onSelect } = {} } = this.props;
    const selected = !!selection.getIn(['items', String(id)]);

    return (
      <div
        className={classNames(css.selectCell, { [css.draggable]: !!onDragStart })}
        draggable={!!onDragStart}
        onDragStart={dragStartWrapper(() => onDragStart({ recordId: id, selected }))}
        onDragEnd={onDragEnd}
      >
        <Checkbox checked={selected} onClick={ev => onSelect(id, !selected, ev)} defaultChecked={false} />
        <ColorDot className={css.colorDot} color={SearchColors[listingType || SearchDefaultColor]} isBig={false}>{value}</ColorDot>
      </div>
    );
  }
}

export default connect(state => ({ selection: selectSelection(state) }))(IndexCellRenderer);
