import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import FormControlWraper from 'components/base/FormControlWraper';
import Dropdown from 'components/base/Dropdown';
import css from './style.scss';


class CustomSelect extends PureComponent {
  render() {
    const { id, label, isInline, before, after, ...rest } = this.props;
    const beforeSymbol = before ? <span className={css.symbol}>{before}</span> : null;
    const afterSymbol = after ? <span className={classNames(css.symbol, css.after)}>{after}</span> : null;
    return (
      <FormControlWraper id={id} label={label} className={classNames(css.dropdownContainer, { [css.inline]: isInline })}>
        {beforeSymbol}
        <Dropdown name={id} id={id} {...rest} />
        {afterSymbol}
      </FormControlWraper>
    );
  }
}

CustomSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  isInline: PropTypes.bool,
  before: PropTypes.string,
  after: PropTypes.string,
};

CustomSelect.defaultProps = {
  isInline: false,
};

export default CustomSelect;
