import React from 'react';
import classNames from 'classnames';
import config from 'config';
import Image from 'components/base/Image';
import PropTypes from 'prop-types';

import css from './style.scss';

const { constants: { API: { BASE_URL } } } = config;

const UserLogo = ({ url, small, className, size, id, showDefault, borderColor }) => {
  const borderStyle = !borderColor ? {} : { borderColor };
  const logoStyle = { ...borderStyle, ...(size && { width: `${size}px`, height: `${size}px` }) };
  const logoFrameStyle = { ...borderStyle, ...(size && { width: `${size - 2}px`, height: `${size - 2}px` }) };

  return (
    <div className={classNames(css.logo, { [css.small]: small }, className)} style={logoStyle}>
      <div className={css.logoFrame} style={logoFrameStyle}>
        <Image src={url || `${BASE_URL}/resource/auth/ps4/user/${!id || showDefault ? 'defaultLogo' : `logo/${id}?${Math.random()}`}`} alt="Logo" />
      </div>
    </div>
  );
};

UserLogo.propTypes = {
  id: PropTypes.number,
  url: PropTypes.string,
  className: PropTypes.string,
  borderColor: PropTypes.string,
  small: PropTypes.bool,
  size: PropTypes.number,
};

UserLogo.defaultProps = {
  small: false,
};

export default UserLogo;
