import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import css from "../style.scss";

const FilterInputComponent = ({ boxTittle, defaultValue, placeHolder, onChange, headerClassName, isHighlightIndex }) => {
  const [value, setValue] = useState(defaultValue || '');

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
      onChange(newValue);
  };

  useEffect(() => {
      setValue(defaultValue);
  }, [defaultValue]);



  return (
    <>
      <h4 className={classNames((headerClassName === "" ? css.clsSubHeading: headerClassName), (isHighlightIndex ? css.highlightOrange : ""))}>{boxTittle}</h4>
      <div className={classNames(css.flexBoxNowrap, css.clsFilterSelect)}>
        <input
          className={css.form_control}
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeHolder || 'Enter text'}
        />
      </div>
    </>
  );
};

export default FilterInputComponent;
