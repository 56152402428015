/** @flow */

const IF_EMPTY = 0;
const LOWEST = 12;
const LOW = 25;
const DEFAULT = 50;
const MEDIUM = 75;
const MEDIUM_HIGH = 100;
const HIGH = 125;
const HIGHEST = 150;

// eslint-disable-next-line import/prefer-default-export
export const Priority = {
  IF_EMPTY,
  LOWEST,
  LOW,
  DEFAULT,
  MEDIUM,
  MEDIUM_HIGH,
  HIGH,
  HIGHEST,
};
