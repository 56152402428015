import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'components/base/SVG';
import { dropdownable } from 'components/base/Dropdown';
import { SolidButton } from 'components/Button';
import { withRouter } from 'react-router-dom';

import css from './style.scss';


class DropdownMenu extends PureComponent {

  handleClick(i) {
    const { closeDropdown, onSelect, history } = this.props;

    closeDropdown();

    const onSel = i.onSelect || onSelect;
    if (onSel) onSel(i);

    if (i.url) history.push(i.url);
  }

  render() {
    const { text, isOpen, onClick, items, direction, title, caption, icon, selection, loading, dropdownClassName, leftAnchored } = this.props;

    return (
      <div className={classNames(css.dropdownMenu, { [css.open]: isOpen, [css.iconMode]: !!icon, [css.left]: leftAnchored })} onClick={onClick} name="dropdown-btn" id={text}>
        {text ? <SolidButton text={text} icon={icon} loading={loading} /> : <SVG icon={icon} className={css.icon} title={title} />}
        <div className={classNames(css.dropdown, { [css.top]: direction === 'top' }, dropdownClassName)}>
          {!caption ? null : <div className={css.caption}>{caption}</div>}
          {items.filter(i => i).map(i => (
            <div className={classNames(css.item, { [css.selected]: 'value' in i && i.value === selection })} onClick={() => this.handleClick(i)} key={`${i.value}-${String(Math.random())}`}>{i.label ? i.label: JSON.stringify(i.value)?.replace(/['"]+/g, '')}</div>
          ))}
        </div>
      </div>
    );
  }
}

DropdownMenu.propType = {
  dropdownClassName: PropTypes.string,
  placeholder: PropTypes.string,
  direction: PropTypes.string,
  caption: PropTypes.string,
  icon: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  selection: PropTypes.any,
  onSelect: PropTypes.func,
  leftAnchored: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

DropdownMenu.defaultProps = {
  direction: 'bottom',
  leftAnchored: false,
};

export default withRouter(dropdownable(DropdownMenu));
