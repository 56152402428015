import React, { PureComponent } from 'react';
import { Field } from 'redux-form/immutable';

import uniqueId from 'utils/uniqueId';

import Radio from './Radio';


export default class RadioFormed extends PureComponent {
  constructor(props) {
    super(props);
    this.id = uniqueId();
  }
  render() {
    return (
      <Field id={this.id} {...this.props} component={Radio} type="radio" />
    );
  }
}
