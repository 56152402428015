import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Modal from 'components/base/Modal';
import Button, { ButtonLink } from 'components/base/Button';
import { selectCampaign } from 'data/campaigns';
import { campaignPath } from 'app/routes';
import css from './style.scss';


class ModalCampaignCreated extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { campaign, history } = this.props;
    history.push(campaignPath(campaign.get('id')));
  }

  render() {
    const { campaign } = this.props;
    const path = campaignPath(campaign.get('id'));
    const btn = { kind: Button.kind.blue, size: Button.size.large, className: css.button };

    return (
      <Modal isOpen uniqId="CampaignCreated" caption="New Campaign Created" width="375px" onClose={this.handleClose}>
        <div className={css.campaignCreated}>
          <div className={css.caption}>You have successfully created a new campaign<br /> {`"${campaign.get('name', '')}"`}</div>
          <div className={css.caption}>Here’s a few suggested things to do next:</div>
          <div className={css.buttons}>
            <ButtonLink {...btn} to={`${path}/website`}>Setup Website</ButtonLink>
            <ButtonLink {...btn} to={`${path}/activity`}>Add an Activity</ButtonLink>
            <ButtonLink {...btn} to={path}>Campaign Dashboard</ButtonLink>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withRouter(connect(state => ({
  campaign: selectCampaign(state),
}))(ModalCampaignCreated));
