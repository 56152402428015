export default function getField(fields, fieldId) {
  for (let i = 0; i < fields.length; i++) {
    const f = fields[i];
    if (f.field.id === fieldId) {
      return f;
    }
  }

  return null;
}
