import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';
import Dropdown from 'components/base/Dropdown';
import FormControlWraper from 'components/base/FormControlWraper';
import Button, { ButtonLink } from 'components/base/Button';
import { pluralize } from 'utils/string';
import classNames from 'classnames';

import { selectGroups, selectLoading as selectContactLoading, getContactIds } from 'data/contacts';
import { ContactAssignmentPopup } from 'app/components/GroupAssignment';
import { saveCampaign, selectLoading } from 'data/campaigns';
import { getGroupOptions } from 'reducers';
import { campaignPath } from 'app/routes';

import css from './style.scss';


const defaultSet = () => (Object.assign({}, {
  id: '',
  groups: List(),
  contactIds: [],
  selectedIds: [],
}));

class CampaignEditor extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditRecipientsClick = this.handleEditRecipientsClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.refreshState = this.refreshState.bind(this);

    this.state = { name: '', sets: [], contactIds: [] };
  }

  componentWillMount() {
    this.handleAddClick();
  }

  getSets() {
    return this.state.sets.slice();
  }

  refreshState(newSets) {
    const sets = (newSets || this.getSets());
    const { groups } = this.props;

    const groupIds = sets.filter(g => g.groupId >= 0).map(g => g.groupId);
    let contactIds = [];

    sets.forEach((set, i) => {
      const s = set;
      s.id = `recipientGroup${i}`;
      s.groups = groups.filter(g => g.get('id') === set.groupId || !groupIds.includes(g.get('id')));
      s.groupIndex = s.groups.findIndex(g => g.get('id') === s.groupId, '');
      contactIds = contactIds.concat(s.selectedIds.filter(id => !contactIds.includes(id)));
    });

    this.setState({ sets, contactIds });
  }

  handleAddClick() {
    this.refreshState(this.state.sets.concat(defaultSet()));
  }

  handleEditClick(setIndex) {
    const set = this.state.sets[setIndex];
    this.props.openContactAssignmentPopup({
      ids: set.selectedIds,
      groupId: set.groupId,
      onSave: (contactIds) => { set.selectedIds = contactIds.filter(id => set.contactIds.includes(id)); this.refreshState(); },
    });
  }

  handleRemoveClick(setIndex) {
    const sets = this.getSets();
    sets.splice(setIndex, 1);
    this.refreshState(sets);
  }

  handleChange(ev) {
    const { name, value } = ev.target;
    this.setState({ [name]: value });
  }

  handleEditRecipientsClick() {
    this.props.openContactAssignmentPopup({
      ids: this.state.contactIds,
      onSave: (contactIds) => {
        const sets = this.getSets();
        // eslint-disable-next-line no-param-reassign
        sets.forEach((set) => { set.selectedIds = set.contactIds.filter(id => contactIds.includes(id)); });
        this.refreshState();
      },
    });
  }

  handleSave(ev) {
    const { saveCampaign, history } = this.props;
    const { name, contactIds } = this.state;

    ev.preventDefault();
    saveCampaign({ name, contactIds }, ({ response }) => history.push(campaignPath(`${response.campaign.id}/created`)));
  }

  handleGroupChange(setIndex, groupIndex) {
    const sets = this.getSets();
    const group = groupIndex === '' ? null : sets[setIndex].groups.get(Number(groupIndex));
    const set = defaultSet();
    sets[setIndex] = set;

    if (!group) this.refreshState(sets);
    else {
      set.group = group;
      set.groupId = group.get('id');
      this.props.getContactIds(set.groupId, ({ response }) => {
        set.contactIds = response;
        set.selectedIds = set.contactIds.slice();
        this.refreshState(sets);
      });
    }
  }

  render() {
    const { loading, groups } = this.props;
    const { name, sets, contactIds } = this.state;
    const len = contactIds.length;
    const btn = { isLoading: loading, size: Button.size.large, className: css.btn };
    const saveEnabled = !!contactIds.length && name.trim() !== '';
    const contactLabel = qty => `${qty} ${pluralize('Contact', qty)}`;

    return (
      <div className={css.campaignEditor}>
        <form onSubmit={this.handleSave}>
          <div>
            <div className={css.header}>
              <div className={css.left}>
                <div className={css.title}>New Campaign{!len ? null : ` (${contactLabel(len)})`}</div>
              </div>
              <div className={css.right}>
                <ButtonLink {...btn} to={campaignPath()} kind={ButtonLink.kind.grayGhost} disabled={loading}>Cancel</ButtonLink>
                <Button {...btn} kind={Button.kind.blue} disabled={loading} onClick={this.handleEditRecipientsClick}>View Selected Recipients{!contactIds.length ? null : ` (${contactIds.length})`}</Button>
                <Button {...btn} type="submit" kind={Button.kind.blue} disabled={loading || !saveEnabled}>Save</Button>
              </div>
            </div>
            <div className={css.body}>
              <div className={css.row}>
                <div className={css.cell50}>
                  <FormControlWraper id={name} label="Campaign Name*" hasMarginBottom large>
                    <input name="name" type="text" value={name} onChange={this.handleChange} />
                  </FormControlWraper>
                </div>
              </div>
              {sets.map((set, i) => (
                <div className={css.row} key={set.id}>
                  <div className={css.cell50}>
                    <FormControlWraper
                      id={set.id}
                      label={(
                        <div className={css.recipientListLabel}>
                          <span>Target Recipients*</span>
                          {!set.group ? null : <span className={css.label}>{set.selectedIds.length} / {contactLabel(set.contactIds.length).toLowerCase()}</span>}
                        </div>
                      )}
                      labelClassName={css.formControlLabel}
                      hasMarginBottom
                      large
                    >
                      <Dropdown groups={getGroupOptions(set.groups)} onChange={ev => this.handleGroupChange(i, ev.target.value)} value={set.groupIndex} />
                    </FormControlWraper>
                  </div>
                  <div className={classNames(css.cell25, css.bottomed)}>
                    {sets.length <= 1 && !set.group ? null : <Button kind={Button.kind.blueLink} className={css.btnEditRemove} disabled={!set.group} onClick={() => this.handleEditClick(i)}>edit</Button>}
                    {sets.length <= 1 ? null : <Button kind={Button.kind.redLink} className={css.btnEditRemove} onClick={() => this.handleRemoveClick(i)}>remove</Button>}
                  </div>
                  <div className={classNames(css.cell25, css.bottomed)} />
                </div>
              ))}
              <div className={css.row}>
                <div className={css.cell50}>
                  <Button {...btn} kind={Button.kind.grayGhost} onClick={this.handleAddClick} disabled={loading || sets.length >= groups.size}>Add Group</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  loading: selectLoading(state) || selectContactLoading(state),
  groups: selectGroups(state),
}), {
  openContactAssignmentPopup: ContactAssignmentPopup.open,
  saveCampaign,
  getContactIds,
})(CampaignEditor));
