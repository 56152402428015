import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { getPopupRegistration, openPopup, closePopup ,  closeAllPopup} from 'app/PopupHolder';
import Modal from 'components/base/Modal';
import Button, { SolidButton } from 'components/Button';
import FormControlWraper from 'components/base/FormControlWraper';
import Radio from 'components/base/Radio';
import Checkbox from 'components/base/Checkbox';
import InputDate from 'components/base/InputDate';
import Confirm from 'app/components/Confirm';
import PriceList from 'app/components/PriceList';
import { formatGraphicUrl } from 'utils/URL';
import { today } from 'utils/date/formatDate';
import { selectPostcardCampaign, selectPostcardTemplates, selectLoading, selectPostcardRates, loadPostcardTemplates, getPostcardCampaign, postcardCampaignChange, ElementTypes } from 'data/campaigns';
import BasicPriceList from './BasicPriceList';

import PostcardTemplatesPopup from './templates';
import css from './style.scss';


const defaultDelivery = { seq: 0, sendDate: today(), quantity: null };

const clone = obj => JSON.parse(JSON.stringify(obj));
const cloneGraphic = (graphic, standard) => {
  const g = clone(graphic);
  g.id = null;
  g.elements = (g.elements || []).filter(el => !standard || el.type !== ElementTypes.LOGO).map(el => Object.assign(el, { id: null }));

  return g;
};

const renderGraphics = (source, onClick) => (
  <div className={classNames(css.images, { [css.clickable]: !!onClick })}>
    <div className={css.image} onClick={onClick}>
      <img src={formatGraphicUrl(source.frontGraphic)} alt="Front" />
    </div>
    <div className={css.image} onClick={onClick}>
      <img src={formatGraphicUrl(source.backGraphic)} alt="Back" />
    </div>
  </div>
);

const MaxDeliveries = 10;
const MaxDeliveryDays = 90;

const PriceOptions = [
  { name: '4.25"x6"', field: 'rate', icon: 'iconPostcard', headerClassName: css.priceHeader },
  { name: '5.5"x8.5" (Oversized)', field: 'rate2', icon: 'iconPostcard', headerClassName: css.priceHeader },
];

class PostcardSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDelivery = this.handleChangeDelivery.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleApplyTemplateClick = this.handleApplyTemplateClick.bind(this);
    this.handleSelectTemplate = this.handleSelectTemplate.bind(this);
    this.handleTemplateUnmount = this.handleTemplateUnmount.bind(this);
    this.closeSettingsPopup = this.closeSettingsPopup.bind(this);

    this.sizes = null;

    const { mode } = props;
    this.newMode = mode === 'new';
    this.saveMode = mode === 'save';
    this.fullMode = mode === 'full';

    this.state = {
      name: '',
      size: { templates: [] },
      deliveries: null,
      templateEnabled: false,
      template: null,
      frontClear: null,
      backClear: null,
      scheduleEnabled: false,
      templateApplied: true,
      templateMode: false,
    };
  }

  componentWillMount() {
    this.handleProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.handleProps(props);
  }

  handleProps(props) {
    const { loading, loadPostcardTemplates, templates: loadedTemplates } = props;
    if (!loading && !this.sizes) {
      if (!loadedTemplates) loadPostcardTemplates();
      else {
        const { campaign: { name, templateId, templateEnabled, deliveries, frontGraphic: { size: { id: sizeId }, elements: el1 = [] }, backGraphic: { elements: el2 = [] } } } = props;
        const templates = loadedTemplates.toJS();

        this.sizes = templates.reduce((sizes, template, i) => {
          const { size } = template.frontGraphic;
          if (!i || sizes[sizes.length - 1].id !== size.id) sizes.push(Object.assign({}, size, { templates: [] }));
          sizes[sizes.length - 1].templates.push(template);
          return sizes;
        }, []);

        const size = this.sizes.find(s => s.id === sizeId) || this.sizes[0];

        this.setState({
          name,
          size,
          templateEnabled,
          scheduleEnabled: !!(deliveries && deliveries.length),
          deliveries: (deliveries || []).map(d => ({ ...d, sendDate: moment(d.sendDate) })),
          template: el1.length || el2.length ? null : templates.find(t => t.id === templateId) || size.templates[0],
          frontClear: !el1.length,
          backClear: !el2.length,
        });
      }
    }
  }

  handleSave() {
    const { closePopup, postcardCampaignChange, campaign, getPostcardCampaign, confirm, onSave, closePostcardTemplates } = this.props;
    const { name, templateEnabled, template, size, frontClear, backClear, deliveries: dels, scheduleEnabled } = this.state;
    let { frontGraphic, backGraphic } = campaign;

    let message = null;
    let deliveries = null;

    if (scheduleEnabled && dels && dels.length) {
      const dateMap = {};

      deliveries = dels.map((d, i) => {
        const delivery = { ...d, sendDate: d.sendDate.toDate() };
        const { quantity } = delivery;
        const val = delivery.sendDate.valueOf();
        if (!message) {
          const date = moment(d.sendDate);
          const fmt = date.format('MMM dd, yyyy');
          if (dateMap[val]) message = `Mailing Schedule date ${fmt} has been specified more than once. Please ensure that each date is unique.`;
          else if (today().isAfter(date)) message = `Mailing Schedule date ${fmt} has passed. Please ensure that all dates are in the future.`;
          else if (date.isAfter(today().add(MaxDeliveryDays, 'd'))) message = `Mailing Schedule date ${fmt} is invalid. Please ensure that all dates are within {MaxDeliveryDays} days.`;
          else if (i < dels.length - 1 && !(quantity > 0)) message = `Quantity for mailing ${i + 1} is invalid. Please enter a positive number.`;
        }

        dateMap[val] = true;

        return delivery;
      });
    }

    if (message) confirm({ cancelLabel: null, question: message })();
    else {
      const process = (template) => {
        if (frontClear && template) frontGraphic = cloneGraphic(template.frontGraphic, template.standardTemplate);
        else frontGraphic.size = size;

        if (backClear && template) backGraphic = cloneGraphic(template.backGraphic, template.standardTemplate);
        else backGraphic.size = size;

        postcardCampaignChange(null, {
          name: name.trim() === '' ? campaign.name : name,
          templateEnabled,
          frontGraphic,
          backGraphic,
          deliveries,
        });

        this.closeSettingsPopup();
        closePostcardTemplates();

        // Hack: Set timeout so save can get updated state.
        if (onSave) setTimeout(onSave, 100);
      };

      if (!(template && (frontClear || backClear))) process();
      else getPostcardCampaign(template.id, ({ response }) => process(response));
    }
  }

  handleChange(ev, onComplete) {
    const { name, value, checked } = ev.target || ev;
    const { deliveries, template, size: { templates } } = this.state;

    let val = ['templateEnabled'].includes(name) ? checked : value;
    if (['scheduleEnabled'].includes(name)) val = val === '1';

    const data = { [name]: val };
    if (name === 'size') data.template = template == null ? null : val.templates[Math.max(0, Math.min(val.templates.length - 1, templates.indexOf(template)))];
    else if (name === 'scheduleEnabled' && val && (!deliveries || !deliveries.length)) data.deliveries = [{ ...defaultDelivery }];
    else if (name === 'templateApplied') data[name] = value === 'true';

    this.setState(data, onComplete);
  }

  handleChangeDelivery(ev) {
    const { name, value } = ev.target || ev;
    const { deliveries } = this.state;

    const field = ['sendDate', 'quantity'].find(f => name.startsWith(f));
    const seq = Number(name.substr(field.length));

    if (seq < deliveries.length) {
      const delivery = { ...deliveries[seq], [field]: value };
      delivery.quantity = Number(String(delivery.quantity).replace(/\D/g, '')) || null;
      if (!delivery.sendDate) delivery.sendDate = today();

      deliveries[seq] = delivery;
      if (seq === deliveries.length - 1 && delivery.quantity > 0) deliveries.push({ ...defaultDelivery, seq: deliveries.length, sendDate: moment(delivery.sendDate).add(1, 'w') });

      this.sortDeliveries(deliveries);
    }
  }

  handleTemplateUnmount() {
    this.setState({ templateMode: false });
  }

  handleApplyTemplateClick() {
    const { size, template } = this.state;
    this.setState({ templateMode: true }, () => this.props.openPostcardTemplates({
      onSelect: this.handleSelectTemplate,
      templates: size.templates.slice(1),
      template,
      onUnmount: this.handleTemplateUnmount,
    }));
  }

  handleSelectTemplate(template) {
    this.handleChange({ name: 'template', value: template }, this.newMode ? this.handleSave : this.props.closePostcardTemplates);
  }

  handleRemoveDeliveryClick(i) {
    const { deliveries } = this.state;
    deliveries.splice(i, 1);
    this.sortDeliveries(deliveries);
  }

  sortDeliveries(dels) {
    dels.sort((d1, d2) => d1.sendDate.valueOf() - d2.sendDate.valueOf() || d1.seq - d2.seq);
    const deliveries = dels.map((d, i) => ({ ...d, seq: i }));
    deliveries[deliveries.length - 1].quantity = null;

    this.setState({ deliveries });
  }
  closeSettingsPopup() {
    this.props.closePopup();
    this.props.closeAllPopup();
  }
  render() {
    const { loading, campaign, title, rates } = this.props;
    const { name, size, templateEnabled, templateApplied, template: selectedTemplate, frontClear, backClear, scheduleEnabled, deliveries, templateMode } = this.state;
    const { templates } = size;
    const initMode = frontClear && backClear;
    const defaultTemplate = initMode ? templates[0] : null;
    const defaultSelected = defaultTemplate === selectedTemplate;
    const deliveryPlaceholder = deliveries && deliveries.length > 1 ? 'All Remaining' : 'All Contacts';

    const rates2 = rates.map((r, i) => ({ ...r, quantityMin: i ? (rates[i - 1].quantity + 1) : 1, quantityMax: r.quantity }));

    // this.initFields = ['FULL', 'INIT'].includes(mode);
    // this.saveFields = ['FULL', 'SAVE'].includes(mode);

    return (
      <Modal
        isOpen={!this.newMode || !templateMode}
        uniqId="PostcardPreview"
        padding="15px"
        width={`${this.newMode ? 800 : 600}px`}
      >
        <div className={css.settings}>
          {this.newMode ? null : <div className={css.previewHeader}>{title}</div>}
          <div className={css.options}>
            {!this.saveMode && !this.fullMode ? null : (
              <div>
                <div className={css.option}>
                  <FormControlWraper label="Postcard Name (Optional)" large>
                    <input type="text" onChange={this.handleChange} name="name" value={name} placeholder="New Postcard" />
                  </FormControlWraper>
                </div>
                <div className={css.option}>
                  <FormControlWraper label="Enable Postcard Template" large>
                    <Checkbox checked={templateEnabled} name="templateEnabled" onClick={this.handleChange} label="Save this template for future use." />
                  </FormControlWraper>
                </div>
              </div>
            )}
            {!this.fullMode ? null : (
              <div>
                <div className={css.option}>
                  <FormControlWraper label="Postcard Size" large>
                    <div className={css.inlineRadio}>
                      {!this.sizes ? null : this.sizes.map((s, i) => (
                        <Radio
                          key={s.id}
                          onChange={() => this.handleChange({ name: 'size', value: s })}
                          label={<div>{s.name} {i ? ' (Oversized)' : ' (Standard)'}</div>}
                          checked={s === size}
                        />
                      ))}
                    </div>
                  </FormControlWraper>
                  <BasicPriceList options={PriceOptions} rates={rates2} />
                </div>
                <div className={css.option}>
                  <FormControlWraper label="Apply Postcard Template" large>
                    <div>
                      <div className={css.inlineRadio}>
                        <Radio onChange={() => this.handleChange({ name: 'template', value: defaultTemplate })} label={initMode ? 'Start with a blank postcard.' : 'Do not apply a template.'} checked={defaultSelected} />
                        <Radio onClick={this.handleApplyTemplateClick} label={initMode ? 'Start with a pre-defined postcard template.' : 'Apply a pre-defined postcard template.'} checked={!defaultSelected} />
                      </div>
                      {defaultSelected ? null : (
                        <div>
                          {initMode ? null : <div className={css.templateWarning}>Template will only be applied to an empty postcard. Please ensure that the side(s) of the postcard you would like the template to be applied to has been cleared.</div>}
                          {renderGraphics(selectedTemplate, this.handleApplyTemplateClick)}
                        </div>
                      )}
                    </div>
                  </FormControlWraper>
                </div>
              </div>
            )}
            {!this.newMode ? null : (
              <div className={css.pricing}>
                <PriceList title="Postcard Pricing" options={PriceOptions} rates={rates2} />
                <div className={css.option}>
                  <div className={css.size}>
                    <div className={css.label}>Postcard Size</div>
                    {!this.sizes ? null : this.sizes.map((s, i) => (
                      <Radio
                        key={s.id}
                        onChange={() => this.handleChange({ name: 'size', value: s })}
                        label={<div>{s.name}{i ? ' (Oversized)' : ''}</div>}
                        checked={s === size}
                      />
                    ))}
                  </div>
                </div>
                <div className={css.option}>
                  <div className={css.label}>Postcard Design</div>
                  <div className={css.design}>
                    <Radio onChange={this.handleChange} name="templateApplied" label="Start with a pre-defined postcard template." checked={templateApplied} value="true" />
                    <Radio onChange={this.handleChange} name="templateApplied" label="Start with a blank postcard." checked={!templateApplied} value="false" />
                  </div>
                </div>
              </div>
            )}
            <div className={css.option} style={{ display: 'none' }}>
              <FormControlWraper label="Postcard Mailing Schedule" large>
                <div className={css.inlineRadio}>
                  <Radio onChange={this.handleChange} name="scheduleEnabled" label="Send all postcards immediately." checked={!scheduleEnabled} />
                  <Radio onChange={this.handleChange} name="scheduleEnabled" label="Send postcards based on a custom schedule." checked={scheduleEnabled} value="1" />
                </div>
                {!scheduleEnabled ? null : (
                  <div className={css.deliveries}>
                    {deliveries.map(({ seq, sendDate, quantity }, i) => (
                      <div className={css.delivery} key={seq}>
                        <label>Mailing {seq + 1} Date:</label>
                        <InputDate onChange={this.handleChangeDelivery} name={`sendDate${seq}`} value={sendDate} />
                        <label>Qty:</label>
                        <input
                          onChange={this.handleChangeDelivery}
                          className={css.quantity}
                          name={`quantity${seq}`}
                          value={quantity || ''}
                          type="text"
                          maxLength={6}
                          readOnly={i === MaxDeliveries - 1}
                          placeholder={i === deliveries.length - 1 ? deliveryPlaceholder : ''}
                        />
                        {i === deliveries.length - 1 ? null : <div className={css.remove} onClick={() => this.handleRemoveDeliveryClick(i)}>Remove</div>}
                      </div>
                    ))}
                  </div>
                )}
              </FormControlWraper>
            </div>
            {!this.saveMode ? null : renderGraphics(campaign)}
          </div>
          <div className={css.buttons}>
            <Button onClick={this.closeSettingsPopup} loading={loading}>Cancel</Button>
            <SolidButton onClick={this.newMode && templateApplied ? this.handleApplyTemplateClick : this.handleSave} loading={loading}>{this.newMode ? 'Next' : 'Save'}</SolidButton>
          </div>
        </div>
      </Modal>
    );
  }
}

PostcardSettings.propTypes = {
  title: PropTypes.string,
  mode: PropTypes.string,
  onSave: PropTypes.func,
};

PostcardSettings.defaultProps = {
  title: 'Postcard Settings',
  mode: 'settings',
};

const PostcardSettingsPopup = withRouter(connect(state => ({
  campaign: selectPostcardCampaign(state).toJS(),
  templates: selectPostcardTemplates(state),
  loading: selectLoading(state),
  rates: selectPostcardRates(state).toJS(),
}), {
  postcardCampaignChange,
  loadPostcardTemplates,
  getPostcardCampaign,
  confirm: Confirm.open,
  closeAllPopup,
  openPostcardTemplates: PostcardTemplatesPopup.open,
  closePostcardTemplates: PostcardTemplatesPopup.close,
})(PostcardSettings));

const registrationId = getPopupRegistration(PostcardSettingsPopup);
PostcardSettingsPopup.open = (props = {}) => openPopup(registrationId, props);
PostcardSettingsPopup.close = () => closePopup({ popup: registrationId });

export default PostcardSettingsPopup;
