import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { formatDateShort } from 'utils/date/formatDate';
import numberFormat from 'utils/number/format';
import CustomTabs from 'components/CustomTabs';
import { toProperCase } from 'utils/string';
import { phoneFormatter } from 'utils/formatter';
import Button from 'components/Button';
import SVG from 'components/base/SVG';
import Confirm, { type } from 'app/components/Confirm';
import { deleteSavedProperty, selectLoading } from 'data/property';
import { selectListManagementEnabled } from 'data/user';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import ContactNameCellRenderer from './contactNameCellRenderer';
import CampaignNameCellRenderer from './campaignNameCellRenderer';
import GroupNameCellRenderer from './groupNameCellRenderer';
import CampaignContentCellRenderer from './campaignContentCellRenderer';
import SavedPropertyActionCellRenderer from './savedPropertyActionCellRenderer';

import css from './style.scss';


const FieldMap = {
  listQuantity: { field: 'lists' },
  contactAppendQuantity: { field: 'appendedContacts' },
  campaignQuantity: { field: 'campaignItems' },
  voicemailCampaignQuantity: { field: 'campaignItems', type: 'VOICEMAIL' },
  postcardCampaignQuantity: { field: 'campaignItems', type: 'POSTCARD' },
  emailCampaignQuantity: { field: 'campaignItems', type: 'EMAIL' },
};

class DetailCellRenderer extends Component {
  constructor(props) {
    super(props);

    this.handleGridReady = this.handleGridReady.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteProperty = this.handleDeleteProperty.bind(this);

    const { context, node, data: { id } } = props;
    const { openPostcardPreview, detailExpandField, setActiveDetail, groupId } = context;

    setActiveDetail(this);

    this.expandField = FieldMap[detailExpandField || 'listQuantity'];
    this.node = node;

    const dateFormatter = d => formatDateShort(d.value);
    const numberFormatter = d => numberFormat(d.value);
    const properCaseFormatter = d => toProperCase(d.value);
    const phoneCellFormatter = d => phoneFormatter(d.value);

    const gridProps = {
      onGridReady: this.handleGridReady,
      onFirstDataRendered: ({ api }) => { api.sizeColumnsToFit(); },
      getRowNodeId: d => d.id,
      suppressCellSelection: true,
      enableCellTextSelection: true,
      groupDefaultExpanded: 1,
      groupUseEntireRow: true,
      defaultColDef: { resizable: true, filter: false, sortable: true, width: 80 },
      context: {
        id,
        groupId,
        openPostcardPreview,
        handleDeleteProperty: this.handleDeleteProperty,
      },
      frameworkComponents: {
        contactNameCellRenderer: ContactNameCellRenderer,
        campaignNameCellRenderer: CampaignNameCellRenderer,
        groupNameCellRenderer: GroupNameCellRenderer,
        campaignContentCellRenderer: CampaignContentCellRenderer,
        savedPropertyActionCellRenderer: SavedPropertyActionCellRenderer,
      },
    };

    this.tabs = {
      lists: {
        title: 'Marketing Lists',
        gridProps: {
          ...gridProps,
          columnDefs: [
            { headerName: 'Name', field: 'name', width: 80, cellRenderer: 'groupNameCellRenderer' },
            { headerName: 'Date', field: 'createDate', width: 80, valueFormatter: dateFormatter },
            { headerName: '', field: 'savedPropertyId', width: 80, cellRenderer: 'savedPropertyActionCellRenderer', cellClass: css.actions },
          ],
        },
      },

      campaignItems: {
        title: 'Marketing Campaigns',
        gridProps: {
          ...gridProps,
          columnDefs: [
            { headerName: 'Type', field: 'type', rowGroup: true, hide: true, valueFormatter: properCaseFormatter },
            { headerName: 'Campaign Name', field: 'name', cellRenderer: 'campaignNameCellRenderer' },
            { headerName: 'Status', field: 'status', valueFormatter: properCaseFormatter },
            { headerName: 'Date', field: 'publishDate', width: 80, valueFormatter: dateFormatter },
            { headerName: 'Recipients', field: 'recipientTotal', valueFormatter: numberFormatter },
            { headerName: 'Delivered', field: 'deliveredQuantity', valueFormatter: numberFormatter },
            { headerName: 'Content', field: 'previewUrl', cellRenderer: 'campaignContentCellRenderer', autoHeight: true, width: 160 },
          ],
          autoGroupColumnDef: { headerName: 'Campaign Name' },
        },
      },

      appendedContacts: {
        title: 'Skip Traces',
        gridProps: {
          ...gridProps,
          columnDefs: [
            { headerName: 'Name', field: 'name', width: 80, cellRenderer: 'contactNameCellRenderer' },
            { headerName: 'Date', field: 'processedDate', width: 80, valueFormatter: dateFormatter },
            { headerName: 'Landline', field: 'landline', width: 80, valueFormatter: phoneCellFormatter },
            { headerName: 'Cell', field: 'cell', width: 80, valueFormatter: phoneCellFormatter },
            { headerName: 'Cell 2', field: 'cell2', width: 80, valueFormatter: phoneCellFormatter },
            { headerName: 'Email', field: 'email', width: 80 },
            { headerName: 'Email 2', field: 'email2', width: 80 },
            { headerName: 'Email 3', field: 'email3', width: 80 },
          ],
        },
      },
    };

    this.state = { loaded: false, tabs: [], tabIndex: 0 };
  }

  componentWillMount() {
    const { data: { id }, context: { getSavedPropertyDetail }, lmEnabled } = this.props;

    getSavedPropertyDetail(id, ({ response }) => {
      const tabs = (lmEnabled ? ['lists', 'campaignItems', 'appendedContacts'] : ['lists']).filter(field => (response[field] || []).length).map((field) => {
        const tab = this.tabs[field];
        tab.field = field;
        tab.gridProps.rowData = response[field];

        return tab;
      });

      this.setState({ loaded: true, tabs, tabIndex: tabs.findIndex(t => t.field === this.expandField.field) });
    });
  }

  loadField(loadField) {
    this.expandField = FieldMap[loadField];

    const { tabs } = this.state;
    const tabIndex = tabs.findIndex(t => t.field === this.expandField.field);

    if (tabIndex !== this.state.tabIndex) this.setState({ tabIndex });
    else if (tabs[tabIndex].api) this.loadType();
  }

  handleGridReady(field, api) {
    this.tabs[field].api = api;
    this.loadType();
  }

  handleClose() {
    this.node.parent.setExpanded(false);
  }

  loadType() {
    if (this.expandField) {
      const { field, type } = this.expandField;
      this.expandField = null;

      const { api } = this.tabs[field];
      if (api) {
        api.forEachNode((node) => {
          if (!node.level) node.setExpanded(!type || node.key === type);
        });
      }
    }
  }

  handleTabSelect(tabIndex) {
    this.setState({ tabIndex });
  }

  handleDeleteProperty(deleteId, groupName) {
    const { confirm, deleteSavedProperty, context: { search, api }, data: { id }, loading } = this.props;

    if (loading) return;

    const runDelete = (deleteContacts) => {
      // FIXME: Without the timeout it automatically triggers OK action if the OK clicked on contact delete confirmation.
      setTimeout(() => {
        confirm({
          caption: 'Confirm Delete',
          onOk: () => deleteSavedProperty(deleteId, id, deleteContacts, ({ response: { savedProperty: { lists = [] } } }) => {
            this.tabs.lists.api.setRowData(lists);
            if (!lists.length) search(false);
            else api.getRowNode(id).setDataValue('listQuantity', lists.length);
          }),
          okLabel: 'Delete',
          type: type.danger,
          question: `Delete property from "${groupName}"?`,
        });
      }, 1);
    };

    runDelete();
  }

  render() {
    const { loading } = this.props;
    const { loaded, tabs, tabIndex } = this.state;

    return (
      <div className={css.detailContainer}>
        {!loaded ? null : (
          <div className={css.detail}>
            <Button kind="link-default" className={css.btnClose} onClick={this.handleClose}>
              <SVG icon="iconClose" className={css.iconClose} />
            </Button>
            <div className={css.detailInner}>
              <CustomTabs kind="default" className={css.detailTabs}>
                <Tabs selectedIndex={tabIndex} onSelect={this.handleTabSelect}>
                  <TabList>
                    {tabs.map(({ title }) => <Tab key={title}>{title}</Tab>)}
                  </TabList>
                  {tabs.map(({ field, gridProps }) => (
                    <TabPanel key={field}>
                      <div className="ag-theme-balham" style={{ height: '225px', width: '100%' }}>
                        <AgGridReact {...gridProps} onGridReady={({ api }) => this.handleGridReady(field, api)} />
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </CustomTabs>
              <div className={css.buttons}>
                <Button onClick={this.handleClose} loading={loading}>Close Detail</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(state => ({
  loading: selectLoading(state),
  lmEnabled: selectListManagementEnabled(state),
}), {
  confirm: Confirm.open,
  deleteSavedProperty,
})(DetailCellRenderer);
