import React, { PureComponent } from 'react';
import classNames from 'classnames';

import css from './style.scss';
import GraphicDraggable from './draggable';


const anchors = [
  { name: 'topLeft', resizeX: -1, resizeY: -1 },
  { name: 'top', resizeX: 0, resizeY: -1 },
  { name: 'topRight', resizeX: 1, resizeY: -1 },
  { name: 'bottomLeft', resizeX: -1, resizeY: 1 },
  { name: 'bottom', resizeX: 0, resizeY: 1 },
  { name: 'bottomRight', resizeX: 1, resizeY: 1 },
  { name: 'left', resizeX: -1, resizeY: 0 },
  { name: 'right', resizeX: 1, resizeY: 0 },
];

class GraphicSelection extends PureComponent {
  render() {
    const { translate, element: { x, y, width, height } } = this.props;
    const coords = translate({ x, y, width, height });

    return (
      <GraphicDraggable
        {...this.props}
        className={css.selection}
        style={{
          transform: `translateX(${coords.x - 10}px) translateY(${coords.y - 10}px)`,
          width: `calc(${coords.width ? `${coords.width}px` : '100%'} + 20px)`,
          height: `calc(${coords.height ? `${coords.height}px` : '100%'} + 20px)`,
        }}
      >
        <div className={css.anchors}>
          {anchors.map(({ name, resizeX, resizeY }) => (
            <GraphicDraggable
              {...this.props}
              key={name}
              resizeX={resizeX}
              resizeY={resizeY}
              className={classNames(css.anchor, css[name])}
            />
          ))}
        </div>
      </GraphicDraggable>
    );
  }
}

export default GraphicSelection;
