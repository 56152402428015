import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MaskedInput from 'react-maskedinput';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';

import SVG from 'components/base/SVG';
import Confirm from 'app/components/Confirm';
import { selectProfile } from 'data/user/selectors';
import numberToPrice from 'utils/currency/numberToPrice';
import formatNumber from 'utils/number/format';
import displayNewLines from 'utils/displayNewLines';
import UpgradeAccount from 'app/components/UpgradeAccount';
import SelectPaymentMethod from 'app/components/SelectPaymentMethod';
import { CVV_PAYMENT_THRESHOLD } from 'data/user/constants';
import { getPrepaidCredit, selectCardCodeRequired } from 'data/user';
import { inferCardType } from 'utils/payment';
import { verify, verifyRequired, isValidCardCode } from 'utils/validation';

import css from './style.scss';


class CheckoutSummary extends PureComponent {
  constructor(props) {
    super(props);

    this.handleUpgradeClick = this.handleUpgradeClick.bind(this);
    this.handleSelectPaymentMethodClick = this.handleSelectPaymentMethodClick.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRef = this.handleRef.bind(this);

    const { paymentMethodId, paymentMethods } = this.props.profile;

    this.ref = {};
    this.state = {
      cardCode: '',
      paymentMethod: (paymentMethods || []).find(pm => pm.id === paymentMethodId) || {},
    };
  }

  componentDidMount() {
    const { getPrepaidCredit, onLoad } = this.props;
    onLoad({ validate: () => this.validate() });
    getPrepaidCredit();
  }

  getAmounts() {
    const { profile: { remainingPrepaidCredit }, lines } = this.props;

    const total = lines[lines.length - 1].total;
    const creditAmount = Math.min(total, remainingPrepaidCredit);
    const paymentAmount = total - creditAmount;

    return { total, creditAmount, paymentAmount };
  }

  validate() {
    const r = this.ref;
    const { cardCodeRequired } = this.props;
    const { cardCode, paymentMethod: { id: paymentMethodId, cardNumber } } = this.state;
    const { paymentAmount, creditAmount } = this.getAmounts();

    // Need to have validation throw outside this function
    // getPrepaidCredit(() => {
    //   const amounts = this.getAmounts();
    //
    //   this.setState(amounts);
    //
    //   verify(null, amounts.paymentAmount === paymentAmount && amounts.creditAmount === creditAmount, 'Your remaining credit has changes. Please review the payment information and submit the order again.');

    verify(null, !paymentAmount || !!paymentMethodId, 'Please select a payment method.');

    if (r.cardCode) {
      const { codePosition, codeLength } = inferCardType(cardNumber);
      verifyRequired(r.cardCode, cardCode, `Please enter the ${codeLength}-Digit Credit Card CVV number on the ${codePosition} of your card.`);
      verify(r.cardCode, isValidCardCode(cardCode, cardNumber), 'Credit Card CVV is invalid.');
    }

    return { paymentMethodId: paymentAmount ? paymentMethodId : null, cardCode, paymentAmount, creditAmount };
    // });
  }

  handleUpgradeClick() {
    this.props.openUpgradeAccount();
  }

  handleSelectPaymentMethod(paymentMethod) {
    this.setState({ paymentMethod });
  }

  handleSelectPaymentMethodClick() {
    this.props.openSelectPaymentMethod({ onSelect: this.handleSelectPaymentMethod, id: this.state.paymentMethod.id });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleRef(element) {
    if (element) {
      const el = element.tagName ? element : findDOMNode(element);
      this.ref[el.name] = el;
    }
  }

  render() {
    const { profile: { overdueAccount, paymentMethods = [], remainingPrepaidCredit }, lines, rate, instructions, detailHeader, detailSubheader, cardCodeRequired } = this.props;
    const { cardCode, paymentMethod: { id: paymentMethodId, cardName, billingStreetAddress, billingStreetAddress2, billingCity, billingState, billingZip, billingCountry, cardNumber, cardExpMonth, cardExpYear } } = this.state;

    const streetAddress = !billingStreetAddress ? '' : `${billingStreetAddress}\n${(billingStreetAddress2 || '') === '' ? '' : `${billingStreetAddress2}\n`}${billingCity}, ${billingState} ${billingZip}\n${billingCountry === 'US' ? '' : billingCountry}`;
    const { codeMask, codeLength } = inferCardType(cardNumber);
    const { amount, quantityMin, quantityMax } = rate;
    const { paymentAmount, creditAmount } = this.getAmounts();

    return (
      <div className={css.checkoutSummary}>
        <div className={css.paymentTop}>
          <div className={css.rateDescription}>
            <SVG icon="iconIdCard" className={css.rateIcon} />
            <div className={css.rate}>
              <div>{Math.round(amount * 100)}</div>
              <div>
                <div>&cent;</div>
                <div>each</div>
              </div>
            </div>
            {!quantityMin && !quantityMax ? null : <div className={css.quantity}>Quantity {formatNumber(quantityMin)}{quantityMax ? `-${formatNumber(quantityMax)}` : '+'}</div>}
          </div>
          <div className={css.instructions}>{instructions}</div>
        </div>
        <div className={css.paymentBottom}>
          <div className={css.order}>
            <div className={css.smallTitle}>Order Details</div>
            {!detailHeader ? null : <div className={css.contacts}>{detailHeader}</div>}
            {!detailSubheader ? null : <div className={css.omitted}>{detailSubheader}</div>}
            <div>
              {lines.map((line, i) => (
                <div key={line.description} className={classNames(css.lineContainer, { [css.total]: i === lines.length - 1 })}>
                  <div className={css.line}>
                    <span className={css.key}>{line.description}</span>
                    <span className={css.value}>{numberToPrice(line.total)}</span>
                  </div>
                  <div className={css.amount}>quantity: {line.quantity}</div>
                </div>
              ))}
            </div>
          </div>
          {overdueAccount ? (
            <div className={css.overdue}>
              Your subscription is currently past due and this feature is disabled. If you would like to renew your subscription and gain access to the Skip Tracing feature, <span onClick={this.handleUpgradeClick}>Click Here</span>.
            </div>
          ) : (
            <div className={css.billing}>
              {!creditAmount ? null : (
                <div className={css.credit}>You have {numberToPrice(remainingPrepaidCredit)} in pre-paid credits remaining. {numberToPrice(creditAmount)} will be deducted from your prepaid credits{!paymentAmount ? '' : ` and ${numberToPrice(paymentAmount)} will be charged to your card`}.</div>
              )}
              {!paymentAmount ? null : (
                <div>
                  <div className={css.billingTitle}>
                    <div className={css.smallTitle}>Billing Details</div>
                    {paymentMethods.length < 2 ? null : <div className={css.paymentMethod} onClick={this.handleSelectPaymentMethodClick}>Change Payment Method</div>}
                  </div>
                  {!paymentMethodId ? <div>Please select a payment method.</div> : (
                    <div className={css.billingInfo}>
                      <div className={css.billingAddress}>
                        <div className={css.billingName}>{cardName}</div>
                        <div>{displayNewLines(streetAddress)}</div>
                      </div>
                      <div className={css.billingNum}>
                        <div>{cardNumber}</div>
                        <div>Expires {`${cardExpMonth < 10 ? `0${cardExpMonth}` : cardExpMonth}/${cardExpYear}`}</div>
                        {!cardCodeRequired && paymentAmount < CVV_PAYMENT_THRESHOLD ? null : (
                          <div className={css.cvv}>
                            <label htmlFor="cardCode">{codeLength}-Digit CVV:</label>
                            <MaskedInput ref={this.handleRef} onChange={this.handleChange} className={css.input} mask={codeMask} name="cardCode" value={cardCode} />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

CheckoutSummary.propTypes = {
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  rate: PropTypes.number.isRequired,
  instructions: PropTypes.string.isRequired,
  detailHeader: PropTypes.string,
  detailSubheader: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
};

CheckoutSummary.defaultProps = {
};

export default connect(state => ({
  profile: selectProfile(state).toJS(),
  cardCodeRequired: selectCardCodeRequired(state),
}), {
  confirmAction: Confirm.open,
  openUpgradeAccount: UpgradeAccount.open,
  openSelectPaymentMethod: SelectPaymentMethod.open,
  getPrepaidCredit,
})(CheckoutSummary);
