import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import SVG from 'components/base/SVG';

import css from './style.scss';


class Button extends PureComponent {
  render() {
    const { name, loading, disabled, solid, red, iconMode, icon, children, text, className, onRef, onClick, url, target, type, upload, accept, onChange, multiple, download } = this.props;

    const external = url && url.startsWith('http');
    const Anchor = external ? 'a' : Link;

    const anchorProps = external && download ? { download } : {};

    return (
      <button
        ref={onRef}
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={classNames(css.button, { [css.solid]: solid, [css.red]: red, [css.iconMode]: iconMode, [css.loading]: loading }, className)}
      >
        <div className={css.content}>
          {children}
          {!text ? null : <div className={css.text}>{text}</div>}
          {!icon ? null : <SVG icon={icon} />}
        </div>
        <div className={css.spinner}>
          <SVG icon="spinner" />
        </div>
        {!url ? null : <Anchor {...{ [external ? 'href' : 'to']: url }} target={target} {...anchorProps}>&nbsp;</Anchor>}
        {!upload ? null : <input type="file" name={name} accept={accept} onChange={onChange} multiple={multiple} />}
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  loading: PropTypes.bool,
  solid: PropTypes.bool,
  red: PropTypes.bool,
  iconMode: PropTypes.bool,
  onClick: PropTypes.func,
  onRef: PropTypes.func,
  upload: PropTypes.bool,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  download: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  solid: false,
  upload: false,
  multiple: false,
  red: false,
  iconMode: false,
  download: false,
  type: 'button',
};


export default Button;
