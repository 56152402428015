
export default function hex2rbg(color = '') {
  let hex = color.toLowerCase();

  if (hex.startsWith('#')) hex = hex.substr(1);
  if (hex.length === 3) {
    const parts = hex.split('');
    hex = `${parts[0]}${parts[0]}${parts[1]}${parts[1]}${parts[2]}${parts[2]}`;
  }

  const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}

// eslint-disable-next-line no-bitwise
export const rgb2hex = ({ r, g, b }) => `#${Number((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

export const lab2rgb = (lab) => {
  let y = (lab.l + 16) / 116;
  let x = (lab.a / 500) + y;
  let z = y - (lab.b / 200);

  x = 0.95047 * ((x * x * x > 0.008856) ? x * x * x : (x - (16 / 116)) / 7.787);
  y = (y * y * y > 0.008856) ? y * y * y : (y - (16 / 116)) / 7.787;
  z = 1.08883 * ((z * z * z > 0.008856) ? z * z * z : (z - (16 / 116)) / 7.787);

  const r = (x * 3.2406) + (y * -1.5372) + (z * -0.4986);
  const g = (x * -0.9689) + (y * 1.8758) + (z * 0.0415);
  const b = (x * 0.0557) + (y * -0.2040) + (z * 1.0570);

  const component = c => Math.max(0, Math.min(255, Math.round(((c > 0.0031308) ? ((1.055 * (c ** (1 / 2.4))) - 0.055) : 12.92 * c) * 255)));

  return { r: component(r), g: component(g), b: component(b) };
};

export const rgb2lab = (rgb) => {
  let r = rgb.r / 255;
  let g = rgb.g / 255;
  let b = rgb.b / 255;

  r = (r > 0.04045) ? ((r + 0.055) / 1.055) ** 2.4 : r / 12.92;
  g = (g > 0.04045) ? ((g + 0.055) / 1.055) ** 2.4 : g / 12.92;
  b = (b > 0.04045) ? ((b + 0.055) / 1.055) ** 2.4 : b / 12.92;

  let x = ((r * 0.4124) + (g * 0.3576) + (b * 0.1805)) / 0.95047;
  let y = ((r * 0.2126) + (g * 0.7152) + (b * 0.0722)) / 1.00000;
  let z = ((r * 0.0193) + (g * 0.1192) + (b * 0.9505)) / 1.08883;

  x = (x > 0.008856) ? (x ** (1 / 3)) : (7.787 * x) + (16 / 116);
  y = (y > 0.008856) ? (y ** (1 / 3)) : (7.787 * y) + (16 / 116);
  z = (z > 0.008856) ? (z ** (1 / 3)) : (7.787 * z) + (16 / 116);

  return { l: (116 * y) - 16, a: 500 * (x - y), b: 200 * (y - z) };
};

export const brightenColor = (color, { min, max, offset }) => {
  let lab = color;
  let format = 'rgb';

  if (typeof lab === 'string') {
    lab = hex2rbg(lab);
    format = 'hex';
  }

  if ('l' in lab) format = 'lab';
  else lab = rgb2lab(lab);

  const { l } = lab;

  if (typeof offset === 'number') lab.l += offset;
  if (typeof min === 'number') lab.l = Math.max(lab.l, min);
  if (typeof max === 'number') lab.l = Math.min(lab.l, max);

  lab.l = Math.max(0, Math.min(100, lab.l));

  if (l === lab.l) return color;

  if (format !== 'lab') {
    lab = lab2rgb(lab);
    if (format !== 'rgb') lab = rgb2hex(lab);
  }

  return lab;
};

// // calculate the perceptual distance between colors in CIELAB
// // https://github.com/THEjoezack/ColorMine/blob/master/ColorMine/ColorSpaces/Comparisons/Cie94Comparison.cs
//
// function deltaE(labA, labB){
//   var deltaL = labA[0] - labB[0];
//   var deltaA = labA[1] - labB[1];
//   var deltaB = labA[2] - labB[2];
//   var c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
//   var c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
//   var deltaC = c1 - c2;
//   var deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
//   deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
//   var sc = 1.0 + 0.045 * c1;
//   var sh = 1.0 + 0.015 * c1;
//   var deltaLKlsl = deltaL / (1.0);
//   var deltaCkcsc = deltaC / (sc);
//   var deltaHkhsh = deltaH / (sh);
//   var i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
//   return i < 0 ? 0 : Math.sqrt(i);
// }
