// @flow
export default class AbortAttempt {
  constructor(unifier, allQueue) {
    this.unifier = unifier;
    this.allQueue = allQueue;
  }

  unifier;
  allQueue;

  getUnifier() {
    return this.unifier;
  }

  shouldAbortAll() {
    return this.allQueue;
  }
}
