import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import css from './style.scss';


class Breadcrumb extends PureComponent {
  render() {
    const { isPast, isActive, name, link, disabled } = this.props;
    const className = classNames(css.link, { [css.past]: isPast, [css.active]: isActive, [css.disabled]: disabled });
    const content = disabled ? name : <Link to={link}>{name}</Link>;

    return (
      <li className={className}>
        {content}
      </li>
    );
  }
}

export default Breadcrumb;
