import classNames from "classnames";
import React from "react";
import PropTypes from 'prop-types';
import css from "./style.scss";

const Label = (props) => {
  const { children, background, size, ...rest } = props;
  const labelClass = classNames(css[background], css[size], css.label);
  return (
    <div {...rest} className={labelClass}>
      {children}
    </div>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["normal", "big"]).isRequired,
};

Label.defaultProps = {
  size: "normal",
  background: "#E3EEFF",
};

export default Label;
