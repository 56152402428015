import Link from './Link';
import RedLink from './RedLink';
import BlueLink from './BlueLink';
import ThemeLink from './ThemeLink';
import UploadLink from './UploadLink';


export default Link;
export {
  RedLink,
  BlueLink,
  ThemeLink,
  UploadLink,
};
