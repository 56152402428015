import { createSelector } from 'reselect';
import { List } from 'immutable';
import { ReportTypes, Reports, Permissions } from './constants';


const emptyList = List();

const checkState = state => state;

export const selectUser = createSelector(
  checkState,
  state => state.get('user'),
);

export const selectProfile = createSelector(
  selectUser,
  user => user.get('profile'),
);

export const getMarketingUrl = (profile, type) => profile.get(`${type.name.toLowerCase()}Url`);

export const selectPermissions = createSelector(
  selectUser,
  user => user.get('permissions'),
);

export const selectFullAccessPermission = createSelector(
  selectPermissions,
  permissions => !!(permissions && permissions.includes(Permissions.searchFull)),
);

export const selectPages = createSelector(
  selectUser,
  user => user.get('pages', emptyList),
);

export const selectReports = createSelector(
  selectUser,
  user => user.get('reports', emptyList).sort((a, b) => a.get('seq') - b.get('seq')),
);

export const selectAlerts = createSelector(
  selectUser,
  user => user.get('alerts', emptyList),
);

export const selectPremiumReports = createSelector(
  selectReports,
  reports => reports.filter(report => report.get('type') === ReportTypes.premium),
);

export const selectPropertyReports = createSelector(
  selectReports,
  reports => reports.filter(report => (report.get('type') === ReportTypes.property || report.get('type') === ReportTypes.analysis) && report.get('code') !== Reports.comparativeMarketAnalysis),
);

export const selectAnalysisReports = createSelector(
  selectReports,
  reports => reports.filter(report => report.get('type') === ReportTypes.analysis),
);

export const selectIsAuthed = createSelector(
  selectUser,
  user => user.get('isAuthed'),
);

export const selectIsLoading = createSelector(
  selectUser,
  user => user.get('isLoading'),
);

export const selectLoading = createSelector(
  selectUser,
  user => user.get('loading'),
);

export const selectAlertLoading = createSelector(
  selectUser,
  user => user.get('alertLoading'),
);

export const selectOffers = createSelector(
  selectUser,
  user => user.get('offers'),
);

export const selectOfferLoading = createSelector(
  selectUser,
  user => user.get('offerLoading'),
);

export const selectUsers = createSelector(
  selectUser,
  user => user.get('users'),
);

export const selectTransactions = createSelector(
  selectUser,
  user => user.get('transactions'),
);

export const selectLayouts = createSelector(
  selectUser,
  user => user.get('layouts'),
);

export const selectDuplicateSession = createSelector(
  selectUser,
  user => user.get('duplicateSession'),
);

export const selectLoggedOut = createSelector(
  selectUser,
  user => user.get('loggedOut'),
);

export const selectChatVisible = createSelector(
  selectUser,
  user => user.get('chatVisible'),
);

export const selectLogoutMessage = createSelector(
  selectUser,
  user => user.get('logoutMessage'),
);

export const selectPropertyGridLayout = createSelector(
  selectUser,
  user => user.get('pages').find(p => p.get('type') === 'GRID' && p.get('code') === 'SAVED_PROPERTY'),
);

export const selectPropertyDetailLayouts = createSelector(
  selectUser,
  user => user.get('pages').filter(p => p.get('type') === 'PROPERTY'),
);

export const selectCardCodeRequired = createSelector(
  selectUser,
  user => user.get('cardCodeRequired'),
);

export const selectListManagementEnabled = createSelector(
  selectProfile,
  profile => profile.get('listManagementEnabled'),
);

export const selectPabEnabled = createSelector(
  selectProfile,
  profile => profile.get('pabEnabled'),
);
