import React, { PureComponent } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';

import CustomTabs from 'components/CustomTabs';
import Modal from 'components/base/Modal';
import Button from 'components/base/Button';
import SVG from 'components/base/SVG';
import Breadcrumbs from 'components/base/Breadcrumbs';
import { LoadableDropdown } from 'components/base/Dropdown';

import TabAnalysis from './TabAnalysis/index';
import TabInformation from './TabInformation/index';
import TabReports from './TabReports/index';
import Purchase from './TabContent/Purchase/index';
import Mortgage from './TabContent/Mortgage/index';
import MortgageList from './TabContent/MortgageList/index';
import Income from './TabContent/Income/index';
import Expenses from './TabContent/Expenses/index';
import Sale from './TabContent/Sale/index';
import Finish from './TabContent/Finish/index';
import css from './style.scss';


class ModalAnalysis extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAnalysisModal: true
    }
    this.closeAnalysisModal = this.closeAnalysisModal.bind(this)
  }
  componentWillReceiveProps() {
    this.setState({ showAnalysisModal: true })
  }
  renderContent() {
    const {
      breadcrumbs,
      mortgageEditIndex,
      activeStepIndex,
      openDefaultRoute,
      savedPropertyId,
      onParamsChange,
      onBooleanFieldChange,
      onDetailsChange,
      onNumberParamsChange,
      onManualTotalChange,
      analysisParams,
    } = this.props;
    const props = {
      analysisParams,
      onParamsChange,
      onBooleanFieldChange,
      onDetailsChange,
      onNumberParamsChange,
      onManualTotalChange,
      savedPropertyId,
      openDefaultRoute,
      disabled: breadcrumbs[activeStepIndex].disabled,
    };

    switch (activeStepIndex) {
      case 0:
        return <Purchase {...props} />;
      case 1:
        return props.analysisParams.mortgageEditIndex == null ? (
          <MortgageList {...props} />
        ) : (
          <Mortgage {...props} mortgageEditIndex={mortgageEditIndex} />
        );
      case 2:
        return <Income {...props} />;
      case 3:
        return <Expenses {...props} />;
      case 4:
        return <Sale {...props} />;
      case 5:
        return <Finish {...props} />;
      default:
        return null;
    }
  }
  closeAnalysisModal() {
    const { property } = this.props;
    const current_location = location.pathname
    const url = current_location.includes('/search') ? `search` : `property/group`; 
    this.setState({ showAnalysisModal: false })
    this.props.closePopup()
    var newUrl = window.location.protocol + "//" + window.location.host + `/${url}/${property.get('id')?.toString()}`;
    this.props.closePopup()
    window.history.pushState({ path:newUrl }, '', newUrl);
  }
  render() {
    const {
      onNextClick,
      breadcrumbs,
      nextEnabled,
      tabLink,
      analysesOptions,
      isAnalysesLoading,
      handleAnalysisSaveClick,
      onNewAnalysisClick,
      onAnalysisRenameClick,
      onAnalysisDeleteClick,
      backEnabled,
      onPreviousClick,
      onActiveAnalysisChange,
      activeAnalysisId,
      analysisParams,
      activeStepIndex,
    } = this.props;

    const header = (
      <div className={css.header}>
        <div className={css.headerSearch}>
          <div className={css.analysesColumn}>
            <LoadableDropdown
              label="Saved Analysis"
              id="analyses"
              options={analysesOptions}
              isLoading={isAnalysesLoading}
              loaderSize="tiny"
              loaderDirection="horizontal"
              onChange={onActiveAnalysisChange}
              value={activeAnalysisId}
            />
          </div>
          <Button
            kind={Button.kind.blue}
            size={Button.size.middle}
            name="rename"
            onClick={onAnalysisRenameClick}
            disabled={!activeAnalysisId}
          >
            Rename
          </Button>
          <Button
            kind={Button.kind.blue}
            size={Button.size.middle}
            name="delete"
            onClick={onAnalysisDeleteClick}
            disabled={!activeAnalysisId}
          >
            Delete
          </Button>
        </div>
        <div className={css.buttons}>
          <Link to={tabLink} kind="link-default" className={css.buttonExport} name="export" target="_blank">
            <SVG icon="iconExport" className={css.iconExport} />
          </Link>
        </div>
      </div>);

    const content = this.renderContent();

    return (
      <Modal isOpen={this.state.showAnalysisModal} onClose={this.closeAnalysisModal} isCloseButton uniqId="modalAnalysis" caption={header} width="1300px" padding="0">
        <div className={css.body}>
          <div className={css.bodyLeft}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            {content}
          </div>
          <div className={css.bodyRight}>
            <CustomTabs>
              <Tabs>
                <TabList>
                  <Tab>Analysis</Tab>
                  <Tab>Information</Tab>
                  <Tab>PDF Reports</Tab>
                </TabList>
                <TabPanel>
                  <TabAnalysis analysisParams={analysisParams} />
                </TabPanel>
                <TabPanel>
                  <TabInformation analysisParams={analysisParams} activeStepIndex={activeStepIndex} />
                </TabPanel>
                <TabPanel>
                  <TabReports analysisParams={analysisParams} />
                </TabPanel>
              </Tabs>
            </CustomTabs>
          </div>
        </div>
        <div className={css.footer}>
          <div className={css.footerLeft}>
            <Button kind={Button.kind.blue} size={Button.size.middle} name="back" onClick={onPreviousClick} disabled={!backEnabled}>{'< Back'}</Button>
            <Button kind={Button.kind.blue} size={Button.size.middle} name="next" onClick={onNextClick} disabled={!nextEnabled}>{'Next >'}</Button>
          </div>
          <div className={css.footerRight}>
            <Button kind={Button.kind.blueGhost} size={Button.size.large} onClick={onNewAnalysisClick}>Start New Analysis</Button>
            <Button kind={Button.kind.blueGhost} size={Button.size.large} onClick={handleAnalysisSaveClick}>Save</Button>
          </div>
        </div>
      </Modal>
    );
  }
}


export default ModalAnalysis;
