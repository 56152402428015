// @flow
import * as React from 'react';
import LoadablePanel from 'components/hoc/loadable/Panel';

import SVG from 'components/base/SVG';

const Overlay = ({
  children,
  className,
  hideButton,
  buttonClassName,
  icon,
  iconClassName,
  triggerOverlay,
  overlayType,
  loading,
}) => {
  return (
    <div className={className}>
      {!hideButton && (
        <button className={buttonClassName} onClick={() => triggerOverlay(overlayType)}>
          <SVG icon={icon} className={iconClassName} />
        </button>
      )}
      <LoadablePanel loading={loading}>
        {children}
      </LoadablePanel>
    </div>
  );
}

export default Overlay;
