export const stdTimezoneOffset = () => {
  const year = new Date().getFullYear();
  return Math.max(new Date(year, 0, 1).getTimezoneOffset(), new Date(year, 6, 1).getTimezoneOffset());
};

export const isDstObserved = (date = new Date()) => date.getTimezoneOffset() < stdTimezoneOffset();

const startOffset = (isDstObserved() ? 7 : 8) * 60;
export const timezones = [
  { value: 'PST', label: 'Pacific Time' },
  { value: 'MST', label: 'Mountain Time' },
  { value: 'CST', label: 'Central Time' },
  { value: 'EST', label: 'Eastern Time' },
].map((z, i) => ({ ...z, def: false, offset: startOffset - (i * 60) }));

const localOffset = new Date().getTimezoneOffset();
timezones[Math.max(timezones.findIndex(z => z.offset === localOffset), 0)].def = true;

export const getDefaultTimezone = () => timezones.find(z => z.def);

/*
function isStandardTime(date) {
  const thisYear = new Date(date).getFullYear();
  const firstOfMarch = new Date(thisYear, 2, 1, 2);
  const firstOfNovember = new Date(thisYear, 10, 1, 2);
  const firstOfMarchDayOfWeek = firstOfMarch.getDay();
  const firstOfNovemberDayOfWeek = firstOfNovember.getDay();
  const daysUntilSecondSundayOfMarch = ((14 - firstOfMarchDayOfWeek) % 7) + 8;
  const daysUntilFirstSundayOfNovember = ((7 - firstOfNovemberDayOfWeek) % 7) + 1;
  const secondSundayOfMarch = new Date(thisYear, 2, daysUntilSecondSundayOfMarch, 2).getTime();
  const firstSundayOfNovember = new Date(thisYear, 10, daysUntilFirstSundayOfNovember, 2).getTime();

  if (date > secondSundayOfMarch && date < firstSundayOfNovember) return false;
  return true;
}

const GMTStandard = [
  { label: 'Eastern Time', value: -300 },
  { label: 'Central Time', value: -360 },
  { label: 'Mountain Time', value: -420 },
  { label: 'Pacific Time', value: -480 },
];
const GMTDaylight = [
  { label: 'Eastern Time', value: -240 },
  { label: 'Central Time', value: -300 },
  { label: 'Mountain Time', value: -360 },
  { label: 'Pacific Time', value: -420 },
];
const scheduledLocalTime = moment(getDate());
const GMT = isStandardTime(scheduledLocalTime.unix() * 1000) ? GMTStandard : GMTDaylight;
const time = '14:00';
const dateTime = getISOString(
  (new Date()).toISOString().split('T')[0],
  Number(GMT[0].value),
  Number(time[0]),
  Number(time[1]),
  '',
);
*/
