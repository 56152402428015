import number from 'utils/number/format';


export function withoutPrefix(number, defaultVal = null, options) {
  if (number === undefined || number === null || number === '' || isNaN(String(number))) return defaultVal;

  const opts = options || {};
  let num = Number(number).toLocaleString('en-US', Object.assign({ style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }, opts));
  if (!opts.forceDecimal) num = num.replace(/\.00$/, '');

  return num;
}

/** @module utils/currency/numberToPrice */
export default function numberToPrice(number, defaultVal = null, options) {
  if (isNaN(String(number))) return defaultVal;
  return `$${withoutPrefix(number, defaultVal, options)}`;
}

export function formatBigNumbers(value) {
  if (Math.abs(value) >= 10 ** 6) {
    return `${number(value / (10 ** 6), '', { maximumFractionDigits: 0 })}M`;
  }
  if (Math.abs(value) >= 10 ** 3) {
    return `${number(value / (10 ** 3), '', { maximumFractionDigits: 0 })}K`;
  }
  return value;
}
