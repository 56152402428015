import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { StatisticPeriods, StatisticColors, updateStatisticsFilter, exportStatistics } from 'data/search';

import Chart from 'components/Chart';
import SVG from 'components/base/SVG';
import Button from 'components/base/Button';
import formatPercent from 'utils/percent/formatPercent';

import Trend from './Trend';
import css from './style.scss';


class StatisticCharts extends PureComponent {

  constructor(props) {
    super(props);

    this.onParamsChange = this.onParamsChange.bind(this);
    this.exportAreaStatistics = this.exportAreaStatistics.bind(this);
  }

  onParamsChange(event) {
    const { name, value } = event.target || event;

    return this.props.updateStatisticsFilter({ [name]: value });
  }

  exportAreaStatistics() {
    const { statistic, exportStatistics } = this.props;

    exportStatistics(statistic.get('location').toJS(), statistic.get('filter').toJS());
  }

  render() {
    const { statistic } = this.props;

    const data = statistic.get('data');
    const filter = statistic.get('filter');
    const location = statistic.get('filter');
    const {
      title = '',
      oneMonthPriceChange,
      oneMonthRentChange,
      marketTrends = [],
      listingTrends = [],
      salesTrends = [],
      pricePerSquareFootGraph = {},
      averageDaysOnMarketGraph = {},
      averageMonthlyRentGraph = {},
      newPreForeclosuresGraph = {},
      listPriceVsSalePriceGraph = {},
      daysOnMarketVsInventoryGraph = {},
    } = statistic.get('data').toJS();

    const props = { className: css.item };
    const periodProps = { ...props, periods: StatisticPeriods, onPeriodChange: this.onParamsChange };
    const colorProps = { ...props, colors: StatisticColors, chartStyle: Chart.chartStyle.type4 };
    const longChart = { className: classNames(css.item, css.longTrend) };

    const changes = [
      { item: oneMonthPriceChange, label: 'Last 30 Days Price Change' },
      { item: oneMonthRentChange, label: 'Last 30 Days Rent Change' },
    ];

    return (
      <section className={css.statistics}>
        <div className={css.row}>
          <div className={css.title}>{title}</div>
          <Button kind={Button.kind.link} className={css.exportButton} onClick={this.exportAreaStatistics} isLoading={statistic.get('loading')} disabled={!location || title.includes('not available')}>
            <SVG icon="iconPrint" className={css.iconExport} />
          </Button>
        </div>
        <div className={css.row}>
          <div className={classNames(css.item, css.chartInfo)}>
            <div className={css.statWraper}>
              {changes.map(({ item, label }) => (
                <div key={label} className={css.statItem}>
                  {!item ? <span className={css.value}>&#8212;</span> : (
                    <div className={css.statItemValue}>
                      <div className={css.icon}>
                        <SVG icon="iconArrowUp" className={classNames(css.arrow, { [css.arrowDown]: item.direction === 'down' })} />
                      </div>
                      <span className={css.value}>{formatPercent(item.percents, '', { maximumFractionDigits: 2 })}</span>
                    </div>
                  )}
                  <span className={css.label}>{label}</span>
                </div>
              ))}
            </div>
          </div>
          <Chart caption="$/SqFt" {...props} data={data.getIn(['pricePerSquareFootGraph', 'points'])} type={pricePerSquareFootGraph.type} categories={data.getIn(['pricePerSquareFootGraph', 'categories'])} yTickFormat={Chart.yTickFormat.currency} />
          <Chart caption="Average Days on Market" {...props} xTickFormat={Chart.xTickFormat.bedroom} data={data.getIn(['averageDaysOnMarketGraph', 'points'])} type={averageDaysOnMarketGraph.type} chartStyle={Chart.chartStyle.bedroom} categories={data.getIn(['averageDaysOnMarketGraph', 'categories'])} />
        </div>
        <div className={css.row}>
          <Trend title="Market Trend" {...periodProps} items={marketTrends} currentPeriod={filter.get('marketTrendGrowthPeriod')} periodName="marketTrendGrowthPeriod" />
          <Chart caption="New Pre-Foreclosures" {...props} data={data.getIn(['newPreForeclosuresGraph', 'points'])} type={newPreForeclosuresGraph.type} categories={data.getIn(['newPreForeclosuresGraph', 'categories'])} />
          <Chart caption="Average Monthly Rent" {...props} xTickFormat={Chart.xTickFormat.bedroom} data={data.getIn(['averageMonthlyRentGraph', 'points'])} type={averageMonthlyRentGraph.type} chartStyle={Chart.chartStyle.bedroom} categories={data.getIn(['averageMonthlyRentGraph', 'categories'])} yTickFormat={Chart.yTickFormat.currency} />
        </div>
        <div className={css.row}>
          <Trend title="Listing Trend" {...periodProps} items={listingTrends} currentPeriod={filter.get('listingTrendGrowthPeriod')} periodName="listingTrendGrowthPeriod" {...longChart} />
          <Chart caption="List Price vs. Sale Price" {...colorProps} data={data.getIn(['listPriceVsSalePriceGraph', 'points'])} type={listPriceVsSalePriceGraph.type} categories={data.getIn(['listPriceVsSalePriceGraph', 'categories'])} yTickFormat={Chart.yTickFormat.currency} />
        </div>
        <div className={css.row}>
          <Trend title="Sales Trend" {...periodProps} items={salesTrends} currentPeriod={filter.get('salesTrendGrowthPeriod')} periodName="salesTrendGrowthPeriod" {...longChart} />
          <Chart caption="Days on Market vs. Inventory" {...colorProps} data={data.getIn(['daysOnMarketVsInventoryGraph', 'points'])} type={daysOnMarketVsInventoryGraph.type} categories={data.getIn(['daysOnMarketVsInventoryGraph', 'categories'])} />
        </div>
      </section>
    );
  }
}


export default connect(null, {
  updateStatisticsFilter,
  exportStatistics,
})(StatisticCharts);
