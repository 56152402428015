import moment from "moment";
import { formatDateShortWithUTC } from "../../app/Search/Header/searchFilterNew/shared/ConstantsNew";

export const generateMinMaxValue = (minValue, maxValue) => {
    const hasMinValue = minValue !== undefined && minValue !== null && minValue !== '';
    const hasMaxValue = maxValue !== undefined && maxValue !== null && maxValue !== '';
  
    if (hasMinValue && hasMaxValue) {
      return `${minValue} to ${maxValue}`;
    } else if (hasMinValue) {
      return `${minValue} or more`;
    } else if (hasMaxValue) {
      return `${maxValue} or fewer`;
    } else {
      return '';
    }
  };


  export const getMinMaxValuesToUpdate = (minValue, maxValue, key) => {
    return [
      { key: `${key}Min`, value: minValue ? minValue : null },
      { key: `${key}Max`, value: maxValue ? maxValue : null }
    ];
  };


  export const generateMinMaxDateValue = (minValue, maxValue) => {
    let name;
    const hasMinValue = minValue !== undefined && minValue !== null && minValue !== '';
    const hasMaxValue = maxValue !== undefined && maxValue !== null && maxValue !== '';
    const formatDate = (date) => formatDateShortWithUTC(moment(date));
    if (hasMinValue && hasMaxValue) {
      name = `${formatDate(minValue)} to ${formatDate(maxValue)}`;
    } else if (hasMinValue) {
      name = `${formatDate(minValue)} or later`;
    } else if (hasMaxValue) {
      name = `${formatDate(maxValue)} or earlier`;
    } else {
      name = ''; 
    }
    return name
  };

  export const getMinMaxDateToUpdate = (minValue, maxValue, key) => {
    return [
      { key: `${key}Min`, value: minValue ? moment(minValue) : null},
      { key: `${key}Max`, value: maxValue ? moment(maxValue) : null }
    ];
  };
