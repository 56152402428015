import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from './Button';

import css from './style.scss';


class ButtonImport extends PureComponent {
  render() {
    const { children, name = `file${Math.random()}`, kind, size, isLoading, accept, className, onChange, ...rest } = this.props;
    const btnClass = classNames(css[kind], css[size], css.importButton, className, { [css.loading]: isLoading });

    return (
      <div
        className={btnClass}
        {...rest}
      >
        <input
          type="file"
          className={css.importInput}
          name={name}
          id={name}
          accept={accept}
          onChange={onChange}
          multiple={this.props.multiple}
        />
        {children}
      </div>
    );
  }
}

ButtonImport.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.string.isRequired,
  name: PropTypes.string,
  accept: PropTypes.string,
};

ButtonImport.kind = Button.kind;
ButtonImport.size = Button.size;

ButtonImport.defaultProps = {
  kind: Button.kind.grayGhost,
  size: Button.size.middle,
};

export default ButtonImport;
