import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import reduxForm from 'components/ReduxForm';

import Layout from './Layout';

const FORM_NAME = 'my-cool-form (can be generated but not recommended way)';

const { Form, selectField } = reduxForm(FORM_NAME);

const MyCoolForm = ({ handleSubmit }) => {
  const submitHandler = () => {
    debugger; // eslint-disable-line no-debugger
    handleSubmit();
  };

  return (
    <Form onSubmit={submitHandler}>
      <Layout />
    </Form>
  );
};

export default connect(state => ({
  myField1: selectField(state, 'myField1'),
  myField2: selectField(state, 'myField2'),
}), null)(MyCoolForm);
