import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RestrictedContent from 'app/components/RestrictedContent';
import { Permissions } from 'data/user/constants';
import config from 'config';

import css from './style.scss';

const MyBluebookButton = ({ checkSavedProperty }) => {
  const handleSubmit = ev => {
    ev.preventDefault();
    ev.stopPropagation();

    const form = ev.target;
    const { constants: { API: { BASE_URL } } } = config;

    checkSavedProperty(id => {
      form.action = `${BASE_URL}/resource/auth/ps4/user/properties/${id}/bluebook/directaccess/url?redirect=true`;
      form.submit();
    });
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <RestrictedContent permission={Permissions.rehabView}>
        <form method="GET" target="mybluebook" onSubmit={handleSubmit}>
          <input type="hidden" name="redirect" value="true" />
          <button id="rehab-calculator" type="submit" className={css.buttonRehabCalculator} icon="iconCalculator">
            Rehab Calculator
          </button>
        </form>
      </RestrictedContent>
    </div>
  );
};

MyBluebookButton.propTypes = {
  checkSavedProperty: PropTypes.func.isRequired,
};

export default connect()(MyBluebookButton);
