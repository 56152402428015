import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormControl, FormGroup, Label, Col } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import Confirm from 'app/components/Confirm';
import RestrictedContent from 'app/components/RestrictedContent';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { dropdownable } from 'components/base/Dropdown';
import { SearchNotificationIntervals, saveSearch, selectFavoriteSearchSlotOptions, selectFilter, selectLocation, selectSavedSearchLoading } from 'data/search';
import { Permissions } from 'data/user/constants';
import css from '../style.scss';
import newsearchfilter from './SearchFilterNew.scss';
import inputStyle from './shared/style.scss';
import SelectAllCheck from '../../../../assets/images/check-green.svg';
import AutoSearchDropdown from './shared/Autosearch';
import { NotificationIntervalsOptions } from './shared/ConstantsNew';
import ToggleList from './shared/ToggleSection/ToggleList';
import { useEffect } from 'react';

const defaultState = {
  infoOpen: false,
  favoriteEnabled: false,
  name: '',
  description: '',
  interval: null,
  slot: 1,
};

const NewUIFilterSaved = ({ isOpen, onRef, onClick }) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectSavedSearchLoading);
  const location = useSelector(selectLocation);
  const filter = useSelector(selectFilter);
  const slots = useSelector(selectFavoriteSearchSlotOptions);

  const [state, setState] = useState(defaultState);
  const { infoOpen, favoriteEnabled, name, description, interval, slot } = state;
  const saveDropdownRef = useRef(null);

  const handleShowHome = () => {
    setState({ ...state, infoOpen: true });
  };

  const handleHideHome = () => {
    setState({ ...state, infoOpen: false });
  };

  const handleChange = (ev) => {
    // console.log(ev)
    const { name, value, type, checked } = ev.target || ev;
    setState({ ...state, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSlotChange  = (selectedOption) =>  {
    let slot = slots.find(s => s.label === selectedOption);
    setState({ ...state, slot : slot.value });
    // console.log(selectedOption)
  }

  const handleEmailChange  = (selectedOption) =>  {
    // console.log(selectedOption)
    setState({ ...state, interval : selectedOption.value });
    // console.log(selectedOption)
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const s = slots.find(s => s.value === Number(slot));

    const save = () => {
      dispatch(saveSearch({ ...filter.toJS(), ...(location ? location.toJS() : {}), name, description, notificationInterval: interval, slot: favoriteEnabled ? slot : null }));
      setState(defaultState);
      setLocalIsOpen(false);
    };

    if (name.trim() === '') {
      dispatch(Confirm.open({ question: 'Please enter a name.', cancelLabel: null }));
    } else if (favoriteEnabled && s.id) {
      dispatch(Confirm.open({ question: `Do you want to replace "${s.label}" with "${name}"?`, onOk: save }));
    } else {
      save();
    }
  };

  const disabled = loading;

  const [isIncluded, setIsIncluded] = useState(false);
  const [slotOptions, setSlotOptions] = useState(false);

  const handleClick = () => {
    setIsIncluded(!isIncluded);
    setState({ ...state, favoriteEnabled : !isIncluded });
    
  };

  const handleMenuClick = event => {
    event.stopPropagation(); // Prevent click event from propagating to document click listener
  };

  useEffect(() => {

    if (slots && slots?.length > 0) {
      const updatedOptions = slots.map(type => ({
        id: type.id,
        name: type.label,
        value: type.label,
        label: type.label,
      }));
      setSlotOptions(updatedOptions)
    } else {
      setSlotOptions([])
    }

  }, [slots])

  const [localIsOpen, setLocalIsOpen] = useState(isOpen); // Local state for dropdown visibility

  useEffect(() => {
    setLocalIsOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (saveDropdownRef.current && !saveDropdownRef.current.contains(event.target)) {
        setLocalIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const closeDropdown = (event) => {
    event.stopPropagation();
    setLocalIsOpen(false);
  };

  const openDropdown = (event) => {
    // event.stopPropagation();
    setLocalIsOpen(!localIsOpen);
  };

  return (
    <div className={css.savedSearch} name="dropdown-btn" ref={saveDropdownRef}>
      <RestrictedContent permission={Permissions.searchFull}>
        <Button className={css.buttonSave} kind="border-blue" size="large" disabled={disabled} onClick={openDropdown} isLoading={loading}>Save</Button>
      </RestrictedContent>
      {!localIsOpen ? null : (
        <div className={classNames(css.dropdown, css.clsSaveSearchPopupDropdown)} ref={onRef}>
          <div className={newsearchfilter.clsSaveSearchPopup}>
            <div className={newsearchfilter.clsSaveSearchPopupInnerScroll}>
              <h2 className={classNames(newsearchfilter.clsSaveSearchPopupHeading, newsearchfilter.pb_24)}>Saved Search</h2>

              <Form onSubmit={handleSubmit}>
                <FormGroup className={classNames(newsearchfilter.pb_24, newsearchfilter.m_0)}>
                  <Label className={newsearchfilter.clsLabel}>Name</Label>
                  <FormControl type="text" placeholder="Text Field" className={newsearchfilter.clsFormControl}
                    name="name" value={name} onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup className={classNames(newsearchfilter.pb_24, newsearchfilter.m_0)}>
                  <Label className={newsearchfilter.clsLabel}>Details</Label>
                  <textarea rows={3} className={newsearchfilter.clsFormControl} placeholder="Text Field"
                    name="description" value={description} onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup className={classNames(newsearchfilter.pb_24, newsearchfilter.m_0)}>

                {isIncluded ? (
                  <>
                    <Button
                      kind="link-default"
                      className={classNames(newsearchfilter.selctAllBtn, newsearchfilter.checkSelctedBtn)}
                      size="large"
                      onClick={handleClick}
                    >
                      <span className={classNames(newsearchfilter.selctAllCrle, newsearchfilter.checkSelctCrle)}>
                        <ReactSVG src={SelectAllCheck} className={newsearchfilter.svgCheckIcon} />
                      </span>
                      Include on Home Screen
                    </Button>
                    <Col className={inputStyle.clsCol}>
                      <h4 className={inputStyle.clsSubHeading}>&nbsp;</h4>
                      <div onClick={handleMenuClick} className={classNames(inputStyle.flexBoxNowrap, inputStyle.clsFilterSelect)}>
                        <AutoSearchDropdown items={slotOptions} onChangeValue={handleSlotChange} 
                        searchDisabled={true}
                        />

                        </div>
                      </Col>

                    </>
                  ) : (
                    <>

                      <Button
                        kind="link-default"
                        className={newsearchfilter.selctAllBtn}
                        size="large"
                        onClick={handleClick}
                      >
                        <span className={classNames(newsearchfilter.selctAllCrle, newsearchfilter.unSelctCrle)}></span>
                        Include on Home Screen
                      </Button>
                    </>

                  )}

                </FormGroup>
                <FormGroup className={classNames(newsearchfilter.pb_24, newsearchfilter.m_0)}>
                  <Label className={newsearchfilter.clsLabel}>Email when new properties become available</Label>

                  <ToggleList
                    headerTitle={''}
                    headerTitleShow={false}
                    backGroudGray={false}
                    selected={''}
                    options={NotificationIntervalsOptions}
                    onSelectOption={(selectedOption) => { handleEmailChange(selectedOption) }
                    }
                  />

                </FormGroup>
                <div className={newsearchfilter.text_right}>
                  <Button kind="link-default" className={classNames(newsearchfilter.clsPopupBtn, newsearchfilter.clsCancelBtn)} size="large" onClick={closeDropdown}> Cancel</Button>
                  <Button kind="link-default" className={classNames(newsearchfilter.clsPopupBtn, newsearchfilter.clsSaveBtn)} size="large" type="submit"> Save</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default dropdownable(NewUIFilterSaved);
