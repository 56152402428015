import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Checkbox from 'components/base/Checkbox';

import { SolidButton } from 'components/Button';
import { selectProfile, savePreliminaryData, selectLoading } from 'data/user';
import { defaultPaymentMethod } from 'data/user/reducer';
import { removeAllErrors } from 'data/errors';
import { getBrandTermsUrl, getBrandLogoUrl } from 'utils/brand';
import { validate, verify } from 'utils/validation';
import PaymentMethod from '../PaymentMethod';

import css from './style.scss';

const PreliminaryOverlay = ({ profile, loading, savePreliminaryData, removeAllErrors }) => {
  const [message, setMessage] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({
    ...defaultPaymentMethod,
    ...(process.env.NODE_ENV !== 'dev-server' ? {} : {
      cardNumber: '4111111111111111',
      cardCode: '411',
      cardName: 'test card',
      cardExpMonth: 12,
      cardExpYear: 2029,
      billingStreetAddress: '12345 Main St',
      billingCity: 'City',
      billingState: 'CA',
      billingZip: '41111',
    }),
  });

  const handleSaveClick = () => {
    const { appPaymentMethodRequired } = profile;

    validate(() => {
      verify(null, agreedToTerms, 'You must agree to the Terms and Conditions.');

      if (appPaymentMethodRequired) paymentMethodRef.validate();

      savePreliminaryData({ agreedToTerms, paymentMethod: appPaymentMethodRequired ? paymentMethod : null }, ({ response: { body: { userMessage } } }) => {
        setMessage(userMessage);
        removeAllErrors();
      });
    });
  };

  const handleChange = (ev) => {
    const { name, value, checked } = ev.target;
    const stateChange = {};

    stateChange[name] = ['agreedToTerms'].includes(name) ? checked : value;

    setAgreedToTerms(checked);
    setPaymentMethod({ ...paymentMethod, ...stateChange });
  };

  const handlePaymentMethodChange = (changes) => {
    setPaymentMethod({ ...paymentMethod, ...changes });
  };

  const { termsAgreementRequired, appPaymentMethodRequired, paymentMethodText } = profile;

  return !termsAgreementRequired && !appPaymentMethodRequired ? null : (
    <div className={classNames(css.prelim)}>
      <div className={css.popup}>
        <div className={classNames(css.image, css.logo)}>
          <img src={getBrandLogoUrl()} alt="logo" />
        </div>
        {message === '' ? null : <div className={css.error}>{message}</div>}
        {!appPaymentMethodRequired ? null : (
          <div>
            <div>{paymentMethodText}</div>
            <PaymentMethod model={paymentMethod} allowSelect={false} onChange={handlePaymentMethodChange} onRef={(ref) => { paymentMethodRef = ref; }} />
          </div>
        )}
        <div className={css.terms}>
          <Checkbox
            label={<div>I agree to the <a href={getBrandTermsUrl()} target="Terms">Terms and Conditions</a>.</div>}
            name="agreedToTerms"
            checked={agreedToTerms}
            onClick={handleChange}
          />
        </div>
        <div className={css.buttons}>
          <SolidButton onClick={handleSaveClick} loading={loading}>Submit</SolidButton>
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  profile: selectProfile(state).toJS(),
  loading: selectLoading(state),
}), {
  savePreliminaryData,
  removeAllErrors,
})(PreliminaryOverlay);
