/** @flow */
import React, { PureComponent } from 'react';
import { changeAnalysisMortgageParams } from 'data/analyses/actions';
import { connect } from 'react-redux';
import { Mortgage as MortgageObj } from 'vendor/Mortgage';

import Layout from './Layout';


class Mortgage extends PureComponent {
  constructor(props) {
    super(props);
    this.handleParamsChange = this.handleParamsChange.bind(this);
    this.handleBooleanParamsChange = this.handleBooleanParamsChange.bind(this);
    this.handleNumberParamsChange = this.handleNumberParamsChange.bind(this);
  }

  /* :: handleParamsChange: Function */
  handleParamsChange(event) {
    const { name, value } = event.target || event;
    this.props.changeAnalysisMortgageParams({ name, value });
  }

  /* :: handleBooleanParamsChange: Function */
  handleBooleanParamsChange(event) {
    const { name, value } = event.target || event;
    this.props.changeAnalysisMortgageParams({ name, value: value === 'true' });
  }

  /* :: handleNumberParamsChange: Function */
  handleNumberParamsChange(event) {
    const { name, value } = event.target || event;
    this.props.changeAnalysisMortgageParams({ name, value: Number(value) || 0 });
  }

  render() {
    const { analysisParams, mortgageEditIndex } = this.props;

    return (
      <Layout
        {...this.props}
        analysisMortgageParams={analysisParams.mortgages[mortgageEditIndex] || new MortgageObj()}
        onChange={this.handleParamsChange}
        onBooleanChange={this.handleBooleanParamsChange}
        onNumberChange={this.handleNumberParamsChange}
      />
    );
  }
}

const mapDispatchToProps = {
  changeAnalysisMortgageParams,
};


export default connect(null, mapDispatchToProps)(Mortgage);
