/** @flow */
/*
 * If there is API you need, please ask Konstantin Petryaev about update this component.
 */
import Table from './Table';
import Column from './Column';
import StickyTable from './StickyTable';


export default Table;

export {
  Column,
  StickyTable,
};
