import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/base/Button';

import css from './style.scss';


const FIRST_LABEL = '≪';
const PREVIOUS_LABEL = 'Back';
const NEXT_LABEL = 'Next';
const LAST_LABEL = '≫';

class PageButton extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const { onClick, disabled, page } = this.props;
    e.preventDefault();
    if (!disabled) onClick(page);
  }

  renderLabel() {
    const { first, last, previous, next, page } = this.props;

    if (first) return FIRST_LABEL;
    if (previous) return PREVIOUS_LABEL;
    if (next) return NEXT_LABEL;
    if (last) return LAST_LABEL;
    return page;
  }

  render() {
    if (this.props.hide) return null;

    const { disabled } = this.props;

    const className = classNames(css.button, {
      [css.disabled]: this.props.disabled,
      [css.big]: this.props.page >= 1000,
    });

    return (
      <Button kind={Button.kind.blueGhost} className={className} onClick={this.handleClick} disabled={disabled}>
        {this.renderLabel()}
      </Button>
    );
  }
}

PageButton.propTypes = {
  hide: PropTypes.bool,
  disabled: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  previous: PropTypes.bool,
  next: PropTypes.bool,
  page: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};


export default PageButton;
