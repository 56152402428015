import React, { Component } from 'react';
import Button, { SolidButton } from 'components/Button';

const serviceList = [
  'Free Daily Webinars',
  'Skip Tracing',
  'List Automator',
  'PropStream Academy Courses',
  'Campaigns',
  'Rehab Calculator',
  'Saved Marketing Lists',
  'Comp Reports',
  'Postcard Services',
];
const reasonList = [
  'I have all that I need and I don’t need the subscription anymore',
  'No longer pursuing my real estate business',
  'PropStream did not have the data I wanted',
  'I am choosing another provider',
  'Not using it anymore',
  'Financial reasons',
  'Technical issues',
  'Other',
];
const roleList = [
  'Investor',
  'Agent',
  'Broker',
  'Lender',
  'Contractor',
  'Flipper',
  'Wholesaler',
  'Property Manager',
];
export default class CancelAccountFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceChecked: [],
      reasonChecked: [],
      roleChecked: [],
      cancelAccount: false,
    };
    this.handleServiceCheck = this.handleServiceCheck.bind(this);
    this.handleReasonCheck = this.handleReasonCheck.bind(this);
    this.handleRoleCheck = this.handleRoleCheck.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel(e) {
    e.preventDefault();
    this.setState({ cancelAccount: !this.state.cancelAccount });
  }
  componentDidMount(){
    if(!this.props.brandCode.includes('propstream')){this.setState({ cancelAccount: !this.state.cancelAccount });}
  }

  handleServiceCheck(event) {
    var updatedList = [...this.state.serviceChecked];
    if (event.target.checked) {
      updatedList = [...this.state.serviceChecked, event.target.value];
    } else {
      updatedList.splice(
        this.state.serviceChecked.indexOf(event.target.value),
        1
      );
    }
    this.setState({ serviceChecked: updatedList });
  }

  handleReasonCheck(event) {
    var updatedList = [...this.state.reasonChecked];
    if (event.target.checked) {
      updatedList = [...this.state.reasonChecked, event.target.value];
    } else {
      updatedList.splice(
        this.state.reasonChecked.indexOf(event.target.value),
        1
      );
    }
    this.setState({ reasonChecked: updatedList });
  }

  handleRoleCheck(event) {
    var updatedList = [...this.state.roleChecked];
    if (event.target.checked) {
      updatedList = [...this.state.roleChecked, event.target.value];
    } else {
      updatedList.splice(this.state.roleChecked.indexOf(event.target.value), 1);
    }
    this.setState({ roleChecked: updatedList });
  }

  render() {
    const { isOpen } = this.props;
    const { serviceChecked, reasonChecked, roleChecked } = this.state;
    return (
      <span>
        {/* <Activities onClick={this.handleClose} /> */}
        <div
          style={{ width: '1160px', padding: '15px 25px 0', display: 'flex' }}
        >
          <div style={{ width: '50%', paddingBottom: '25px' }}>
          <img
              src='https://signup.propstream.com/resource/logo?c=CA02937056'
              placeholder='https://signup.propstream.com/resource/logo?c=CA02937056'
              class='_159lC__logoSmall'
              style={{ width: '138px', height: '76px', visibility: `${this.props.brandCode.includes('propstream')? 'visible': 'hidden'}` }}
            />
            {this.state.cancelAccount ? (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: 'calc(100% - 76px)',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#3D3D3D',
                }}
              >
                <div style={{ width: '292px', textAlign: 'center' }}>
                  <div
                    style={{
                      font: 'normal normal bold 24px Helvetica Neue',
                      lineHeight: 1.2,
                      marginBottom: '25px',
                    }}
                  >
                    We’re going to miss you.
                  </div>
                  <SolidButton
                    onClick={() =>
                      this.props.handleCancelConfirm(
                        serviceChecked,
                        reasonChecked,
                        roleChecked
                      )
                    }
                  >
                    Confirm Your Account Cancelation
                  </SolidButton>
                  <div style={{ marginTop: '25px', lineHeight: 1.5 }}>
                    <div>
                      <b>Just making sure.</b>
                    </div>{' '}
                    This will remove your saved searches, contacts, and
                    marketing lists.
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    marginTop: '21px',
                    color: '#3D3D3D',
                    font: 'normal normal bold 21px Helvetica Neue',
                    lineHeight: 1.2,
                  }}
                >
                  Before you go, would you mind letting us know which of our
                  services you used?
                </div>

                <div
                  style={{
                    marginTop: '5px',
                    font: 'normal normal 12px Helvetica Neue',
                    color: '#3D3D3D',
                  }}
                >
                  (Select all that apply)
                </div>
                <div
                  style={{
                    marginTop: '7.5px',
                    font: 'normal normal 12px Helvetica Neue',
                    color: '#3D3D3D',
                  }}
                >
                  {serviceList.map((item, index) => (
                    <span
                      key={index}
                      style={{
                        margin: '7.5px 0',
                        display: 'inline-flex',
                        width: `${
                          index == 0 || index == 3 || index == 6 ? '40%' : '30%'
                        }`,
                      }}
                    >
                      <input
                        value={item}
                        type='checkbox'
                        onChange={this.handleServiceCheck}
                      />
                      <span
                        style={{
                          marginLeft: '5px',
                        }}
                      >
                        {item}
                      </span>
                    </span>
                  ))}
                </div>
                <div
                  style={{
                    marginTop: '21px',
                    color: '#3D3D3D',
                    font: 'normal normal bold 21px Helvetica Neue',
                    lineHeight: 1.2,
                  }}
                >
                  What are your reason(s) for canceling PropStream?
                </div>

                <div
                  style={{
                    marginTop: '5px',
                    font: 'normal normal 12px Helvetica Neue',
                    color: '#3D3D3D',
                  }}
                >
                  (Select all that apply)
                </div>
                <div
                  style={{
                    marginTop: '7.5px',
                    font: 'normal normal 12px Helvetica Neue',
                    color: '#3D3D3D',
                  }}
                >
                  {reasonList.map((item, index) => (
                    <span
                      key={index}
                      style={{
                        margin: '7px 0',
                        display: 'inline-flex',
                        width: `${index % 2 === 0 ? '60%':'40%'}`,
                        alignItems: 'flex-start',
                        lineHeight: '1.5'
                      }}
                    >
                      <input
                       style={{
                        marginTop: '3px',
                      }}
                        value={item}
                        type='checkbox'
                        onChange={this.handleReasonCheck}
                      />
                      <span
                        style={{
                          marginLeft: '5px',
                          width: '80%',
                        }}
                      >
                        {item}
                      </span>
                    </span>
                  ))}
                </div>
                <div
                  style={{
                    marginTop: '21px',
                    color: '#3D3D3D',
                    font: 'normal normal bold 21px Helvetica Neue',
                    lineHeight: 1.2,
                  }}
                >
                  What best describes your role?
                </div>

                <div
                  style={{
                    marginTop: '5px',
                    font: 'normal normal 12px Helvetica Neue',
                    color: '#3D3D3D',
                  }}
                >
                  (Select all that apply)
                </div>
                <div
                  style={{
                    marginTop: '7.5px',
                    font: 'normal normal 12px Helvetica Neue',
                    color: '#3D3D3D',
                  }}
                >
                  {roleList.map((item, index) => (
                    <span
                      key={index}
                      style={{
                        margin: '7.5px 0',
                        display: 'inline-flex',
                        width: `25%`,
                      }}
                    >
                      <input
                        value={item}
                        type='checkbox'
                        onChange={this.handleRoleCheck}
                      />
                      <span
                        style={{
                          marginLeft: '5px',
                        }}
                      >
                        {item}
                      </span>
                    </span>
                  ))}
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: `${
                      this.props.trialOfferbutton ? 'space-between' : 'flex-end'
                    }`,
                    paddingRight: '5px',
                    margin: '36px 0 6px',
                  }}
                >
                  {this.props.trialOfferbutton && (
                    <SolidButton onClick={() => this.props.handleCancelClick()}>
                      Tell Me More About The Special Offer
                    </SolidButton>
                  )}
                  <Button onClick={(e) => this.handleCancel(e)}>
                    Cancel My Account
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    marginBottom: '20px',
                    fontSize: '10px',
                  }}
                >
                  (This will remove all saved data in your account)
                </div>
              </div>
            )}
          </div>
          <div
            style={{ width: '50%', display: 'flex', alignItems: 'flex-end' }}
          >
            <img src={'/assets/images/cancelAccountFeedback.jpg'} alt='' height='640px' />
          </div>
        </div>
      </span>
    );
  }
}
