import React, { PureComponent } from 'react';
import { fromJS } from 'immutable';

import Table, { Column } from '../../../components/base/Table';
import Button from '../../../components/base/Button';
import loadable from '../../../components/hoc/loadable';


class Loadable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      example1Loading: true,
      example1Error: false,
      example2Loading: true,
      example2Error: false,
      example3Loading: true,
      example3Error: false,
      example3ErrorText: null,
    };
  }

  startCount() {
    setTimeout(() => {
      this.setState({
        example1Loading: false,
      });
    }, 3000);

    setTimeout(() => {
      this.setState({
        example2Loading: false,
        example2Error: true,
      });
    }, 5000);

    setTimeout(() => {
      this.setState({
        example3Loading: false,
        example3Error: true,
        example3ErrorText: 'Something went wrong',
      });
    }, 7000);
  }

  render() {
    const data = fromJS([
      { field1: 'isLoading', field2: 'Boolean', field3: 'false' },
      { field1: 'isError', field2: 'Boolean', field3: 'false' },
      { field1: 'errorText', field2: 'String', field3: 'false' },
    ]);

    const ButtonLoadable = loadable(Button);
    const usageText = `const ButtonLoadable = loadable(Button);
<ButtonLoadable
  isLoading={isLoadingState}
  isError={isErrorState}
  errorText={errorText}
  kind="button"
  name="example-1"
>
  Button
</ButtonLoadable>`;

    this.startCount();

    return (
      <section style={{ paddingLeft: '100px' }}>
        <header>
          <h1>Loadable HOC</h1>
        </header>

        <article>
          <header>
            <h2>Props</h2>
          </header>
          <Table data={data}>
            <Column field="field1" defaultValue="default field1 value" isKey>Name</Column>
            <Column field="field2" defaultValue="default field2 value">Type</Column>
            <Column field="field3" defaultValue="default field3 value">Is required</Column>
          </Table>
        </article>

        <article>
          <header>
            <h2>Usage</h2>
          </header>
          <pre>{usageText}</pre>
        </article>

        <article>
          <header>
            <h2>Success in 3 seconds</h2>
          </header>
          <ButtonLoadable
            isLoading={this.state.example1Loading}
            isError={this.state.example1Error}
            kind="button"
            name="example-1"
          >
            Button
          </ButtonLoadable>
        </article>

        <article>
          <header>
            <h2>Failed in 5 seconds</h2>
          </header>
          <ButtonLoadable
            isLoading={this.state.example2Loading}
            isError={this.state.example2Error}
            kind="button"
            name="example-2"
          >
            Button
          </ButtonLoadable>
        </article>

        <article>
          <header>
            <h2>Failed in 7 seconds with error message</h2>
          </header>
          <ButtonLoadable
            isLoading={this.state.example3Loading}
            isError={this.state.example3Error}
            errorText={this.state.example3ErrorText}
            kind="button"
            name="example-3"
          >
            Button
          </ButtonLoadable>
        </article>
      </section>
    );
  }
}

export default Loadable;
