/** @flow */
import React, { PureComponent } from 'react';


export default class CustomLabel extends PureComponent {
  render() {
    const { stroke, value, orientation, x, y, width, height } = this.props;
    const xPosition = Math.round(x + (width / 2));
    const yPosition = Math.round(y + (height / 2));
    const transform = ['left', 'right'].includes(orientation) ? `rotate(-90 ${xPosition},${yPosition})` : '';
    const dy = ['bottom', 'right'].includes(orientation) ? 15 : -15;

    return (
      <text x={xPosition} y={yPosition} fill={stroke} textAnchor="middle" transform={transform} dy={dy}>
        {value}
      </text>
    );
  }
}
