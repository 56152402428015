import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import SVG from 'components/base/SVG';
import { dropdownable } from 'components/base/Dropdown';
import { MAP_NO_METRIC, getMapMetrics, selectMapMetrics } from 'data/search';

import DropdownPriceUpdateUI from './DropdownPrice/dropdownPriceUpdateUI';
import { getMetricsByType } from './constants';
import css from './style.scss';
import Analytics from '../../../../assets/images/analytics.svg'
import { ReactSVG } from 'react-svg';


const paletteItems = [
  '#8ab5e8',
  '#4a5fb7',
  '#2e3ca2',
  '#245266',
  '#1d6633',
  '#2a8300',
  '#aec900',
  '#ffd400',
  '#ff8c00',
  '#ff6b00',
  '#ff4a00',
  '#b31400',
  '#910400',
];

const Buttons = [
  { name: 'iconDollar', label: 'Est. Values' },
  { name: 'iconSmallChart', label: 'MLS Stats' },
  { name: 'iconGrowth', label: 'Price Growth' },
  { name: 'iconHouse', label: 'Rental Values' },
]


class MapAnalytics extends PureComponent {
  constructor(props) {
    super(props);

    this.handleTabChange = this.handleTabChange.bind(this);

    this.state = { activeTab: 0 };
  }

  componentWillMount() {
    this.props.getMapMetrics();
  }

  handleTabChange(newIndex) {
    this.setState({ activeTab: newIndex }, () => this.props.onChange(MAP_NO_METRIC));
  }

  render() {
    const { metrics, isOpen, onClick, disabled, onRef, onChange, metric } = this.props;
    const { activeTab } = this.state;

    if (!metrics.size) return null;

    const currentMetric = metrics.find(menu => menu.get('name') === metric);
    const noMetric = currentMetric.get('name') === MAP_NO_METRIC;

    const opacityProps = { name: 'searchPageOpacity', disabled: true };

    return (
      <div className={css.dropdownAnalytics} name="dropdown-btn">
        <button className={classNames(css.dropdownButton, {[css.active]: isOpen})} onClick={onClick} disabled={disabled}>
          <ReactSVG src={Analytics} className={css.iconAnalytics}/>
          <span className={css.analyticsText}> Analytics </span>
          <SVG icon="iconCaretDown" className={css.iconCaretDown}/>
        </button>
        <div ref={onRef} className={classNames(css.dropdown, {[css.open]: isOpen})}>
          <div className={css.buttonsGroup}>
            {Buttons.map((b, i) => (
              <button onClick={() => this.handleTabChange(i)} className={classNames(css.buttonGroup, { [css.active]: activeTab === i })} key={b.name}>
                <SVG icon={b.name} className={css[b.name]} />
                <div className={css.label}>{b.label}</div>
              </button>
            ))}
          </div>
          <DropdownPriceUpdateUI
            type={activeTab}
            currentMetric={currentMetric}
            onChange={onChange}
            metric={metric}
            data={getMetricsByType(activeTab, metrics)}
          />
          <div className={css.opacity}>
            <button className={classNames(css.buttonOpacity, css.out)} {...opacityProps}>
              <SVG icon="iconCaretRight" className={css.iconOut}/>
            </button>
            <button className={classNames(css.buttonOpacity, css.in)} {...opacityProps}>
              <SVG icon="iconCaretLeft" className={css.iconIn}/>
            </button>
          </div>
          <div className={css.palette}>
            <div className={css.paletteRow}>
              {paletteItems.map(result => (
                <div
                  key={result}
                  className={css.paletteItem}
                  style={{background: result}}
                />
              ))}
            </div>
            <div className={css.labels}>
              <span>{noMetric ? <span>&#160;</span> : `<${currentMetric.get('minString')}`}</span>
              <span>{noMetric ? <span>&#160;</span> : `${currentMetric.get('maxString')}+`}</span>
            </div>
          </div>

        </div>
      </div>
    );
  }
}


MapAnalytics.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  metric: PropTypes.string.isRequired,
};

MapAnalytics.defaultProps = {
  isOpen: false,
  disabled: false,
};


export default connect(state => ({
  metrics: selectMapMetrics(state),
}), {
  getMapMetrics,
})(dropdownable(MapAnalytics));
