/** @flow */
export const PropertyTypeValues = [
  { label: 'Investment', value: 'INVESTMENT' },
  { label: 'Personal', value: 'PERSONAL' },
];

export const BooleanValues = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const MortgageTypes = {
  fixedInterest: 'FIXED_INTEREST',
  interestOnly: 'INTEREST_ONLY',
  combination: 'COMBINATION',
  accelerated: 'ACCELERATED',
};

export const MortgageTypeValues = [
  { label: 'Fixed Interest', value: MortgageTypes.fixedInterest },
  { label: 'Interest Only', value: MortgageTypes.interestOnly },
  { label: 'Combination', value: MortgageTypes.combination },
  { label: 'Accelerated', value: MortgageTypes.accelerated },
];

export const MortgageTypeInputs = {
  FIXED_INTEREST: { fixedInterest: true, interestOnly: false },
  INTEREST_ONLY: { fixedInterest: false, interestOnly: true },
  COMBINATION: { fixedInterest: true, interestOnly: true },
  ACCELERATED: { fixedInterest: true, interestOnly: false },
};

export const MortgageFrequencyValues  = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Quarterly', value: 'QUARTERLY' },
  { label: 'Semi-annually', value: 'SEMI_ANNUALLY' },
  { label: 'Annually', value: 'ANNUALLY' },
];

export const ArmTypeValues = [
  { label: 'Standard', value: 'STANDARD' },
  { label: 'Hybrid', value: 'HYBRID' },
];

export const ArmTypes = {
  standard: 'STANDARD',
  hybrid: 'HYBRID',
};

export const ArmHybridCustomType = 'CUSTOM';

export const ArmHybridTypeValues = [
  { label: '10/1 ARM', value: 'ARM_10_1' },
  { label: '7/1 ARM', value: 'ARM_7_1' },
  { label: '5/1 ARM', value: 'ARM_5_1' },
  { label: '3/1 ARM', value: 'ARM_3_1' },
  { label: '1 Year ARM', value: 'ARM_1_YEAR' },
  { label: 'Custom', value: 'CUSTOM' },
];

export const ArmRateCapTypeValues = [
  { label: 'Limit Rate', value: 'RATE', field: 'armRateMax' },
  { label: 'Limit Increase', value: 'INCREASE', field: 'armIncreaseRateMax' },
];

export const AnalysisFieldsTypes = {
  closingCosts: 'CLOSING_COST',
  improvements: 'IMPROVEMENT',
  expenses: 'EXPENSE',
  incomes: 'INCOME',
};

export const DepreciationMethodValues = [
  { label: 'None', value: 'NONE', realValue: null },
  { label: 'Straightline', value: 'STRAIGHT_LINE', realValue: 'STRAIGHT_LINE' },
  { label: 'Double-Declining Balance', value: 'DOUBLE_DECLINING_BALANCE', realValue: 'DOUBLE_DECLINING_BALANCE' },
];

export const RateAmountTypeValues = [
  { label: 'Percentage', value: 'PERCENTAGE' },
  { label: 'Fixed Dollar Amount', value: 'DOLLAR_AMOUNT' },
];

export const RateAmountNoneTypeValues = [
  { label: 'None', value: 'NONE', realValue: null },
  { label: 'Percentage', value: 'PERCENTAGE', realValue: 'PERCENTAGE' },
  { label: 'Fixed Dollar Amount', value: 'DOLLAR_AMOUNT', realValue: 'DOLLAR_AMOUNT' },
];

export const ExpenseIncreaseTypeValues = [
  { label: 'Percentage', value: 'PERCENTAGE' },
  { label: 'Fixed Dollar Amount', value: 'DOLLAR_AMOUNT' },
];

export const PropertyValueBasisValues = [
  { label: 'Purchase Price', value: 'PURCHASE_PRICE' },
  { label: 'Market Value', value: 'MARKET_VALUE' },
  { label: 'Other', value: 'DOLLAR_AMOUNT' },
];

export const PropertyValueIncreaseTypeValues = [
  { label: 'Market Value Increase Rate', value: 'MARKET_VALUE_INCREASE_RATE' },
  { label: 'Cap Rate', value: 'CAP_RATE' },
  { label: 'Inflation Rate', value: 'INFLATION_RATE' },
  { label: 'Fixed Selling Price', value: 'DOLLAR_AMOUNT' },
];

export const TermTypeValues = [
  { label: 'One Year', value: 'ONE_YEAR' },
  { label: 'Three Years', value: 'THREE_YEAR' },
  { label: 'Five Years', value: 'FIVE_YEAR' },
  { label: 'Ten Years', value: 'TEN_YEAR' },
  { label: 'Mortgage Term', value: 'MORTGAGE' },
  { label: 'Months(Short Sale)', value: 'MANUAL' },
];

export const IncomeIncreaseTypeValues = [
  { label: 'Percentage', value: 'PERCENTAGE' },
  { label: 'Fixed Dollar Amount', value: 'DOLLAR_AMOUNT' },
];
