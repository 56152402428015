import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SolidButton } from 'components/Button';
import SVG from 'components/base/SVG';
import Confirm from 'app/components/Confirm';
import { dropWrapper } from 'utils/DOM/dragDrop';

import css from './style.scss';

const LeftPart = (props) => {
  const [dropping, setDropping] = useState(false);

  const handleDeleteClick = () => {
    props.alert('Please drag any groups or records here that you would like to remove.');
  };

  const handleDragEnter = () => {
    setDropping(true);
  };

  const handleDragLeave = () => {
    setDropping(false);
  };

  const handleDrop = (ev) => {
    handleDragLeave();
    props.onDeleteDrop(ev);
  };

  const { children, caption, onDeleteDrop, allowDrop, onAddClick, addLabel, headerClass } = props;

  return (
    <div className={css.root}>
      <div className={classNames(css.header, headerClass)}>
        <div className={css.caption}>{caption}</div>
        <div className={css.actions}>
          {!onDeleteDrop ? null : (
            <div
              className={classNames(css.action, { [css.dropping]: dropping })}
              onClick={handleDeleteClick}
              onDragEnter={allowDrop && handleDragEnter}
              onDragLeave={allowDrop && handleDragLeave}
              onDragOver={allowDrop && (ev => ev.preventDefault())}
              onDrop={allowDrop && dropWrapper(handleDrop)}
            >
              <SVG icon={`iconTrash${dropping ? 'Open' : ''}`} />
            </div>
          )}
        </div>
        {!onAddClick ? null : <SolidButton text={addLabel} onClick={onAddClick} />}
      </div>
      <div className={css.body}>
        {children}
      </div>
    </div>
  );
};

LeftPart.propTypes = {
  caption: PropTypes.string.isRequired,
  headerClass: PropTypes.string,
  addLabel: PropTypes.string,
  onAddClick: PropTypes.func,
  onDeleteDrop: PropTypes.func,
  allowDrop: PropTypes.bool,
};

LeftPart.defaultProps = {
  allowDrop: false,
  addLabel: 'New',
};

export default connect(null, { alert: Confirm.alert })(LeftPart);
