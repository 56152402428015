import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pluralize from 'pluralize';

import AddToGroup from 'app/components/AddToGroup';
import Prompt from 'app/components/Prompt';
import Button from 'components/Button';
import { defaultGroupId, MAIN_CONTEXT } from 'reducers';
import ContactSearchTable from 'app/components/SearchTable/ContactSearchTable';
import { contactGroupPath } from 'app/routes';
import DropdownMenu from 'components/base/DropdownMenu';
import MailingLabelsPopup from 'app/components/MailingLabels';
import {
  saveGroupContext,
  selectLoading,
  selectAssignableGroups,
  ContactGroupTypes,
  selectGroupContext,
  exportContacts,
  updateAppendJob,
  updateContactGroup,
  getSearch,
} from 'data/contacts';
import { selectPabEnabled } from 'data/user';

import ContactImport from '../ContactImport';
import AppendJobSummary from '../AppendJobSummary';

import css from './style.scss';


class ContactList extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAddToGroupClick = this.handleAddToGroupClick.bind(this);
    this.handleViewSummaryClick = this.handleViewSummaryClick.bind(this);
    this.handleExportClick = this.handleExportClick.bind(this);
    this.handleRenameGroupClick = this.handleRenameGroupClick.bind(this);
    this.handleGenerateMailingLabels = this.handleGenerateMailingLabels.bind(this);
  }

  handleAddToGroupClick() {
    const { context, groups, openAddToGroup } = this.props;
    const size = context.get('selected');
    const limit = 1000;

    openAddToGroup({
      limit,
      size,
      groups,
      selectLoading,
      itemType: 'Contact',
      add: (name, afterSuccess) => this.saveGroup(context.setIn(['info', 'name'], name), () => afterSuccess(Math.min(size, limit))),
    });
  }

  handleExportClick() {
    const { exportContacts, context } = this.props;
    exportContacts(context);
  }

  saveGroup(context, afterSuccess) {
    const { saveGroupContext, history } = this.props;
    saveGroupContext(context, ({ response }) => {
      afterSuccess();
      history.push(contactGroupPath(response.find(g => g.modified).id));
    });
  }

  handleViewSummaryClick() {
    const { openAppendJobSummary, context } = this.props;
    openAppendJobSummary({ appendJobId: context.get('id').substr(1) });
  }

  handleRenameGroupClick() {
    const { context, prompt, updateAppendJob, updateContactGroup } = this.props;

    prompt({
      text: 'Please enter a new name for this group.',
      title: 'Rename Group',
      value: context.getIn(['info', 'name']),
      onSubmit: name => (context.getIn(['info', 'type']) === ContactGroupTypes.APPEND ? updateAppendJob : updateContactGroup)({ id: context.get('id').substr(1), name }),
    });
  }

  handleGenerateMailingLabels() {
    const { openMailingLabels, context } = this.props;

    openMailingLabels({ contactSearch: getSearch(context) });
  }

  render() {
    const { loading, openImport, context, getContactInfo, deleteContacts, deleteGroup, onDragStart, onDragEnd, pabEnabled } = this.props;
    const btn = { loading };
    const status = context.getIn(['info', 'status']) === 'COMPLETE' ? 'Complete' : 'Processing';

    const retrieved = context.get('data').size;
    const size = context.get('size');
    const append = context.getIn(['info', 'type']) === ContactGroupTypes.APPEND;
    const selected = context.get('selected');

    return (
      <div className={css.root}>
        <div className={css.header}>
          {!selected ? null : <div className={css.selected}>{selected} Selected</div>}
          <h1 className={css.title}>{context.getIn(['info', 'name'])} {size == null ? '' : ` (${retrieved}${retrieved < size ? ` of ${size}` : ''})`}</h1>
          {!append ? null : <div className={css.status}>Append Status: <span className={css[status.toLowerCase()]}>{status}</span></div>}
          <div className={css.legend}>
            <div>(DNC) - Do Not Call</div>
            {!pabEnabled ? null : <div>(PAB) - Possible Agent or Broker</div>}
          </div>
          <div className={css.actions}>
            <Button {...btn} onClick={openImport}>Import List</Button>
            <Button {...btn} disabled={!selected} onClick={this.handleExportClick}>Export</Button>
            <Button {...btn} url={contactGroupPath('0/new')}>+ New Contact</Button>
            <DropdownMenu
              text="Actions"
              icon="iconCheckSquare"
              loading={loading}
              items={[
                append ? { label: 'View Summary', onSelect: this.handleViewSummaryClick } : null,
                selected && !append ? { label: 'Append Info', onSelect: () => getContactInfo() } : null,
                { label: 'Add to Group', onSelect: this.handleAddToGroupClick },
                context.get('id') !== defaultGroupId ? { label: 'Rename Group', onSelect: this.handleRenameGroupClick } : null,
                selected && !append ? { label: `Delete ${pluralize('Contact', selected)}`, onSelect: deleteContacts } : null,
                context.get('id') !== defaultGroupId && !append ? { label: 'Delete Group', onSelect: deleteGroup } : null,
                selected ? { label: 'Generate Mailing Labels', onSelect: this.handleGenerateMailingLabels } : null,
              ]}
            />
          </div>
        </div>
        <div className={css.body}>
          <ContactSearchTable context={context} onDragStart={onDragStart} onDragEnd={onDragEnd} fullMode />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: selectLoading(state),
  pabEnabled: selectPabEnabled(state),
  groups: selectAssignableGroups(state),
  context: selectGroupContext(MAIN_CONTEXT)(state),
});

const mapActionToProps = {
  saveGroupContext,
  exportContacts,
  updateAppendJob,
  updateContactGroup,
  openImport: ContactImport.open,
  openAppendJobSummary: AppendJobSummary.open,
  openAddToGroup: AddToGroup.open,
  openMailingLabels: MailingLabelsPopup.open,
  prompt: Prompt.open,
};


export default withRouter(connect(mapStateToProps, mapActionToProps)(ContactList));
