import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { fromJS, List, Map as ImMap } from 'immutable';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import { PropertyGroupTypes, saveProperty, saveProperties, selectLists, selectOriginalFavorites, selectLoading as selectPropertyLoading , selectProperty } from 'data/property';
import { selectProfile } from 'data/user';
import AddToGroup from 'app/components/AddToGroup';

import { deferExecutor, deferRegistrator } from 'app/DeferredOnLocation';
import Map from 'app/components/Map';
import Confirm from 'app/components/Confirm';

import {
  RESULTS_PER_PAGE,
  SearchModes,
  SearchOverlays,
  setLocation,
  setPage,
  setRange,
  toggleRange,
  toggleSelection,
  setSort,
  setLayout,
  setActiveContext,
  setOverlay,
  searchListings,
  searchProperty,
  searchStatistics,
  searchFavorites,
  searchLeadList,
  saveListings,
  clearStatistics,
  refreshSearch,
  selectLoading,
  selectStale,
  selectLocation,
  selectSuggestion,
  selectFilter,
  selectResult,
  selectActiveContext,
  selectStatistic,
  selectFavoriteSearchStale,
  selectFavoriteSearchLoading,
  clearAll
} from 'data/search';

import css from './style.scss';
import OverlayView from './Overlay';
import StatisticCharts from './Statistic';
import Results from './Results';
import PropertyView from './Property';
import Header from './Header';


class Search extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRange = this.handleChangeRange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSaveList = this.handleSaveList.bind(this);
    this.handleLayoutChange = this.handleLayoutChange.bind(this);
    this.handleOverlayChange = this.handleOverlayChange.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleDraw = this.handleDraw.bind(this);
    this.handleSaveProperty = this.handleSaveProperty.bind(this);

    this.loadMarkerDetail = this.loadMarkerDetail.bind(this);

    this.state = {
      activeMarker: null,
    };
  }

  componentDidUpdate() {
    const { stale, loading, location, suggestion, filter, activeContext, searchListings, searchProperty, searchFavorites, searchLeadList, searchStatistics, statistic, favoriteStale, favoriteLoading } = this.props;

    if (!loading && location) {
      if (stale) { // Primary search is stale
        const l = (location.get('query') || ImMap()).toJS();
        const { propertyId, streetAddress, fips } = l;
        let { apn } = l;
        const type = (suggestion.get('value') || ImMap()).get('type');

        let filt = filter.toJS();
        filt = Object.keys(filt).reduce((o, k) => ({ ...o, [k]: moment.isMoment(filt[k]) ? filt[k].valueOf() : filt[k] }), {});

        if (propertyId || streetAddress || (fips && apn)) {
          // Strip weird characters from the APN, which can cause AWS to reject the request. E.g. "53-(A)- L109B" in property 1844922339 (which wasn't having its paraentheses escaped for some reason).
          if (apn) apn = apn.replace(/[^a-zA-Z0-9\s:-]/g, '');

          // For street searches, pass in search criteria so results may be filtered
          searchProperty({ ...(type === 'S' ? { ...filt, applyFilter: true } : {}), ...l, apn });
        } else {
          const ac = activeContext || ImMap();
          const sortField = (ac.get('sortField') || '').trim();

          searchListings({
            ...l,
            ...filt,
            type: null,
            resultOffset: ac.get('resultOffset') || 1,
            resultLimit: RESULTS_PER_PAGE,
            ...(sortField === '' ? {} : { sortField, sortAscending: ac.get('sortAscending') || false }),
          });
        }

        // this.setState({ activeMarker: null });
      } else {
        const l = (location.get('geo') || ImMap()).toJS();
        if (!favoriteLoading && favoriteStale) searchFavorites(l), searchLeadList(l);
        else if (!statistic.get('loading') && statistic.get('stale')) searchStatistics(l, statistic.get('filter').toJS());
      }
    }

    const previewModal = document.getElementById('PostcardPreview');
    if (previewModal) {
      previewModal.style.display = "none";
    }
  }

  handleDraw(value) {
    this.props.setLocation({ shapeDefinition: value.join('/'), label: 'Shape Search' });
  }

  handleChangePage(page) {
    this.props.setPage(page);
  }

  handleChangeRange(event) {
    const { toggleRange, setRange, activeContext } = this.props;
    const { name, value } = event.target || event;

    if (name === 'rangeEnabled') toggleRange(value);
    else if (name === 'rangeFrom') setRange(value, activeContext.get('rangeTo'));
    else if (name === 'rangeTo') setRange(activeContext.get('rangeFrom'), value);
  }

  handleSort({ fieldName }) {
    const { activeContext, setSort } = this.props;

    let sortField = activeContext.get('sortField');
    let sortAscending = activeContext.get('sortAscending');

    if (fieldName === sortField) {
      if (sortAscending) sortAscending = false;
      else sortField = null;
    } else {
      sortField = fieldName;
      sortAscending = true;
    }

    setSort(sortField, sortAscending);
  }

  handleLayoutChange(layout) {
    this.props.setLayout(layout);
  }

  handleOverlayChange(overlay) {
    this.props.setOverlay(overlay);
  }

  handleSaveList() {
    const { saveListings, saveProperties, lists, openAddToGroup, profile, result, activeContext: ac } = this.props;
    const listingMode = result.get('mode') === SearchModes.Listing;

    openAddToGroup({
      selectLoading: listingMode ? selectLoading : selectPropertyLoading,
      groups: lists,
      size: ac.get('selectionCount'),
      limit: profile.get('marketingListAddLimit'),
      groupType: 'Marketing List',
      addPropertyMode: true,
      add: (name, onComplete) => {
        const callback = ({ response: { quantity } }) => onComplete(quantity);
        const sel = ac.get('selection').toJS();

        if (!listingMode) saveProperties(sel.map(id => [id]), name, PropertyGroupTypes.MARKETING, null, callback);
        else {
          saveListings({
            ...result.get('query').toJS(),
            resultOffset: ac.get('effectiveRangeFrom'),
            resultLimit: ac.get('effectiveRangeTo'),
          }, sel, ac.get('selectionInversed'), PropertyGroupTypes.MARKETING, name, callback);
        }
      },
    });
  }

  loadMarkerDetail(id, data) {
    this.setState({ activeMarker: data ? data.get('id') : id });
  }

  handleSelect(id) {
    this.props.toggleSelection(typeof id === 'number' ? id : null);
  }

  handleSaveProperty(onSuccess) {
    const { saveProperty, openAddToGroup, favorites, result } = this.props;
    const id = result.getIn(['property', 'id']);

    openAddToGroup({
      selectLoading: selectPropertyLoading,
      groups: favorites,
      size: 1,
      addPropertyMode: true,
      save: onComplete => saveProperty(id, null, ({ response: { quantity, property: { savedPropertyId } } }) => { onComplete(quantity); (onSuccess || (() => null))(savedPropertyId); }),
      add: (name, onComplete) => saveProperty(id, { name }, ({ response: { quantity } }) => onComplete(quantity)),
    });
  }

  render() {
    const {
      location,
      filter,
      result,
      activeContext,
      setOverlay,
      loading,
      refreshSearch,
      visible,
      statistic,
      setActiveContext,
      propertyLoading,
      children = null,
      selectProperty
    } = this.props;

    const { activeMarker } = this.state;

    const mode = result.get('mode');
    const listingMode = mode === SearchModes.Listing || mode === SearchModes.Multiple;
    const displayed = !!mode;
    const overlay = result.get('overlay');
    const property = result.get('property');

    const ac = activeContext || ImMap();
    const sortField = ac.get('sortField');
    const sort = fromJS(sortField ? [sortField] : []);

    if (listingMode && !loading && !activeContext) {
      this.props.clearAll();
    }

    return (
      <div style={{ display: visible ? 'block' : 'none' }}>
        <Helmet title="Search" />
        <div className={css.wrapper}>
          <Header location={location} result={result} loading={loading} onLayoutChange={this.handleLayoutChange} onSearch={refreshSearch} />
          <section className={classNames(css.content, { [css.contentOverlayed]: !!overlay && listingMode })}>
            <div className={classNames(css.leftSide, { [css.leftSideFullSize]: !displayed })}>
              <div className={classNames(css.map, { [css.mapFullSize]: !displayed })}>
                <Map
                  properties={loading ? null : (!property ? List() : List([property.set('pinColor', 'green')])).concat(ac.get('displayResults') || List())}
                  infoEnabled
                  analyticsEnabled
                  parcelClickEnabled
                  activeResult={activeMarker}
                  onDraw={this.handleDraw}
                />
              </div>
              {!displayed ? null : (
                <OverlayView
                  className={classNames(css.statistics, { [css.statisticsExpanded]: overlay === SearchOverlays.Graph })}
                  buttonClassName={css.bottomExpand}
                  icon="iconCaretDown"
                  iconClassName={css.iconCaret}
                  overlayType={SearchOverlays.Graph}
                  triggerOverlay={setOverlay}
                >
                  <StatisticCharts statistic={statistic} />
                </OverlayView>
              )}
            </div>
            {!displayed ? null : (
              <OverlayView
                className={classNames(css.rightSide, { [css.rightSideExpanded]: overlay === SearchOverlays.Property && listingMode })}
                buttonClassName={css.rightExpand}
                icon="iconCaretDown"
                iconClassName={css.iconCaret}
                overlayType={SearchOverlays.Property}
                triggerOverlay={setOverlay}
                hideButton={mode === SearchModes.Property}
                loading={loading}
              >
                {listingMode && !loading && activeContext && (
                  <Results
                    filter={filter}
                    result={result}
                    sort={sort}
                    activeContext={activeContext}
                    activeMarker={activeMarker}
                    onChangePage={this.handleChangePage}
                    onChangeRange={this.handleChangeRange}
                    onSort={this.handleSort}
                    onClick={this.loadMarkerDetail}
                    onSaveList={this.handleSaveList}
                    onSelect={this.handleSelect}
                  />
                )}
                {mode === SearchModes.Property && !loading && (
                  <PropertyView
                    result={result}
                    activeContext={activeContext}
                    setActiveContext={setActiveContext}
                    activeMarker={activeMarker}
                    sort={sort}
                    loading={propertyLoading}
                    selectProperty={selectProperty}
                    onSort={this.handleSort}
                    onClick={this.loadMarkerDetail}
                    onSaveProperty={this.handleSaveProperty}
                  />
                )}
              </OverlayView>
            )}
          </section>
        </div>
        {children}
      </div>
    );
  }
}

export default connect(state => ({
  stale: selectStale(state),
  location: selectLocation(state),
  suggestion: selectSuggestion(state),
  filter: selectFilter(state),
  result: selectResult(state),
  activeContext: selectActiveContext(state),
  lists: selectLists(state),
  favorites: selectOriginalFavorites(state),
  profile: selectProfile(state),
  statistic: selectStatistic(state),
  selectProperty: selectProperty(state),
  propertyLoading: selectPropertyLoading(state),
  loading: selectLoading(state),
  favoriteStale: selectFavoriteSearchStale(state),
  favoriteLoading: selectFavoriteSearchLoading(state),
}), {
  setLocation,
  clearAll,
  searchListings,
  searchProperty,
  searchStatistics,
  searchFavorites,
  searchLeadList,
  saveListings,
  saveProperties,
  refreshSearch,
  setPage,
  toggleRange,
  toggleSelection,
  setActiveContext,
  setRange,
  setSort,
  setLayout,
  setOverlay,
  saveProperty,
  clearStatistics,
  openAddToGroup: AddToGroup.open,
  confirmSaveProperty: Confirm.open,
})(deferExecutor(deferRegistrator(Search)));