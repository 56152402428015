import React, { PureComponent } from 'react';
import Breadcrumb from './Breadcrumb';
import PropTypes from 'prop-types';

import css from './style.scss';


class Breadcrumbs extends PureComponent {
  render() {
    const { breadcrumbs } = this.props;
    const breadcrumbsLinks = breadcrumbs.map(breadcrumb => <Breadcrumb key={breadcrumb.name} {...breadcrumb} />);

    return (
      <ul className={css.breadcrumbs}>
        {breadcrumbsLinks}
      </ul>
    );
  }
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
      isActive: PropTypes.bool,
      isPast: PropTypes.bool,
      disabled: PropTypes.bool,
    }),
  ),
};

export default Breadcrumbs;
