import React, { PureComponent } from 'react';
import classNames from 'classnames';

import SVG from 'components/base/SVG';
import numberFormat from 'utils/number/format';
import PropTypes from 'prop-types';

import css from './style.scss';


class PropertyGroupTile extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDragStart = this.handleDragStart.bind(this);
  }

  handleClick() {
    const { onClick, type, disabled } = this.props;

    if (!disabled) onClick(type);
  }

  handleEdit(ev) {
    const { onEdit, type } = this.props;

    ev.stopPropagation();
    onEdit(type);
  }

  handleDragStart() {
    const { onClick, type } = this.props;
    onClick(type);
  }

  render() {
    const { name, description, quantity, selected, up, down, editable, custom, disabled } = this.props;

    return (
      <div
        className={classNames(css.tile, { [css.active]: selected, [css.grayed]: !quantity, [css.disabled]: disabled, [css.custom]: custom })}
        onClick={this.handleClick}
        onDragStart={this.handleDragStart}
        draggable={!!quantity}
        title={description || name}
      >
        <div className={css.label}>
          <div>{name}</div>
        </div>
        <div className={classNames(css.value, { [css.up]: up, [css.down]: down })}>
          <SVG icon="iconExpand" />
          <div>{quantity == null ? '-' : numberFormat(quantity)}</div>
        </div>
        {!editable ? null : (
          <div className={css.edit} onClick={this.handleEdit}>
            <SVG icon="iconEdit" />
          </div>
        )}
      </div>
    );
  }
}

PropertyGroupTile.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  type: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  up: PropTypes.bool,
  down: PropTypes.bool,
  custom: PropTypes.bool,
};

PropertyGroupTile.defaultProps = {
  selected: false,
  editable: false,
  disabled: false,
  up: false,
  down: false,
  custom: false,
};

export default PropertyGroupTile;
