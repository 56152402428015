import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Map, List } from 'immutable';

import { selectTags, selectStatuses, savePropertyStatus } from 'data/property';
import { saveTag, deleteTag } from 'data/user';
import withProperty from 'app/components/withProperty';

import Button2 from 'components/base/Button';
import Button, { SolidButton } from 'components/Button';
import { BlueLink } from 'components/Link';
import Dropdown, { dropdownable } from 'components/base/Dropdown';
import Checkbox from 'components/base/Checkbox';
import SVG from 'components/base/SVG';
import TeamMemberList from 'app/components/TeamMemberList';
import Confirm from 'app/components/Confirm';
import css from './style.scss';
import { validate, verify, verifyRequired } from 'utils/validation';


const defaultState = {
  id: null,
  tagEditing: false,
  userEditing: false,
  editTag: null,
  name: '',
  note: '',
  message: '',
  statusId: null,
  assignedTags: [],
  assignedUsers: [],
};

export class PropertyTags extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleEditTagClick = this.handleEditTagClick.bind(this);
    this.handleEditUserClick = this.handleEditUserClick.bind(this);
    this.handleSaveTagClick = this.handleSaveTagClick.bind(this);
    this.handleDeleteTagClick = this.handleDeleteTagClick.bind(this);
    this.handleTeamMemberClick = this.handleTeamMemberClick.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    this.handleApplyClick = this.handleApplyClick.bind(this);

    this.ref = {};
    this.state = defaultState;
  }

  componentDidMount() {
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(props) {
    const { isOpen, property } = props;
    const id = property.get('id');

    if ((isOpen && !this.props.isOpen) || id !== this.state.id) {
      const { note = '', statusId, assignedTags = [], assignedUsers = [] } = property.get('status', Map()).toJS();
      this.setState({ id, note, statusId, assignedTags, assignedUsers });
    }
  }

  handleApplyClick() {
    const { note, statusId, assignedTags, assignedUsers, id } = this.state;
    const { savePropertyStatus, closeDropdown, checkSavedProperty } = this.props;

    checkSavedProperty(() => {
      validate(() => {
        verify(this.ref.note, note.length <= 4000, 'Note length cannot exceed 4000 characters.');
        savePropertyStatus(id, { note, statusId, assignedTags, assignedUsers }, closeDropdown);
      });
    });
  }

  handleChange(ev) {
    const { name, value } = ev.target || ev;

    this.setState({ [name]: value });
  }

  handleTagChange(ev) {
    const { tagEditing, assignedTags } = this.state;
    const { tags } = this.props;
    const { value, checked } = ev.target || ev;
    const id = Number(value);

    if (!tagEditing) this.setState({ assignedTags: tags.map(t => t.id).filter(tid => ((tid === id && checked) || (tid !== id && assignedTags.includes(tid)))) });
  }

  handleTagClick(tag) {
    const { editTag, tagEditing } = this.state;
    const { id } = tag;

    if (tagEditing) {
      const newTag = editTag && editTag.id === id ? null : tag;
      this.setState({ editTag: newTag, name: newTag ? newTag.name : '' });
    }
  }

  handleEditTagClick() {
    this.setState({ tagEditing: !this.state.tagEditing, editTag: null, name: '' }, () => { if (this.state.tagEditing) this.ref.name.focus(); });
  }

  handleEditUserClick() {
    this.setState({ userEditing: !this.state.userEditing, message: '' });
  }

  handleTeamMemberClick({ id, name }) {
    const { confirm } = this.props;
    const { userEditing, assignedUsers } = this.state;

    if (userEditing) this.setState({ assignedUsers: assignedUsers.concat([id]), message: `Team Member ${name} assigned!` });
    else {
      confirm({
        question: `Unassign Team Member ${name}?`,
        okLabel: 'Unassign',
        onOk: () => this.setState({ assignedUsers: assignedUsers.filter(uid => uid !== id) }),
      });
    }
  }

  handleSaveTagClick() {
    const { name, editTag, assignedTags } = this.state;
    const { id } = editTag || {};

    validate(() => {
      const n = name.trim();

      if (editTag && editTag.name === n) this.handleEditTagClick();
      else {
        verifyRequired(this.ref.name, n, 'Name is required.');
        verify(this.ref.name, n.length <= 22, 'Name length cannot exceed 22 characters.');

        this.props.saveTag({ id, name: n, type: 'P' }, ({ response }) => {
          if (!id) this.setState({ assignedTags: assignedTags.concat([response.find(t => t.modified).id]) });
          this.handleEditTagClick();
        });
      }
    });
  }

  handleDeleteTagClick() {
    const { confirm, deleteTag } = this.props;
    const { editTag } = this.state;

    confirm({
      caption: `Delete tag "${editTag.name}"?`,
      question: 'The tag will be removed from all properties it\'s currently assigned to.',
      okLabel: 'Delete',
      onOk: () => deleteTag(editTag.id, this.handleEditTagClick),
    });
  }

  handleRef(ref, name) {
    if (ref) this.ref[name] = ref;
  }

  render() {
    const { closeDropdown, isOpen, onRef, onClick, statuses, tags, loading, property } = this.props;
    const { statusId, note, tagEditing, userEditing, editTag, assignedTags, name, assignedUsers } = this.state;

    const statusOptions = [{ value: '', label: '--Select Status--' }].concat(statuses.map(s => ({ value: s.id, label: s.name })));
    const logs = property.getIn(['status', 'logs'], List());

    return (
      <div onClick={onClick} className={css.tags} name="dropdown-btn">
        <SolidButton className={css.dropdownButton}>
          <SVG icon="iconTag" className={css.iconTag} />
          Tags / Status
        </SolidButton>
        {!isOpen ? null : (
          <div className={css.dropdown} ref={onRef}>
            <div className={css.header}>
              <div className={css.caption}>Property Status / Tags</div>
              <Button2 kind="link-default" className={css.btnClose} onClick={closeDropdown}>
                <SVG icon="iconClose" className={css.iconClose} />
              </Button2>
            </div>
            <div className={css.sides}>
              <div>
                <div className={css.section}>
                  <div className={css.label}>Property Status</div>
                  <Dropdown name="statusId" options={statusOptions} value={statusId} onChange={this.handleChange} />
                </div>
                <div className={css.section}>
                  <div className={css.row}>
                    <div className={css.label}>Property Tags</div>
                    <BlueLink onClick={this.handleEditTagClick}>{tagEditing ? 'Cancel ' : ''}Edit</BlueLink>
                  </div>
                  <div className={css.tags}>
                    {tags.map(t => (
                      <Checkbox
                        onChange={this.handleTagChange}
                        onLabelClick={() => this.handleTagClick(t)}
                        checked={assignedTags.includes(t.id)}
                        defaultChecked={false}
                        label={t.name}
                        value={t.id}
                        disabled={tagEditing}
                        key={t.id}
                        containerClassName={css.tag}
                        labelClassName={classNames({ [css.editCheck]: tagEditing, [css.selected]: editTag && t.id === editTag.id })}
                      />
                    ))}
                  </div>
                  {!tagEditing ? null : (
                    <div className={css.tagEdit}>
                      <input name="name" value={name} maxLength={22} onChange={this.handleChange} ref={r => this.handleRef(r, 'name')} />
                      <Button onClick={this.handleSaveTagClick}>{editTag ? 'Rename' : 'Add'}</Button>
                      {!editTag ? null : <Button onClick={this.handleDeleteTagClick}>Delete</Button>}
                    </div>
                  )}
                </div>
                <div className={css.section}>
                  <div className={css.label}>Notes</div>
                  <textarea name="note" value={note} onChange={this.handleChange} className={css.field} rows={6} />
                </div>
              </div>
              <div>
                <div className={css.section}>
                  <div className={css.row}>
                    <div className={css.label}>Assigned Team Members</div>
                    <BlueLink onClick={this.handleEditUserClick}>{userEditing ? 'Done' : 'Add'}</BlueLink>
                  </div>
                  <div className={css.users}>
                    <TeamMemberList
                      iconSize={42}
                      editButtonText={userEditing ? 'Add' : 'Remove'}
                      onEditClick={this.handleTeamMemberClick}
                      {...({ [userEditing ? 'excludeIds' : 'includeIds']: assignedUsers })}
                      placeholder={`${userEditing ? 'All' : 'No'} Team Members Assigned`}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={css.buttons}>
              <Button loading={loading} onClick={closeDropdown}>Cancel</Button>
              <SolidButton loading={loading} onClick={this.handleApplyClick}>Apply</SolidButton>
            </div>
            {!logs.size ? null : (
              <div className={css.log}>
                <div className={css.label}>Status Log:</div>
                <div className={css.logs}>
                  {logs.map(log => <div key={log}>{log}</div>)}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default dropdownable(withProperty(PropertyTags, state => ({
  tags: selectTags(state).toJS(),
  statuses: selectStatuses(state).toJS(),
}), {
  savePropertyStatus,
  saveTag,
  deleteTag,
  confirm: Confirm.open,
}));
