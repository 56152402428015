import React, { PureComponent } from 'react';

import CustomInput from './CustomInput';


export default class PercentInput extends PureComponent {
  render() {
    return (
      <CustomInput {...this.props} type="percent" showSymbol />
    );
  }
}
